import * as React from 'react';
import { Radio } from 'antd';

import { Antd3Form, Antd3FormModel } from 'common/components/deprecated/antd3';
import { Antd3Radio } from 'common/components/deprecated/antd3/Antd3Radio';

interface Props extends Antd3FormModel {
    initialValue?: boolean;
}

export const EmployeeRole: React.FunctionComponent<Props> = ({ initialValue = false, form }) => (
    <Antd3Form.Item>
        <Antd3Radio
            form={form}
            id="isAdministrator"
            getFieldDecoratorOptions={{
                initialValue,
                rules: [{ required: true, message: 'Please choose an employee role' }],
            }}
        >
            <Radio.Group name="isAdministrator">
                <Radio value={true}>Admin</Radio>
                <Radio value={false}>User</Radio>
            </Radio.Group>
        </Antd3Radio>
    </Antd3Form.Item>
);
