import { Component } from 'react';
import { Button, Select } from 'antd';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { orderBy } from 'lodash';

import { AppState } from 'common/appState';
import { selectContractOrganisation } from 'features/contract/actions/contractActions';
import { selectBenefitContract } from 'features/benefit/actions';
import { FormFieldIds } from 'common/helpers/utils';
import {
    Antd3Form,
    Antd3Icon,
    Antd3InputProps,
    Antd3Select,
} from 'common/components/deprecated/antd3';

import { AutocompleteField, AutocompleteData, Filters } from '../models/paginationModel';
import {
    autocompleteRequest,
    getInitialValueRequest,
    clearInitialValueData,
} from '../actions/paginationActions';
import {
    getAutocompleteResults,
    getAutocompleteFetchingStatus,
    getInitialValueFetchingStatus,
    getInitialValueData,
} from '../selectors/paginationSelectors';
import { CustomOption } from './CustomOption';

const GlobalSelectOptionStyle = createGlobalStyle`
  && li.ant-select-dropdown-menu-item {
        white-space: normal;
  }
`;

const Center = styled.div`
    text-align: center;
`;

interface AutocompleteStateProps {
    results: AutocompleteData[];
    isFetchingResults: boolean;
    initialValueData?: AutocompleteData;
    isFetchingInitialValueData: boolean;
}

interface AutocompleteDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    getInitialValueRequest: typeof getInitialValueRequest;
    clearInitialValueData: typeof clearInitialValueData;
    selectContractOrganisation: typeof selectContractOrganisation;
    selectBenefitContract: typeof selectBenefitContract;
}

interface AutocompleteProps extends Antd3InputProps {
    autocompleteField: AutocompleteField;
    id: string;
    label?: string;
    placeholder?: string;
    initialValue?: number;
    isFetchingInitialValueData?: boolean;
    required?: boolean;
    notFoundContent?: string;
    getAutocompleteData?(value: AutocompleteData): void;
    multiple?: boolean;
    searchParams?: Filters;
    defaultValue?: string | string[];
    showLabel?: boolean;
    selectOnly?: boolean;
    handleSelectAllPriorities(priorities: string[]): void;
    areAllPrioritiesSelected?: boolean;
    selected: string[];
    addPriority?(priority: string | string[]): void;
    removePriority?(priority: string): void;
}

type Props = AutocompleteProps & AutocompleteStateProps & AutocompleteDispatchProps;

export class AutocompleteSelectAllComponent extends Component<Props> {
    private requestAutocomplete = (searchTerm: string = '') =>
        this.props.autocompleteRequest({
            searchTerm,
            autocompleteField: this.props.autocompleteField,
            searchParams: this.props.searchParams,
        });

    private handleSearch = (searchTerm: string) => this.requestAutocomplete(searchTerm);

    private onFocus = () => this.requestAutocomplete();

    public componentDidMount(): void {
        const { autocompleteField, initialValue: id } = this.props;

        this.props.clearInitialValueData(autocompleteField);

        if (id) {
            this.props.getInitialValueRequest({
                autocompleteField,
                id,
            });
        }

        this.requestAutocomplete();
    }

    public setOptionValue = (
        fieldId: string,
        value: string,
        key: string,
        referenceNumber?: string,
    ) => {
        switch (fieldId) {
            case FormFieldIds.Title:
            case FormFieldIds.ContractTitle:
                return value;
            case FormFieldIds.ReferenceNumber:
            case FormFieldIds.ContractReferenceNumber:
                return referenceNumber;
            default:
                return key;
        }
    };

    public setOptionLabel = (fieldId: string, value: string, referenceNumber?: string) => {
        switch (fieldId) {
            case FormFieldIds.ReferenceNumber:
            case FormFieldIds.ContractReferenceNumber:
                return referenceNumber;
            case FormFieldIds.Title:
                return `${value} (${referenceNumber})`;
            case FormFieldIds.Contract:
                return `${value} ${referenceNumber ? ' | ' + referenceNumber : ''}`;
            default:
                return value;
        }
    };

    public render(): JSX.Element {
        const {
            id: fieldId,
            label,
            required,
            placeholder,
            isFetchingResults,
            multiple,
            autocompleteField,
            initialValueData,
            isFetchingInitialValueData,
            results,
            notFoundContent = 'Not found',
            showLabel = true,
            handleSelectAllPriorities,
            areAllPrioritiesSelected,
            addPriority,
            removePriority,
            selected,
        } = this.props;

        const autocompleteData = initialValueData
            ? [initialValueData, ...results.filter(({ id }) => id !== initialValueData.id)]
            : results;

        const formattedData = autocompleteData.map(
            ({ id, name, title, outcome, user, referenceNumber, organisation, contract }) => {
                const employee = autocompleteField === AutocompleteField.Employee;
                const executive = autocompleteField === AutocompleteField.BenefitRoleExecutives;

                let value = '';
                if (employee && user) {
                    value = `${user.firstName} ${user.lastName}`;
                } else if (executive && user) {
                    value = user.email;
                } else {
                    value = `${name || title || outcome || ''}`;
                }

                const key = (employee || executive) && user ? user.id : id;

                return {
                    key: key.toString(),
                    value,
                    organisation,
                    contract,
                    referenceNumber,
                };
            },
        );

        const notFound = (
            <Center>{isFetchingResults ? <Antd3Icon type="loading" /> : notFoundContent}</Center>
        );

        const formattedDataAsc = orderBy(formattedData, [(o) => o.value.toLowerCase()], ['asc']);

        return (
            <Antd3Form.Item label={showLabel && (label || placeholder)} required={required}>
                <GlobalSelectOptionStyle />
                <>
                    <Antd3Select
                        showSearch
                        onSearch={this.handleSearch}
                        placeholder={placeholder || label}
                        loading={isFetchingInitialValueData}
                        allowClear
                        size="large"
                        notFoundContent={notFound}
                        filterOption={false}
                        onFocus={this.onFocus}
                        mode={multiple ? 'multiple' : 'default'}
                        onSelect={addPriority}
                        onDeselect={removePriority}
                        onChange={removePriority}
                        defaultValue={selected}
                        value={selected}
                    >
                        {formattedDataAsc.map(
                            ({ key, value, referenceNumber, organisation, contract }) => (
                                <Select.Option
                                    key={key}
                                    value={this.setOptionValue(
                                        fieldId,
                                        value,
                                        key,
                                        referenceNumber,
                                    )}
                                >
                                    <CustomOption organisation={organisation} contract={contract}>
                                        {this.setOptionLabel(fieldId, value, referenceNumber)}
                                    </CustomOption>
                                </Select.Option>
                            ),
                        )}
                    </Antd3Select>
                    <Button
                        onClick={() => {
                            handleSelectAllPriorities(formattedData.map((a) => a.key));
                        }}
                    >
                        {areAllPrioritiesSelected ? 'Remove all' : 'Select all'}
                    </Button>
                </>
            </Antd3Form.Item>
        );
    }
}

const mapStateToProps = (
    state: AppState,
    { autocompleteField }: AutocompleteProps,
): AutocompleteStateProps => ({
    results: getAutocompleteResults(state, autocompleteField),
    isFetchingResults: getAutocompleteFetchingStatus(state, autocompleteField),
    initialValueData: getInitialValueData(state, autocompleteField),
    isFetchingInitialValueData: getInitialValueFetchingStatus(state, autocompleteField),
});

export const AutocompleteSelectAll = connect(mapStateToProps, {
    autocompleteRequest,
    getInitialValueRequest,
    clearInitialValueData,
    selectContractOrganisation,
    selectBenefitContract,
})(AutocompleteSelectAllComponent);
