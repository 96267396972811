import { inject, injectable } from 'inversify';

import { HttpService } from 'common/services/HttpService';
import { SERVICE } from 'config/identifiers';
import { config } from 'config/config';

import { Organisation } from '../models/organisationModels';

@injectable()
export class OrganisationService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    // unused since CFS-291
    // public editOrganisationName(name: string): Promise<void> {
    //     return this.http.PATCH(config.routeConfig.myOrganisation.slice(0, -1), { name });
    // }

    public getOrganisation(id: number): Promise<Organisation> {
        return this.http.GET<Organisation>(`${config.routeConfig.organisations}${id}/`);
    }

    public editOrganisation(data: Partial<Organisation>): Promise<Organisation> {
        return this.http.PATCH<Organisation>(
            `${config.routeConfig.organisations}${data.id}/`,
            data,
        );
    }

    public getExecutives(): Promise<Organisation[]> {
        return this.http.GET<Organisation[]>(`${config.routeConfig.executives}`);
    }

    public getPotentialExecutives(): Promise<Organisation[]> {
        return this.http.GET<Organisation[]>(config.routeConfig.potentialExecutives);
    }

    public getAllExecutives(): Promise<Organisation[]> {
        return this.http.GET<Organisation[]>(config.routeConfig.executives);
    }

    public removeExecutive(id: number): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.executives}${id}/`);
    }

    public checkOrganisationExists(id: string): Promise<any> {
        return this.http.GET(
            `${
                config.routeConfig.organisations
            }reference-number-check/?reference_number=${id.toUpperCase()}`,
            undefined,
            undefined,
            true,
        );
    }
}
