import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { isTenant } from 'features/user/selectors/userSelectors';

import { CheckScores as CheckScoresComponent } from '../components/CheckScores';
import { CheckBenefit } from '../models/checkModel';
import { getCheckScores } from '../selectors/checkSelectors';

export interface CheckScoresStateProps {
    checkScores: CheckBenefit[];
    filter?: PaginationFilters;
    isTenant: boolean;
}

interface CheckScoresContainerDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    redirectToUrl: typeof redirectToUrl;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
}

export type CheckScoresProps = CheckScoresStateProps & CheckScoresContainerDispatchProps;

class CheckScores extends React.Component<CheckScoresProps> {
    public render(): JSX.Element {
        return <CheckScoresComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState): CheckScoresStateProps => ({
    checkScores: getCheckScores(state),
    filter: getFilterParams(state, PaginationView.CheckScores),
    isTenant: isTenant(state),
});

export const CheckScoresContainer = connect(mapStateToProps, {
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
})(CheckScores);
