import { Button, Form, Tag } from 'antd';
import { useDispatch } from 'react-redux';

import { actionPropsByAction } from 'features/upload/helpers';
import { BenefitChangeSuggestionsAcceptanceFormBenefitPriority } from 'features/upload/models/uploadModels';
import { addBenefitPriorityRequest, editBenefitPriorityRequest } from 'features/benefit/actions';
import * as S from 'features/upload/components/BenefitChangeSuggestionsFormItem/shared/styles';
import { AutocompleteSelect } from 'common/components';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { FormFieldIds } from 'common/helpers/utils';
import { useEffect, useState } from 'react';
import { HttpService } from 'common/services/HttpService';
import { generateAutocompleteEndpoint } from 'config/config';
import { useParams } from 'react-router-dom';

type Props = {
    name: string;
    contractId?: number;
    benefitId?: number;
    benefitLocation?: number;
};

export const BenefitLocationSuggestionAcceptanceCard = ({ name, benefitLocation }: Props) => {
    const [locations, setLocations] = useState([]);
    const [httpService] = useState(new HttpService());

    useEffect(() => {
        getLocationNames();
    }, []);

    const getLocationNames = async () => {
        const locationsPath = generateAutocompleteEndpoint(AutocompleteField.Location);
        if (!locationsPath) {
            return;
        }
        const { results }: any = await httpService.GET(locationsPath, {
            limit: 50,
            offset: 0,
        });
        setLocations(results);
    };

    const prevLocation: any = locations.find((location: any) => location.id === benefitLocation);

    const locationSuggestion = Form.useWatch('benefitLocation');

    const isUpdated = locationSuggestion !== benefitLocation;

    const action = isUpdated && !benefitLocation ? 'create' : 'update';

    const actionProps = actionPropsByAction[action];

    return (
        <S.SuggestionWrapper>
            <S.ChangeInfo>
                {isUpdated && (
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {
                            <span>
                                {action === 'update' &&
                                    `, Current Benefit Location: "${prevLocation?.name}"`}
                            </span>
                        }
                    </Tag>
                )}
            </S.ChangeInfo>
            <S.InputsWrapper direction="horizontal" align="start">
                <AutocompleteSelect
                    name={name}
                    label={'Benefit Location'}
                    autocompleteField={AutocompleteField.Location}
                    id={FormFieldIds.Location}
                    notFoundContent="No locations found"
                    required={false}
                    disabled
                />
            </S.InputsWrapper>
        </S.SuggestionWrapper>
    );
};
