import { TablePaginationConfig, Button, Table as AntdTable, Select, Space, Input } from 'antd';
import styled from 'styled-components';
import React, { useState } from 'react';

import { iconSizes, colors } from 'theme/variables';
import { ButtonWrapper } from 'common/components/styled';
import { Antd3Form, Antd3Icon } from 'common/components/deprecated/antd3';
import { ArchivedFilterOptions } from 'common/helpers/utils';

import {
    PortfolioFilter,
    PortfolioFiltersEnum,
    PortfolioFilterRowData,
} from '../models/portfolioFilterModel';

const TableHeader = styled(Space)`
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.5rem;
`;

interface AddIconProps {
    cancreate: number;
}

const AddIcon = styled(Antd3Icon)`
    && {
        height: ${iconSizes.large};
        width: ${iconSizes.large};
        font-size: ${iconSizes.large};
    }

    svg {
        fill: ${({ cancreate }: AddIconProps) => (cancreate ? colors.primary : colors.disabled)};
    }
`;

const TableWrapper = styled.div`
    flex: 1 1 auto;
    position: relative;
    margin-bottom: 10px;
`;

const Table = styled(AntdTable)`
    .ant-table-title {
        font-weight: bold;
    }
`;

interface RowNameProps {
    isSelected: boolean;
}

const RowName = styled.div`
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    color: ${({ isSelected }: RowNameProps) => (isSelected ? colors.primary : colors.default)};
`;

type EditPortfolioFilterType = (
    portfolioFilterData: PortfolioFilterRowData,
    depth: PortfolioFiltersEnum,
) => void;

const portfolioFiltersColumns = (
    archivePortfolioFilter: EditPortfolioFilterType,
    deletePortfolioFilter: EditPortfolioFilterType,
    openEditModal: EditPortfolioFilterType,
    depth: PortfolioFiltersEnum,
    selectedId: number,
    onSelect?: (id: number) => void,
) => [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name: string, { id }: PortfolioFilter) => (
            <RowName isSelected={selectedId === id} onClick={() => onSelect && onSelect(id)}>
                {name}
            </RowName>
        ),
    },
    {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (id: number, { archived, name }: PortfolioFilter) => {
            const buttonText = archived ? 'Unarchive' : 'Archive';

            return (
                <ButtonWrapper>
                    <Button
                        onClick={() =>
                            openEditModal && openEditModal({ id, name, archived }, depth)
                        }
                        size="small"
                        icon={<Antd3Icon type="edit" />}
                    >
                        Edit
                    </Button>
                    <Button
                        onClick={() => {
                            archivePortfolioFilter({ id, name, archived }, depth);
                        }}
                        type={archived ? 'default' : 'danger'}
                        icon={<Antd3Icon type={archived ? 'undo' : 'folder'} />}
                        size="small"
                    >
                        {buttonText}
                    </Button>
                    <Button
                        onClick={() => {
                            deletePortfolioFilter({ id, name }, depth);
                        }}
                        type="danger"
                        icon={<Antd3Icon type="folder" />}
                        size="small"
                    >
                        Remove
                    </Button>
                </ButtonWrapper>
            );
        },
        width: 300,
    },
];

interface PortfolioFiltersTableInterface {
    title: string;
    portfolioFilters: PortfolioFilter[];
    depth: PortfolioFiltersEnum;
    canCreate: boolean;
    selectedId: number;
    onSelect?: (id: number) => void;
    openEditModal: EditPortfolioFilterType;
    openCreateModal: (depth: PortfolioFiltersEnum) => void;
    archivePortfolioFilter: EditPortfolioFilterType;
    pageNumber?: number;
    handlePageChange?: (currentPage: TablePaginationConfig) => void;
    withPaginationHistory?: boolean;
    deletePortfolioFilter: EditPortfolioFilterType;
}

export const PortfolioFiltersTable = ({
    portfolioFilters,
    title,
    onSelect,
    openEditModal,
    openCreateModal,
    archivePortfolioFilter,
    deletePortfolioFilter,
    depth,
    selectedId,
    canCreate,
    pageNumber,
    handlePageChange,
    withPaginationHistory,
}: PortfolioFiltersTableInterface) => {
    const [archivedFilter, setArchivedFilter] = useState(ArchivedFilterOptions.Unarchived);
    const [nameFilter, setNameFilter] = useState('');

    const handleOnArchivedFilterChange = (value: ArchivedFilterOptions) => setArchivedFilter(value);
    const handleNameFilterChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setNameFilter(e.target.value);

    const getFilteredPortfolioFilters = () => {
        let filteredPortfolioFilters =
            archivedFilter === ArchivedFilterOptions.All
                ? portfolioFilters
                : portfolioFilters.filter((portfolioFilter) =>
                      archivedFilter === ArchivedFilterOptions.Archived
                          ? portfolioFilter.archived
                          : !portfolioFilter.archived,
                  );

        if (nameFilter?.length) {
            filteredPortfolioFilters = filteredPortfolioFilters.filter((portfolioFilter) =>
                portfolioFilter.name.includes(nameFilter),
            );
        }

        return filteredPortfolioFilters;
    };

    return (
        <TableWrapper>
            <Antd3Form>
                <Antd3Form.Item>
                    <TableWrapper>
                        <TableHeader>
                            <h3>{title}</h3>
                            <Space align="center">
                                <Input
                                    onChange={handleNameFilterChange}
                                    placeholder="Filter name..."
                                    allowClear
                                />
                                <Select
                                    value={archivedFilter}
                                    onChange={handleOnArchivedFilterChange}
                                    allowClear
                                    dropdownMatchSelectWidth={false}
                                >
                                    {(
                                        Object.keys(
                                            ArchivedFilterOptions,
                                        ) as (keyof typeof ArchivedFilterOptions)[]
                                    ).map((name) => (
                                        <Select.Option
                                            key={name}
                                            value={ArchivedFilterOptions[name]}
                                        >
                                            {name}
                                        </Select.Option>
                                    ))}
                                </Select>
                                <AddIcon
                                    onClick={() => canCreate && openCreateModal(depth)}
                                    type="plus-circle"
                                    cancreate={canCreate ? 1 : 0}
                                />
                            </Space>
                        </TableHeader>
                        <Table
                            rowKey="id"
                            pagination={{
                                pageSize: 10,
                                ...(withPaginationHistory && { current: pageNumber || 1 }),
                                showSizeChanger: false,
                            }}
                            onChange={handlePageChange}
                            columns={portfolioFiltersColumns(
                                archivePortfolioFilter,
                                deletePortfolioFilter,
                                openEditModal,
                                depth,
                                selectedId,
                                onSelect,
                            )}
                            dataSource={getFilteredPortfolioFilters()}
                        />
                    </TableWrapper>
                </Antd3Form.Item>
            </Antd3Form>
        </TableWrapper>
    );
};
