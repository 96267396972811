import { Action } from 'common/actions/appAction';

import { CheckBenefit, CheckActiveBenefit } from '../models/checkModel';

export const GET_CHECK_EVIDENCES_BENEFITS_SUCCEED = 'GET_CHECK_EVIDENCES_BENEFITS_SUCCEED';
export type GetCheckEvidencesBenefitsSuccessAction = Action<
    typeof GET_CHECK_EVIDENCES_BENEFITS_SUCCEED,
    { checkEvidences: CheckBenefit[] }
>;
export const getCheckEvidencesBenefitsSuccess = (
    checkEvidences: CheckBenefit[],
): GetCheckEvidencesBenefitsSuccessAction => ({
    type: GET_CHECK_EVIDENCES_BENEFITS_SUCCEED,
    payload: { checkEvidences },
});

export const GET_CHECK_ACTIVE_BENEFITS_SUCCEED = 'GET_CHECK_ACTIVE_BENEFITS_SUCCEED';
export type GetCheckActiveBenefitsSuccessAction = Action<
    typeof GET_CHECK_ACTIVE_BENEFITS_SUCCEED,
    { checkActiveBenefits: CheckActiveBenefit[] }
>;
export const getCheckActiveBenefitsSuccess = (
    checkActiveBenefits: CheckActiveBenefit[],
): GetCheckActiveBenefitsSuccessAction => ({
    type: GET_CHECK_ACTIVE_BENEFITS_SUCCEED,
    payload: { checkActiveBenefits },
});

export const GET_CHECK_SCORES_BENEFITS_SUCCEED = 'GET_CHECK_SCORES_BENEFITS_SUCCEED';
export type GetCheckScoresBenefitsSuccessAction = Action<
    typeof GET_CHECK_SCORES_BENEFITS_SUCCEED,
    { checkScores: CheckBenefit[] }
>;
export const getCheckScoresBenefitsSuccess = (
    checkScores: CheckBenefit[],
): GetCheckScoresBenefitsSuccessAction => ({
    type: GET_CHECK_SCORES_BENEFITS_SUCCEED,
    payload: { checkScores },
});

export type CheckAction =
    | GetCheckEvidencesBenefitsSuccessAction
    | GetCheckActiveBenefitsSuccessAction
    | GetCheckScoresBenefitsSuccessAction;
