import * as React from 'react';
import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import { ButtonType } from 'antd/lib/button/button';

import { fontSizes } from 'theme/variables';

import { Antd3Icon } from './deprecated/antd3';

interface ButtonData {
    name: string;
    type: ButtonType;
    icon?: string;
    onClick(e: React.MouseEvent): void;
    ghost?: boolean;
}

interface ListItemProps extends ButtonProps {
    text: string;
    buttons?: ButtonData[];
}

interface ButtonProps {
    header?: string;
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

// `any` omits antd button type problems
const Button = styled<ButtonProps & ButtonProps & any>(AntdButton)`
    &&& {
        height: ${fontSizes.large};
        line-height: ${fontSizes.large};
        padding: 0 4px;
    }
    ${(props) => !props.header && 'transform: translateX(20px)'};
`;

export const ListItem: React.FunctionComponent<ListItemProps> = ({ text, buttons, header }) => (
    <Wrapper>
        <span>{text}</span>
        {buttons && (
            <AntdButton.Group>
                {buttons.map(({ name, type, icon, onClick }) => (
                    <Button
                        type={type}
                        key={name}
                        icon={<Antd3Icon type={icon || name.toLowerCase()} />}
                        onClick={onClick}
                        header={header}
                    >
                        {name}
                    </Button>
                ))}
            </AntdButton.Group>
        )}
    </Wrapper>
);
