import * as React from 'react';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';
import { connect } from 'react-redux';

import { EditUploadContractNoteFile, SubmitButton } from 'common/components';
import {
    editBenefitNoteRequest,
    deleteBenefitNoteFileRequest,
    addBenefitNoteFileRequest,
} from 'features/benefit/actions/benefitActions';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { BenefitNoteData } from 'features/benefit/models/benefitModels';
import { Antd3Form, Antd3FormProps, StyledAntd3Form } from 'common/components/deprecated/antd3';
import { ResetButton, TextArea } from 'common/components/deprecated/form';

interface EditBenefitNoteFormProps extends Antd3FormProps {
    contractId: number;
    benefitId: number;
    benefitNote: BenefitNoteData;
}

interface EditBenefitNoteDispatchProps {
    editBenefitNoteRequest: typeof editBenefitNoteRequest;
    deleteBenefitNoteFileRequest: typeof deleteBenefitNoteFileRequest;
    addBenefitNoteFileRequest: typeof addBenefitNoteFileRequest;
}

type Props = EditBenefitNoteFormProps & EditBenefitNoteDispatchProps;

export class EditBenefitNoteForm extends React.Component<Props> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { ...values }) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }

            return this.props.editBenefitNoteRequest({
                ...values,
                contract: this.props.contractId,
                benefit: this.props.benefitId,
                id: this.props.benefitNote.id,
            });
        });
    };

    private addFile = (file: IUploadFile) => {
        this.props.addBenefitNoteFileRequest({
            note: this.props.benefitNote.id,
            file,
            contract: this.props.contractId,
            benefit: this.props.benefitId,
        });
    };

    private removeFile = (id: number) => {
        this.props.deleteBenefitNoteFileRequest({
            id,
            contract: this.props.contractId,
            benefit: this.props.benefitId,
        });
    };

    public render(): JSX.Element {
        const { benefitNote, form } = this.props;

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit}>
                <ResetButton form={form} />
                <TextArea
                    form={form}
                    id="note"
                    placeholder="Benefit Note"
                    initialValue={benefitNote.note}
                    required={false}
                    rows={4}
                />
                <EditUploadContractNoteFile
                    form={form}
                    id="benefitNoteFile"
                    addFile={this.addFile}
                    removeFile={this.removeFile}
                    uploadedFiles={benefitNote.files}
                    description={'Private Benefit Note File'}
                />
                <SubmitButton value="Save" />
            </StyledAntd3Form>
        );
    }
}

export const EditBenefitNote = connect(null, {
    editBenefitNoteRequest,
    deleteBenefitNoteFileRequest,
    addBenefitNoteFileRequest,
})(Antd3Form.create({})(EditBenefitNoteForm));
