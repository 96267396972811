import { Action } from 'common/actions/appAction';
import { CreateAccountCredentials } from 'features/user/models/userModel';

import { Credentials } from '../models/credentialsModel';
import { NewUser } from '../models/newUserModel';

export const ACTIVATE_ACCOUNT_REQUESTED = 'ACTIVATE_ACCOUNT_REQUESTED';
export type ActivateAccountRequestAction = Action<
    typeof ACTIVATE_ACCOUNT_REQUESTED,
    CreateAccountCredentials
>;
export const activateAccountRequest = (
    credentials: CreateAccountCredentials,
): ActivateAccountRequestAction => ({
    type: ACTIVATE_ACCOUNT_REQUESTED,
    payload: credentials,
});

export const ACTIVATE_ACCOUNT_SUCCEED = 'ACTIVATE_ACCOUNT_SUCCEED';
export type ActivateAccountSuccessAction = Action<
    typeof ACTIVATE_ACCOUNT_SUCCEED,
    CreateAccountCredentials,
    string
>;
export const activateAccountSuccess = (
    credentials: CreateAccountCredentials,
): ActivateAccountSuccessAction => ({
    type: ACTIVATE_ACCOUNT_SUCCEED,
    payload: credentials,
    meta: `${
        credentials.isAuthenticated
            ? 'Joined organisation.'
            : ' Your account has been created. You can now log in.'
    }`,
});

export const ACTIVATE_ACCOUNT_FAILED = 'ACTIVATE_ACCOUNT_FAILED';
export type ActivateAccountFailureAction = Action<typeof ACTIVATE_ACCOUNT_FAILED, string>;
export const activateAccountFailure = (payload: string): ActivateAccountFailureAction => ({
    type: ACTIVATE_ACCOUNT_FAILED,
    payload,
});

export const ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_REQUESTED =
    'ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_REQUESTED';
export type ActivateExecutiveAdminAccountRequestAction = Action<
    typeof ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_REQUESTED,
    CreateAccountCredentials
>;
export const activateExecutiveAdminAccountRequest = (
    credentials: CreateAccountCredentials,
): ActivateExecutiveAdminAccountRequestAction => ({
    type: ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_REQUESTED,
    payload: credentials,
});

export const ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_SUCCEED = 'ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_SUCCEED';
export type ActivateExecutiveAdminAccountSuccessAction = Action<
    typeof ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_SUCCEED,
    CreateAccountCredentials,
    string
>;
export const activateExecutiveAdminAccountSuccess = (
    credentials: CreateAccountCredentials,
): ActivateExecutiveAdminAccountSuccessAction => ({
    type: ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_SUCCEED,
    payload: credentials,
    meta: 'Your account has been created. You can now log in.',
});

export const ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_FAILED = 'ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_FAILED';
export type ActivateExecutiveAdminAccountFailureAction = Action<
    typeof ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_FAILED,
    string
>;
export const activateExecutiveAdminAccountFailure = (
    payload: string,
): ActivateExecutiveAdminAccountFailureAction => ({
    type: ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_FAILED,
    payload,
});

export const ACTIVATE_EXISTING_EXECUTIVE_ADMIN_REQUESTED =
    'ACTIVATE_EXISTING_EXECUTIVE_ADMIN_REQUESTED';
export type ActivateExistingExecutiveAdminRequestAction = Action<
    typeof ACTIVATE_EXISTING_EXECUTIVE_ADMIN_REQUESTED
>;
export const activateExistingExecutiveAdminRequest =
    (): ActivateExistingExecutiveAdminRequestAction => ({
        type: ACTIVATE_EXISTING_EXECUTIVE_ADMIN_REQUESTED,
    });

export const ACTIVATE_EXISTING_EXECUTIVE_ADMIN_SUCCEED =
    'ACTIVATE_EXISTING_EXECUTIVE_ADMIN_SUCCEED';
export type ActivateExistingExecutiveAdminSuccessAction = Action<
    typeof ACTIVATE_EXISTING_EXECUTIVE_ADMIN_SUCCEED,
    null,
    string
>;
export const activateExistingExecutiveAdminSuccess =
    (): ActivateExistingExecutiveAdminSuccessAction => ({
        type: ACTIVATE_EXISTING_EXECUTIVE_ADMIN_SUCCEED,
        meta: 'Invitation accepted.',
    });

export const ACTIVATE_EXISTING_EXECUTIVE_ADMIN_FAILED = 'ACTIVATE_EXISTING_EXECUTIVE_ADMIN_FAILED';
export type ActivateExistingExecutiveAdminFailureAction = Action<
    typeof ACTIVATE_EXISTING_EXECUTIVE_ADMIN_FAILED,
    string,
    string
>;
export const activateExistingExecutiveAdminFailure = (
    payload: string,
): ActivateExistingExecutiveAdminFailureAction => ({
    type: ACTIVATE_EXISTING_EXECUTIVE_ADMIN_FAILED,
    payload,
    meta: 'Error accepting invitation.',
});

export const LOG_IN_REQUESTED = 'LOG_IN_REQUESTED';
export type LogInRequestAction = Action<typeof LOG_IN_REQUESTED, Credentials>;
export const logInRequest = (credentials: Credentials): LogInRequestAction => ({
    type: LOG_IN_REQUESTED,
    payload: { ...credentials },
});

export const LOG_IN_SUCCEED = 'LOG_IN_SUCCEED';
export type LogInSuccessAction = Action<typeof LOG_IN_SUCCEED>;
export const logInSuccess = (): LogInSuccessAction => ({
    type: LOG_IN_SUCCEED,
});

export const LOG_IN_FAILED = 'LOG_IN_FAILED';
export type LogInFailureAction = Action<typeof LOG_IN_FAILED, string>;
export const logInFailure = (payload: string): LogInFailureAction => ({
    type: LOG_IN_FAILED,
    payload,
});

export const LOG_IN_COMPLETE = 'LOG_IN_COMPLETE';
export type LogInCompleteAction = Action<typeof LOG_IN_COMPLETE>;
export const logInComplete = (): LogInCompleteAction => ({
    type: LOG_IN_COMPLETE,
});

export const LOG_OUT_REQUESTED = 'LOG_OUT_REQUESTED';
export type LogOutRequestAction = Action<typeof LOG_OUT_REQUESTED>;
export const logOutRequest = (): LogOutRequestAction => ({
    type: LOG_OUT_REQUESTED,
});

export const LOG_OUT_SUCCEED = 'LOG_OUT_SUCCEED';
export type LogOutSuccessAction = Action<typeof LOG_OUT_SUCCEED>;
export const logOutSuccess = (): LogOutSuccessAction => ({
    type: LOG_OUT_SUCCEED,
});

export const REFRESH_TOKEN_REQUESTED = 'REFRESH_TOKEN_REQUESTED';
export type RefreshTokenRequestAction = Action<typeof REFRESH_TOKEN_REQUESTED>;
export const refreshTokenRequest = (): RefreshTokenRequestAction => ({
    type: REFRESH_TOKEN_REQUESTED,
});

export const REFRESH_TOKEN_SUCCEED = 'REFRESH_TOKEN_SUCCEED';
export type RefreshTokenSuccessAction = Action<typeof REFRESH_TOKEN_SUCCEED>;
export const refreshTokenSuccess = (): RefreshTokenSuccessAction => ({
    type: REFRESH_TOKEN_SUCCEED,
});

export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED';
export type RefreshTokenFailureAction = Action<typeof REFRESH_TOKEN_FAILED, string>;
export const refreshTokenFailure = (payload: string): RefreshTokenFailureAction => ({
    type: REFRESH_TOKEN_FAILED,
    payload,
});

export const VERIFY_NEW_EMPLOYEE_REQUESTED = 'VERIFY_NEW_EMPLOYEE_REQUESTED';
export type VerifyNewEmployeeRequestAction = Action<typeof VERIFY_NEW_EMPLOYEE_REQUESTED, string>;
export const verifyNewEmployeeRequest = (token: string): VerifyNewEmployeeRequestAction => ({
    type: VERIFY_NEW_EMPLOYEE_REQUESTED,
    payload: token,
});

export const VERIFY_NEW_EMPLOYEE_SUCCEED = 'VERIFY_NEW_EMPLOYEE_SUCCEED';
export type VerifyNewEmployeeSuccessAction = Action<typeof VERIFY_NEW_EMPLOYEE_SUCCEED, NewUser>;
export const verifyNewEmployeeSuccess = (data: NewUser): VerifyNewEmployeeSuccessAction => ({
    type: VERIFY_NEW_EMPLOYEE_SUCCEED,
    payload: data,
});

export const VERIFY_NEW_EMPLOYEE_FAILED = 'VERIFY_NEW_EMPLOYEE_FAILED';
export type VerifyNewEmployeeFailureAction = Action<typeof VERIFY_NEW_EMPLOYEE_FAILED, string>;
export const verifyNewEmployeeFailure = (payload: string): VerifyNewEmployeeFailureAction => ({
    type: VERIFY_NEW_EMPLOYEE_FAILED,
    payload,
});

export const VERIFY_NEW_EXECUTIVE_REQUESTED = 'VERIFY_NEW_EXECUTIVE_REQUESTED';
export type VerifyNewExecutiveRequestAction = Action<typeof VERIFY_NEW_EXECUTIVE_REQUESTED, string>;
export const verifyNewExecutiveRequest = (token: string): VerifyNewExecutiveRequestAction => ({
    type: VERIFY_NEW_EXECUTIVE_REQUESTED,
    payload: token,
});

export const VERIFY_NEW_EXECUTIVE_SUCCEED = 'VERIFY_NEW_EXECUTIVE_SUCCEED';
export type VerifyNewExecutiveSuccessAction = Action<typeof VERIFY_NEW_EXECUTIVE_SUCCEED>;
export const verifyNewExecutiveSuccess = (): VerifyNewExecutiveSuccessAction => ({
    type: VERIFY_NEW_EXECUTIVE_SUCCEED,
});

export const VERIFY_NEW_EXECUTIVE_FAILED = 'VERIFY_NEW_EXECUTIVE_FAILED';
export type VerifyNewExecutiveFailureAction = Action<typeof VERIFY_NEW_EXECUTIVE_FAILED, string>;
export const verifyNewExecutiveFailure = (payload: string): VerifyNewExecutiveFailureAction => ({
    type: VERIFY_NEW_EXECUTIVE_FAILED,
    payload,
});

export const VERIFY_EXISTING_EXECUTIVE_REQUESTED = 'VERIFY_EXISTING_EXECUTIVE_REQUESTED';
export type VerifyExistingExecutiveRequestAction = Action<
    typeof VERIFY_EXISTING_EXECUTIVE_REQUESTED,
    string
>;
export const verifyExistingExecutiveRequest = (
    token: string,
): VerifyExistingExecutiveRequestAction => ({
    type: VERIFY_EXISTING_EXECUTIVE_REQUESTED,
    payload: token,
});

export const VERIFY_EXISTING_EXECUTIVE_SUCCEED = 'VERIFY_EXISTING_EXECUTIVE_SUCCEED';
export type VerifyExistingExecutiveSuccessAction = Action<
    typeof VERIFY_EXISTING_EXECUTIVE_SUCCEED,
    string
>;
export const verifyExistingExecutiveSuccess = (
    token: string,
): VerifyExistingExecutiveSuccessAction => ({
    type: VERIFY_EXISTING_EXECUTIVE_SUCCEED,
    payload: token,
});

export const VERIFY_EXISTING_EXECUTIVE_FAILED = 'VERIFY_EXISTING_EXECUTIVE_FAILED';
export type VerifyExistingExecutiveFailureAction = Action<
    typeof VERIFY_EXISTING_EXECUTIVE_FAILED,
    string
>;
export const verifyExistingExecutiveFailure = (
    payload: string,
): VerifyExistingExecutiveFailureAction => ({
    type: VERIFY_EXISTING_EXECUTIVE_FAILED,
    payload,
});

export type AuthAction =
    | ActivateAccountRequestAction
    | ActivateAccountSuccessAction
    | ActivateAccountFailureAction
    | ActivateExecutiveAdminAccountRequestAction
    | ActivateExecutiveAdminAccountSuccessAction
    | ActivateExecutiveAdminAccountFailureAction
    | ActivateExistingExecutiveAdminRequestAction
    | ActivateExistingExecutiveAdminSuccessAction
    | ActivateExistingExecutiveAdminFailureAction
    | LogInRequestAction
    | LogInSuccessAction
    | LogInFailureAction
    | LogInCompleteAction
    | LogOutRequestAction
    | LogOutSuccessAction
    | RefreshTokenRequestAction
    | RefreshTokenSuccessAction
    | RefreshTokenFailureAction
    | VerifyNewEmployeeRequestAction
    | VerifyNewEmployeeSuccessAction
    | VerifyNewEmployeeFailureAction
    | VerifyNewExecutiveRequestAction
    | VerifyNewExecutiveSuccessAction
    | VerifyNewExecutiveFailureAction
    | VerifyExistingExecutiveRequestAction
    | VerifyExistingExecutiveSuccessAction
    | VerifyExistingExecutiveFailureAction;
