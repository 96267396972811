import * as React from 'react';
import { Button, Card, Divider, Space } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';

import { DateFormat } from 'config/variables';
import { ApprovalStatus } from 'features/upload/components/ApprovalStatus';
import { Decline } from 'features/upload/components/Decline';
import { QuestionnaireAnswers } from 'features/benefit/components/Questionnaire/QuestionnaireAnswers';
import { PaddedSection } from 'common/components';
import { colors } from 'theme/variables';

import { BenefitScoreProps } from '../containers/BenefitScoreContainer';
import { FileList } from './FileList';
import { BenefitDetails } from './BenefitDetails';
import { BenefitCardHeader } from './BenefitCardHeader';
import { GdprStatus } from './GdprStatus';

const ScoreCard = styled(Card)`
    border-color: ${colors.borderGray};

    & + & {
        margin-top: 1.5rem;
    }

    .ant-card-actions > li {
        text-align: left;
    }
`;

const ButtonsWrapper = styled(Space)`
    width: 100%;
    justify-content: flex-end;
    padding: 0 1.5rem;
`;

export const BenefitScore: React.FunctionComponent<BenefitScoreProps> = ({
    approveScoreRequest,
    benefit,
    contract,
    declineScoreRequest,
    scores,
    isChangingApprovalStatus,
    benefitAssessors,
    benefitSuppliers,
    benefitPartners,
}) => {
    if (!benefit || !contract || !scores) {
        return null;
    }

    return (
        <>
            <PaddedSection grayBg bottomBorder>
                <BenefitDetails
                    contract={contract}
                    benefit={benefit}
                    benefitAssessors={benefitAssessors}
                    benefitSuppliers={benefitSuppliers}
                    benefitPartners={benefitPartners}
                />
            </PaddedSection>
            <PaddedSection>
                {scores.map(
                    ({
                        approved,
                        declined,
                        approvedAt,
                        approvedBy,
                        declinedBy,
                        declineMessage,
                        files,
                        ...score
                    }) => {
                        const createdAt = `${moment(score.createdAt).format(DateFormat.Exact)}`;

                        return (
                            <ScoreCard
                                key={score.id}
                                title={
                                    <BenefitCardHeader author={score.author} createdAt={createdAt}>
                                        <ApprovalStatus
                                            approved={approved}
                                            declined={declined}
                                            approvedAt={approvedAt}
                                            approvedBy={approvedBy}
                                            declinedBy={declinedBy}
                                            declineMessage={declineMessage}
                                        />
                                    </BenefitCardHeader>
                                }
                                actions={[
                                    <>
                                        <ButtonsWrapper size="small" align="center">
                                            <GdprStatus agreedToGdpr={score.agreedToGdpr} />
                                            <Button
                                                type="primary"
                                                onClick={() =>
                                                    approveScoreRequest({
                                                        benefitId: benefit.id,
                                                        contractId: contract.id,
                                                        scoreId: score.id,
                                                        organisationId: benefit.organisation,
                                                    })
                                                }
                                                loading={isChangingApprovalStatus}
                                                disabled={isChangingApprovalStatus || approved}
                                            >
                                                Approve Feedback
                                                {approved && <CheckOutlined />}
                                            </Button>
                                        </ButtonsWrapper>
                                        <Divider />
                                        <Decline
                                            data={{
                                                scoreId: score.id,
                                                benefitId: benefit.id,
                                                contractId: contract.id,
                                                organisationId: benefit.organisation,
                                            }}
                                            dataType="Feedback"
                                            onDecline={declineScoreRequest}
                                            loading={isChangingApprovalStatus}
                                        />
                                    </>,
                                ]}
                            >
                                <QuestionnaireAnswers
                                    questions={score.scoreQuestionnaire}
                                    answers={score.questionnaireAnswer}
                                />
                                {files?.length ? (
                                    <>
                                        <Divider />
                                        <FileList files={files} />
                                    </>
                                ) : null}
                            </ScoreCard>
                        );
                    },
                )}
            </PaddedSection>
        </>
    );
};
