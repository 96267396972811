import * as React from 'react';
import { isEmpty } from 'lodash';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Link } from 'react-router-dom';

import {
    generateColumns,
    DownloadFileFormats,
    idExtractorForFileDownload,
} from 'common/helpers/utils';
import { Contract, EditContractPaths } from 'features/contract/models/contractModels';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getAllContractsSuccess } from 'features/contract/actions/contractActions';
import { FilterData } from 'common/helpers/url';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { PaddedSection } from 'common/components';
import { EditPaths } from 'features/edit/models/editModel';

import { DownloadContractsProps as ContainerProps } from '../containers/DownloadContractsContainer';
import { DownloadAll } from '../models/downloadModel';
import { DownloadTableFiltersBar } from './DownloadTableFiltersBar';

export interface State {
    selectedContractIds: number[];
    isButtonDisabled: boolean;
    selectedAll: boolean;
    fileFormat: string;
}

interface DownloadContractsProps {
    redirect(path: string): void;
}

type Props = DownloadContractsProps & ContainerProps & Antd3FormProps;

const downloadContractsColumns = (tenant: boolean) => [
    {
        title: 'Project',
        key: 'title',
        render: (title: string, { id }: Contract) =>
            tenant ? (
                <Link to={`${EditPaths.Contract}/${id}${EditContractPaths.ContractDetails}`}>
                    {title}
                </Link>
            ) : (
                title
            ),
    },
    {
        title: 'Ref. No.',
        key: 'referenceNumber',
    },
    {
        title: 'Category',
        key: 'categoryName',
    },
    {
        title: 'Department',
        key: 'departmentName',
    },
];

class DownloadContractsForm extends React.Component<Props, State> {
    public state: State = {
        selectedContractIds: [],
        isButtonDisabled: false,
        selectedAll: false,
        fileFormat: DownloadFileFormats.XLSX,
    };

    public componentDidMount(): void {
        this.setState({ isButtonDisabled: isEmpty(this.state.selectedContractIds) });
    }

    private handleSubmit = ({ downloadAll = false }: DownloadAll = {}): void => {
        const contractIdsForRequest = idExtractorForFileDownload(
            this.props.contracts,
            downloadAll,
            this.state.selectedContractIds,
        );

        this.props.getContractsCSVRequest({
            ids: contractIdsForRequest,
            fileFormat: this.state.fileFormat,
            downloadAll,
        });
    };

    private downloadContracts = (event: React.FormEvent): void => {
        event.preventDefault();

        this.handleSubmit();
    };

    private downloadAllContracts = (event: React.FormEvent): void => {
        event.preventDefault();

        this.handleSubmit({ downloadAll: true });
    };

    private setFileFormat = (FileFormatChangeEvent: RadioChangeEvent) =>
        this.setState({ fileFormat: FileFormatChangeEvent.target.value });

    private onSelectChange = (selectedContractIds: number[]) =>
        this.setState({ selectedContractIds });

    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.DownloadContracts,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getAllContractsSuccess,
        });

    private saveContractFilters = (contractTitle: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.DownloadContracts,
            values: {
                title: contractTitle,
            },
        });

    private searchByContracts = (contractTitle: string) =>
        this.saveContractFilters(contractTitle) && this.refreshTable();

    private onRemoveContractAutocomplete = () =>
        this.saveContractFilters(undefined) && this.refreshTable();

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.DownloadContracts) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            title: '',
        });

    public render(): JSX.Element {
        const selectedCount = this.state.selectedContractIds.length;

        const filter = this.props.filter;

        return (
            <PaddedSection header="Download Projects">
                <DownloadTableFiltersBar
                    contracts
                    downloadAll={this.downloadAllContracts}
                    downloadSelected={this.downloadContracts}
                    setFileFormat={this.setFileFormat}
                    fileFormat={this.state.fileFormat}
                    selectedCount={selectedCount}
                    isFetchingCSV={this.props.isFetchingCSV}
                    filterId={PaginationView.DownloadContracts}
                    searchByContractTitle={this.searchByContracts}
                    onRemoveContractTitleAutocomplete={this.onRemoveContractAutocomplete}
                    clearFilters={this.clearFilters}
                    form={this.props.form}
                    filter={filter}
                    isTenant={this.props.tenant}
                />
                <PaginatedTable
                    data={this.props.contracts}
                    columns={generateColumns(downloadContractsColumns(this.props.tenant))}
                    emptyText="No projects found"
                    view={PaginationView.DownloadContracts}
                    paginationSuccessCallback={getAllContractsSuccess}
                    rowSelection={{
                        selectedRowKeys: this.state.selectedContractIds,
                        onChange: this.onSelectChange,
                    }}
                />
            </PaddedSection>
        );
    }
}

export const DownloadContracts = Antd3Form.create({})(DownloadContractsForm);
