import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { redirectToUrl } from 'common/actions/navigationAction';
import { isLoadingRole, isTenant, isAdmin } from 'features/user/selectors/userSelectors';
import { saveFilters, clearFilters } from 'features/pagination/actions/paginationActions';
import {
    getBenefitTemplatesWithRag,
    getDateRangeBenefitTemplateDashboard,
} from 'features/benefit/selectors/benefitSelectors';
import { BenefitTemplateWithRag } from 'features/benefit/models/benefitModels';

import { BenefitTemplateDashboardComponent } from '../components/BenefitTemplateDashboard';
import moment from 'moment';
import { PaginationView } from 'features/pagination/models/paginationModel';

interface BenefitTemplateDashboardContainerStateProps {
    loading: boolean;
    isUserTenant: boolean;
    isUserAdmin: boolean;
    benefitTemplates: BenefitTemplateWithRag[];
    dateRange: string[];
}

interface BenefitTemplateDashboardContainerDispatchProps {
    redirectToUrl: typeof redirectToUrl;
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
    getDateRangeBenefitTemplateDashboard: typeof getDateRangeBenefitTemplateDashboard;
}

export type BenefitTemplateDashboardContainerProps = BenefitTemplateDashboardContainerStateProps &
    BenefitTemplateDashboardContainerDispatchProps;

export class BenefitTemplateDashboard extends React.Component<BenefitTemplateDashboardContainerProps> {
    constructor(props: BenefitTemplateDashboardContainerProps) {
        super(props);
        const today = moment().format();
        const threeMonthsAgo = moment().subtract(3, 'months').format();
        this.props.saveFilters({
            paginatedView: PaginationView.BenefitTemplateRAGDashboard,
            values: {
                contractDateRangeAfter:
                    this.props.dateRange.length > 0
                        ? this.props.dateRange[0] == null
                            ? undefined
                            : this.props.dateRange[0]
                        : threeMonthsAgo,
                contractDateRangeBefore:
                    this.props.dateRange.length > 0
                        ? this.props.dateRange[1] == null
                            ? undefined
                            : this.props.dateRange[1]
                        : today,
            },
            path: '/dashboard/benefits',
        });
    }

    public render(): JSX.Element {
        return (
            <BenefitTemplateDashboardComponent
                {...this.props}
                redirect={this.props.redirectToUrl}
            />
        );
    }
}

const mapStateToProps = createSelector(
    isLoadingRole,
    isAdmin,
    isTenant,
    getBenefitTemplatesWithRag,
    getDateRangeBenefitTemplateDashboard,
    (
        loading,
        isUserAdmin,
        isUserTenant,
        benefitTemplates,
        dateRange,
    ): BenefitTemplateDashboardContainerStateProps => ({
        loading,
        isUserAdmin,
        isUserTenant,
        benefitTemplates,
        dateRange,
    }),
);

export const BenefitTemplateDashboardContainer = connect(mapStateToProps, {
    redirectToUrl,
    saveFilters,
    clearFilters,
})(BenefitTemplateDashboard);
