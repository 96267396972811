import * as React from 'react';
import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';

import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { FormFieldIds } from 'common/helpers/utils';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { PaginationRequestAction } from 'features/pagination/actions/paginationActions';
import { Antd3FormProps, WrappedFormUtils } from 'common/components/deprecated/antd3';
import { EditBenefitPaths } from 'features/benefit/models/benefitModels';
import { CreatePaths } from 'features/create/models/createModel';
import { TableFiltersBar } from 'common/components/TableFiltersBar';

import { EditPaths } from '../models/editModel';

type FormProps = {
    searchByBenefits?(benefitReferenceNumber: string): PaginationRequestAction;
    searchByContracts?(contractId: number): PaginationRequestAction;
    onRemoveBenefitAutocomplete?(): PaginationRequestAction;
    onRemoveContractAutocomplete?(): PaginationRequestAction;
    clearFilters?(): void;
    form: WrappedFormUtils;
    filter?: PaginationFilters;
};

type Props = FormProps & Antd3FormProps;

export const EditBenefitsTableFiltersBar: React.FunctionComponent<Props> = ({
    searchByBenefits,
    searchByContracts,
    onRemoveBenefitAutocomplete,
    onRemoveContractAutocomplete,
    clearFilters,
    form,
    filter,
}) => {
    const { pathname } = useLocation();
    const isMyView = pathname.includes(EditPaths.MyBenefits);

    return (
        <TableFiltersBar
            leftButtons={[
                <Link to={isMyView ? EditPaths.Benefits : EditPaths.MyBenefits}>
                    <Button type="primary">{isMyView ? 'All Benefits' : 'My Benefits'}</Button>
                </Link>,
                <Link to={CreatePaths.Benefit}>
                    <Button type="primary" icon={<PlusOutlined />}>
                        New Benefit
                    </Button>
                </Link>,
            ]}
            filters={[
                <AutocompleteSelect
                    id={FormFieldIds.QuickFilterContractReferenceNumber}
                    placeholder="Search by Projects"
                    form={form}
                    autocompleteField={AutocompleteField.Contract}
                    onSelect={searchByContracts}
                    onRemove={onRemoveContractAutocomplete}
                    notFoundContent="No projects found"
                    requiredMessage="Project is required"
                    showLabel={false}
                    defaultValue={filter && filter.values.contract}
                />,
                <AutocompleteSelect
                    id={FormFieldIds.QuickFilterBenefitReferenceNumber}
                    placeholder="Search by Benefits"
                    form={form}
                    autocompleteField={
                        isMyView ? AutocompleteField.MyBenefit : AutocompleteField.Benefit
                    }
                    onSelect={searchByBenefits}
                    onRemove={onRemoveBenefitAutocomplete}
                    notFoundContent="No benefits found"
                    requiredMessage="Benefit is required"
                    showLabel={false}
                    defaultValue={filter && filter.values.referenceNumber}
                />,
            ]}
            filtersButtons={[
                <Link
                    to={`${EditPaths.MyBenefits}${EditBenefitPaths.Filter}/${PaginationView.Benefits}`}
                >
                    <Button type="primary" icon={<FilterOutlined />}>
                        All Filters
                    </Button>
                </Link>,
                <Button onClick={clearFilters}>Clear Filters</Button>,
            ]}
        />
    );
};
