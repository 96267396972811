import { Empty } from 'antd';

type Props = {
    description: string;
};

export const CustomEmpty = ({ description }: Props) => (
    <Empty
        description={description}
        image={
            'data:image/svg+xml,%3Csvg%20width%3D%2264%22%20height%3D%2241%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cg%20transform%3D%22translate(0%201)%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%3Cellipse%20fill%3D%22%23F5F5F5%22%20cx%3D%2232%22%20cy%3D%2233%22%20rx%3D%2232%22%20ry%3D%227%22%2F%3E%0A%20%20%20%20%3Cg%20fill-rule%3D%22nonzero%22%20stroke%3D%22%23D9D9D9%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M55%2012.76L44.854%201.258C44.367.474%2043.656%200%2042.907%200H21.093c-.749%200-1.46.474-1.947%201.257L9%2012.761V22h46v-9.24z%22%2F%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M41.613%2015.931c0-1.605.994-2.93%202.227-2.931H55v18.137C55%2033.26%2053.68%2035%2052.05%2035h-40.1C10.32%2035%209%2033.259%209%2031.137V13h11.16c1.233%200%202.227%201.323%202.227%202.928v.022c0%201.605%201.005%202.901%202.237%202.901h14.752c1.232%200%202.237-1.308%202.237-2.913v-.007z%22%20fill%3D%22%23FAFAFA%22%2F%3E%0A%20%20%20%20%3C%2Fg%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A'
        }
        className={'ant-empty-normal'}
    />
);
