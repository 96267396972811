import * as React from 'react';
import { connect } from 'react-redux';

import { isTenant } from 'features/user/selectors/userSelectors';
import { clearFilters, saveFilters } from 'features/pagination/actions/paginationActions';

import { EditContractFilter as EditContractFilterComponent } from '../components/EditContractFilter';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { AppState } from 'common/appState';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { PaginationView } from 'features/pagination/models/paginationModel';

interface EditContractFilterStateProps {
    tenant: boolean;
    formInitial: PaginationFilters;
}

interface EditContractFilterDispatchProps {
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
}

export type EditContractFilterContainerProps = EditContractFilterStateProps &
    EditContractFilterDispatchProps;

export class EditContractFilter extends React.Component<EditContractFilterContainerProps> {
    public render(): JSX.Element {
        return <EditContractFilterComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState) => ({
    tenant: isTenant(state),
    formInitial: getFilterParams(state, PaginationView.Contracts),
});

export const EditContractFilterContainer = connect(mapStateToProps, {
    saveFilters,
    clearFilters,
})(EditContractFilter);
