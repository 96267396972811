import * as React from 'react';
import { connect } from 'react-redux';

import { FullPageSpinner } from 'common/components';
import { extractRouteBenefitId, extractRouteContractId } from 'common/selectors/utilitySelectors';
import { Benefit, BenefitOrganisationRole } from 'features/benefit/models/benefitModels';
import { Contract } from 'features/contract/models/contractModels';
import {
    getContractRequest,
    getContractRolesRequest,
} from 'features/contract/actions/contractActions';
import {
    getFetchingFiltersStatus,
    getContractByBenefitRouteId,
    isContractManager,
} from 'features/contract/selectors/contractSelectors';
import {
    getFetchingBenefitsStatus,
    getBenefitByRouteId,
    getBenefitAssessors,
    getBenefitIndividualSuppliers,
    getBenefitIndividualPartners,
} from 'features/benefit/selectors/benefitSelectors';
import {
    getContractBenefitDataRequest,
    getBenefitsRolesRequest,
} from 'features/benefit/actions/benefitActions';
import { redirectToUrl } from 'common/actions/navigationAction';
import { AppState } from 'common/appState';
import { RouteParams, routeParams } from 'common/selectors/routeSelectors';
import { Score } from 'features/upload/models/uploadModels';
import { getScores, getChangingApprovalStatus } from 'features/upload/selectors/uploadSelectors';
import { getEmployeesRequest } from 'features/user/actions/userActions';
import { Profile } from 'features/user/models/userModel';
import { getEmployees, isAdmin } from 'features/user/selectors/userSelectors';
import { getExecutivesRequest } from 'features/organisation/actions/organisationActions';
import {
    approveScoreRequest,
    declineScoreRequest,
    getScoresRequest,
} from 'features/upload/actions/scoreActions';

import { BenefitScore as BenefitScoreComponent } from '../components/BenefitScore';

interface BenefitScoreState {
    benefit: Benefit | undefined;
    contract: Contract | undefined;
}

export interface BenefitScoreStateProps extends BenefitScoreState {
    benefitId: number;
    contractId: number;
    employees: Profile[];
    isFetchingBenefits: boolean;
    isFetchingContracts: boolean;
    scores: Score[];
    isChangingApprovalStatus: boolean;
    isContractManager: boolean;
    isAdmin: boolean;
    benefitAssessors: Profile[];
    benefitSuppliers: BenefitOrganisationRole[];
    benefitPartners: BenefitOrganisationRole[];
}

interface BenefitScoreContainerDispatchProps {
    approveScoreRequest: typeof approveScoreRequest;
    declineScoreRequest: typeof declineScoreRequest;
    getContractBenefitDataRequest: typeof getContractBenefitDataRequest;
    getContractRequest: typeof getContractRequest;
    getEmployeesRequest: typeof getEmployeesRequest;
    getScoresRequest: typeof getScoresRequest;
    redirectToUrl: typeof redirectToUrl;
    getContractRolesRequest: typeof getContractRolesRequest;
    getBenefitsRolesRequest: typeof getBenefitsRolesRequest;
    getExecutivesRequest: typeof getExecutivesRequest;
}

export type BenefitScoreProps = BenefitScoreStateProps &
    BenefitScoreContainerDispatchProps &
    RouteParams;

class BenefitScore extends React.Component<BenefitScoreProps, BenefitScoreState> {
    public state: BenefitScoreState = {
        benefit: undefined,
        contract: undefined,
    };

    public componentDidMount(): void {
        const { benefitId, contractId } = this.props;

        this.props.getContractBenefitDataRequest({ benefitId, contractId });
        this.props.getContractRequest({ contractId });
        this.props.getContractRolesRequest(contractId);
        this.props.getEmployeesRequest();
        this.props.getExecutivesRequest();
        this.props.getBenefitsRolesRequest({
            benefitIds: [benefitId],
            contractId,
        });
    }

    public static getDerivedStateFromProps(
        props: BenefitScoreProps,
        state: BenefitScoreState,
    ): BenefitScoreState | null {
        if (
            !props.benefit ||
            !props.contract ||
            props.benefit.id === (state.benefit && state.benefit.id) ||
            props.contract.id === (state.contract && state.contract.id)
        ) {
            return null;
        }

        props.getScoresRequest({ benefitId: props.benefit.id, contractId: props.contract.id });

        return { benefit: props.benefit, contract: props.contract };
    }

    public render(): JSX.Element {
        return this.props.isFetchingContracts || this.props.isFetchingBenefits ? (
            <FullPageSpinner />
        ) : (
            <BenefitScoreComponent {...this.props} />
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams): BenefitScoreStateProps => ({
    benefitId: extractRouteBenefitId(state, routeParams(ownProps)),
    contractId: extractRouteContractId(state, routeParams(ownProps)),
    benefit: getBenefitByRouteId(state, routeParams(ownProps)),
    contract: getContractByBenefitRouteId(state, routeParams(ownProps)),
    employees: getEmployees(state),
    isFetchingBenefits: getFetchingBenefitsStatus(state),
    isFetchingContracts: getFetchingFiltersStatus(state),
    scores: getScores(state),
    isChangingApprovalStatus: getChangingApprovalStatus(state),
    isContractManager: isContractManager(state),
    isAdmin: isAdmin(state),
    benefitAssessors: getBenefitAssessors(state),
    benefitSuppliers: getBenefitIndividualSuppliers(state),
    benefitPartners: getBenefitIndividualPartners(state),
});

export const BenefitScoreContainer = connect(mapStateToProps, {
    approveScoreRequest,
    declineScoreRequest,
    getContractBenefitDataRequest,
    getContractRequest,
    getEmployeesRequest,
    getScoresRequest,
    redirectToUrl,
    getContractRolesRequest,
    getBenefitsRolesRequest,
    getExecutivesRequest,
})(BenefitScore);
