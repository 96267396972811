import { Button, Upload } from 'antd';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';
import styled from 'styled-components';

import { sizes } from 'theme/variables';

import { Antd3Icon, Antd3Form, Antd3FormProps } from '../deprecated/antd3';

const StyledUpload = styled(Upload)`
    button.ant-btn,
    .ant-upload {
        width: 100%;
        max-width: ${sizes.formWidth};
    }
`;

interface Props extends Antd3FormProps {
    id: string;
    description: string;
    required?: boolean;
    disabled?: boolean;
    accept?: string;
    onChange?(): void;
    addFile: (file: IUploadFile) => void;
    removeFile: (file: IUploadFile) => void;
    uploadedFiles: IUploadFile[];
}

export const UploadContractNoteFile = (props: Props) => {
    const { disabled } = props;

    const uploadProps = {
        beforeUpload: (file: IUploadFile) => {
            props.addFile(file);
            return false;
        },
        onRemove: (fileToRemove: IUploadFile) => {
            props.removeFile(fileToRemove);
        },
        fileList: props.uploadedFiles,
        disabled,
        accept: props.accept,
        multiple: false,
    };

    return (
        <Antd3Form.Item>
            <StyledUpload {...uploadProps}>
                <Button size="large" disabled={disabled} loading={disabled}>
                    <Antd3Icon type="upload" />
                    {props.description}
                </Button>
            </StyledUpload>
        </Antd3Form.Item>
    );
};
