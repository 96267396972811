import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { Input } from 'common/components';

const AnswerChoiceWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 1rem;

    > .ant-form-item {
        flex: 1;
    }
`;

const RemoveButton = styled(Button)`
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
`;

type Props = {
    name: number;
    disabled?: boolean;
    onRemove: (index: number) => void;
};

export const AnswerChoice = ({ name, disabled, onRemove }: Props) => (
    <AnswerChoiceWrapper>
        <Input name={[name, 'value']} noBottomMargin disabled={disabled} />
        <Input name={[name, 'id']} disabled hidden required={false} />
        <RemoveButton
            onClick={() => onRemove(name)}
            shape="circle"
            icon={<CloseOutlined />}
            size="small"
            disabled={disabled}
        />
    </AnswerChoiceWrapper>
);
