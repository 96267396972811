import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { changeUserPasswordRequest } from '../actions/userActions';
import { Profile } from '../models/userModel';
import { getProfile, isAdmin } from '../selectors/userSelectors';
import { ChangeUserPassword } from '../components/ChangeUserPassword';

interface StateProps {
    profile: Profile;
    isUserAdmin: boolean;
}

interface DispatchProps {
    changeUserPasswordRequest: typeof changeUserPasswordRequest;
}

export type Props = StateProps & DispatchProps;

const ChangePassword = (props: Props): JSX.Element => <ChangeUserPassword {...props} />;

const mapStateToProps = createSelector(
    getProfile,
    isAdmin,
    (profile, isUserAdmin): StateProps => ({ profile, isUserAdmin }),
);

export const ChangePasswordContainer = connect(mapStateToProps, {
    changeUserPasswordRequest,
})(ChangePassword);
