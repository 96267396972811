import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { isTenant } from 'features/user/selectors/userSelectors';

import { UploadScore as UploadScoreComponent } from '../components/UploadScore';
import { getScorableBenefits } from '../selectors/uploadSelectors';
import { UploadBenefit } from '../models/uploadModels';
import { getScorableBenefitsRequest } from '../actions/scoreActions';

interface UploadScoreStateProps {
    scorableBenefits: UploadBenefit[];
    filter?: PaginationFilters;
    isTenant: boolean;
}

interface UploadScoreDispatchProps {
    getScorableBenefitsRequest: typeof getScorableBenefitsRequest;
    autocompleteRequest: typeof autocompleteRequest;
    redirectToUrl: typeof redirectToUrl;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
}

export type UploadScoreProps = UploadScoreStateProps & UploadScoreDispatchProps;

class UploadScore extends React.Component<UploadScoreProps> {
    public render(): JSX.Element {
        return <UploadScoreComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState): UploadScoreStateProps => ({
    scorableBenefits: getScorableBenefits(state),
    filter: getFilterParams(state, PaginationView.UploadScore),
    isTenant: isTenant(state),
});

export const UploadScoreContainer = connect(mapStateToProps, {
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
})(UploadScore);
