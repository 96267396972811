import { RouteComponentProps } from 'react-router-dom';
import { Organisation } from 'features/organisation/models/organisationModels';
import { Moment } from 'moment';

export interface RouteParams {
    id: string;
}

export type EditEmployeeRouteParams = RouteComponentProps<RouteParams>;

export enum UserRole {
    Admin = 'Admin, Contract Manager View',
    Partner = 'Assessor, Supplier, Partner View',
}

export interface User extends UserData {
    telephone?: string;
}

export interface Role {
    benefit: number;
    id: number;
    organisation: number;
    role: string;
    user: number;
    email?: string;
}

export interface AutocompleteUserData {
    id: number;
    user: User;
    organisation: number;
}

interface UserRequest {
    email: string;
    firstName: string;
    lastName: string;
    telephone: string;
    role: string;
}

interface BaseProfile {
    archived: boolean;
    department: number;
    id: number;
    isAdministrator: boolean;
    isTenant: boolean;
    organisation: number;
    telephone: string;
    isOrganisationSelectModalOpen: boolean;
}

export interface Profile extends BaseProfile, UserAccountAgreementsFields {
    user: User;
}

export interface EditEmployeeProfile extends BaseProfile {
    user: Exclude<User, 'email'>;
}

interface UserData {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    username: string;
}

export interface ProfileResponse extends Profile, UserData {
    userId: number;
    departmentName: string;
}

export interface ProfileRequest {
    department: number;
    id: number;
    telephone: string;
    user: UserRequest;
    isAdministrator: boolean;
}

export interface UpdateUserData extends Partial<UserData>, UserAccountAgreementsFields {
    telephone?: string;
}

export enum UserAccountAgreements {
    FunctionalEmails = 'functionalEmailsConsent',
    NonFunctionalEmails = 'marketingEmailsConsent',
    TermsOfService = 'termsOfServiceConsent',
}

export type UserAccountAgreementsFields = {
    [UserAccountAgreements.FunctionalEmails]?: boolean;
    [UserAccountAgreements.NonFunctionalEmails]?: boolean;
    [UserAccountAgreements.TermsOfService]?: boolean;
};

export interface CreateAccountCredentials extends UserAccountAgreementsFields {
    password: string;
    userExists?: boolean;
    isAuthenticated?: boolean;
}

export interface ResetPasswordRequest {
    password: string;
    token?: string;
}

export interface ChangePasswordRequest {
    newPassword: string;
    oldPassword: string;
}

export interface ChangeEmailRequest {
    id: number;
    email: string;
    password: string;
}
export interface ChangeEmailResponse {
    email: string;
}

export interface UserOrganisation {
    idProfile: number;
    isAdministrator: boolean;
    organisation: Organisation;
}

export enum UserPaths {
    SignIn = '/sign-in',
    ResetPassword = '/reset-password',
    ConfirmNewPassword = '/confirm-new-password',
    EditProfile = '/user/update-user-data',
    Subscription = '/user/subscription',
    ChangePassword = '/user/change-password',
    ChangeEmail = '/user/change-email',
    EditConsents = '/edit-consents',
    VerifyNewAccount = '/invitation-employee',
    VerifyNewExecutive = '/invitation-executive',
    VerifyExistingExecutive = '/invitation-existing-executive',
    VerifyConsents = '/verify-consents',
}

export enum EditProfileTabs {
    UpdateData = 'Update Profile',
    Subscription = 'Subscription Details',
    ChangePassword = 'Change Password',
    ChangeEmail = 'Change Email',
    UpdateConsents = 'Update Emails Consents',
}

export interface ArchiveEmployee {
    id: number;
    archived: boolean;
}

export enum SubscriptionItemTitle {
    Micro = 'Micro',
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

export type Subscription = {
    pk: number;
    id: number;
    type: PaymentType;
    status?: string;
    tierIndex: number;
    name: string;
    itemTitle: SubscriptionItemTitle;
    price: number;
    currentUsers?: number;
    description: string;
    organisationType?: string;
    maxAvailableNumberOfUsers?: number;
    expiresAt?: string;
    paymentPeriods?: number;
    title: string;
    createdAt?: string;
    activatedAt?: string;
    nextInvoiceOn?: string;
    startingOn?: string;
};

export enum PaymentType {
    Monthly = 'Monthly',
    Annual = 'Annual',
    Yearly = 'Yearly',
    MultipleYears = 'Multiple Years',
}

export enum AddressType {
    POBOX = 'POBOX',
    STREET = 'STREET',
}

export type InvoiceData = {
    xeroId?: string;
    emailAddress: string;
    firstName: string;
    lastName: string;
    addressType: AddressType;
    city: string;
    region: string;
    postalCode: string;
    country: string;
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    addressLine4?: string;
};

export type SubscriptionPurchaseData = {
    item: number;
    type: string;
    payment_periods: number;
    starting_on: Moment;
};

export type Invoice = {
    id: number;
    type: string;
    xeroInvoiceId: string;
    invoiceNumber?: string;
    xeroBrandingThemeId?: string;
    status?: string;
    lineAmountTypes?: string;
    xeroItemId?: string;
    createdAt: string;
    issuedAt?: string;
    dueAt?: string;
    currencyRate?: number;
    amountDue?: number;
    amountPaid?: number;
    subTotal?: number;
    totalTax?: number;
    total?: number;
    subscription: number;
};
