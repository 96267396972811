import * as React from 'react';
import { Select as AntdSelect } from 'antd';
import { DefaultOptionType, OptionProps } from 'antd/lib/select';

import { InputLabelWithTooltip } from '../../form/components/InputLabelWithTooltip';
import { Antd3Form, Antd3InputProps } from '../antd3';

interface Props extends Antd3InputProps {
    options: DefaultOptionType[];
    onChange?(id: number): void;
    onSelect?(id: number): void;
    extra?: string;
    showSearch?: boolean;
    allowClear?: boolean;
    initialValue?: string | number;
}

export const filterOption = (input: string, option: React.ReactElement<OptionProps>) =>
    option.props.children &&
    option.props.children.toString().toLowerCase().includes(input.toLowerCase());

export const Select: React.FunctionComponent<Props> = ({
    form,
    id,
    requiredMessage,
    options,
    onChange,
    placeholder,
    label,
    initialValue,
    onSelect,
    extra,
    required = true,
    showSearch = true,
    disabled = false,
    allowClear = false,
    tooltip,
}) => (
    <Antd3Form.Item
        label={
            tooltip ? (
                <InputLabelWithTooltip label={label || placeholder} tooltip={tooltip} />
            ) : (
                label || placeholder
            )
        }
        extra={extra}
    >
        {form.getFieldDecorator(id, {
            initialValue,
            rules: [
                { required, message: requiredMessage || `${label || placeholder} is required` },
            ],
        })(
            <AntdSelect
                onChange={onChange}
                showSearch={showSearch}
                optionFilterProp="children"
                filterOption={filterOption}
                size="large"
                placeholder={placeholder || label}
                onSelect={onSelect}
                disabled={disabled}
                allowClear={allowClear}
            >
                {options.map((option) => {
                    const optionId = option.id ? option.id : option.value || option.name;

                    return (
                        <AntdSelect.Option key={optionId} value={optionId}>
                            {option.name || option.value}
                        </AntdSelect.Option>
                    );
                })}
            </AntdSelect>,
        )}
    </Antd3Form.Item>
);
