import { Button, Drawer, Layout } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { breakpoints, colors, fontSizes } from 'theme/variables';
import { getActiveOrganisation, getUserRole } from 'features/user/selectors/userSelectors';
import { toggleUserOrganisationSelectModal } from 'features/user/actions/userActions';
import { logOutRequest } from 'features/auth/actions/authActions';

import { MenuContent } from './MenuContent';
import { Logo } from '.';

const PageHeaderWrapper = styled(Layout.Header)`
    && {
        position: relative;
        padding: 0 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        background-color: ${colors.secondaryColor};

        ${breakpoints.small} {
            height: 64px;
        }

        ${breakpoints.medium} {
            display: none;
        }
    }

    a {
        display: flex;
        justify-content: center;
    }
`;

const StyledButton = styled(Button)`
    border-color: ${colors.secondaryColor};
    color: ${colors.primaryGray};

    &:hover {
        border-color: ${colors.secondaryColor};
    }
`;

const LogoWrapper = styled.div`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
`;

const LogoutText = styled.span`
    && {
        display: none;

        ${breakpoints.small} {
            display: inline-block;
        }
    }
`;

const StyledDrawer = styled(Drawer)`
    display: block;

    ${breakpoints.medium} {
        display: none;
    }

    .ant-drawer-header {
        background-color: ${colors.secondaryColor};
        height: 40px;
        border: none;
        padding-left: 8px;

        ${breakpoints.small} {
            height: 64px;
        }

        .ant-drawer-close {
            position: absolute;
            top: 10px;
            right: 0;
            color: ${colors.primaryGray};

            ${breakpoints.small} {
                top: 22px;
            }
        }
    }
    .ant-drawer-body {
        background-color: ${colors.secondaryColor};
        padding: 0;
    }
`;

const StyledText = styled.a`
    display: block;
    text-align: center;
    margin: 10px 0;
    color: ${colors.primary};
    font-size: ${fontSizes.small};
    overflow: auto;
    text-overflow: ellipsis;

    ${breakpoints.medium} {
        display: none;
    }
`;

type Props = {
    isAuthenticated: boolean;
};

export const MobileMenu = ({ isAuthenticated }: Props) => {
    const dispatch = useDispatch();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const userRole = useSelector(getUserRole);
    const activeOrganisation = useSelector(getActiveOrganisation);

    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

    return (
        <>
            <PageHeaderWrapper>
                {isAuthenticated && userRole && (
                    <StyledButton type="ghost" onClick={toggleDrawer} icon={<MenuOutlined />} />
                )}
                <LogoWrapper>
                    <Logo />
                </LogoWrapper>
                {isAuthenticated && (
                    <StyledButton
                        type="ghost"
                        onClick={() => dispatch(logOutRequest())}
                        icon={<LogoutOutlined />}
                    >
                        <LogoutText>Logout</LogoutText>
                    </StyledButton>
                )}
            </PageHeaderWrapper>
            {isAuthenticated && userRole && (
                <StyledDrawer
                    title={<Logo onClick={toggleDrawer} />}
                    placement="left"
                    width={300}
                    onClose={toggleDrawer}
                    visible={isDrawerOpen}
                >
                    <MenuContent onLinkClick={toggleDrawer} />
                </StyledDrawer>
            )}
            {isAuthenticated && activeOrganisation && (
                <StyledText onClick={() => dispatch(toggleUserOrganisationSelectModal(true))}>
                    {activeOrganisation.organisation.name}
                </StyledText>
            )}
        </>
    );
};
