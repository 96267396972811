import { Action } from 'common/actions/appAction';

import {
    User,
    UpdateUserData,
    CreateAccountCredentials,
    ProfileRequest,
    Profile,
    UserRole,
    UserOrganisation,
    ChangePasswordRequest,
    ResetPasswordRequest,
    ChangeEmailRequest,
    ArchiveEmployee,
    Subscription,
    InvoiceData,
    SubscriptionPurchaseData,
    Invoice,
} from '../models/userModel';

export const ARCHIVE_EMPLOYEE_REQUESTED = 'ARCHIVE_EMPLOYEE_REQUESTED';
export type ArchiveEmployeeRequestAction = Action<
    typeof ARCHIVE_EMPLOYEE_REQUESTED,
    ArchiveEmployee
>;
export const archiveEmployeeRequest = (
    id: number,
    archived: boolean,
): ArchiveEmployeeRequestAction => ({
    type: ARCHIVE_EMPLOYEE_REQUESTED,
    payload: { id, archived },
});

export const ARCHIVE_EMPLOYEE_SUCCEED = 'ARCHIVE_EMPLOYEE_SUCCEED';
export type ArchiveEmployeeSuccessAction = Action<typeof ARCHIVE_EMPLOYEE_SUCCEED, null, string>;
export const archiveEmployeeSuccess = (meta: string): ArchiveEmployeeSuccessAction => ({
    type: ARCHIVE_EMPLOYEE_SUCCEED,
    meta,
});

export const ARCHIVE_EMPLOYEE_FAILED = 'ARCHIVE_EMPLOYEE_FAILED';
export type ArchiveEmployeeFailureAction = Action<typeof ARCHIVE_EMPLOYEE_FAILED, string>;
export const archiveEmployeeFailure = (payload: string): ArchiveEmployeeFailureAction => ({
    type: ARCHIVE_EMPLOYEE_FAILED,
    payload,
});

export const CONFIRM_NEW_PASSWORD_REQUESTED = 'CONFIRM_NEW_PASSWORD_REQUESTED';
export type ConfirmNewPasswordRequestAction = Action<typeof CONFIRM_NEW_PASSWORD_REQUESTED, string>;
export const confirmNewPasswordRequest = (token: string): ConfirmNewPasswordRequestAction => ({
    type: CONFIRM_NEW_PASSWORD_REQUESTED,
    payload: token,
});

export const CONFIRM_NEW_PASSWORD_SUCCEED = 'CONFIRM_NEW_PASSWORD_SUCCEED';
export type ConfirmNewPasswordSuccessAction = Action<
    typeof CONFIRM_NEW_PASSWORD_SUCCEED,
    null,
    string
>;
export const confirmNewPasswordSuccess = (meta: string): ConfirmNewPasswordSuccessAction => ({
    type: CONFIRM_NEW_PASSWORD_SUCCEED,
    meta,
});

export const CONFIRM_NEW_PASSWORD_FAILED = 'CONFIRM_NEW_PASSWORD_FAILED';
export type ConfirmNewPasswordFailureAction = Action<typeof CONFIRM_NEW_PASSWORD_FAILED, string>;
export const confirmNewPasswordFailure = (payload: string): ConfirmNewPasswordFailureAction => ({
    type: CONFIRM_NEW_PASSWORD_FAILED,
    payload,
});

export const CREATE_ACCOUNT_REQUESTED = 'CREATE_ACCOUNT_REQUESTED';
export type CreateAccountRequestAction = Action<
    typeof CREATE_ACCOUNT_REQUESTED,
    CreateAccountCredentials
>;
export const createAccountRequest = (
    createAccount: CreateAccountCredentials,
): CreateAccountRequestAction => ({
    type: CREATE_ACCOUNT_REQUESTED,
    payload: createAccount,
});

export const CREATE_ACCOUNT_SUCCEED = 'CREATE_ACCOUNT_SUCCEED';
export type CreateAccountSuccessAction = Action<typeof CREATE_ACCOUNT_SUCCEED, null, string>;
export const createAccountSuccess = (meta: string): CreateAccountSuccessAction => ({
    type: CREATE_ACCOUNT_SUCCEED,
    meta,
});

export const CREATE_ACCOUNT_FAILED = 'CREATE_ACCOUNT_FAILED';
export type CreateAccountFailureAction = Action<typeof CREATE_ACCOUNT_FAILED, string>;
export const createAccountFailure = (payload: string): CreateAccountFailureAction => ({
    type: CREATE_ACCOUNT_FAILED,
    payload,
});

export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';
export type ChangeUserRoleAction = Action<typeof CHANGE_USER_ROLE, UserRole>;
export const changeUserRole = (role: UserRole): ChangeUserRoleAction => ({
    type: CHANGE_USER_ROLE,
    payload: role,
});

export const OPEN_FORM = 'OPEN_FORM';
export type OpenFormAction = Action<typeof OPEN_FORM>;
export const openForm = (): OpenFormAction => ({ type: OPEN_FORM });

export const CLOSE_FORM = 'CLOSE_FORM';
export type CloseFormAction = Action<typeof CLOSE_FORM>;
export const closeForm = (): CloseFormAction => ({ type: CLOSE_FORM });

export const EDIT_EMPLOYEE_REQUESTED = 'EDIT_EMPLOYEE_REQUESTED';
export type EditEmployeeRequestAction = Action<typeof EDIT_EMPLOYEE_REQUESTED, ProfileRequest>;
export const editEmployeeRequest = (employee: ProfileRequest): EditEmployeeRequestAction => ({
    type: EDIT_EMPLOYEE_REQUESTED,
    payload: employee,
});

export const EDIT_EMPLOYEE_SUCCEED = 'EDIT_EMPLOYEE_SUCCEED';
export type EditEmployeeSuccessAction = Action<typeof EDIT_EMPLOYEE_SUCCEED, User>;
export const editEmployeeSuccess = (user: User): EditEmployeeSuccessAction => ({
    type: EDIT_EMPLOYEE_SUCCEED,
    payload: user,
});

export const EDIT_EMPLOYEE_FAILED = 'EDIT_EMPLOYEE_FAILED';
export type EditEmployeeFailureAction = Action<typeof EDIT_EMPLOYEE_FAILED, string>;
export const editEmployeeFailure = (payload: string): EditEmployeeFailureAction => ({
    type: EDIT_EMPLOYEE_FAILED,
    payload,
});

export const GET_EMPLOYEES_REQUESTED = 'GET_EMPLOYEES_REQUESTED';
export type GetEmployeesRequestAction = Action<typeof GET_EMPLOYEES_REQUESTED>;
export const getEmployeesRequest = (): GetEmployeesRequestAction => ({
    type: GET_EMPLOYEES_REQUESTED,
});

export const GET_EMPLOYEES_SUCCEED = 'GET_EMPLOYEES_SUCCEED';
export type GetEmployeesSuccessAction = Action<
    typeof GET_EMPLOYEES_SUCCEED,
    { employees: Profile[] }
>;
export const getEmployeesSuccess = (employees: Profile[]): GetEmployeesSuccessAction => ({
    type: GET_EMPLOYEES_SUCCEED,
    payload: { employees },
});

export const GET_EMPLOYEES_FAILED = 'GET_EMPLOYEES_FAILED';
export type GetEmployeesFailureAction = Action<typeof GET_EMPLOYEES_FAILED, string>;
export const getEmployeesFailure = (payload: string): GetEmployeesFailureAction => ({
    type: GET_EMPLOYEES_FAILED,
    payload,
});

export const GET_USER_REQUESTED = 'GET_USER_REQUESTED';
export type GetUserRequestAction = Action<typeof GET_USER_REQUESTED>;
export const getUserRequest = (): GetUserRequestAction => ({
    type: GET_USER_REQUESTED,
});

export const GET_USER_SUCCEED = 'GET_USER_SUCCEED';
export type GetUserSuccessAction = Action<typeof GET_USER_SUCCEED, Profile>;
export const getUserSuccess = (user: Profile): GetUserSuccessAction => ({
    type: GET_USER_SUCCEED,
    payload: user,
});

export const GET_USER_FAILED = 'GET_USER_FAILED';
export type GetUserFailureAction = Action<typeof GET_USER_FAILED, string>;
export const getUserFailure = (payload: string): GetUserFailureAction => ({
    type: GET_USER_FAILED,
    payload,
});

export const UPDATE_USER_DATA_REQUESTED = 'UPDATE_USER_DATA_REQUESTED';
export type UpdateUserDataRequestAction = Action<typeof UPDATE_USER_DATA_REQUESTED, UpdateUserData>;
export const updateUserDataRequest = (user: UpdateUserData): UpdateUserDataRequestAction => ({
    type: UPDATE_USER_DATA_REQUESTED,
    payload: user,
});

export const UPDATE_USER_DATA_SUCCEED = 'UPDATE_USER_DATA_SUCCEED';
export type UpdateUserDataSuccessAction = Action<typeof UPDATE_USER_DATA_SUCCEED, User, string>;
export const updateUserDataSuccess = (user: User, meta: string): UpdateUserDataSuccessAction => ({
    type: UPDATE_USER_DATA_SUCCEED,
    payload: user,
    meta,
});

export const UPDATE_USER_ORGANISATIONS_REQUESTED = 'UPDATE_USER_ORGANISATIONS_REQUESTED';
export type UpdateUserOrganistaionsRequestAction = Action<
    typeof UPDATE_USER_ORGANISATIONS_REQUESTED
>;
export const updateUserOrganisationsRequest = (): UpdateUserOrganistaionsRequestAction => ({
    type: UPDATE_USER_ORGANISATIONS_REQUESTED,
});

export const UPDATE_USER_ORGANISATIONS_SUCCEED = 'UPDATE_USER_ORGANISATIONS_SUCCEED';
export type UpdateUserOrganistaionsSuccessAction = Action<
    typeof UPDATE_USER_ORGANISATIONS_SUCCEED,
    UserOrganisation[]
>;
export const updateUserOrganisationsSuccess = (
    organisations: UserOrganisation[],
): UpdateUserOrganistaionsSuccessAction => ({
    type: UPDATE_USER_ORGANISATIONS_SUCCEED,
    payload: organisations,
});

export const UPDATE_USER_ORGANISATIONS_FAILED = 'UPDATE_USER_ORGANISATIONS_FAILED';
export type UpdateUserOrganistaionsFailureAction = Action<
    typeof UPDATE_USER_ORGANISATIONS_FAILED,
    string
>;
export const updateUserOrganisationsFailure = (
    payload: string,
): UpdateUserOrganistaionsFailureAction => ({
    type: UPDATE_USER_ORGANISATIONS_FAILED,
    payload,
});

export const UPDATE_USER_ACTIVE_ORGANISATION = 'UPDATE_USER_ACTIVE_ORGANISATION';
export type UpdateUserActiveOrganisationAction = Action<
    typeof UPDATE_USER_ACTIVE_ORGANISATION,
    UserOrganisation
>;
export const updateUserActiveOrganisation = (
    organisation: UserOrganisation,
): UpdateUserActiveOrganisationAction => ({
    type: UPDATE_USER_ACTIVE_ORGANISATION,
    payload: organisation,
});

export const UPDATE_USER_DATA_FAILED = 'UPDATE_USER_DATA_FAILED';
export type UpdateUserDataFailureAction = Action<typeof UPDATE_USER_DATA_FAILED, string>;
export const updateUserDataFailure = (payload: string): UpdateUserDataFailureAction => ({
    type: UPDATE_USER_DATA_FAILED,
    payload,
});

export const CHANGE_USER_PASSWORD_REQUESTED = 'CHANGE_USER_PASSWORD_REQUESTED';
export type ChangeUserPasswordRequestAction = Action<
    typeof CHANGE_USER_PASSWORD_REQUESTED,
    ChangePasswordRequest
>;
export const changeUserPasswordRequest = (
    payload: ChangePasswordRequest,
): ChangeUserPasswordRequestAction => ({
    type: CHANGE_USER_PASSWORD_REQUESTED,
    payload,
});

export const CHANGE_USER_PASSWORD_SUCCEED = 'CHANGE_USER_PASSWORD_SUCCEED';
export type ChangeUserPasswordSuccessAction = Action<
    typeof CHANGE_USER_PASSWORD_SUCCEED,
    null,
    string
>;
export const changeUserPasswordSuccess = (meta: string): ChangeUserPasswordSuccessAction => ({
    type: CHANGE_USER_PASSWORD_SUCCEED,
    meta,
});

export const CHANGE_USER_PASSWORD_FAILED = 'CHANGE_USER_PASSWORD_FAILED';
export type ChangeUserPasswordFailureAction = Action<typeof CHANGE_USER_PASSWORD_FAILED, string>;
export const changeUserPasswordFailure = (payload: string): ChangeUserPasswordFailureAction => ({
    type: CHANGE_USER_PASSWORD_FAILED,
    payload,
});

export const SEND_RESET_PASSWORD_REQUESTED = 'SEND_RESET_PASSWORD_REQUESTED';
export type SendResetPasswordRequestAction = Action<typeof SEND_RESET_PASSWORD_REQUESTED, string>;
export const sendResetPasswordRequest = (email: string): SendResetPasswordRequestAction => ({
    type: SEND_RESET_PASSWORD_REQUESTED,
    payload: email,
});

export const SEND_RESET_PASSWORD_SUCCEED = 'SEND_RESET_PASSWORD_SUCCEED';
export type SendResetPasswordSuccessAction = Action<
    typeof SEND_RESET_PASSWORD_SUCCEED,
    null,
    string
>;
export const sendResetPasswordSuccess = (
    successMessage: string,
): SendResetPasswordSuccessAction => ({
    type: SEND_RESET_PASSWORD_SUCCEED,
    meta: successMessage,
});

export const SEND_RESET_PASSWORD_FAILED = 'SEND_RESET_PASSWORD_FAILED';
export type SendResetPasswordFailureAction = Action<typeof SEND_RESET_PASSWORD_FAILED, string>;
export const sendResetPasswordFailure = (payload: string): SendResetPasswordFailureAction => ({
    type: SEND_RESET_PASSWORD_FAILED,
    payload,
});

export const VERIFY_PASSWORD_RESET_TOKEN_REQUESTED = 'VERIFY_PASSWORD_RESET_TOKEN_REQUESTED';
export type VerifyPasswordResetTokenRequestAction = Action<
    typeof VERIFY_PASSWORD_RESET_TOKEN_REQUESTED,
    string
>;
export const verifyPasswordResetTokenRequest = (
    token: string,
): VerifyPasswordResetTokenRequestAction => ({
    type: VERIFY_PASSWORD_RESET_TOKEN_REQUESTED,
    payload: token,
});

export const VERIFY_PASSWORD_RESET_TOKEN_SUCCEED = 'VERIFY_PASSWORD_RESET_TOKEN_SUCCEED';
export type VerifyPasswordResetTokenSuccessAction = Action<
    typeof VERIFY_PASSWORD_RESET_TOKEN_SUCCEED,
    null,
    string
>;
export const verifyPasswordResetTokenSuccess = (
    successMessage: string,
): VerifyPasswordResetTokenSuccessAction => ({
    type: VERIFY_PASSWORD_RESET_TOKEN_SUCCEED,
    meta: successMessage,
});

export const VERIFY_PASSWORD_RESET_TOKEN_FAILED = 'VERIFY_PASSWORD_RESET_TOKEN_FAILED';
export type VerifyPasswordResetTokenFailureAction = Action<
    typeof VERIFY_PASSWORD_RESET_TOKEN_FAILED,
    string
>;
export const verifyPasswordResetTokenFailure = (
    payload: string,
): VerifyPasswordResetTokenFailureAction => ({
    type: VERIFY_PASSWORD_RESET_TOKEN_FAILED,
    payload,
});

export const RESET_PASSWORD_CHANGE_REQUESTED = 'RESET_PASSWORD_CHANGE_REQUESTED';
export type ResetPasswordChangeRequestAction = Action<
    typeof RESET_PASSWORD_CHANGE_REQUESTED,
    ResetPasswordRequest
>;
export const resetPasswordChangeRequest = (
    payload: ResetPasswordRequest,
): ResetPasswordChangeRequestAction => ({
    type: RESET_PASSWORD_CHANGE_REQUESTED,
    payload,
});

export const RESET_PASSWORD_CHANGE_SUCCEED = 'RESET_PASSWORD_CHANGE_SUCCEED';
export type ResetPasswordChangeSuccessAction = Action<
    typeof RESET_PASSWORD_CHANGE_SUCCEED,
    null,
    string
>;
export const resetPasswordChangeSuccess = (
    successMessage: string,
): ResetPasswordChangeSuccessAction => ({
    type: RESET_PASSWORD_CHANGE_SUCCEED,
    meta: successMessage,
});

export const RESET_PASSWORD_CHANGE_FAILED = 'RESET_PASSWORD_CHANGE_FAILED';
export type ResetPasswordChangeFailureAction = Action<typeof RESET_PASSWORD_CHANGE_FAILED, string>;
export const resetPasswordChangeFailure = (payload: string): ResetPasswordChangeFailureAction => ({
    type: RESET_PASSWORD_CHANGE_FAILED,
    payload,
});

export const UPDATE_USER_EMAIL_REQUESTED = 'CHANGE_USER_EMAIL_REQUESTED';
export type UpdateUserEmailRequestAction = Action<
    typeof UPDATE_USER_EMAIL_REQUESTED,
    ChangeEmailRequest
>;
export const updateUserEmailRequest = (data: ChangeEmailRequest): UpdateUserEmailRequestAction => ({
    type: UPDATE_USER_EMAIL_REQUESTED,
    payload: data,
});

export const UPDATE_USER_EMAIL_SUCCEED = 'UPDATE_USER_EMAIL_SUCCEED';
export type UpdateUserEmailSuccessAction = Action<typeof UPDATE_USER_EMAIL_SUCCEED, string, string>;
export const updateUserEmailSuccess = (
    email: string,
    meta: string,
): UpdateUserEmailSuccessAction => ({
    type: UPDATE_USER_EMAIL_SUCCEED,
    payload: email,
    meta,
});

export const UPDATE_USER_EMAIL_FAILED = 'UPDATE_USER_EMAIL_FAILED';
export type UpdateUserEmailFailureAction = Action<typeof UPDATE_USER_EMAIL_FAILED, string>;
export const updateUserEmailFailure = (payload: string): UpdateUserEmailFailureAction => ({
    type: UPDATE_USER_EMAIL_FAILED,
    payload,
});

export const TOGGLE_USER_ORGANISATION_SELECT_MODAL = 'TOGGLE_USER_ORGANISATION_SELECT_MODAL';
export type ToggleUserOrganisationSelectModal = Action<
    typeof TOGGLE_USER_ORGANISATION_SELECT_MODAL,
    boolean
>;
export const toggleUserOrganisationSelectModal = (
    payload: boolean,
): ToggleUserOrganisationSelectModal => ({
    type: TOGGLE_USER_ORGANISATION_SELECT_MODAL,
    payload,
});

export const DELETE_EMPLOYEE_REQUESTED = 'DELETE_EMPLOYEE_REQUESTED';
export type DeleteEmployeeRequestAction = Action<typeof DELETE_EMPLOYEE_REQUESTED, number>;
export const deleteEmployeeRequest = (id: number): DeleteEmployeeRequestAction => ({
    type: DELETE_EMPLOYEE_REQUESTED,
    payload: id,
});

export const DELETE_EMPLOYEE_SUCCEED = 'DELETE_EMPLOYEE_SUCCEED';
export type DeleteEmployeeSuccessAction = Action<typeof DELETE_EMPLOYEE_SUCCEED, null, string>;
export const deleteEmployeeSuccess = (meta: string): DeleteEmployeeSuccessAction => ({
    type: DELETE_EMPLOYEE_SUCCEED,
    meta,
});

export const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED';
export type DeleteEmployeeFailureAction = Action<typeof DELETE_EMPLOYEE_FAILED, string>;
export const deleteEmployeeFailure = (payload: string): DeleteEmployeeFailureAction => ({
    type: DELETE_EMPLOYEE_FAILED,
    payload,
});

export const GET_SUBSCRIPTION_TIERS_REQUESTED = 'GET_SUBSCRIPTION_TIERS_REQUESTED';
export type GetSubscriptionTiersRequestAction = Action<typeof GET_SUBSCRIPTION_TIERS_REQUESTED>;
export const getSubscriptionsTiersRequest = (): GetSubscriptionTiersRequestAction => ({
    type: GET_SUBSCRIPTION_TIERS_REQUESTED,
});

export const GET_SUBSCRIPTION_TIERS_SUCCEED = 'GET_SUBSCRIPTION_TIERS_SUCCEED';
export type GetSubscriptionTiersSuccessAction = Action<
    typeof GET_SUBSCRIPTION_TIERS_SUCCEED,
    Subscription[]
>;
export const GetSubscriptionTiersSuccess = (
    payload: Subscription[],
): GetSubscriptionTiersSuccessAction => ({
    type: GET_SUBSCRIPTION_TIERS_SUCCEED,
    payload,
});

export const GET_SUBSCRIPTION_TIERS_FAILED = 'GET_SUBSCRIPTION_TIERS_FAILED';
export type GetSubscriptionTiersFailureAction = Action<
    typeof GET_SUBSCRIPTION_TIERS_FAILED,
    string
>;
export const GetSubscriptionTiersFailure = (
    payload: string,
): GetSubscriptionTiersFailureAction => ({
    type: GET_SUBSCRIPTION_TIERS_FAILED,
    payload,
});

export const GET_SUBSCRIPTIONS_REQUESTED = 'GET_SUBSCRIPTIONS_REQUESTED';
export type GetSubscriptionsRequestAction = Action<typeof GET_SUBSCRIPTIONS_REQUESTED>;
export const getSubscriptionsRequest = (): GetSubscriptionsRequestAction => ({
    type: GET_SUBSCRIPTIONS_REQUESTED,
});

export const GET_SUBSCRIPTIONS_SUCCEED = 'GET_SUBSCRIPTIONS_SUCCEED';
export type GetSubscriptionsSuccessAction = Action<
    typeof GET_SUBSCRIPTIONS_SUCCEED,
    Subscription[]
>;
export const GetSubscriptionsSuccess = (
    payload: Subscription[],
): GetSubscriptionsSuccessAction => ({
    type: GET_SUBSCRIPTIONS_SUCCEED,
    payload,
});

export const GET_SUBSCRIPTIONS_FAILED = 'GET_SUBSCRIPTIONS_FAILED';
export type GetSubscriptionsFailureAction = Action<typeof GET_SUBSCRIPTIONS_FAILED, string>;
export const GetSubscriptionsFailure = (payload: string): GetSubscriptionsFailureAction => ({
    type: GET_SUBSCRIPTIONS_FAILED,
    payload,
});

export const SAVE_INVOICE_DATA_REQUESTED = 'SAVE_INVOICE_DATA_REQUESTED';
export type SaveInvoiceDataRequestAction = Action<typeof SAVE_INVOICE_DATA_REQUESTED, InvoiceData>;
export const saveInvoiceDataRequest = (payload: InvoiceData): SaveInvoiceDataRequestAction => ({
    type: SAVE_INVOICE_DATA_REQUESTED,
    payload,
});

export const SAVE_INVOICE_DATA_SUCCEED = 'SAVE_INVOICE_DATA_SUCCEED';
export type SaveInvoiceDataSuccessAction = Action<
    typeof SAVE_INVOICE_DATA_SUCCEED,
    InvoiceData,
    string
>;
export const saveInvoiceDataSuccess = (payload: InvoiceData): SaveInvoiceDataSuccessAction => ({
    type: SAVE_INVOICE_DATA_SUCCEED,
    payload,
    meta: 'Invoice data saved successfully! You can now purchase subscriptions',
});

export const SAVE_INVOICE_DATA_FAILED = 'SAVE_INVOICE_DATA_FAILED';
export type SaveInvoiceDataFailureAction = Action<typeof SAVE_INVOICE_DATA_FAILED, string>;
export const saveInvoiceDataFailure = (payload: string): SaveInvoiceDataFailureAction => ({
    type: SAVE_INVOICE_DATA_FAILED,
    payload,
});

export const UPDATE_INVOICE_DATA_REQUESTED = 'UPDATE_INVOICE_DATA_REQUESTED';
export type UpdateInvoiceDataRequestAction = Action<
    typeof UPDATE_INVOICE_DATA_REQUESTED,
    InvoiceData
>;
export const updateInvoiceDataRequest = (payload: InvoiceData): UpdateInvoiceDataRequestAction => ({
    type: UPDATE_INVOICE_DATA_REQUESTED,
    payload,
});

export const UPDATE_INVOICE_DATA_SUCCEED = 'UPDATE_INVOICE_DATA_SUCCEED';
export type UpdateInvoiceDataSuccessAction = Action<
    typeof UPDATE_INVOICE_DATA_SUCCEED,
    InvoiceData,
    string
>;
export const updateInvoiceDataSuccess = (payload: InvoiceData): UpdateInvoiceDataSuccessAction => ({
    type: UPDATE_INVOICE_DATA_SUCCEED,
    payload,
    meta: 'Invoice data have been successfully updated',
});

export const UPDATE_INVOICE_DATA_FAILED = 'UPDATE_INVOICE_DATA_FAILED';
export type UpdateInvoiceDataFailureAction = Action<typeof UPDATE_INVOICE_DATA_FAILED, string>;
export const UpdateInvoiceDataFailureAction = (
    payload: string,
): UpdateInvoiceDataFailureAction => ({
    type: UPDATE_INVOICE_DATA_FAILED,
    payload,
});

export const GET_INVOICE_DATA_REQUESTED = 'GET_INVOICE_DATA_REQUESTED';
export type GetInvoiceDataRequestAction = Action<typeof GET_INVOICE_DATA_REQUESTED>;
export const getInvoiceDataRequest = (): GetInvoiceDataRequestAction => ({
    type: GET_INVOICE_DATA_REQUESTED,
});

export const GET_INVOICE_DATA_SUCCEED = 'GET_INVOICE_DATA_SUCCEED';
export type GetInvoiceDataSuccessAction = Action<typeof GET_INVOICE_DATA_SUCCEED, InvoiceData>;
export const getInvoiceDataSuccess = (payload: InvoiceData): GetInvoiceDataSuccessAction => ({
    type: GET_INVOICE_DATA_SUCCEED,
    payload,
});

export const GET_INVOICE_DATA_FAILED = 'GET_INVOICE_DATA_FAILED';
export type GetInvoiceDataFailureAction = Action<typeof GET_INVOICE_DATA_FAILED, string>;
export const getInvoiceDataFailure = (payload: string): GetInvoiceDataFailureAction => ({
    type: GET_INVOICE_DATA_FAILED,
    payload,
});

export const GET_SUBSCRIPTION_INVOICES_REQUESTED = 'GET_SUBSCRIPTION_INVOICES_REQUESTED';
export type GetSubscriptionInvoicesRequestAction = Action<
    typeof GET_SUBSCRIPTION_INVOICES_REQUESTED,
    number
>;
export const getSubscriptionInvoicesRequest = (
    id: number,
): GetSubscriptionInvoicesRequestAction => ({
    type: GET_SUBSCRIPTION_INVOICES_REQUESTED,
    payload: id,
});

export const GET_SUBSCRIPTION_INVOICES_SUCCEED = 'GET_SUBSCRIPTION_INVOICES_SUCCEED';
export type GetSubscriptionInvoicesSuccessAction = Action<
    typeof GET_SUBSCRIPTION_INVOICES_SUCCEED,
    Invoice[]
>;
export const getSubscriptionInvoicesSuccess = (
    payload: Invoice[],
): GetSubscriptionInvoicesSuccessAction => ({
    type: GET_SUBSCRIPTION_INVOICES_SUCCEED,
    payload,
});

export const GET_SUBSCRIPTION_INVOICES_FAILED = 'GET_SUBSCRIPTION_INVOICES_FAILED';
export type GetSubscriptionInvoicesFailureAction = Action<
    typeof GET_SUBSCRIPTION_INVOICES_FAILED,
    string
>;
export const getSubscriptionInvoicesFailure = (payload: string): GetInvoiceDataFailureAction => ({
    type: GET_INVOICE_DATA_FAILED,
    payload,
});

export const PURCHASE_SUBSCRIPTION_REQUESTED = 'PURCHASE_SUBSCRIPTION_REQUESTED';
export type PurchaseSubscriptionRequestAction = Action<
    typeof PURCHASE_SUBSCRIPTION_REQUESTED,
    SubscriptionPurchaseData
>;
export const PurchaseSubscriptionRequest = (
    payload: SubscriptionPurchaseData,
): PurchaseSubscriptionRequestAction => ({
    type: PURCHASE_SUBSCRIPTION_REQUESTED,
    payload,
});

export const PURCHASE_SUBSCRIPTION_SUCCEED = 'PURCHASE_SUBSCRIPTION_SUCCEED';
export type PurchaseSubscriptionSuccessAction = Action<
    typeof PURCHASE_SUBSCRIPTION_SUCCEED,
    SubscriptionPurchaseData,
    String
>;
export const purchaseSubscriptionSuccess = (
    payload: SubscriptionPurchaseData,
): PurchaseSubscriptionSuccessAction => ({
    type: PURCHASE_SUBSCRIPTION_SUCCEED,
    payload,
    meta: 'Subscription purchased successfully',
});

export const PURCHASE_SUBSCRIPTION_FAILED = 'PURCHASE_SUBSCRIPTION_FAILED';
export type PurchaseSubscriptionFailureAction = Action<typeof PURCHASE_SUBSCRIPTION_FAILED, string>;
export const purchaseSubscriptionFailure = (
    payload: string,
): PurchaseSubscriptionFailureAction => ({
    type: PURCHASE_SUBSCRIPTION_FAILED,
    payload,
});

export type UserAction =
    | ArchiveEmployeeRequestAction
    | ArchiveEmployeeSuccessAction
    | ArchiveEmployeeFailureAction
    | ConfirmNewPasswordRequestAction
    | ConfirmNewPasswordSuccessAction
    | ConfirmNewPasswordFailureAction
    | CreateAccountRequestAction
    | CreateAccountSuccessAction
    | CreateAccountFailureAction
    | ChangeUserRoleAction
    | GetEmployeesRequestAction
    | GetEmployeesSuccessAction
    | GetEmployeesFailureAction
    | OpenFormAction
    | CloseFormAction
    | EditEmployeeRequestAction
    | EditEmployeeSuccessAction
    | EditEmployeeFailureAction
    | GetUserRequestAction
    | GetUserSuccessAction
    | GetUserFailureAction
    | UpdateUserDataRequestAction
    | UpdateUserDataSuccessAction
    | UpdateUserDataFailureAction
    | UpdateUserOrganistaionsRequestAction
    | UpdateUserOrganistaionsSuccessAction
    | UpdateUserOrganistaionsFailureAction
    | UpdateUserActiveOrganisationAction
    | ChangeUserPasswordRequestAction
    | ChangeUserPasswordSuccessAction
    | ChangeUserPasswordFailureAction
    | SendResetPasswordRequestAction
    | SendResetPasswordSuccessAction
    | SendResetPasswordFailureAction
    | VerifyPasswordResetTokenRequestAction
    | VerifyPasswordResetTokenSuccessAction
    | VerifyPasswordResetTokenFailureAction
    | ResetPasswordChangeRequestAction
    | ResetPasswordChangeSuccessAction
    | ResetPasswordChangeFailureAction
    | UpdateUserEmailRequestAction
    | UpdateUserEmailSuccessAction
    | UpdateUserEmailFailureAction
    | ToggleUserOrganisationSelectModal
    | DeleteEmployeeRequestAction
    | DeleteEmployeeSuccessAction
    | DeleteEmployeeFailureAction
    | GetSubscriptionTiersRequestAction
    | GetSubscriptionTiersSuccessAction
    | GetSubscriptionTiersFailureAction
    | SaveInvoiceDataRequestAction
    | SaveInvoiceDataSuccessAction
    | SaveInvoiceDataFailureAction
    | GetInvoiceDataRequestAction
    | GetInvoiceDataSuccessAction
    | GetInvoiceDataFailureAction
    | PurchaseSubscriptionRequestAction
    | PurchaseSubscriptionSuccessAction
    | PurchaseSubscriptionFailureAction
    | GetSubscriptionsRequestAction
    | GetSubscriptionsSuccessAction
    | GetSubscriptionsFailureAction
    | GetSubscriptionInvoicesRequestAction
    | GetSubscriptionInvoicesSuccessAction
    | GetSubscriptionInvoicesFailureAction;
