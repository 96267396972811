import * as React from 'react';
import { Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import styled from 'styled-components';
import { InboxOutlined } from '@ant-design/icons';

import { sizes } from 'theme/variables';
import { showNotification, Notification } from 'common/helpers/notifications';
import { FileData } from 'features/upload/models/uploadModels';

interface UploadFileProps {
    required?: boolean;
    disabled?: boolean;
    accept?: string;
    uploadUrl: string;
    successAction: (file: FileData) => void;
    failureAction: (payload: string) => void;
}

const StyledUpload = styled(Upload.Dragger)`
    .ant-upload-drag-container {
        width: 100%;
        max-width: ${sizes.formWidth};
    }
    && .anticon-cross {
        display: none;
    }
`;

export class UploadMultiFiles extends React.Component<UploadFileProps> {
    public render(): JSX.Element {
        const props = {
            disabled: this.props.disabled,
            accept: this.props.accept,
            action: this.props.uploadUrl,
            onChange: ({ file: { status, name, response } }: UploadFile & UploadChangeParam) => {
                if (status === 'done') {
                    showNotification({
                        text: `${name} file uploaded successfully.`,
                        type: Notification.Success,
                    });

                    this.props.successAction(response);
                } else if (status === 'error') {
                    showNotification({
                        text: `${name} file upload failed.`,
                    });

                    this.props.failureAction(response);
                }
            },
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
            },
        };

        return (
            <StyledUpload {...props} multiple listType="picture">
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Upload files</p>
                <p className="ant-upload-hint">Click or drag files to this area to upload</p>
            </StyledUpload>
        );
    }
}
