import { RouteComponentProps } from 'react-router-dom';
import { createSelector } from 'reselect';
import { AppState } from '../appState';

export interface IdParam {
    id: string;
    benefitId: string;
    contractId: string;
    pageNumber: string;
}
export type RouteParams = RouteComponentProps<IdParam>;

export const routeParams = <P>(props: RouteComponentProps<P>) => props.match.params;
export const routeFilterParams = <P>(_: AppState, props: RouteComponentProps<P>) =>
    props.match.params; // temp solution for using the same filter form

export const routeLocation = <P>(props: RouteComponentProps<P>) => props.location;

export const getRouteId = createSelector(routeParams, ({ id }) => parseInt(id, 10));
export const getBenefitId = createSelector(routeParams, ({ benefitId }) => parseInt(benefitId, 10));
export const getContractId = createSelector(routeParams, ({ contractId }) =>
    parseInt(contractId, 10),
);

export const getOrganisationId = createSelector(routeParams, ({ organisationId }) =>
    parseInt(organisationId, 10),
);

export const getFilterId = createSelector(routeFilterParams, ({ filterId }) =>
    parseInt(filterId, 10),
);

export const getLocationPathname = createSelector(routeLocation, ({ pathname }) => pathname);

export const getFilterBackUrl = createSelector(getLocationPathname, (pathname) =>
    pathname.replace(/\/filter\/\d+/, ''),
);

export const getPageNumber = createSelector(routeParams, ({ pageNumber }) =>
    parseInt(pageNumber, 10),
);
