import styled from 'styled-components';

import { Antd3Form } from '../deprecated/antd3';

export const CenteringFormItem = styled(Antd3Form.Item)`
    && {
        display: flex;
        justify-content: center;
        width: 100%;
    }
`;
