import { Checkbox as AntdCheckbox, Form, CheckboxProps } from 'antd';
import { FormItemProps } from '@ant-design/compatible/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import styled from 'styled-components';

import { FormInputProps } from './models';

const CheckboxWrapper = styled(Form.Item)<{ nobottommargin: number }>(
    ({ nobottommargin }) => `
    max-width: 35rem;
        margin-bottom: ${nobottommargin ? 0 : '1rem'};
`,
);

interface Props
    extends Omit<FormItemProps, 'label'>,
        Omit<CheckboxProps, 'label' | 'name'>,
        FormInputProps {
    name: NamePath;
    noBottomMargin?: boolean;
}

export const Checkbox = ({
    name,
    label,
    disabled = false,
    required,
    initialValue,
    children,
    noBottomMargin,
}: Props) => (
    <CheckboxWrapper
        name={name}
        valuePropName="checked"
        required={required}
        initialValue={initialValue}
        nobottommargin={noBottomMargin ? 1 : 0}
    >
        <AntdCheckbox value={label} disabled={disabled}>
            {label || children}
        </AntdCheckbox>
    </CheckboxWrapper>
);
