import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { Organisation } from 'features/organisation/models/organisationModels';
import {
    getInvitedOrganisationExists,
    getPotentialExecutives,
} from 'features/organisation/selectors/organisationSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import {
    paginationRequest,
    saveFilters,
    clearFilters,
    clearPagination,
} from 'features/pagination/actions/paginationActions';
import { getPotentialExecutivesSuccess } from 'features/organisation/actions/organisationActions';
import {
    invitePotentialExecutivesRequest,
    deleteExistingOrgPendingInvitationsRequest,
} from '../actions/inviteActions';
import { getExistingOrganisationPendingInvitations } from '../selectors/inviteSelectors';
import { InviteOrganisation as InviteOrganisationComponent } from '../components/InviteOrganisation';
import { OrganisationInvitation } from '../models/inviteModel';

export interface InviteOrganisationStateProps {
    potentialExecutives: Organisation[];
    existingOrganisationPendingInvitations: OrganisationInvitation[];
    invitedOrganisationExists?: boolean;
}

interface InviteOrganisationDispatchProps {
    invitePotentialExecutivesRequest: typeof invitePotentialExecutivesRequest;
    deleteExistingOrgPendingInvitationsRequest: typeof deleteExistingOrgPendingInvitationsRequest;
    redirectToUrl: typeof redirectToUrl;
    paginationRequest: typeof paginationRequest;
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
    clearPagination: typeof clearPagination;
    getPotentialExecutivesSuccess: typeof getPotentialExecutivesSuccess;
}

export type InviteOrganisationProps = InviteOrganisationDispatchProps &
    InviteOrganisationStateProps;

export class InviteOrganisation extends React.Component<InviteOrganisationProps> {
    public render(): JSX.Element {
        return <InviteOrganisationComponent {...this.props} />;
    }
}

const mapStateToProps = createSelector(
    getPotentialExecutives,
    getExistingOrganisationPendingInvitations,
    getInvitedOrganisationExists,
    (
        potentialExecutives,
        existingOrganisationPendingInvitations,
        invitedOrganisationExists,
    ): InviteOrganisationStateProps => ({
        potentialExecutives,
        existingOrganisationPendingInvitations,
        invitedOrganisationExists,
    }),
);

export const InviteOrganisationContainer = connect(mapStateToProps, {
    invitePotentialExecutivesRequest,
    deleteExistingOrgPendingInvitationsRequest,
    redirectToUrl,
    paginationRequest,
    saveFilters,
    clearFilters,
    clearPagination,
    getPotentialExecutivesSuccess,
})(InviteOrganisation);
