import { Action } from 'common/actions/appAction';

import {
    PortfolioFilterRequest,
    GetPortfolioFiltersRequest,
    PortfolioFilterSuccess,
    GetPortfolioFiltersSuccess,
    PortfolioFilter,
} from '../models/portfolioFilterModel';

export const GET_PORTFOLIO_FILTERS_LIST_REQUESTED = 'GET_PORTFOLIO_FILTERS_LIST_REQUESTED';
export type GetPortfolioFiltersRequestAction = Action<
    typeof GET_PORTFOLIO_FILTERS_LIST_REQUESTED,
    GetPortfolioFiltersRequest
>;
export const getPortfolioFiltersRequest = (
    portfolioFiltersRequest: GetPortfolioFiltersRequest,
): GetPortfolioFiltersRequestAction => ({
    type: GET_PORTFOLIO_FILTERS_LIST_REQUESTED,
    payload: portfolioFiltersRequest,
});

export const GET_PORTFOLIO_FILTERS_LIST_SUCCEED = 'GET_PORTFOLIO_FILTERS_LIST_SUCCEED';
export type GetPortfolioFiltersSuccessAction = Action<
    typeof GET_PORTFOLIO_FILTERS_LIST_SUCCEED,
    GetPortfolioFiltersSuccess
>;
export const getPortfolioFiltersSuccess = (
    payload: GetPortfolioFiltersSuccess,
): GetPortfolioFiltersSuccessAction => ({
    type: GET_PORTFOLIO_FILTERS_LIST_SUCCEED,
    payload,
});

export const GET_PORTFOLIO_FILTERS_LIST_FAILED = 'GET_PORTFOLIO_FILTERS_LIST_FAILED';
export type GetPortfolioFiltersFailureAction = Action<
    typeof GET_PORTFOLIO_FILTERS_LIST_FAILED,
    string
>;
export const getPortfolioFiltersFailure = (message: string): GetPortfolioFiltersFailureAction => ({
    type: GET_PORTFOLIO_FILTERS_LIST_FAILED,
    payload: message,
});

export const CREATE_PORTFOLIO_FILTER_REQUESTED = 'CREATE_PORTFOLIO_FILTER_REQUESTED';
export type CreatePortfolioFilterRequestAction = Action<
    typeof CREATE_PORTFOLIO_FILTER_REQUESTED,
    PortfolioFilterRequest
>;
export const createPortfolioFilterRequest = (
    portfolioFilter: PortfolioFilterRequest,
): CreatePortfolioFilterRequestAction => ({
    type: CREATE_PORTFOLIO_FILTER_REQUESTED,
    payload: portfolioFilter,
});

export const CREATE_PORTFOLIO_FILTER_SUCCEED = 'CREATE_PORTFOLIO_FILTER_SUCCEED';
export type CreatePortfolioFilterSuccessAction = Action<
    typeof CREATE_PORTFOLIO_FILTER_SUCCEED,
    PortfolioFilterSuccess
>;
export const createPortfolioFilterSuccess = (
    payload: PortfolioFilterSuccess,
): CreatePortfolioFilterSuccessAction => ({
    type: CREATE_PORTFOLIO_FILTER_SUCCEED,
    payload,
});

export const CREATE_PORTFOLIO_FILTER_FAILED = 'CREATE_PORTFOLIO_FILTER_FAILED';
export type CreatePortfolioFilterFailureAction = Action<
    typeof CREATE_PORTFOLIO_FILTER_FAILED,
    string
>;
export const createPortfolioFilterFailure = (
    message: string,
): CreatePortfolioFilterFailureAction => ({
    type: CREATE_PORTFOLIO_FILTER_FAILED,
    payload: message,
});

export const EDIT_PORTFOLIO_FILTER_REQUESTED = 'EDIT_PORTFOLIO_FILTER_REQUESTED';
export type EditPortfolioFilterRequestAction = Action<
    typeof EDIT_PORTFOLIO_FILTER_REQUESTED,
    PortfolioFilterRequest
>;
export const editPortfolioFilterRequest = (
    portfolioFilter: PortfolioFilterRequest,
): EditPortfolioFilterRequestAction => ({
    type: EDIT_PORTFOLIO_FILTER_REQUESTED,
    payload: portfolioFilter,
});

export const EDIT_PORTFOLIO_FILTER_SUCCEED = 'EDIT_PORTFOLIO_FILTER_SUCCEED';
export type EditPortfolioFilterSuccessAction = Action<
    typeof EDIT_PORTFOLIO_FILTER_SUCCEED,
    PortfolioFilterSuccess
>;
export const editPortfolioFilterSuccess = (
    portfolioFilter: PortfolioFilterSuccess,
): EditPortfolioFilterSuccessAction => ({
    type: EDIT_PORTFOLIO_FILTER_SUCCEED,
    payload: portfolioFilter,
});

export const EDIT_PORTFOLIO_FILTER_FAILED = 'EDIT_PORTFOLIO_FILTER_FAILED';
export type EditPortfolioFilterFailureAction = Action<typeof EDIT_PORTFOLIO_FILTER_FAILED, string>;
export const editPortfolioFilterFailure = (message: string): EditPortfolioFilterFailureAction => ({
    type: EDIT_PORTFOLIO_FILTER_FAILED,
    payload: message,
});

export const GET_PORTFOLIO_FILTERS_ROOT_REQUESTED = 'GET_PORTFOLIO_FILTERS_ROOT_REQUESTED';
export type GetPortfolioFiltersRootRequestAction = Action<
    typeof GET_PORTFOLIO_FILTERS_ROOT_REQUESTED
>;
export const getPortfolioFiltersRootRequest = (): GetPortfolioFiltersRootRequestAction => ({
    type: GET_PORTFOLIO_FILTERS_ROOT_REQUESTED,
});

export const GET_PORTFOLIO_FILTERS_ROOT_SUCCEED = 'GET_PORTFOLIO_FILTERS_ROOT_SUCCEED';
export type GetPortfolioFiltersRootSuccessAction = Action<
    typeof GET_PORTFOLIO_FILTERS_ROOT_SUCCEED,
    PortfolioFilter
>;
export const getPortfolioFiltersRootSuccess = (
    payload: PortfolioFilter,
): GetPortfolioFiltersRootSuccessAction => ({
    type: GET_PORTFOLIO_FILTERS_ROOT_SUCCEED,
    payload,
});

export const GET_PORTFOLIO_FILTERS_ROOT_FAILED = 'GET_PORTFOLIO_FILTERS_ROOT_FAILED';
export type GetPortfolioFiltersRootFailureAction = Action<
    typeof GET_PORTFOLIO_FILTERS_ROOT_FAILED,
    string
>;
export const getPortfolioFiltersRootFailure = (
    message: string,
): GetPortfolioFiltersRootFailureAction => ({
    type: GET_PORTFOLIO_FILTERS_ROOT_FAILED,
    payload: message,
});

export const DELETE_PORTFOLIO_FILTER_REQUESTED = 'DELETE_PORTFOLIO_FILTER_REQUESTED';
export type DeletePortfolioFilterRequestAction = Action<
    typeof DELETE_PORTFOLIO_FILTER_REQUESTED,
    PortfolioFilterRequest
>;
export const deletePortfolioFilterRequest = (
    portfolioFilter: PortfolioFilterRequest,
): DeletePortfolioFilterRequestAction => ({
    type: DELETE_PORTFOLIO_FILTER_REQUESTED,
    payload: portfolioFilter,
});

export const DELETE_PORTFOLIO_FILTER_SUCCEED = 'DELETE_PORTFOLIO_FILTER_SUCCEED';
export type DeletePortfolioFilterSuccessAction = Action<
    typeof DELETE_PORTFOLIO_FILTER_SUCCEED,
    GetPortfolioFiltersRequest,
    string
>;
export const deletePortfolioFilterSuccess = (
    portfolioFilter: GetPortfolioFiltersRequest,
    meta: string,
): DeletePortfolioFilterSuccessAction => ({
    type: DELETE_PORTFOLIO_FILTER_SUCCEED,
    payload: portfolioFilter,
    meta,
});

export const DELETE_PORTFOLIO_FILTER_FAILED = 'DELETE_PORTFOLIO_FILTER_FAILED';
export type DeletePortfolioFilterFailureAction = Action<
    typeof DELETE_PORTFOLIO_FILTER_FAILED,
    string
>;
export const deletePortfolioFilterFailure = (
    message: string,
): DeletePortfolioFilterFailureAction => ({
    type: DELETE_PORTFOLIO_FILTER_FAILED,
    payload: message,
});

export type PortfolioFilterActions =
    | GetPortfolioFiltersRequestAction
    | GetPortfolioFiltersSuccessAction
    | GetPortfolioFiltersFailureAction
    | CreatePortfolioFilterRequestAction
    | CreatePortfolioFilterSuccessAction
    | CreatePortfolioFilterFailureAction
    | EditPortfolioFilterRequestAction
    | EditPortfolioFilterSuccessAction
    | EditPortfolioFilterFailureAction
    | GetPortfolioFiltersRootRequestAction
    | GetPortfolioFiltersRootSuccessAction
    | GetPortfolioFiltersRootFailureAction
    | DeletePortfolioFilterRequestAction
    | DeletePortfolioFilterSuccessAction
    | DeletePortfolioFilterFailureAction;
