import posthog from 'posthog-js';

import { SENTRY_ENVIRONMENT } from 'config/env';
import { Action } from 'common/actions/appAction';
import { EditPaths } from 'features/edit/models/editModel';

import {
    ArchiveFilterRequest,
    Contract,
    EditFilterRequest,
    Filters,
    ContractRequest,
    ContractRequestData,
    CreateFilterRequest,
    FilterContract,
    ContractRoleData,
    DeleteContractRoleRequest,
    AddContractRoleRequest,
    ArchiveFilterResponse,
    GetContractRequest,
    PortfolioFilters,
    ContractPriorities,
    AddContractPriorityRequest,
    EditContractPriorityRequest,
    ContractWithRAG,
    ContractPriorityDashboardItem,
    ToggleMultipleEmployeesContractRoleRequest,
    ContractNoteData,
    CreateContractNoteRequest,
    DeleteContractNoteRequest,
    EditContractNoteRequest,
    DeleteContractNoteFileRequest,
    AddContractNoteFileRequest,
    DeleteContractFileRequest,
    AddContractFileRequest,
} from '../models/contractModels';
import { ContractPath, FileData } from 'features/upload/models/uploadModels';

export const ARCHIVE_CONTRACT_CATEGORY_REQUESTED = 'ARCHIVE_CONTRACT_CATEGORY_REQUESTED';
export type ArchiveContractCategoryRequestAction = Action<
    typeof ARCHIVE_CONTRACT_CATEGORY_REQUESTED,
    ArchiveFilterRequest
>;
export const archiveContractCategoryRequest = (
    filterData: ArchiveFilterRequest,
): ArchiveContractCategoryRequestAction => ({
    type: ARCHIVE_CONTRACT_CATEGORY_REQUESTED,
    payload: filterData,
});

export const ARCHIVE_CONTRACT_CATEGORY_SUCCEED = 'ARCHIVE_CONTRACT_CATEGORY_SUCCEED';
export type ArchiveContractCategorySuccessAction = Action<
    typeof ARCHIVE_CONTRACT_CATEGORY_SUCCEED,
    number,
    string
>;
export const archiveContractCategorySuccess = (
    filterData: ArchiveFilterResponse,
): ArchiveContractCategorySuccessAction => ({
    type: ARCHIVE_CONTRACT_CATEGORY_SUCCEED,
    payload: filterData.id,
    meta: `Project category ${filterData.name} ${filterData.archived ? 'archived' : 'restored'}`,
});

export const ARCHIVE_CONTRACT_CATEGORY_FAILED = 'ARCHIVE_CONTRACT_CATEGORY_FAILED';
export type ArchiveContractCategoryFailureAction = Action<
    typeof ARCHIVE_CONTRACT_CATEGORY_FAILED,
    string
>;
export const archiveContractCategoryFailure = (
    payload: string,
): ArchiveContractCategoryFailureAction => ({
    type: ARCHIVE_CONTRACT_CATEGORY_FAILED,
    payload,
});

export const ARCHIVE_LOCATION_REQUESTED = 'ARCHIVE_LOCATION_REQUESTED';
export type ArchiveLocationRequestAction = Action<
    typeof ARCHIVE_LOCATION_REQUESTED,
    ArchiveFilterRequest
>;
export const archiveLocationRequest = (
    filterData: ArchiveFilterRequest,
): ArchiveLocationRequestAction => ({
    type: ARCHIVE_LOCATION_REQUESTED,
    payload: filterData,
});

export const ARCHIVE_LOCATION_SUCCEED = 'ARCHIVE_LOCATION_SUCCEED';
export type ArchiveLocationSuccessAction = Action<typeof ARCHIVE_LOCATION_SUCCEED, number, string>;
export const archiveLocationSuccess = (
    filterData: ArchiveFilterResponse,
): ArchiveLocationSuccessAction => ({
    type: ARCHIVE_LOCATION_SUCCEED,
    payload: filterData.id,
    meta: `Location ${filterData.name} archived`,
});

export const ARCHIVE_LOCATION_FAILED = 'ARCHIVE_LOCATION_FAILED';
export type ArchiveLocationFailureAction = Action<typeof ARCHIVE_LOCATION_FAILED, string>;
export const archiveLocationFailure = (payload: string): ArchiveLocationFailureAction => ({
    type: ARCHIVE_LOCATION_FAILED,
    payload,
});

export const ARCHIVE_CONTRACT_PRIORITY_REQUESTED = 'ARCHIVE_CONTRACT_PRIORITY_REQUESTED';
export type ArchiveContractPriorityRequestAction = Action<
    typeof ARCHIVE_CONTRACT_PRIORITY_REQUESTED,
    ArchiveFilterRequest
>;
export const archiveContractPriorityRequest = (
    filterData: ArchiveFilterRequest,
): ArchiveContractPriorityRequestAction => ({
    type: ARCHIVE_CONTRACT_PRIORITY_REQUESTED,
    payload: filterData,
});

export const ARCHIVE_CONTRACT_PRIORITY_SUCCEED = 'ARCHIVE_CONTRACT_PRIORITY_SUCCEED';
export type ArchiveContractPrioritySuccessAction = Action<
    typeof ARCHIVE_CONTRACT_PRIORITY_SUCCEED,
    number,
    string
>;
export const archiveContractPrioritySuccess = (
    filterData: ArchiveFilterResponse,
): ArchiveContractPrioritySuccessAction => ({
    type: ARCHIVE_CONTRACT_PRIORITY_SUCCEED,
    payload: filterData.id,
    meta: `Project Priority ${filterData.name} archived`,
});

export const ARCHIVE_CONTRACT_PRIORITY_FAILED = 'ARCHIVE_CONTRACT_PRIORITY_FAILED';
export type ArchiveContractPriorityFailureAction = Action<
    typeof ARCHIVE_CONTRACT_PRIORITY_FAILED,
    string
>;
export const archiveContractPriorityFailure = (
    payload: string,
): ArchiveContractPriorityFailureAction => ({
    type: ARCHIVE_CONTRACT_PRIORITY_FAILED,
    payload,
});

export const ARCHIVE_BENEFIT_PRIORITY_REQUESTED = 'ARCHIVE_BENEFIT_PRIORITY_REQUESTED';
export type ArchiveBenefitPriorityRequestAction = Action<
    typeof ARCHIVE_BENEFIT_PRIORITY_REQUESTED,
    ArchiveFilterRequest
>;
export const archiveBenefitPriorityRequest = (
    filterData: ArchiveFilterRequest,
): ArchiveBenefitPriorityRequestAction => ({
    type: ARCHIVE_BENEFIT_PRIORITY_REQUESTED,
    payload: filterData,
});

export const ARCHIVE_BENEFIT_PRIORITY_SUCCEED = 'ARCHIVE_BENEFIT_PRIORITY_SUCCEED';
export type ArchiveBenefitPrioritySuccessAction = Action<
    typeof ARCHIVE_BENEFIT_PRIORITY_SUCCEED,
    number,
    string
>;
export const archiveBenefitPrioritySuccess = (
    filterData: ArchiveFilterResponse,
): ArchiveBenefitPrioritySuccessAction => ({
    type: ARCHIVE_BENEFIT_PRIORITY_SUCCEED,
    payload: filterData.id,
    meta: `Benefit Priority ${filterData.name} archived`,
});

export const ARCHIVE_BENEFIT_PRIORITY_FAILED = 'ARCHIVE_BENEFIT_PRIORITY_FAILED';
export type ArchiveBenefitPriorityFailureAction = Action<
    typeof ARCHIVE_BENEFIT_PRIORITY_FAILED,
    string
>;
export const archiveBenefitPriorityFailure = (
    payload: string,
): ArchiveBenefitPriorityFailureAction => ({
    type: ARCHIVE_BENEFIT_PRIORITY_FAILED,
    payload,
});

export const ARCHIVE_DEPARTMENT_REQUESTED = 'ARCHIVE_DEPARTMENT_REQUESTED';
export type ArchiveDepartmentRequestAction = Action<
    typeof ARCHIVE_DEPARTMENT_REQUESTED,
    ArchiveFilterRequest
>;
export const archiveDepartmentRequest = (
    filterData: ArchiveFilterRequest,
): ArchiveDepartmentRequestAction => ({
    type: ARCHIVE_DEPARTMENT_REQUESTED,
    payload: filterData,
});

export const ARCHIVE_DEPARTMENT_SUCCEED = 'ARCHIVE_DEPARTMENT_SUCCEED';
export type ArchiveDepartmentSuccessAction = Action<
    typeof ARCHIVE_DEPARTMENT_SUCCEED,
    number,
    string
>;
export const archiveDepartmentSuccess = (
    filterData: ArchiveFilterResponse,
): ArchiveDepartmentSuccessAction => ({
    type: ARCHIVE_DEPARTMENT_SUCCEED,
    payload: filterData.id,
    meta: `Department ${filterData.name} archived`,
});

export const ARCHIVE_DEPARTMENT_FAILED = 'ARCHIVE_DEPARTMENT_FAILED';
export type ArchiveDepartmentFailureAction = Action<typeof ARCHIVE_DEPARTMENT_FAILED, string>;
export const archiveDepartmentFailure = (payload: string): ArchiveDepartmentFailureAction => ({
    type: ARCHIVE_DEPARTMENT_FAILED,
    payload,
});

export const DELETE_CONTRACT_REQUESTED = 'DELETE_CONTRACT_REQUESTED';
export type DeleteContractRequestAction = Action<typeof DELETE_CONTRACT_REQUESTED, number>;
export const deleteContractRequest = (contractId: number): DeleteContractRequestAction => ({
    type: DELETE_CONTRACT_REQUESTED,
    payload: contractId,
});

export const DELETE_CONTRACT_SUCCEED = 'DELETE_CONTRACT_SUCCEED';
export type DeleteContractSuccessAction = Action<typeof DELETE_CONTRACT_SUCCEED, string, string>;
export const deleteContractSuccess = (): DeleteContractSuccessAction => ({
    type: DELETE_CONTRACT_SUCCEED,
    payload: EditPaths.Contracts,
    meta: 'Project deleted',
});

export const DELETE_CONTRACT_FAILED = 'DELETE_CONTRACT_FAILED';
export type DeleteContractFailureAction = Action<typeof DELETE_CONTRACT_FAILED, string>;
export const deleteContractFailure = (payload: string): DeleteContractFailureAction => ({
    type: DELETE_CONTRACT_FAILED,
    payload,
});

export const EDIT_CONTRACT_REQUESTED = 'EDIT_CONTRACT_REQUESTED';
export type EditContractRequestAction = Action<typeof EDIT_CONTRACT_REQUESTED, Contract>;
export const editContractRequest = (contract: Contract): EditContractRequestAction => ({
    type: EDIT_CONTRACT_REQUESTED,
    payload: contract,
});

export const EDIT_CONTRACT_SUCCEED = 'EDIT_CONTRACT_SUCCEED';
export type EditContractSuccessAction = Action<typeof EDIT_CONTRACT_SUCCEED, null, string>;
export const editContractSuccess = (): EditContractSuccessAction => ({
    type: EDIT_CONTRACT_SUCCEED,
    meta: 'Project edited',
});

export const EDIT_CONTRACT_FAILED = 'EDIT_CONTRACT_FAILED';
export type EditContractFailureAction = Action<typeof EDIT_CONTRACT_FAILED, string>;
export const editContractFailure = (payload: string): EditContractFailureAction => ({
    type: EDIT_CONTRACT_FAILED,
    payload,
});

export const EDIT_FILTER_REQUESTED = 'EDIT_FILTER_REQUESTED';
export type EditFilterRequestAction = Action<typeof EDIT_FILTER_REQUESTED, EditFilterRequest>;
export const editFilterRequest = (filterData: EditFilterRequest): EditFilterRequestAction => ({
    type: EDIT_FILTER_REQUESTED,
    payload: filterData,
});

export const EDIT_FILTER_SUCCEED = 'EDIT_FILTER_SUCCEED';
export type EditFilterSuccessAction = Action<typeof EDIT_FILTER_SUCCEED, Filters, string>;
export const editFilterSuccess = (filterData: Filters): EditFilterSuccessAction => ({
    type: EDIT_FILTER_SUCCEED,
    payload: filterData,
    meta: `${filterData.name} edited`,
});

export const EDIT_FILTER_FAILED = 'EDIT_FILTER_FAILED';
export type EditFilterFailureAction = Action<typeof EDIT_FILTER_FAILED, string>;
export const editFilterFailure = (payload: string): EditFilterFailureAction => ({
    type: EDIT_FILTER_FAILED,
    payload,
});

export const GET_CONTRACT_DETAILS_REQUESTED = 'GET_CONTRACT_DETAILS_REQUESTED';
export type GetContractDetailsRequestAction = Action<typeof GET_CONTRACT_DETAILS_REQUESTED>;
export const getContractDetailsRequest = (): GetContractDetailsRequestAction => ({
    type: GET_CONTRACT_DETAILS_REQUESTED,
});

export const GET_CONTRACT_DETAILS_SUCCEED = 'GET_CONTRACT_DETAILS_SUCCEED';
export type GetContractDetailsSuccessAction = Action<
    typeof GET_CONTRACT_DETAILS_SUCCEED,
    ContractRequestData
>;
export const getContractDetailsSuccess = (
    contractDetails: ContractRequestData,
): GetContractDetailsSuccessAction => ({
    type: GET_CONTRACT_DETAILS_SUCCEED,
    payload: contractDetails,
});

export const GET_CONTRACT_DETAILS_FAILED = 'GET_CONTRACT_DETAILS_FAILED';
export type GetContractDetailsFailureAction = Action<typeof GET_CONTRACT_DETAILS_FAILED, string>;
export const getContractDetailsFailure = (payload: string): GetContractDetailsFailureAction => ({
    type: GET_CONTRACT_DETAILS_FAILED,
    payload,
});

export const GET_CONTRACTS_SUCCEED = 'GET_CONTRACTS_SUCCEED';
export type GetContractsSuccessAction = Action<
    typeof GET_CONTRACTS_SUCCEED,
    { contracts: Contract[] }
>;
export const getContractsSuccess = (contracts: Contract[]): GetContractsSuccessAction => ({
    type: GET_CONTRACTS_SUCCEED,
    payload: { contracts },
});

export const GET_ALL_CONTRACTS_REQUESTED = 'GET_ALL_CONTRACTS_REQUESTED';
export type GetAllContractsRequestAction = Action<typeof GET_ALL_CONTRACTS_REQUESTED>;
export const getAllContractsRequest = (): GetAllContractsRequestAction => ({
    type: GET_ALL_CONTRACTS_REQUESTED,
});

export const GET_ALL_CONTRACTS_SUCCEED = 'GET_ALL_CONTRACTS_SUCCEED';
export type GetAllContractsSuccessAction = Action<
    typeof GET_ALL_CONTRACTS_SUCCEED,
    { contracts: Contract[] }
>;
export const getAllContractsSuccess = (contracts: Contract[]): GetAllContractsSuccessAction => ({
    type: GET_ALL_CONTRACTS_SUCCEED,
    payload: { contracts },
});

export const GET_ALL_CONTRACTS_FAILED = 'GET_ALL_CONTRACTS_FAILED';
export type GetAllContractsFailureAction = Action<typeof GET_ALL_CONTRACTS_FAILED, string>;
export const getAllContractsFailure = (payload: string): GetAllContractsFailureAction => ({
    type: GET_ALL_CONTRACTS_FAILED,
    payload,
});

export const GET_MY_CONTRACTS_REQUESTED = 'GET_MY_CONTRACTS_REQUESTED';
export type GetMyContractsRequestAction = Action<typeof GET_MY_CONTRACTS_REQUESTED>;
export const getMyContractsRequest = (): GetMyContractsRequestAction => ({
    type: GET_MY_CONTRACTS_REQUESTED,
});

export const GET_MY_CONTRACTS_SUCCEED = 'GET_MY_CONTRACTS_SUCCEED';
export type GetMyContractsSuccessAction = Action<
    typeof GET_MY_CONTRACTS_SUCCEED,
    { myContracts: Contract[] }
>;
export const getMyContractsSuccess = (myContracts: Contract[]): GetMyContractsSuccessAction => ({
    type: GET_MY_CONTRACTS_SUCCEED,
    payload: { myContracts },
});

export const GET_MY_CONTRACTS_FAILED = 'GET_MY_CONTRACTS_FAILED';
export type GetMyContractsFailureAction = Action<typeof GET_MY_CONTRACTS_FAILED, string>;
export const getMyContractsFailure = (payload: string): GetMyContractsFailureAction => ({
    type: GET_MY_CONTRACTS_FAILED,
    payload,
});

export const GET_RAG_CONTRACTS_SUCCEED = 'GET_RAG_CONTRACTS_SUCCEED';
export type GetRagContractsSuccessAction = Action<
    typeof GET_RAG_CONTRACTS_SUCCEED,
    { contractsWithRag: ContractWithRAG[] }
>;
export const getRagContractsSuccess = (
    contractsWithRag: ContractWithRAG[],
): GetRagContractsSuccessAction => ({
    type: GET_RAG_CONTRACTS_SUCCEED,
    payload: { contractsWithRag },
});

export const GET_CONTRACT_PRIORITY_DASHBOARD_SUCCEED = 'GET_CONTRACT_PRIORITY_DASHBOARD_SUCCEED';
export type GetContractPriorityDashboardSuccessAction = Action<
    typeof GET_CONTRACT_PRIORITY_DASHBOARD_SUCCEED,
    { priorityDashboard: ContractPriorityDashboardItem[] }
>;
export const getContractPriorityDashboardSuccess = (
    priorityDashboard: ContractPriorityDashboardItem[],
): GetContractPriorityDashboardSuccessAction => ({
    type: GET_CONTRACT_PRIORITY_DASHBOARD_SUCCEED,
    payload: { priorityDashboard },
});

export const GET_CONTRACT_ROLES_REQUESTED = 'GET_CONTRACT_ROLES_REQUESTED';
export type GetContractRolesRequestAction = Action<typeof GET_CONTRACT_ROLES_REQUESTED, number>;
export const getContractRolesRequest = (contractId: number): GetContractRolesRequestAction => ({
    type: GET_CONTRACT_ROLES_REQUESTED,
    payload: contractId,
});

export const GET_CONTRACT_ROLES_SUCCEED = 'GET_CONTRACT_ROLES_SUCCEED';
export type GetContractRolesSuccessAction = Action<
    typeof GET_CONTRACT_ROLES_SUCCEED,
    { contractRoles: ContractRoleData[] }
>;
export const getContractRolesSuccess = (
    contractRoles: ContractRoleData[],
): GetContractRolesSuccessAction => ({
    type: GET_CONTRACT_ROLES_SUCCEED,
    payload: { contractRoles },
});

export const GET_CONTRACT_ROLES_FAILED = 'GET_CONTRACT_ROLES_FAILED';
export type GetContractRolesFailureAction = Action<typeof GET_CONTRACT_ROLES_FAILED, string>;
export const getContractRolesFailure = (payload: string): GetContractRolesFailureAction => ({
    type: GET_CONTRACT_ROLES_FAILED,
    payload,
});

export const GET_CONTRACT_REQUESTED = 'GET_CONTRACT_REQUESTED';
export type GetContractRequestAction = Action<typeof GET_CONTRACT_REQUESTED, GetContractRequest>;
export const getContractRequest = (request: GetContractRequest): GetContractRequestAction => ({
    type: GET_CONTRACT_REQUESTED,
    payload: request,
});

export const GET_CONTRACT_SUCCEED = 'GET_CONTRACT_SUCCEED';
export type GetContractSuccessAction = Action<typeof GET_CONTRACT_SUCCEED, Contract>;
export const getContractSuccess = (contract: Contract): GetContractSuccessAction => ({
    type: GET_CONTRACT_SUCCEED,
    payload: contract,
});

export const GET_CONTRACT_FAILED = 'GET_CONTRACT_FAILED';
export type GetContractFailureAction = Action<typeof GET_CONTRACT_FAILED, string>;
export const getContractFailure = (payload: string): GetContractFailureAction => ({
    type: GET_CONTRACT_FAILED,
    payload,
});

export const GET_CATEGORIES_REQUESTED = 'GET_CATEGORIES_REQUESTED';
export type GetCategoriesRequestAction = Action<typeof GET_CATEGORIES_REQUESTED>;
export const getCategoriesRequest = (): GetCategoriesRequestAction => ({
    type: GET_CATEGORIES_REQUESTED,
});

export const GET_CATEGORIES_SUCCEED = 'GET_CATEGORIES_SUCCEED';
export type GetCategoriesSuccessAction = Action<
    typeof GET_CATEGORIES_SUCCEED,
    { categories: Filters[] }
>;
export const getCategoriesSuccess = (categories: Filters[]): GetCategoriesSuccessAction => ({
    type: GET_CATEGORIES_SUCCEED,
    payload: { categories },
});

export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';
export type GetCategoriesFailureAction = Action<typeof GET_CATEGORIES_FAILED, string>;
export const getCategoriesFailure = (payload: string): GetCategoriesFailureAction => ({
    type: GET_CATEGORIES_FAILED,
    payload,
});

export const GET_PORTFOLIO_FILTERS_REQUESTED = 'GET_PORTFOLIO_FILTERS_REQUESTED';
export type GetPortfolioFiltersRequestAction = Action<typeof GET_PORTFOLIO_FILTERS_REQUESTED>;
export const getPortfolioFiltersRequest = (): GetPortfolioFiltersRequestAction => ({
    type: GET_PORTFOLIO_FILTERS_REQUESTED,
});

export const GET_PORTFOLIO_FILTERS_SUCCEED = 'GET_PORTFOLIO_FILTERS_SUCCEED';
export type GetPortfolioFiltersSuccessAction = Action<
    typeof GET_PORTFOLIO_FILTERS_SUCCEED,
    PortfolioFilters
>;
export const getPortfolioFiltersSuccess = (
    portfolioFilters: Filters[],
): GetPortfolioFiltersSuccessAction => ({
    type: GET_PORTFOLIO_FILTERS_SUCCEED,
    payload: { portfolioFilters },
});

export const GET_PORTFOLIO_FILTERS_FAILED = 'GET_PORTFOLIO_FILTERS_FAILED';
export type GetPortfolioFiltersFailureAction = Action<typeof GET_PORTFOLIO_FILTERS_FAILED, string>;
export const getPortfolioFiltersFailure = (payload: string): GetPortfolioFiltersFailureAction => ({
    type: GET_PORTFOLIO_FILTERS_FAILED,
    payload,
});

export const GET_DEPARTMENTS_REQUESTED = 'GET_DEPARTMENTS_REQUESTED';
export type GetDepartmentsRequestAction = Action<typeof GET_DEPARTMENTS_REQUESTED>;
export const getDepartmentsRequest = (): GetDepartmentsRequestAction => ({
    type: GET_DEPARTMENTS_REQUESTED,
});

export const GET_DEPARTMENTS_SUCCEED = 'GET_DEPARTMENTS_SUCCEED';
export type GetDepartmentsSuccessAction = Action<
    typeof GET_DEPARTMENTS_SUCCEED,
    { departments: Filters[] }
>;
export const getDepartmentsSuccess = (departments: Filters[]): GetDepartmentsSuccessAction => ({
    type: GET_DEPARTMENTS_SUCCEED,
    payload: { departments },
});

export const GET_DEPARTMENTS_FAILED = 'GET_DEPARTMENTS_FAILED';
export type GetDepartmentsFailureAction = Action<typeof GET_DEPARTMENTS_FAILED, string>;
export const getDepartmentsFailure = (payload: string): GetDepartmentsFailureAction => ({
    type: GET_DEPARTMENTS_FAILED,
    payload,
});

export const GET_LOCATIONS_REQUESTED = 'GET_LOCATIONS_REQUESTED';
export type GetLocationsRequestAction = Action<typeof GET_LOCATIONS_REQUESTED>;
export const getLocationsRequest = (): GetLocationsRequestAction => ({
    type: GET_LOCATIONS_REQUESTED,
});

export const GET_LOCATIONS_SUCCEED = 'GET_LOCATIONS_SUCCEED';
export type GetLocationsSuccessAction = Action<
    typeof GET_LOCATIONS_SUCCEED,
    { locations: Filters[] }
>;
export const getLocationsSuccess = (locations: Filters[]): GetLocationsSuccessAction => ({
    type: GET_LOCATIONS_SUCCEED,
    payload: { locations },
});

export const GET_LOCATIONS_FAILED = 'GET_LOCATIONS_FAILED';
export type GetLocationsFailureAction = Action<typeof GET_LOCATIONS_FAILED, string>;
export const getLocationsFailure = (payload: string): GetLocationsFailureAction => ({
    type: GET_LOCATIONS_FAILED,
    payload,
});

export const GET_CONTRACT_PRIORITIES_REQUESTED = 'GET_CONTRACT_PRIORITIES_REQUESTED';
export type GetContractPrioritiesRequestAction = Action<
    typeof GET_CONTRACT_PRIORITIES_REQUESTED,
    number
>;
export const getContractPrioritiesRequest = (
    contractId: number,
): GetContractPrioritiesRequestAction => ({
    type: GET_CONTRACT_PRIORITIES_REQUESTED,
    payload: contractId,
});

export const GET_CONTRACT_PRIORITIES_SUCCEED = 'GET_CONTRACT_PRIORITIES_SUCCEED';
export type GetContractPrioritiesSuccessAction = Action<
    typeof GET_CONTRACT_PRIORITIES_SUCCEED,
    { priorities: ContractPriorities[] }
>;
export const getContractPrioritiesSuccess = (
    priorities: ContractPriorities[],
): GetContractPrioritiesSuccessAction => ({
    type: GET_CONTRACT_PRIORITIES_SUCCEED,
    payload: { priorities },
});

export const GET_CONTRACT_PRIORITIES_FAILED = 'GET_CONTRACT_PRIORITIES_FAILED';
export type GetContractPrioritiesFailureAction = Action<
    typeof GET_CONTRACT_PRIORITIES_FAILED,
    string
>;
export const getContractPrioritiesFailure = (
    payload: string,
): GetContractPrioritiesFailureAction => ({
    type: GET_CONTRACT_PRIORITIES_FAILED,
    payload,
});

export const ADD_CONTRACT_PRIORITY_REQUESTED = 'ADD_CONTRACT_PRIORITY_REQUESTED';
export type AddContractPriorityRequestAction = Action<
    typeof ADD_CONTRACT_PRIORITY_REQUESTED,
    AddContractPriorityRequest
>;
export const addContractPriorityRequest = (
    payload: AddContractPriorityRequest,
): AddContractPriorityRequestAction => ({
    type: ADD_CONTRACT_PRIORITY_REQUESTED,
    payload,
});

export const ADD_CONTRACT_PRIORITY_SUCCEED = 'ADD_CONTRACT_PRIORITY_SUCCEED';
export type AddContractPrioritySuccessAction = Action<
    typeof ADD_CONTRACT_PRIORITY_SUCCEED,
    { priorities: ContractPriorities[] }
>;
export const addContractPrioritySuccess = (
    priorities: ContractPriorities[],
): AddContractPrioritySuccessAction => ({
    type: ADD_CONTRACT_PRIORITY_SUCCEED,
    payload: { priorities },
});

export const ADD_CONTRACT_PRIORITY_FAILED = 'ADD_CONTRACT_PRIORITY_FAILED';
export type AddContractPriorityFailureAction = Action<typeof ADD_CONTRACT_PRIORITY_FAILED, string>;
export const addContractPriorityFailure = (payload: string): AddContractPriorityFailureAction => ({
    type: ADD_CONTRACT_PRIORITY_FAILED,
    payload,
});

export const EDIT_CONTRACT_PRIORITY_REQUESTED = 'EDIT_CONTRACT_PRIORITY_REQUESTED';
export type EditContractPriorityRequestAction = Action<
    typeof EDIT_CONTRACT_PRIORITY_REQUESTED,
    EditContractPriorityRequest
>;
export const editContractPriorityRequest = (
    priority: EditContractPriorityRequest,
): EditContractPriorityRequestAction => ({
    type: EDIT_CONTRACT_PRIORITY_REQUESTED,
    payload: priority,
});

export const EDIT_CONTRACT_PRIORITY_SUCCEED = 'EDIT_CONTRACT_PRIORITY_SUCCEED';
export type EditContractPrioritySuccessAction = Action<
    typeof EDIT_CONTRACT_PRIORITY_SUCCEED,
    ContractPriorities,
    string
>;
export const editContractPrioritySuccess = (
    priority: ContractPriorities,
): EditContractPrioritySuccessAction => ({
    type: EDIT_CONTRACT_PRIORITY_SUCCEED,
    payload: priority,
    meta: 'Priority updated',
});

export const EDIT_CONTRACT_PRIORITY_FAILED = 'EDIT_CONTRACT_PRIORITY_FAILED';
export type EditContractPriorityFailureAction = Action<
    typeof EDIT_CONTRACT_PRIORITY_FAILED,
    string
>;
export const editContractPriorityFail = (payload: string): EditContractPriorityFailureAction => ({
    type: EDIT_CONTRACT_PRIORITY_FAILED,
    payload,
});

export const DELETE_CONTRACT_PRIORITY_REQUESTED = 'DELETE_CONTRACT_PRIORITY_REQUESTED';
export type DeleteContractPriorityRequestAction = Action<
    typeof DELETE_CONTRACT_PRIORITY_REQUESTED,
    EditContractPriorityRequest
>;
export const deleteContractPriorityRequest = (
    data: EditContractPriorityRequest,
): DeleteContractPriorityRequestAction => ({
    type: DELETE_CONTRACT_PRIORITY_REQUESTED,
    payload: data,
});

export const DELETE_CONTRACT_PRIORITY_SUCCEED = 'DELETE_CONTRACT_PRIORITY_SUCCEED';
export type DeleteContractPrioritySuccessAction = Action<
    typeof DELETE_CONTRACT_PRIORITY_SUCCEED,
    number,
    string
>;
export const deleteContractPrioritySuccess = (
    ContractId: number,
): DeleteContractPrioritySuccessAction => ({
    type: DELETE_CONTRACT_PRIORITY_SUCCEED,
    payload: ContractId,
    meta: 'Project priority deleted',
});

export const DELETE_CONTRACT_PRIORITY_FAILED = 'DELETE_CONTRACT_PRIORITY_FAILED';
export type DeleteContractPriorityFailureAction = Action<
    typeof DELETE_CONTRACT_PRIORITY_FAILED,
    string
>;
export const deleteContractPriorityFailure = (
    payload: string,
): DeleteContractPriorityFailureAction => ({
    type: DELETE_CONTRACT_PRIORITY_FAILED,
    payload,
});

export const GET_CONTRACT_PRIORITIES_FILTERS_REQUESTED =
    'GET_CONTRACT_PRIORITIES_FILTERS_REQUESTED';
export type GetContractPrioritiesFiltersRequestAction = Action<
    typeof GET_CONTRACT_PRIORITIES_FILTERS_REQUESTED
>;
export const getContractPrioritiesFiltersRequest =
    (): GetContractPrioritiesFiltersRequestAction => ({
        type: GET_CONTRACT_PRIORITIES_FILTERS_REQUESTED,
    });

export const GET_CONTRACT_PRIORITIES_FILTERS_SUCCEED = 'GET_CONTRACT_PRIORITIES_FILTERS_SUCCEED';
export type GetContractPrioritiesFiltersSuccessAction = Action<
    typeof GET_CONTRACT_PRIORITIES_FILTERS_SUCCEED,
    { contractPrioritiesFilters: Filters[] }
>;
export const getContractPrioritiesFiltersSuccess = (
    contractPrioritiesFilters: Filters[],
): GetContractPrioritiesFiltersSuccessAction => ({
    type: GET_CONTRACT_PRIORITIES_FILTERS_SUCCEED,
    payload: { contractPrioritiesFilters },
});

export const GET_CONTRACT_PRIORITIES_FILTERS_FAILED = 'GET_CONTRACT_PRIORITIES_FILTERS_FAILED';
export type GetContractPrioritiesFiltersFailureAction = Action<
    typeof GET_CONTRACT_PRIORITIES_FILTERS_FAILED,
    string
>;
export const getContractPrioritiesFiltersFailure = (
    payload: string,
): GetContractPrioritiesFiltersFailureAction => ({
    type: GET_CONTRACT_PRIORITIES_FILTERS_FAILED,
    payload,
});

export const GET_BENEFIT_PRIORITIES_FILTERS_REQUESTED = 'GET_BENEFIT_PRIORITIES_FILTERS_REQUESTED';
export type GetBenefitPrioritiesFiltersRequestAction = Action<
    typeof GET_BENEFIT_PRIORITIES_FILTERS_REQUESTED
>;
export const getBenefitPrioritiesFiltersRequest = (): GetBenefitPrioritiesFiltersRequestAction => ({
    type: GET_BENEFIT_PRIORITIES_FILTERS_REQUESTED,
});

export const GET_BENEFIT_PRIORITIES_FILTERS_SUCCEED = 'GET_BENEFIT_PRIORITIES_FILTERS_SUCCEED';
export type GetBenefitPrioritiesFiltersSuccessAction = Action<
    typeof GET_BENEFIT_PRIORITIES_FILTERS_SUCCEED,
    { benefitPrioritiesFilters: Filters[] }
>;
export const getBenefitPrioritiesFiltersSuccess = (
    benefitPrioritiesFilters: Filters[],
): GetBenefitPrioritiesFiltersSuccessAction => ({
    type: GET_BENEFIT_PRIORITIES_FILTERS_SUCCEED,
    payload: { benefitPrioritiesFilters },
});

export const GET_BENEFIT_PRIORITIES_FILTERS_FAILED = 'GET_BENEFIT_PRIORITIES_FILTERS_FAILED';
export type GetBenefitPrioritiesFiltersFailureAction = Action<
    typeof GET_BENEFIT_PRIORITIES_FILTERS_FAILED,
    string
>;
export const getBenefitPrioritiesFiltersFailure = (
    payload: string,
): GetBenefitPrioritiesFiltersFailureAction => ({
    type: GET_BENEFIT_PRIORITIES_FILTERS_FAILED,
    payload,
});

export const CREATE_CONTRACT_REQUESTED = 'CREATE_CONTRACT_REQUESTED';
export type CreateContractRequestAction = Action<typeof CREATE_CONTRACT_REQUESTED, Contract>;
export const createContractRequest = (contract: Contract): CreateContractRequestAction => ({
    type: CREATE_CONTRACT_REQUESTED,
    payload: contract,
});

export const CREATE_CONTRACT_SUCCEED = 'CREATE_CONTRACT_SUCCEED';
export type CreateContractSuccessAction = Action<
    typeof CREATE_CONTRACT_SUCCEED,
    null,
    string,
    posthog.CaptureResult | null
>;
export const createContractSuccess = (): CreateContractSuccessAction => ({
    type: CREATE_CONTRACT_SUCCEED,
    meta: 'Project created',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Contract created') : null,
});

export const CREATE_CONTRACT_FAILED = 'CREATE_CONTRACT_FAILED';
export type CreateContractFailureAction = Action<
    typeof CREATE_CONTRACT_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const createContractFailure = (payload: string): CreateContractFailureAction => ({
    type: CREATE_CONTRACT_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Project creation failed')
            : null,
});

export const CREATE_FILTER_REQUESTED = 'CREATE_FILTER_REQUESTED';
export type CreateFilterRequestAction = Action<typeof CREATE_FILTER_REQUESTED, CreateFilterRequest>;
export const createFilterRequest = (filter: CreateFilterRequest): CreateFilterRequestAction => ({
    type: CREATE_FILTER_REQUESTED,
    payload: filter,
});

export const CREATE_FILTER_SUCCEED = 'CREATE_FILTER_SUCCEED';
export type CreateFilterSuccessAction = Action<
    typeof CREATE_FILTER_SUCCEED,
    null,
    string,
    posthog.CaptureResult | null
>;
export const createFilterSuccess = (): CreateFilterSuccessAction => ({
    type: CREATE_FILTER_SUCCEED,
    meta: 'Filter created',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Filter created') : null,
});

export const CREATE_FILTER_FAILED = 'CREATE_FILTER_FAILED';
export type CreateFilterFailureAction = Action<
    typeof CREATE_FILTER_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const createFilterFailure = (payload: string): CreateFilterFailureAction => ({
    type: CREATE_FILTER_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Filter creation failed')
            : null,
});

export const FILTER_CONTRACTS_REQUESTED = 'FILTER_CONTRACTS_REQUESTED';
export type FilterContractsRequestAction = Action<
    typeof FILTER_CONTRACTS_REQUESTED,
    FilterContract
>;
export const filterContractsRequest = (contract: FilterContract): FilterContractsRequestAction => ({
    type: FILTER_CONTRACTS_REQUESTED,
    payload: contract,
});

export const FILTER_CONTRACTS_SUCCEED = 'FILTER_CONTRACTS_SUCCEED';
export type FilterContractsSuccessAction = Action<typeof FILTER_CONTRACTS_SUCCEED, ContractRequest>;
export const filterContractsSuccess = (
    contracts: ContractRequest,
): FilterContractsSuccessAction => ({
    type: FILTER_CONTRACTS_SUCCEED,
    payload: contracts,
});

export const FILTER_CONTRACTS_FAILED = 'FILTER_CONTRACTS_FAILED';
export type FilterContractsFailureAction = Action<typeof FILTER_CONTRACTS_FAILED, string>;
export const filterContractsFailure = (payload: string): FilterContractsFailureAction => ({
    type: FILTER_CONTRACTS_FAILED,
    payload,
});

export const ADD_CONTRACT_ROLE_REQUESTED = 'ADD_CONTRACT_ROLE_REQUESTED';
export type AddContractRoleRequestAction = Action<
    typeof ADD_CONTRACT_ROLE_REQUESTED,
    AddContractRoleRequest
>;
export const addContractRoleRequest = (
    addContractRole: AddContractRoleRequest,
): AddContractRoleRequestAction => ({
    type: ADD_CONTRACT_ROLE_REQUESTED,
    payload: addContractRole,
});

export const ADD_CONTRACT_ROLE_SUCCEED = 'ADD_CONTRACT_ROLE_SUCCEED';
export type AddContractRoleSuccessAction = Action<
    typeof ADD_CONTRACT_ROLE_SUCCEED,
    ContractRoleData,
    string
>;
export const addContractRoleSuccess = (role: ContractRoleData): AddContractRoleSuccessAction => ({
    type: ADD_CONTRACT_ROLE_SUCCEED,
    payload: role,
    meta: 'Role added',
});

export const ADD_CONTRACT_ROLE_FAILED = 'ADD_CONTRACT_ROLE_FAILED';
export type AddContractRoleFailAction = Action<typeof ADD_CONTRACT_ROLE_FAILED, string>;
export const addContractRoleFail = (payload: string): AddContractRoleFailAction => ({
    type: ADD_CONTRACT_ROLE_FAILED,
    payload,
});

export const DELETE_CONTRACT_ROLE_REQUESTED = 'DELETE_CONTRACT_ROLE_REQUESTED';
export type DeleteContractRoleRequestAction = Action<
    typeof DELETE_CONTRACT_ROLE_REQUESTED,
    DeleteContractRoleRequest
>;
export const deleteContractRoleRequest = (
    deleteContractRole: DeleteContractRoleRequest,
): DeleteContractRoleRequestAction => ({
    type: DELETE_CONTRACT_ROLE_REQUESTED,
    payload: deleteContractRole,
});

export const DELETE_CONTRACT_ROLE_SUCCEED = 'DELETE_CONTRACT_ROLE_SUCCEED';
export type DeleteContractRoleSuccessAction = Action<
    typeof DELETE_CONTRACT_ROLE_SUCCEED,
    number,
    string
>;
export const deleteContractRoleSuccess = (roleId: number): DeleteContractRoleSuccessAction => ({
    type: DELETE_CONTRACT_ROLE_SUCCEED,
    payload: roleId,
    meta: 'Role deleted',
});

export const DELETE_CONTRACT_ROLE_FAILED = 'DELETE_CONTRACT_ROLE_FAILED';
export type DeleteContractRoleFailureAction = Action<typeof DELETE_CONTRACT_ROLE_FAILED, string>;
export const deleteContractRoleFail = (payload: string): DeleteContractRoleFailureAction => ({
    type: DELETE_CONTRACT_ROLE_FAILED,
    payload,
});

export const SELECT_CONTRACT_ORGANISATION = 'SELECT_CONTRACT_ORGANISATION';
export type SelectContractOrganisationAction = Action<typeof SELECT_CONTRACT_ORGANISATION, number>;
export const selectContractOrganisation = (
    organisationId: number,
): SelectContractOrganisationAction => ({
    type: SELECT_CONTRACT_ORGANISATION,
    payload: organisationId,
});

export const SET_DATE_RANGE_CONTRACT_DASHBOARD = 'SET_DATE_RANGE_CONTRACT_DASHBOARD';
export type SetDateRangeContractDashboardAction = Action<
    typeof SET_DATE_RANGE_CONTRACT_DASHBOARD,
    string[]
>;
export const setDateRangeContractDashboard = (
    contractFilterDateRange: string[],
): SetDateRangeContractDashboardAction => ({
    type: SET_DATE_RANGE_CONTRACT_DASHBOARD,
    payload: contractFilterDateRange,
});

export const SET_DATE_RANGE_CONTRACT_PRIORITIES_DASHBOARD =
    'SET_DATE_RANGE_CONTRACT_PRIORITIES_DASHBOARD';
export type SetDateRangeContractPrioritiesDashboardAction = Action<
    typeof SET_DATE_RANGE_CONTRACT_PRIORITIES_DASHBOARD,
    string[]
>;
export const setDateRangeContractPrioritiesDashboard = (
    contractPrioritiesFilterDateRange: string[],
): SetDateRangeContractPrioritiesDashboardAction => ({
    type: SET_DATE_RANGE_CONTRACT_PRIORITIES_DASHBOARD,
    payload: contractPrioritiesFilterDateRange,
});

export const DELETE_CONTRACT_CATEGORY_FILTER_REQUESTED =
    'DELETE_CONTRACT_CATEGORY_FILTER_REQUESTED';
export type DeleteContractCategoryFilterRequestAction = Action<
    typeof DELETE_CONTRACT_CATEGORY_FILTER_REQUESTED,
    EditFilterRequest
>;
export const deleteContractCategoryFilterRequest = (
    filterData: EditFilterRequest,
): DeleteContractCategoryFilterRequestAction => ({
    type: DELETE_CONTRACT_CATEGORY_FILTER_REQUESTED,
    payload: filterData,
});

export const DELETE_CONTRACT_CATEGORY_FILTER_SUCCEED = 'DELETE_CONTRACT_CATEGORY_FILTER_SUCCEED';
export type DeleteContractCategoryFilterSuccessAction = Action<
    typeof DELETE_CONTRACT_CATEGORY_FILTER_SUCCEED,
    Filters,
    string
>;
export const deleteContractCategoryFilterSuccess = (
    filterData: Filters,
): DeleteContractCategoryFilterSuccessAction => ({
    type: DELETE_CONTRACT_CATEGORY_FILTER_SUCCEED,
    payload: filterData,
    meta: 'Project category deleted',
});

export const DELETE_CONTRACT_CATEGORY_FILTER_FAILED = 'DELETE_CONTRACT_CATEGORY_FILTER_FAILED';
export type DeleteContractCategoryFilterFailureAction = Action<
    typeof DELETE_CONTRACT_CATEGORY_FILTER_FAILED,
    string
>;
export const deleteContractCategoryFilterFailure = (
    payload: string,
): DeleteContractCategoryFilterFailureAction => ({
    type: DELETE_CONTRACT_CATEGORY_FILTER_FAILED,
    payload,
});

export const DELETE_CONTRACT_PRIORITY_FILTER_REQUESTED =
    'DELETE_CONTRACT_PRIORITY_FILTER_REQUESTED';
export type DeleteContractPriorityFilterRequestAction = Action<
    typeof DELETE_CONTRACT_PRIORITY_FILTER_REQUESTED,
    EditFilterRequest
>;
export const deleteContractPriorityFilterRequest = (
    filterData: EditFilterRequest,
): DeleteContractPriorityFilterRequestAction => ({
    type: DELETE_CONTRACT_PRIORITY_FILTER_REQUESTED,
    payload: filterData,
});

export const DELETE_CONTRACT_PRIORITY_FILTER_SUCCEED = 'DELETE_CONTRACT_PRIORITY_FILTER_SUCCEED';
export type DeleteContractPriorityFilterSuccessAction = Action<
    typeof DELETE_CONTRACT_PRIORITY_FILTER_SUCCEED,
    Filters,
    string
>;
export const deleteContractPriorityFilterSuccess = (
    filterData: Filters,
): DeleteContractPriorityFilterSuccessAction => ({
    type: DELETE_CONTRACT_PRIORITY_FILTER_SUCCEED,
    payload: filterData,
    meta: 'Project priority deleted',
});

export const DELETE_CONTRACT_PRIORITY_FILTER_FAILED = 'DELETE_CONTRACT_PRIORITY_FILTER_FAILED';
export type DeleteContractPriorityFilterFailureAction = Action<
    typeof DELETE_CONTRACT_PRIORITY_FILTER_FAILED,
    string
>;
export const deleteContractPriorityFilterFailure = (
    payload: string,
): DeleteContractPriorityFilterFailureAction => ({
    type: DELETE_CONTRACT_PRIORITY_FILTER_FAILED,
    payload,
});

export const DELETE_BENEFIT_PRIORITY_FILTER_REQUESTED = 'DELETE_BENEFIT_PRIORITY_FILTER_REQUESTED';
export type DeleteBenefitPriorityFilterRequestAction = Action<
    typeof DELETE_BENEFIT_PRIORITY_FILTER_REQUESTED,
    EditFilterRequest
>;
export const deleteBenefitPriorityFilterRequest = (
    filterData: EditFilterRequest,
): DeleteBenefitPriorityFilterRequestAction => ({
    type: DELETE_BENEFIT_PRIORITY_FILTER_REQUESTED,
    payload: filterData,
});

export const DELETE_BENEFIT_PRIORITY_FILTER_SUCCEED = 'DELETE_BENEFIT_PRIORITY_FILTER_SUCCEED';
export type DeleteBenefitPriorityFilterSuccessAction = Action<
    typeof DELETE_BENEFIT_PRIORITY_FILTER_SUCCEED,
    Filters,
    string
>;
export const deleteBenefitPriorityFilterSuccess = (
    filterData: Filters,
): DeleteBenefitPriorityFilterSuccessAction => ({
    type: DELETE_BENEFIT_PRIORITY_FILTER_SUCCEED,
    payload: filterData,
    meta: 'Benefit priority deleted',
});

export const DELETE_BENEFIT_PRIORITY_FILTER_FAILED = 'DELETE_BENEFIT_PRIORITY_FILTER_FAILED';
export type DeleteBenefitPriorityFilterFailureAction = Action<
    typeof DELETE_BENEFIT_PRIORITY_FILTER_FAILED,
    string
>;
export const deleteBenefitPriorityFilterFailure = (
    payload: string,
): DeleteBenefitPriorityFilterFailureAction => ({
    type: DELETE_BENEFIT_PRIORITY_FILTER_FAILED,
    payload,
});

export const DELETE_DEPARTMENT_FILTER_REQUESTED = 'DELETE_DEPARTMENT_FILTER_REQUESTED';
export type DeleteDepartmentFilterRequestAction = Action<
    typeof DELETE_DEPARTMENT_FILTER_REQUESTED,
    EditFilterRequest
>;
export const deleteDepartmentFilterRequest = (
    filterData: EditFilterRequest,
): DeleteDepartmentFilterRequestAction => ({
    type: DELETE_DEPARTMENT_FILTER_REQUESTED,
    payload: filterData,
});

export const DELETE_DEPARTMENT_FILTER_SUCCEED = 'DELETE_DEPARTMENT_FILTER_SUCCEED';
export type DeleteDepartmentFilterSuccessAction = Action<
    typeof DELETE_DEPARTMENT_FILTER_SUCCEED,
    Filters,
    string
>;
export const deleteDepartmentFilterSuccess = (
    filterData: Filters,
): DeleteDepartmentFilterSuccessAction => ({
    type: DELETE_DEPARTMENT_FILTER_SUCCEED,
    payload: filterData,
    meta: 'Department deleted',
});

export const DELETE_DEPARTMENT_FILTER_FAILED = 'DELETE_DEPARTMENT_FILTER_FAILED';
export type DeleteDepartmentFilterFailureAction = Action<
    typeof DELETE_DEPARTMENT_FILTER_FAILED,
    string
>;
export const deleteDepartmentFilterFailure = (
    payload: string,
): DeleteDepartmentFilterFailureAction => ({
    type: DELETE_DEPARTMENT_FILTER_FAILED,
    payload,
});

export const DELETE_LOCATION_FILTER_REQUESTED = 'DELETE_LOCATION_FILTER_REQUESTED';
export type DeleteLocationFilterRequestAction = Action<
    typeof DELETE_LOCATION_FILTER_REQUESTED,
    EditFilterRequest
>;
export const deleteLocationFilterRequest = (
    filterData: EditFilterRequest,
): DeleteLocationFilterRequestAction => ({
    type: DELETE_LOCATION_FILTER_REQUESTED,
    payload: filterData,
});

export const DELETE_LOCATION_FILTER_SUCCEED = 'DELETE_LOCATION_FILTER_SUCCEED';
export type DeleteLocationFilterSuccessAction = Action<
    typeof DELETE_LOCATION_FILTER_SUCCEED,
    Filters,
    string
>;
export const deleteLocationFilterSuccess = (
    filterData: Filters,
): DeleteLocationFilterSuccessAction => ({
    type: DELETE_LOCATION_FILTER_SUCCEED,
    payload: filterData,
    meta: 'Location deleted',
});

export const DELETE_LOCATION_FILTER_FAILED = 'DELETE_LOCATION_FILTER_FAILED';
export type DeleteLocationFilterFailureAction = Action<
    typeof DELETE_LOCATION_FILTER_FAILED,
    string
>;
export const deleteLocationFilterFailure = (
    payload: string,
): DeleteLocationFilterFailureAction => ({
    type: DELETE_LOCATION_FILTER_FAILED,
    payload,
});

export const TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_REQUESTED =
    'TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_REQUESTED';
export type ToggleMultipleEmployeesContractRoleRequestAction = Action<
    typeof TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_REQUESTED,
    ToggleMultipleEmployeesContractRoleRequest
>;
export const toggleMultipleEmployeesContractRoleRequest = (
    assignMultiple: ToggleMultipleEmployeesContractRoleRequest,
): ToggleMultipleEmployeesContractRoleRequestAction => ({
    type: TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_REQUESTED,
    payload: assignMultiple,
});

export const TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_SUCCEED =
    'TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_SUCCEED';
export type ToggleMultipleEmployeesContractRoleSuccessAction = Action<
    typeof TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_SUCCEED,
    null,
    string
>;
export const toggleMultipleEmployeesContractRoleSuccess = (
    meta: string,
): ToggleMultipleEmployeesContractRoleSuccessAction => ({
    type: TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_SUCCEED,
    meta,
});

export const TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_FAILED =
    'TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_FAILED';
export type ToggleMultipleEmployeesContractRoleFailureAction = Action<
    typeof TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_FAILED,
    string
>;
export const toggleMultipleEmployeesContractRoleFailure = (
    payload: string,
): ToggleMultipleEmployeesContractRoleFailureAction => ({
    type: TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_FAILED,
    payload,
});

export const GET_CONTRACT_NOTES_REQUESTED = 'GET_CONTRACT_NOTES_REQUESTED';
export type GetContractNotesRequestAction = Action<typeof GET_CONTRACT_NOTES_REQUESTED, number>;
export const getContractNotesRequest = (contractId: number): GetContractNotesRequestAction => ({
    type: GET_CONTRACT_NOTES_REQUESTED,
    payload: contractId,
});

export const GET_CONTRACT_NOTES_SUCCEED = 'GET_CONTRACT_NOTES_SUCCEED';
export type GetContractNotesSuccessAction = Action<
    typeof GET_CONTRACT_NOTES_SUCCEED,
    { contractNotes: ContractNoteData[] }
>;
export const getContractNotesSuccess = (
    contractNotes: ContractNoteData[],
): GetContractNotesSuccessAction => ({
    type: GET_CONTRACT_NOTES_SUCCEED,
    payload: { contractNotes },
});

export const GET_CONTRACT_NOTES_FAILED = 'GET_CONTRACT_NOTES_FAILED';
export type GetContractNotesFailureAction = Action<typeof GET_CONTRACT_NOTES_FAILED, string>;
export const getContractNotesFailure = (payload: string): GetContractNotesFailureAction => ({
    type: GET_CONTRACT_NOTES_FAILED,
    payload,
});

export const CREATE_CONTRACT_NOTE_REQUESTED = 'CREATE_CONTRACT_NOTE_REQUESTED';
export type CreateContractNoteRequestAction = Action<
    typeof CREATE_CONTRACT_NOTE_REQUESTED,
    CreateContractNoteRequest
>;
export const createContractNoteRequest = (
    contractNoteData: CreateContractNoteRequest,
): CreateContractNoteRequestAction => ({
    type: CREATE_CONTRACT_NOTE_REQUESTED,
    payload: contractNoteData,
});

export const CREATE_CONTRACT_NOTE_SUCCEED = 'CREATE_CONTRACT_NOTE_SUCCEED';
export type CreateContractNoteSuccessAction = Action<typeof CREATE_CONTRACT_NOTE_SUCCEED, number>;
export const createContractNoteSuccess = (contractId: number): CreateContractNoteSuccessAction => ({
    type: CREATE_CONTRACT_NOTE_SUCCEED,
    payload: contractId,
});

export const CREATE_CONTRACT_NOTE_FAILED = 'CREATE_CONTRACT_NOTE_FAILED';
export type CreateContractNoteFailureAction = Action<typeof CREATE_CONTRACT_NOTE_FAILED, string>;
export const createContractNoteFailure = (payload: string): CreateContractNoteFailureAction => ({
    type: CREATE_CONTRACT_NOTE_FAILED,
    payload,
});

export const DELETE_CONTRACT_NOTE_REQUESTED = 'DELETE_CONTRACT_NOTE_REQUESTED';
export type DeleteContractNoteRequestAction = Action<
    typeof DELETE_CONTRACT_NOTE_REQUESTED,
    DeleteContractNoteRequest
>;
export const deleteContractNoteRequest = (
    data: DeleteContractNoteRequest,
): DeleteContractNoteRequestAction => ({
    type: DELETE_CONTRACT_NOTE_REQUESTED,
    payload: data,
});

export const DELETE_CONTRACT_NOTE_SUCCEED = 'DELETE_CONTRACT_NOTE_SUCCEED';
export type DeleteContractNoteSuccessAction = Action<typeof DELETE_CONTRACT_NOTE_SUCCEED, number>;
export const deleteContractNoteSuccess = (contract: number): DeleteContractNoteSuccessAction => ({
    type: DELETE_CONTRACT_NOTE_SUCCEED,
    payload: contract,
});

export const DELETE_CONTRACT_NOTE_FAILED = 'DELETE_CONTRACT_NOTE_FAILED';
export type DeleteContractNoteFailureAction = Action<typeof DELETE_CONTRACT_NOTE_FAILED, string>;
export const deleteContractNoteFailure = (payload: string): DeleteContractNoteFailureAction => ({
    type: DELETE_CONTRACT_NOTE_FAILED,
    payload,
});

export const OPEN_FORM = 'OPEN_FORM';
export type OpenFormAction = Action<typeof OPEN_FORM>;
export const openForm = (): OpenFormAction => ({ type: OPEN_FORM });

export const CLOSE_FORM = 'CLOSE_FORM';
export type CloseFormAction = Action<typeof CLOSE_FORM>;
export const closeForm = (): CloseFormAction => ({ type: CLOSE_FORM });

export const EDIT_CONTRACT_NOTE_REQUESTED = 'EDIT_CONTRACT_NOTE_REQUESTED';
export type EditContractNoteRequestAction = Action<
    typeof EDIT_CONTRACT_NOTE_REQUESTED,
    EditContractNoteRequest
>;
export const editContractNoteRequest = (
    contractNoteData: EditContractNoteRequest,
): EditContractNoteRequestAction => ({
    type: EDIT_CONTRACT_NOTE_REQUESTED,
    payload: contractNoteData,
});

export const EDIT_CONTRACT_NOTE_SUCCEED = 'EDIT_CONTRACT_NOTE_SUCCEED';
export type EditContractNoteSuccessAction = Action<typeof EDIT_CONTRACT_NOTE_SUCCEED, number>;
export const editContractNoteSuccess = (contractId: number): EditContractNoteSuccessAction => ({
    type: EDIT_CONTRACT_NOTE_SUCCEED,
    payload: contractId,
});

export const EDIT_CONTRACT_NOTE_FAILED = 'EDIT_CONTRACT_NOTE_FAILED';
export type EditContractNoteFailureAction = Action<typeof EDIT_CONTRACT_NOTE_FAILED, string>;
export const editContractNoteFailure = (payload: string): EditContractNoteFailureAction => ({
    type: EDIT_CONTRACT_NOTE_FAILED,
    payload,
});

export const DELETE_CONTRACT_NOTE_FILE_REQUESTED = 'DELETE_CONTRACT_NOTE_FILE_REQUESTED';
export type DeleteContractNoteFileRequestAction = Action<
    typeof DELETE_CONTRACT_NOTE_FILE_REQUESTED,
    DeleteContractNoteFileRequest
>;
export const deleteContractNoteFileRequest = (
    data: DeleteContractNoteFileRequest,
): DeleteContractNoteFileRequestAction => ({
    type: DELETE_CONTRACT_NOTE_FILE_REQUESTED,
    payload: data,
});

export const DELETE_CONTRACT_NOTE_FILE_SUCCEED = 'DELETE_CONTRACT_NOTE_FILE_SUCCEED';
export type DeleteContractNoteFileSuccessAction = Action<
    typeof DELETE_CONTRACT_NOTE_FILE_SUCCEED,
    number,
    string
>;
export const deleteContractNoteFileSuccess = (
    contract: number,
): DeleteContractNoteFileSuccessAction => ({
    type: DELETE_CONTRACT_NOTE_FILE_SUCCEED,
    payload: contract,
    meta: 'File deleted',
});

export const DELETE_CONTRACT_NOTE_FILE_FAILED = 'DELETE_CONTRACT_NOTE_FILE_FAILED';
export type DeleteContractNoteFileFailureAction = Action<
    typeof DELETE_CONTRACT_NOTE_FILE_FAILED,
    string
>;
export const deleteContractNoteFileFailure = (
    payload: string,
): DeleteContractNoteFileFailureAction => ({
    type: DELETE_CONTRACT_NOTE_FILE_FAILED,
    payload,
});

export const ADD_CONTRACT_NOTE_FILE_REQUESTED = 'ADD_CONTRACT_NOTE_FILE_REQUESTED';
export type AddContractNoteFileRequestAction = Action<
    typeof ADD_CONTRACT_NOTE_FILE_REQUESTED,
    AddContractNoteFileRequest
>;
export const addContractNoteFileRequest = (
    data: AddContractNoteFileRequest,
): AddContractNoteFileRequestAction => ({
    type: ADD_CONTRACT_NOTE_FILE_REQUESTED,
    payload: data,
});

export const ADD_CONTRACT_NOTE_FILE_SUCCEED = 'ADD_CONTRACT_NOTE_FILE_SUCCEED';
export type AddContractNoteFileSuccessAction = Action<
    typeof ADD_CONTRACT_NOTE_FILE_SUCCEED,
    number,
    string
>;
export const addContractNoteFileSuccess = (contract: number): AddContractNoteFileSuccessAction => ({
    type: ADD_CONTRACT_NOTE_FILE_SUCCEED,
    payload: contract,
    meta: 'File added',
});

export const ADD_CONTRACT_NOTE_FILE_FAILED = 'ADD_CONTRACT_NOTE_FILE_FAILED';
export type AddContractNoteFileFailureAction = Action<typeof ADD_CONTRACT_NOTE_FILE_FAILED, string>;
export const addContractNoteFileFailure = (payload: string): AddContractNoteFileFailureAction => ({
    type: ADD_CONTRACT_NOTE_FILE_FAILED,
    payload,
});

export const GET_CONTRACT_FILES_REQUESTED = 'GET_CONTRACT_FILES_REQUESTED';
export type GetContractFilesRequestAction = Action<
    typeof GET_CONTRACT_FILES_REQUESTED,
    ContractPath
>;
export const getContractFilesRequest = (
    contractPath: ContractPath,
): GetContractFilesRequestAction => ({
    type: GET_CONTRACT_FILES_REQUESTED,
    payload: contractPath,
});

export const GET_CONTRACT_FILES_SUCCEED = 'GET_CONTRACT_FILES_SUCCEED';
export type GetContractFilesSuccessAction = Action<
    typeof GET_CONTRACT_FILES_SUCCEED,
    { contractFiles: FileData[] }
>;
export const getContractFilesSuccess = (
    contractFiles: FileData[],
): GetContractFilesSuccessAction => ({
    type: GET_CONTRACT_FILES_SUCCEED,
    payload: { contractFiles },
});

export const GET_CONTRACT_FILES_FAILED = 'GET_CONTRACT_FILES_FAILED';
export type GetContractFilesFailureAction = Action<typeof GET_CONTRACT_FILES_FAILED, string>;
export const getContractFilesFailure = (payload: string): GetContractFilesFailureAction => ({
    type: GET_CONTRACT_FILES_FAILED,
    payload,
});

export const DELETE_CONTRACT_FILE_REQUESTED = 'DELETE_CONTRACT_FILE_REQUESTED';
export type DeleteContractFileRequestAction = Action<
    typeof DELETE_CONTRACT_FILE_REQUESTED,
    DeleteContractFileRequest
>;
export const deleteContractFileRequest = (
    data: DeleteContractFileRequest,
): DeleteContractFileRequestAction => ({
    type: DELETE_CONTRACT_FILE_REQUESTED,
    payload: data,
});

export const DELETE_CONTRACT_FILE_SUCCEED = 'DELETE_CONTRACT_NOTE_FILE_SUCCEED';
export type DeleteContractFileSuccessAction = Action<
    typeof DELETE_CONTRACT_FILE_SUCCEED,
    number,
    string
>;
export const deleteContractFileSuccess = (contract: number): DeleteContractFileSuccessAction => ({
    type: DELETE_CONTRACT_FILE_SUCCEED,
    payload: contract,
    meta: 'File deleted',
});

export const DELETE_CONTRACT_FILE_FAILED = 'DELETE_CONTRACT_FILE_FAILED';
export type DeleteContractFileFailureAction = Action<typeof DELETE_CONTRACT_FILE_FAILED, string>;
export const deleteContractFileFailure = (payload: string): DeleteContractFileFailureAction => ({
    type: DELETE_CONTRACT_FILE_FAILED,
    payload,
});

export const ADD_CONTRACT_FILE_REQUESTED = 'ADD_CONTRACT_FILE_REQUESTED';
export type AddContractFileRequestAction = Action<
    typeof ADD_CONTRACT_FILE_REQUESTED,
    AddContractFileRequest
>;
export const addContractFileRequest = (
    data: AddContractFileRequest,
): AddContractFileRequestAction => ({
    type: ADD_CONTRACT_FILE_REQUESTED,
    payload: data,
});

export const ADD_CONTRACT_FILE_SUCCEED = 'ADD_CONTRACT_FILE_SUCCEED';
export type AddContractFileSuccessAction = Action<
    typeof ADD_CONTRACT_FILE_SUCCEED,
    FileData,
    string,
    posthog.CaptureResult | null
>;
export const addContractFileSuccess = (file: FileData): AddContractFileSuccessAction => ({
    type: ADD_CONTRACT_FILE_SUCCEED,
    payload: file,
    meta: 'File uploaded',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Project file uploaded')
            : null,
});

export const ADD_CONTRACT_FILE_FAILED = 'ADD_CONTRACT_FILE_FAILED';
export type AddContractFileFailureAction = Action<typeof ADD_CONTRACT_FILE_FAILED, string>;
export const addContractFileFailure = (payload: string): AddContractFileFailureAction => ({
    type: ADD_CONTRACT_FILE_FAILED,
    payload,
});

export type ContractActions =
    | ArchiveContractCategoryRequestAction
    | ArchiveContractCategorySuccessAction
    | ArchiveContractCategoryFailureAction
    | ArchiveLocationRequestAction
    | ArchiveLocationSuccessAction
    | ArchiveLocationFailureAction
    | ArchiveDepartmentRequestAction
    | ArchiveDepartmentSuccessAction
    | ArchiveDepartmentFailureAction
    | DeleteContractRequestAction
    | DeleteContractSuccessAction
    | DeleteContractFailureAction
    | EditContractRequestAction
    | EditContractSuccessAction
    | EditContractFailureAction
    | EditFilterRequestAction
    | EditFilterSuccessAction
    | EditFilterFailureAction
    | GetContractDetailsRequestAction
    | GetContractDetailsSuccessAction
    | GetContractDetailsFailureAction
    | GetContractsSuccessAction
    | GetAllContractsRequestAction
    | GetAllContractsSuccessAction
    | GetAllContractsFailureAction
    | GetRagContractsSuccessAction
    | GetContractPriorityDashboardSuccessAction
    | GetMyContractsRequestAction
    | GetMyContractsSuccessAction
    | GetMyContractsFailureAction
    | GetContractRolesRequestAction
    | GetContractRolesSuccessAction
    | GetContractRolesFailureAction
    | GetCategoriesRequestAction
    | GetCategoriesSuccessAction
    | GetCategoriesFailureAction
    | GetPortfolioFiltersRequestAction
    | GetPortfolioFiltersSuccessAction
    | GetPortfolioFiltersFailureAction
    | GetDepartmentsRequestAction
    | GetDepartmentsSuccessAction
    | GetDepartmentsFailureAction
    | GetLocationsRequestAction
    | GetLocationsSuccessAction
    | GetLocationsFailureAction
    | CreateContractRequestAction
    | CreateContractSuccessAction
    | CreateContractFailureAction
    | CreateFilterRequestAction
    | CreateFilterSuccessAction
    | CreateFilterFailureAction
    | FilterContractsRequestAction
    | FilterContractsSuccessAction
    | FilterContractsFailureAction
    | AddContractRoleRequestAction
    | AddContractRoleSuccessAction
    | AddContractRoleFailAction
    | DeleteContractRoleRequestAction
    | DeleteContractRoleSuccessAction
    | DeleteContractRoleFailureAction
    | GetContractRequestAction
    | GetContractSuccessAction
    | GetContractFailureAction
    | SelectContractOrganisationAction
    | GetContractPrioritiesFiltersRequestAction
    | GetContractPrioritiesFiltersSuccessAction
    | GetContractPrioritiesFiltersFailureAction
    | ArchiveContractPriorityRequestAction
    | ArchiveContractPrioritySuccessAction
    | ArchiveContractPriorityFailureAction
    | GetBenefitPrioritiesFiltersRequestAction
    | GetBenefitPrioritiesFiltersSuccessAction
    | GetBenefitPrioritiesFiltersFailureAction
    | ArchiveBenefitPriorityRequestAction
    | ArchiveBenefitPrioritySuccessAction
    | ArchiveBenefitPriorityFailureAction
    | GetContractPrioritiesRequestAction
    | GetContractPrioritiesSuccessAction
    | GetContractPrioritiesFailureAction
    | AddContractPriorityRequestAction
    | AddContractPrioritySuccessAction
    | AddContractPriorityFailureAction
    | EditContractPriorityRequestAction
    | EditContractPrioritySuccessAction
    | EditContractPriorityFailureAction
    | DeleteContractPriorityRequestAction
    | DeleteContractPrioritySuccessAction
    | DeleteContractPriorityFailureAction
    | SetDateRangeContractDashboardAction
    | SetDateRangeContractPrioritiesDashboardAction
    | DeleteContractCategoryFilterRequestAction
    | DeleteContractCategoryFilterSuccessAction
    | DeleteContractCategoryFilterFailureAction
    | DeleteContractPriorityFilterRequestAction
    | DeleteContractPriorityFilterSuccessAction
    | DeleteContractPriorityFilterFailureAction
    | DeleteBenefitPriorityFilterRequestAction
    | DeleteBenefitPriorityFilterFailureAction
    | DeleteBenefitPriorityFilterSuccessAction
    | DeleteDepartmentFilterRequestAction
    | DeleteDepartmentFilterSuccessAction
    | DeleteDepartmentFilterFailureAction
    | DeleteLocationFilterRequestAction
    | DeleteLocationFilterSuccessAction
    | DeleteLocationFilterFailureAction
    | ToggleMultipleEmployeesContractRoleRequestAction
    | ToggleMultipleEmployeesContractRoleSuccessAction
    | ToggleMultipleEmployeesContractRoleFailureAction
    | GetContractNotesRequestAction
    | GetContractNotesSuccessAction
    | GetContractNotesFailureAction
    | CreateContractNoteRequestAction
    | CreateContractNoteSuccessAction
    | CreateContractNoteFailureAction
    | DeleteContractNoteRequestAction
    | DeleteContractNoteSuccessAction
    | DeleteContractNoteFailureAction
    | OpenFormAction
    | CloseFormAction
    | EditContractNoteRequestAction
    | EditContractNoteSuccessAction
    | EditContractNoteFailureAction
    | DeleteContractNoteFileRequestAction
    | DeleteContractNoteFileSuccessAction
    | DeleteContractNoteFileFailureAction
    | AddContractNoteFileRequestAction
    | AddContractNoteFileSuccessAction
    | AddContractNoteFileFailureAction
    | DeleteContractFileRequestAction
    | DeleteContractFileSuccessAction
    | DeleteContractFileFailureAction
    | AddContractFileRequestAction
    | AddContractFileSuccessAction
    | AddContractFileFailureAction
    | GetContractFilesRequestAction
    | GetContractFilesSuccessAction
    | GetContractFilesFailureAction;
