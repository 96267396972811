import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from 'common/appState';
import { getBenefitByRouteId } from 'features/benefit/selectors/benefitSelectors';
import { getContractBenefitDataRequest } from 'features/benefit/actions';
import { getContractByBenefitRouteId } from 'features/contract/selectors/contractSelectors';
import { getContractRequest } from 'features/contract/actions/contractActions';
import { FullPageSpinner } from 'common/components';

import { BenefitScore } from '../components/BenefitScore';
import { useUploadParams } from '../helpers';
import { getContractManagerDetailsRequest } from '../actions/evidenceActions';
import { getMyScoreRequest } from '../actions/scoreActions';

export const BenefitScoreContainer = () => {
    const dispatch = useDispatch();

    const { organisationId, contractId, benefitId } = useUploadParams();
    const contract = useSelector((state: AppState) =>
        getContractByBenefitRouteId(state, { benefitId }),
    );
    const benefit = useSelector((state: AppState) => getBenefitByRouteId(state, { benefitId }));

    useEffect(() => {
        dispatch(
            getContractBenefitDataRequest({
                contractId,
                benefitId,
                organisationId,
            }),
        );

        dispatch(getContractRequest({ contractId, organisationId }));
        dispatch(getContractManagerDetailsRequest(contractId));

        dispatch(
            getMyScoreRequest({
                organisationId: contract && contract.organisation,
                benefitId,
                contractId,
            }),
        );
    }, []);

    return contract && benefit ? (
        <BenefitScore
            contractId={contractId}
            contract={contract}
            benefitId={benefitId}
            benefit={benefit}
        />
    ) : (
        <FullPageSpinner />
    );
};
