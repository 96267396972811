import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { breakpoints, colors, fontSizes } from 'theme/variables';
import { Antd3Form } from 'common/components/deprecated/antd3';

export const MainFormHeader = styled.h2`
    text-align: center;
    color: ${colors.primary};
    font-size: ${fontSizes.medium};
    margin-bottom: 2.5rem;

    ${breakpoints.small} {
        font-size: ${fontSizes.extraLarge};
    }
`;

export const FormHeader = styled.h2`
    margin: 0 0 2.5rem;
    font-size: ${fontSizes.extraLarge};
    text-align: center;
    color: ${colors.primary};
    font-size: ${fontSizes.medium};

    ${breakpoints.small} {
        font-size: ${fontSizes.extraLarge};
    }
`;

export const SubmitButtonContainer = styled(Antd3Form.Item)`
    margin-bottom: 0;
    button {
        width: 100%;
    }
`;

export const MarginLink = styled(Link)`
    margin: 25px 0;
`;
