import styled from 'styled-components';
import { Card } from 'antd';

import { sizes } from 'theme/variables';

export const InnerCard = styled(Card)`
    && {
        margin-bottom: ${sizes.contentMargin};

        li > span {
            width: 100%;
            text-align: left;
        }
    }
`;
