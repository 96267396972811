import * as React from 'react';
import { Button } from 'antd';

import { notArchivedFilterParam, notRootNodeFilterParam } from 'config/config';
import { NumberInputsRange } from 'common/components/deprecated/form/NumberInputsRange';
import {
    contractType,
    status,
    budgetType,
    routeToMarket,
    RAG,
} from 'features/contract/models/contractModels';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { FormFieldIds } from 'common/helpers/utils';
import { AutocompleteSelectAll } from 'features/pagination/components/AutocompleteSelectAll';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import {
    PublishRadioButtons,
    RangePicker,
    Select,
    ResetButton,
} from 'common/components/deprecated/form';

import { EditContractFilterContainerProps } from '../containers/EditContractFilterContainer';
import { EditPaths } from '../models/editModel';

interface EditContractsFilterState {
    priorities: string[];
    areAllPrioritiesSelected: boolean;
}

type EditContractFilterProps = EditContractFilterContainerProps & Antd3FormProps;

class EditContractFilterComponent extends React.Component<
    EditContractFilterProps,
    EditContractsFilterState
> {
    public state: EditContractsFilterState = {
        priorities: [],
        areAllPrioritiesSelected: false,
    };

    public componentDidMount(): void {
        const { formInitial } = this.props;
        const { values } = formInitial || { values: {} };

        if (values.priorities) {
            this.setState({
                priorities: values.priorities,
            });
        }
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }

            const { priorities } = this.state;

            this.props.saveFilters({
                paginatedView: PaginationView.Contracts,
                values: { ...values, priorities },
                path: EditPaths.Contracts,
            });
        });
    };

    private handleSelectAllPriorities = (priorities: string[]) => {
        this.state.areAllPrioritiesSelected
            ? this.setState((prevState) => ({
                  priorities: [],
                  areAllPrioritiesSelected: !prevState.areAllPrioritiesSelected,
              }))
            : this.setState((prevState) => ({
                  priorities,
                  areAllPrioritiesSelected: !prevState.areAllPrioritiesSelected,
              }));
    };

    private addPriority = (selectedPriority: string) => {
        this.setState((prevState) => ({
            priorities: [...prevState.priorities, selectedPriority],
        }));
    };

    private removePriority = (selectedPriority: string) => {
        this.setState((prevState) => ({
            priorities: prevState.priorities.filter((priority) => priority !== selectedPriority),
        }));
    };

    public render(): JSX.Element {
        const { form, tenant, formInitial, clearFilters } = this.props;

        const { values } = formInitial || { values: {} };

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Edit Projects Filter">
                <ResetButton
                    form={form}
                    clearFilters={clearFilters}
                    paginationView={PaginationView.Contracts}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Contract}
                    id={FormFieldIds.Title}
                    placeholder="Project Title"
                    defaultValue={values.title}
                    notFoundContent="No projects found"
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Contract}
                    id={FormFieldIds.ReferenceNumber}
                    placeholder="Project Reference Number"
                    defaultValue={values.referenceNumber}
                    notFoundContent="No reference number found"
                />
                <Select
                    form={form}
                    id={FormFieldIds.ContractType}
                    placeholder="Project Type"
                    initialValue={values.contractType}
                    options={contractType}
                    required={false}
                    allowClear
                />
                {tenant && (
                    <>
                        <AutocompleteSelect
                            autocompleteField={AutocompleteField.ContractCategory}
                            form={form}
                            id={FormFieldIds.Category}
                            placeholder="Project Category"
                            defaultValue={values.category}
                        />
                        <AutocompleteSelect
                            autocompleteField={AutocompleteField.ContractPortfolioFilter}
                            form={form}
                            id={FormFieldIds.PortfolioFilter}
                            placeholder="Portfolio"
                            defaultValue={values.portfolioFilter}
                            searchParams={{ ...notRootNodeFilterParam, ...notArchivedFilterParam }}
                        />
                        <AutocompleteSelect
                            autocompleteField={AutocompleteField.Department}
                            form={form}
                            id={FormFieldIds.Department}
                            placeholder="Department"
                            defaultValue={values.department}
                        />
                        <AutocompleteSelect
                            autocompleteField={AutocompleteField.Location}
                            form={form}
                            id={FormFieldIds.Location}
                            placeholder="Project Location"
                            defaultValue={values.location}
                            notFoundContent="No locations found"
                        />
                    </>
                )}
                <Select
                    form={form}
                    id={FormFieldIds.Budget}
                    placeholder="Budget Source"
                    initialValue={values.budget}
                    options={budgetType}
                    required={false}
                    allowClear
                />
                <Select
                    form={form}
                    id={FormFieldIds.RouteToMarket}
                    placeholder="Route to Market"
                    initialValue={values.routeToMarket}
                    options={routeToMarket}
                    required={false}
                    allowClear
                />
                {tenant && (
                    <AutocompleteSelect
                        form={form}
                        autocompleteField={AutocompleteField.Contract}
                        id={FormFieldIds.FrameworkContract}
                        placeholder="Framework Project"
                        defaultValue={values.frameworkContract}
                        notFoundContent="No projects found"
                    />
                )}
                <NumberInputsRange
                    form={form}
                    fromInput={{ id: 'valueMin', placeholder: 'Minimum value' }}
                    toInput={{ id: 'valueMax', placeholder: 'Maximum value' }}
                    initFrom={values.valueMin}
                    initTo={values.valueMax}
                    label="Project Value"
                />
                {tenant && (
                    <AutocompleteSelectAll
                        form={form}
                        autocompleteField={AutocompleteField.ContractPriorities}
                        id={FormFieldIds.Priorities}
                        placeholder="Project Priorities"
                        notFoundContent="No categories found"
                        defaultValue={values.priorities}
                        handleSelectAllPriorities={this.handleSelectAllPriorities}
                        multiple
                        areAllPrioritiesSelected={this.state.areAllPrioritiesSelected}
                        selected={this.state.priorities}
                        addPriority={this.addPriority}
                        removePriority={this.removePriority}
                    />
                )}
                <RangePicker
                    form={form}
                    label="Project Start Date"
                    inputs={[
                        {
                            id: FormFieldIds.MinStartDate,
                            placeholder: 'From',
                            initialValue: values.minStartDate,
                        },
                        {
                            id: FormFieldIds.MaxStartDate,
                            placeholder: 'To',
                            initialValue: values.maxStartDate,
                        },
                    ]}
                />
                <RangePicker
                    form={form}
                    label="Project Completion Date"
                    inputs={[
                        {
                            id: FormFieldIds.MinEndDate,
                            placeholder: 'From',
                            initialValue: values.minEndDate,
                        },
                        {
                            id: FormFieldIds.MaxEndDate,
                            placeholder: 'To',
                            initialValue: values.maxEndDate,
                        },
                    ]}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ContractManager}
                    id={FormFieldIds.ContractManager}
                    placeholder="Project Manager"
                    defaultValue={values.contractManager}
                    notFoundContent="No project managers found"
                    searchParams={notArchivedFilterParam}
                />
                <AutocompleteSelect
                    autocompleteField={AutocompleteField.ContractSupplierOrganisation}
                    form={form}
                    id={FormFieldIds.Supplier}
                    placeholder="Suppliers"
                    defaultValue={values.supplier}
                    notFoundContent="No suppliers found"
                />
                <Select
                    form={form}
                    id={FormFieldIds.Status}
                    placeholder="Project Status"
                    initialValue={values.status}
                    options={status}
                    required={false}
                    allowClear
                />
                <Select
                    form={form}
                    id={FormFieldIds.BenefitDeliveredNumberRag}
                    placeholder="Number of Benefits RAG"
                    initialValue={values.benefitDeliveredNumber}
                    options={RAG}
                    required={false}
                    allowClear
                />
                <Select
                    form={form}
                    id={FormFieldIds.BenefitPointsRag}
                    placeholder="Benefit Points RAG"
                    initialValue={values.benefitPoints}
                    options={RAG}
                    required={false}
                    allowClear
                />
                <Select
                    form={form}
                    id={FormFieldIds.BenefitValueRag}
                    placeholder="Benefit Value RAG"
                    initialValue={values.benefitValue}
                    options={RAG}
                    required={false}
                    allowClear
                />
                <Select
                    form={form}
                    id={FormFieldIds.BenefitEmissionSavingsRag}
                    placeholder="Benefit Emissions Savings RAG"
                    initialValue={values.benefitEmissionSavings}
                    options={RAG}
                    required={false}
                    allowClear
                />
                {tenant && (
                    <PublishRadioButtons
                        form={form}
                        required={false}
                        defaultValue={values.published}
                    />
                )}
                <Button type="primary" htmlType="submit">
                    Filter
                </Button>
            </StyledAntd3Form>
        );
    }
}

export const EditContractFilter = Antd3Form.create({})(EditContractFilterComponent);
