import * as React from 'react';
import styled from 'styled-components';

import { fontSizes } from 'theme/variables';
import { generateColumns } from 'common/helpers/utils';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { getEmployeesSuccess } from 'features/user/actions/userActions';
import { Profile } from 'features/user/models/userModel';
import { PaddedSection } from 'common/components';

import { OrganisationDetailsContainerProps } from '../containers/OrganisationDetailsContainer';

const organisationEmployeeColumns = [
    {
        title: 'Role',
        key: 'id',
        render: (_: string, { isAdministrator }: Profile) => (
            <>{isAdministrator ? 'Administrator' : 'Employee'}</>
        ),
    },
    {
        title: 'Name',
        key: 'user.id',
        render: (_: string, { user: { firstName, lastName } }: Profile) =>
            `${firstName} ${lastName}`,
    },
    {
        title: 'Email',
        key: 'user.email',
        render: (_value: string, item: Profile) => {
            const mailToLink = `mailto:${item.user.email}`;
            return (
                <span>
                    <a href={mailToLink}>{item.user.email}</a>
                </span>
            );
        },
    },
    {
        title: 'Telephone',
        key: 'telephone',
    },
];

const StyledText = styled.span`
    display: block;
    text-align: center;
    color: #2a8cca;
    font-size: ${fontSizes.small};
`;

export class EditOrganisation extends React.Component<OrganisationDetailsContainerProps> {
    public render(): JSX.Element {
        const { organisationId, organisation } = this.props;

        return organisation && !this.props.isFetching ? (
            <PaddedSection header={organisation.name}>
                {organisation.referenceNumber ? (
                    <h3>
                        <StyledText> Reference Number: {organisation.referenceNumber} </StyledText>
                    </h3>
                ) : undefined}
                <PaginatedTable
                    requestParams={{ organisationId }}
                    view={PaginationView.OrganisationEmployees}
                    data={this.props.employees}
                    paginationSuccessCallback={getEmployeesSuccess}
                    columns={generateColumns(organisationEmployeeColumns)}
                />
            </PaddedSection>
        ) : (
            <h1>No organisation with id {organisationId} found</h1>
        );
    }
}
