import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { importBenefitsRequest } from 'features/import/actions/importActions';
import { redirectToUrl } from 'common/actions/navigationAction';
import { isAdmin } from 'features/user/selectors/userSelectors';

import { ImportBenefits as ImportBenefitsComponent } from '../components/ImportBenefits';
import { getProcessingImportStatus, getImportErrors } from '../selectors/importSelectors';

export interface ImportBenefitsContainerDispatchProps {
    importBenefitsRequest: typeof importBenefitsRequest;
    redirectToUrl: typeof redirectToUrl;
}

export interface ImportBenefitsContainerStateProps {
    isProcessing: boolean;
    errors: string[];
    isUserAdmin: boolean;
}

export type ImportBenefitsContainerProps = ImportBenefitsContainerDispatchProps &
    ImportBenefitsContainerStateProps;

const ImportBenefits: React.FunctionComponent<ImportBenefitsContainerProps> = (props) => (
    <ImportBenefitsComponent {...props} />
);

const mapStateToProps = createSelector(
    getProcessingImportStatus,
    getImportErrors,
    isAdmin,
    (isProcessing, errors, isUserAdmin): ImportBenefitsContainerStateProps => ({
        isProcessing,
        errors,
        isUserAdmin,
    }),
);

export const ImportBenefitsContainer = connect(mapStateToProps, {
    importBenefitsRequest,
    redirectToUrl,
})(ImportBenefits);
