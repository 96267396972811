import { reduce, snakeCase } from 'lodash';

import { history } from 'config/config';
import {
    dateRequestFormat,
    snakeUpperCase,
    publishStatus,
    archivedStatus,
} from 'common/helpers/utils';
import { QueryStringParams } from 'config/variables';
import { AutocompleteProperties } from 'features/pagination/models/paginationModel';
import { FilterContract } from 'features/contract/models/contractModels';
import { FilterBenefitRequest } from 'features/benefit/models/benefitModels';

export const getUrlHashCode = () => window.location.hash.substring(1);
export const openRoute = (path: string) => history.push(`/${path}`);

export const filteringRequestPath = <T extends Record<string, any>>(
    requestData: T,
    firstKeyPrefix: boolean = true,
) =>
    reduce(
        requestData,
        (result, value, key) => {
            const date = [
                QueryStringParams.MinStartDate,
                QueryStringParams.MaxStartDate,
                QueryStringParams.MinEndDate,
                QueryStringParams.MaxEndDate,
                QueryStringParams.ContractDateRangeBefore,
                QueryStringParams.ContractDateRangeAfter,
            ];

            const upperCases = [
                QueryStringParams.ContractType,
                QueryStringParams.Status,
                QueryStringParams.SupplierScore,
                QueryStringParams.AssessorScore,
            ];

            const basePath = `${result}${result || (firstKeyPrefix && key) ? '&' : ''}${snakeCase(
                key,
            )}=`;

            if (!value) {
                return result;
            }

            if (upperCases.find((element) => element === key)) {
                return `${basePath}${snakeUpperCase(value) || ''}`;
            }

            if (date.find((element) => element === key)) {
                return `${basePath}${dateRequestFormat(value)}`;
            }

            return `${basePath}${value || ''}`;
        },
        '',
    );

export type FilterData = AutocompleteProperties &
    Partial<FilterContract> &
    Partial<FilterBenefitRequest>;

export const generateFilterQueryParams = (
    {
        status,
        contractType,
        method,
        minStartDate,
        maxStartDate,
        minEndDate,
        maxEndDate,
        contractDateRangeBefore,
        contractDateRangeAfter,
        published,
        title,
        referenceNumber,
        contractCategory,
        contractTitle,
        contractReferenceNumber,
        supplierName,
        deliveryPartnerName,
        outcome,
        rootNodes,
        archived,
        routeToMarket,
        ...filters
    }: FilterData,
    firstKeyPrefix?: boolean,
) => {
    const formattedFilters = {
        ...filters,
        ...publishStatus(published),
        referenceNumber: referenceNumber && encodeURIComponent(referenceNumber),
        title: title && encodeURIComponent(title),
        minStartDate: minStartDate && dateRequestFormat(minStartDate),
        maxStartDate: maxStartDate && dateRequestFormat(maxStartDate),
        minEndDate: minEndDate && dateRequestFormat(minEndDate),
        maxEndDate: maxEndDate && dateRequestFormat(maxEndDate),
        contractDateRangeBefore:
            contractDateRangeBefore && dateRequestFormat(contractDateRangeBefore),
        contractDateRangeAfter: contractDateRangeAfter && dateRequestFormat(contractDateRangeAfter),
        status: status && snakeUpperCase(status),
        contractType: contractType && snakeUpperCase(contractType),
        method: method && snakeUpperCase(method),
        contractTitle: contractTitle && encodeURIComponent(contractTitle),
        contractReferenceNumber:
            contractReferenceNumber && encodeURIComponent(contractReferenceNumber),
        outcome: outcome && encodeURIComponent(outcome),
        contractCategory: contractCategory && snakeUpperCase(contractCategory.toString()),
        supplierName: supplierName && encodeURIComponent(supplierName),
        deliveryPartnerName: deliveryPartnerName && encodeURIComponent(deliveryPartnerName),
        rootNodes: rootNodes && snakeCase(rootNodes),
        archived: archivedStatus(archived),
        routeToMarket: routeToMarket && snakeCase(routeToMarket),
    };

    return filteringRequestPath(formattedFilters, firstKeyPrefix);
};
