import { Button, Form as AntdForm, FormProps } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { breakpoints, sizes } from 'theme/variables';
import { isFormValid } from 'common/helpers/formHelpers';

import { ResetButton } from '..';
import { FormHeader } from '../styled';

const FormWrapper = styled.div`
    && {
        width: 100%;
        padding: 1.5rem;

        ${breakpoints.small} {
            max-width: ${sizes.formWidth};
        }
    }
`;

const SubmitButton = styled(Button)`
    width: 100%;
`;

type Props = FormProps & {
    header?: string;
    showResetButton?: boolean;
    submitButtonText?: string;
    hideSubmitButton?: boolean;
};

export const Form = (props: Props) => {
    const [isValid, setIsValid] = useState(false);

    const { showResetButton, submitButtonText, hideSubmitButton, header, children, ...formProps } =
        props;

    useEffect(() => {
        checkIsFormValid();
    }, []);

    const checkIsFormValid = () => {
        setIsValid(isFormValid(formProps.form));
    };

    return (
        <FormWrapper>
            {header && <FormHeader>{header}</FormHeader>}
            {showResetButton && <ResetButton onReset={() => formProps.form?.resetFields()} />}
            <AntdForm {...formProps} layout="vertical" onFieldsChange={checkIsFormValid}>
                {children}
                {!hideSubmitButton && (
                    <SubmitButton disabled={!isValid} type="primary" htmlType="submit">
                        {submitButtonText || 'Save'}
                    </SubmitButton>
                )}
            </AntdForm>
        </FormWrapper>
    );
};
