import { CustomRouteProps } from 'common/models/navigationModel';

import { ImportBenefitsContainer } from '../containers/ImportBenefitsContainer';
import { ImportContractsContainer } from '../containers/ImportContractsContainer';
import { ImportBenefitTemplatesContainer } from '../containers/ImportTemplatedBenefitsContainer';
import { ImportPaths } from '../models/importModel';

const adminMenuLinks = [
    { key: ImportPaths.Contracts, label: 'Projects' },
    { key: ImportPaths.BenefitTemplates, label: 'Templated Benefits' },
    { key: ImportPaths.Benefits, label: 'Unique Benefits' },
];

const menuLinks = [
    { key: ImportPaths.BenefitTemplates, label: 'Templated Benefits' },
    { key: ImportPaths.Benefits, label: 'Unique Benefits' },
];

export const importRoutes: CustomRouteProps[] = [
    {
        path: ImportPaths.Benefits,
        component: ImportBenefitsContainer,
    },
    {
        path: ImportPaths.Contracts,
        component: ImportContractsContainer,
    },
    {
        path: ImportPaths.BenefitTemplates,
        component: ImportBenefitTemplatesContainer,
    },
].map((route) => ({
    ...route,
    adminMenuLinks,
    menuLinks,
}));
