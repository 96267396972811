export interface PortfolioFilterRequest {
    portfolioFilter: PortfolioFilterData;
    params: GetPortfolioFiltersRequest;
}

export interface PortfolioFilterSuccess {
    portfolioFilter: PortfolioFilter;
    depth: PortfolioFiltersEnum;
}

export interface PortfolioFilter {
    id: number;
    name: string;
    archived: boolean;
    path: string;
    depth: PortfolioFiltersEnum;
    organisation: number;
    childrenCount: number;
}

export interface PortfolioFiltersLevel {
    depth: PortfolioFiltersEnum;
    portfolioFilters: PortfolioFilter[];
}

export interface PortfolioFilterIds {
    subProgramId: number;
    mainProjectId: number;
    subProjectId: number;
}

export interface GetPortfolioFiltersRequest {
    depth: PortfolioFiltersEnum;
    portfolioFilterIds: PortfolioFilterIds;
    portfolioFilterRootId: number;
}

export interface GetPortfolioFiltersSuccess {
    depth: PortfolioFiltersEnum;
    portfolioFilters: PortfolioFilter[];
}

export enum PortfolioFiltersEnum {
    SubProgram = 'subPrograms',
    MainProject = 'mainProjects',
    SubProject = 'subProjects',
}

export type PortfolioFiltersLists = { [key in PortfolioFiltersEnum]: PortfolioFilter[] };

export interface PortfolioFilterData {
    name: string;
    archived?: boolean;
}

export interface PortfolioFilterRowData extends PortfolioFilterData {
    id: number;
}
export interface DeletePortfolioFiltersSuccess {
    meta: string;
    portfolioFilter: GetPortfolioFiltersRequest;
}

export const portfolioFilterDefaultIds = { subProgramId: -1, mainProjectId: -1, subProjectId: -1 };
