import { useEffect, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { iconSizes, colors } from 'theme/variables';
import { DeliveryDate, FormDeliveryDate } from 'features/benefit/models/benefitModels';
import { getEmptyDeliveryDate } from 'features/benefit/benefitHelpers';
import { IdValueObject } from 'common/models/defaultsModel';
import { InputLabelWithTooltip } from 'common/components/form/components/InputLabelWithTooltip';

import { InterimDate } from '.';
import { Antd3Icon, Antd3FormProps } from '../antd3';

const DynamicFieldSetWrapper = styled.div`
    margin-bottom: 20px;
`;

const FieldSetElementWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 7px;
`;

export const FieldSetIcon = styled(Antd3Icon)`
    color: ${colors.red};
    display: flex;
    align-items: center;
    margin: 0 20px 20px 20px;
    font-size: ${iconSizes.medium};
`;

export const FieldSetButton = styled(Button)`
    margin: 5px 5px 5px 0;
    display: block;
`;

interface DynamicFieldSetProps {
    fieldSetId: string;
    fieldType: DynamicFieldSetFieldType;
    initialFields?: DynamicFieldSetField[] | null;
    label: string;
    tooltip?: string;
    orderByObjectsIndexes?: boolean;
    onFieldsChanged?: (value: Record<string, DynamicFieldSetField>) => void;
}

export interface DynamicFieldSetItemProps {
    fullId: string;
}

export type DynamicFieldSetField = FormDeliveryDate | IdValueObject;
export type DynamicFieldSetFieldType = 'deliveryDate';

export type Props = DynamicFieldSetProps & Antd3FormProps;

export const DynamicFieldSet = (props: Props) => {
    const [fields, setFields] = useState<Record<string, DynamicFieldSetField>>(
        props.initialFields?.reduce(
            (acc, field) => ({
                ...acc,
                [field.id || uuid()]: field,
            }),
            {},
        ) || ({} as any),
    );

    useEffect(() => {
        props.form.setFieldsValue({ [props.fieldSetId]: fields });

        if (props.onFieldsChanged) {
            props.onFieldsChanged(fields);
        }
    }, [fields]);

    const addFormItem = () => {
        setFields({
            ...fields,
            [uuid()]: getEmptyDeliveryDate(),
        });
    };

    const updateFormItemInput = (
        fieldId: string,
        inputName: string,
        value: string | number | Date,
    ): void => {
        setFields({
            ...fields,
            [fieldId]: {
                ...fields[fieldId],
                [inputName]: value,
            },
        });
    };

    const removeFormItem = (id: string) => {
        const updatedFields = fields;
        delete updatedFields[id];
        setFields({ ...updatedFields });
    };

    const getFieldComponent = (id: string): JSX.Element | null => {
        const field = fields[id];
        const fieldId = field.id || id;

        const fieldSetProps = {
            form: props.form,
            id: fieldId,
            fullId: `${props.fieldSetId}[${fieldId}]`,
            isTemporaryId: !field.id,
            onInputChange: updateFormItemInput,
        };

        return <InterimDate {...(field as DeliveryDate)} {...fieldSetProps} />;
    };

    return (
        <DynamicFieldSetWrapper>
            <InputLabelWithTooltip label={props.label} tooltip={props.tooltip} />
            {Object.keys(fields).map((fieldId) => (
                <FieldSetElementWrapper key={fieldId}>
                    {getFieldComponent(fieldId)}
                    <FieldSetIcon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => removeFormItem(fieldId)}
                    />
                </FieldSetElementWrapper>
            ))}
            <FieldSetButton onClick={addFormItem}>
                <Antd3Icon type="plus" />
                {`Add ${props.placeholder}`}
            </FieldSetButton>
        </DynamicFieldSetWrapper>
    );
};
