import React from 'react';
import { Tooltip } from 'antd';

import {
    ArchiveFilterRequest,
    EditFilterRequest,
    FilterType,
    Filters,
} from 'features/contract/models/contractModels';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { generateColumns } from 'common/helpers/utils';
import {
    PaginationView,
    PaginationSuccessCallback,
} from 'features/pagination/models/paginationModel';
import { Antd3Icon } from 'common/components/deprecated/antd3';
import { PaddedSection } from 'common/components';
import { PaginationArchivedFilter } from 'features/pagination/components/PaginationArchivedFilter';
import { PaginationValueFilter } from 'features/pagination/components/PaginationValueFilter';
import { TableFiltersBar } from 'common/components/TableFiltersBar';

import { FilterInput } from './FilterInput';

interface FilterTabProps extends PaginationSuccessCallback {
    data: Filters[];
    filterType: FilterType;
    header: string;
    onArchiveMessage: string;
    onArchive(filterData: ArchiveFilterRequest): void;
    onEdit(filterData: EditFilterRequest): void;
    onDelete(filterData: EditFilterRequest): void;
    view: PaginationView;
    pathUrl: string;
    pageNumber?: number;
    tooltip?: string;
}

interface FilterColumns {
    onEdit: any;
    filterType: any;
    onArchiveMessage: any;
    header: any;
    onArchive(filterData: EditFilterRequest): void;
    onDelete(filterData: EditFilterRequest): void;
}

const filterColumns = ({
    onEdit,
    filterType,
    onArchive,
    onArchiveMessage,
    onDelete,
}: FilterColumns) => [
    {
        title: '',
        key: 'id',
        render: (_: string, { id, archived, name }: Filters) => (
            <FilterInput
                key={id}
                filterType={filterType}
                id={id}
                name={name}
                onArchive={onArchive}
                onArchiveMessage={onArchiveMessage}
                onEdit={onEdit}
                archived={archived}
                onDelete={onDelete}
            />
        ),
    },
];

export const FilterTab: React.FunctionComponent<FilterTabProps> = ({
    onEdit,
    filterType,
    header,
    onArchive,
    onArchiveMessage,
    view,
    data,
    paginationSuccessCallback,
    pageNumber,
    pathUrl,
    onDelete,
    tooltip,
}) => (
    <PaddedSection
        header={
            <span>
                Edit {header}&nbsp;&nbsp;
                <Tooltip title={tooltip}>
                    <Antd3Icon type="info-circle-o" />
                </Tooltip>
            </span>
        }
    >
        <TableFiltersBar
            filters={[
                <PaginationValueFilter paginatedView={view} />,
                <PaginationArchivedFilter paginatedView={view} />,
            ]}
        />
        <PaginatedTable
            hideHeader
            data={data}
            view={view}
            paginationSuccessCallback={paginationSuccessCallback}
            emptyText={`No ${header} found`}
            columns={generateColumns(
                filterColumns({
                    onEdit,
                    filterType,
                    header,
                    onArchive,
                    onArchiveMessage,
                    onDelete,
                }),
            )}
            pageNumber={pageNumber}
            pathUrl={pathUrl}
            withPaginationHistory
            refetchOnFiltersChange
        />
    </PaddedSection>
);
