import * as React from 'react';
import { Button, Radio, Select, Form } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { NativeButtonProps } from 'antd/lib/button/button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FilterOutlined } from '@ant-design/icons';

import { PaginationRequestAction } from 'features/pagination/actions/paginationActions';
import { DownloadBenefitTypeNames, DownloadFileFormats, FormFieldIds } from 'common/helpers/utils';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { Antd3Form, Antd3FormProps, WrappedFormUtils } from 'common/components/deprecated/antd3';
import { TableFiltersBar } from 'common/components/TableFiltersBar';
import { breakpoints } from 'theme/variables';

import { DownloadPaths } from '../models/downloadModel';

type FormProps = {
    searchByContractTitle?(contractTitle: string): PaginationRequestAction;
    onRemoveContractTitleAutocomplete?(): PaginationRequestAction;
    searchByBenefits?(benefitReferenceNumber: string): void;
    searchByContracts?(contractId: number): void;
    onRemoveBenefitAutocomplete?(): void;
    onRemoveContractAutocomplete?(): void;
    onRemoveTenantAutocomplete?(): PaginationRequestAction | void;
    clearFilters?(): void;
    searchByBenefitType?(benefitType: string): PaginationRequestAction | void;
    searchByTenant?(tenant: number): PaginationRequestAction | void;
    tenantFilter?: number;
    form: WrappedFormUtils;
    filter?: PaginationFilters;
};

type Props = FormProps &
    Antd3FormProps & {
        isFetchingCSV: boolean;
        downloadAll(event: React.FormEvent): void;
        downloadSelected(event: React.FormEvent): void;
        setFileFormat(fileType: RadioChangeEvent): void;
        setExportType?(exportType: RadioChangeEvent): void;
        exportType?: string;
        benefitType?: string;
        fileFormat: string;
        selectedCount: number;
        contracts?: boolean;
        filterId: number;
        isTenant: boolean;
    };

const StyledButton = styled((props: NativeButtonProps) => <Button {...props} />)`
    margin-right: 10px;
`;

const FileFormatRadioLabel = styled.span`
    margin-right: 10px;
    display: inline-block;
`;

const FilesMenu = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${breakpoints.large} {
        flex-direction: row;
    }

    > div {
        margin-right: 24px;
    }
`;

export const DownloadTableFiltersBar: React.FunctionComponent<Props> = ({
    contracts,
    downloadAll,
    selectedCount,
    isFetchingCSV,
    downloadSelected,
    setFileFormat,
    setExportType,
    exportType,
    fileFormat,
    filterId,
    isTenant,
    searchByContractTitle,
    onRemoveContractTitleAutocomplete,
    searchByBenefits,
    searchByContracts,
    onRemoveBenefitAutocomplete,
    onRemoveContractAutocomplete,
    onRemoveTenantAutocomplete,
    clearFilters,
    searchByBenefitType,
    searchByTenant,
    benefitType,
    tenantFilter,
    form,
    filter,
}) => (
    <Antd3Form.Item>
        <TableFiltersBar
            filters={
                contracts
                    ? [
                          <AutocompleteSelect
                              id={FormFieldIds.Title}
                              placeholder="Search by Projects"
                              form={form}
                              autocompleteField={AutocompleteField.DownloadContracts}
                              onSelect={searchByContractTitle}
                              onRemove={onRemoveContractTitleAutocomplete}
                              notFoundContent="No projects found"
                              requiredMessage="Project is required"
                              showLabel={false}
                              defaultValue={filter && filter.values.title}
                          />,
                      ]
                    : [
                          <AutocompleteSelect
                              id={FormFieldIds.QuickFilterContractReferenceNumber}
                              placeholder="Search by Projects"
                              form={form}
                              autocompleteField={
                                  isTenant
                                      ? AutocompleteField.Contract
                                      : AutocompleteField.ContractExecutive
                              }
                              onSelect={searchByContracts}
                              onRemove={onRemoveContractAutocomplete}
                              notFoundContent="No projects found"
                              requiredMessage="Project is required"
                              showLabel={false}
                              defaultValue={filter && filter.values.contract}
                          />,
                          <AutocompleteSelect
                              id={FormFieldIds.QuickFilterBenefitReferenceNumber}
                              placeholder="Search by Benefits"
                              form={form}
                              autocompleteField={AutocompleteField.DownloadBenefits}
                              onSelect={searchByBenefits}
                              onRemove={onRemoveBenefitAutocomplete}
                              notFoundContent="No benefits found"
                              requiredMessage="Benefit is required"
                              showLabel={false}
                              defaultValue={filter && filter.values.referenceNumber}
                          />,
                          <Form.Item>
                              <Select
                                  id={FormFieldIds.QuickFilterBenefitType}
                                  placeholder="Unique/Templated Benefits"
                                  value={benefitType}
                                  onChange={searchByBenefitType}
                                  allowClear
                                  size="large"
                              >
                                  {(
                                      Object.keys(
                                          DownloadBenefitTypeNames,
                                      ) as (keyof typeof DownloadBenefitTypeNames)[]
                                  ).map((name) => (
                                      <Select.Option
                                          key={name}
                                          value={DownloadBenefitTypeNames[name]}
                                      >
                                          {`${name} Benefits`}
                                      </Select.Option>
                                  ))}
                              </Select>
                          </Form.Item>,
                      ]
            }
            filtersButtons={[
                <Link
                    to={`${
                        contracts ? DownloadPaths.ContractsFilter : DownloadPaths.BenefitsFilter
                    }/${filterId}`}
                >
                    <Button type="primary" icon={<FilterOutlined />}>
                        All Filters
                    </Button>
                </Link>,
                <Button onClick={clearFilters}>Clear Filters</Button>,
            ]}
        />
        <FilesMenu>
            <div>
                <FileFormatRadioLabel>Choose file format:</FileFormatRadioLabel>
                <Radio.Group onChange={setFileFormat} value={fileFormat}>
                    {Object.values(DownloadFileFormats).map((e) => (
                        <Radio value={e} key={e}>
                            {e}
                        </Radio>
                    ))}
                </Radio.Group>
            </div>
            <div>
                {!contracts && (
                    <>
                        <FileFormatRadioLabel>Choose export type</FileFormatRadioLabel>
                        <Radio.Group onChange={setExportType} defaultValue="summary">
                            <Radio value="summary">Summary</Radio>
                            {benefitType && ['templated', 'unique'].includes(benefitType) && (
                                <>
                                    <Radio value="evidence_answers">Evidence</Radio>
                                    <Radio value="feedback_answers">Feedback</Radio>
                                </>
                            )}
                        </Radio.Group>
                        {!isTenant && exportType !== 'summary' && (
                            <AutocompleteSelect
                                id={FormFieldIds.Tenant}
                                placeholder="Choose buyer organisation"
                                form={form}
                                autocompleteField={AutocompleteField.TenantOrganisation}
                                onSelect={searchByTenant}
                                onRemove={onRemoveTenantAutocomplete}
                                showLabel={false}
                            />
                        )}
                    </>
                )}
            </div>
        </FilesMenu>
        <StyledButton
            type="primary"
            onClick={downloadSelected}
            disabled={
                !selectedCount ||
                isFetchingCSV ||
                // Executive admin needs to choose buyer organisation when generating evidence/feedback report to avoid huge amount of columns/sheets
                (!isTenant && exportType !== 'summary' && !tenantFilter)
            }
            loading={isFetchingCSV}
        >
            {`Download selected ${selectedCount ? `(${selectedCount})` : ''}`}
        </StyledButton>
        <Button
            type="default"
            onClick={downloadAll}
            disabled={isFetchingCSV || (!isTenant && exportType !== 'summary' && !tenantFilter)}
            loading={isFetchingCSV}
        >
            Download all
        </Button>
    </Antd3Form.Item>
);
