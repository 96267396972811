import * as React from 'react';
import { Redirect } from 'react-router';

import { notArchivedFilterParam } from 'config/config';
import { SubmitButton, PaddedSection } from 'common/components';
import { EditEmployeeProfile } from 'features/user/models/userModel';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input, PhoneInput, ResetButton } from 'common/components/deprecated/form';

import { EditPaths } from '../models/editModel';
import { EmployeeRole } from './EmployeeRole';

interface EditEmployeeFormProps extends Antd3FormProps {
    profile: EditEmployeeProfile;
    isFetching?: boolean;
}

export class EditEmployeeForm extends React.Component<EditEmployeeFormProps> {
    public render(): JSX.Element {
        const {
            profile,
            profile: {
                user: { firstName, lastName },
            },
            form,
        } = this.props;

        return profile ? (
            <PaddedSection>
                <ResetButton form={form} />
                <Input form={form} id="firstName" label="First Name" initialValue={firstName} />
                <Input form={form} id="lastName" label="Last Name" initialValue={lastName} />
                <PhoneInput
                    form={form}
                    id="telephone"
                    initialValue={profile.telephone}
                    placeholder="Telephone Number"
                />
                <AutocompleteSelect
                    autocompleteField={AutocompleteField.Department}
                    form={form}
                    id="department"
                    label="Department"
                    initialValue={profile.department}
                    searchParams={notArchivedFilterParam}
                    required
                />
                <EmployeeRole initialValue={profile.isAdministrator} form={form} />
                <SubmitButton value="Save" loading={this.props.isFetching} />
            </PaddedSection>
        ) : (
            <Redirect to={EditPaths.Employees} />
        );
    }
}
