import { CustomRouteProps } from 'common/models/navigationModel';
import { VerifyNewAccountContainer } from 'features/auth/containers/VerifyNewAccountContainer';
import { VerifyNewExecutiveContainer } from 'features/auth/containers/VerifyNewExecutiveContainer';

import { EditConsentsContainer } from '../containers/EditConsentsContainer';
import { ResetPasswordContainer } from '../containers/ResetPasswordContainer';
import { UpdateUserDataContainer } from '../containers/UpdateUserDataContainer';
import { SubscriptionContainer } from '../containers/SubscriptionContainer';
import { ChangePasswordContainer } from '../containers/ChangePasswordContainer';
import { ChangeEmailContainer } from '../containers/ChangeEmailContainer';
import { UserPaths } from '../models/userModel';
import { insert } from 'common/helpers/utils';
import { SENTRY_ENVIRONMENT } from 'config/env';

const menuLinks = [
    {
        key: UserPaths.EditProfile,
        label: 'Update Profile',
    },
    {
        key: UserPaths.ChangePassword,
        label: 'Change Password',
    },
    {
        key: UserPaths.ChangeEmail,
        label: 'Change Email',
    },
];

const adminMenuLinks = menuLinks;
// SENTRY_ENVIRONMENT !== 'frontend-production'
//     ? insert(menuLinks, 1, {
//           key: UserPaths.Subscription,
//           label: 'Subscription Details',
//       })
//     : menuLinks;

const editProfileRoutes: CustomRouteProps[] = [
    {
        path: UserPaths.EditProfile,
        component: UpdateUserDataContainer,
    },
    {
        path: UserPaths.Subscription,
        component: SubscriptionContainer,
    },
    {
        path: UserPaths.ChangePassword,
        component: ChangePasswordContainer,
    },
    {
        path: UserPaths.ChangeEmail,
        component: ChangeEmailContainer,
    },
].map((route) => ({
    ...route,
    adminMenuLinks,
    menuLinks,
}));

export const userRoutes: CustomRouteProps[] = [
    ...editProfileRoutes,
    {
        path: UserPaths.ResetPassword,
        component: ResetPasswordContainer,
        secured: false,
    },
    {
        path: UserPaths.EditConsents,
        component: EditConsentsContainer,
        hideNavigation: true,
    },
    {
        path: UserPaths.VerifyNewAccount,
        component: VerifyNewAccountContainer,
        secured: false,
    },
    {
        path: UserPaths.VerifyNewExecutive,
        component: VerifyNewExecutiveContainer,
        secured: false,
    },
    {
        path: UserPaths.VerifyNewExecutive,
        component: VerifyNewExecutiveContainer,
        secured: false,
    },
];
