import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { isTenant } from 'features/user/selectors/userSelectors';

import { UploadPendingEvidence as UploadPendingEvidenceComponent } from '../components/UploadPendingEvidence';
import { getReadyForPendingEvidenceBenefits } from '../selectors/uploadSelectors';
import { UploadBenefit } from '../models/uploadModels';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';

interface UploadPendingEvidenceStateProps {
    readyForPendingEvidenceBenefits: UploadBenefit[];
    filter?: PaginationFilters;
    isTenant: boolean;
}

interface UploadPendingEvidenceDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    redirectToUrl: typeof redirectToUrl;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
}

export type UploadPendingEvidenceContainerProps = UploadPendingEvidenceStateProps &
    UploadPendingEvidenceDispatchProps;

class UploadPendingEvidence extends React.Component<UploadPendingEvidenceContainerProps> {
    public render(): JSX.Element {
        return <UploadPendingEvidenceComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState): UploadPendingEvidenceStateProps => ({
    readyForPendingEvidenceBenefits: getReadyForPendingEvidenceBenefits(state),
    filter: getFilterParams(state, PaginationView.UploadPendingEvidence),
    isTenant: isTenant(state),
});

export const UploadPendingEvidenceContainer = connect(mapStateToProps, {
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
})(UploadPendingEvidence);
