import { Provider } from 'react-redux';

import { store } from 'config/configureStore';
import { Antd3GlobalStyle } from 'theme/Antd3GlobalStyle';

import { AppRouter } from './AppRouter';

export const Root = () => (
    <Provider store={store}>
        <Antd3GlobalStyle />
        <AppRouter />
    </Provider>
);
