import moment, { Moment } from 'moment';
import { DatePicker as AntdDatePicker } from 'antd';
import styled from 'styled-components';

import { InputLabelWithTooltip } from '../../form/components/InputLabelWithTooltip';
import { Antd3Form, Antd3InputProps } from '../antd3';

const FullWidthDatePicker = styled(AntdDatePicker)`
    width: 100%;
`;

type DatePickerProps = Antd3InputProps & {
    initialValue?: string;
    disabledDate?: (currentDate: Moment) => boolean;
};

export const DatePicker = ({
    form,
    id,
    requiredMessage,
    label,
    placeholder,
    initialValue,
    required = true,
    disabledDate,
    tooltip,
}: DatePickerProps) => (
    <Antd3Form.Item
        label={
            tooltip ? (
                <InputLabelWithTooltip label={label || placeholder} tooltip={tooltip} />
            ) : (
                label || placeholder
            )
        }
    >
        {form.getFieldDecorator(id, {
            initialValue: initialValue && moment(initialValue),
            rules: [
                { required, message: requiredMessage || `${label || placeholder} is required` },
            ],
        })(
            <FullWidthDatePicker
                size="large"
                placeholder={placeholder || label}
                disabledDate={disabledDate}
            />,
        )}
    </Antd3Form.Item>
);
