import 'reflect-metadata';
import { Container } from 'inversify';

import { AppEpic } from 'common/epics/appEpic';
import { AuthService } from 'features/auth/services/authService';
import { BenefitService } from 'features/benefit/services/benefitService';
import { ContractService } from 'features/contract/services/contractService';
import { DownloadService } from 'features/download/services/downloadService';
import { ImportService } from 'features/import/services/importService';
import { HttpService } from 'common/services/HttpService';
import { StorageService } from 'common/services/storageService';
import { InviteService } from 'features/invite/services/inviteService';
import { OrganisationService } from 'features/organisation/services/organisationService';
import { UploadService } from 'features/upload/services/uploadService';
import { UserService } from 'features/user/services/userService';
import { PaginationService } from 'features/pagination/services/paginationService';
import { PortfolioFilterService } from 'features/portfolioFilter/services/portfolioFilterService';
import { benefitEpicFactory } from 'features/benefit/epics/benefitEpic';
import { contractEpicFactory } from 'features/contract/epics/contractEpic';
import { downloadEpicFactory } from 'features/download/epics/downloadEpic';
import { importEpicFactory } from 'features/import/epics/importEpic';
import { logInEpicFactory } from 'features/auth/epics/logInEpic';
import { inviteEpicFactory } from 'features/invite/epics/inviteEpic';
import { pendingInvitationEpicFactory } from 'features/invite/epics/pendingInvitationsEpic';
import { organisationEpicFactory } from 'features/organisation/epics/organisationEpic';
import { tokenEpicFactory } from 'features/auth/epics/tokenEpic';
import { uploadEpicFactory } from 'features/upload/epics/uploadEpic';
import { userEpicFactory } from 'features/user/epics/userEpic';
import { paginationEpicFactory } from 'features/pagination/epics/paginationEpic';
import { portfolioFilterEpicFactory } from 'features/portfolioFilter/epics/portfolioFilterEpic';

import { SERVICE } from './identifiers';
import { rootEpicFactory } from './configureEpics';

export const container = new Container();

container.bind<AuthService>(SERVICE.Auth).to(AuthService);
container.bind<BenefitService>(SERVICE.Benefit).to(BenefitService);
container.bind<ContractService>(SERVICE.Contract).to(ContractService);
container.bind<DownloadService>(SERVICE.Download).to(DownloadService);
container.bind<ImportService>(SERVICE.Import).to(ImportService);
container.bind<HttpService>(SERVICE.Http).to(HttpService);
container.bind<StorageService>(SERVICE.Storage).to(StorageService);
container.bind<InviteService>(SERVICE.Invite).to(InviteService);
container.bind<OrganisationService>(SERVICE.Organisation).to(OrganisationService);
container.bind<UploadService>(SERVICE.Upload).to(UploadService);
container.bind<UserService>(SERVICE.User).to(UserService);
container.bind<PaginationService>(SERVICE.Pagination).to(PaginationService);
container.bind<PortfolioFilterService>(SERVICE.PortfolioFilter).to(PortfolioFilterService);

container
    .bind<AppEpic>(SERVICE.rootEpicFactory)
    .toFactory((context) =>
        rootEpicFactory(
            context.container.get<AppEpic>(SERVICE.benefitEpicFactory),
            context.container.get<AppEpic>(SERVICE.contractEpicFactory),
            context.container.get<AppEpic>(SERVICE.downloadEpicFactory),
            context.container.get<AppEpic>(SERVICE.importEpicFactory),
            context.container.get<AppEpic>(SERVICE.logInEpicFactory),
            context.container.get<AppEpic>(SERVICE.inviteEpicFactory),
            context.container.get<AppEpic>(SERVICE.pendingInvitationsEpicFactory),
            context.container.get<AppEpic>(SERVICE.organisationEpicFactory),
            context.container.get<AppEpic>(SERVICE.tokenEpicFactory),
            context.container.get<AppEpic>(SERVICE.uploadEpicFactory),
            context.container.get<AppEpic>(SERVICE.userEpicFactory),
            context.container.get<AppEpic>(SERVICE.paginationEpicFactory),
            context.container.get<AppEpic>(SERVICE.portfolioFilterEpicFactory),
        ),
    );

container
    .bind<AppEpic>(SERVICE.benefitEpicFactory)
    .toFactory((context) =>
        benefitEpicFactory(context.container.get<BenefitService>(SERVICE.Benefit)),
    );

container
    .bind<AppEpic>(SERVICE.contractEpicFactory)
    .toFactory((context) =>
        contractEpicFactory(context.container.get<ContractService>(SERVICE.Contract)),
    );

container
    .bind<AppEpic>(SERVICE.downloadEpicFactory)
    .toFactory((context) =>
        downloadEpicFactory(context.container.get<DownloadService>(SERVICE.Download)),
    );

container
    .bind<AppEpic>(SERVICE.importEpicFactory)
    .toFactory((context) =>
        importEpicFactory(context.container.get<ImportService>(SERVICE.Import)),
    );

container
    .bind<AppEpic>(SERVICE.logInEpicFactory)
    .toFactory((context) =>
        logInEpicFactory(
            context.container.get<AuthService>(SERVICE.Auth),
            context.container.get<UserService>(SERVICE.User),
            context.container.get<OrganisationService>(SERVICE.Organisation),
        ),
    );

container
    .bind<AppEpic>(SERVICE.inviteEpicFactory)
    .toFactory((context) =>
        inviteEpicFactory(context.container.get<InviteService>(SERVICE.Invite)),
    );

container
    .bind<AppEpic>(SERVICE.pendingInvitationsEpicFactory)
    .toFactory((context) =>
        pendingInvitationEpicFactory(context.container.get<InviteService>(SERVICE.Invite)),
    );

container
    .bind<AppEpic>(SERVICE.organisationEpicFactory)
    .toFactory((context) =>
        organisationEpicFactory(context.container.get<OrganisationService>(SERVICE.Organisation)),
    );

container
    .bind<AppEpic>(SERVICE.tokenEpicFactory)
    .toFactory((context) => tokenEpicFactory(context.container.get<AuthService>(SERVICE.Auth)));

container
    .bind<AppEpic>(SERVICE.uploadEpicFactory)
    .toFactory((context) =>
        uploadEpicFactory(context.container.get<UploadService>(SERVICE.Upload)),
    );

container
    .bind<AppEpic>(SERVICE.userEpicFactory)
    .toFactory((context) =>
        userEpicFactory(
            context.container.get<UserService>(SERVICE.User),
            context.container.get<StorageService>(SERVICE.Storage),
        ),
    );

container
    .bind<AppEpic>(SERVICE.paginationEpicFactory)
    .toFactory((context) =>
        paginationEpicFactory(context.container.get<PaginationService>(SERVICE.Pagination)),
    );

container
    .bind<AppEpic>(SERVICE.portfolioFilterEpicFactory)
    .toFactory((context) =>
        portfolioFilterEpicFactory(
            context.container.get<PortfolioFilterService>(SERVICE.PortfolioFilter),
        ),
    );
