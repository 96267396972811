import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { Profile } from 'features/user/models/userModel';
import {
    RouteParams,
    getBenefitId,
    getRouteId,
    routeParams,
} from 'common/selectors/routeSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import {
    getContractRequest,
    getContractRolesRequest,
} from 'features/contract/actions/contractActions';
import {
    getContractByRouteId,
    getFetchingContractsStatus,
    isContractManager,
} from 'features/contract/selectors/contractSelectors';
import { Contract } from 'features/contract/models/contractModels';
import { getEmployeesRequest } from 'features/user/actions/userActions';
import { getExecutivesRequest } from 'features/organisation/actions/organisationActions';
import { isAdmin } from 'features/user/selectors/userSelectors';

import { Benefit, BenefitOrganisationRole, BenefitPriority } from '../models/benefitModels';
import { BenefitDetails as BenefitDetailsComponent } from '../components/BenefitDetails';
import {
    deleteBenefitRequest,
    getContractBenefitDataRequest,
    getBenefitsRolesRequest,
    getBenefitPrioritiesRequest,
} from '../actions';
import {
    getFetchingBenefitsStatus,
    getBenefitByRouteId,
    getFetchingRolesStatus,
    getBenefitAssessors,
    getBenefitSuppliers,
    getBenefitPartners,
    getBenefitPriorities,
} from '../selectors/benefitSelectors';

interface BenefitDetailsStateProps {
    contract?: Contract;
    benefit?: Benefit;
    benefitId: number;
    contractId: number;
    isFetching: boolean;
    benefitAssessors: Profile[];
    benefitSuppliers: BenefitOrganisationRole[];
    benefitPartners: BenefitOrganisationRole[];
    priorities: BenefitPriority[];
    isContractManager: boolean;
    isAdmin: boolean;
}

interface BenefitDetailsDispatchProps {
    deleteBenefitRequest: typeof deleteBenefitRequest;
    redirectToUrl: typeof redirectToUrl;
    getContractRequest: typeof getContractRequest;
    getContractBenefitDataRequest: typeof getContractBenefitDataRequest;
    getBenefitsRolesRequest: typeof getBenefitsRolesRequest;
    getEmployeesRequest: typeof getEmployeesRequest;
    getExecutivesRequest: typeof getExecutivesRequest;
    getBenefitSuppliers: typeof getBenefitSuppliers;
    getBenefitPartners: typeof getBenefitPartners;
    getBenefitPrioritiesRequest: typeof getBenefitPrioritiesRequest;
    getContractRolesRequest: typeof getContractRolesRequest;
}

export type BenefitDetailsProps = BenefitDetailsStateProps &
    BenefitDetailsDispatchProps &
    RouteParams;

class BenefitDetails extends React.Component<BenefitDetailsProps> {
    public componentDidMount(): void {
        const { contractId, benefitId } = this.props;

        this.props.getContractRequest({ contractId });
        this.props.getContractRolesRequest(contractId);

        this.props.getEmployeesRequest();
        this.props.getExecutivesRequest();

        this.props.getContractBenefitDataRequest({
            contractId,
            benefitId,
        });
        this.props.getBenefitsRolesRequest({
            benefitIds: [benefitId],
            contractId,
        });
        this.props.getBenefitPrioritiesRequest({
            contractId,
            benefitId,
        });
    }

    public render(): JSX.Element {
        return <BenefitDetailsComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams): BenefitDetailsStateProps => ({
    contractId: getRouteId(ownProps),
    benefitId: getBenefitId(ownProps),
    isFetching:
        getFetchingBenefitsStatus(state) ||
        getFetchingContractsStatus(state) ||
        getFetchingRolesStatus(state),
    contract: getContractByRouteId(state, routeParams(ownProps)),
    benefit: getBenefitByRouteId(state, routeParams(ownProps))!,
    benefitAssessors: getBenefitAssessors(state),
    benefitSuppliers: getBenefitSuppliers(state),
    benefitPartners: getBenefitPartners(state),
    priorities: getBenefitPriorities(state),
    isContractManager: isContractManager(state),
    isAdmin: isAdmin(state),
});

export const BenefitDetailsContainer = connect(mapStateToProps, {
    getContractRequest,
    getContractBenefitDataRequest,
    getBenefitsRolesRequest,
    deleteBenefitRequest,
    redirectToUrl,
    getEmployeesRequest,
    getExecutivesRequest,
    getBenefitPrioritiesRequest,
    getContractRolesRequest,
})(BenefitDetails);
