import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { RouteParams, getRouteId, getPageNumber } from 'common/selectors/routeSelectors';
import { getFilteredContractId } from 'features/benefit/selectors/benefitSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { clearFilters } from 'features/pagination/actions/paginationActions';
import { isAdmin } from 'features/user/selectors/userSelectors';
import { RoleInvitation } from 'features/contract/models/contractModels';
import {
    getRolesPendingInvitationsWithBenefit,
    getRolesExpiredInvitationsWithBenefit,
} from 'features/invite/selectors/inviteSelectors';
import {
    getRolesPendingInvitationsSuccess,
    getRolesExpiredInvitationsSuccess,
    resendRolesEmailInvitationRequest,
    deletePendingRoleInviteRequest,
} from 'features/invite/actions/inviteActions';
import { getExecutivesRequest } from 'features/organisation/actions/organisationActions';
import { ContractInvitations as ContractInvitationsComponent } from 'features/contract/components/ContractInvitations';

import { isContractManager } from '../selectors/contractSelectors';

interface ContractInvitationsStateProps {
    contractId: number;
    filteredContractId: number;
    rolesPendingInvitations: RoleInvitation[];
    rolesExpiredInvitations: RoleInvitation[];
    isContractManager: boolean;
    isAdmin: boolean;
    path: string;
    pageNumber?: number;
}

interface ContractInvitationsDispatchProps {
    redirectToUrl: typeof redirectToUrl;
    clearFilters: typeof clearFilters;
    getExecutivesRequest: typeof getExecutivesRequest;
    deletePendingRoleInviteRequest: typeof deletePendingRoleInviteRequest;
    resendRolesEmailInvitationRequest: typeof resendRolesEmailInvitationRequest;
}

export type ContractInvitationsProps = ContractInvitationsStateProps &
    ContractInvitationsDispatchProps &
    RouteParams;

class ContractInvitations extends React.Component<ContractInvitationsProps> {
    state = {
        formattedPath: this.props.path,
    };

    public componentWillUnmount(): void {
        this.props.clearFilters(PaginationView.BenefitRolePendingInvitations);
        this.props.clearFilters(PaginationView.BenefitRoleExpiredInvitations);
    }

    public componentDidMount(): void {
        this.props.getExecutivesRequest();
    }

    public render(): JSX.Element {
        const newPath = this.state.formattedPath.replace(':id', this.props.contractId.toString());

        return (
            <ContractInvitationsComponent
                {...this.props}
                pendingInvitations={this.props.rolesPendingInvitations}
                expiredInvitations={this.props.rolesExpiredInvitations}
                pendingPaginationView={PaginationView.BenefitRolePendingInvitations}
                expiredPaginationView={PaginationView.BenefitRoleExpiredInvitations}
                resendPendingInvitation={this.props.resendRolesEmailInvitationRequest}
                resendExpiredInvitation={this.props.resendRolesEmailInvitationRequest}
                deletePendingInvitation={this.props.deletePendingRoleInviteRequest}
                deleteExpiredInvitation={this.props.deletePendingRoleInviteRequest}
                pendingInvitationsPaginationSuccessCallback={getRolesPendingInvitationsSuccess}
                expiredInvitationsPaginationSuccessCallback={getRolesExpiredInvitationsSuccess}
                redirectToUrl={this.props.redirectToUrl}
                contractId={this.props.contractId}
                requestParams={{ contractId: this.props.contractId }}
                path={newPath}
                isRole
            />
        );
    }
}

const mapStateToProps = (
    state: AppState,
    ownProps: RouteParams,
): ContractInvitationsStateProps => ({
    contractId: getRouteId(ownProps),
    filteredContractId: getFilteredContractId(state),
    rolesPendingInvitations: getRolesPendingInvitationsWithBenefit(state),
    rolesExpiredInvitations: getRolesExpiredInvitationsWithBenefit(state),
    isContractManager: isContractManager(state),
    isAdmin: isAdmin(state),
    path: ownProps.match.path.slice(0, ownProps.match.path.lastIndexOf('/')),
    pageNumber: getPageNumber(ownProps),
});

export const ContractInvitationsContainer = connect(mapStateToProps, {
    redirectToUrl,
    clearFilters,
    getExecutivesRequest,
    deletePendingRoleInviteRequest,
    resendRolesEmailInvitationRequest,
})(ContractInvitations);
