import * as React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';

import { UserOrganisation } from '../models/userModel';
import { OrganisationSelectList } from '../components/OrganisationSelectList';
import {
    toggleUserOrganisationSelectModal,
    updateUserActiveOrganisation,
} from '../actions/userActions';
import { getActiveOrganisation } from '../selectors/userSelectors';

interface OrganisationSelectModalContainerProps {
    isOrganisationSelectModalOpen: boolean;
    isFetching: boolean;
    organisations: UserOrganisation[];
    activeOrganisation?: UserOrganisation;
    toggleUserOrganisationSelectModal: typeof toggleUserOrganisationSelectModal;
    updateUserActiveOrganisation: typeof updateUserActiveOrganisation;
    redirectToUrl: typeof redirectToUrl;
}

interface OrganisationSelectModalDispatchProps {
    toggleUserOrganisationSelectModal: typeof toggleUserOrganisationSelectModal;
    updateUserActiveOrganisation: typeof updateUserActiveOrganisation;
}

type OrganisationSelectModalProps = OrganisationSelectModalContainerProps &
    OrganisationSelectModalDispatchProps;

class OrganisationSelectModal extends React.Component<OrganisationSelectModalProps> {
    private setActiveOrganisation = (organisation: UserOrganisation) => {
        this.props.toggleUserOrganisationSelectModal(false);
        this.props.updateUserActiveOrganisation(organisation);
        this.props.redirectToUrl('/');
    };

    public render(): JSX.Element {
        const { isOrganisationSelectModalOpen, organisations, isFetching, activeOrganisation } =
            this.props;

        return (
            <Modal
                visible={isOrganisationSelectModalOpen}
                closable={Boolean(activeOrganisation)}
                footer={null}
                onCancel={
                    Boolean(activeOrganisation)
                        ? () => this.props.toggleUserOrganisationSelectModal(false)
                        : undefined
                }
            >
                <OrganisationSelectList
                    organisationsList={organisations}
                    setActiveOrganisation={this.setActiveOrganisation}
                    isFetching={isFetching}
                />
            </Modal>
        );
    }
}

function mapStateToProps(state: AppState) {
    return {
        isOrganisationSelectModalOpen: state.user.isOrganisationSelectModalOpen,
        isFetching: state.user.isFetchingOrganisations,
        organisations: state.user.organisations,
        activeOrganisation: getActiveOrganisation(state),
    };
}

export const OrganisationSelectModalContainer = connect(mapStateToProps, {
    toggleUserOrganisationSelectModal,
    updateUserActiveOrganisation,
    redirectToUrl,
})(OrganisationSelectModal);
