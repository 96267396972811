import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { isTenant } from 'features/user/selectors/userSelectors';
import { breakpoints } from 'theme/variables';
import { DashboardPaths } from 'features/dashboard/models/dashboardModel';
import { UploadPaths } from 'features/upload/models/uploadModels';

const Image = styled.img`
    max-width: 140px;

    ${breakpoints.small} {
        max-width: 200px;
    }
    ${breakpoints.medium} {
        max-width: 100%;
        margin-top: 2rem;
    }
`;

const StyledLink = styled(Link)`
    :focus {
        text-decoration: none;
    }
`;

type Props = {
    onClick?: () => void;
};

export const Logo = ({ onClick }: Props) => {
    const isUserTenant = useSelector(isTenant);

    return (
        <StyledLink
            to={isUserTenant ? DashboardPaths.Contracts : UploadPaths.PendingEvidence}
            onClick={onClick}
        >
            <Image
                src="https://storage.googleapis.com/cenefits-static-assets/logo/cenefits-logo-white.png"
                alt="cenefits-logo"
            />
        </StyledLink>
    );
};
