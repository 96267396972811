import React from 'react';

import { generateColumns } from 'common/helpers/utils';
import { pickColumnsForInvitationList } from 'common/helpers/pickColumnsForInvitationList';
import {
    PaginationView,
    PaginationSuccessCallback,
    RequestParams,
} from 'features/pagination/models/paginationModel';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { RoleInvitation } from 'features/contract/models/contractModels';
import { PaddedSection } from 'common/components';
import { EmployeeExpandableRow } from 'features/pagination/components/EmployeeExpandableRow';

import {
    EmployeeInvitation,
    ResendRoleInvitation,
    DeleteRoleInvitation,
} from '../models/inviteModel';

interface InvitationTabProps extends PaginationSuccessCallback {
    data: EmployeeInvitation[] | RoleInvitation[];
    view: PaginationView;
    resendInvitation: (id: number | ResendRoleInvitation) => void;
    deleteInvitation: (id: string | DeleteRoleInvitation) => void;
    pageNumber?: number;
    pathUrl: string;
    requestParams?: RequestParams;
    isEmployee?: boolean;
    isExistingOrganisation?: boolean;
    isRole?: boolean;
}

export const InvitationTab: React.FunctionComponent<InvitationTabProps> = ({
    view,
    data,
    resendInvitation,
    deleteInvitation,
    paginationSuccessCallback,
    pageNumber,
    pathUrl,
    requestParams,
    isEmployee,
    isExistingOrganisation,
    isRole,
}) => (
    <PaddedSection>
        <PaginatedTable
            data={data}
            view={view}
            paginationSuccessCallback={paginationSuccessCallback}
            emptyText={`No data found`}
            columns={generateColumns(
                pickColumnsForInvitationList(
                    resendInvitation,
                    deleteInvitation,
                    isEmployee,
                    isExistingOrganisation,
                    isRole,
                ),
            )}
            pageNumber={pageNumber}
            pathUrl={pathUrl}
            withPaginationHistory
            requestParams={requestParams}
            isInvitationTable
            expandedRowRender={(rowData) => <EmployeeExpandableRow rowData={rowData} />}
        />
    </PaddedSection>
);
