import { CustomRouteProps } from 'common/models/navigationModel';
import { EditPaths } from 'features/edit/models/editModel';

import { ApprovedContainer } from '../containers/ApprovedContainer';
import { BenefitEvidenceContainer } from '../containers/BenefitEvidenceContainer';
import { BenefitScoreContainer } from '../containers/BenefitScoreContainer';
import { CheckEvidencesContainer } from '../containers/CheckEvidencesContainer';
import { CheckPendingEvidencesContainer } from '../containers/CheckPendingEvidencesContainer';
import { CheckScoresContainer } from '../containers/CheckScoresContainer';
import { CheckPaths } from '../models/checkModel';

const menuLinks = [
    { key: CheckPaths.Evidence, label: 'Evidence' },
    { key: CheckPaths.PendingEvidence, label: 'Pending Evidence' },
    { key: CheckPaths.ApprovedEvidence, label: 'Approved Evidence' },
    { key: CheckPaths.Scores, label: 'Feedback' },
    { key: CheckPaths.ApprovedScores, label: 'Approved Feedback' },
];

export const checkRoutes: CustomRouteProps[] = [
    {
        path: CheckPaths.Evidence,
        component: CheckEvidencesContainer,
        exact: true,
    },
    {
        path: CheckPaths.PendingEvidence,
        component: CheckPendingEvidencesContainer,
        exact: true,
    },
    {
        path: CheckPaths.ApprovedEvidence,
        component: ApprovedContainer,
        exact: true,
    },
    {
        path: `${CheckPaths.Evidence}${EditPaths.Contract}/:contractId/benefit/:benefitId`,
        component: BenefitEvidenceContainer,
    },
    {
        path: CheckPaths.Scores,
        component: CheckScoresContainer,
        exact: true,
    },
    {
        path: CheckPaths.ApprovedScores,
        component: ApprovedContainer,
        exact: true,
    },
    {
        path: `${CheckPaths.Scores}${EditPaths.Contract}/:contractId/benefit/:benefitId`,
        component: BenefitScoreContainer,
    },
].map((route) => ({
    ...route,
    menuLinks,
}));
