import * as React from 'react';
import { connect } from 'react-redux';

import { saveFilters, clearFilters } from 'features/pagination/actions/paginationActions';

import { DashboardFilter as DashboardFilterComponent } from '../components/DashboardFilter';
import { AppState } from 'common/appState';
import { getFilterId, getFilterBackUrl, RouteParams } from 'common/selectors/routeSelectors';
import { getFilterFormInitialValues } from 'features/pagination/selectors/paginationSelectors';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';

import {
    setDateRangeContractDashboard,
    setDateRangeContractPrioritiesDashboard,
} from 'features/contract/actions/contractActions';
import { setDateRangeBenefitTemplateDashboard } from 'features/benefit/actions/benefitTemplateActions';
import { setDateRangeBenefitPriorityDashboard } from 'features/benefit/actions/benefitActions';

interface DashboardFilterStateProps {
    filterId: number;
    backUrl: string;
    formInitial: PaginationFilters;
}

interface DashboardFilterDispatchProps {
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
    setDateRangeContractDashboard: typeof setDateRangeContractDashboard;
    setDateRangeContractPrioritiesDashboard: typeof setDateRangeContractPrioritiesDashboard;
    setDateRangeBenefitPriorityDashboard: typeof setDateRangeBenefitPriorityDashboard;
    setDateRangeBenefitTemplateDashboard: typeof setDateRangeBenefitTemplateDashboard;
}

export type DashboardFilterContainerProps = DashboardFilterDispatchProps &
    DashboardFilterStateProps;

export class DashboardFilter extends React.Component<DashboardFilterContainerProps> {
    public render(): JSX.Element {
        return <DashboardFilterComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams) => ({
    backUrl: getFilterBackUrl(ownProps),
    filterId: getFilterId(state, ownProps),
    formInitial: getFilterFormInitialValues(state, ownProps),
});

export const DashboardFilterContainer = connect(mapStateToProps, {
    saveFilters,
    clearFilters,
    setDateRangeContractDashboard,
    setDateRangeContractPrioritiesDashboard,
    setDateRangeBenefitPriorityDashboard,
    setDateRangeBenefitTemplateDashboard,
})(DashboardFilter);
