import * as React from 'react';
import { Tabs } from 'antd';

import { CreateBenefitForm } from 'features/create/components/CreateBenefitForm';
import { CreateTemplatedBenefit } from 'features/create/components/CreateTemplatedBenefit';
import { SmallTabs } from 'common/components/styled';

import { CreateBenefitContainerProps } from '../containers/CreateBenefitContainer';
import { CreateBenefitTitles } from '../models/createModel';

export const CreateBenefit: React.FunctionComponent<CreateBenefitContainerProps> = (props) => (
    <SmallTabs>
        <Tabs.TabPane
            key={CreateBenefitTitles.CreateTemplatedBenefit}
            tab={CreateBenefitTitles.CreateTemplatedBenefit}
        >
            <CreateTemplatedBenefit {...props} />
        </Tabs.TabPane>
        <Tabs.TabPane
            key={CreateBenefitTitles.CreateSpecificBenefit}
            tab={CreateBenefitTitles.CreateSpecificBenefit}
        >
            <CreateBenefitForm {...props} />
        </Tabs.TabPane>
    </SmallTabs>
);
