import styled from 'styled-components';
import { Card as AntdCard } from 'antd';
import { CardProps } from 'antd/lib/card';

import { borders, breakpoints, colors, fontSizes, fontWeights } from 'theme/variables';

interface Props extends CardProps {
    path?: string;
    active?: string | number;
}

export const CardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 240px;
    margin: 0 auto 20px auto;

    ${breakpoints.small} {
        max-width: 360px;
    }

    ${breakpoints.medium} {
        display: none;
    }
`;

export const Card = styled(AntdCard)<Props>`
    && {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 100px;
        margin: 10px 10px;
        font-size: ${fontSizes.extraSmall};
        background-color: ${(props) =>
            props.active ? colors.primary : colors.semiTransparentBlack};
        color: ${colors.primaryWhite};
        font-weight: ${fontWeights.bold};
        box-shadow: ${colors.contentShadow};
        border: none;
        border-radius: ${borders.mediumRadius};

        ${breakpoints.small} {
            height: 160px;
            width: 160px;
            margin: 10px;
            font-size: ${fontSizes.medium};
        }

        ${breakpoints.medium} {
            height: 200px;
            width: 200px;
            margin: 20px;
            font-size: ${fontSizes.large};
        }
    }
`;

export const CardName = styled.p`
    margin: 0;
    font-size: ${fontSizes.small};
    text-align: center;
`;
