import { inject, injectable } from 'inversify';

import { SERVICE } from 'config/identifiers';
import { config, rootNodeFilterParam } from 'config/config';
import { HttpService } from 'common/services/HttpService';

import {
    PortfolioFilterRequest,
    PortfolioFilter,
    GetPortfolioFiltersRequest,
} from '../models/portfolioFilterModel';
import { getPortfolioFiltersOtherUrl } from '../helpers/getPortfolioFiltersOtherUrl';
import { getPortfolioFiltersEditUrl } from '../helpers/getPortfolioFiltersEditUrl';

@injectable()
export class PortfolioFilterService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    public async getPortfolioFilterRoot(): Promise<PortfolioFilter> {
        const portfolioFiltersList = await this.http.GET<PortfolioFilter[]>(
            `${config.routeConfig.portfolioFilters}${rootNodeFilterParam}`,
        );

        if (portfolioFiltersList.length > 0) {
            return portfolioFiltersList[0];
        }
        throw Error('There is problem with portfolio filters root. Please contact the admin.');
    }

    public async getPortfolioFilters(
        portfolioFilterRequest: GetPortfolioFiltersRequest,
    ): Promise<PortfolioFilter[]> {
        const { portfolioFilterIds, depth, portfolioFilterRootId } = portfolioFilterRequest;
        const url = getPortfolioFiltersOtherUrl(portfolioFilterRootId, portfolioFilterIds, depth);

        return this.http.GET(url);
    }

    public async createPortfolioFilter(
        portfolioFilterRequest: PortfolioFilterRequest,
    ): Promise<PortfolioFilter> {
        const {
            portfolioFilter,
            params: { portfolioFilterIds, depth, portfolioFilterRootId },
        } = portfolioFilterRequest;

        const url = getPortfolioFiltersOtherUrl(portfolioFilterRootId, portfolioFilterIds, depth);

        return this.http.POST(url, portfolioFilter);
    }

    public async editPortfolioFilter(
        portfolioFilterRequest: PortfolioFilterRequest,
    ): Promise<PortfolioFilter> {
        const {
            portfolioFilter,
            params: { portfolioFilterIds, depth, portfolioFilterRootId },
        } = portfolioFilterRequest;

        const url = getPortfolioFiltersEditUrl(portfolioFilterRootId, portfolioFilterIds, depth);

        return this.http.PATCH(url, portfolioFilter);
    }

    public async deletePortfolioFilter(
        portfolioFilterRequest: PortfolioFilterRequest,
    ): Promise<PortfolioFilter> {
        const {
            params: { portfolioFilterIds, depth, portfolioFilterRootId },
        } = portfolioFilterRequest;

        const url = getPortfolioFiltersEditUrl(portfolioFilterRootId, portfolioFilterIds, depth);

        return this.http.DELETE(url);
    }
}
