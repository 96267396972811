import * as React from 'react';

import {
    DynamicFieldSet,
    DatePicker,
    Input,
    Select,
    ResetButton,
} from 'common/components/deprecated/form';
import { SubmitButton } from 'common/components';
import { notificationText, showNotification, Notification } from 'common/helpers/notifications';
import { dateFormat, notArchivedFilterParam } from 'config/config';
import { DateObject, FormList } from 'features/create/components/CreateBenefitForm';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField, AutocompleteData } from 'features/pagination/models/paginationModel';
import { benefitMethod } from 'features/contract/models/contractModels';
import { EditStatusExplanation } from 'features/benefit/components/EditStatusExplanation';
import {
    formatSupplierIdRequest,
    formatPartnerIdRequest,
    formatIndividualExecutiveRequest,
    formatAssessorsRequest,
    formatPrioritiesRequest,
    formatPriorityToState,
    checkIntegerConfirmation,
    checkDecimalPlaceConfirmation,
    formatInvitePendingInvididualExecutiveRequest,
} from 'common/helpers/utils';
import { templatedBenefitTooltips } from 'common/tooltips/templatedBenefitTooltips';
import { Organisation } from 'features/organisation/models/organisationModels';
import { SupplierForm } from 'features/create/components/SupplierForm';
import { DeliveryPartnerForm } from 'features/create/components/DeliveryPartnerForm';
import { InviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { BenefitRole, BenefitPriority } from 'features/benefit/models/benefitModels';
import { StyledAntd3Form, Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { CreateBenefitContainerProps } from '../containers/CreateBenefitContainer';
import { PrioritiesForm } from './PrioritiesForm';

interface CreateTemplatedBenefitState {
    priorities: BenefitPriority[];
    selectedDeliveryPartners: AutocompleteData[];
    deliveryPartnerForms: FormList;
    supplier?: Organisation;
    partners: Organisation[];
    individual_suppliers: AutocompleteData[];
    individual_partners: AutocompleteData[];
    new_executive_users: InviteByEmailWithRole[];
    invite_pending_supplier_users: AutocompleteData[];
    invite_pending_partner_users: AutocompleteData[];
}

type Props = CreateBenefitContainerProps & Antd3FormProps;

class CreateTemplatedBenefitForm extends React.Component<Props, CreateTemplatedBenefitState> {
    public state: CreateTemplatedBenefitState = {
        selectedDeliveryPartners: [],
        deliveryPartnerForms: {},
        supplier: undefined,
        partners: [],
        individual_suppliers: [],
        individual_partners: [],
        new_executive_users: [],
        priorities: [],
        invite_pending_supplier_users: [],
        invite_pending_partner_users: [],
    };

    private selectSupplierOrganisation = (supplier: Organisation) => {
        if (this.state.partners.find((item) => item.id == supplier.id)) {
            showNotification({
                text: 'This organisation has already been selected as a Delivery Partner.',
            });
            return;
        }
        this.setState({ supplier });
        this.setState({ individual_suppliers: [] });
        this.setState({ invite_pending_supplier_users: [] });
        this.setState({
            new_executive_users: this.state.new_executive_users.filter(
                (item) => item.role !== BenefitRole.SUPPLIER,
            ),
        });
    };

    private deleteSupplierOrganisation = (_: number) => {
        this.setState({ supplier: undefined });
        this.setState({ individual_suppliers: [] });
        this.setState({ invite_pending_supplier_users: [] });
        this.setState({
            new_executive_users: this.state.new_executive_users.filter(
                (item) => item.role !== BenefitRole.SUPPLIER,
            ),
        });
    };

    private selectSupplierUser = (supplier: AutocompleteData) => {
        if (this.state.individual_suppliers.find((item) => item.id == supplier.id)) {
            showNotification({ text: "You've already selected this supplier user." });
            return;
        }
        this.setState({ individual_suppliers: [...this.state.individual_suppliers, supplier] });
    };

    private deleteSupplierUser = (userId: number) => {
        this.setState({
            individual_suppliers: this.state.individual_suppliers.filter(
                (item) => item.id !== userId,
            ),
        });
    };

    private selectInvitePendingSupplierUser = (supplier: AutocompleteData) => {
        if (this.state.invite_pending_supplier_users.find((item) => item.id == supplier.id)) {
            showNotification({ text: "You've already selected this supplier user." });
            return;
        }
        this.setState({
            invite_pending_supplier_users: [...this.state.invite_pending_supplier_users, supplier],
        });
    };

    private deleteInvitePendingSupplierUser = (userId: number) => {
        this.setState({
            invite_pending_supplier_users: this.state.invite_pending_supplier_users.filter(
                (item) => item.id !== userId,
            ),
        });
    };

    private selectDeliveryPartnerOrganisation = (selectedPartner: Organisation) => {
        if (this.state.partners.find((item) => item.id == selectedPartner.id)) {
            showNotification({ text: "You've already selected this Delivery Partner." });
            return;
        }
        if (this.state.supplier && this.state.supplier.id == selectedPartner.id) {
            showNotification({
                text: 'This organisation has already been selected as a Supplier.',
            });
            return;
        }
        this.setState({ partners: [...this.state.partners, selectedPartner] });
    };

    private deleteDeliveryPartner = (partnerId: number) => {
        this.setState({
            partners: this.state.partners.filter((item) => item.id !== partnerId),
        });
        this.setState({
            individual_partners: this.state.individual_partners.filter(
                (item) => item.organisation !== partnerId,
            ),
        });
        this.setState({
            invite_pending_partner_users: this.state.invite_pending_partner_users.filter(
                (item) => item.organisation !== partnerId,
            ),
        });
        this.setState({
            new_executive_users: this.state.new_executive_users.filter(
                (item) =>
                    item.organisationId !== partnerId && item.role !== BenefitRole.DELIVERY_PARTNER,
            ),
        });
    };

    private selectDeliveryPartnerUser = (selectedExecutive: AutocompleteData) => {
        if (
            this.state.individual_partners.find(
                (item) =>
                    item.id == selectedExecutive.id &&
                    item.organisation == selectedExecutive.organisation,
            )
        ) {
            showNotification({ text: "You've already selected this user." });
            return;
        }
        this.setState({
            individual_partners: [...this.state.individual_partners, selectedExecutive],
        });
    };

    private deletePartnerUser = (executiveId: number) => {
        this.setState({
            individual_partners: this.state.individual_partners.filter(
                (item) => item.id !== executiveId,
            ),
        });
    };

    private selectInvitePendingPartnerUser = (selectedExecutive: AutocompleteData) => {
        if (
            this.state.invite_pending_partner_users.find(
                (item) =>
                    item.id == selectedExecutive.id &&
                    item.organisation == selectedExecutive.organisation,
            )
        ) {
            showNotification({ text: "You've already selected this user." });
            return;
        }
        this.setState({
            invite_pending_partner_users: [
                ...this.state.invite_pending_partner_users,
                selectedExecutive,
            ],
        });
    };

    private deleteInvitePendingPartnerUser = (executiveId: number) => {
        this.setState({
            invite_pending_partner_users: this.state.invite_pending_partner_users.filter(
                (item) => item.id !== executiveId,
            ),
        });
    };

    private selectNewExecutiveUser = (employeeData: InviteByEmailWithRole) => {
        this.setState({
            new_executive_users: [...this.state.new_executive_users, employeeData],
        });
    };

    private deleteNewExecutiveUser = (employeeData: InviteByEmailWithRole) => {
        this.setState({
            new_executive_users: this.state.new_executive_users.filter(
                (employee) => employee !== employeeData,
            ),
        });
    };

    private selectNewPriority = (priorityData: BenefitPriorities) => {
        const formattedPriority = formatPriorityToState(priorityData);

        this.setState((prevState) => ({
            ...prevState,
            priorities: [...prevState.priorities, formattedPriority],
        }));
    };

    private deletePriority = (priorityId: number) => {
        this.setState((prevState) => ({
            ...prevState,
            priorities: prevState.priorities.filter((item) => item.id !== Number(priorityId)),
        }));
    };

    private changePriorityValue = (priorityId: number, value: number) => {
        this.setState((prevState) => ({
            ...prevState,
            priorities: prevState.priorities.map((priority) =>
                priority.id === priorityId ? { ...priority, value } : priority,
            ),
        }));
    };

    private updateHintValues = (id: number) => {
        this.props.getBenefitTemplateWhenCreatingTemplatedBenefitRequest(id);
    };

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields(
            (error, { assessors, benefitTemplate, contract, ...values }) => {
                if (error) {
                    return showNotification({ text: notificationText.Error });
                }

                const deliveryDates: DateObject[] =
                    values.deliveryDates && Object.values(values.deliveryDates);

                const filteredDates = deliveryDates && deliveryDates.filter(({ date }) => date);

                const dates =
                    filteredDates &&
                    filteredDates.map(({ date, ...rest }: DateObject) => ({
                        ...rest,
                        date: date.format(dateFormat),
                    }));

                const partners =
                    this.state.partners.length === 0
                        ? []
                        : formatPartnerIdRequest(this.state.partners);

                const suppliers = this.state.supplier
                    ? formatSupplierIdRequest(this.state.supplier.id)
                    : [];

                const individualSuppliers =
                    this.state.individual_suppliers.length === 0
                        ? []
                        : formatIndividualExecutiveRequest(this.state.individual_suppliers);

                const individualPartners =
                    this.state.individual_partners.length === 0
                        ? []
                        : formatIndividualExecutiveRequest(this.state.individual_partners);

                const individualInvitePendingSuppliers =
                    this.state.invite_pending_supplier_users.length === 0
                        ? []
                        : formatInvitePendingInvididualExecutiveRequest(
                              this.state.invite_pending_supplier_users,
                              BenefitRole.SUPPLIER,
                          );

                const individualInvitePendingPartners =
                    this.state.invite_pending_partner_users.length === 0
                        ? []
                        : formatInvitePendingInvididualExecutiveRequest(
                              this.state.invite_pending_partner_users,
                              BenefitRole.DELIVERY_PARTNER,
                          );

                const new_executive_users = this.state.new_executive_users;

                this.state.partners.forEach(
                    (partner) => delete values[`individual_partners-${partner.id}`],
                );

                if (benefitTemplate && contract) {
                    return this.props.selectBenefitTemplateRequest({
                        ...values,
                        id: benefitTemplate,
                        contract,
                        assessors: formatAssessorsRequest(assessors, this.props.organisation),
                        suppliers,
                        partners,
                        deliveryDates: dates || [],
                        individual_suppliers: individualSuppliers,
                        individual_partners: individualPartners,
                        invite_pending_individual_users: individualInvitePendingSuppliers.concat(
                            individualInvitePendingPartners,
                        ),
                        new_executive_users,
                        priorities: formatPrioritiesRequest(this.state.priorities),
                    });
                }

                return showNotification({
                    type: Notification.Error,
                    text: 'Please choose a Benefit Template and/or Project',
                });
            },
        );
    };

    public render(): JSX.Element {
        const { form, selectedBenefitTemplate } = this.props;

        const contractId = this.props.contractId
            ? Number.parseInt(this.props.contractId, 10)
            : undefined;

        const benefitStatus = form.getFieldValue('status');

        const placeholderNumberOfPoints =
            selectedBenefitTemplate[0] && selectedBenefitTemplate[0].pointsHint
                ? 'Benefit Points (Hint: ' + selectedBenefitTemplate[0].pointsHint + ')'
                : 'Benefit Points';
        const placeholderHintValue =
            selectedBenefitTemplate[0] && selectedBenefitTemplate[0].valueHint
                ? 'Benefit Value (Hint: ' + selectedBenefitTemplate[0].valueHint + ')'
                : 'Benefit Value';
        const placeholderEmissionSavings =
            selectedBenefitTemplate[0] && selectedBenefitTemplate[0].emissionSavingsHint
                ? 'Emissions Savings (Hint: ' + selectedBenefitTemplate[0].emissionSavingsHint + ')'
                : 'Emissions Savings';

        return (
            <StyledAntd3Form
                onSubmit={this.handleSubmit}
                header="Create Templated Benefit"
                bigHeader
            >
                <ResetButton form={form} />
                <AutocompleteSelect
                    form={form}
                    id="benefitTemplate"
                    autocompleteField={AutocompleteField.BenefitTemplate}
                    required
                    label="Benefit Template Outcome"
                    notFoundContent="No Benefit Template found"
                    searchParams={notArchivedFilterParam}
                    onSelect={this.updateHintValues}
                    tooltip={templatedBenefitTooltips.BenefitTemplateOutcome}
                />

                <Input
                    form={form}
                    id="label"
                    placeholder="Benefit Label"
                    required={false}
                    tooltip={templatedBenefitTooltips.BenefitLabel}
                />

                <AutocompleteSelect
                    form={form}
                    id="contract"
                    autocompleteField={AutocompleteField.Contract}
                    required
                    label="Project Title / Reference Number"
                    notFoundContent="No projects found"
                    initialValue={contractId}
                    tooltip={templatedBenefitTooltips.ContractTitle}
                />
                <Select
                    form={form}
                    id="method"
                    placeholder="Benefit Model"
                    options={benefitMethod}
                    required={false}
                    allowClear
                    tooltip={templatedBenefitTooltips.BenefitModel}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Location}
                    id="location"
                    placeholder="Benefit Location"
                    notFoundContent="No Benefit Location found"
                    searchParams={notArchivedFilterParam}
                    required={false}
                    tooltip={templatedBenefitTooltips.BenefitLocation}
                />
                <Input
                    form={form}
                    id="points"
                    placeholder={placeholderNumberOfPoints}
                    type="number"
                    required={false}
                    validator={checkDecimalPlaceConfirmation}
                    tooltip={templatedBenefitTooltips.NumberOfPoints}
                />
                <Input
                    form={form}
                    id="value"
                    placeholder={placeholderHintValue}
                    type="number"
                    required={false}
                    validator={checkDecimalPlaceConfirmation}
                    tooltip={templatedBenefitTooltips.BenefitValue}
                />
                <Input
                    form={form}
                    id="emissionSavings"
                    placeholder={placeholderEmissionSavings}
                    type="number"
                    required={false}
                    validator={checkDecimalPlaceConfirmation}
                    tooltip={templatedBenefitTooltips.EmissionsSavings}
                />
                <Input
                    form={form}
                    id="quantity"
                    placeholder="Quantity"
                    type="number"
                    validator={checkIntegerConfirmation}
                    tooltip={templatedBenefitTooltips.Quantity}
                />
                <PrioritiesForm
                    form={form}
                    selectedPriorities={this.state.priorities}
                    selectNewPriority={this.selectNewPriority}
                    deletePriority={this.deletePriority}
                    changePriorityValue={this.changePriorityValue}
                    autocompleteField={AutocompleteField.BenefitPriorities}
                    id="priorities"
                    placeholder="Benefit Priorities"
                    notFoundContent="No benefit priorities found"
                    tooltip={templatedBenefitTooltips.BenefitPriorities}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Employee}
                    id="assessors"
                    placeholder="Contributor (Please read tooltip before use)"
                    notFoundContent="No Contributor found"
                    multiple
                    searchParams={notArchivedFilterParam}
                    required={false}
                    tooltip={templatedBenefitTooltips.ProjectManagers}
                />
                <SupplierForm
                    form={form}
                    newSupplierUsers={this.state.new_executive_users}
                    selectNewSupplierUser={this.selectNewExecutiveUser}
                    deleteNewSupplierUser={this.deleteNewExecutiveUser}
                    selectedSupplier={this.state.supplier}
                    selectSupplierOrganisation={this.selectSupplierOrganisation}
                    deleteSupplierOrganisation={this.deleteSupplierOrganisation}
                    selectedSupplierUsers={this.state.individual_suppliers}
                    selectSupplierUser={this.selectSupplierUser}
                    deleteSupplierUser={this.deleteSupplierUser}
                    selectedInvitePendingUsers={this.state.invite_pending_supplier_users}
                    selectInvitePendingSupplierUser={this.selectInvitePendingSupplierUser}
                    deleteInvitePendingSupplierUser={this.deleteInvitePendingSupplierUser}
                    tooltip={templatedBenefitTooltips.Supplier}
                />
                <DeliveryPartnerForm
                    form={this.props.form}
                    newPartnerUsers={this.state.new_executive_users}
                    selectNewPartnerUser={this.selectNewExecutiveUser}
                    deleteNewPartnerUser={this.deleteNewExecutiveUser}
                    selectedPartners={this.state.partners}
                    selectPartnerOrganisation={this.selectDeliveryPartnerOrganisation}
                    deletePartner={this.deleteDeliveryPartner}
                    selectedPartnerUsers={this.state.individual_partners}
                    selectPartnerUser={this.selectDeliveryPartnerUser}
                    deletePartnerUser={this.deletePartnerUser}
                    selectedInvitePendingUsers={this.state.invite_pending_partner_users}
                    selectInvitePendingPartnerUser={this.selectInvitePendingPartnerUser}
                    deleteInvitePendingPartnerUser={this.deleteInvitePendingPartnerUser}
                    tooltip={templatedBenefitTooltips.DeliveryPartners}
                />
                <DatePicker
                    form={form}
                    id="startDate"
                    message="Please select the Benefit Start Date"
                    placeholder="Benefit Start Date"
                    required={false}
                    tooltip={templatedBenefitTooltips.StartDate}
                />
                <DynamicFieldSet
                    fieldSetId="deliveryDates"
                    fieldType="deliveryDate"
                    label="Interim Delivery Date"
                    placeholder="Interim Delivery Date"
                    form={form}
                    tooltip={templatedBenefitTooltips.InterimDeliveryDate}
                />
                <DatePicker
                    form={form}
                    id="finalDeliveryDate"
                    placeholder="Benefit Completion Date"
                    tooltip={templatedBenefitTooltips.FinalDeliveryDate}
                />
                <EditStatusExplanation
                    form={form}
                    id="status"
                    benefitStatus={benefitStatus}
                    defaultValueForNewBenefit={true}
                    tooltip={templatedBenefitTooltips.BenefitStatus}
                />
                <SubmitButton value="Create Templated Benefit" />
            </StyledAntd3Form>
        );
    }
}
export const CreateTemplatedBenefit = Antd3Form.create({})(CreateTemplatedBenefitForm);
