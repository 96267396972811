import * as React from 'react';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';
import { connect } from 'react-redux';

import { EditUploadContractNoteFile, SubmitButton } from 'common/components';
import { ContractNoteData } from 'features/contract/models/contractModels';
import {
    editContractNoteRequest,
    deleteContractNoteFileRequest,
    addContractNoteFileRequest,
} from 'features/contract/actions/contractActions';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { Antd3Form, Antd3FormProps, StyledAntd3Form } from 'common/components/deprecated/antd3';
import { TextArea, ResetButton } from 'common/components/deprecated/form';

interface EditContractNoteFormProps extends Antd3FormProps {
    contractId: number;
    contractNote: ContractNoteData;
}

interface EditContractNoteDispatchProps {
    editContractNoteRequest: typeof editContractNoteRequest;
    deleteContractNoteFileRequest: typeof deleteContractNoteFileRequest;
    addContractNoteFileRequest: typeof addContractNoteFileRequest;
}

type Props = EditContractNoteFormProps & EditContractNoteDispatchProps;

export class EditContractNoteForm extends React.Component<Props> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { ...values }) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }

            return this.props.editContractNoteRequest({
                ...values,
                contract: this.props.contractId,
                id: this.props.contractNote.id,
            });
        });
    };

    private addFile = (file: IUploadFile) => {
        this.props.addContractNoteFileRequest({
            note: this.props.contractNote.id,
            file,
            contract: this.props.contractId,
        });
    };

    private removeFile = (id: number) => {
        this.props.deleteContractNoteFileRequest({ id, contract: this.props.contractId });
    };

    public render(): JSX.Element {
        const { contractNote, form } = this.props;

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit}>
                <ResetButton form={form} />
                <TextArea
                    form={form}
                    id="note"
                    placeholder="Project Note"
                    initialValue={contractNote.note}
                    required={false}
                    rows={4}
                />
                <EditUploadContractNoteFile
                    form={form}
                    id="contractNoteFile"
                    addFile={this.addFile}
                    removeFile={this.removeFile}
                    uploadedFiles={contractNote.files}
                    description={'Private Project Note File'}
                />
                <SubmitButton value="Save" />
            </StyledAntd3Form>
        );
    }
}

export const EditContractNote = connect(null, {
    editContractNoteRequest,
    deleteContractNoteFileRequest,
    addContractNoteFileRequest,
})(Antd3Form.create({})(EditContractNoteForm));
