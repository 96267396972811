import { Progress } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { Antd3Form, Antd3Input, Antd3InputProps } from './deprecated/antd3';

const NewPasswordWrapper = styled(Antd3Form.Item)`
    && {
        margin-bottom: 0;
    }
`;

const PasswordStrengthIndicator = styled(Progress)`
    transform: translateY(-10px);
`;

interface NewPasswordInputProps extends Antd3InputProps {
    onChange(event: React.ChangeEvent<HTMLInputElement>): void;
    percent: number;
    status: 'success' | 'active' | 'exception' | undefined;
    required?: boolean;
}

export class NewPasswordInput extends React.Component<NewPasswordInputProps> {
    public render(): JSX.Element {
        const { label, placeholder, form, id, required, onChange, percent, status } = this.props;

        return (
            <NewPasswordWrapper label={label || placeholder}>
                <Antd3Input
                    form={form}
                    id={id}
                    getFieldDecoratorOptions={{
                        rules: [
                            {
                                required: required || false,
                                message: 'Your password must contain at least 9 characters',
                                min: 9,
                            },
                        ],
                    }}
                    type="password"
                    size="large"
                    placeholder={placeholder}
                    onChange={onChange}
                    minLength={9}
                />

                <PasswordStrengthIndicator percent={percent} status={status} showInfo={false} />
            </NewPasswordWrapper>
        );
    }
}
