import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { isTenant } from 'features/user/selectors/userSelectors';

import { CheckPendingEvidences as CheckPendingEvidencesComponent } from '../components/CheckPendingEvidences';
import { CheckBenefit } from '../models/checkModel';
import { getCheckEvidences } from '../selectors/checkSelectors';

export interface CheckPendingEvidencesStateProps {
    checkEvidences: CheckBenefit[];
    filter?: PaginationFilters;
    isTenant: boolean;
}

interface CheckPendingEvidencesContainerDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    redirectToUrl: typeof redirectToUrl;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
}

export type CheckPendingEvidencesProps = CheckPendingEvidencesStateProps &
    CheckPendingEvidencesContainerDispatchProps;

class CheckPendingEvidences extends React.Component<CheckPendingEvidencesProps> {
    public render(): JSX.Element {
        return <CheckPendingEvidencesComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState): CheckPendingEvidencesStateProps => ({
    checkEvidences: getCheckEvidences(state),
    filter: getFilterParams(state, PaginationView.CheckEvidence),
    isTenant: isTenant(state),
});

export const CheckPendingEvidencesContainer = connect(mapStateToProps, {
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
})(CheckPendingEvidences);
