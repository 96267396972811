import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import { saveFilters } from 'features/pagination/actions/paginationActions';

import { PaginationView } from '../models/paginationModel';

type Props = {
    paginatedView: PaginationView;
    path?: string;
    filterName?: string;
};

export const PaginationValueFilter = ({ paginatedView, path, filterName = 'name' }: Props) => {
    const dispatch = useDispatch();

    const handleNameFilterChange = debounce(
        (e) =>
            dispatch(
                saveFilters({
                    paginatedView,
                    values: { [filterName]: e.target.value },
                    path,
                }),
            ),
        300,
    );

    return <Input onChange={handleNameFilterChange} placeholder="Filter name..." allowClear />;
};
