import { AppState } from 'common/appState';
import { getContractBenefits } from 'features/benefit/selectors/benefitSelectors';
import { ContractBenefit } from 'features/benefit/models/benefitModels';
import { getExecutives } from 'features/organisation/selectors/organisationSelectors';
import { Organisation } from 'features/organisation/models/organisationModels';

export const getInviteFetchingStatus = (state: AppState) => state.invite.isFetching;

export const getEmployeePendingInvitations = (state: AppState) =>
    state.invite.employeesPendingInvitations;

export const getEmployeeExpiredInvitations = (state: AppState) =>
    state.invite.employeesExpiredInvitations;

export const getRolesPendingInvitations = (state: AppState) => state.invite.rolesPendingInvitations;

export const getRolesExpiredInvitations = (state: AppState) => state.invite.rolesExpiredInvitations;

export const getRolesPendingInvitationsWithBenefit = (state: AppState) => {
    const benefits = getContractBenefits(state);
    const executives = getExecutives(state);
    const invites = getRolesPendingInvitations(state);

    return invites.map((invite) => ({
        ...invite,
        awaitingRoles: invite.awaitingRoles.map((role) => ({
            id: role.id,
            role: role.role,
            benefit: role.benefit,
            contract: role.contract,
            referenceNumber: findBenefit(role.benefit, benefits),
            executive: findExecutive(invite.organisation, executives),
        })),
    }));
};

export const getRolesExpiredInvitationsWithBenefit = (state: AppState) => {
    const benefits = getContractBenefits(state);
    const executives = getExecutives(state);
    const invites = getRolesExpiredInvitations(state);

    return invites.map((invite) => ({
        ...invite,
        awaitingRoles: invite.awaitingRoles.map((role) => ({
            id: role.id,
            role: role.role,
            benefit: role.benefit,
            contract: role.contract,
            referenceNumber: findBenefit(role.benefit, benefits),
            executive: findExecutive(invite.organisation, executives),
        })),
    }));
};

const findBenefit = (benefitId: number, benefits: ContractBenefit[]) => {
    const benefit = benefits.find((item) => item.id == benefitId);
    if (benefit) {
        return benefit.referenceNumber;
    }
    return '';
};

const findExecutive = (orgId: number, executives: Organisation[]) => {
    const executive = executives.find((org) => org.id == orgId);
    if (executive) {
        return executive.name;
    }
    return '';
};

export const getExistingOrganisationPendingInvitations = (state: AppState) =>
    state.invite.existingOrganisationPendingInvitations;

export const getselectedOrganisationInvitedIndividualExecutive = (state: AppState) =>
    state.invite.selectedOrganisationInvitedIndividualExecutive.results;
