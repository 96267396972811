import { Action } from 'common/actions/appAction';

import { Organisation } from '../models/organisationModels';

export const EDIT_ORGANISATION_NAME_REQUESTED = 'EDIT_ORGANISATION_NAME_REQUESTED';
export type EditOrganisationNameRequestAction = Action<
    typeof EDIT_ORGANISATION_NAME_REQUESTED,
    string
>;
export const editOrganisationNameRequest = (name: string): EditOrganisationNameRequestAction => ({
    type: EDIT_ORGANISATION_NAME_REQUESTED,
    payload: name,
});

export const EDIT_ORGANISATION_NAME_SUCCEED = 'EDIT_ORGANISATION_NAME_SUCCEED';
export type EditOrganisationNameSuccessAction = Action<
    typeof EDIT_ORGANISATION_NAME_SUCCEED,
    null,
    string
>;
export const editOrganisationNameSuccess = (): EditOrganisationNameSuccessAction => ({
    type: EDIT_ORGANISATION_NAME_SUCCEED,
    meta: 'Organisation name has been changed',
});

export const EDIT_ORGANISATION_NAME_FAILED = 'EDIT_ORGANISATION_NAME_FAILED';
export type EditOrganisationNameFailureAction = Action<
    typeof EDIT_ORGANISATION_NAME_FAILED,
    string
>;
export const editOrganisationNameFailure = (
    payload: string,
): EditOrganisationNameFailureAction => ({
    type: EDIT_ORGANISATION_NAME_FAILED,
    payload,
});

export const GET_ORGANISATION_REQUESTED = 'GET_ORGANISATION_REQUESTED';
export type GetOrganisationRequestAction = Action<typeof GET_ORGANISATION_REQUESTED, number>;
export const getOrganisationRequest = (id: number): GetOrganisationRequestAction => ({
    type: GET_ORGANISATION_REQUESTED,
    payload: id,
});

export const GET_ORGANISATION_SUCCEED = 'GET_ORGANISATION_SUCCEED';
export type GetOrganisationSuccessAction = Action<typeof GET_ORGANISATION_SUCCEED, Organisation>;
export const getOrganisationSuccess = (
    organisation: Organisation,
): GetOrganisationSuccessAction => ({
    type: GET_ORGANISATION_SUCCEED,
    payload: organisation,
});

export const GET_ORGANISATION_FAILED = 'GET_ORGANISATION_FAILED';
export type GetOrganisationFailureAction = Action<typeof GET_ORGANISATION_FAILED, string>;
export const getOrganisationFailure = (payload: string): GetOrganisationFailureAction => ({
    type: GET_ORGANISATION_FAILED,
    payload,
});

export const EDIT_ORGANISATION_REQUESTED = 'EDIT_ORGANISATION_REQUESTED';
export type EditOrganisationRequestAction = Action<
    typeof EDIT_ORGANISATION_REQUESTED,
    Partial<Organisation>
>;
export const editOrganisationRequest = (
    organisation: Partial<Organisation>,
): EditOrganisationRequestAction => ({
    type: EDIT_ORGANISATION_REQUESTED,
    payload: organisation,
});

export const EDIT_ORGANISATION_SUCCEED = 'EDIT_ORGANISATION_SUCCEED';
export type EditOrganisationSuccessAction = Action<
    typeof EDIT_ORGANISATION_SUCCEED,
    Organisation,
    string
>;
export const editOrganisationSuccess = (
    organisation: Organisation,
): EditOrganisationSuccessAction => ({
    type: EDIT_ORGANISATION_SUCCEED,
    meta: 'Organisation data updated',
    payload: organisation,
});

export const EDIT_ORGANISATION_FAILED = 'EDIT_ORGANISATION_FAILED';
export type EditOrganisationFailureAction = Action<typeof EDIT_ORGANISATION_FAILED, string>;
export const editOrganisationFailure = (payload: string): EditOrganisationFailureAction => ({
    type: EDIT_ORGANISATION_FAILED,
    payload,
});

export const GET_EXECUTIVES_REQUESTED = 'GET_EXECUTIVES_REQUESTED';
export type GetExecutivesRequestAction = Action<typeof GET_EXECUTIVES_REQUESTED>;
export const getExecutivesRequest = (): GetExecutivesRequestAction => ({
    type: GET_EXECUTIVES_REQUESTED,
});

export const GET_EXECUTIVES_SUCCEED = 'GET_EXECUTIVES_SUCCEED';
export type GetExecutivesSuccessAction = Action<
    typeof GET_EXECUTIVES_SUCCEED,
    { executives: Organisation[] }
>;
export const getExecutivesSuccess = (executives: Organisation[]): GetExecutivesSuccessAction => ({
    type: GET_EXECUTIVES_SUCCEED,
    payload: { executives },
});

export const GET_EXECUTIVES_FAILED = 'GET_EXECUTIVES_FAILED';
export type GetExecutivesFailureAction = Action<typeof GET_EXECUTIVES_FAILED, string>;
export const getExecutivesFailure = (payload: string): GetExecutivesFailureAction => ({
    type: GET_EXECUTIVES_FAILED,
    payload,
});

export const GET_POTENTIAL_EXECUTIVES_REQUESTED = 'GET_POTENTIAL_EXECUTIVES_REQUESTED';
export type GetPotentialExecutivesRequestAction = Action<typeof GET_POTENTIAL_EXECUTIVES_REQUESTED>;
export const getPotentialExecutivesRequest = (): GetPotentialExecutivesRequestAction => ({
    type: GET_POTENTIAL_EXECUTIVES_REQUESTED,
});

export const GET_POTENTIAL_EXECUTIVES_SUCCEED = 'GET_POTENTIAL_EXECUTIVES_SUCCEED';
export type GetPotentialExecutivesSuccessAction = Action<
    typeof GET_POTENTIAL_EXECUTIVES_SUCCEED,
    { potentialExecutives: Organisation[] }
>;
export const getPotentialExecutivesSuccess = (
    potentialExecutives: Organisation[],
): GetPotentialExecutivesSuccessAction => ({
    type: GET_POTENTIAL_EXECUTIVES_SUCCEED,
    payload: { potentialExecutives },
});

export const GET_POTENTIAL_EXECUTIVES_FAILED = 'GET_POTENTIAL_EXECUTIVES_FAILED';
export type GetPotentialExecutivesFailureAction = Action<
    typeof GET_POTENTIAL_EXECUTIVES_FAILED,
    string
>;
export const getPotentialExecutivesFailure = (
    payload: string,
): GetPotentialExecutivesFailureAction => ({
    type: GET_POTENTIAL_EXECUTIVES_FAILED,
    payload,
});

export const GET_ALL_EXECUTIVES_REQUESTED = 'GET_ALL_EXECUTIVES_REQUESTED';
export type GetAllExecutivesRequestAction = Action<typeof GET_ALL_EXECUTIVES_REQUESTED>;
export const getAllExecutivesRequest = (): GetAllExecutivesRequestAction => ({
    type: GET_ALL_EXECUTIVES_REQUESTED,
});

export const GET_ALL_EXECUTIVES_SUCCEED = 'GET_ALL_EXECUTIVES_SUCCEED';
export type GetAllExecutivesSuccessAction = Action<
    typeof GET_ALL_EXECUTIVES_SUCCEED,
    { executives: Organisation[] }
>;
export const getAllExecutivesSuccess = (
    executives: Organisation[],
): GetAllExecutivesSuccessAction => ({
    type: GET_ALL_EXECUTIVES_SUCCEED,
    payload: { executives },
});

export const GET_ALL_EXECUTIVES_FAILED = 'GET_ALL_EXECUTIVES_FAILED';
export type GetAllExecutivesFailureAction = Action<typeof GET_ALL_EXECUTIVES_FAILED, string>;
export const getAllExecutivesFailure = (payload: string): GetAllExecutivesFailureAction => ({
    type: GET_ALL_EXECUTIVES_FAILED,
    payload,
});

export const REMOVE_EXECUTIVE_REQUESTED = 'REMOVE_EXECUTIVE_REQUESTED';
export type RemoveExecutiveRequestAction = Action<typeof REMOVE_EXECUTIVE_REQUESTED, number>;
export const removeExecutiveRequest = (id: number): RemoveExecutiveRequestAction => ({
    type: REMOVE_EXECUTIVE_REQUESTED,
    payload: id,
});

export const REMOVE_EXECUTIVE_SUCCEED = 'REMOVE_EXECUTIVE_SUCCEED';
export type RemoveExecutiveSuccessAction = Action<typeof REMOVE_EXECUTIVE_SUCCEED, null, string>;
export const removeExecutiveSuccess = (): RemoveExecutiveSuccessAction => ({
    type: REMOVE_EXECUTIVE_SUCCEED,
    meta: 'Organisation has been removed',
});

export const REMOVE_EXECUTIVE_FAILED = 'REMOVE_EXECUTIVE_FAILED';
export type RemoveExecutiveFailureAction = Action<typeof REMOVE_EXECUTIVE_FAILED, string>;
export const removeExecutiveFailure = (payload: string): RemoveExecutiveFailureAction => ({
    type: REMOVE_EXECUTIVE_FAILED,
    payload,
});

export const CHECK_ORGANISATION_EXISTS = 'CHECK_ORGANISATION_EXISTS';
export type CheckOrganisationExistsAction = Action<typeof CHECK_ORGANISATION_EXISTS, string>;
export const checkOrganisationExists = (payload: string): CheckOrganisationExistsAction => ({
    type: CHECK_ORGANISATION_EXISTS,
    payload,
});

export const CHECK_ORGANISATION_EXISTS_FAILED = 'CHECK_ORGANISATION_EXISTS_FAILED';
export type CheckOrganisationExistsFailedAction = Action<
    typeof CHECK_ORGANISATION_EXISTS_FAILED,
    string
>;
export const checkOrganisationExistsFailed = (
    payload: string,
): CheckOrganisationExistsFailedAction => ({
    type: CHECK_ORGANISATION_EXISTS_FAILED,
    payload,
});

export const CHECK_ORGANISATION_EXISTS_SUCCEED = 'CHECK_ORGANISATION_EXISTS_SUCCEED';
export type CheckOrganisationExistsSuccessAction = Action<
    typeof CHECK_ORGANISATION_EXISTS_SUCCEED,
    string
>;
export const checkOrganisationExistsSuccess = (
    payload: string,
): CheckOrganisationExistsSuccessAction => ({
    type: CHECK_ORGANISATION_EXISTS_SUCCEED,
    meta: null,
    payload,
});

export type OrganisationAction =
    | EditOrganisationNameRequestAction
    | EditOrganisationNameSuccessAction
    | EditOrganisationNameFailureAction
    | GetOrganisationRequestAction
    | GetOrganisationSuccessAction
    | GetOrganisationFailureAction
    | EditOrganisationRequestAction
    | EditOrganisationSuccessAction
    | EditOrganisationFailureAction
    | GetExecutivesRequestAction
    | GetExecutivesSuccessAction
    | GetExecutivesFailureAction
    | GetPotentialExecutivesRequestAction
    | GetPotentialExecutivesSuccessAction
    | GetPotentialExecutivesFailureAction
    | GetAllExecutivesRequestAction
    | GetAllExecutivesSuccessAction
    | GetAllExecutivesFailureAction
    | RemoveExecutiveRequestAction
    | RemoveExecutiveSuccessAction
    | RemoveExecutiveFailureAction
    | CheckOrganisationExistsAction
    | CheckOrganisationExistsSuccessAction
    | CheckOrganisationExistsFailedAction;
