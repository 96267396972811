import * as React from 'react';
import { connect } from 'react-redux';
import { Divider } from 'antd';

import { InnerCard } from 'common/components/styled';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteData, AutocompleteField } from 'features/pagination/models/paginationModel';
import { Organisation } from 'features/organisation/models/organisationModels';
import { selectBenefitContract } from 'features/benefit/actions';
import {
    autocompleteRequest,
    clearInitialValueData,
    getInitialValueRequest,
} from 'features/pagination/actions/paginationActions';
import { selectContractOrganisation } from 'features/contract/actions/contractActions';
import { RolesList } from 'common/components';
import { InviteUserModal } from 'features/invite/components/InviteUserModal';
import { DeleteOrganisationButton } from 'features/create/components/DeletePartnerButton';
import { BenefitOrganisationRole, BenefitRole } from 'features/benefit/models/benefitModels';
import { EditBenefitInviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { RoleInvitation } from 'features/contract/models/contractModels';
import { Antd3FormProps } from 'common/components/deprecated/antd3';

interface AutocompleteDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    getInitialValueRequest: typeof getInitialValueRequest;
    clearInitialValueData: typeof clearInitialValueData;
    selectContractOrganisation: typeof selectContractOrganisation;
    selectBenefitContract: typeof selectBenefitContract;
}

interface AutocompleteSelectionProps {
    benefitId: number;
    contractId: number;
    selectedPartners: BenefitOrganisationRole[];
    selectPartnerOrganisation: (selectedSupplier: Organisation) => void;
    deletePartner: (partnerId: number, orgId: number) => void;
    selectedPartnerUsers: BenefitOrganisationRole[];
    selectPartnerUser: (selectedExecutive: AutocompleteData) => void;
    deletePartnerUser: (executiveId: number) => void;
    pendingPartnerUsers: RoleInvitation[];
    selectInvitePendingPartnerUser: (selectedExecutive: AutocompleteData) => void;
    deletePendingPartnerUser: (roleId: string) => void;
    inviteNewEmployee: (employee: EditBenefitInviteByEmailWithRole) => void;
    tooltip?: string;
}

class EditDeliveryPartnerFields extends React.Component<
    AutocompleteSelectionProps & AutocompleteDispatchProps & Antd3FormProps
> {
    public render(): JSX.Element {
        const { form, deletePendingPartnerUser, pendingPartnerUsers, benefitId, contractId } =
            this.props;

        const partnerForms = this.props.selectedPartners.map((partner) => (
            <div key={partner.id}>
                {partner.name}
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.BenefitRoleExecutives}
                    id={`individual_partners-${partner.id}`}
                    placeholder="Add Existing Delivery Partner Users"
                    notFoundContent="No users found"
                    getAutocompleteData={this.props.selectPartnerUser}
                    searchParams={{ organisation: partner.organisation }}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.BenefitRoleInvitedExecutives}
                    id="invited_individual_delivery_partners"
                    placeholder="Add Invited Delivery Partner Users"
                    notFoundContent="No users with pending invites"
                    getAutocompleteData={this.props.selectInvitePendingPartnerUser}
                    searchParams={{
                        organisation: partner.organisation,
                    }}
                />
                <InviteUserModal
                    form={form}
                    onInvite={this.props.inviteNewEmployee}
                    organisationName={partner.name}
                    organisationId={partner.organisation!}
                    role={BenefitRole.DELIVERY_PARTNER}
                    contractId={contractId}
                />
                <Divider orientation="left">Active Roles</Divider>
                <RolesList
                    items={this.props.selectedPartnerUsers
                        .filter((user) => user.organisation == partner.organisation)
                        .map(({ id, email }) => ({
                            id,
                            name: email ? email : '',
                        }))}
                    deleteItem={this.props.deletePartnerUser}
                    noData={`No Delivery Partner users added to ${partner.name}`}
                />
                {pendingPartnerUsers.length > 0 && (
                    <>
                        <Divider orientation="left">Pending Roles</Divider>
                        <RolesList
                            items={pendingPartnerUsers.map(({ awaitingRoles, email }) => ({
                                id: awaitingRoles.filter((item) => item.benefit == benefitId)[0].id,
                                name: email ? email : '',
                            }))}
                            deleteItem={deletePendingPartnerUser}
                            noData="There are no pending supplier user invites."
                        />
                    </>
                )}
                <DeleteOrganisationButton
                    onClick={() => this.props.deletePartner(partner.id, partner.organisation!)}
                />
            </div>
        ));

        return (
            <InnerCard>
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ExecutiveOrganisation}
                    id="partners"
                    placeholder="Choose Delivery partner Organisations"
                    notFoundContent="No executive organisations found"
                    getAutocompleteData={this.props.selectPartnerOrganisation}
                    tooltip={this.props.tooltip}
                />
                {partnerForms}
            </InnerCard>
        );
    }
}

export const EditDeliveryPartnerForm = connect(null, {
    autocompleteRequest,
    getInitialValueRequest,
    clearInitialValueData,
    selectContractOrganisation,
    selectBenefitContract,
})(EditDeliveryPartnerFields);
