import * as React from 'react';
import { Input, Button, Space } from 'antd';
import styled from 'styled-components';

import { Antd3Icon } from 'common/components/deprecated/antd3';

import { ScoreDecline, EvidenceDecline } from '../models/uploadModels';

const DeclineWrapper = styled(Space)`
    width: 100%;
    justify-content: space-between;
    padding: 0 1.5rem 0.5rem;

    > .ant-space-item:nth-of-type(1) {
        flex: 1;
    }
`;

interface DeclineData {
    benefitId: number;
    contractId: number;
    organisationId: number;
}

interface DeclineScoreData extends DeclineData {
    scoreId: number;
}

interface DeclineEvidenceData extends DeclineData {
    evidenceId: number;
    deliveryDateId: number;
}

interface DeclineProps {
    data: DeclineScoreData | DeclineEvidenceData;
    onDecline(declineRequest: ScoreDecline | EvidenceDecline): void;
    dataType: 'Evidence' | 'Feedback';
    loading?: boolean;
}

interface DeclineState {
    declineMessage: string;
}

export class Decline extends React.Component<DeclineProps> {
    public state: DeclineState = {
        declineMessage: '',
    };

    private handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
        this.setState({ declineMessage: event.target.value });

    private onDecline = (event: React.MouseEvent) => {
        event.preventDefault();
        this.props.onDecline({
            ...this.props.data,
            declineMessage: this.state.declineMessage,
        });
    };

    public render(): JSX.Element {
        const { loading } = this.props;

        return (
            <DeclineWrapper size="small" align="center">
                <Input.TextArea
                    value={this.state.declineMessage}
                    placeholder="Reject message"
                    onChange={this.handleInput}
                />
                <Button type="danger" onClick={this.onDecline} disabled={loading} loading={loading}>
                    {`Reject ${this.props.dataType}`}
                    <Antd3Icon type="delete" />
                </Button>
            </DeclineWrapper>
        );
    }
}
