import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';
import { getUserId } from 'features/user/selectors/userSelectors';
import { toggleMultipleEmployeesRoleRequest } from 'features/benefit/actions';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import {
    getSelectedBenefitContractId,
    getBenefits,
    getFilteringBenefitsStatus,
} from 'features/benefit/selectors/benefitSelectors';
import { Benefit } from 'features/benefit/models/benefitModels';
import { RouteParams, getPageNumber } from 'common/selectors/routeSelectors';

import { SupplierEditContracts as SupplierEditContractsComponent } from '../components/SupplierEditContracts';

export interface SupplierEditContractsStateProps {
    userId: number;
    filter?: PaginationFilters;
    contractId: number;
    benefits: Benefit[];
    isFiltered: boolean;
    pageNumber?: number;
}

interface SupplierEditContractsContainerDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    toggleMultipleEmployeesRoleRequest: typeof toggleMultipleEmployeesRoleRequest;
    redirectToUrl: typeof redirectToUrl;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
}

export type SupplierEditContractsContainerProps = SupplierEditContractsStateProps &
    SupplierEditContractsContainerDispatchProps;

class SupplierEditContracts extends React.Component<SupplierEditContractsContainerProps> {
    public render(): JSX.Element {
        return <SupplierEditContractsComponent {...this.props} />;
    }
}

const mapStateToProps = (
    state: AppState,
    ownProps: RouteParams,
): SupplierEditContractsStateProps => ({
    userId: getUserId(state),
    filter: getFilterParams(state, PaginationView.SupplierEditContract),
    contractId: getSelectedBenefitContractId(state),
    benefits: getBenefits(state),
    isFiltered: getFilteringBenefitsStatus(state),
    pageNumber: getPageNumber(ownProps),
});

export const SupplierEditContractsContainer = connect(mapStateToProps, {
    autocompleteRequest,
    toggleMultipleEmployeesRoleRequest,
    redirectToUrl,
    clearFilters,
    saveFilters,
    paginationRequest,
})(SupplierEditContracts);
