import { useState } from 'react';
import styled from 'styled-components';
import { Button, Form } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';

import {
    QuestionnaireQuestionType,
    benefitQuestionnaireQuestionTypeNames,
    BenefitQuestionnaireType,
} from 'features/benefit/models/questionnaireModels';
import { borders, colors } from 'theme/variables';
import { Input, Select, TextArea, Checkbox } from 'common/components';

import { AnswerChoice } from './AnswerChoice';

const QuestionnaireQuestionWrapper = styled.div`
    width: 100%;
    display: flex;
    border-radius: 4px;
    margin-bottom: 1rem;
    position: relative;
`;

const OrderButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem 1rem 0;
    margin-left: 0.5rem;
    border: ${borders.border};
`;

const ArrowButton = styled(Button)`
    flex: 1;
    padding: 0;
    width: 32px;
`;

const OrderInfo = styled.span`
    padding: 1rem 0;
    font-weight: bold;
    text-align: center;
`;

const AnswersWrapper = styled(Form.Item)`
    padding: 1rem;
    border: 1px dashed ${colors.borderGray};
`;

const RemoveButton = styled(Button)`
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
`;

const typeOptions = Object.values(QuestionnaireQuestionType).map((type) => ({
    label: benefitQuestionnaireQuestionTypeNames[type],
    value: type,
}));

type Props = {
    questionnaireType: BenefitQuestionnaireType;
    name: number;
    disabled?: boolean;
    onUpdateIndex: (name: number, offset: -1 | 1) => void;
    onRemove: (name: number) => void;
};

export const Question = ({ questionnaireType, name, disabled, onUpdateIndex, onRemove }: Props) => {
    const form = Form.useFormInstance();
    const questions = Form.useWatch([questionnaireType], form);
    const index = Form.useWatch([questionnaireType, name, 'index'], form) || name;

    const [isQuestionChoiceBased, setIsQuestionChoiceBased] = useState(
        [QuestionnaireQuestionType.CHOICE, QuestionnaireQuestionType.MULTIPLE_CHOICE].includes(
            form.getFieldValue([questionnaireType, name, 'type']),
        ),
    );

    const handleTypeChange = (value: QuestionnaireQuestionType) => {
        setIsQuestionChoiceBased(
            [QuestionnaireQuestionType.CHOICE, QuestionnaireQuestionType.MULTIPLE_CHOICE].includes(
                value,
            ),
        );
    };

    return (
        <QuestionnaireQuestionWrapper>
            <OrderButtonsWrapper>
                <ArrowButton
                    onClick={() => onUpdateIndex(name, -1)}
                    disabled={disabled || index === 0}
                >
                    <ArrowUpOutlined />
                </ArrowButton>
                <OrderInfo>{index + 1}</OrderInfo>
                <ArrowButton
                    onClick={() => onUpdateIndex(name, 1)}
                    disabled={disabled || index === questions?.length - 1}
                >
                    <ArrowDownOutlined />
                </ArrowButton>
            </OrderButtonsWrapper>
            <Content>
                <Select
                    name={[name, 'type']}
                    label="Question type"
                    options={typeOptions}
                    onChange={handleTypeChange}
                    initialValue={QuestionnaireQuestionType.TEXT}
                    disabled={disabled}
                />
                <Input name={[name, 'label']} label="Label" disabled={disabled} />
                <TextArea name={[name, 'body']} label="Question" disabled={disabled} />
                {isQuestionChoiceBased && (
                    <AnswersWrapper label="Possible choices:">
                        <Form.List name={[name, 'choices']}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field) => (
                                        <AnswerChoice
                                            key={field.key}
                                            name={field.name}
                                            disabled={disabled}
                                            onRemove={remove}
                                        />
                                    ))}
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                        disabled={disabled}
                                    >
                                        Add possible choice
                                    </Button>
                                </>
                            )}
                        </Form.List>
                    </AnswersWrapper>
                )}
                <Checkbox
                    name={[name, 'isOptional']}
                    label="Optional"
                    disabled={disabled}
                    initialValue={false}
                >
                    Optional
                </Checkbox>
            </Content>
            <Input name={[name, 'index']} disabled hidden required={false} initialValue={name} />
            <Input name={[name, 'id']} disabled hidden required={false} />
            <RemoveButton
                onClick={() => onRemove(name)}
                shape="circle"
                icon={<CloseOutlined />}
                disabled={disabled}
            />
        </QuestionnaireQuestionWrapper>
    );
};
