import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { isTenant } from 'features/user/selectors/userSelectors';

import { UploadEvidence as UploadEvidenceComponent } from '../components/UploadEvidence';
import { getReadyForEvidenceBenefits } from '../selectors/uploadSelectors';
import { UploadBenefit } from '../models/uploadModels';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';

interface UploadEvidenceStateProps {
    readyForEvidenceBenefits: UploadBenefit[];
    filter?: PaginationFilters;
    isTenant: boolean;
}

interface UploadEvidenceDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    redirectToUrl: typeof redirectToUrl;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
}

export type UploadEvidenceContainerProps = UploadEvidenceStateProps & UploadEvidenceDispatchProps;

class UploadEvidence extends React.Component<UploadEvidenceContainerProps> {
    public render(): JSX.Element {
        return <UploadEvidenceComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState): UploadEvidenceStateProps => ({
    readyForEvidenceBenefits: getReadyForEvidenceBenefits(state),
    filter: getFilterParams(state, PaginationView.UploadEvidence),
    isTenant: isTenant(state),
});

export const UploadEvidenceContainer = connect(mapStateToProps, {
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
})(UploadEvidence);
