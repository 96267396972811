import { isPhoneNumberValid } from 'common/helpers/phoneValidator';

import { Antd3Form, Antd3Input, Antd3InputProps } from '../antd3';

const checkPhone = (_: any, value: string, callback: (message?: string) => void) =>
    isPhoneNumberValid(value) ? callback() : callback('Valid telephone number in required');

interface Props extends Antd3InputProps {
    initialValue?: string;
}

export const PhoneInput = ({
    form,
    id,
    placeholder,
    label,
    initialValue,
    required = true,
}: Props) => (
    <Antd3Form.Item label={label || placeholder}>
        <Antd3Input
            form={form}
            id={id}
            getFieldDecoratorOptions={{
                initialValue,
                rules: [{ validator: required ? checkPhone : undefined, required }],
            }}
            size="large"
            placeholder={placeholder}
        />
    </Antd3Form.Item>
);
