import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';
import { isAdmin } from 'features/user/selectors/userSelectors';
import { RouteParams, getPageNumber } from 'common/selectors/routeSelectors';

import { PortfolioFilters as PortfolioFiltersComponent } from '../components/PortfolioFilters';
import {
    getPortfolioFiltersRequest,
    createPortfolioFilterRequest,
    editPortfolioFilterRequest,
    getPortfolioFiltersRootRequest,
    deletePortfolioFilterRequest,
} from '../actions/portfolioFilterAction';
import { PortfolioFilter, PortfolioFiltersEnum } from '../models/portfolioFilterModel';
import {
    getMainProjects,
    getSubPrograms,
    getSubProjects,
    getRootPortfolioFilterId,
} from '../selectors/portfolioFilterSelectors';

interface PortfolioFiltersTableDispatchProps {
    location: { state: { from: string } };
    getPortfolioFiltersRootRequest: typeof getPortfolioFiltersRootRequest;
    getPortfolioFiltersRequest: typeof getPortfolioFiltersRequest;
    createPortfolioFilterRequest: typeof createPortfolioFilterRequest;
    editPortfolioFilterRequest: typeof editPortfolioFilterRequest;
    deletePortfolioFilterRequest: typeof deletePortfolioFilterRequest;
    redirectToUrl: typeof redirectToUrl;
}

type PortfolioFiltersLists = { [key in PortfolioFiltersEnum]: PortfolioFilter[] };

interface PortfolioFiltersTableStateProps extends PortfolioFiltersLists {
    isUserAdmin: boolean;
    portfolioFilterRootId: number;
    pageNumber?: number;
}

export type PortfolioFiltersContainerProps = PortfolioFiltersTableDispatchProps &
    PortfolioFiltersTableStateProps &
    RouteParams;

class PortfolioFiltersTable extends React.Component<PortfolioFiltersContainerProps> {
    public componentDidMount(): void {
        this.props.getPortfolioFiltersRootRequest();
    }

    public render(): JSX.Element {
        return <PortfolioFiltersComponent {...this.props} />;
    }
}

const mapStateToProps = (
    state: AppState,
    ownProps: RouteParams,
): PortfolioFiltersTableStateProps => ({
    subPrograms: getSubPrograms(state),
    mainProjects: getMainProjects(state),
    subProjects: getSubProjects(state),
    isUserAdmin: isAdmin(state),
    portfolioFilterRootId: getRootPortfolioFilterId(state),
    pageNumber: getPageNumber(ownProps),
});

export const PortfolioFiltersTableContainer = connect(mapStateToProps, {
    getPortfolioFiltersRootRequest,
    getPortfolioFiltersRequest,
    createPortfolioFilterRequest,
    editPortfolioFilterRequest,
    deletePortfolioFilterRequest,
    redirectToUrl,
})(PortfolioFiltersTable);
