import { BenefitsFilterContainer } from 'common/containers/BenefitsFilterContainer';
import { CustomRouteProps } from 'common/models/navigationModel';

import { DownloadBenefitsContainer } from '../containers/DownloadBenefitsContainer';
import { DownloadContractsContainer } from '../containers/DownloadContractsContainer';
import { DownloadContractsFilterContainer } from '../containers/DownloadContractsFilterContainer';
import { DownloadPaths } from '../models/downloadModel';

const menuLinks = [
    { key: DownloadPaths.Contracts, label: 'Projects' },
    { key: DownloadPaths.Benefits, label: 'Benefits' },
];

export const downloadRoutes: CustomRouteProps[] = [
    {
        path: `${DownloadPaths.Benefits}`,
        component: DownloadBenefitsContainer,
        exact: true,
    },
    {
        path: `${DownloadPaths.BenefitsFilter}/:filterId`,
        component: BenefitsFilterContainer,
    },
    {
        path: DownloadPaths.Contracts,
        component: DownloadContractsContainer,
        exact: true,
    },
    {
        path: `${DownloadPaths.ContractsFilter}/:filterId`,
        component: DownloadContractsFilterContainer,
    },
].map((route) => ({
    ...route,
    menuLinks,
}));
