import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { Benefit } from 'features/benefit/models/benefitModels';
import { getBenefitsSuccess } from 'features/benefit/actions';
import {
    getBenefits,
    getFilteringBenefitsStatus,
} from 'features/benefit/selectors/benefitSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import { getFetchingDownloadStatus } from 'features/download/selectors/downloadSelectors';
import { isTenant } from 'features/user/selectors/userSelectors';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';

import { DownloadBenefits as DownloadBenefitsComponent } from '../components/DownloadBenefits';
import { getBenefitsCSVRequest } from '../actions/downloadActions';

interface DownloadBenefitsStateProps {
    benefits: Benefit[];
    isFetchingCSV: boolean;
    isFiltered: boolean;
    tenant: boolean;
    filter?: PaginationFilters;
}

interface DownloadBenefitsContainerDispatchProps {
    getBenefitsCSVRequest: typeof getBenefitsCSVRequest;
    getBenefitsSuccess: typeof getBenefitsSuccess;
    redirectToUrl: typeof redirectToUrl;
    autocompleteRequest: typeof autocompleteRequest;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
}

export type DownloadBenefitsContainerProps = DownloadBenefitsStateProps &
    DownloadBenefitsContainerDispatchProps;

class DownloadBenefits extends React.Component<DownloadBenefitsContainerProps> {
    public render(): JSX.Element {
        return <DownloadBenefitsComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState): DownloadBenefitsStateProps => ({
    isFetchingCSV: getFetchingDownloadStatus(state),
    isFiltered: getFilteringBenefitsStatus(state),
    benefits: getBenefits(state),
    tenant: isTenant(state),
    filter: getFilterParams(state, PaginationView.DownloadBenefits),
});

export const DownloadBenefitsContainer = connect(mapStateToProps, {
    getBenefitsCSVRequest,
    getBenefitsSuccess,
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
})(DownloadBenefits);
