import * as React from 'react';

import { notificationText, showNotification } from 'common/helpers/notifications';
import { SupplierInviteForm } from 'features/edit/components/SupplierInviteForm';
import { Antd3Form, Antd3FormProps, StyledAntd3Form } from 'common/components/deprecated/antd3';

import { InviteByEmail } from '../models/inviteModel';
import { SupplierInviteUsersContainerProps } from '../containers/SupplierInviteUsersContainer';
import { PendingInvitationsList } from './PendingInvitationsList';

export interface SupplierInviteUsersProps {
    inviteEmployeeRequest(employee: InviteByEmail): void;
}

type SupplierEditUsersProps = Antd3FormProps &
    SupplierInviteUsersContainerProps &
    SupplierInviteUsersProps;

class SupplierInviteUsersComponent extends React.Component<SupplierEditUsersProps> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { email, ...values }) => {
            error
                ? showNotification({ text: notificationText.Error })
                : this.props.inviteEmployeeRequest({
                      email: email.toLowerCase(),
                      isAdministrator: false,
                      ...values,
                  });
        });
    };

    public render(): JSX.Element {
        return (
            <>
                <StyledAntd3Form onSubmit={this.handleSubmit} header="Invite Employees" bigHeader>
                    <SupplierInviteForm showInviteButton {...this.props} />
                </StyledAntd3Form>
                <PendingInvitationsList {...this.props} />
            </>
        );
    }
}

export const SupplierInviteUsers = Antd3Form.create({})(SupplierInviteUsersComponent);
