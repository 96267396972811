import * as React from 'react';

import { useState } from 'react';

import { FilterType } from 'features/contract/models/contractModels';
import { SmallTabs } from 'common/components/styled';
import { SubmitButton } from 'common/components';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { filterTooltips } from 'common/tooltips/filterTooltips';
import { StyledAntd3Form, Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input } from 'common/components/deprecated/form';

import { Props as ContainerProps } from '../containers/CreateFilterContainer';

const filterTypesNames = new Map([
    [FilterType.CONTRACT_CATEGORIES, 'Project Category'],
    [FilterType.CONTRACT_PRIORITIES, 'Project Priorities'],
    [FilterType.BENEFIT_CATEGORIES, 'Benefit Category'],
    [FilterType.BENEFIT_PRIORITIES, 'Benefit Priorities'],
    [FilterType.DEPARTMENTS, 'Department'],
    [FilterType.LOCATIONS, 'Location'],
]);

type Props = ContainerProps & Antd3FormProps;

class CreateFilterContainer extends React.Component<Props> {
    private handleSubmit(event: React.FormEvent, filterType: FilterType): void {
        event.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (error && (values[filterType] === '' || !values[filterType])) {
                return showNotification({ text: notificationText.Error });
            }

            return this.props.createFilterRequest({ name: values[filterType], filterType });
        });
    }

    public render(): JSX.Element {
        return (
            <SmallTabs>
                {Array.from(filterTypesNames).map(([filterTypeName, value]) => (
                    <SmallTabs.TabPane key={value} tab={value}>
                        <StyledAntd3Form
                            onSubmit={(e: React.FormEvent) => this.handleSubmit(e, filterTypeName)}
                            header={`Create ${value}`}
                            bigHeader
                        >
                            <Input
                                form={this.props.form}
                                id={filterTypeName}
                                placeholder={`${value} Name`}
                                tooltip={filterTooltips[filterTypeName]}
                            />
                            <SubmitButton value={`Create ${value}`} />
                        </StyledAntd3Form>
                    </SmallTabs.TabPane>
                ))}
            </SmallTabs>
        );
    }
}

export const CreateFilter = Antd3Form.create({})(CreateFilterContainer);
