import styled from 'styled-components';
import { Tooltip } from 'antd';

import { Antd3Icon } from '../../deprecated/antd3';

const Label = styled.span`
    margin-right: 4px;
`;

type Props = {
    label?: string;
    tooltip?: string;
};

export const InputLabelWithTooltip = ({ label, tooltip }: Props) => (
    <span>
        <Label>{label}</Label>
        <Tooltip title={tooltip}>
            <Antd3Icon type="info-circle-o" />
        </Tooltip>
    </span>
);
