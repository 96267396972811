import { Input, InputProps as AntdInputProps } from 'antd';

import { Antd3FormElementProps } from './Antd3Form';

export const Antd3Search = ({
    form,
    id,
    required = false,
    getFieldDecoratorOptions,
    ...inputProps
}: AntdInputProps & Antd3FormElementProps) => (
    <>
        {form?.getFieldDecorator ? (
            form.getFieldDecorator(id, getFieldDecoratorOptions)(<Input.Search {...inputProps} />)
        ) : (
            <Input.Search {...inputProps} />
        )}
    </>
);
