import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { CreateAccount } from 'features/user/components/CreateAccount';
import { FullPageSpinner } from 'common/components';
import { createAccountRequest } from 'features/user/actions/userActions';
import { getFetchingUserStatus } from 'features/user/selectors/userSelectors';
import { getUrlHashCode } from 'common/helpers/url';

import {
    getUserVerificationStatus,
    checkIfUserExists,
    getAuthenticatedStatus,
} from '../selectors/authSelectors';
import {
    verifyNewEmployeeRequest,
    activateAccountRequest,
    refreshTokenRequest,
} from '../actions/authActions';

interface VerifyNewAccountContainerStateProps {
    isUserVerified: boolean;
    isFetching: boolean;
    userExists?: boolean;
    isAuthenticated?: boolean;
}

interface VerifyNewAccountContainerDispatchProps {
    activateAccountRequest: typeof activateAccountRequest;
    createAccountRequest: typeof createAccountRequest;
    verifyNewEmployeeRequest: typeof verifyNewEmployeeRequest;
    refreshTokenRequest: typeof refreshTokenRequest;
}

export type VerifyNewAccountContainerProps = VerifyNewAccountContainerStateProps &
    VerifyNewAccountContainerDispatchProps;
class VerifyNewAccount extends React.Component<VerifyNewAccountContainerProps> {
    public componentDidMount(): void {
        this.props.verifyNewEmployeeRequest(getUrlHashCode());
    }

    public componentWillUnmount(): void {
        this.props.refreshTokenRequest();
    }

    public render(): JSX.Element {
        return this.props.isUserVerified ? (
            <CreateAccount {...this.props} activateRequest={this.props.activateAccountRequest} />
        ) : (
            <FullPageSpinner />
        );
    }
}

const mapStateToProps = createSelector(
    getUserVerificationStatus,
    getFetchingUserStatus,
    checkIfUserExists,
    getAuthenticatedStatus,
    (
        isUserVerified,
        isFetching,
        userExists,
        isAuthenticated,
    ): VerifyNewAccountContainerStateProps => ({
        isUserVerified,
        isFetching,
        userExists,
        isAuthenticated,
    }),
);

export const VerifyNewAccountContainer = connect(mapStateToProps, {
    refreshTokenRequest,
    activateAccountRequest,
    createAccountRequest,
    verifyNewEmployeeRequest,
})(VerifyNewAccount);
