import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, RouteProps } from 'react-router';

import { redirectToUrl } from 'common/actions/navigationAction';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { isTenant } from 'features/user/selectors/userSelectors';
import { AppState } from 'common/appState';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';

import { ApprovedScores as ApprovedScoresComponent } from '../components/ApprovedScores';
import { ApprovedEvidences as ApprovedEvidencesComponent } from '../components/ApprovedEvidences';
import { CheckBenefit, CheckPaths } from '../models/checkModel';
import { getCheckScores, getCheckEvidences } from '../selectors/checkSelectors';

export interface ApprovedStateProps {
    approvedScores: CheckBenefit[];
    approvedEvidences: CheckBenefit[];
    path: string;
    filter?: PaginationFilters;
    isTenant: boolean;
}

interface ApprovedDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    redirectToUrl: typeof redirectToUrl;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
}

export type ApprovedProps = ApprovedStateProps & ApprovedDispatchProps & RouteComponentProps<{}>;

class Approved extends React.Component<ApprovedProps> {
    public render(): JSX.Element {
        return this.props.path === CheckPaths.ApprovedScores ? (
            <ApprovedScoresComponent {...this.props} />
        ) : (
            <ApprovedEvidencesComponent {...this.props} />
        );
    }
}

const mapStateToProps = (
    state: AppState,
    { match: { path } }: RouteComponentProps<{}>,
): ApprovedStateProps & RouteProps => ({
    approvedScores: getCheckScores(state),
    approvedEvidences: getCheckEvidences(state),
    path,
    filter: getFilterParams(
        state,
        path === CheckPaths.ApprovedScores
            ? PaginationView.ApprovedCheckScores
            : PaginationView.ApprovedCheckEvidence,
    ),
    isTenant: isTenant(state),
});

export const ApprovedContainer = connect(mapStateToProps, {
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
})(Approved);
