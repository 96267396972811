import { NODE_ENV } from 'config/env';

export const getEnvValue = (key: string) => {
    const variable = process.env[key];
    const found = variable !== undefined;
    if (!found) {
        console.error(`Error reading variable ${key} `, variable);
    }
    return found ? variable.toString() : '';
};
