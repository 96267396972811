import { Upload as AntdUpload, UploadProps, FormItemProps, Form, Button, Tooltip } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Antd3Icon } from '../deprecated/antd3';
import { generateFilesListFromSingleFile } from './helpers';
import { FormInputProps } from './models';

const StyledUpload = styled(AntdUpload)`
    .ant-upload {
        display: flex;
        width: 100%;
        align-items: center;
    }
`;

const StyledButton = styled(Button)`
    flex: 1;
    margin-right: 1rem;
`;

type Props = FormItemProps &
    UploadProps &
    FormInputProps & {
        name: string;
        file?: UploadFile;
        files?: UploadFile[];
        allowClear?: boolean;
        label: string;
        onUpload: (name: string, fileList: UploadFile[]) => void;
    };

export const Upload = ({
    name,
    multiple,
    file,
    files,
    disabled = false,
    tooltip,
    label,
    onUpload,
}: Props) => {
    const [fileList, setFileList] = useState<UploadFile[]>(
        (multiple ? files : generateFilesListFromSingleFile(file)) || [],
    );

    useEffect(() => {
        if (onUpload) {
            onUpload(name, fileList);
        }
    }, [fileList]);

    const handleBeforeUpload = (_file: UploadFile, FileList: UploadFile[]) => setFileList(FileList);

    const handleOnRemove = (file: UploadFile) =>
        setFileList(fileList.filter((fileListItem) => fileListItem.uid !== file.uid));

    return (
        <Form.Item>
            <StyledUpload
                fileList={fileList}
                customRequest={(options) => options.onSuccess && options.onSuccess('ok')}
                beforeUpload={handleBeforeUpload}
                onRemove={handleOnRemove}
            >
                <StyledButton size="large" disabled={disabled} loading={disabled} role="button">
                    <Antd3Icon type="upload" />
                    {label}
                </StyledButton>
                <Tooltip title={tooltip}>
                    <Antd3Icon type="info-circle-o" />
                </Tooltip>
            </StyledUpload>
        </Form.Item>
    );
};
