import React from 'react';
import { Alert } from 'antd';
import styled from 'styled-components';

import { approvalStatus } from 'common/helpers/utils';

const ApprovalStatusWrapper = styled(Alert)<{ bottommargin: number }>(
    ({ bottommargin }) => `
    margin-top: 0.5rem;
    margin-bottom: ${bottommargin ? '1rem' : 0};

    .ant-alert-description {
        white-space: break-spaces;
    }
`,
);

export interface ApprovalStatusProps {
    approved?: boolean;
    declined?: boolean;
    approvedAt?: string;
    approvedBy?: string;
    declinedBy?: string;
    declineMessage?: string;
    bottomMargin?: boolean;
}

export const ApprovalStatus: React.FunctionComponent<ApprovalStatusProps> = ({
    approved,
    declined,
    approvedAt,
    approvedBy,
    declinedBy,
    declineMessage,
    bottomMargin,
}) =>
    approved || declined ? (
        <ApprovalStatusWrapper
            message={approvalStatus({
                approved,
                approvedAt,
                approvedBy,
                declinedBy,
            })}
            description={declineMessage}
            type={approved ? 'success' : 'error'}
            bottommargin={bottomMargin ? 1 : 0}
        />
    ) : null;
