import * as React from 'react';
import { Button, Upload, Tooltip } from 'antd';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';
import styled from 'styled-components';

import { sizes } from 'theme/variables';

import { UploadedFilesList } from './UploadedFilesList';
import { Antd3Form, Antd3Icon, Antd3FormProps } from '../deprecated/antd3';

interface UploadTemplateFileProps extends Antd3FormProps {
    id: string;
    description: string;
    required?: boolean;
    disabled?: boolean;
    accept?: string;
    uploadedFiles: string[];
    onChange?(): void;
    addFile: (id: string, file: IUploadFile) => void;
    removeFile: (id: string) => void;
    tooltip?: string;
}

const StyledUpload = styled(Upload)`
    button.ant-btn,
    .ant-upload {
        width: 100%;
        max-width: ${sizes.formWidth};
    }
`;

export class UploadTemplateFile extends React.Component<UploadTemplateFileProps> {
    public render(): JSX.Element {
        const props = {
            onChange: () => this.props.onChange && this.props.onChange(),
            beforeUpload: (file: IUploadFile) => {
                this.props.addFile(this.props.id, file);
                return false;
            },
            fileList: [],
            disabled: this.props.disabled,
            accept: this.props.accept,
            multiple: false,
        };

        const { disabled, tooltip } = this.props;

        return (
            <Antd3Form.Item>
                <StyledUpload {...props}>
                    <Button size="large" disabled={disabled} loading={disabled}>
                        <Antd3Icon type="upload" />
                        {this.props.description}
                    </Button>
                    <Tooltip title={tooltip}>
                        <Antd3Icon type="info-circle-o" />
                    </Tooltip>
                </StyledUpload>
                <UploadedFilesList
                    items={
                        this.props.uploadedFiles &&
                        this.props.uploadedFiles.map((item) => ({
                            name: item.split('/').pop() || item,
                            id: item,
                        }))
                    }
                    deleteItem={(_: string) => this.props.removeFile(this.props.id)}
                    noData={'No File Uploaded'}
                />
            </Antd3Form.Item>
        );
    }
}
