import { List, Tooltip, Button } from 'antd';

import { IdValueNameObject } from 'common/models/defaultsModel';

import { Antd3Icon } from '../deprecated/antd3';

interface Props {
    items?: IdValueNameObject[];
    deleteItem(id: number | string): void;
    noData?: string;
}

export const RolesList = ({ items, deleteItem, noData }: Props) => (
    <List
        dataSource={items}
        renderItem={(item: IdValueNameObject) => (
            <List.Item
                actions={[
                    deleteItem && (
                        <Tooltip placement="left" title={`Unassign ${item.name}`}>
                            <Button
                                size="small"
                                type="danger"
                                ghost
                                onClick={() => item.id && deleteItem(item.id)}
                            >
                                <Antd3Icon type="delete" />
                                Remove
                            </Button>
                        </Tooltip>
                    ),
                ]}
            >
                <List.Item.Meta title={item.name} />
            </List.Item>
        )}
        locale={{ emptyText: noData || 'No values added' }}
    />
);
