import * as React from 'react';
import { Button, Card, Divider, Space } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { DateFormat } from 'config/variables';
import { ApprovalStatus } from 'features/upload/components/ApprovalStatus';
import { Decline } from 'features/upload/components/Decline';
import { QuestionnaireAnswers } from 'features/benefit/components/Questionnaire/QuestionnaireAnswers';
import { Evidence, EvidenceApproval } from 'features/upload/models/uploadModels';
import { Antd3Icon } from 'common/components/deprecated/antd3';
import { PaddedSection } from 'common/components';
import { colors } from 'theme/variables';

import { BenefitEvidenceProps } from '../containers/BenefitEvidenceContainer';
import { FileList } from './FileList';
import { BenefitDetails } from './BenefitDetails';
import { BenefitCardHeader } from './BenefitCardHeader';
import { GdprStatus } from './GdprStatus';
import { BenefitChangeSuggestionsAcceptanceForms } from './BenefitChangeSuggestionsAcceptanceForms/BenefitChangeSuggestionsAcceptanceForms';
import { openModal } from 'common/helpers/modal';
import { benefitStatuses } from 'features/benefit/models/benefitModels';
import { titleCase } from 'common/helpers/titleCase';

const EvidenceCard = styled(Card)`
    border-color: ${colors.borderGray};

    & + & {
        margin-top: 1.5rem;
    }

    .ant-card-actions > li {
        text-align: left;
    }
`;

const ButtonsWrapper = styled(Space)`
    width: 100%;
    justify-content: flex-end;
    padding: 0 1.5rem;
`;

const openBenefitStatusModal = (
    e: React.FormEvent,
    benefitId: number,
    contractId: number,
    deliveryDateId: number,
    evidenceId: number,
    organisationId: number,
    status: string,
    onApprove: (evidence: EvidenceApproval) => void,
) => {
    e.preventDefault();

    return openModal({
        okText: 'Approve Evidence',
        cancelText: 'Return to change status',
        callback: () =>
            onApprove({ benefitId, contractId, deliveryDateId, evidenceId, organisationId }),
        title: `The Benefit Status is set to ${titleCase(
            status,
        )}. Would you like to go back and update the status before proceeding with Evidence approval?`,
    });
};

export const BenefitEvidence: React.FunctionComponent<BenefitEvidenceProps> = ({
    approveEvidenceRequest,
    benefit,
    priorities,
    contract,
    declineEvidenceRequest,
    evidences,
    isChangingApprovalStatus,
    benefitAssessors,
    benefitSuppliers,
    benefitPartners,
}) => {
    if (!benefit || !contract) {
        return null;
    }

    return (
        <>
            <PaddedSection grayBg bottomBorder>
                <BenefitDetails
                    benefit={benefit}
                    contract={contract}
                    benefitAssessors={benefitAssessors}
                    benefitSuppliers={benefitSuppliers}
                    benefitPartners={benefitPartners}
                    isEditable
                />
            </PaddedSection>
            <PaddedSection>
                {benefit.deliveryDates.map(({ date, id, title }) => {
                    const deliveryDateEvidences: Evidence[] = evidences.filter(
                        ({ deliveryDate }) => deliveryDate === id,
                    );

                    return deliveryDateEvidences.map(
                        ({
                            approved,
                            declined,
                            approvedAt,
                            approvedBy,
                            declinedBy,
                            declineMessage,
                            files,
                            ...evidence
                        }) => {
                            const createdAt = `${moment(evidence.createdAt).format(
                                DateFormat.Exact,
                            )}`;

                            return (
                                <EvidenceCard
                                    key={evidence.id}
                                    title={
                                        <BenefitCardHeader
                                            date={date}
                                            dateTitle={title}
                                            author={evidence.author}
                                            createdAt={createdAt}
                                        >
                                            <ApprovalStatus
                                                approved={approved}
                                                declined={declined}
                                                approvedAt={approvedAt}
                                                approvedBy={approvedBy}
                                                declinedBy={declinedBy}
                                                declineMessage={declineMessage}
                                            />
                                        </BenefitCardHeader>
                                    }
                                    actions={[
                                        <>
                                            <ButtonsWrapper size="small" align="center">
                                                <GdprStatus agreedToGdpr={evidence.agreedToGdpr} />
                                                <Button
                                                    type="primary"
                                                    onClick={(e: React.FormEvent) => {
                                                        if (!benefit.status.includes('DELIVERED')) {
                                                            openBenefitStatusModal(
                                                                e,
                                                                benefit.id,
                                                                contract.id,
                                                                id,
                                                                evidence.id,
                                                                benefit.organisation,
                                                                benefit.status,
                                                                approveEvidenceRequest,
                                                            );
                                                        } else {
                                                            approveEvidenceRequest({
                                                                benefitId: benefit.id,
                                                                contractId: contract.id,
                                                                deliveryDateId: id,
                                                                evidenceId: evidence.id,
                                                                organisationId:
                                                                    benefit.organisation,
                                                            });
                                                        }
                                                    }}
                                                    loading={isChangingApprovalStatus}
                                                    disabled={isChangingApprovalStatus || approved}
                                                >
                                                    Approve Evidence
                                                    {approved && <Antd3Icon type="check" />}
                                                </Button>
                                            </ButtonsWrapper>
                                            <Divider />
                                            <Decline
                                                data={{
                                                    organisationId: benefit.organisation,
                                                    contractId: contract.id,
                                                    benefitId: benefit.id,
                                                    deliveryDateId: id,
                                                    evidenceId: evidence.id,
                                                }}
                                                dataType="Evidence"
                                                onDecline={declineEvidenceRequest}
                                                loading={isChangingApprovalStatus}
                                            />
                                        </>,
                                    ]}
                                >
                                    <QuestionnaireAnswers
                                        questions={evidence.evidenceQuestionnaire}
                                        answers={evidence.questionnaireAnswer}
                                    />
                                    {files?.length ? (
                                        <>
                                            <Divider />
                                            <FileList files={files} />
                                        </>
                                    ) : null}
                                    {evidence.benefitChangeSuggestions && (
                                        <BenefitChangeSuggestionsAcceptanceForms
                                            contractId={benefit.contract}
                                            benefitId={benefit.id}
                                            sharedBenefitForUrl={benefit.sharedBenefit}
                                            benefitDeliveryDates={benefit.deliveryDates}
                                            benefitPriorities={priorities}
                                            benefitLocation={benefit.location}
                                            benefitValue={benefit.value}
                                            benefitChangeSuggestions={
                                                evidence.benefitChangeSuggestions
                                            }
                                        />
                                    )}
                                </EvidenceCard>
                            );
                        },
                    );
                })}
            </PaddedSection>
        </>
    );
};
