import { RouteComponentProps } from 'react-router-dom';

export interface EditBenefitParams {
    contractId: string;
}
export type EditBenefitRouteParams = RouteComponentProps<EditBenefitParams>;

export enum CreatePaths {
    Contract = '/create/project',
    Benefit = '/create/benefit',
    Evidence = '/create/evidence',
    Score = '/create/score',
    BenefitTemplate = '/create/benefit-template',
    Filter = '/create/filter',
}

export enum CreateBenefitTitles {
    CreateTemplatedBenefit = 'Create Templated Benefit',
    CreateSpecificBenefit = 'Create Unique Benefit',
}
