import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { createAccountRequest } from 'features/user/actions/userActions';
import { getFetchingUserStatus } from 'features/user/selectors/userSelectors';
import { FullPageSpinner } from 'common/components';
import { CreateAccount } from 'features/user/components/CreateAccount';
import { getUrlHashCode } from 'common/helpers/url';

import { getUserVerificationStatus } from '../selectors/authSelectors';
import {
    verifyNewExecutiveRequest,
    activateExecutiveAdminAccountRequest,
} from '../actions/authActions';

interface VerifyNewExecutiveContainerStateProps {
    isUserVerified: boolean;
    isFetching: boolean;
}

interface VerifyNewExecutiveContainerDispatchProps {
    activateExecutiveAdminAccountRequest: typeof activateExecutiveAdminAccountRequest;
    createAccountRequest: typeof createAccountRequest;
    verifyNewExecutiveRequest: typeof verifyNewExecutiveRequest;
}

export type VerifyNewExecutiveContainerProps = VerifyNewExecutiveContainerStateProps &
    VerifyNewExecutiveContainerDispatchProps;

class VerifyNewExecutive extends React.Component<VerifyNewExecutiveContainerProps> {
    public componentDidMount(): void {
        this.props.verifyNewExecutiveRequest(getUrlHashCode());
    }

    public render(): JSX.Element {
        return this.props.isUserVerified ? (
            <CreateAccount
                {...this.props}
                activateRequest={this.props.activateExecutiveAdminAccountRequest}
            />
        ) : (
            <FullPageSpinner />
        );
    }
}

const mapStateToProps = createSelector(
    getUserVerificationStatus,
    getFetchingUserStatus,
    (isUserVerified, isFetching): VerifyNewExecutiveContainerStateProps => ({
        isUserVerified,
        isFetching,
    }),
);

export const VerifyNewExecutiveContainer = connect(mapStateToProps, {
    activateExecutiveAdminAccountRequest,
    createAccountRequest,
    verifyNewExecutiveRequest,
})(VerifyNewExecutive);
