import { Button, List } from 'antd';
import { useSelector } from 'react-redux';
import { PaddedSection } from 'common/components';
import styled from 'styled-components';
import { colors, fontWeights } from 'theme/variables';
import { Props } from '../containers/SubscriptionContainer';
import { getUserActiveOrganisation } from '../selectors/userSelectors';
import { ButtonWrapper } from 'common/components/styled';
import { Antd3Icon } from 'common/components/deprecated/antd3';

export const SubscriptionUserInfo = ({ userSubscription }: Props) => {
    const { itemTitle, type, status, paymentPeriods, createdAt, activatedAt, expiresAt } =
        userSubscription;

    const activeOrganisation = useSelector(getUserActiveOrganisation);

    return (
        <PaddedSection header="Subscription">
            <List>
                <List.Item>Type: {itemTitle}</List.Item>
                <List.Item>Payment Type: {type}</List.Item>
                <List.Item>Status: {status}</List.Item>
                <List.Item>Number of Payment Periods: {paymentPeriods}</List.Item>
                <List.Item>Created at: {createdAt?.slice(0, 10)}</List.Item>
                {status === 'ACTIVE' && (
                    <>
                        <List.Item>Started at: {activatedAt?.slice(0, 10)}</List.Item>
                        <List.Item>Expires at: {expiresAt?.slice(0, 10)}</List.Item>
                        <List.Item>
                            Number of active users:
                            {` ${activeOrganisation?.activeUsersCount}/${activeOrganisation?.maxAvailableNumberOfUsers}`}
                        </List.Item>
                    </>
                )}
            </List>
            <ButtonWrapper>
                <Button type="text">
                    Cancel Subscription
                    <Antd3Icon type="cross" />
                </Button>
                <Button type="primary">
                    Renew Subscription
                    <Antd3Icon type="dollar" />
                </Button>
            </ButtonWrapper>
        </PaddedSection>
    );
};
