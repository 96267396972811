import { createGlobalStyle } from 'styled-components';

import { colors } from 'theme/variables';

export const Antd3GlobalStyle = createGlobalStyle`
    .ant-table-tbody {
        > tr:hover:not(.ant-table-expanded-row) {
            > td {
                background-color: ${colors.antdSelection};
            }
        }
    }
    .ant-legacy-form-item {
        && {
            display: block;
        }
    }
    .ant-tabs-nav-wrap {
        justify-content: center;
    }
    .ant-input-affix-wrapper {
        && {
            padding: 0;

            .ant-input-prefix {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
                left: 10px;
            }
            .ant-input {
                padding: 4px 11px;

                &:not(:first-child) {
                    padding-left: 30px;
                }
            }
        }
    }
    .ant-btn-danger {
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        color: #f5222d;
    }
    .ant-legacy-form-item-control .ant-space-item {
        line-height: initial;
    }
`;
