import * as React from 'react';
import { Collapse, Table } from 'antd';

import { CollapseWrapper } from 'common/components/styled';
import { generateColumns } from 'common/helpers/utils';

import { ContractManagerDetail } from '../models/uploadModels';

interface AssignedContractManagersProps {
    contractManagerDetails: ContractManagerDetail[];
}

const contractManagerColumns = [
    {
        title: 'Name',
        key: 'user',
        render: (_value: string, item: ContractManagerDetail) => (
            <span>
                {item.firstName} {item.lastName}
            </span>
        ),
    },
    {
        title: 'Email',
        key: 'email',
        render: (_value: string, item: ContractManagerDetail) => {
            const mailToLink = `mailto:${item.email}`;
            return (
                <span>
                    <a href={mailToLink}>{item.email}</a>
                </span>
            );
        },
    },
];

export const AssignedContractManagers: React.FunctionComponent<AssignedContractManagersProps> = ({
    contractManagerDetails,
}) =>
    contractManagerDetails.length ? (
        <CollapseWrapper>
            <Collapse>
                <Collapse.Panel header="Project Managers" key="0">
                    <Table
                        columns={generateColumns(contractManagerColumns)}
                        dataSource={contractManagerDetails}
                        pagination={false}
                    />
                </Collapse.Panel>
            </Collapse>
        </CollapseWrapper>
    ) : null;
