import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';

import { redirectToUrl } from 'common/actions/navigationAction';
import {
    getContractPriorityDashboard,
    getDateRangeContractPrioritiesDashboard,
} from 'features/contract/selectors/contractSelectors';
import { ContractPriorityDashboardItem } from 'features/contract/models/contractModels';
import { isLoadingRole, isTenant, isAdmin } from 'features/user/selectors/userSelectors';
import { saveFilters, clearFilters } from 'features/pagination/actions/paginationActions';
import { PaginationView } from 'features/pagination/models/paginationModel';

import { ContractPriorityDashboardComponent } from '../components/ContractPriorityDashboard';
import { DashboardPaths } from '../models/dashboardModel';

interface ContractPriorityDashboardContainerStateProps {
    loading: boolean;
    isUserTenant: boolean;
    isUserAdmin: boolean;
    priorities: ContractPriorityDashboardItem[];
    dateRange: string[];
}

interface ContractPriorityDashboardContainerDispatchProps {
    redirectToUrl: typeof redirectToUrl;
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
    getDateRangeContractPrioritiesDashboard: typeof getDateRangeContractPrioritiesDashboard;
}
export type ContractPriorityDashboardContainerProps = ContractPriorityDashboardContainerStateProps &
    ContractPriorityDashboardContainerDispatchProps;

export class ContractPriorityDashboard extends React.Component<ContractPriorityDashboardContainerProps> {
    constructor(props: ContractPriorityDashboardContainerProps) {
        super(props);
        const today = moment().format();
        const threeMonthsAgo = moment().subtract(3, 'months').format();
        this.props.saveFilters({
            paginatedView: PaginationView.ContractPrioritiesDashboard,
            values: {
                contractDateRangeAfter:
                    this.props.dateRange.length > 0
                        ? this.props.dateRange[0] == null
                            ? undefined
                            : this.props.dateRange[0]
                        : threeMonthsAgo,
                contractDateRangeBefore:
                    this.props.dateRange.length > 0
                        ? this.props.dateRange[1] == null
                            ? undefined
                            : this.props.dateRange[1]
                        : today,
            },
            path: DashboardPaths.ContractPriorities,
        });
    }

    public render(): JSX.Element {
        return (
            <ContractPriorityDashboardComponent
                {...this.props}
                redirect={this.props.redirectToUrl}
            />
        );
    }
}

const mapStateToProps = createSelector(
    isLoadingRole,
    isAdmin,
    isTenant,
    getContractPriorityDashboard,
    getDateRangeContractPrioritiesDashboard,
    (
        loading,
        isUserAdmin,
        isUserTenant,
        priorities,
        dateRange,
    ): ContractPriorityDashboardContainerStateProps => ({
        loading,
        isUserAdmin,
        isUserTenant,
        priorities,
        dateRange,
    }),
);

export const ContractPriorityDashboardContainer = connect(mapStateToProps, {
    redirectToUrl,
    saveFilters,
    clearFilters,
})(ContractPriorityDashboard);
