import { PropsWithChildren } from 'react';
import { Form } from '@ant-design/compatible';
import { FormProps } from '@ant-design/compatible/lib/form';
import '@ant-design/compatible/lib/form/style/index.less';
import styled from 'styled-components';

import { FormHeader, MainFormHeader } from 'common/components/styled';
import { breakpoints, sizes } from 'theme/variables';

const StyledForm = styled(Form)`
    && {
        width: 100%;
        margin: 0 auto;
        padding: 1.5rem;
        max-width: ${sizes.formWidth};
    }
`;

type Props = FormProps &
    PropsWithChildren<{
        header?: JSX.Element | string;
        bigHeader?: boolean;
    }>;

export const StyledAntd3Form = (props: Props) => {
    const HeaderComponent = props.bigHeader ? MainFormHeader : FormHeader;

    return (
        <StyledForm {...props}>
            {props.header && <HeaderComponent>{props.header}</HeaderComponent>}
            {props.children}
        </StyledForm>
    );
};
