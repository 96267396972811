import { pluck, switchMap, map } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { AppEpic } from 'common/epics/appEpic';

import * as actions from '../actions/organisationActions';
import { OrganisationService } from '../services/organisationService';
import { Organisation } from '../models/organisationModels';

export const organisationEpicFactory = (organisationService: OrganisationService): AppEpic => {
    const getOrganisationEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_ORGANISATION_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                organisationService
                    .getOrganisation(id)
                    .then(actions.getOrganisationSuccess)
                    .catch(actions.getOrganisationFailure),
            ),
        );

    const editOrganisationEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_ORGANISATION_REQUESTED),
            pluck('payload'),
            switchMap((data: Partial<Organisation>) =>
                organisationService
                    .editOrganisation(data)
                    .then(actions.editOrganisationSuccess)
                    .catch(actions.editOrganisationFailure),
            ),
        );

    const getExecutivesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_EXECUTIVES_REQUESTED),
            switchMap(() =>
                organisationService
                    .getExecutives()
                    .then(actions.getExecutivesSuccess)
                    .catch(actions.getExecutivesFailure),
            ),
        );

    const getPotentialExecutivesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_POTENTIAL_EXECUTIVES_REQUESTED),
            switchMap(() =>
                organisationService
                    .getPotentialExecutives()
                    .then(actions.getPotentialExecutivesSuccess)
                    .catch(actions.getPotentialExecutivesFailure),
            ),
        );

    const getAllExecutivesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_ALL_EXECUTIVES_REQUESTED),
            switchMap(() =>
                organisationService
                    .getAllExecutives()
                    .then(actions.getAllExecutivesSuccess)
                    .catch(actions.getAllExecutivesFailure),
            ),
        );

    const removeExecutiveEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.REMOVE_EXECUTIVE_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                organisationService
                    .removeExecutive(id)
                    .then(actions.removeExecutiveSuccess)
                    .catch(actions.removeExecutiveFailure),
            ),
        );

    const removeExecutiveSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.REMOVE_EXECUTIVE_SUCCEED),
            map(actions.getAllExecutivesRequest),
        );

    const checkOrganisationExistsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CHECK_ORGANISATION_EXISTS),
            pluck('payload'),
            switchMap((id: string) =>
                organisationService
                    .checkOrganisationExists(id)
                    .then()
                    .catch((error) => actions.checkOrganisationExistsSuccess(error.message)),
            ),
        );

    return combineEpics(
        getOrganisationEpic,
        editOrganisationEpic,
        getExecutivesEpic,
        getPotentialExecutivesEpic,
        getAllExecutivesEpic,
        removeExecutiveEpic,
        removeExecutiveSuccessEpic,
        checkOrganisationExistsEpic,
    );
};
