import { Props } from '../containers/SubscriptionContainer';
import { InvoiceForm } from './InvoiceForm';
import { InvoiceList } from './InvoiceList';
import { SubscriptionCards } from './SubscriptionCards';
import { SubscriptionUserInfo } from './SubscriptionUserInfo';

export const SubscriptionDetails = ({ userSubscription, profile, isUserAdmin }: Props) => (
    <>
        <SubscriptionCards profile={profile} userSubscription={userSubscription} />
        {userSubscription && (
            <>
                <SubscriptionUserInfo
                    profile={profile}
                    userSubscription={userSubscription}
                    isUserAdmin={isUserAdmin}
                />

                <InvoiceList
                    userSubscription={userSubscription}
                    profile={profile}
                    isUserAdmin={isUserAdmin}
                />
            </>
        )}

        <InvoiceForm />
    </>
);
