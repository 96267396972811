import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { colors, sizes } from 'theme/variables';

import { FormHeader, MainFormHeader } from './styled';

const PaddedSectionWrapper = styled.section<{ grayBg?: boolean; bottomBorder?: boolean }>(
    ({ grayBg, bottomBorder }) => `
    width: 100%;
    margin: 0 auto;
    background-color: ${grayBg ? colors.lightGray : 'transparent'};
    border-bottom: ${bottomBorder ? `1px solid ${colors.antdBorder}` : 'none'};

    > div {
        max-width: ${sizes.formWidth};
        padding: 1.5rem;
        margin: 0 auto;
    }
`,
);

type Props = PropsWithChildren<{
    header?: JSX.Element | string;
    bigHeader?: boolean;
    grayBg?: boolean;
    bottomBorder?: boolean;
}>;

export const PaddedSection = ({ header, bigHeader, grayBg, bottomBorder, children }: Props) => {
    const HeaderComponent = bigHeader ? MainFormHeader : FormHeader;

    return (
        <PaddedSectionWrapper grayBg={grayBg} bottomBorder={bottomBorder}>
            <div>
                {header ? <HeaderComponent>{header}</HeaderComponent> : null}
                {children}
            </div>
        </PaddedSectionWrapper>
    );
};
