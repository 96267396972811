import { injectable } from 'inversify';

import { UserOrganisation } from 'features/user/models/userModel';

@injectable()
export class StorageService {
    public async read(key: string): Promise<UserOrganisation> {
        try {
            const data = localStorage.getItem(key);
            const parseData = await JSON.parse(data!);

            return parseData;
        } catch (error) {
            throw Error(error);
        }
    }

    public async write(key: string, data: any): Promise<void> {
        const stringData = await JSON.stringify(data);
        return localStorage.setItem(key, stringData);
    }
}
