import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { StaticContext } from 'react-router';

import { setAfterLoginRoutes } from 'config/config';
import { CustomRouteProps } from 'common/models/navigationModel';
import { UserPaths } from 'features/user/models/userModel';

import { FullPageSpinner } from './FullPageSpinner';
import { ContentMenu } from './ContentMenu';
import { userRoutes } from 'features/user/config/userRouting';

export type RouteProps = RouteComponentProps<
    {
        [x: string]: string | undefined;
    },
    StaticContext,
    unknown
>;

export const CustomRoute = ({
    component: Component,
    isAuthenticated,
    isTokenChecked,
    redirect = '/sign-in',
    secured = false,
    path,
    activeOrganisation,
    adminMenuLinks,
    contractManagerMenuLinks,
    menuLinks,
    exact,
    location,
    hideNavigation,
    SubNavigation,
}: CustomRouteProps) => {
    if (activeOrganisation) {
        setAfterLoginRoutes(activeOrganisation.organisation.id);
    }

    const renderRouteContent = (routeProps: RouteProps) => {
        if (!Component) {
            return;
        }

        if (!isTokenChecked) {
            return <FullPageSpinner />;
        }

        if (isAuthenticated === secured) {
            return isAuthenticated && isUndefined(activeOrganisation) ? (
                <FullPageSpinner />
            ) : (
                <>
                    {!hideNavigation && (
                        <ContentMenu
                            adminMenuLinks={adminMenuLinks}
                            contractManagerMenuLinks={contractManagerMenuLinks}
                            menuLinks={menuLinks}
                        />
                    )}
                    {SubNavigation && <SubNavigation {...routeProps} />}
                    <Component {...routeProps} />
                </>
            );
        }

        if (path == UserPaths.VerifyNewAccount || path == UserPaths.VerifyNewExecutive) {
            return <Component {...routeProps} />;
        }

        return (
            <Redirect
                to={{
                    pathname: redirect,
                    state: { state: 'Access denied', referrer: location },
                }}
            />
        );
    };

    return <Route path={path} exact={exact} render={renderRouteContent} />;
};
