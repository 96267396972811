import { dashboardRoutes } from 'features/dashboard/config/dashboardRouting';
import { checkRoutes } from 'features/check/config/checkRouting';
import { downloadRoutes } from 'features/download/config/downloadRouting';
import { editRoutes } from 'features/edit/config/editRouting';
import { userRoutes } from 'features/user/config/userRouting';
import { inviteRoutes } from 'features/invite/config/inviteRouting';
import { createRoutes } from 'features/create/config/createRouting';
import { importRoutes } from 'features/import/config/importRouting';
import { uploadRoutes } from 'features/upload/config/uploadRouting';
import { assignRoutes } from 'features/assign/config/assignRouting';
import { CustomRouteProps } from 'common/models/navigationModel';

export const navigationRoutes: CustomRouteProps[] = [
    ...userRoutes,
    ...dashboardRoutes,
    ...checkRoutes,
    ...downloadRoutes,
    ...editRoutes,
    ...assignRoutes,
    ...inviteRoutes,
    ...createRoutes,
    ...importRoutes,
    ...uploadRoutes,
];
