import * as React from 'react';

import { history } from 'config/config';
import { Card, CardName, CardWrapper } from 'common/components/styled';

import { SupplierAssignPageTitles, SupplierAssignPaths } from '../models/editModel';

interface SupplierAssignPageProps {
    tile: SupplierAssignPageTitles;
}

const supplierAssignPage = new Map([
    [SupplierAssignPageTitles.Assign, SupplierAssignPaths.Contracts],
]);

export const SupplierAssignPage: React.FunctionComponent<SupplierAssignPageProps> = () => (
    <CardWrapper>
        {Array.from(supplierAssignPage.keys()).map((element) => (
            <Card
                key={element}
                onClick={() => history.push(supplierAssignPage.get(element) || '/')}
                active={1}
            >
                <CardName>{element}</CardName>
            </Card>
        ))}
    </CardWrapper>
);
