import moment from 'moment';
import { startCase, camelCase } from 'lodash';

import { DateFormat } from 'config/variables';
import { Benefit } from 'features/benefit/models/benefitModels';

export const getListData = (benefit: Benefit, showFinalDeliveryDate: boolean) => {
    const finalDeliveryDate = `Benefit Completion Date: ${moment(benefit.finalDeliveryDate).format(
        DateFormat.Euro,
    )}`;

    const dataArray = [
        `Benefit: ${benefit.outcome}` +
            `${benefit.referenceNumber ? ` | ${benefit.referenceNumber}` : ``}`,
        `Benefit Label: ${benefit.label ? benefit.label : 'none'}`,
        `Description: ${benefit.description}`,
        `Quantity: ${benefit.quantity}`,
        `Benefit Status: ${startCase(camelCase(benefit.status))}`,
        `Benefit Value: ${benefit.value ? benefit.value : 'none'}`,
        `Points: ${benefit.points ? benefit.points : 'none'}`,
        `Emission Savings: ${benefit.emissionSavings ? benefit.emissionSavings : 'none'}`,
    ];

    if (showFinalDeliveryDate) {
        dataArray.splice(3, 0, finalDeliveryDate);
    }

    return dataArray.map((name: string, id: number) => ({
        id,
        name,
    }));
};
