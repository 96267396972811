import { CustomRouteProps } from 'common/models/navigationModel';

import { BenefitPriorityDashboardContainer } from '../containers/BenefitPriorityDashboardContainer';
import { BenefitTemplateDashboardContainer } from '../containers/BenefitTemplateDashboardContainer';
import { ContractDashboardContainer } from '../containers/ContractDashboardContainer';
import { ContractPriorityDashboardContainer } from '../containers/ContractPriorityDashboardContainer';
import { DashboardFilterContainer } from '../containers/DashboardFilterContainer';
import { DashboardPaths } from '../models/dashboardModel';

const menuLinks = [
    { key: DashboardPaths.Contracts, label: 'Projects' },
    { key: DashboardPaths.BenefitTemplates, label: 'Benefit Template Outcomes' },
    { key: DashboardPaths.ContractPriorities, label: 'Project Priorities' },
    { key: DashboardPaths.BenefitPriorities, label: 'Benefit Priorities' },
];

export const dashboardRoutes: CustomRouteProps[] = [
    {
        path: DashboardPaths.Contracts,
        component: ContractDashboardContainer,
        exact: true,
    },
    {
        path: `${DashboardPaths.ContractsFilter}/:filterId`,
        component: DashboardFilterContainer,
    },
    {
        path: DashboardPaths.BenefitTemplates,
        component: BenefitTemplateDashboardContainer,
        exact: true,
    },
    {
        path: `${DashboardPaths.BenefitTemplatesFilter}/:filterId`,
        component: DashboardFilterContainer,
    },
    {
        path: DashboardPaths.ContractPriorities,
        component: ContractPriorityDashboardContainer,
        exact: true,
    },
    {
        path: `${DashboardPaths.ContractPrioritiesFilter}/:filterId`,
        component: DashboardFilterContainer,
    },
    {
        path: DashboardPaths.BenefitPriorities,
        component: BenefitPriorityDashboardContainer,
        exact: true,
    },
    {
        path: `${DashboardPaths.BenefitPrioritiesFilter}/:filterId`,
        component: DashboardFilterContainer,
    },
].map((route) => ({
    ...route,
    menuLinks,
}));
