import * as React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { generateColumns, addReferenceSuffixToBenfitOutcome } from 'common/helpers/utils';
import { Benefit, EditBenefitPaths } from 'features/benefit/models/benefitModels';
import { openModal } from 'common/helpers/modal';
import { FilterData } from 'common/helpers/url';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getContractBenefitsSuccess } from 'features/benefit/actions';
import { EditPaths } from 'features/edit/models/editModel';
import { Antd3Form, Antd3Icon, Antd3FormProps } from 'common/components/deprecated/antd3';
import { PaddedSection } from 'common/components';

import { ContractBenefitsProps as ContractBenefitsContainerProps } from '../containers/ContractBenefitsContainer';
import { ContractBenefitsButtons } from './ContractBenefitsButtons';
import { EditContractPaths } from '../models/contractModels';

interface ContainerProps {
    deleteBenefit(benefitId: number): void;
}

const openDeleteBenefitModal = (e: React.FormEvent, id: number, onRemove: (id: number) => void) => {
    e.preventDefault();

    return openModal({
        callback: () => onRemove(id),
        title: 'If you delete this benefit you will also delete any evidence associated with it. Are you sure you want to continue?',
    });
};

type ContractBenefitsProps = ContractBenefitsContainerProps & ContainerProps & Antd3FormProps;

class ContractBenefitsForm extends React.Component<ContractBenefitsProps> {
    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.ContractBenefits,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getContractBenefitsSuccess,
        });

    private saveBenefitFilters = (benefitReferenceNumber: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.ContractBenefits,
            values: {
                referenceNumber: benefitReferenceNumber,
            },
        });

    private searchByBenefits = (benefitReferenceNumber: string) =>
        this.saveBenefitFilters(benefitReferenceNumber) && this.refreshTable();

    private onRemoveBenefitAutocomplete = () =>
        this.saveBenefitFilters(undefined) && this.refreshTable();

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.ContractBenefits) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            quickFilterBenefitReferenceNumber: '',
        });

    public render(): JSX.Element {
        const { contractBenefits, deleteBenefit, contractId, filter } = this.props;

        const contractBenefitsColumns = [
            {
                title: 'Benefit',
                key: 'outcome',
                render: (outcome: string, { id }: Benefit) => (
                    <Link
                        to={`${EditPaths.Contract}/${contractId}/benefits/${id}${EditBenefitPaths.Details}`}
                    >
                        {outcome}
                    </Link>
                ),
            },
            {
                title: '',
                key: 'id',
                render: (_: string, { id }: Benefit) => (
                    <>
                        <Link
                            to={`${EditPaths.Contract}/${contractId}/benefits/${id}${EditBenefitPaths.BenefitForm}`}
                        >
                            <Button icon={<Antd3Icon type="edit" />} size="small">
                                Edit
                            </Button>
                        </Link>
                        <Button
                            onClick={(e: React.FormEvent) =>
                                openDeleteBenefitModal(e, id, deleteBenefit)
                            }
                            type="danger"
                            icon={<Antd3Icon type="delete" />}
                            size="small"
                        >
                            Remove
                        </Button>
                    </>
                ),
            },
        ];

        return (
            <PaddedSection header="Benefits">
                <ContractBenefitsButtons
                    contractId={contractId}
                    searchByBenefits={this.searchByBenefits}
                    onRemoveBenefitAutocomplete={this.onRemoveBenefitAutocomplete}
                    clearFilters={this.clearFilters}
                    form={this.props.form}
                    filter={filter}
                />
                <Antd3Form.Item>
                    <PaginatedTable
                        view={PaginationView.ContractBenefits}
                        paginationSuccessCallback={getContractBenefitsSuccess}
                        requestParams={{ contractId }}
                        data={addReferenceSuffixToBenfitOutcome(contractBenefits)}
                        columns={generateColumns(contractBenefitsColumns)}
                        emptyText="No benefits found"
                        pageNumber={this.props.pageNumber}
                        pathUrl={`${EditPaths.Contract}/${contractId}${EditContractPaths.Benefits}`}
                        withPaginationHistory
                    />
                </Antd3Form.Item>
            </PaddedSection>
        );
    }
}

export const ContractBenefits = Antd3Form.create({})(ContractBenefitsForm);
