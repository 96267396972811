import { Checkbox, CheckboxProps as AntdCheckboxProps } from 'antd';

import { Antd3FormElementProps } from './Antd3Form';

export const Antd3Checkbox = ({
    form,
    id,
    required = false,
    getFieldDecoratorOptions,
    ...inputProps
}: AntdCheckboxProps & Antd3FormElementProps) => (
    <>
        {form?.getFieldDecorator ? (
            form.getFieldDecorator(
                id,
                getFieldDecoratorOptions,
            )(<Checkbox {...inputProps} checked={form?.getFieldValue(id)} />)
        ) : (
            <Checkbox {...inputProps} checked={form?.getFieldValue(id)} />
        )}
    </>
);
