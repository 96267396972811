import * as React from 'react';

import { ModalInput } from 'common/components/deprecated/form';
import { Antd3FormProps } from 'common/components/deprecated/antd3';
import { PaddedSection } from 'common/components';

interface InviteExecutiveUserProps {
    handleChange: (e: React.FormEvent) => void;
}

export class InviteExecutiveUserForm extends React.Component<
    InviteExecutiveUserProps & Antd3FormProps
> {
    public render(): JSX.Element {
        const { handleChange, form } = this.props;

        return (
            <PaddedSection>
                <ModalInput
                    form={form}
                    id="First Name"
                    name="first_name"
                    placeholder="First Name"
                    onChange={handleChange}
                    required
                />
                <ModalInput
                    form={form}
                    id="last_name"
                    name="last_name"
                    placeholder="Last Name"
                    onChange={handleChange}
                    required
                />
                <ModalInput
                    form={form}
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    required
                />
                <ModalInput
                    form={form}
                    id="telephone"
                    name="telephone"
                    placeholder="Telephone Number"
                    onChange={handleChange}
                    required={false}
                />
            </PaddedSection>
        );
    }
}
