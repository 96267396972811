import {
    PortfolioFiltersEnum,
    PortfolioFilter,
    PortfolioFiltersLists,
} from '../models/portfolioFilterModel';

export const getPortfolioFiltersChangeOnEdit = (
    depth: PortfolioFiltersEnum,
    portfolioFilter: PortfolioFilter,
    { subPrograms, mainProjects, subProjects }: PortfolioFiltersLists,
) => {
    switch (depth) {
        case PortfolioFiltersEnum.SubProgram: {
            return {
                subPrograms: updatePortfolioFilter(portfolioFilter, subPrograms),
                mainProjects: [],
                subProjects: [],
            };
        }
        case PortfolioFiltersEnum.MainProject: {
            return {
                mainProjects: updatePortfolioFilter(portfolioFilter, mainProjects),
                subProjects: [],
            };
        }
        default:
            return {
                subProjects: updatePortfolioFilter(portfolioFilter, subProjects),
            };
    }
};

const updatePortfolioFilter = (
    editedPortfolioFilter: PortfolioFilter,
    portfolioFilters: PortfolioFilter[],
) =>
    portfolioFilters.map((portfolioFilter) =>
        editedPortfolioFilter.id === portfolioFilter.id ? editedPortfolioFilter : portfolioFilter,
    );
