import { Action } from 'common/actions/appAction';
import { InviteByEmailWithRole } from 'features/invite/models/inviteModel';

import {
    Benefit,
    SelectBenefitTemplateRequest,
    CreateBenefitTemplateRequest,
    BenefitTemplate,
    EditBenefitTemplateFilesRequest,
    BenefitTemplateWithRag,
    ArchiveBenefitTemplateRequest,
    InvitePendingIndividualExecutivesRequest,
    EditBenefitTemplateRequest,
    CloneBenefitTemplateRequest,
    MigrateBenefitsRequest,
} from '../models/benefitModels';

export const ARCHIVE_BENEFIT_TEMPLATE_REQUESTED = 'ARCHIVE_BENEFIT_TEMPLATE_REQUESTED';
export type ArchiveBenefitTemplateRequestAction = Action<
    typeof ARCHIVE_BENEFIT_TEMPLATE_REQUESTED,
    ArchiveBenefitTemplateRequest
>;
export const archiveBenefitTemplateRequest = (
    id: number,
    archived: boolean,
): ArchiveBenefitTemplateRequestAction => ({
    type: ARCHIVE_BENEFIT_TEMPLATE_REQUESTED,
    payload: { id, archived },
});

export const ARCHIVE_BENEFIT_TEMPLATE_SUCCEED = 'ARCHIVE_BENEFIT_TEMPLATE_SUCCEED';
export type ArchiveBenefitTemplateSuccessAction = Action<
    typeof ARCHIVE_BENEFIT_TEMPLATE_SUCCEED,
    null,
    string
>;

export const archiveBenefitTemplateSuccess = (
    meta: string,
): ArchiveBenefitTemplateSuccessAction => ({
    type: ARCHIVE_BENEFIT_TEMPLATE_SUCCEED,
    meta,
});

export const ARCHIVE_BENEFIT_TEMPLATE_FAILED = 'ARCHIVE_BENEFIT_TEMPLATE_FAILED';
export type ArchiveBenefitTemplateFailureAction = Action<
    typeof ARCHIVE_BENEFIT_TEMPLATE_FAILED,
    string
>;
export const archiveBenefitTemplateFailure = (
    payload: string,
): ArchiveBenefitTemplateFailureAction => ({
    type: ARCHIVE_BENEFIT_TEMPLATE_FAILED,
    payload,
});

export const CREATE_BENEFIT_TEMPLATE_REQUESTED = 'CREATE_BENEFIT_TEMPLATE_REQUESTED';
export type CreateBenefitTemplateRequestAction = Action<
    typeof CREATE_BENEFIT_TEMPLATE_REQUESTED,
    CreateBenefitTemplateRequest
>;
export const createBenefitTemplateRequest = (
    benefitTemplate: CreateBenefitTemplateRequest,
): CreateBenefitTemplateRequestAction => ({
    type: CREATE_BENEFIT_TEMPLATE_REQUESTED,
    payload: benefitTemplate,
});

export const CREATE_BENEFIT_TEMPLATE_SUCCEED = 'CREATE_BENEFIT_TEMPLATE_SUCCEED';
export type CreateBenefitTemplateSuccessAction = Action<
    typeof CREATE_BENEFIT_TEMPLATE_SUCCEED,
    BenefitTemplate,
    string
>;
export const createBenefitTemplateSuccess = (
    benefitTemplate: BenefitTemplate,
): CreateBenefitTemplateSuccessAction => ({
    type: CREATE_BENEFIT_TEMPLATE_SUCCEED,
    meta: 'Benefit template created',
    payload: benefitTemplate,
});

export const CREATE_BENEFIT_TEMPLATE_FAILED = 'CREATE_BENEFIT_TEMPLATE_FAILED';
export type CreateBenefitTemplateFailureAction = Action<
    typeof CREATE_BENEFIT_TEMPLATE_FAILED,
    string
>;
export const createBenefitTemplateFailure = (
    payload: string,
): CreateBenefitTemplateFailureAction => ({
    type: CREATE_BENEFIT_TEMPLATE_FAILED,
    payload,
});

export const EDIT_BENEFIT_TEMPLATE_REQUESTED = 'EDIT_BENEFIT_TEMPLATE_REQUESTED';
export type EditBenefitTemplateRequestAction = Action<
    typeof EDIT_BENEFIT_TEMPLATE_REQUESTED,
    EditBenefitTemplateRequest
>;
export const editBenefitTemplateRequest = (
    benefitTemplate: EditBenefitTemplateRequest,
): EditBenefitTemplateRequestAction => ({
    type: EDIT_BENEFIT_TEMPLATE_REQUESTED,
    payload: benefitTemplate,
});

export const EDIT_BENEFIT_TEMPLATE_SUCCEED = 'EDIT_BENEFIT_TEMPLATE_SUCCEED';
export type EditBenefitTemplateSuccessAction = Action<
    typeof EDIT_BENEFIT_TEMPLATE_SUCCEED,
    BenefitTemplate,
    string
>;
export const editBenefitTemplateSuccess = (
    benefitTemplate: BenefitTemplate,
): EditBenefitTemplateSuccessAction => ({
    type: EDIT_BENEFIT_TEMPLATE_SUCCEED,
    meta: 'Benefit template edited',
    payload: benefitTemplate,
});

export const EDIT_BENEFIT_TEMPLATE_FAILED = 'EDIT_BENEFIT_TEMPLATE_FAILED';
export type EditBenefitTemplateFailureAction = Action<typeof EDIT_BENEFIT_TEMPLATE_FAILED, string>;
export const editBenefitTemplateFailure = (payload: string): EditBenefitTemplateFailureAction => ({
    type: EDIT_BENEFIT_TEMPLATE_FAILED,
    payload,
});

export const CLONE_BENEFIT_TEMPLATE_REQUESTED = 'CLONE_BENEFIT_TEMPLATE_REQUESTED';
export type CloneBenefitTemplateRequestAction = Action<
    typeof CLONE_BENEFIT_TEMPLATE_REQUESTED,
    CloneBenefitTemplateRequest
>;

export const cloneBenefitTemplateRequest = (
    benefitTemplate: CloneBenefitTemplateRequest,
): CloneBenefitTemplateRequestAction => ({
    type: CLONE_BENEFIT_TEMPLATE_REQUESTED,
    payload: benefitTemplate,
});

export const CLONE_BENEFIT_TEMPLATE_SUCCEED = 'CLONE_BENEFIT_TEMPLATE_SUCCEED';
export type CloneBenefitTemplateSuccessAction = Action<
    typeof CLONE_BENEFIT_TEMPLATE_SUCCEED,
    EditBenefitTemplateFilesRequest,
    string
>;

export const cloneBenefitTemplateSuccess = (
    benefitTemplate: EditBenefitTemplateFilesRequest,
): CloneBenefitTemplateSuccessAction => ({
    type: CLONE_BENEFIT_TEMPLATE_SUCCEED,
    meta: 'Benefit template cloned',
    payload: benefitTemplate,
});

export const CLONE_BENEFIT_TEMPLATE_FAILED = 'CLONE_BENEFIT_TEMPLATE_FAILED';
export type CloneBenefitTemplateFailureAction = Action<
    typeof CLONE_BENEFIT_TEMPLATE_FAILED,
    string
>;
export const cloneBenefitTemplateFailure = (
    payload: string,
): CloneBenefitTemplateFailureAction => ({
    type: CLONE_BENEFIT_TEMPLATE_FAILED,
    payload,
});

export const MIGRATE_BENEFITS_REQUESTED = 'MIGRATE_BENEFITS_REQUESTED';
export type MigrateBenefitsRequestAction = Action<
    typeof MIGRATE_BENEFITS_REQUESTED,
    MigrateBenefitsRequest
>;

export const migrateBenefitsRequest = (
    payload: MigrateBenefitsRequest,
): MigrateBenefitsRequestAction => ({
    type: MIGRATE_BENEFITS_REQUESTED,
    payload,
});

export const MIGRATE_BENEFITS_SUCCEED = 'MIGRATE_BENEFITS_SUCCEED';
export type MigrateBenefitsSuccessAction = Action<
    typeof MIGRATE_BENEFITS_SUCCEED,
    MigrateBenefitsRequest,
    string
>;

export const migrateBenefitsSuccess = (): MigrateBenefitsSuccessAction => ({
    type: MIGRATE_BENEFITS_SUCCEED,
    meta: 'Benefits migrated',
});

export const MIGRATE_BENEFITS_FAILED = 'MIGRATE_BENEFITS_FAILED';
export type MigrateBenefitsFailureAction = Action<typeof MIGRATE_BENEFITS_FAILED, string>;

export const migrateBenefitsFailure = (payload: string): MigrateBenefitsFailureAction => ({
    type: MIGRATE_BENEFITS_FAILED,
    payload,
});

export const EDIT_BENEFIT_TEMPLATE_FILES_REQUESTED = 'EDIT_BENEFIT_TEMPLATE_FILES_REQUESTED';
export type EditBenefitTemplateFilesRequestAction = Action<
    typeof EDIT_BENEFIT_TEMPLATE_FILES_REQUESTED,
    EditBenefitTemplateFilesRequest
>;
export const editBenefitTemplateFilesRequest = (
    payload: EditBenefitTemplateFilesRequest,
): EditBenefitTemplateFilesRequestAction => ({
    type: EDIT_BENEFIT_TEMPLATE_FILES_REQUESTED,
    payload,
});

export const EDIT_BENEFIT_TEMPLATE_FILES_SUCCEED = 'EDIT_BENEFIT_TEMPLATE_FILES_SUCCEED';
export type EditBenefitTemplateFilesSuccessAction = Action<
    typeof EDIT_BENEFIT_TEMPLATE_FILES_SUCCEED,
    number,
    string
>;
export const editBenefitTemplateFilesSuccess = (
    id: number,
): EditBenefitTemplateFilesSuccessAction => ({
    type: EDIT_BENEFIT_TEMPLATE_FILES_SUCCEED,
    payload: id,
    meta: 'Benefit template files updated',
});

export const EDIT_BENEFIT_TEMPLATE_FILES_FAILED = 'EDIT_BENEFIT_TEMPLATE_FILES_FAILED';
export type EditBenefitTemplateFilesFailureAction = Action<
    typeof EDIT_BENEFIT_TEMPLATE_FILES_FAILED,
    string
>;
export const editBenefitTemplateFilesFailure = (
    payload: string,
): EditBenefitTemplateFilesFailureAction => ({
    type: EDIT_BENEFIT_TEMPLATE_FILES_FAILED,
    payload,
});

export const GET_BENEFIT_TEMPLATES_REQUESTED = 'GET_BENEFIT_TEMPLATES_REQUESTED';
export type GetBenefitTemplatesRequestAction = Action<typeof GET_BENEFIT_TEMPLATES_REQUESTED>;
export const getBenefitTemplatesRequest = (): GetBenefitTemplatesRequestAction => ({
    type: GET_BENEFIT_TEMPLATES_REQUESTED,
});

export const GET_BENEFIT_TEMPLATES_SUCCEED = 'GET_BENEFIT_TEMPLATES_SUCCEED';
export type GetBenefitTemplatesSuccessAction = Action<
    typeof GET_BENEFIT_TEMPLATES_SUCCEED,
    { benefitTemplates: Benefit[] }
>;
export const getBenefitTemplatesSuccess = (
    benefitTemplates: Benefit[],
): GetBenefitTemplatesSuccessAction => ({
    type: GET_BENEFIT_TEMPLATES_SUCCEED,
    payload: { benefitTemplates },
});

export const GET_BENEFIT_TEMPLATES_FAILED = 'GET_BENEFIT_TEMPLATES_FAILED';
export type GetBenefitTemplatesFailureAction = Action<typeof GET_BENEFIT_TEMPLATES_FAILED, string>;
export const getBenefitTemplatesFailure = (payload: string): GetBenefitTemplatesFailureAction => ({
    type: GET_BENEFIT_TEMPLATES_FAILED,
    payload,
});

export const GET_RAG_BENEFIT_TEMPLATE_SUCCEED = 'GET_RAG_BENEFIT_TEMPLATE_SUCCEED';
export type GetRagBenefitTemplateSuccessAction = Action<
    typeof GET_RAG_BENEFIT_TEMPLATE_SUCCEED,
    { benefitTemplatesWithRag: BenefitTemplateWithRag[] }
>;
export const getRagBenefitTemplateSuccess = (
    benefitTemplatesWithRag: BenefitTemplateWithRag[],
): GetRagBenefitTemplateSuccessAction => ({
    type: GET_RAG_BENEFIT_TEMPLATE_SUCCEED,
    payload: { benefitTemplatesWithRag },
});

export const GET_BENEFIT_TEMPLATE_DATA_REQUESTED = 'GET_BENEFIT_TEMPLATE_DATA_REQUESTED';
export type GetBenefitTemplateDataRequestAction = Action<
    typeof GET_BENEFIT_TEMPLATE_DATA_REQUESTED
>;
export const getBenefitTemplateDataRequest = (): GetBenefitTemplateDataRequestAction => ({
    type: GET_BENEFIT_TEMPLATE_DATA_REQUESTED,
});

export const GET_BENEFIT_TEMPLATE_DATA_SUCCEED = 'GET_BENEFIT_TEMPLATE_DATA_SUCCEED';
export type GetBenefitTemplateDataSuccessAction = Action<
    typeof GET_BENEFIT_TEMPLATE_DATA_SUCCEED,
    { benefitCategories: Benefit[]; benefitTemplates: Benefit[] }
>;
export const getBenefitTemplateDataSuccess = (
    benefitCategories: Benefit[],
    benefitTemplates: Benefit[],
): GetBenefitTemplateDataSuccessAction => ({
    type: GET_BENEFIT_TEMPLATE_DATA_SUCCEED,
    payload: { benefitCategories, benefitTemplates },
});

export const GET_BENEFIT_TEMPLATE_DATA_FAILED = 'GET_BENEFIT_TEMPLATE_DATA_FAILED';
export type GetBenefitTemplateDataFailureAction = Action<
    typeof GET_BENEFIT_TEMPLATE_DATA_FAILED,
    string
>;
export const getBenefitTemplateDataFailure = (
    payload: string,
): GetBenefitTemplateDataFailureAction => ({
    type: GET_BENEFIT_TEMPLATE_DATA_FAILED,
    payload,
});

export const SELECT_BENEFIT_TEMPLATE_REQUESTED = 'SELECT_BENEFIT_TEMPLATE_REQUESTED';
export type SelectBenefitTemplateRequestAction = Action<
    typeof SELECT_BENEFIT_TEMPLATE_REQUESTED,
    SelectBenefitTemplateRequest
>;
export const selectBenefitTemplateRequest = (
    benefitTemplate: SelectBenefitTemplateRequest,
): SelectBenefitTemplateRequestAction => ({
    type: SELECT_BENEFIT_TEMPLATE_REQUESTED,
    payload: benefitTemplate,
});

export const SELECT_BENEFIT_TEMPLATE_SUCCEED = 'SELECT_BENEFIT_TEMPLATE_SUCCEED';
export type SelectBenefitTemplateSuccessAction = Action<
    typeof SELECT_BENEFIT_TEMPLATE_SUCCEED,
    {
        newUsers: InviteByEmailWithRole[];
        assignInvitePendingUsers: InvitePendingIndividualExecutivesRequest[];
    },
    string
>;
export const selectBenefitTemplateSuccess = (
    newUsers: InviteByEmailWithRole[],
    assignInvitePendingUsers: InvitePendingIndividualExecutivesRequest[],
): SelectBenefitTemplateSuccessAction => ({
    type: SELECT_BENEFIT_TEMPLATE_SUCCEED,
    payload: { newUsers, assignInvitePendingUsers },
    meta: 'Benefit created',
});

export const SELECT_BENEFIT_TEMPLATE_FAILED = 'SELECT_BENEFIT_TEMPLATE_FAILED';
export type SelectBenefitTemplateFailureAction = Action<
    typeof SELECT_BENEFIT_TEMPLATE_FAILED,
    string
>;
export const selectBenefitTemplateFailure = (
    payload: string,
): SelectBenefitTemplateFailureAction => ({
    type: SELECT_BENEFIT_TEMPLATE_FAILED,
    payload,
});

export const GET_BENEFIT_TEMPLATE_REQUESTED = 'GET_BENEFIT_TEMPLATE_REQUESTED';
export type GetBenefitTemplateRequestAction = Action<typeof GET_BENEFIT_TEMPLATE_REQUESTED, number>;
export const getBenefitTemplateRequest = (id: number): GetBenefitTemplateRequestAction => ({
    type: GET_BENEFIT_TEMPLATE_REQUESTED,
    payload: id,
});

export const GET_BENEFIT_TEMPLATE_SUCCEED = 'GET_BENEFIT_TEMPLATE_SUCCEED';
export type GetBenefitTemplateSuccessAction = Action<
    typeof GET_BENEFIT_TEMPLATE_SUCCEED,
    BenefitTemplate
>;
export const getBenefitTemplateSuccess = (
    benefitTemplate: BenefitTemplate,
): GetBenefitTemplateSuccessAction => ({
    type: GET_BENEFIT_TEMPLATE_SUCCEED,
    payload: benefitTemplate,
});

export const GET_BENEFIT_TEMPLATE_FAILED = 'GET_BENEFIT_TEMPLATE_FAILED';
export type GetBenefitTemplateFailureAction = Action<typeof GET_BENEFIT_TEMPLATE_FAILED, string>;
export const getBenefitTemplateFailure = (payload: string): GetBenefitTemplateFailureAction => ({
    type: GET_BENEFIT_TEMPLATE_FAILED,
    payload,
});

export const GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_REQUESTED =
    'GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_REQUESTED';
export type GetBenefitTemplateWhenCreatingTemplatedBenefitRequestAction = Action<
    typeof GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_REQUESTED,
    number
>;
export const getBenefitTemplateWhenCreatingTemplatedBenefitRequest = (
    selectedBenefitTemplateId: number,
): GetBenefitTemplateWhenCreatingTemplatedBenefitRequestAction => ({
    type: GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_REQUESTED,
    payload: selectedBenefitTemplateId,
});

export const GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_SUCCEED =
    'GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_SUCCEED';
export type GetBenefitTemplateWhenCreatingTemplatedBenefitSuccessAction = Action<
    typeof GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_SUCCEED,
    BenefitTemplate
>;
export const getBenefitTemplateWhenCreatingTemplatedBenefitSuccess = (
    benefitTemplate: BenefitTemplate,
): GetBenefitTemplateWhenCreatingTemplatedBenefitSuccessAction => ({
    type: GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_SUCCEED,
    payload: benefitTemplate,
});

export const GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_FAILED =
    'GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_FAILED';
export type GetBenefitTemplateWhenCreatingTemplatedBenefitFailureAction = Action<
    typeof GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_FAILED,
    string
>;
export const getBenefitTemplateWhenCreatingTemplatedBenefitFailure = (
    payload: string,
): GetBenefitTemplateWhenCreatingTemplatedBenefitFailureAction => ({
    type: GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_FAILED,
    payload,
});

export const CLEAR_BENEFIT_TEMPLATE = 'CLEAR_BENEFIT_TEMPLATE';
export type ClearBenefitTemplate = Action<typeof CLEAR_BENEFIT_TEMPLATE>;
export const clearBenefitTemplate = (): ClearBenefitTemplate => ({
    type: CLEAR_BENEFIT_TEMPLATE,
});

export const CLEAR_SELECTED_BENEFIT_TEMPLATE = 'CLEAR_SELECTED_BENEFIT_TEMPLATE';
export type ClearSelectedBenefitTemplate = Action<typeof CLEAR_SELECTED_BENEFIT_TEMPLATE>;
export const clearSelectedBenefitTemplate = (): ClearSelectedBenefitTemplate => ({
    type: CLEAR_SELECTED_BENEFIT_TEMPLATE,
});

export const SET_DATE_RANGE_BENEFIT_TEMPLATE_DASHBOARD =
    'SET_DATE_RANGE_BENEFIT_TEMPLATE_DASHBOARD';
export type SetDateRangeBenefitTemplateDashboardAction = Action<
    typeof SET_DATE_RANGE_BENEFIT_TEMPLATE_DASHBOARD,
    string[]
>;
export const setDateRangeBenefitTemplateDashboard = (
    benefitTemplateFilterDateRange: string[],
): SetDateRangeBenefitTemplateDashboardAction => ({
    type: SET_DATE_RANGE_BENEFIT_TEMPLATE_DASHBOARD,
    payload: benefitTemplateFilterDateRange,
});

export const DELETE_BENEFIT_TEMPLATE_REQUESTED = 'DELETE_BENEFIT_TEMPLATE_REQUESTED';
export type DeleteBenefitTemplateRequestAction = Action<
    typeof DELETE_BENEFIT_TEMPLATE_REQUESTED,
    number
>;
export const deleteBenefitTemplateRequest = (id: number): DeleteBenefitTemplateRequestAction => ({
    type: DELETE_BENEFIT_TEMPLATE_REQUESTED,
    payload: id,
});

export const DELETE_BENEFIT_TEMPLATE_SUCCEED = 'DELETE_BENEFIT_TEMPLATE_SUCCEED';
export type DeleteBenefitTemplateSuccessAction = Action<
    typeof DELETE_BENEFIT_TEMPLATE_SUCCEED,
    null,
    string
>;
export const deleteBenefitTemplateSuccess = (meta: string): DeleteBenefitTemplateSuccessAction => ({
    type: DELETE_BENEFIT_TEMPLATE_SUCCEED,
    meta,
});

export const DELETE_BENEFIT_TEMPLATE_FAILED = 'DELETE_BENEFIT_TEMPLATE_FAILED';
export type DeleteBenefitTemplateFailureAction = Action<
    typeof DELETE_BENEFIT_TEMPLATE_FAILED,
    string
>;
export const deleteBenefitTemplateFailure = (
    payload: string,
): DeleteBenefitTemplateFailureAction => ({
    type: DELETE_BENEFIT_TEMPLATE_FAILED,
    payload,
});

export type BenefitTemplateActions =
    | ArchiveBenefitTemplateRequestAction
    | ArchiveBenefitTemplateSuccessAction
    | ArchiveBenefitTemplateFailureAction
    | CreateBenefitTemplateRequestAction
    | CreateBenefitTemplateSuccessAction
    | CreateBenefitTemplateFailureAction
    | EditBenefitTemplateRequestAction
    | EditBenefitTemplateSuccessAction
    | EditBenefitTemplateFailureAction
    | CloneBenefitTemplateRequestAction
    | CloneBenefitTemplateSuccessAction
    | CloneBenefitTemplateFailureAction
    | EditBenefitTemplateFilesRequestAction
    | EditBenefitTemplateFilesSuccessAction
    | EditBenefitTemplateFilesFailureAction
    | GetBenefitTemplatesRequestAction
    | GetBenefitTemplatesSuccessAction
    | GetBenefitTemplatesFailureAction
    | GetBenefitTemplateDataRequestAction
    | GetBenefitTemplateDataSuccessAction
    | GetBenefitTemplateDataFailureAction
    | SelectBenefitTemplateRequestAction
    | SelectBenefitTemplateSuccessAction
    | SelectBenefitTemplateFailureAction
    | GetBenefitTemplateRequestAction
    | GetBenefitTemplateSuccessAction
    | GetBenefitTemplateFailureAction
    | GetRagBenefitTemplateSuccessAction
    | GetBenefitTemplateWhenCreatingTemplatedBenefitRequestAction
    | GetBenefitTemplateWhenCreatingTemplatedBenefitSuccessAction
    | GetBenefitTemplateWhenCreatingTemplatedBenefitFailureAction
    | ClearBenefitTemplate
    | ClearSelectedBenefitTemplate
    | SetDateRangeBenefitTemplateDashboardAction
    | DeleteBenefitTemplateRequestAction
    | DeleteBenefitTemplateSuccessAction
    | DeleteBenefitTemplateFailureAction;
