import { pluck, tap, ignoreElements } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { AppEpic } from 'common/epics/appEpic';
import * as authActions from 'features/auth/actions/authActions';
import * as benefitActions from 'features/benefit/actions';
import * as contractActions from 'features/contract/actions/contractActions';
import * as downloadActions from 'features/download/actions/downloadActions';
import * as importActions from 'features/import/actions/importActions';
import * as inviteActions from 'features/invite/actions/inviteActions';
import * as organisationActions from 'features/organisation/actions/organisationActions';
import * as scoreActions from 'features/upload/actions/scoreActions';
import * as evidenceActions from 'features/upload/actions/evidenceActions';
import * as userActions from 'features/user/actions/userActions';
import * as paginationActions from 'features/pagination/actions/paginationActions';
import * as portfolioFilterActions from 'features/portfolioFilter/actions/portfolioFilterAction';

import { showNotification, Notification } from '../helpers/notifications';

export const messageEpicFactory = (): AppEpic => {
    const successEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(
                authActions.ACTIVATE_ACCOUNT_SUCCEED,
                authActions.ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_SUCCEED,
                authActions.ACTIVATE_EXISTING_EXECUTIVE_ADMIN_SUCCEED,
                benefitActions.ARCHIVE_BENEFIT_TEMPLATE_SUCCEED,
                benefitActions.CREATE_BENEFIT_SUCCEED,
                benefitActions.CREATE_BENEFIT_TEMPLATE_SUCCEED,
                benefitActions.DELETE_BENEFIT_SUCCEED,
                benefitActions.EDIT_BENEFIT_TEMPLATE_SUCCEED,
                benefitActions.EDIT_BENEFIT_SUCCEED,
                benefitActions.SELECT_BENEFIT_TEMPLATE_SUCCEED,
                benefitActions.ADD_BENEFIT_ROLE_SUCCEED,
                benefitActions.DELETE_BENEFIT_ROLE_SUCCEED,
                benefitActions.ASSIGN_EMPLOYEES_SUCCEED,
                benefitActions.UNASSIGN_EMPLOYEES_SUCCEED,
                benefitActions.ARCHIVE_BENEFIT_CATEGORY_SUCCEED,
                benefitActions.TOGGLE_MULTIPLE_EMPLOYEES_ROLE_SUCCEED,
                benefitActions.TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_SUCCEED,
                benefitActions.EDIT_BENEFIT_PRIORITY_SUCCEED,
                benefitActions.ADD_BENEFIT_PRIORITY_SUCCEED,
                benefitActions.DELETE_BENEFIT_PRIORITY_SUCCEED,
                benefitActions.EDIT_BENEFIT_TEMPLATE_FILES_SUCCEED,
                benefitActions.DELETE_BENEFIT_CATEGORY_FILTER_SUCCEED,
                benefitActions.CREATE_BENEFIT_NOTE_SUCCEED,
                benefitActions.DELETE_BENEFIT_NOTE_SUCCEED,
                benefitActions.EDIT_BENEFIT_NOTE_SUCCEED,
                benefitActions.DELETE_BENEFIT_NOTE_FILE_SUCCEED,
                benefitActions.ADD_BENEFIT_NOTE_FILE_SUCCEED,
                benefitActions.MIGRATE_BENEFITS_SUCCEED,
                contractActions.ARCHIVE_CONTRACT_CATEGORY_SUCCEED,
                contractActions.ARCHIVE_LOCATION_SUCCEED,
                contractActions.ARCHIVE_DEPARTMENT_SUCCEED,
                contractActions.CREATE_CONTRACT_SUCCEED,
                contractActions.CREATE_FILTER_SUCCEED,
                contractActions.DELETE_CONTRACT_SUCCEED,
                contractActions.EDIT_CONTRACT_SUCCEED,
                contractActions.EDIT_FILTER_SUCCEED,
                contractActions.DELETE_CONTRACT_CATEGORY_FILTER_SUCCEED,
                contractActions.DELETE_DEPARTMENT_FILTER_SUCCEED,
                contractActions.DELETE_LOCATION_FILTER_SUCCEED,
                contractActions.ADD_CONTRACT_ROLE_SUCCEED,
                contractActions.DELETE_CONTRACT_ROLE_SUCCEED,
                contractActions.GET_CONTRACT_FAILED,
                contractActions.ADD_CONTRACT_PRIORITY_SUCCEED,
                contractActions.EDIT_CONTRACT_PRIORITY_SUCCEED,
                contractActions.DELETE_CONTRACT_PRIORITY_SUCCEED,
                contractActions.TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_SUCCEED,
                contractActions.CREATE_CONTRACT_NOTE_SUCCEED,
                contractActions.DELETE_CONTRACT_NOTE_SUCCEED,
                contractActions.EDIT_CONTRACT_NOTE_SUCCEED,
                contractActions.DELETE_CONTRACT_NOTE_FILE_SUCCEED,
                contractActions.ADD_CONTRACT_NOTE_FILE_SUCCEED,
                inviteActions.INVITE_EMPLOYEE_SUCCEED,
                inviteActions.INVITE_POTENTIAL_EXECUTIVES_SUCCEED,
                inviteActions.INVITE_EMPLOYEE_WITH_ROLE_SUCCEED,
                inviteActions.EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_SUCCEED,
                inviteActions.RESEND_ROLES_EMAIL_INVITATION_SUCCEED,
                inviteActions.RESEND_EMPLOYEE_EMAIL_INVITATION_SUCCEED,
                inviteActions.RESEND_EMPLOYEE_EXPIRED_INVITATION_SUCCEED,
                inviteActions.ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED,
                inviteActions.DELETE_EMPLOYEE_PENDING_INVITATION_SUCCEED,
                inviteActions.DELETE_EMPLOYEE_EXPIRED_INVITATION_SUCCEED,
                organisationActions.EDIT_ORGANISATION_NAME_SUCCEED,
                organisationActions.REMOVE_EXECUTIVE_SUCCEED,
                evidenceActions.APPROVE_EVIDENCE_SUCCEED,
                evidenceActions.DECLINE_EVIDENCE_SUCCEED,
                evidenceActions.SUBMIT_FOR_APPROVAL_SUCCEED,
                evidenceActions.CREATE_EVIDENCE_SUCCEED,
                evidenceActions.UPDATE_EVIDENCE_SUCCEED,
                evidenceActions.DELETE_EVIDENCE_FILE_SUCCEED,
                scoreActions.APPROVE_SCORE_SUCCEED,
                scoreActions.DECLINE_SCORE_SUCCEED,
                scoreActions.CREATE_SCORE_SUCCEED,
                scoreActions.UPDATE_SCORE_SUCCEED,
                scoreActions.DELETE_SCORE_FILE_SUCCEED,
                scoreActions.SUBMIT_SCORE_FOR_APPROVAL_SUCCEED,
                importActions.IMPORT_BENEFITS_SUCCEED,
                importActions.IMPORT_CONTRACTS_SUCCEED,
                importActions.IMPORT_BENEFIT_TEMPLATES_SUCCEED,
                userActions.ARCHIVE_EMPLOYEE_SUCCEED,
                userActions.CONFIRM_NEW_PASSWORD_SUCCEED,
                userActions.CREATE_ACCOUNT_SUCCEED,
                userActions.EDIT_EMPLOYEE_SUCCEED,
                userActions.UPDATE_USER_DATA_SUCCEED,
                userActions.CHANGE_USER_PASSWORD_SUCCEED,
                userActions.SEND_RESET_PASSWORD_SUCCEED,
                userActions.RESET_PASSWORD_CHANGE_SUCCEED,
                userActions.UPDATE_USER_EMAIL_SUCCEED,
                userActions.PURCHASE_SUBSCRIPTION_SUCCEED,
                userActions.SAVE_INVOICE_DATA_SUCCEED,
                downloadActions.GET_CONTRACTS_CSV_SUCCEED,
                downloadActions.GET_BENEFITS_CSV_SUCCEED,
                downloadActions.DOWNLOAD_INVOICE_PDF_SUCCEED,
                portfolioFilterActions.CREATE_PORTFOLIO_FILTER_SUCCEED,
                portfolioFilterActions.EDIT_PORTFOLIO_FILTER_SUCCEED,
                portfolioFilterActions.DELETE_PORTFOLIO_FILTER_SUCCEED,
                organisationActions.EDIT_ORGANISATION_SUCCEED,
            ),
            pluck('meta'),
            tap((text: string) => showNotification({ type: Notification.Success, text })),
            ignoreElements(),
        );

    const errorEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(
                authActions.LOG_IN_FAILED,
                authActions.VERIFY_NEW_EMPLOYEE_FAILED,
                authActions.VERIFY_NEW_EXECUTIVE_FAILED,
                authActions.VERIFY_EXISTING_EXECUTIVE_FAILED,
                authActions.ACTIVATE_ACCOUNT_FAILED,
                authActions.ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_FAILED,
                authActions.ACTIVATE_EXISTING_EXECUTIVE_ADMIN_FAILED,
                benefitActions.ARCHIVE_BENEFIT_TEMPLATE_FAILED,
                benefitActions.CREATE_BENEFIT_FAILED,
                benefitActions.CREATE_BENEFIT_TEMPLATE_FAILED,
                benefitActions.DELETE_BENEFIT_FAILED,
                benefitActions.EDIT_BENEFIT_FAILED,
                benefitActions.EDIT_BENEFIT_TEMPLATE_FAILED,
                benefitActions.SELECT_BENEFIT_TEMPLATE_FAILED,
                benefitActions.ADD_BENEFIT_ROLE_FAILED,
                benefitActions.DELETE_BENEFIT_ROLE_FAILED,
                benefitActions.ASSIGN_EMPLOYEES_FAILED,
                benefitActions.UNASSIGN_EMPLOYEES_FAILED,
                benefitActions.ARCHIVE_BENEFIT_CATEGORY_FAILED,
                benefitActions.CHANGE_BENEFIT_STATUS_FAILED,
                benefitActions.TOGGLE_MULTIPLE_EMPLOYEES_ROLE_FAILED,
                benefitActions.TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_FAILED,
                benefitActions.ADD_BENEFIT_PRIORITY_FAILED,
                benefitActions.EDIT_BENEFIT_PRIORITY_FAILED,
                benefitActions.EDIT_BENEFIT_TEMPLATE_FILES_FAILED,
                benefitActions.CREATE_BENEFIT_NOTE_FAILED,
                benefitActions.DELETE_BENEFIT_NOTE_FAILED,
                benefitActions.EDIT_BENEFIT_NOTE_FAILED,
                benefitActions.DELETE_BENEFIT_NOTE_FILE_FAILED,
                benefitActions.ADD_BENEFIT_NOTE_FILE_FAILED,
                benefitActions.MIGRATE_BENEFITS_FAILED,
                contractActions.ARCHIVE_CONTRACT_CATEGORY_FAILED,
                contractActions.ARCHIVE_LOCATION_FAILED,
                contractActions.ARCHIVE_DEPARTMENT_FAILED,
                contractActions.CREATE_CONTRACT_FAILED,
                contractActions.CREATE_FILTER_FAILED,
                contractActions.DELETE_CONTRACT_FAILED,
                contractActions.EDIT_CONTRACT_FAILED,
                contractActions.EDIT_FILTER_FAILED,
                contractActions.ADD_CONTRACT_ROLE_FAILED,
                contractActions.DELETE_CONTRACT_ROLE_FAILED,
                contractActions.ADD_CONTRACT_PRIORITY_FAILED,
                contractActions.EDIT_CONTRACT_PRIORITY_FAILED,
                contractActions.TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_FAILED,
                contractActions.CREATE_CONTRACT_NOTE_FAILED,
                contractActions.DELETE_CONTRACT_NOTE_FAILED,
                contractActions.EDIT_CONTRACT_NOTE_FAILED,
                contractActions.DELETE_CONTRACT_NOTE_FILE_FAILED,
                contractActions.ADD_CONTRACT_NOTE_FILE_FAILED,
                downloadActions.GET_CONTRACTS_CSV_FAILED,
                downloadActions.GET_BENEFITS_CSV_FAILED,
                downloadActions.DOWNLOAD_INVOICE_PDF_FAILED,
                inviteActions.INVITE_EMPLOYEE_FAILED,
                inviteActions.INVITE_POTENTIAL_EXECUTIVES_FAILED,
                inviteActions.INVITE_EMPLOYEE_WITH_ROLE_FAILED,
                inviteActions.DELETE_PENDING_ROLE_INVITE_FAILED,
                inviteActions.RESEND_ROLES_EMAIL_INVITATION_FAILED,
                inviteActions.RESEND_EMPLOYEE_EMAIL_INVITATION_FAILED,
                inviteActions.RESEND_EMPLOYEE_EXPIRED_INVITATION_FAILED,
                inviteActions.INVITE_POTENTIAL_EXECUTIVES_FAILED,
                organisationActions.EDIT_ORGANISATION_NAME_FAILED,
                organisationActions.REMOVE_EXECUTIVE_FAILED,
                evidenceActions.APPROVE_EVIDENCE_FAILED,
                evidenceActions.DECLINE_EVIDENCE_FAILED,
                evidenceActions.CREATE_EVIDENCE_FAILED,
                evidenceActions.UPDATE_EVIDENCE_FAILED,
                evidenceActions.GET_MY_EVIDENCE_FILES_FAILED,
                evidenceActions.SUBMIT_FOR_APPROVAL_FAILED,
                evidenceActions.SUBMIT_FOR_APPROVAL_FAILED,
                evidenceActions.DELETE_EVIDENCE_FILE_FAILED,
                scoreActions.APPROVE_SCORE_FAILED,
                scoreActions.DECLINE_SCORE_FAILED,
                scoreActions.SUBMIT_SCORE_FOR_APPROVAL_FAILED,
                scoreActions.CREATE_SCORE_FAILED,
                scoreActions.UPDATE_SCORE_FAILED,
                scoreActions.DELETE_SCORE_FILE_FAILED,
                importActions.IMPORT_BENEFITS_FAILED,
                importActions.IMPORT_CONTRACTS_FAILED,
                importActions.IMPORT_BENEFIT_TEMPLATES_FAILED,
                userActions.EDIT_EMPLOYEE_FAILED,
                userActions.CHANGE_USER_PASSWORD_FAILED,
                userActions.SEND_RESET_PASSWORD_FAILED,
                userActions.VERIFY_PASSWORD_RESET_TOKEN_FAILED,
                userActions.RESET_PASSWORD_CHANGE_FAILED,
                userActions.UPDATE_USER_EMAIL_FAILED,
                userActions.PURCHASE_SUBSCRIPTION_FAILED,
                userActions.SAVE_INVOICE_DATA_FAILED,
                paginationActions.PAGINATION_FAILED,
                paginationActions.AUTOCOMPLETE_FAILED,
                paginationActions.GET_INITIAL_VALUE_DATA_FAILED,
                portfolioFilterActions.CREATE_PORTFOLIO_FILTER_FAILED,
                portfolioFilterActions.EDIT_PORTFOLIO_FILTER_FAILED,
            ),
            pluck('payload', 'message'),
            tap((text: string) => showNotification({ text })),
            ignoreElements(),
        );

    return combineEpics(successEpic, errorEpic);
};
