import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { AppState } from 'common/appState';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { Filters } from 'features/contract/models/contractModels';
import { FullPageSpinner } from 'common/components';
import {
    getDepartments,
    getFetchingFiltersStatus,
} from 'features/contract/selectors/contractSelectors';
import { getDepartmentsRequest } from 'features/contract/actions/contractActions';
import { getUserOrganisationId } from 'features/user/selectors/userSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import { getPageNumber } from 'common/selectors/routeSelectors';

import { InviteEmployee as InviteEmployeeComponent } from '../components/InviteEmployee';
import {
    inviteEmployeeRequest,
    getEmployeesPendingInvitationsSuccess,
    getEmployeesExpiredInvitationsSuccess,
    resendEmployeeEmailInvitationRequest,
    resendEmployeeExpiredInvitationRequest,
    deleteEmployeesPendingInvitationsRequest,
    deleteEmployeesExpiredInvitationRequest,
} from '../actions/inviteActions';
import {
    getInviteFetchingStatus,
    getEmployeePendingInvitations,
    getEmployeeExpiredInvitations,
} from '../selectors/inviteSelectors';
import { EmployeeInvitation } from '../models/inviteModel';

export interface InviteEmployeeStateProps {
    departments: Filters[];
    isFetchingFilters: boolean;
    isFetchingInvite: boolean;
    userOrganisation: number;
    pendingInvitations: EmployeeInvitation[];
    expiredInvitations: EmployeeInvitation[];
    path: string;
    pageNumber?: number;
}

interface InviteEmployeeDispatchProps {
    getDepartmentsRequest: typeof getDepartmentsRequest;
    resendEmployeeEmailInvitationRequest: typeof resendEmployeeEmailInvitationRequest;
    resendEmployeeExpiredInvitationRequest: typeof resendEmployeeExpiredInvitationRequest;
    deleteEmployeesPendingInvitationsRequest: typeof deleteEmployeesPendingInvitationsRequest;
    deleteEmployeesExpiredInvitationRequest: typeof deleteEmployeesExpiredInvitationRequest;
    inviteEmployeeRequest: typeof inviteEmployeeRequest;
    redirectToUrl: typeof redirectToUrl;
}

export type InviteEmployeeProps = InviteEmployeeDispatchProps & InviteEmployeeStateProps;

export class InviteEmployee extends React.Component<InviteEmployeeProps> {
    public componentDidMount(): void {
        this.props.getDepartmentsRequest();
    }

    public render(): JSX.Element {
        return this.props.isFetchingFilters ? (
            <FullPageSpinner />
        ) : (
            <InviteEmployeeComponent
                {...this.props}
                pendingInvitations={this.props.pendingInvitations}
                expiredInvitations={this.props.expiredInvitations}
                pendingPaginationView={PaginationView.EmployeePendingInvitations}
                expiredPaginationView={PaginationView.EmployeeExpiredInvitations}
                resendPendingInvitation={this.props.resendEmployeeEmailInvitationRequest}
                resendExpiredInvitation={this.props.resendEmployeeExpiredInvitationRequest}
                deletePendingInvitation={this.props.deleteEmployeesPendingInvitationsRequest}
                deleteExpiredInvitation={this.props.deleteEmployeesExpiredInvitationRequest}
                pendingInvitationsPaginationSuccessCallback={getEmployeesPendingInvitationsSuccess}
                expiredInvitationsPaginationSuccessCallback={getEmployeesExpiredInvitationsSuccess}
                redirectToUrl={this.props.redirectToUrl}
                path={this.props.path}
                isFetching={this.props.isFetchingInvite}
                isEmployee
                isTenant
            />
        );
    }
}

const mapStateToProps = (
    state: AppState,
    routeParams: RouteComponentProps,
): InviteEmployeeStateProps => ({
    departments: getDepartments(state),
    userOrganisation: getUserOrganisationId(state),
    isFetchingInvite: getInviteFetchingStatus(state),
    isFetchingFilters: getFetchingFiltersStatus(state),
    pendingInvitations: getEmployeePendingInvitations(state),
    expiredInvitations: getEmployeeExpiredInvitations(state),
    path: routeParams.match.path.slice(0, routeParams.match.path.lastIndexOf('/')),
    pageNumber: getPageNumber(routeParams),
});

export const InviteEmployeeContainer = connect(mapStateToProps, {
    getDepartmentsRequest,
    resendEmployeeEmailInvitationRequest,
    resendEmployeeExpiredInvitationRequest,
    deleteEmployeesPendingInvitationsRequest,
    deleteEmployeesExpiredInvitationRequest,
    inviteEmployeeRequest,
    redirectToUrl,
})(InviteEmployee);
