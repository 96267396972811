import * as React from 'react';

import { SubmitButton } from 'common/components';
import { CenteringWrapper, Header, MarginLink } from 'common/components/styled';
import {
    Antd3Icon,
    Antd3Form,
    StyledAntd3Form,
    Antd3FormProps,
    Antd3Input,
} from 'common/components/deprecated/antd3';

import { ResetPasswordContainerProps } from '../containers/ResetPasswordContainer';
import { UserPaths } from '../models/userModel';

type ResetPasswordRequestProps = ResetPasswordContainerProps & Antd3FormProps;

class ResetPasswordRequest extends React.Component<ResetPasswordRequestProps> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields(
            (error, { email }) => !error && this.props.sendResetPasswordRequest(email),
        );
    };

    public render(): JSX.Element {
        const { form, isProcessing } = this.props;

        return (
            <CenteringWrapper>
                <StyledAntd3Form onSubmit={this.handleSubmit}>
                    <Header>Reset Password</Header>
                    <Antd3Form.Item>
                        <Antd3Input
                            form={form}
                            id="email"
                            getFieldDecoratorOptions={{
                                rules: [
                                    { required: true, message: 'Please enter your email address' },
                                ],
                            }}
                            disabled={isProcessing}
                            prefix={<Antd3Icon type="mail" />}
                            placeholder="Email Address"
                        />
                    </Antd3Form.Item>
                    <SubmitButton value="Reset Password" loading={isProcessing} />
                </StyledAntd3Form>
                <MarginLink to={UserPaths.SignIn}>Return to login page</MarginLink>
            </CenteringWrapper>
        );
    }
}

export const ResetPasswordRequestForm = Antd3Form.create({})(ResetPasswordRequest);
