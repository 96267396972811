import * as React from 'react';
import { connect } from 'react-redux';

import { isTenant } from 'features/user/selectors/userSelectors';
import { saveFilters, clearFilters } from 'features/pagination/actions/paginationActions';

import { DownloadContractsFilter as DownloadContractsFilterComponent } from '../components/DownloadContractsFilter';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { AppState } from 'common/appState';
import { getFilterFormInitialValues } from 'features/pagination/selectors/paginationSelectors';
import { RouteParams } from 'common/selectors/routeSelectors';

interface DownloadContractsFilterStateProps {
    tenant: boolean;
    formInitial: PaginationFilters;
}

interface DownloadContractsFilterDispatchProps {
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
}

export type DownloadContractsFilterContainerProps = DownloadContractsFilterStateProps &
    DownloadContractsFilterDispatchProps;

export class DownloadContractsFilter extends React.Component<DownloadContractsFilterContainerProps> {
    public render(): JSX.Element {
        return <DownloadContractsFilterComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams) => ({
    tenant: isTenant(state),
    formInitial: getFilterFormInitialValues(state, ownProps),
});

export const DownloadContractsFilterContainer = connect(mapStateToProps, {
    saveFilters,
    clearFilters,
})(DownloadContractsFilter);
