import { PortfolioFilterIds, PortfolioFiltersEnum } from '../models/portfolioFilterModel';

export const getPorftolioFiltersIdsChange = (
    depth: PortfolioFiltersEnum,
    id: number,
    portfolioFiltersIds: PortfolioFilterIds,
): PortfolioFilterIds => {
    switch (depth) {
        case PortfolioFiltersEnum.SubProgram: {
            return {
                subProgramId: id,
                mainProjectId: -1,
                subProjectId: -1,
            };
        }
        case PortfolioFiltersEnum.MainProject: {
            return {
                ...portfolioFiltersIds,
                mainProjectId: id,
                subProjectId: -1,
            };
        }
        default:
            return {
                ...portfolioFiltersIds,
                subProjectId: id,
            };
    }
};
