import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { AppState } from 'common/appState';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getUserOrganisationId, isTenant } from 'features/user/selectors/userSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import { getPageNumber } from 'common/selectors/routeSelectors';

import { SupplierInviteUsers as SupplierInviteUsersComponent } from '../components/SupplierInviteUsers';
import {
    inviteEmployeeRequest,
    getEmployeesPendingInvitationsSuccess,
    getEmployeesExpiredInvitationsSuccess,
    resendEmployeeEmailInvitationRequest,
    resendEmployeeExpiredInvitationRequest,
    deleteEmployeesPendingInvitationsRequest,
    deleteEmployeesExpiredInvitationRequest,
} from '../actions/inviteActions';
import {
    getInviteFetchingStatus,
    getEmployeePendingInvitations,
    getEmployeeExpiredInvitations,
} from '../selectors/inviteSelectors';
import { EmployeeInvitation } from '../models/inviteModel';

export interface SupplierInviteUsersStateProps {
    isFetchingInvite: boolean;
    userOrganisation: number;
    pendingInvitations: EmployeeInvitation[];
    expiredInvitations: EmployeeInvitation[];
    path: string;
    pageNumber?: number;
    isTenant: boolean;
}

interface SupplierInviteUsersContainerDispatchProps {
    resendEmployeeEmailInvitationRequest: typeof resendEmployeeEmailInvitationRequest;
    resendEmployeeExpiredInvitationRequest: typeof resendEmployeeExpiredInvitationRequest;
    deleteEmployeesPendingInvitationsRequest: typeof deleteEmployeesPendingInvitationsRequest;
    deleteEmployeesExpiredInvitationRequest: typeof deleteEmployeesExpiredInvitationRequest;
    inviteEmployeeRequest: typeof inviteEmployeeRequest;
    redirectToUrl: typeof redirectToUrl;
}

export type SupplierInviteUsersContainerProps = SupplierInviteUsersContainerDispatchProps &
    SupplierInviteUsersStateProps;

class SupplierInviteUsers extends React.Component<SupplierInviteUsersContainerProps> {
    public render(): JSX.Element {
        return (
            <SupplierInviteUsersComponent
                {...this.props}
                pendingInvitations={this.props.pendingInvitations}
                expiredInvitations={this.props.expiredInvitations}
                pendingPaginationView={PaginationView.EmployeePendingInvitations}
                expiredPaginationView={PaginationView.EmployeeExpiredInvitations}
                resendPendingInvitation={this.props.resendEmployeeEmailInvitationRequest}
                resendExpiredInvitation={this.props.resendEmployeeExpiredInvitationRequest}
                deletePendingInvitation={this.props.deleteEmployeesPendingInvitationsRequest}
                deleteExpiredInvitation={this.props.deleteEmployeesExpiredInvitationRequest}
                pendingInvitationsPaginationSuccessCallback={getEmployeesPendingInvitationsSuccess}
                expiredInvitationsPaginationSuccessCallback={getEmployeesExpiredInvitationsSuccess}
                redirectToUrl={this.props.redirectToUrl}
                path={this.props.path}
                isEmployee
            />
        );
    }
}

const mapStateToProps = (
    state: AppState,
    routeParams: RouteComponentProps,
): SupplierInviteUsersStateProps => ({
    userOrganisation: getUserOrganisationId(state),
    isFetchingInvite: getInviteFetchingStatus(state),
    pendingInvitations: getEmployeePendingInvitations(state),
    expiredInvitations: getEmployeeExpiredInvitations(state),
    path: routeParams.match.path.slice(0, routeParams.match.path.lastIndexOf('/')),
    pageNumber: getPageNumber(routeParams),
    isTenant: isTenant(state),
});

export const SupplierInviteUsersContainer = connect(mapStateToProps, {
    resendEmployeeEmailInvitationRequest,
    resendEmployeeExpiredInvitationRequest,
    deleteEmployeesPendingInvitationsRequest,
    deleteEmployeesExpiredInvitationRequest,
    inviteEmployeeRequest,
    redirectToUrl,
})(SupplierInviteUsers);
