import { Radio, RadioProps as AntdRadioProps } from 'antd';

import { Antd3FormElementProps } from './Antd3Form';

export const Antd3Radio = ({
    form,
    id,
    required = false,
    getFieldDecoratorOptions,
    children,
    ...inputProps
}: AntdRadioProps & Antd3FormElementProps) => (
    <>
        {form?.getFieldDecorator
            ? form.getFieldDecorator(
                  id,
                  getFieldDecoratorOptions,
              )(children || <Radio {...inputProps} />)
            : children || <Radio {...inputProps} />}
    </>
);
