import styled from 'styled-components';
import { Tabs as AntdTabs } from 'antd';

import { breakpoints, colors } from 'theme/variables';

export const Tabs = styled(AntdTabs)<{ noBottomMargin?: boolean }>(
    ({ noBottomMargin }) => `
    width: 100%;

    ${breakpoints.medium} {
        .ant-tabs-nav {
            display: flex;
            justify-content: center;
        }
    }

    .ant-tabs-nav {
        margin-bottom: ${noBottomMargin ? 0 : '50px'};
    }

    form {
        margin: 0 auto;
    }
`,
);

export const SmallTabs = styled(Tabs)`
    && .ant-tabs-nav {
        margin-bottom: 0;
        background-color: ${colors.lightGray};

        .ant-tabs-tab {
            padding: 8px 0;
            font-size: 12px;
        }
    }
`;
