import * as zxcvbn from 'zxcvbn';

export interface PasswordStrength {
    passwordStrength: number;
    status: 'success' | 'active' | 'exception' | undefined;
}

export const getPercentagePasswordStrength = (password: string): PasswordStrength => {
    const score = zxcvbn(password).score;

    switch (score) {
        case 1:
            return {
                passwordStrength: 25,
                status: 'exception',
            };
        case 2:
            return {
                passwordStrength: 50,
                status: 'active',
            };
        case 3:
            return {
                passwordStrength: 75,
                status: 'active',
            };
        case 4:
            return {
                passwordStrength: 100,
                status: 'success',
            };
        default:
            return {
                passwordStrength: 0,
                status: 'exception',
            };
    }
};
