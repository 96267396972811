import { DatePicker as AntdDatePicker, DatePickerProps, Form } from 'antd';
import { FormItemProps } from '@ant-design/compatible/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import styled from 'styled-components';

import { InputLabelWithTooltip } from './components/InputLabelWithTooltip';
import { FormInputProps } from './models';

const StyledFormItem = styled(Form.Item)<{ nobottommargin: number }>(
    ({ nobottommargin }) => `
        margin-bottom: ${nobottommargin ? 0 : '1rem'};
`,
);

interface Props
    extends Omit<FormItemProps, 'label'>,
        Omit<DatePickerProps, 'label' | 'name'>,
        FormInputProps {
    name: NamePath;
    validationLabel?: string;
    noBottomMargin?: boolean;
}

export const DatePicker = ({
    name,
    placeholder,
    label,
    required = true,
    requiredMessage,
    disabled = false,
    autoComplete,
    tooltip,
    help,
    validateStatus,
    initialValue,
    noBottomMargin,
    disabledDate,
}: Props) => (
    <StyledFormItem
        name={name}
        label={
            tooltip ? (
                <InputLabelWithTooltip label={label || placeholder} tooltip={tooltip} />
            ) : (
                label || placeholder
            )
        }
        help={help}
        validateStatus={validateStatus}
        rules={[
            {
                required,
                message: requiredMessage || `${label || placeholder} is required`,
            },
        ]}
        initialValue={initialValue}
        nobottommargin={noBottomMargin ? 1 : 0}
    >
        <AntdDatePicker
            size="large"
            placeholder={placeholder || label}
            disabled={disabled}
            autoComplete={autoComplete}
            disabledDate={disabledDate}
        />
    </StyledFormItem>
);
