import { AppState } from 'common/appState';

export const getSubPrograms = (state: AppState) =>
    state.portfolioFilter.portfolioFilters.subPrograms;
export const getMainProjects = (state: AppState) =>
    state.portfolioFilter.portfolioFilters.mainProjects;
export const getSubProjects = (state: AppState) =>
    state.portfolioFilter.portfolioFilters.subProjects;
export const getRootPortfolioFilterId = (state: AppState) =>
    state.portfolioFilter.rootPortfolioFilter.id;
