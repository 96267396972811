import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Profile } from 'features/user/models/userModel';
import {
    archiveEmployeeRequest,
    editEmployeeRequest,
    openForm,
    closeForm,
    deleteEmployeeRequest,
} from 'features/user/actions/userActions';
import {
    getAllEmployees,
    getEmployeeFormVisibility,
    getFetchingUserStatus,
} from 'features/user/selectors/userSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import { RouteParams, getPageNumber } from 'common/selectors/routeSelectors';
import { AppState } from 'common/appState';

import { EditEmployees as EditEmployeeComponent } from '../components/EditEmployees';

interface EditEmployeeStateProps {
    employees: Profile[];
    formVisible: boolean;
    isFetching: boolean;
    pageNumber?: number;
}

interface EditEmployeeContainerDispatchProps {
    deleteEmployeeRequest: typeof deleteEmployeeRequest;
    archiveEmployeeRequest: typeof archiveEmployeeRequest;
    editEmployeeRequest: typeof editEmployeeRequest;
    redirectToUrl: typeof redirectToUrl;
    openForm: typeof openForm;
    closeForm: typeof closeForm;
}

export type EditEmployeeProps = EditEmployeeStateProps &
    EditEmployeeContainerDispatchProps &
    RouteParams;

class EditEmployee extends React.Component<EditEmployeeProps> {
    public render(): JSX.Element {
        return <EditEmployeeComponent {...this.props} />;
    }
}

const mapStateToProps = createStructuredSelector<AppState, RouteParams, EditEmployeeStateProps>({
    employees: getAllEmployees,
    formVisible: getEmployeeFormVisibility,
    isFetching: getFetchingUserStatus,
    pageNumber: (_, ownProps) => getPageNumber(ownProps),
});

export const EditEmployeeContainer = connect(mapStateToProps, {
    deleteEmployeeRequest,
    archiveEmployeeRequest,
    editEmployeeRequest,
    redirectToUrl,
    openForm,
    closeForm,
})(EditEmployee);
