import { Button, Form, Tag } from 'antd';
import { useDispatch } from 'react-redux';

import { actionPropsByAction } from 'features/upload/helpers';
import { BenefitChangeSuggestionsAcceptanceFormBenefitPriority } from 'features/upload/models/uploadModels';
import { addBenefitPriorityRequest, editBenefitPriorityRequest } from 'features/benefit/actions';
import * as S from 'features/upload/components/BenefitChangeSuggestionsFormItem/shared/styles';

type Props = {
    name: number;
    contractId: number;
    benefitId: number;
};

export const BenefitPrioritySuggestionAcceptanceCard = ({ name, contractId, benefitId }: Props) => {
    const dispatch = useDispatch();
    const benefitPriority = Form.useWatch(['benefitPriorities', name]) as
        | BenefitChangeSuggestionsAcceptanceFormBenefitPriority
        | undefined;

    if (!benefitPriority) {
        return null;
    }

    const approveSuggestion = () => {
        switch (benefitPriority.action) {
            case 'create':
                dispatch(
                    addBenefitPriorityRequest({
                        contractId,
                        benefitId,
                        priority: {
                            priority: benefitPriority.priority,
                            value: benefitPriority.value,
                            name: benefitPriority.name,
                        },
                    }),
                );
                break;
            case 'update':
                if (benefitPriority.id) {
                    dispatch(
                        editBenefitPriorityRequest({
                            contractId,
                            benefitId,
                            priorityId: benefitPriority.id,
                            value: benefitPriority.value,
                        }),
                    );
                }
                break;
            default:
        }
    };

    const isValueUpdated =
        benefitPriority.value && benefitPriority.prevValue !== benefitPriority.value;
    const isUpdated = benefitPriority.action === 'update' && isValueUpdated;

    const actionProps = benefitPriority.action && actionPropsByAction[benefitPriority.action];
    const displayChange = actionProps && (benefitPriority.action !== 'update' || isUpdated);

    return (
        <S.SuggestionWrapper color={displayChange ? actionProps.color : undefined}>
            {displayChange && (
                <S.ChangeInfo>
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {isUpdated && (
                            <span>{` | Previous value: "${benefitPriority.prevValue}"`}</span>
                        )}
                    </Tag>
                </S.ChangeInfo>
            )}
            <S.InputsWrapper direction="horizontal" align="start">
                <S.SuggestionValue topMargin="2px">{`${benefitPriority.name}: ${benefitPriority.value}`}</S.SuggestionValue>
                {benefitPriority.action && displayChange && (
                    <Button onClick={approveSuggestion} type="primary">
                        Approve
                    </Button>
                )}
            </S.InputsWrapper>
        </S.SuggestionWrapper>
    );
};
