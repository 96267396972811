import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { Input, InputProps as AntdInputProps } from 'antd';

import { Antd3FormElementProps } from './Antd3Form';

export interface Antd3InputProps {
    form: WrappedFormUtils;
    id: string;
    isTemporaryId?: boolean;
    placeholder?: string;
    disabled?: boolean;
    hidden?: boolean;
    required?: boolean;
    requiredMessage?: string;
    label?: string;
    tooltip?: string;
    value?: string;
    onInputChange?: (id: string, inputName: string, value: string | number | Date) => void;
}

export const Antd3Input = ({
    form,
    id,
    required = false,
    getFieldDecoratorOptions,
    ...inputProps
}: AntdInputProps & Antd3FormElementProps) => (
    <>
        {form?.getFieldDecorator ? (
            form.getFieldDecorator(id, getFieldDecoratorOptions)(<Input {...inputProps} />)
        ) : (
            <Input {...inputProps} />
        )}
    </>
);
