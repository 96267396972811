import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { Contract } from 'features/contract/models/contractModels';
import { FullPageSpinner } from 'common/components';
import { getAllContractsSuccess } from 'features/contract/actions/contractActions';
import {
    getFetchingFiltersStatus,
    getFilteringContractsStatus,
    getContracts,
} from 'features/contract/selectors/contractSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import { getFetchingDownloadStatus } from 'features/download/selectors/downloadSelectors';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { isTenant } from 'features/user/selectors/userSelectors';

import { DownloadContracts as DownloadContractsComponent } from '../components/DownloadContracts';
import { getContractsCSVRequest } from '../actions/downloadActions';

export interface DownloadContractsStateProps {
    isFetching: boolean;
    isFetchingCSV: boolean;
    isFiltered: boolean;
    contracts: Contract[];
    tenant: boolean;
    filter?: PaginationFilters;
}

interface DownloadContractsContainerDispatchProps {
    getContractsCSVRequest: typeof getContractsCSVRequest;
    redirectToUrl: typeof redirectToUrl;
    getAllContractsSuccess: typeof getAllContractsSuccess;
    autocompleteRequest: typeof autocompleteRequest;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
}

export type DownloadContractsProps = DownloadContractsStateProps &
    DownloadContractsContainerDispatchProps;

class DownloadContracts extends React.Component<DownloadContractsProps> {
    public componentDidMount(): void {
        const { contracts, isFiltered } = this.props;

        if (isFiltered) {
            this.props.getAllContractsSuccess(contracts);
        }
    }

    public render(): JSX.Element {
        return this.props.isFetching ? (
            <FullPageSpinner />
        ) : (
            <DownloadContractsComponent {...this.props} redirect={this.props.redirectToUrl} />
        );
    }
}

const mapStateToProps = (state: AppState): DownloadContractsStateProps => ({
    isFetching: getFetchingFiltersStatus(state),
    isFetchingCSV: getFetchingDownloadStatus(state),
    isFiltered: getFilteringContractsStatus(state),
    contracts: getContracts(state),
    tenant: isTenant(state),
    filter: getFilterParams(state, PaginationView.DownloadContracts),
});

export const DownloadContractsContainer = connect(mapStateToProps, {
    getContractsCSVRequest,
    getAllContractsSuccess,
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
})(DownloadContracts);
