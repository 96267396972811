import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { Auth as AuthComponent } from '../components/Auth';
import { logInRequest } from '../actions/authActions';
import { isAuthProcessing } from '../selectors/authSelectors';

interface AuthContainerDispatchProps {
    logInRequest: typeof logInRequest;
}

interface AuthContainerStateProps {
    isProcessing: boolean;
}

export type AuthContainerProps = AuthContainerStateProps & AuthContainerDispatchProps;

const Auth = (props: AuthContainerProps): JSX.Element => <AuthComponent {...props} />;

const mapStateToProps = createSelector(
    isAuthProcessing,
    (isProcessing): AuthContainerStateProps => ({
        isProcessing,
    }),
);

export const AuthContainer = connect(mapStateToProps, { logInRequest })(Auth);
