import * as React from 'react';
import { Form, FormItemProps, Select as AntdSelect, SelectProps } from 'antd';

import { FormInputProps } from './models';
import { InputLabelWithTooltip } from './components/InputLabelWithTooltip';

type Props = FormItemProps &
    SelectProps &
    FormInputProps & {
        allowClear?: boolean;
        label: string;
    };

export const Select: React.FunctionComponent<Props> = ({
    name,
    label,
    required = true,
    requiredMessage,
    disabled = false,
    tooltip,
    help,
    validateStatus,
    options,
    showSearch = true,
    allowClear,
    initialValue,
    onChange,
    mode,
}) => (
    <Form.Item
        name={name}
        label={tooltip ? <InputLabelWithTooltip label={label} tooltip={tooltip} /> : label}
        help={help}
        validateStatus={validateStatus}
        rules={[{ required, message: requiredMessage || `${label} is required` }]}
        initialValue={initialValue}
    >
        <AntdSelect
            showSearch={showSearch}
            optionFilterProp="children"
            size="large"
            disabled={disabled}
            allowClear={allowClear}
            onChange={onChange}
            mode={mode}
        >
            {options?.map((option, i) => {
                const optionId = option.id || option.value || option.label || `option-${i}`;

                return (
                    <AntdSelect.Option key={optionId} value={optionId}>
                        {option.label || option.value || option.id}
                    </AntdSelect.Option>
                );
            })}
        </AntdSelect>
    </Form.Item>
);
