import * as React from 'react';
import { Input as AntdInput } from 'antd';

import { Antd3Form, Antd3InputNumber, Antd3InputProps } from '../antd3';

interface Props extends Antd3InputProps {
    isPassword?: boolean;
    type?: string;
    typeMessage?: string;
    initialValue?: string | number;
    validator?: (_rule: any, value: string, callback: (msg?: string) => void) => void;
    autoComplete?: string;
    onChange?: (e: React.FormEvent) => void;
    name?: string;
}

export const ModalInput = ({
    form,
    id,
    isPassword,
    requiredMessage,
    placeholder,
    label,
    initialValue,
    type = 'string',
    required = true,
    disabled = false,
    validator,
    autoComplete,
    onChange,
    name,
}: Props) => (
    <Antd3Form.Item label={label || placeholder}>
        {form.getFieldDecorator(id, {
            initialValue,
            rules: [
                type === 'email'
                    ? { type, message: 'This is not a valid email address' }
                    : { type },
                { required, message: requiredMessage || `${label || placeholder} is required` },
                ...(validator ? [{ validator }] : []),
            ],
        })(
            type === 'number' ? (
                <Antd3InputNumber
                    name={name}
                    size="large"
                    placeholder={placeholder || label}
                    disabled={disabled}
                />
            ) : (
                <AntdInput
                    type={isPassword ? 'password' : 'string'}
                    size="large"
                    placeholder={placeholder || label}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    onChange={onChange}
                    name={name}
                />
            ),
        )}
    </Antd3Form.Item>
);
