import { snakeCase, startCase, isUndefined } from 'lodash';
import moment from 'moment';
import URLSearchParams from '@ungap/url-search-params';

import { DateFormat } from 'config/variables';
import { Contract, Filters, ContractPriorities } from 'features/contract/models/contractModels';
import {
    Benefit,
    BenefitRole,
    ContractBenefit,
    DeliveryDate,
} from 'features/benefit/models/benefitModels';
import {
    ContractPath,
    EvidenceData,
    ScorePath,
    UploadBenefit,
} from 'features/upload/models/uploadModels';
import { config, organisationUrl } from 'config/config';
import { Profile } from 'features/user/models/userModel';
import {
    AutocompleteField,
    AutocompleteData,
    AutocompleteRequest,
} from 'features/pagination/models/paginationModel';
import { Organisation } from 'features/organisation/models/organisationModels';

export const dateRequestFormat = (formDate: any) => {
    const formattedDate = moment(formDate).format();

    return formattedDate.substring(0, formattedDate.indexOf('T')); // Can't use new Date().getMonth() or moment().format('YYYY-M-DD') because of issues on iOS with getting month from Date string
};

export const formatOrganisationIdRequest = (ids: number[] = []) =>
    ids.map((id) => ({ organisation: id }));

export const formatSupplierIdRequest = (id: number) => [{ organisation: id }];

export const formatPartnerIdRequest = (orgs: Organisation[]) =>
    orgs.map(({ id }) => ({ organisation: id }));

export const formatUserIdRequest = (ids: number[] = []) => ids.map((id) => ({ user: id }));

export const formatPrioritiesRequest = (priorities: ContractPriorities[] = []) =>
    priorities.map((priority) => ({ priority: priority.id, value: priority.value }));

export const formatPriorityRequest = (priority: ContractPriorities) => ({
    priority: priority.id,
    value: 1,
    name: priority.name,
});

export const formatPriorityToState = (priority: ContractPriorities) => ({
    priority: priority.id,
    value: 1,
    priorityName: priority.name,
    ...priority,
});

export const formatAssessorsRequest = (ids: number[] = [], organisationId: number) =>
    ids.map((id) => ({ user: id, organisation: organisationId }));

export const formatIndividualExecutiveRequest = (executives: AutocompleteData[]) =>
    executives.map(({ userId, organisation }) => ({ user: userId, organisation: organisation! }));

export const formatInvitePendingInvididualExecutiveRequest = (
    executives: AutocompleteData[],
    role: BenefitRole,
) =>
    executives.map(({ id }) => ({
        userInvitation: id,
        role,
    }));

export const snakeUpperCase = (data: string) => snakeCase(data).toUpperCase();

export const findElementNameById = (elements: Filters[], id: number) => {
    const element = elements.find((el) => el.id === id);

    return (element && startCase(element.name)) || '';
};

export const disabledDate = (current: any) => current && current < moment().endOf('day');

export interface SaveBlobParams {
    fileName: string;
    blob: Blob;
}

export const saveBlob = async ({ fileName = 'data.xlsx', blob }: SaveBlobParams): Promise<void> => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const csvUrl = URL.createObjectURL(blob);
        a.href = csvUrl;
        a.download = fileName;
        a.click();
        a.remove();
    }
};

interface FullNameParams {
    firstName: string;
    lastName: string;
    isAdministrator?: boolean;
    archived?: boolean;
}

export const getUsername = ({ firstName, lastName, isAdministrator, archived }: FullNameParams) => {
    const admin = isAdministrator ? ' | Administrator' : '';
    const isArchived = archived ? ' (Archived)' : '';
    return `${firstName} ${lastName}${admin}${isArchived}`;
};

export const filterObjectsArray = <T>(array: T[], searchTerm: string) =>
    array.filter((item) =>
        Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchTerm.toLowerCase()),
        ),
    );

interface ApprovalStatusArgs {
    approved?: boolean;
    approvedBy?: string;
    declinedBy?: string;
    approvedAt?: string;
}

export const approvalStatus = ({
    approved,
    approvedBy,
    declinedBy,
    approvedAt,
}: ApprovalStatusArgs) => {
    const status = approved ? 'Approved' : 'Declined';
    const author = `by ${approved ? approvedBy : declinedBy}`;
    const date = approved ? `at ${moment(approvedAt).format(DateFormat.Exact)}` : '';

    return `${status} ${author} ${date}`;
};

interface GenerateColumns {
    title: string | JSX.Element;
    key: string;
    render?: (value: string, item: object) => any;
    width?: string | number;
}

export const generateColumns = (columns: GenerateColumns[]) =>
    columns.map((column) => ({
        ...column,
        dataIndex: column.key,
    }));

export const publishStatus = (published?: boolean) => ({
    published: isUndefined(published) ? undefined : published ? 'True' : 'False',
});

export const archivedStatus = (archived?: boolean) =>
    isUndefined(archived) ? undefined : archived.toString();

export const contractPath = ({ organisationId, contractId }: ContractPath): string => {
    const organisation = organisationId
        ? `${organisationUrl}${organisationId}/contracts/`
        : config.routeConfig.contract;
    return `${organisation}${contractId}/`;
};

export const evidencePath = ({
    organisationId,
    contractId,
    benefitId,
    deliveryDateId,
}: EvidenceData): string => {
    const organisation = organisationId
        ? `${organisationUrl}${organisationId}/contracts/`
        : config.routeConfig.contract;

    const contract = `${organisation}${contractId}/`;
    const benefit = `benefits/${benefitId}/`;
    const evidence = `delivery-dates/${deliveryDateId}/evidences/`;

    return `${contract}${benefit}${evidence}`;
};

export const scorePath = ({
    organisationId,
    contractId,
    benefitId,
    scoreId,
}: ScorePath): string => {
    const organisation = organisationId
        ? `${organisationUrl}${organisationId}/contracts/`
        : config.routeConfig.contract;
    const path = `${organisation}${contractId}/benefits/${benefitId}/scores/`;
    const id = scoreId ? `${scoreId}/` : '';

    return `${path}${id}`;
};

export const getFilterName = (filters: Filters[], filterId: number) => {
    const filter = filters.find(({ id }) => filterId === id);
    return filter && filter.name;
};

export const generateAutocompleteFilterProperties = ({
    autocompleteField,
    searchTerm,
    searchParams,
}: AutocompleteRequest) => {
    switch (autocompleteField) {
        case AutocompleteField.ContractManager:
        case AutocompleteField.Assessor:
        case AutocompleteField.Employee:
        case AutocompleteField.ExecutiveSupplierUser:
        case AutocompleteField.ExecutiveDeliveryPartnerUser:
            return {
                fullName: searchTerm,
                ...searchParams,
            };

        case AutocompleteField.BenefitTemplate:
            return {
                outcome: searchTerm,
                ordering: 'outcome',
                ...searchParams,
            };

        case AutocompleteField.Contract:
        case AutocompleteField.MyContract:
        case AutocompleteField.DownloadContracts:
        case AutocompleteField.ExecutiveAdminSearchContractsAssignBenefit:
            return {
                contract: searchTerm,
                ordering: 'start_date',
            };
        case AutocompleteField.ContractPortfolioFilter:
            return {
                ...searchParams,
                name: searchTerm,
            };
        case AutocompleteField.BenefitRoleExecutives:
        case AutocompleteField.BenefitRoleInvitedExecutives:
        case AutocompleteField.MassAssignBenefitRoleExecutives:
        case AutocompleteField.MassAssignBenefitRoleInvitedExecutives:
            return {
                email: searchTerm,
                ...searchParams,
            };
        case AutocompleteField.ReadyForEvidenceBenefits:
        case AutocompleteField.ReadyForPendingEvidenceBenefits:
        case AutocompleteField.ApprovedUploadEvidences:
        case AutocompleteField.ScorableBenefits:
        case AutocompleteField.ApprovedUploadScores:
        case AutocompleteField.CheckEvidence:
        case AutocompleteField.CheckPendingEvidence:
        case AutocompleteField.ApprovedCheckEvidence:
        case AutocompleteField.CheckScores:
        case AutocompleteField.ApprovedCheckScores:
        case AutocompleteField.ContractBenefits:
        case AutocompleteField.DownloadBenefits:
        case AutocompleteField.Benefit:
        case AutocompleteField.MyBenefit:
            return {
                referenceNumber: searchTerm,
                ...searchParams,
            };
        case AutocompleteField.ContractCategory:
        case AutocompleteField.BenefitCategory:
        case AutocompleteField.ContractPriorities:
        case AutocompleteField.BenefitPriorities:
        case AutocompleteField.Department:
        case AutocompleteField.Location:
            return {
                ...searchParams,
                name: searchTerm,
            };
        default:
            return {
                name: searchTerm,
            };
    }
};

export enum DownloadFileFormats {
    XLSX = 'XLSX',
    ODS = 'ODS',
}

export enum DownloadBenefitsFileNames {
    XLSX = 'benefits.xlsx',
    ODS = 'benefits.ods',
}

export enum DownloadBenefitTypeNames {
    All = '',
    Unique = 'unique',
    Templated = 'templated',
}

export enum DownloadBenefitExportTypeNames {
    Summary = 'summary',
    Evidence = 'evidenceAnswers',
    Feedback = 'feedbackAnswers',
}

export enum DownloadContractsFileNames {
    XLSX = 'projects.xlsx',
    ODS = 'projects.ods',
}

export enum MimeFileTypes {
    ODS = 'application/vnd.oasis.opendocument.spreadsheet',
}

export enum ArchivedFilterOptions {
    All = '',
    Archived = 'Archived',
    Unarchived = 'Unarchived',
}

export const idExtractorForFileDownload = (
    allFiles: Array<Contract | Benefit>,
    downloadAll: boolean | undefined,
    selectedFiles: number[],
): number[] => {
    const allFilesIds = allFiles.map(({ id }) => id);
    const filesIdForRequest = downloadAll ? allFilesIds : selectedFiles.map(Number);

    return filesIdForRequest;
};

export const addReferenceSuffixToBenfitOutcome = (
    benefits: Array<UploadBenefit | Benefit | ContractBenefit>,
) =>
    benefits.map((benefit) => ({
        ...benefit,
        outcome: benefit.referenceNumber
            ? `${benefit.outcome} | ${benefit.referenceNumber}`
            : benefit.outcome,
    }));

export const formatProfileForDetails = (role: Profile[]) =>
    role
        .map(({ user, archived }) =>
            getUsername({
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                archived,
            }),
        )
        .join(', ');

export enum FormFieldIds {
    Title = 'title',
    Description = 'description',
    Department = 'department',
    Location = 'location',
    Category = 'category',
    Contract = 'contract',
    Managers = 'managers',
    PortfolioFilter = 'portfolioFilter',
    ContractManager = 'contractManager',
    Organisation = 'organisation',
    ContractType = 'contractType',
    ContractCategory = 'contractCategory',
    SupplierName = 'supplierName',
    Suppliers = 'suppliers',
    Budget = 'budget',
    RouteToMarket = 'routeToMarket',
    Status = 'status',
    ReferenceNumber = 'referenceNumber',
    ContractDepartment = 'contractDepartment',
    Method = 'method',
    Outcome = 'outcome',
    SourceTemplate = 'sourceTemplate',
    Assessor = 'assessor',
    Supplier = 'supplier',
    SupplierExecutive = 'supplierExecutive',
    DeliveryPartner = 'deliveryPartner',
    DeliveryPartnerExecutive = 'deliveryPartnerExecutive',
    AssessorScore = 'assessorScore',
    SupplierScore = 'supplierScore',
    ContractTitle = 'contractTitle',
    ContractReferenceNumber = 'contractReferenceNumber',
    Value = 'value',
    ValueMin = 'valueMin',
    ValueMax = 'valueMax',
    StartDate = 'startDate',
    EndDate = 'endDate',
    ContractDateRangeBefore = 'contractDateRangeBefore',
    ContractDateRangeAfter = 'contractDateRangeAfter',
    MinStartDate = 'minStartDate',
    MaxStartDate = 'maxStartDate',
    MinEndDate = 'minEndDate',
    MaxEndDate = 'maxEndDate',
    PointsMin = 'pointsMin',
    PointsMax = 'pointsMax',
    BenefitRoleExecutives = 'benefitRoleExecutives',
    BenefitRoleInvitedExecutives = 'benefitRoleInvitedExecutives',
    Priority = 'priority',
    Priorities = 'priorities',
    BenefitValueRag = 'benefitValue',
    BenefitPointsRag = 'benefitPoints',
    BenefitEmissionSavingsRag = 'benefitEmissionSavings',
    BenefitDeliveredNumberRag = 'benefitDeliveredNumber',
    TargetBenefitValue = 'targetBenefitValue',
    TargetBenefitPoints = 'targetBenefitPoints',
    TargetBenefitEmissionSavings = 'targetBenefitEmissionSavings',
    TargetBenefitDeliveryNumber = 'targetBenefitDeliveryNumber',
    Tenant = 'tenant',
    EmissionSavingsMin = 'emissionSavingsMin',
    EmissionSavingsMax = 'emissionSavingsMax',
    QuickFilterContractReferenceNumber = 'quickFilterContractReferenceNumber',
    QuickFilterBenefitReferenceNumber = 'quickFilterBenefitReferenceNumber',
    QuickFilterBenefitType = 'quickFilterBenefitType',
    ContractId = 'contractId',
    FrameworkContract = 'frameworkContract',
    MassAssignBenefitRoleExistingExecutives = 'massAssignBenefitRoleExistingExecutives',
    MassAssignBenefitRoleInvitedExecutives = 'massAssignBenefitRoleInvitedExecutives',
}

export const getProfileIdFromQuery = () => {
    const urlParams = new URLSearchParams(location.search);

    if (!urlParams.has('profile')) {
        return false;
    }

    const profileId = urlParams.get('profile');

    return Number.parseInt(profileId!, 10);
};

export const getCategoryName = (categoryId: number, categories: AutocompleteData[]) => {
    if (!categories.length) {
        return '';
    }

    const foundCategory = categories && categories.find((item) => item.category === categoryId);

    return foundCategory ? foundCategory.categoryName : '';
};

export function getBenefitOutcomeStyle(benefitDeliveryDates: DeliveryDate[]): React.CSSProperties {
    for (const element of benefitDeliveryDates) {
        if (element.needsUserAction) {
            return {
                fontWeight: 'bold',
            };
        }
    }

    return {
        fontWeight: 'normal',
    };
}

export const formatRAGStatus = (status: string): string => {
    switch (status) {
        case 'Red':
            return 'red';
        case 'Amber':
            return 'yellow';
        case 'Green':
            return 'green';
        default:
            return 'grey';
    }
};

export const getContractIdFromQuery = () => {
    const urlParams = new URLSearchParams(location.search);
    const contractId = urlParams.get('contract');

    return contractId !== null ? Number.parseInt(contractId, 10) : null;
};

export const checkIntegerConfirmation = (
    _rule: any,
    value: string | number,
    callback: (msg?: string) => void,
) => {
    Number.isInteger(Number(value)) || typeof value === 'string'
        ? callback()
        : callback('Please enter a valid integer');
};

export const checkDecimalPlaceConfirmation = (
    _rule: any,
    value: string,
    callback: (msg?: string) => void,
) => {
    const checkDecimalPoints = new RegExp('^[0-9]+(.[0-9]{1,2})?$');
    const checkAlphabets = new RegExp('.*[a-zA-Z]+.*');
    (checkDecimalPoints.test(value) && !checkAlphabets.test(value)) ||
    (!checkDecimalPoints.test(value) && checkAlphabets.test(value)) ||
    !value
        ? callback()
        : callback('No more than 2 decimals');
};

export const formatRouteToMethod = (contract: Contract) => {
    if (contract.routeToMarketName !== null) {
        return contract.routeToMarketName;
    }
};

export const hexToRGBA = (hex: string, alpha?: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
};

export const insert = (arr: any[], index: number, newItem: any) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
];
