import React from 'react';
import styled from 'styled-components';

import { ContractWithRAG } from 'features/contract/models/contractModels';

import { Column, PaginationResources } from '../models/paginationModel';

interface ExpandableRowProps {
    rowData: ContractWithRAG;
    columns: Column<PaginationResources>[];
}

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ExpandableRow: React.FunctionComponent<ExpandableRowProps> = ({
    rowData,
    columns,
}) => (
    <>
        {columns
            .slice(1)
            .filter((column) => column.key != 'id')
            .map(({ key, title, render }) => (
                <RowWrapper key={key}>
                    <p>{title}</p>
                    <p>{render ? render(rowData[key]) : rowData[key]}</p>
                </RowWrapper>
            ))}
    </>
);
