import * as React from 'react';

import { ImportForm } from 'features/import/components/ImportForm';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { ImportBenefitsContainerProps } from '../containers/ImportBenefitsContainer';
import { ErrorList } from './ErrorList';

type ImportBenefitsProps = ImportBenefitsContainerProps & Antd3FormProps;

class ImportBenefitsForm extends React.Component<ImportBenefitsProps> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { fileList: { file } }) => {
            if (!error) {
                return file && this.props.importBenefitsRequest(file);
            }
            return;
        });
    };

    public render(): JSX.Element {
        const { form, isProcessing, errors } = this.props;
        const importError = errors.length > 0;

        return (
            <>
                <ImportForm
                    header="Import Unique Benefits"
                    form={form}
                    href="import-unique-benefits"
                    onSubmit={this.handleSubmit}
                    helpUrl="https://support.cenefits.com/articles/admins-contract-managers/how-to-import-unique-benefits"
                    processing={this.props.isProcessing}
                    importError={importError}
                />
                {!isProcessing && importError && <ErrorList errors={errors} />}
            </>
        );
    }
}

export const ImportBenefits = Antd3Form.create({})(ImportBenefitsForm);
