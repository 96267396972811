import { Form, Input, InputProps } from 'antd';
import { FormItemProps } from '@ant-design/compatible/lib/form';
import { NamePath } from 'antd/lib/form/interface';

import { InputLabelWithTooltip } from './components/InputLabelWithTooltip';
import { FormInputProps } from './models';

interface Props
    extends Omit<FormItemProps, 'label'>,
        Omit<InputProps, 'label' | 'name'>,
        FormInputProps {
    name: NamePath;
}

export const TextArea = ({
    name,
    placeholder,
    label,
    required = true,
    requiredMessage,
    disabled = false,
    hidden,
    autoComplete,
    tooltip,
    help,
    validateStatus,
}: Props) => (
    <Form.Item
        name={name}
        label={
            tooltip ? (
                <InputLabelWithTooltip label={label || placeholder} tooltip={tooltip} />
            ) : (
                label || placeholder
            )
        }
        help={help}
        validateStatus={validateStatus}
        rules={[{ required, message: requiredMessage || `${label || placeholder} is required` }]}
    >
        <Input.TextArea
            size="large"
            placeholder={placeholder || label}
            disabled={disabled}
            hidden={hidden}
            autoComplete={autoComplete}
        />
    </Form.Item>
);
