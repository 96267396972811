import { Form, FormInstance, Tag } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { AutocompleteSelect } from 'common/components';
import { HttpService } from 'common/services/HttpService';
import { generateAutocompleteEndpoint } from 'config/config';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { actionPropsByAction } from 'features/upload/helpers';
import { BenefitChangeSuggestionFormItemLocation } from 'features/upload/models/uploadModels';
import { useEffect, useState } from 'react';

import * as S from '../shared/styles';
import { useParams } from 'react-router-dom';

type Props = {
    onSelect: (location: number) => void;
    onDelete: () => void;
    name?: NamePath;
};

export const BenefitLocationSuggestionCard = ({ onSelect, name, onDelete }: Props) => {
    const benefitLocation: BenefitChangeSuggestionFormItemLocation | undefined = Form.useWatch([
        'benefitChangeSuggestions',
        'benefitLocation',
    ]);
    const [locations, setLocations] = useState([]);
    const [httpService] = useState(new HttpService());
    const { organisationId } = useParams();

    useEffect(() => {
        getLocationNames();
    }, []);

    const getLocationNames = async () => {
        const locationsPath = generateAutocompleteEndpoint(AutocompleteField.Location);

        if (!locationsPath) {
            return;
        }
        const { results }: any = await httpService.GET(locationsPath, {
            limit: 50,
            offset: 0,
        });
        setLocations(results);
    };

    if (!benefitLocation) {
        return null;
    }

    const isLocationUpdated = benefitLocation.location !== benefitLocation.prevLocation;
    const isUpdated = benefitLocation.action && isLocationUpdated;

    const prevLocation: any = locations.find(
        (location: any) => location.id === benefitLocation.prevLocation,
    );

    const actionProps = benefitLocation.action && actionPropsByAction[benefitLocation.action];
    const displayChange = actionProps && (benefitLocation.action !== 'update' || isUpdated);

    return (
        <S.SuggestionWrapper color={displayChange ? actionProps.color : undefined}>
            {displayChange && (
                <S.ChangeInfo>
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {isUpdated && (
                            <span>
                                {isLocationUpdated &&
                                    benefitLocation.action === 'update' &&
                                    `, previous Location: "${prevLocation?.name}"`}
                            </span>
                        )}
                    </Tag>
                </S.ChangeInfo>
            )}
            <AutocompleteSelect
                name={name}
                initialValue={benefitLocation.location}
                onSelect={onSelect}
                onDeselect={onDelete}
                label="Benefit Location"
                autocompleteField={AutocompleteField.Location}
                requestParams={{ organisationId }}
                notFoundContent="No locations found"
                required={false}
            />
        </S.SuggestionWrapper>
    );
};
