import { FormInstance } from 'antd';

import { IdValueObject } from 'common/models/defaultsModel';

export const getEmptyIdValueObject = (): IdValueObject => ({
    value: '',
});

export const isFormValid = (form?: FormInstance) =>
    Boolean(form?.getFieldsError().flatMap((error) => error.errors).length === 0);
