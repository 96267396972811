import * as React from 'react';
import { Tooltip, Button } from 'antd';

import { CenteringFormItem } from 'common/components/styled';
import { Antd3Icon } from 'common/components/deprecated/antd3';

interface DeleteOrganisationButtonProps {
    onClick: () => void;
}
export const DeleteOrganisationButton: React.FunctionComponent<DeleteOrganisationButtonProps> = ({
    onClick,
}): JSX.Element => (
    <CenteringFormItem>
        <Tooltip placement="right" title={'Remove'}>
            <Button size="small" type="danger" ghost onClick={() => onClick()}>
                <Antd3Icon type="delete" />
                Remove Organisation
            </Button>
        </Tooltip>
    </CenteringFormItem>
);
