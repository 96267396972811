import * as actions from '../actions/portfolioFilterAction';
import {
    PortfolioFiltersLists,
    PortfolioFilter,
    PortfolioFiltersEnum,
} from '../models/portfolioFilterModel';
import { getPortfolioFiltersChangeOnGet } from '../helpers/getPortfolioFiltersChangeOnGet';
import { getPortfolioFiltersChangeOnEdit } from '../helpers/gePortfolioFiltersChangeOnEdit';

export interface PortfolioFilterState {
    isFetching: boolean;
    rootPortfolioFilter: PortfolioFilter;
    portfolioFilters: PortfolioFiltersLists;
}

const defaultPortfolioFilterState: PortfolioFilterState = {
    isFetching: false,
    rootPortfolioFilter: {
        id: -1,
        name: '',
        archived: false,
        path: '',
        depth: PortfolioFiltersEnum.SubProgram,
        organisation: -1,
        childrenCount: 0,
    },
    portfolioFilters: {
        subPrograms: [],
        mainProjects: [],
        subProjects: [],
    },
};

export const portfolioFilterReducer = (
    state: PortfolioFilterState = defaultPortfolioFilterState,
    action: actions.PortfolioFilterActions,
) => {
    switch (action.type) {
        case actions.GET_PORTFOLIO_FILTERS_ROOT_SUCCEED: {
            return {
                ...state,
                rootPortfolioFilter: action.payload,
            };
        }
        case actions.GET_PORTFOLIO_FILTERS_LIST_SUCCEED: {
            const { depth, portfolioFilters } = action.payload!;

            return {
                ...state,
                portfolioFilters: {
                    ...state.portfolioFilters,
                    ...getPortfolioFiltersChangeOnGet(depth, portfolioFilters),
                },
            };
        }
        case actions.EDIT_PORTFOLIO_FILTER_SUCCEED: {
            const { depth, portfolioFilter } = action.payload!;

            return {
                ...state,
                portfolioFilters: {
                    ...state.portfolioFilters,
                    ...getPortfolioFiltersChangeOnEdit(
                        depth,
                        portfolioFilter,
                        state.portfolioFilters,
                    ),
                },
            };
        }
        case actions.CREATE_PORTFOLIO_FILTER_SUCCEED: {
            const { depth, portfolioFilter } = action.payload!;

            return {
                ...state,
                portfolioFilters: {
                    ...state.portfolioFilters,
                    [depth]: [...state.portfolioFilters[depth], portfolioFilter],
                },
            };
        }
        default:
            return state;
    }
};
