import * as React from 'react';
import styled from 'styled-components';
import { Alert, Button } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';

import { Antd3Form } from 'common/components/deprecated/antd3';
import { WarningMessage } from 'common/components/WarningMessage';

interface Props {
    isChildVisible: boolean;
    showChild: () => void;
    isDisabled?: boolean;
}

const ButtonStyled = styled((props: NativeButtonProps) => <Button {...props} />)`
    width: 100%;
`;

const showWarningMessage = () => (
    <Alert
        type="error"
        message={
            "Some of Benefit's questionnaires have been answered, so the Benefit Template cannot be changed"
        }
    />
);

export const SelectBenefitTemplateParent: React.FC<Props> = ({
    isChildVisible,
    showChild,
    children,
    isDisabled,
}) => (
    <Antd3Form.Item>
        <ButtonStyled type="danger" onClick={showChild} disabled={isDisabled}>
            Change Benefit Template
        </ButtonStyled>
        {isChildVisible && children}
        {isDisabled && showWarningMessage()}
    </Antd3Form.Item>
);
