import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { AuthContainer } from 'features/auth/containers/AuthContainer';
import { getFetchingUserStatus } from 'features/user/selectors/userSelectors';
import { getUrlHashCode } from 'common/helpers/url';
import { ContractDashboardContainer } from 'features/dashboard/containers/ContractDashboardContainer';
import { getDateRangeContractDashboard } from 'features/contract/selectors/contractSelectors';

import { getUserVerificationStatus, getAuthenticatedStatus } from '../selectors/authSelectors';
import { verifyExistingExecutiveRequest } from '../actions/authActions';

interface VerifyExistingExecutiveContainerStateProps {
    isUserVerified: boolean;
    isFetching: boolean;
    isUserAuthenticated: boolean;
}

interface VerifyExistingExecutiveContainerDispatchProps {
    verifyExistingExecutiveRequest: typeof verifyExistingExecutiveRequest;
    getDateRangeContractDashboard: typeof getDateRangeContractDashboard;
}

export type VerifyExistingExecutiveContainerProps = VerifyExistingExecutiveContainerStateProps &
    VerifyExistingExecutiveContainerDispatchProps;

class VerifyExistingExecutive extends React.Component<VerifyExistingExecutiveContainerProps> {
    public componentDidMount(): void {
        this.props.verifyExistingExecutiveRequest(getUrlHashCode());
    }

    public render(): JSX.Element {
        if (this.props.isUserVerified) {
            if (this.props.isUserAuthenticated) {
                return (
                    <ContractDashboardContainer
                        getDateRangeContractDashboard={getDateRangeContractDashboard}
                    />
                );
            }
        }

        return <AuthContainer />;
    }
}

const mapStateToProps = createSelector(
    getUserVerificationStatus,
    getFetchingUserStatus,
    getAuthenticatedStatus,
    (
        isUserVerified,
        isFetching,
        isUserAuthenticated,
    ): VerifyExistingExecutiveContainerStateProps => ({
        isUserVerified,
        isFetching,
        isUserAuthenticated,
    }),
);

export const VerifyExistingExecutiveContainer = connect(mapStateToProps, {
    verifyExistingExecutiveRequest,
})(VerifyExistingExecutive);
