import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { Contract } from 'features/contract/models/contractModels';
import {
    getContracts,
    getFilteringContractsStatus,
} from 'features/contract/selectors/contractSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { RouteParams, getPageNumber } from 'common/selectors/routeSelectors';
import { toggleMultipleEmployeesContractRoleRequest } from 'features/contract/actions/contractActions';

import { EditContract as EditContractComponent } from '../components/EditContract';
import { isAdmin } from 'features/user/selectors/userSelectors';

export interface EditContractStateProps {
    contracts: Contract[];
    isFiltered: boolean;
    isUserAdmin: boolean;
    filter?: PaginationFilters;
    pageNumber?: number;
}

interface EditContractDispatchProps {
    redirectToUrl: typeof redirectToUrl;
    autocompleteRequest: typeof autocompleteRequest;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
    toggleMultipleEmployeesContractRoleRequest: typeof toggleMultipleEmployeesContractRoleRequest;
}

export type EditContractProps = EditContractDispatchProps & EditContractStateProps & RouteParams;

export class EditContract extends React.Component<EditContractProps> {
    public render(): JSX.Element {
        return <EditContractComponent {...this.props} />;
    }
}
const mapStateToProps = (state: AppState, ownProps: RouteParams): EditContractStateProps => ({
    contracts: getContracts(state),
    isFiltered: getFilteringContractsStatus(state),
    isUserAdmin: isAdmin(state),
    filter: getFilterParams(state, PaginationView.Contracts),
    pageNumber: getPageNumber(ownProps),
});

export const EditContractContainer = connect(mapStateToProps, {
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
    toggleMultipleEmployeesContractRoleRequest,
})(EditContract);
