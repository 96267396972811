import { Checkbox } from 'antd';
import styled from 'styled-components';

import { Antd3Checkbox, WrappedFormUtils } from 'common/components/deprecated/antd3';

import { UserAccountAgreements } from '../models/userModel';

const checkboxStyles = `
    && {
        display: flex;
        max-width: 40rem;
        margin: 0 0 1rem;
    }
`;

const CheckboxWrapper = styled(Antd3Checkbox)`
    ${checkboxStyles}
`;

const StyledCheckbox = styled(Checkbox)`
    ${checkboxStyles}
`;

type Props = {
    form: WrappedFormUtils;
};

export const ConsentsCheckboxes = ({ form }: Props) => {
    const areAllCheckboxesChecked = Object.values(
        form.getFieldsValue([
            UserAccountAgreements.FunctionalEmails,
            UserAccountAgreements.NonFunctionalEmails,
            UserAccountAgreements.TermsOfService,
        ]),
    ).every((check) => Boolean(check));

    const checkAllCheckboxes = () => {
        const valueToSet = areAllCheckboxesChecked ? false : true;

        form.setFieldsValue({
            [UserAccountAgreements.FunctionalEmails]: valueToSet,
            [UserAccountAgreements.NonFunctionalEmails]: valueToSet,
            [UserAccountAgreements.TermsOfService]: valueToSet,
        });
    };

    return (
        <>
            <p>Please check the boxes next to the emails you would like to receive from us:</p>
            <CheckboxWrapper form={form} id={UserAccountAgreements.FunctionalEmails}>
                Functional emails related to your user account, organisation, projects and benefits.
            </CheckboxWrapper>
            <CheckboxWrapper form={form} id={UserAccountAgreements.NonFunctionalEmails}>
                Non-functional emails related to important changes, user research and training
                events (no more than once per month).
            </CheckboxWrapper>
            <CheckboxWrapper form={form} id={UserAccountAgreements.TermsOfService}>
                By ticking this box and creating this account, I confirm that I have read and
                consent to the{' '}
                <a href="https://cenefits.com/terms.html" target="blank" rel="noopener noreferrer">
                    Terms and Conditions
                </a>
                ,{' '}
                <a href="https://cenefits.com/use.html" target="blank" rel="noopener noreferrer">
                    Acceptable Use Policy
                </a>{' '}
                and{' '}
                <a
                    href="https://cenefits.com/privacy.html"
                    target="blank"
                    rel="noopener noreferrer"
                >
                    Privacy Policy
                </a>{' '}
                for this service.
            </CheckboxWrapper>
            <StyledCheckbox checked={areAllCheckboxesChecked} onChange={checkAllCheckboxes}>
                Check all
            </StyledCheckbox>
        </>
    );
};
