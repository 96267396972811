import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { ChangeUserEmail } from '../components/ChangeUserEmail';
import { updateUserEmailRequest } from '../actions/userActions';
import { Profile } from '../models/userModel';
import { getProfile, isAdmin } from '../selectors/userSelectors';

interface StateProps {
    profile: Profile;
    isUserAdmin: boolean;
}

interface DispatchProps {
    updateUserEmailRequest: typeof updateUserEmailRequest;
}

export type Props = StateProps & DispatchProps;

const ChangeEmail = (props: Props): JSX.Element => <ChangeUserEmail {...props} />;

const mapStateToProps = createSelector(
    getProfile,
    isAdmin,
    (profile, isUserAdmin): StateProps => ({ profile, isUserAdmin }),
);

export const ChangeEmailContainer = connect(mapStateToProps, {
    updateUserEmailRequest,
})(ChangeEmail);
