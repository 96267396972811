import * as React from 'react';
import { Button } from 'antd';

import { SubmitButton } from 'common/components';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { SubmitButtonWrapper } from 'features/invite/components/InviteNewOrganisation';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { ResetButton, Input, TextArea } from 'common/components/deprecated/form';

import { SupplierEditUsersContainerProps } from '../containers/SupplierEditUsersContainer';

type SupplierEditUsersProps = SupplierEditUsersContainerProps & Antd3FormProps;

class SupplierEditOrganisationForm extends React.Component<SupplierEditUsersProps> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }
        });
    };

    public render(): JSX.Element {
        const { form } = this.props;

        const orgNameInput = this.props.form.getFieldValue('organisationName');
        const orgRegistrationNumberInput = this.props.form.getFieldValue(
            'organisationRegistrationNumber',
        );
        const optionalNoteInput = this.props.form.getFieldValue('optionalNote');

        const isOrgRegistrationNumberEmpty =
            orgRegistrationNumberInput === undefined || orgRegistrationNumberInput === '';
        const isOrgNameEmpty = orgNameInput === undefined || orgNameInput === '';

        const mailtoContent = ` mailto:hello@cenefits.com?subject=New%20Organisation%3A%20${orgRegistrationNumberInput}
                                &body=Name%3A%0D%0A%0D%0A${orgNameInput}
                                %0D%0A%0D%0ANumber%3A%0D%0A%0D%0A${orgRegistrationNumberInput}
                                %0D%0A%0D%0ANote%3A%0D%0A%0D%0A${optionalNoteInput} `;

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Edit Organisation" bigHeader>
                <p>
                    If you would like to change your Organisation Name or Registration Number,
                    please fill out the fields below and then click to send an email to our support
                    site with the details.
                </p>
                <p>
                    Please be as precise as possible, using the official organisation name and
                    registration number from your organisation’s country’s company or charity
                    regulator. In the UK that’s Companies House, Charity Commission or Office of the
                    Scottish Charity Regulator.
                </p>
                <ResetButton form={form} />
                <Input form={form} id="organisationName" label="Organisation Name" />
                <Input
                    form={form}
                    id="organisationRegistrationNumber"
                    label="Organisation Registration Number"
                />
                <TextArea form={form} id="optionalNote" label="Optional Note" required={false} />
                {isOrgNameEmpty || isOrgRegistrationNumberEmpty ? (
                    <SubmitButton value="Submit For Approval" />
                ) : (
                    <SubmitButtonWrapper>
                        <Button type="primary" htmlType="submit">
                            <a href={mailtoContent}>Submit For Approval</a>
                        </Button>
                    </SubmitButtonWrapper>
                )}
            </StyledAntd3Form>
        );
    }
}

export const SupplierEditOrganisation = Antd3Form.create({})(SupplierEditOrganisationForm);
