import { Alert, AlertProps, Space } from 'antd';
import { PropsWithChildren } from 'react';

import { PaddedSection } from './PaddedSectionComponent';

type Props = PropsWithChildren<AlertProps> & {
    message: String;
    type?: 'success' | 'info' | 'warning' | 'error';
};

export const WarningMessage = ({ message, type = 'warning', children }: Props) => (
    <PaddedSection>
        <Space direction="vertical">
            <Alert message={message} type={type} />
            {children}
        </Space>
    </PaddedSection>
);
