import * as React from 'react';
import { Divider } from 'antd';
import { connect } from 'react-redux';

import { InnerCard } from 'common/components/styled';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteData, AutocompleteField } from 'features/pagination/models/paginationModel';
import { Organisation } from 'features/organisation/models/organisationModels';
import { selectBenefitContract } from 'features/benefit/actions';
import {
    autocompleteRequest,
    clearInitialValueData,
    getInitialValueRequest,
} from 'features/pagination/actions/paginationActions';
import { selectContractOrganisation } from 'features/contract/actions/contractActions';
import { RolesList } from 'common/components';
import { InviteUserModal } from 'features/invite/components/InviteUserModal';
import { DeleteOrganisationButton } from 'features/create/components/DeletePartnerButton';
import { BenefitRole } from 'features/benefit/models/benefitModels';
import { InviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { NewUserRolesList } from 'features/invite/components/NewUserRolesList';
import { Antd3FormProps } from 'common/components/deprecated/antd3';

interface AutocompleteDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    getInitialValueRequest: typeof getInitialValueRequest;
    clearInitialValueData: typeof clearInitialValueData;
    selectContractOrganisation: typeof selectContractOrganisation;
    selectBenefitContract: typeof selectBenefitContract;
}

interface AutocompleteSelectionProps {
    newPartnerUsers: InviteByEmailWithRole[];
    selectNewPartnerUser: (employeeData: InviteByEmailWithRole) => void;
    deleteNewPartnerUser: (employeeData: InviteByEmailWithRole) => void;
    selectedPartners: Organisation[];
    selectPartnerOrganisation: (selectedSupplier: Organisation) => void;
    deletePartner: (partnerId: number) => void;
    selectedPartnerUsers: AutocompleteData[];
    selectPartnerUser: (selectedExecutive: AutocompleteData) => void;
    deletePartnerUser: (executiveId: number) => void;
    selectedInvitePendingUsers: AutocompleteData[];
    selectInvitePendingPartnerUser: (selectedExecutive: AutocompleteData) => void;
    deleteInvitePendingPartnerUser: (partnerId: number) => void;
    tooltip?: string;
}

class DeliveryPartnerFields extends React.Component<
    AutocompleteSelectionProps & AutocompleteDispatchProps & Antd3FormProps
> {
    private selectPartner = (selectedPartner: Organisation) => {
        this.props.selectPartnerOrganisation(selectedPartner);
    };

    public render(): JSX.Element {
        const { form, tooltip } = this.props;

        const partnerForms = this.props.selectedPartners.map((partner) => (
            <div key={partner.id}>
                {partner.name}
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.BenefitRoleExecutives}
                    id={`individual_partners-${partner.id}`}
                    placeholder="Add Existing Delivery Partner Users"
                    notFoundContent="No users found"
                    getAutocompleteData={this.props.selectPartnerUser}
                    searchParams={{ organisation: partner.id }}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.BenefitRoleInvitedExecutives}
                    id={`invited_individual_partners-${partner.id}`}
                    placeholder="Add Invite Pending Delivery Partner Users"
                    notFoundContent="No invite pending users found"
                    getAutocompleteData={this.props.selectInvitePendingPartnerUser}
                    searchParams={{ organisation: partner.id }}
                />
                <InviteUserModal
                    form={form}
                    onInvite={this.props.selectNewPartnerUser}
                    organisationName={partner.name}
                    organisationId={partner.id}
                    role={BenefitRole.DELIVERY_PARTNER}
                />
                <Divider orientation="left">Active Roles</Divider>
                <RolesList
                    items={this.props.selectedPartnerUsers
                        .filter((user) => user.organisation == partner.id)
                        .map((user) => ({
                            id: user ? user.id : '',
                            name: user && user.email ? user.email : 'User email not found',
                        }))}
                    deleteItem={this.props.deletePartnerUser}
                    noData={`No delivery partner users added to ${partner.name}`}
                />
                <Divider orientation="left">Pending Roles</Divider>
                <RolesList
                    items={this.props.selectedInvitePendingUsers
                        .filter((user) => user.organisation == partner.id)
                        .map((user) => ({
                            id: user ? user.id : '',
                            name:
                                user && user.email
                                    ? user.email + ' - invite pending'
                                    : 'User email not found',
                        }))}
                    deleteItem={this.props.deleteInvitePendingPartnerUser}
                    noData={`No invite pending delivery partner users added to ${partner.name}`}
                />
                <NewUserRolesList
                    items={this.props.newPartnerUsers
                        .filter(
                            (user) =>
                                user.organisationId == partner.id &&
                                user.role == BenefitRole.DELIVERY_PARTNER,
                        )
                        .map((user) => ({
                            id: user,
                            name: user.email || '',
                        }))}
                    deleteItem={this.props.deleteNewPartnerUser}
                    noData="No new users invited"
                />
                <DeleteOrganisationButton onClick={() => this.props.deletePartner(partner.id)} />
            </div>
        ));

        return (
            <InnerCard>
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ExecutiveOrganisation}
                    id="partners"
                    placeholder="Choose Delivery Partner Organisations"
                    notFoundContent="No executive organisations found"
                    getAutocompleteData={this.selectPartner}
                    tooltip={tooltip}
                />
                {partnerForms}
            </InnerCard>
        );
    }
}

export const DeliveryPartnerForm = connect(null, {
    autocompleteRequest,
    getInitialValueRequest,
    clearInitialValueData,
    selectContractOrganisation,
    selectBenefitContract,
})(DeliveryPartnerFields);
