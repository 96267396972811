import { inject, injectable } from 'inversify';

import { HttpService } from 'common/services/HttpService';
import { SERVICE } from 'config/identifiers';
import { config } from 'config/config';
import { showErrorMessage } from 'common/services/errorMessage';
import { CreateAccountCredentials, UserAccountAgreements } from 'features/user/models/userModel';
import { getUrlHashCode } from 'common/helpers/url';

import { NewUser } from '../models/newUserModel';
import { Credentials, Account } from '../models/credentialsModel';

interface AuthResponse {
    token: string;
}

@injectable()
export class AuthService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    public async logIn(credentials: Credentials): Promise<string | Error> {
        const { token } = await this.http.POST<AuthResponse>(
            config.routeConfig.logInRoute,
            credentials,
        );
        localStorage.setItem('token', token);

        return token;
    }

    public async activateUser(
        credentials: CreateAccountCredentials,
    ): Promise<Account & CreateAccountCredentials> {
        try {
            const account = await this.http.POST<Account>(
                `${config.routeConfig.invitations}${getUrlHashCode()}/activate/`,
                {
                    password: credentials.password,
                    userExists: credentials.userExists,
                    [UserAccountAgreements.FunctionalEmails]:
                        credentials[UserAccountAgreements.FunctionalEmails],
                    [UserAccountAgreements.NonFunctionalEmails]:
                        credentials[UserAccountAgreements.NonFunctionalEmails],
                    [UserAccountAgreements.TermsOfService]:
                        credentials[UserAccountAgreements.TermsOfService],
                },
            );

            return { ...credentials, ...account };
        } catch {
            return showErrorMessage({ message: 'Token expired' });
        }
    }

    public async activateNewExecutiveAdmin(
        credentials: CreateAccountCredentials,
    ): Promise<Account & CreateAccountCredentials> {
        try {
            const account = await this.http.POST<Account>(
                `${config.routeConfig.newExecutiveInvitations}${getUrlHashCode()}/activate/`,
                {
                    password: credentials.password,
                },
            );

            return { ...credentials, ...account };
        } catch {
            return showErrorMessage({ message: 'Token expired' });
        }
    }

    public async activateExistingExecutiveAdmin(invitationToken: string): Promise<Account> {
        try {
            return await this.http.POST<Account>(
                `${config.routeConfig.executiveInvitations}${invitationToken}/activate/`,
            );
        } catch {
            return showErrorMessage({ message: 'Token expired' });
        }
    }

    public async verifyNewExecutiveToken(invitationToken: string): Promise<Account> {
        try {
            const account = await this.http.GET<Account>(
                `${config.routeConfig.newExecutiveInvitations}${invitationToken}/`,
            );

            return account;
        } catch {
            return showErrorMessage({ message: 'Token expired' });
        }
    }

    public async verifyExistingExecutiveToken(invitationToken: string): Promise<Account> {
        try {
            const account = await this.http.GET<Account>(
                `${config.routeConfig.executiveInvitations}${invitationToken}/`,
            );

            return account;
        } catch {
            return showErrorMessage({ message: 'Token expired' });
        }
    }

    public async verifyNewUserToken(oldToken: string): Promise<NewUser> {
        try {
            const account = await this.http.GET<NewUser>(
                `${config.routeConfig.invitations}${oldToken}/`,
            );

            return account;
        } catch {
            return showErrorMessage({ message: 'Token expired' });
        }
    }

    public async refreshToken(): Promise<string | Error> {
        try {
            const { token } = await this.http.POST<AuthResponse>(config.routeConfig.refreshToken);
            localStorage.setItem('token', token);
            return token;
        } catch ({ message }) {
            return showErrorMessage(message);
        }
    }
}
