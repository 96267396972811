import { Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input, Select, TextArea } from 'common/components/deprecated/form';
import { titleCase } from 'common/helpers/titleCase';
import { checkDecimalPlaceConfirmation } from 'common/helpers/utils';
import { uniqueBenefitTooltips } from 'common/tooltips/uniqueBenefitTooltips';

import { benefitStatuses, BenefitStatus } from '../models/benefitModels';

interface EditExplanationStatusProps {
    benefitStatus: string;
    benefitStatusExplanation?: string;
    defaultValueForNewBenefit?: boolean;
    deliveredEmissionSavings?: number;
    deliveredPoints?: number;
    deliveredValue?: number;
    tooltip?: string;
}

export const EditStatusExplanation = (props: EditExplanationStatusProps & Antd3FormProps) => {
    const getFormattedStatusValue = (): string => {
        const statusValue = props.form.getFieldValue('status') || props.benefitStatus;

        return titleCase(statusValue);
    };

    const showTextArea = (): boolean =>
        [
            BenefitStatus.PARTIALLY_DELIVERED,
            BenefitStatus.SUBSTITUTED,
            BenefitStatus.OVERDUE,
            BenefitStatus.DELAYED,
            BenefitStatus.NOT_DELIVERED,
            BenefitStatus.ABANDONED,
        ].includes(getFormattedStatusValue() as BenefitStatus);

    const showPartiallyDeliveredValues = (): boolean =>
        getFormattedStatusValue() === BenefitStatus.PARTIALLY_DELIVERED;

    return (
        <>
            <Select
                form={props.form}
                id="status"
                label="Benefit Status"
                initialValue={
                    props.defaultValueForNewBenefit
                        ? benefitStatuses[0].name
                        : titleCase(props.benefitStatus)
                }
                options={benefitStatuses}
                required={true}
                extra={
                    showTextArea()
                        ? 'Your status explanation will be cleared after status change.'
                        : ''
                }
                tooltip={props.tooltip}
            />
            {showTextArea() ? (
                <TextArea
                    form={props.form}
                    id="statusExplanation"
                    placeholder="Status Explanation"
                    initialValue={props.benefitStatusExplanation}
                    required={false}
                    tooltip={uniqueBenefitTooltips.BenefitStatusExplanation}
                />
            ) : null}

            {showPartiallyDeliveredValues() ? (
                <>
                    <Input
                        form={props.form}
                        id="deliveredPoints"
                        label="Points Delivered"
                        required={true}
                        initialValue={props.deliveredPoints}
                        type="number"
                        validator={checkDecimalPlaceConfirmation}
                    />
                    <Input
                        form={props.form}
                        id="deliveredValue"
                        label="Value Delivered"
                        required={true}
                        initialValue={props.deliveredValue}
                        type="number"
                        validator={checkDecimalPlaceConfirmation}
                    />
                    <Input
                        form={props.form}
                        id="deliveredEmissionSavings"
                        label="Emission Savings Delivered"
                        required={true}
                        initialValue={props.deliveredEmissionSavings}
                        type="number"
                        validator={checkDecimalPlaceConfirmation}
                    />
                </>
            ) : null}
        </>
    );
};
