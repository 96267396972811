import * as React from 'react';
import { List, Tooltip, Button } from 'antd';

import { IdValueNameObject } from 'common/models/defaultsModel';
import { InviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { Antd3Icon } from 'common/components/deprecated/antd3';

interface NewUserRolesListProps {
    items?: IdValueNameObject[];
    deleteItem(user: InviteByEmailWithRole): void;
    noData?: string;
}

export const NewUserRolesList: React.FunctionComponent<NewUserRolesListProps> = ({
    items,
    deleteItem,
    noData,
}) => (
    <List
        dataSource={items}
        renderItem={(item: IdValueNameObject) => (
            <List.Item
                actions={[
                    <Tooltip placement="left" title={`Unassign ${item.name}`}>
                        <Button
                            size="small"
                            type="danger"
                            ghost
                            onClick={() => item.id && deleteItem(item.id)}
                        >
                            <Antd3Icon type="delete" />
                            Remove
                        </Button>
                    </Tooltip>,
                ]}
            >
                <List.Item.Meta title={item.name} />
            </List.Item>
        )}
        locale={{ emptyText: noData || 'No values added' }}
    />
);
