import * as React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import { Profile } from 'features/user/models/userModel';
import { breakpoints } from 'theme/variables';
import { Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input, PhoneInput, ResetButton } from 'common/components/deprecated/form';
import { CenteringFormItem } from 'common/components/styled';
import { PaddedSection } from 'common/components';

const SubmitButtonsWrapper = styled(CenteringFormItem)`
    button {
        margin-right: 20px;
    }
    ${breakpoints.small} {
        button {
            margin: 0 20px;
        }
    }
`;

export interface SubmitButtonsProps {
    showInviteButton?: boolean;
    showSaveButton?: boolean;
    isFetching?: boolean;
}

interface SupplierUserFormProps extends SubmitButtonsProps {
    profile: Profile;
    isFetching?: boolean;
}

type Props = Antd3FormProps & SupplierUserFormProps;

export const SubmitButtons: React.FunctionComponent<SubmitButtonsProps> = ({
    showInviteButton,
    showSaveButton,
    isFetching,
}) => (
    <SubmitButtonsWrapper>
        {showInviteButton && (
            <Button type="primary" htmlType="submit" disabled={showSaveButton}>
                Invite
            </Button>
        )}
        {showSaveButton && (
            <Button
                disabled={!showSaveButton}
                type="primary"
                htmlType="submit"
                loading={isFetching}
            >
                Save
            </Button>
        )}
    </SubmitButtonsWrapper>
);

export const SupplierUserForm: React.FunctionComponent<Props> = ({ profile, ...props }) => {
    const { form } = props;
    const {
        telephone,
        user: { firstName, lastName },
    } = profile;

    return (
        <PaddedSection>
            <ResetButton form={form} />
            <Input form={form} id="firstName" label="First name" initialValue={firstName} />
            <Input form={form} id="lastName" label="Last Name" initialValue={lastName} />
            <PhoneInput
                form={form}
                id="telephone"
                placeholder="Telephone Number"
                initialValue={telephone}
            />
            <SubmitButtons {...props} />
        </PaddedSection>
    );
};
