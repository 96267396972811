import { BenefitEvidenceContainer } from '../containers/BenefitEvidenceContainer';
import { BenefitScoreContainer } from '../containers/BenefitScoreContainer';
import { ApprovedUploadContainer } from '../containers/ApprovedUploadContainer';
import { UploadEvidenceContainer } from '../containers/UploadEvidenceContainer';
import { UploadPendingEvidenceContainer } from '../containers/UploadPendingEvidenceContainer';
import { UploadScoreContainer } from '../containers/UploadScoreContainer';
import { UploadPaths, UploadTitles } from '../models/uploadModels';
import { EditPaths } from 'features/edit/models/editModel';

const menuLinks = [
    { key: UploadPaths.Evidence, label: UploadTitles.Evidence },
    { key: UploadPaths.PendingEvidence, label: UploadTitles.PendingEvidence },
    { key: UploadPaths.ApprovedEvidence, label: UploadTitles.ApprovedEvidence },
    { key: UploadPaths.Score, label: UploadTitles.Score },
    { key: UploadPaths.ApprovedScore, label: UploadTitles.ApprovedScore },
];

export const generateUploadBenefitEvidencePath = (
    organisationId: string | number,
    contractId: string | number,
    benefitId: string | number,
) =>
    `${UploadPaths.Organisation}/${organisationId}${UploadPaths.Evidence}${EditPaths.Contract}/${contractId}/benefit/${benefitId}`;

export const generateUploadBenefitScorePath = (
    organisationId: string | number,
    contractId: string | number,
    benefitId: string | number,
) =>
    `${UploadPaths.Organisation}/${organisationId}${UploadPaths.Score}${EditPaths.Contract}/${contractId}/benefit/${benefitId}`;

export const uploadRoutes = [
    {
        path: UploadPaths.Evidence,
        component: UploadEvidenceContainer,
        exact: true,
    },
    {
        path: UploadPaths.PendingEvidence,
        component: UploadPendingEvidenceContainer,
        exact: true,
    },
    {
        path: UploadPaths.ApprovedEvidence,
        component: ApprovedUploadContainer,
    },
    {
        path: generateUploadBenefitEvidencePath(':organisationId', ':contractId', ':benefitId'),
        component: BenefitEvidenceContainer,
    },
    {
        path: UploadPaths.Score,
        component: UploadScoreContainer,
        exact: true,
    },
    {
        path: generateUploadBenefitScorePath(':organisationId', ':contractId', ':benefitId'),
        component: BenefitScoreContainer,
    },
    {
        path: UploadPaths.ApprovedScore,
        component: ApprovedUploadContainer,
    },
].map((route) => ({
    ...route,
    menuLinks,
}));
