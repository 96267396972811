import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { ContractBenefit, BenefitPathParams } from 'features/benefit/models/benefitModels';
import { RouteParams, getRouteId, getPageNumber } from 'common/selectors/routeSelectors';
import { deleteBenefitRequest } from 'features/benefit/actions';
import {
    getContractBenefits,
    getFilteringContractBenefitsStatus,
    getFilteredContractId,
} from 'features/benefit/selectors/benefitSelectors';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { isAdmin } from 'features/user/selectors/userSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import { PaginationView } from 'features/pagination/models/paginationModel';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';

import { ContractBenefits as ContractBenefitsComponent } from '../components/ContractBenefits';
import { isContractManager } from '../selectors/contractSelectors';

interface ContractBenefitsStateProps {
    contractBenefits: ContractBenefit[];
    contractId: number;
    isFiltered: boolean;
    filteredContractId: number;
    filter?: PaginationFilters;
    pageNumber?: number;
    isContractManager: boolean;
    isAdmin: boolean;
}

interface ContractBenefitsDispatchProps {
    deleteBenefitRequest: typeof deleteBenefitRequest;
    redirectToUrl: typeof redirectToUrl;
    clearFilters: typeof clearFilters;
    autocompleteRequest: typeof autocompleteRequest;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
    getPageNumber: typeof getPageNumber;
}

export type ContractBenefitsProps = ContractBenefitsStateProps &
    ContractBenefitsDispatchProps &
    RouteParams;

class ContractBenefits extends React.Component<ContractBenefitsProps> {
    public deleteBenefit = (benefitId: number) => {
        const deleteBenefitData: BenefitPathParams = {
            benefitId,
            contractId: this.props.contractId,
        };

        this.props.deleteBenefitRequest(deleteBenefitData);
    };

    public componentWillUnmount(): void {
        this.props.clearFilters(PaginationView.ContractBenefits);
    }

    public render(): JSX.Element {
        return <ContractBenefitsComponent deleteBenefit={this.deleteBenefit} {...this.props} />;
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams): ContractBenefitsStateProps => ({
    contractBenefits: getContractBenefits(state),
    contractId: getRouteId(ownProps),
    isFiltered: getFilteringContractBenefitsStatus(state),
    filteredContractId: getFilteredContractId(state),
    filter: getFilterParams(state, PaginationView.ContractBenefits),
    pageNumber: getPageNumber(ownProps),
    isContractManager: isContractManager(state),
    isAdmin: isAdmin(state),
});

export const ContractBenefitsContainer = connect(mapStateToProps, {
    deleteBenefitRequest,
    redirectToUrl,
    clearFilters,
    autocompleteRequest,
    saveFilters,
    paginationRequest,
})(ContractBenefits);
