import * as React from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NativeButtonProps } from 'antd/lib/button/button';
import { PlusOutlined } from '@ant-design/icons';

import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { FormFieldIds } from 'common/helpers/utils';
import { PaginationRequestAction } from 'features/pagination/actions/paginationActions';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { EditPaths } from 'features/edit/models/editModel';
import { Antd3Form, Antd3FormProps, WrappedFormUtils } from 'common/components/deprecated/antd3';
import { CreatePaths } from 'features/create/models/createModel';
import { EditContractPaths } from '../models/contractModels';

export interface ContractBenefitsButtonsProps {
    contractId: number;
}

export interface FormProps {
    searchByBenefits(benefitReferenceNumber: string): PaginationRequestAction;
    onRemoveBenefitAutocomplete(): PaginationRequestAction;
    clearFilters(): void;

    form: WrappedFormUtils;
    filter?: PaginationFilters;
}

const SearchWrapper = styled.div`
    width: 35%;
    height: 35px;
    margin: 0 10px;
`;

const ClearFilterButton = styled((props: NativeButtonProps) => <Button {...props} />)`
    && {
        height: 32px;
    }
`;

type ButtonProps = ContractBenefitsButtonsProps & FormProps & Antd3FormProps;

export const ContractBenefitsButtons: React.FunctionComponent<ButtonProps> = ({
    contractId,
    searchByBenefits,
    onRemoveBenefitAutocomplete,
    clearFilters,
    form,
    filter,
}) => (
    <Antd3Form.Item>
        <Row type="flex" justify="space-between">
            <Col>
                <Link to={`${EditPaths.Contract}/${contractId}${EditContractPaths.BenefitsFilter}`}>
                    <Button type="primary">Filter</Button>
                </Link>
            </Col>
            <SearchWrapper>
                <AutocompleteSelect
                    id={FormFieldIds.QuickFilterBenefitReferenceNumber}
                    placeholder="Search by Benefits"
                    form={form}
                    autocompleteField={AutocompleteField.ContractBenefits}
                    onSelect={searchByBenefits}
                    onRemove={onRemoveBenefitAutocomplete}
                    notFoundContent="No benefits found"
                    requiredMessage="Benefit is required"
                    showLabel={false}
                    defaultValue={filter && filter.values.referenceNumber}
                    requestParams={{ contractId }}
                />
            </SearchWrapper>
            <Col>
                <ClearFilterButton onClick={clearFilters}>Clear Filter</ClearFilterButton>
            </Col>
            <Col>
                <Link to={`${CreatePaths.Benefit}/${contractId}`}>
                    <Button type="primary" icon={<PlusOutlined />}>
                        New Benefit
                    </Button>
                </Link>
            </Col>
        </Row>
    </Antd3Form.Item>
);
