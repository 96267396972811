import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { UpdateUserData as UpdateUserDataComponent } from '../components/UpdateUserData';
import { updateUserDataRequest } from '../actions/userActions';
import { Profile } from '../models/userModel';
import { getProfile, isAdmin } from '../selectors/userSelectors';

interface UpdateUserDataStateProps {
    profile: Profile;
    isUserAdmin: boolean;
}

interface UpdateUserDataDispatchProps {
    updateUserDataRequest: typeof updateUserDataRequest;
}

export type UpdateUserDataProps = UpdateUserDataStateProps & UpdateUserDataDispatchProps;

const UpdateUserData = (props: UpdateUserDataProps): JSX.Element => (
    <UpdateUserDataComponent {...props} />
);

const mapStateToProps = createSelector(
    getProfile,
    isAdmin,
    (profile, isUserAdmin): UpdateUserDataStateProps => ({ profile, isUserAdmin }),
);

export const UpdateUserDataContainer = connect(mapStateToProps, {
    updateUserDataRequest,
})(UpdateUserData);
