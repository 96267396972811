import React from 'react';

interface CustomOptionProps {
    organisation?: number;
    contract?: number;
}

export const CustomOption: React.FunctionComponent<CustomOptionProps> = ({
    children,
    ...props
}) => <div {...props}>{children}</div>;
