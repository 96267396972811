import * as React from 'react';
import { Button } from 'antd';

import { FormFieldIds } from 'common/helpers/utils';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';

import { WrappedFormUtils } from './deprecated/antd3';
import { TableFiltersBar } from './TableFiltersBar';

interface Props {
    form: WrappedFormUtils;
    searchByBenefits?: (value: string | number) => void;
    onRemoveBenefitAutocomplete?: () => void;
    searchByContracts?: (value: string | number) => void;
    onRemoveContractAutocomplete?: () => void;
    searchByExecutiveOrganisation?: (value: string | number) => void;
    onRemoveExecutiveOrganisationAutocomplete?: () => void;
    searchByBuyerOrganisation?: (value: string) => void;
    onRemoveBuyerOrganisationAutocomplete?: () => void;
    filter?: PaginationFilters;
    clearFilters: () => void;
    benefitFilterAutocompleteField: AutocompleteField;
    isTenant: boolean;
}

export const UploadAndCheckTableFiltersBar: React.FunctionComponent<Props> = ({
    form,
    searchByBenefits,
    onRemoveBenefitAutocomplete,
    searchByContracts,
    onRemoveContractAutocomplete,
    searchByExecutiveOrganisation,
    onRemoveExecutiveOrganisationAutocomplete,
    searchByBuyerOrganisation,
    onRemoveBuyerOrganisationAutocomplete,
    filter,
    clearFilters,
    benefitFilterAutocompleteField,
    isTenant,
}) => {
    const filters = [
        <AutocompleteSelect
            id={FormFieldIds.QuickFilterBenefitReferenceNumber}
            placeholder="Search by Benefits"
            form={form}
            autocompleteField={benefitFilterAutocompleteField}
            onSelect={searchByBenefits}
            onRemove={onRemoveBenefitAutocomplete}
            notFoundContent="No benefits found"
            requiredMessage="Benefit is required"
            showLabel={false}
            defaultValue={filter && filter.values.referenceNumber}
        />,
        <AutocompleteSelect
            id={FormFieldIds.QuickFilterContractReferenceNumber}
            placeholder="Search by Projects"
            form={form}
            autocompleteField={
                isTenant ? AutocompleteField.Contract : AutocompleteField.ContractExecutive
            }
            onSelect={searchByContracts}
            onRemove={onRemoveContractAutocomplete}
            notFoundContent="No projects found"
            requiredMessage="Project is required"
            showLabel={false}
            defaultValue={filter && filter.values.contract}
        />,
    ];

    if (searchByExecutiveOrganisation && isTenant) {
        filters.push(
            <AutocompleteSelect
                id={FormFieldIds.Organisation}
                placeholder={`Search by Organisation`}
                form={form}
                autocompleteField={AutocompleteField.ExecutiveOrganisation}
                onSelect={searchByExecutiveOrganisation}
                onRemove={onRemoveExecutiveOrganisationAutocomplete}
                notFoundContent="No organisations found"
                showLabel={false}
            />,
        );
    } else if (searchByBuyerOrganisation && !isTenant) {
        filters.push(
            <AutocompleteSelect
                id={FormFieldIds.Organisation}
                placeholder={`Search by Organisation`}
                form={form}
                autocompleteField={AutocompleteField.TenantOrganisation}
                onSelect={searchByBuyerOrganisation}
                onRemove={onRemoveBuyerOrganisationAutocomplete}
                notFoundContent="No organisations found"
                showLabel={false}
            />,
        );
    }

    return (
        <TableFiltersBar
            filters={filters}
            filtersButtons={[<Button onClick={clearFilters}>Clear Filters</Button>]}
        />
    );
};
