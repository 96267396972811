import { CalendarOutlined, UserOutlined } from '@ant-design/icons';
import { Space, Statistic } from 'antd';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { colors, fontSizes } from 'theme/variables';

const BenefitCardHeaderWrapper = styled(Space)`
    width: 100%;
`;

const StyledSpace = styled(Space)`
    width: 100%;
    justify-content: space-between;
`;

const StyledStatistic = styled(Statistic)<{ small?: boolean }>(
    ({ small }) => `
    .ant-statistic-title {
        font-size: ${small ? '12px' : fontSizes.small};
    }
    .ant-statistic-content {
        font-size: ${small ? '14px' : fontSizes.small};
    }
`,
);

type Props = {
    date?: string;
    dateTitle?: string;
    author: string;
    createdAt: string;
};

export const BenefitCardHeader = ({
    dateTitle,
    date,
    author,
    createdAt,
    children,
}: PropsWithChildren<Props>) => (
    <BenefitCardHeaderWrapper direction="vertical" size="middle">
        {children}
        <StyledSpace size="large">
            {date && dateTitle && (
                <StyledStatistic
                    title={dateTitle}
                    value={date}
                    prefix={<CalendarOutlined color={colors.borderGray} />}
                />
            )}
            <Space size="large" align="center">
                <StyledStatistic
                    title="Submitted by"
                    value={author}
                    prefix={<UserOutlined color={colors.borderGray} />}
                    small
                />
                <StyledStatistic
                    title="Created at"
                    value={createdAt}
                    prefix={<CalendarOutlined color={colors.borderGray} />}
                    small
                />
            </Space>
        </StyledSpace>
    </BenefitCardHeaderWrapper>
);
