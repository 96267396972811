import * as React from 'react';
import { connect } from 'react-redux';

import { isTenant } from 'features/user/selectors/userSelectors';
import { saveFilters, clearFilters } from 'features/pagination/actions/paginationActions';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterFormInitialValues } from 'features/pagination/selectors/paginationSelectors';

import { AppState } from '../appState';
import { BenefitsFilter as BenefitsFilterComponent } from '../components/BenefitsFilter';
import { getFilterId, getFilterBackUrl, RouteParams } from '../selectors/routeSelectors';

interface BenefitsFilterStateProps {
    tenant: boolean;
    filterId: number;
    backUrl: string;
    formInitial: PaginationFilters;
}

interface BenefitsFilterDispatchProps {
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
}

export type BenefitsFilterContainerProps = BenefitsFilterStateProps & BenefitsFilterDispatchProps;

export class BenefitsFilter extends React.Component<BenefitsFilterContainerProps> {
    public render(): JSX.Element {
        return <BenefitsFilterComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams) => ({
    tenant: isTenant(state),
    backUrl: getFilterBackUrl(ownProps),
    filterId: getFilterId(state, ownProps),
    formInitial: getFilterFormInitialValues(state, ownProps),
});

export const BenefitsFilterContainer = connect(mapStateToProps, { saveFilters, clearFilters })(
    BenefitsFilter,
);
