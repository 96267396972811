import { RoleInvitation } from 'features/contract/models/contractModels';

import * as actions from '../actions/inviteActions';
import {
    EmployeeInvitation,
    OrganisationInvitation,
    InvitedExecutives,
} from '../models/inviteModel';

export interface InviteState {
    isFetching: boolean;
    employeesPendingInvitations: EmployeeInvitation[];
    employeesExpiredInvitations: EmployeeInvitation[];
    rolesPendingInvitations: RoleInvitation[];
    rolesExpiredInvitations: RoleInvitation[];
    newOrganisationPendingInvitations: OrganisationInvitation[];
    existingOrganisationPendingInvitations: OrganisationInvitation[];
    selectedOrganisationInvitedIndividualExecutive: InvitedExecutives;
}
const defaultInvitedExecutives = {
    results: [{ id: -1, email: '', organisation: -1, organisationName: '' }],
};

const defaultInviteState = {
    isFetching: false,
    employeesPendingInvitations: [],
    employeesExpiredInvitations: [],
    rolesPendingInvitations: [],
    rolesExpiredInvitations: [],
    newOrganisationPendingInvitations: [],
    existingOrganisationPendingInvitations: [],
    selectedOrganisationInvitedIndividualExecutive: defaultInvitedExecutives,
};

export const inviteReducer = (
    state: InviteState = defaultInviteState,
    action: actions.InviteActions,
) => {
    switch (action.type) {
        case actions.INVITE_EMPLOYEE_REQUESTED:
        case actions.GET_EMPLOYEES_PENDING_INVITATIONS_REQUESTED:
        case actions.GET_EMPLOYEES_EXPIRED_INVITATIONS_REQUESTED:
        case actions.GET_EXISTING_ORG_PENDING_INVITATIONS_REQUESTED:
        case actions.GET_NEW_ORG_PENDING_INVITATIONS_REQUESTED:
        case actions.GET_ROLES_PENDING_INVITATIONS_REQUESTED:
        case actions.GET_ROLES_EXPIRED_INVITATIONS_REQUESTED:
        case actions.GET_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED:
            return {
                ...state,
                isFetching: true,
            };
        case actions.INVITE_EMPLOYEE_SUCCEED:
        case actions.INVITE_EMPLOYEE_FAILED:
            return {
                ...state,
                isFetching: false,
            };
        case actions.GET_EMPLOYEES_PENDING_INVITATIONS_SUCCEED:
        case actions.GET_EMPLOYEES_EXPIRED_INVITATIONS_SUCCEED:
        case actions.GET_EXISTING_ORG_PENDING_INVITATIONS_SUCCEED:
        case actions.GET_NEW_ORG_PENDING_INVITATIONS_SUCCEED:
        case actions.GET_ROLES_PENDING_INVITATIONS_SUCCEED:
        case actions.GET_ROLES_EXPIRED_INVITATIONS_SUCCEED:
        case actions.GET_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
            };
        case actions.GET_EMPLOYEES_PENDING_INVITATIONS_FAILED:
        case actions.GET_EMPLOYEES_EXPIRED_INVITATIONS_FAILED:
        case actions.GET_EXISTING_ORG_PENDING_INVITATIONS_FAILED:
        case actions.GET_NEW_ORG_PENDING_INVITATIONS_FAILED:
        // case actions.GET_ROLES_PENDING_INVITATIONS_SUCCEED:
        // case actions.GET_ROLES_EXPIRED_INVITATIONS_SUCCEED:
        case actions.GET_INVITED_INDIVIDUAL_EXECUTIVE_FAILED:
            return {
                isFetching: false,
            };
        default:
            return state;
    }
};
