import { DefaultOptionType } from 'antd/lib/select';

export enum QuestionnaireQuestionType {
    TEXT = 'text',
    NUMERICAL = 'numerical',
    CHOICE = 'choice',
    MULTIPLE_CHOICE = 'multiple_choice',
}

export const benefitQuestionnaireQuestionTypeNames = {
    [QuestionnaireQuestionType.TEXT]: 'Text',
    [QuestionnaireQuestionType.NUMERICAL]: 'Numerical',
    [QuestionnaireQuestionType.CHOICE]: 'Single choice',
    [QuestionnaireQuestionType.MULTIPLE_CHOICE]: 'Multiple choice',
};

export interface FormQuestionnaireQuestion {
    id?: string;
    type: QuestionnaireQuestionType;
    index: number;
    label: string;
    body: string;
    choices?: DefaultOptionType[];
    isOptional?: boolean;
}

export interface QuestionnaireQuestion extends FormQuestionnaireQuestion {
    id: string;
}

export enum BenefitQuestionnairesEditabilityType {
    EVIDENCE_ASSESSOR = 'isEvidenceAssessorQuestionnaireEditable',
    SCORE_ASSESSOR = 'isScoreAssessorQuestionnaireEditable',
    EVIDENCE_SUPPLIER = 'isEvidenceSupplierQuestionnaireEditable',
    SCORE_SUPPLIER = 'isScoreSupplierQuestionnaireEditable',
    EVIDENCE_DELIVERY_PARTNER = 'isEvidenceDeliveryPartnerQuestionnaireEditable',
}

export interface BenefitQuestionnairesEditability {
    [BenefitQuestionnairesEditabilityType.EVIDENCE_ASSESSOR]?: boolean;
    [BenefitQuestionnairesEditabilityType.SCORE_ASSESSOR]?: boolean;
    [BenefitQuestionnairesEditabilityType.EVIDENCE_SUPPLIER]?: boolean;
    [BenefitQuestionnairesEditabilityType.SCORE_SUPPLIER]?: boolean;
    [BenefitQuestionnairesEditabilityType.EVIDENCE_DELIVERY_PARTNER]?: boolean;
}

export enum BenefitQuestionnaireType {
    EVIDENCE_ASSESSOR = 'evidenceAssessorQuestionnaire',
    SCORE_ASSESSOR = 'scoreAssessorQuestionnaire',
    EVIDENCE_SUPPLIER = 'evidenceSupplierQuestionnaire',
    SCORE_SUPPLIER = 'scoreSupplierQuestionnaire',
    EVIDENCE_DELIVERY_PARTNER = 'evidenceDeliveryPartnerQuestionnaire',
}

export interface BenefitQuestionnaires {
    [BenefitQuestionnaireType.EVIDENCE_ASSESSOR]?: QuestionnaireQuestion[] | null;
    [BenefitQuestionnaireType.SCORE_ASSESSOR]?: QuestionnaireQuestion[] | null;
    [BenefitQuestionnaireType.EVIDENCE_SUPPLIER]?: QuestionnaireQuestion[] | null;
    [BenefitQuestionnaireType.SCORE_SUPPLIER]?: QuestionnaireQuestion[] | null;
    [BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER]?: QuestionnaireQuestion[] | null;
}

export const benefitQuestionnairesTypesNames = {
    [BenefitQuestionnaireType.EVIDENCE_ASSESSOR]: 'Contributor Evidence Questions',
    [BenefitQuestionnaireType.SCORE_ASSESSOR]: 'Contributor Feedback Questions',
    [BenefitQuestionnaireType.EVIDENCE_SUPPLIER]: 'Supplier Evidence Questions',
    [BenefitQuestionnaireType.SCORE_SUPPLIER]: 'Supplier Feedback Questions',
    [BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER]: 'Delivery Partner Evidence Questions',
};

export enum SubmittableQuestionnaireType {
    EVIDENCE = 'evidenceQuestionnaire',
    SCORE = 'scoreQuestionnaire',
}

export interface SubmittableQuestionnaires {
    [SubmittableQuestionnaireType.EVIDENCE]: QuestionnaireQuestion[];
    [SubmittableQuestionnaireType.SCORE]: QuestionnaireQuestion[];
}

export type QuestionnaireType = BenefitQuestionnaireType | SubmittableQuestionnaireType;

export type QuestionnaireAnswerValue = undefined | string | DefaultOptionType | DefaultOptionType[];

export interface QuestionnaireAnswer {
    index: number;
    questionId?: string; // TODO: Change on backend to questionId during submit also
    value: QuestionnaireAnswerValue;
}
