import * as React from 'react';
import { connect } from 'react-redux';

import { getContractId, RouteParams } from 'common/selectors/routeSelectors';
import { AppState } from 'common/appState';
import { clearFilters, saveFilters } from 'features/pagination/actions/paginationActions';

import { EditBenefitsFilter as EditBenefitsFilterComponent } from '../components/EditBenefitsFilter';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { isTenant } from 'features/user/selectors/userSelectors';

interface EditBenefitsFilterStateProps {
    contractId: number;
    formInitial: PaginationFilters;
    isTenant: boolean;
}

interface EditBenefitsFilterDispatchProps {
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
}

export type EditBenefitsFilterContainerProps = EditBenefitsFilterStateProps &
    EditBenefitsFilterDispatchProps &
    RouteParams;

export class EditBenefitsFilter extends React.Component<EditBenefitsFilterContainerProps> {
    public render(): JSX.Element {
        return <EditBenefitsFilterComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams): EditBenefitsFilterStateProps => ({
    contractId: ownProps.match.params.id,
    formInitial: getFilterParams(state, PaginationView.ContractBenefits)!,
    isTenant: isTenant(state),
});

export const EditBenefitsFilterContainer = connect(mapStateToProps, {
    saveFilters,
    clearFilters,
})(EditBenefitsFilter);
