import * as React from 'react';
import { InputNumber, Alert } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import styled from 'styled-components';

import { colors } from 'theme/variables';

import { Antd3Form, Antd3FormModel } from '../antd3';

const Item = styled(Antd3Form.Item)`
    && {
        width: 100%;

        :not(:last-child) {
            margin-right: 10px;
        }
    }
`;

const StyledInputNumber = styled(InputNumber)`
    && {
        width: 100%;
        ${({ showError }: StyledInputProps) =>
            showError && `border-color: ${colors.antdErrorText}`};
    }
`;

const Wrapper = styled.div`
    display: flex;
    margin-top: 8px;
`;

const AlertWrapper = styled.div`
    margin-bottom: 10px;
`;

interface Input {
    id: string;
    placeholder: string;
    min?: number;
    max?: number;
}

interface StyledInputProps extends InputNumberProps {
    showError?: boolean;
}

interface NumberInputsRangeProps extends Antd3FormModel {
    fromInput: Input;
    toInput: Input;
    label: string;
    initFrom?: number;
    initTo?: number;
}

interface NumberInputsRangeState {
    fromInputMax: number;
    toInputMin: number;
}

export class NumberInputsRange extends React.Component<
    NumberInputsRangeProps,
    NumberInputsRangeState
> {
    public state: NumberInputsRangeState = {
        fromInputMax: +Infinity,
        toInputMin: 0,
    };

    private changeFromValue = (fromValue: number) => {
        this.props.form.setFieldsValue({ [this.props.fromInput.id]: fromValue });

        this.setState({ toInputMin: fromValue });
    };

    private changeToValue = (toValue: number) => {
        this.props.form.setFieldsValue({ [this.props.toInput.id]: toValue });

        this.setState({ fromInputMax: toValue });
    };

    public render(): JSX.Element {
        const { label, toInput, fromInput, form, initFrom, initTo } = this.props;
        const { fromInputMax, toInputMin } = this.state;

        const fromValue = form.getFieldValue(fromInput.id);
        const toValue = form.getFieldValue(toInput.id);

        const showError = fromValue >= fromInputMax || toValue <= toInputMin;

        return (
            <>
                <h4>{label}:</h4>
                <Wrapper>
                    <Item>
                        {form.getFieldDecorator(fromInput.id, {
                            initialValue: initFrom,
                        })(
                            <StyledInputNumber
                                size="large"
                                placeholder={fromInput.placeholder}
                                onChange={this.changeFromValue}
                                showError={showError}
                            />,
                        )}
                    </Item>
                    <Item>
                        {form.getFieldDecorator(toInput.id, {
                            initialValue: initTo,
                        })(
                            <StyledInputNumber
                                size="large"
                                placeholder={toInput.placeholder}
                                onChange={this.changeToValue}
                                showError={showError}
                            />,
                        )}
                    </Item>
                </Wrapper>
                {showError && (
                    <AlertWrapper>
                        <Alert
                            showIcon
                            message={`${label}: Minimum value must be lower than maximum, maximum value must be higher than minimum`}
                            type="error"
                        />
                    </AlertWrapper>
                )}
            </>
        );
    }
}
