import * as React from 'react';
import posthog from 'posthog-js';

import { POSTHOG_API_KEY, SENTRY_ENVIRONMENT } from 'config/env';
import {
    DatePicker,
    TextArea,
    Input,
    PublishRadioButtons,
    Select,
    ResetButton,
    UploadMultiFiles,
} from 'common/components/deprecated/form';
import { SubmitButton } from 'common/components';
import {
    formatUserIdRequest,
    formatOrganisationIdRequest,
    formatPrioritiesRequest,
    formatPriorityToState,
    contractPath,
} from 'common/helpers/utils';
import { contractTooltips } from 'common/tooltips/contractTooltips';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { CreateContractContainerProps } from 'features/create/containers/CreateContractContainer';
import { notRootNodeFilterParam, notArchivedFilterParam } from 'config/config';
import { PrioritiesForm } from 'features/create/components/PrioritiesForm';
import { StyledAntd3Form, Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import {
    contractType,
    status,
    budgetType,
    routeToMarket,
    ContractPriorities,
} from '../models/contractModels';
import styled from 'styled-components';
import { Divider, Upload } from 'antd';
import { sizes } from 'theme/variables';
import { InboxOutlined } from '@ant-design/icons';
import { FileData } from 'features/upload/models/uploadModels';

const StyledUpload = styled(Upload.Dragger)`
    .ant-upload-drag-container {
        width: 100%;
        max-width: ${sizes.formWidth};
    }
    && .anticon-cross {
        display: none;
    }
`;

const StyledFiles = styled.div`
    width: 100%;
    padding: 1.5rem;
`;

type EditContractProps = CreateContractContainerProps & Antd3FormProps;

interface CreateContractState {
    priorities: ContractPriorities[];
}

if (SENTRY_ENVIRONMENT === 'frontend-production') {
    if (POSTHOG_API_KEY) {
        posthog.init(POSTHOG_API_KEY, {
            api_host: 'https://app.posthog.com',
            autocapture: false,
            capture_pageview: false,
        });
    }
}

class CreateContractFormComponent extends React.Component<EditContractProps, CreateContractState> {
    public state: CreateContractState = {
        priorities: [],
    };

    private selectNewPriority = (priorityData: ContractPriorities) => {
        const formattedPriority = formatPriorityToState(priorityData);

        this.setState({
            priorities: [...this.state.priorities, formattedPriority],
        });
    };

    private deletePriority = (priorityId: number) => {
        this.setState({
            priorities: this.state.priorities.filter((item) => item.id !== Number(priorityId)),
        });
    };

    private changePriorityValue = (priorityId: number, value: number) => {
        this.setState({
            priorities: this.state.priorities.map((priority) =>
                priority.id === priorityId ? { ...priority, value } : priority,
            ),
        });
    };

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { managers, suppliers, ...values }) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }

            return this.props.createContractRequest({
                ...values,
                managers: formatUserIdRequest(managers),
                suppliers: formatOrganisationIdRequest(suppliers),
                priorities: formatPrioritiesRequest(this.state.priorities),
            });
        });
    };

    public render(): JSX.Element {
        const { form } = this.props;

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Create Project">
                <ResetButton form={form} />
                <Input
                    form={form}
                    id="title"
                    label="Project Title"
                    tooltip={contractTooltips.ContractTitle}
                />
                <Input
                    form={form}
                    id="referenceNumber"
                    label="Project Reference Number"
                    tooltip={contractTooltips.ContractReferenceNumber}
                />
                <TextArea
                    form={form}
                    id="description"
                    label="Project Description"
                    tooltip={contractTooltips.ContractDescription}
                    required={false}
                />
                <Select
                    form={form}
                    id="contractType"
                    label="Project Type"
                    tooltip={contractTooltips.ContractType}
                    options={contractType}
                    addFileToContract
                    required={false}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ContractCategory}
                    id="category"
                    placeholder="Project Category"
                    notFoundContent="No categories found"
                    tooltip={contractTooltips.ContractCategory}
                    searchParams={notArchivedFilterParam}
                    required={false}
                />
                <AutocompleteSelect
                    autocompleteField={AutocompleteField.ContractPortfolioFilter}
                    form={form}
                    id="portfolioFilter"
                    placeholder="Portfolio"
                    tooltip={contractTooltips.Portfolio}
                    searchParams={{ ...notRootNodeFilterParam, ...notArchivedFilterParam }}
                    required={false}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Department}
                    id="department"
                    placeholder="Department"
                    notFoundContent="No departments found"
                    tooltip={contractTooltips.Department}
                    searchParams={notArchivedFilterParam}
                    required={false}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Location}
                    id="location"
                    placeholder="Project location"
                    notFoundContent="No locations found"
                    tooltip={contractTooltips.ContractLocation}
                    searchParams={notArchivedFilterParam}
                    required={false}
                />
                <Select
                    form={form}
                    id="budget"
                    placeholder="Budget Source"
                    tooltip={contractTooltips.BudgetSource}
                    options={budgetType}
                    required={false}
                    allowClear
                />
                <Select
                    form={form}
                    id="routeToMarket"
                    placeholder="Route to Market"
                    tooltip={contractTooltips.RouteToMarket}
                    options={routeToMarket}
                    required={false}
                    allowClear
                />
                {form.getFieldValue('routeToMarket') && (
                    <AutocompleteSelect
                        form={form}
                        autocompleteField={AutocompleteField.Contract}
                        id="frameworkContract"
                        placeholder="Framework Project"
                        notFoundContent="No project found"
                        tooltip={contractTooltips.FrameworkContract}
                        searchParams={notArchivedFilterParam}
                        required={false}
                    />
                )}
                <Input
                    form={form}
                    id="value"
                    label="Project Value"
                    type="number"
                    tooltip={contractTooltips.ContractValue}
                    required={false}
                />
                <Input
                    form={form}
                    id="targetBenefitValue"
                    label="Target Benefit Value"
                    type="number"
                    tooltip={contractTooltips.TargetBenefitValue}
                    required={false}
                />
                <Input
                    form={form}
                    id="targetBenefitPoints"
                    label="Target Benefit Points"
                    type="number"
                    tooltip={contractTooltips.TargetBenefitPoints}
                    required={false}
                />
                <Input
                    form={form}
                    id="targetBenefitEmissionSavings"
                    label="Target Benefit Emission Savings"
                    type="number"
                    tooltip={contractTooltips.TargetBenefitEmissionSavings}
                    required={false}
                />
                <Input
                    form={form}
                    id="targetBenefitDeliveryNumber"
                    label="Target Benefit Delivery Number"
                    type="number"
                    tooltip={contractTooltips.TargetBenefitDeliveryNumber}
                    required={false}
                />
                <PrioritiesForm
                    form={form}
                    selectedPriorities={this.state.priorities}
                    selectNewPriority={this.selectNewPriority}
                    deletePriority={this.deletePriority}
                    changePriorityValue={this.changePriorityValue}
                    autocompleteField={AutocompleteField.ContractPriorities}
                    id="priorities"
                    placeholder="Project priorities"
                    notFoundContent="No project priorities found"
                    tooltip={contractTooltips.ContractPriorities}
                />
                <DatePicker
                    form={form}
                    id="startDate"
                    message="Please select the project start date"
                    label="Project Start Date"
                    tooltip={contractTooltips.StartDate}
                    required={false}
                />
                <DatePicker
                    form={form}
                    id="endDate"
                    message="Please select the project completion date"
                    label="Project Completion Date"
                    tooltip={contractTooltips.EndDate}
                    required={false}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Employee}
                    id="managers"
                    placeholder="Project Managers"
                    notFoundContent="No project managers found"
                    tooltip={contractTooltips.ContractManagers}
                    searchParams={notArchivedFilterParam}
                    multiple
                    required={false}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ExecutiveOrganisation}
                    id="suppliers"
                    placeholder="Suppliers"
                    notFoundContent="No organisations found"
                    tooltip={contractTooltips.Suppliers}
                    multiple
                    required={false}
                />
                <Select
                    form={form}
                    id="status"
                    label="Project Status"
                    tooltip={contractTooltips.ContractStatus}
                    options={status}
                />
                <PublishRadioButtons required form={form} tooltip={contractTooltips.Published} />
                <Divider />
                <label>Project Files</label>
                <StyledFiles>
                    <StyledUpload disabled>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-hint">Please save the Project to add files</p>
                    </StyledUpload>
                </StyledFiles>

                <SubmitButton value="Create Project" />
            </StyledAntd3Form>
        );
    }
}

export const CreateContractForm = Antd3Form.create({})(CreateContractFormComponent);
