import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { EditContractForm } from 'features/contract/components/EditContractForm';
import {
    Contract,
    OrganisationRole,
    ContractPriorities,
    AddContractPriorityRequest,
    EditContractPriorityRequest,
} from 'features/contract/models/contractModels';
import {
    editContractRequest,
    getContractRolesRequest,
    addContractRoleRequest,
    deleteContractRoleRequest,
    getContractRequest,
    getContractPrioritiesRequest,
    addContractPriorityRequest,
    editContractPriorityRequest,
    deleteContractPriorityRequest,
    getContractFilesRequest,
    deleteContractFileRequest,
} from 'features/contract/actions/contractActions';
import {
    getContractByRouteId,
    getContractManagers,
    getContractSuppliers,
    getFetchingContractsStatus,
    getContractPriorities,
    isContractManager,
    getContractFiles,
} from 'features/contract/selectors/contractSelectors';
import { Profile, EditEmployeeRouteParams } from 'features/user/models/userModel';
import { getRouteId, routeParams } from 'common/selectors/routeSelectors';
import { getExecutivesRequest } from 'features/organisation/actions/organisationActions';
import { getEmployeesRequest } from 'features/user/actions/userActions';
import { redirectToUrl } from 'common/actions/navigationAction';
import { isAdmin } from 'features/user/selectors/userSelectors';
import { FileData } from 'features/upload/models/uploadModels';

export interface EditContractContainerStateProps {
    contractId: number;
    contract: Contract | undefined;
    contractManagers: Profile[];
    contractSuppliers: OrganisationRole[];
    isFetching: boolean;
    priorities: ContractPriorities[];
    isContractManager: boolean;
    isAdmin: boolean;
    contractFiles: FileData[];
}

export interface EditContractContainerDispatchProps {
    editContractRequest: typeof editContractRequest;
    getContractRolesRequest: typeof getContractRolesRequest;
    addContractRoleRequest: typeof addContractRoleRequest;
    deleteContractRoleRequest: typeof deleteContractRoleRequest;
    getContractRequest: typeof getContractRequest;
    getEmployeesRequest: typeof getEmployeesRequest;
    getExecutivesRequest: typeof getExecutivesRequest;
    getContractPrioritiesRequest: typeof getContractPrioritiesRequest;
    addContractPriorityRequest: typeof addContractPriorityRequest;
    editContractPriorityRequest: typeof editContractPriorityRequest;
    deleteContractPriorityRequest: typeof deleteContractPriorityRequest;
    getContractFilesRequest: typeof getContractFilesRequest;
    redirectToUrl: typeof redirectToUrl;
    deleteContractFileRequest: typeof deleteContractFileRequest;
}

export type EditContractContainerProps = EditContractContainerStateProps &
    EditContractContainerDispatchProps &
    EditEmployeeRouteParams;

class EditContract extends React.Component<EditContractContainerProps> {
    public componentDidMount(): void {
        this.props.getContractRequest({ contractId: this.props.contractId });
        this.props.getContractRolesRequest(this.props.contractId);
        this.props.getEmployeesRequest();
        this.props.getExecutivesRequest();
        this.props.getContractPrioritiesRequest(this.props.contractId);
        this.props.getContractFilesRequest({
            contractId: this.props.contractId,
            organisationId: this.props.contract?.organisation,
        });
    }

    private updatePriorities = () => {
        this.props.getContractPrioritiesRequest(this.props.contractId);
    };

    private addPriority = (data: AddContractPriorityRequest) =>
        this.props.addContractPriorityRequest(data);

    private editPriority = (data: EditContractPriorityRequest) =>
        this.props.editContractPriorityRequest(data);

    private deletePriority = (data: EditContractPriorityRequest) =>
        this.props.deleteContractPriorityRequest(data);

    public render(): JSX.Element {
        return (
            <EditContractForm
                updatePriorities={this.updatePriorities}
                addPriority={this.addPriority}
                editPriority={this.editPriority}
                deletePriority={this.deletePriority}
                {...this.props}
            />
        );
    }
}

function mapStateToProps(
    state: AppState,
    ownProps: EditEmployeeRouteParams,
): EditContractContainerStateProps {
    return {
        contractId: getRouteId(ownProps),
        contract: getContractByRouteId(state, routeParams(ownProps)),
        isFetching: getFetchingContractsStatus(state),
        contractManagers: getContractManagers(state),
        contractSuppliers: getContractSuppliers(state),
        priorities: getContractPriorities(state),
        isContractManager: isContractManager(state),
        isAdmin: isAdmin(state),
        contractFiles: getContractFiles(state),
    };
}

export const EditContractFormContainer = connect(mapStateToProps, {
    getContractRequest,
    editContractRequest,
    getContractRolesRequest,
    addContractRoleRequest,
    deleteContractRoleRequest,
    getExecutivesRequest,
    getEmployeesRequest,
    getContractPrioritiesRequest,
    addContractPriorityRequest,
    editContractPriorityRequest,
    deleteContractPriorityRequest,
    redirectToUrl,
    getContractFiles,
    deleteContractFileRequest,
    getContractFilesRequest,
})(EditContract);
