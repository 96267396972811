export enum CheckPaths {
    PendingEvidence = '/check/pending-evidences',
    Evidence = '/check/evidences',
    ApprovedEvidence = '/check/approved-evidences',
    Scores = '/check/feedback',
    ApprovedScores = '/check/approved-feedback',
}

export interface CheckBenefit {
    id: number;
    outcome: string;
    contractTitle: string;
    contract: number;
    contractReferenceNumber: string;
    referenceNumber: string;
}

export interface CheckActiveBenefit {
    id: number;
    outcome: string;
    contractTitle: string;
    contract: number;
    contractReferenceNumber: string;
    referenceNumber: string;
    hasPendingEvidence: boolean;
}
