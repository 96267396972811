import * as _ from 'lodash';
import { inject, injectable } from 'inversify';

import { HttpService, RequestBody } from 'common/services/HttpService';
import { SERVICE } from 'config/identifiers';
import { config, organisationUrl, route } from 'config/config';
import { dateRequestFormat, snakeUpperCase, publishStatus } from 'common/helpers/utils';
import { Role } from 'features/user/models/userModel';
import { filteringRequestPath } from 'common/helpers/url';
import {
    ArchiveFilterRequest,
    ArchiveFilterResponse,
    EditFilterRequest,
    Filters,
} from 'features/contract/models/contractModels';

import {
    AssignEmployeeRequest,
    Benefit,
    SelectBenefitTemplateRequest,
    CreateBenefitTemplateRequest,
    ContractBenefit,
    EditBenefitRequest,
    BenefitPathParams,
    BenefitsRolesRequest,
    FilterBenefitRequest,
    FilteredBenefits,
    BenefitDataRequest,
    BenefitRoleData,
    AddBenefitRoleRequest,
    DeleteBenefitRoleRequest,
    BenefitRequest,
    FilterContractBenefitsRequest,
    FilteredContractBenefits,
    BenefitTemplate,
    ChangeBenefitStatusRequest,
    AssignActions,
    ToggleMultipleEmployeesRoleRequest,
    BenefitPriority,
    EditBenefitPriorityRequest,
    AddBenefitPriorityRequest,
    EditBenefitTemplateFilesRequest,
    BenefitNoteData,
    CreateBenefitNoteRequest,
    DeleteBenefitNoteRequest,
    EditBenefitNoteRequest,
    DeleteBenefitNoteFileRequest,
    AddBenefitNoteFileRequest,
    GetBenefitNoteRequest,
    ToggleMultipleInvitePendingExecutiveUsersRoleRequest,
    EditBenefitTemplateRequest,
    MigrateBenefitsRequest,
} from '../models/benefitModels';
import { pickBy } from 'lodash';

@injectable()
export class BenefitService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    public assignEmployees(data: AssignEmployeeRequest[]): Promise<void[]> {
        const requests = data.map(({ benefitId, contractId, role, user, organisationId }) =>
            this.http.POST(
                `${organisationUrl}${organisationId}/contracts/${contractId}/benefits/${benefitId}/roles/`,
                {
                    role,
                    user,
                },
            ),
        );

        return Promise.all(requests);
    }

    public unassignEmployees(data: AssignEmployeeRequest[]): Promise<void[]> {
        const requests = data.map(({ benefitId, contractId, roleId, organisationId }) =>
            this.http.DELETE(
                `${organisationUrl}${organisationId}/contracts/${contractId}/benefits/${benefitId}/roles/${roleId}`,
            ),
        );

        return Promise.all(requests);
    }

    public archiveBenefitTemplate(id: number, archived: boolean): Promise<void> {
        return this.http.PATCH(`${config.routeConfig.benefitTemplates}${id}/`, {
            archived,
        });
    }

    public deleteBenefitTemplate(id: number): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.benefitTemplates}${id}/`);
    }

    public createBenefit({
        startDate,
        finalDeliveryDate,
        deliveryDates,
        status,
        method,
        new_executive_users,
        ...data
    }: BenefitRequest): Promise<Benefit> {
        return this.http.POST(`${config.routeConfig.contract}${data.contract}/benefits/`, {
            ...data,
            finalDeliveryDate: finalDeliveryDate && dateRequestFormat(finalDeliveryDate),
            startDate: startDate && dateRequestFormat(startDate),
            deliveryDates,
            status: status && snakeUpperCase(status),
            method: method && snakeUpperCase(method),
        });
    }

    public createBenefitTemplate({
        priority,
        ...data
    }: CreateBenefitTemplateRequest): Promise<BenefitTemplate> {
        const formattedData = pickBy(
            {
                ...data,
                priority: priority && snakeUpperCase(priority),
            },
            (value) => value !== undefined,
        ) as RequestBody;

        return this.http.POST(config.routeConfig.benefitTemplates, formattedData as any);
    }

    public async getBenefitPriorities(data: BenefitPathParams): Promise<BenefitPriority[]> {
        return this.http.GET<BenefitPriority[]>(
            `${config.routeConfig.contract}${data.contractId}/benefits/${data.benefitId}/priorities/`,
        );
    }

    public async deleteBenefit(data: BenefitPathParams): Promise<number> {
        await this.http.DELETE(
            `${config.routeConfig.contract}${data.contractId}/benefits/${data.benefitId}/`,
        );

        return data.benefitId;
    }

    public async editBenefitPriority(data: EditBenefitPriorityRequest): Promise<BenefitPriorities> {
        return this.http.PATCH<BenefitPriorities>(
            `${config.routeConfig.contract}${data.contractId}/benefits/${data.benefitId}/priorities/${data.priorityId}/`,
            { value: data.value },
        );
    }

    public async deleteBenefitPriority(data: EditBenefitPriorityRequest): Promise<number> {
        await this.http.DELETE(
            `${config.routeConfig.contract}${data.contractId}/benefits/${data.benefitId}/priorities/${data.priorityId}/`,
        );

        return data.priorityId;
    }

    public async addBenefitPriority(data: AddBenefitPriorityRequest): Promise<BenefitPriority[]> {
        return this.http.POST<BenefitPriority[]>(
            `${config.routeConfig.contract}${data.contractId}/benefits/${data.benefitId}/priorities/`,
            data.priority,
        );
    }

    public async migrateBenefitsBetweenTemplates({
        sourceTemplateId,
        targetTemplateId,
    }: MigrateBenefitsRequest): Promise<BenefitTemplate> {
        return this.http.POST(
            `${config.routeConfig.benefitTemplates}${targetTemplateId}/migrate-benefits/`,
            { sourceTemplate: sourceTemplateId },
        );
    }

    public editBenefitTemplate({
        priority,
        ...data
    }: EditBenefitTemplateRequest): Promise<BenefitTemplate> {
        const formattedData = pickBy(
            {
                ...data,
                priority: priority && snakeUpperCase(priority),
            },
            (value) => value !== undefined,
        ) as RequestBody;

        return this.http.PATCH(`${config.routeConfig.benefitTemplates}${data.id}/`, formattedData);
    }

    public editBenefitTemplateFiles(data: EditBenefitTemplateFilesRequest): Promise<void> {
        const formattedData = pickBy(data, (value) => value !== undefined) as RequestBody;

        return this.http.formPATCH(
            `${config.routeConfig.benefitTemplates}${data.id}/`,
            formattedData,
        );
    }

    public deleteFilter({ filterId, filterType }: EditFilterRequest): Promise<Filters> {
        return this.http.DELETE<Filters>(`${config.routeConfig[filterType]}${filterId}/`);
    }

    public async filterBenefits({
        path,
        method,
        contractCategory,
        contractType,
        minStartDate,
        maxStartDate,
        minEndDate,
        maxEndDate,
        published,
        status,
        outcome,
        contractTitle,
        contractReferenceNumber,
        supplierName,
        deliveryPartnerName,
        ...benefitFilters
    }: FilterBenefitRequest): Promise<FilteredBenefits> {
        const filters = {
            ...benefitFilters,
            ...publishStatus(published),
            contractTitle: contractTitle && encodeURIComponent(contractTitle),
            contractReferenceNumber:
                contractReferenceNumber && encodeURIComponent(contractReferenceNumber),
            supplierName: supplierName && encodeURIComponent(supplierName),
            deliveryPartnerName: deliveryPartnerName && encodeURIComponent(deliveryPartnerName),
            outcome: outcome && encodeURIComponent(outcome),
            minStartDate: minStartDate && dateRequestFormat(minStartDate),
            maxStartDate: maxStartDate && dateRequestFormat(maxStartDate),
            minEndDate: minEndDate && dateRequestFormat(minEndDate),
            maxEndDate: maxEndDate && dateRequestFormat(maxEndDate),
            method: method && snakeUpperCase(method),
            contractCategory: contractCategory && snakeUpperCase(contractCategory.toString()),
            contractType: contractType && snakeUpperCase(contractType),
            status: status && snakeUpperCase(status),
        };

        const requestPath = filteringRequestPath(filters);

        const benefits = await this.http.GET<Benefit[]>(
            `${config.routeConfig.allBenefits}?${requestPath}`,
        );

        return { benefits, path };
    }

    public async filterContractBenefits({
        contractId,
        path,
        minStartDate,
        maxStartDate,
        minEndDate,
        maxEndDate,
        status,
        supplierName,
        deliveryPartnerName,
        outcome,
        ...benefitFilters
    }: FilterContractBenefitsRequest): Promise<FilteredContractBenefits> {
        const filters = {
            ...benefitFilters,

            supplierName: supplierName && encodeURIComponent(supplierName),
            deliveryPartnerName: deliveryPartnerName && encodeURIComponent(deliveryPartnerName),
            outcome: outcome && encodeURIComponent(outcome),
            minStartDate: minStartDate && dateRequestFormat(minStartDate),
            maxStartDate: maxStartDate && dateRequestFormat(maxStartDate),
            minEndDate: minEndDate && dateRequestFormat(minEndDate),
            maxEndDate: maxEndDate && dateRequestFormat(maxEndDate),
            status: status && snakeUpperCase(status),
        };

        const requestPath = filteringRequestPath(filters);

        const contractBenefits = await this.http.GET<ContractBenefit[]>(
            `${config.routeConfig.contract}${contractId}/benefits/?${requestPath}`,
        );

        return { contractBenefits, path };
    }

    public editBenefit({
        sharedBenefit,
        sharedBenefitForUrl,
        contract,
        benefitId,
        startDate,
        finalDeliveryDate,
        status,
        method,
        ...data
    }: EditBenefitRequest): Promise<Benefit> {
        const URL = sharedBenefitForUrl
            ? `${config.routeConfig.benefitTemplates}${sharedBenefitForUrl}/benefits/${benefitId}/`
            : `${config.routeConfig.contract}${contract}/benefits/${benefitId}/`;

        return this.http.PATCH<Benefit>(URL, {
            ...data,
            sharedBenefit,
            finalDeliveryDate: finalDeliveryDate && dateRequestFormat(finalDeliveryDate),
            startDate: startDate && dateRequestFormat(startDate),
            status: status && snakeUpperCase(status),
            method: method && snakeUpperCase(method),
        });
    }

    public getMyBenefits(): Promise<Benefit[]> {
        return this.http.GET<Benefit[]>(config.routeConfig.myBenefits);
    }

    public getBenefitCategories(): Promise<Benefit[]> {
        return this.http.GET<Benefit[]>(config.routeConfig.benefitCategories);
    }

    public async getBenefitsRoles({
        benefitIds,
        contractId,
        organisationId,
    }: BenefitsRolesRequest): Promise<Role[]> {
        const path = organisationId
            ? `${organisationUrl}${organisationId}/contracts/`
            : config.routeConfig.contract;

        const request = (benefitId: number) =>
            this.http.GET<Role[]>(`${path}${contractId}/benefits/${benefitId}/roles/`);

        const roles = await Promise.all(benefitIds.map(request));

        return _.flatten(roles);
    }

    public getContractBenefits(id: number): Promise<ContractBenefit[]> {
        return this.http.GET<ContractBenefit[]>(`${config.routeConfig.contract}${id}/benefits/`);
    }

    public getContractBenefit({
        contractId,
        benefitId,
        organisationId,
    }: BenefitDataRequest): Promise<ContractBenefit> {
        const contractBenefitPath = `${contractId}/benefits/${benefitId}/`;

        const path = organisationId
            ? `${organisationUrl}${organisationId}/contracts/${contractBenefitPath}`
            : `${config.routeConfig.contract}${contractBenefitPath}`;
        return this.http.GET<ContractBenefit>(path);
    }

    public getBenefitTemplates(): Promise<Benefit[]> {
        return this.http.GET<Benefit[]>(config.routeConfig.benefitTemplates);
    }

    public getBenefitTemplate(id: number): Promise<BenefitTemplate> {
        return this.http.GET<BenefitTemplate>(`${config.routeConfig.benefitTemplates}${id}/`);
    }

    public selectBenefitTemplate({
        finalDeliveryDate,
        startDate,
        id,
        status,
        method,
        new_executive_users,
        ...benefitTemplate
    }: SelectBenefitTemplateRequest): Promise<BenefitTemplate> {
        return this.http.POST(`${config.routeConfig.benefitTemplates}${id}/benefits/`, {
            ...benefitTemplate,
            finalDeliveryDate: finalDeliveryDate && dateRequestFormat(finalDeliveryDate),
            startDate: startDate && dateRequestFormat(startDate),
            status: status && snakeUpperCase(status),
            method: method && snakeUpperCase(method),
        });
    }

    public addBenefitRole({
        role,
        contractId,
        benefitId,
        organisationId,
        userId,
    }: AddBenefitRoleRequest): Promise<BenefitRoleData> {
        const benefit = userId
            ? {
                  user: userId,
                  organisation: organisationId,
              }
            : {
                  organisation: organisationId,
              };

        return this.http.POST<BenefitRoleData>(
            `${config.routeConfig.contract}${contractId}/benefits/${benefitId}/roles/`,
            {
                ...benefit,
                role: snakeUpperCase(role),
            },
        );
    }

    public async deleteBenefitRole({
        contractId,
        benefitId,
        roleId,
    }: DeleteBenefitRoleRequest): Promise<number> {
        await this.http.DELETE(
            `${config.routeConfig.contract}${contractId}/benefits/${benefitId}/roles/${roleId}/`,
        );

        return roleId;
    }

    public async archiveCategory(filterData: ArchiveFilterRequest): Promise<ArchiveFilterResponse> {
        return this.http.PATCH<ArchiveFilterResponse>(
            `${config.routeConfig.benefitCategories}${filterData.filterId}/`,
            {
                archived: filterData.archived,
            },
        );
    }

    public changeBenefitStatus({
        contractId,
        benefitId,
        status,
        sharedBenefitId,
        organisationId,
    }: ChangeBenefitStatusRequest): Promise<Benefit> {
        const { contract, organisations } = config.routeConfig;
        const { benefitTemplates, benefits } = route;

        return this.http.PATCH<Benefit>(
            sharedBenefitId
                ? `${organisations}${organisationId}/${benefitTemplates}/${sharedBenefitId}/${benefits}/${benefitId}/`
                : `${contract}${contractId}/${benefits}/${benefitId}/`,
            {
                status: status && snakeUpperCase(status),
            },
        );
    }

    public toggleMultipleEmployeesRole({
        action,
        benefitIds,
        userIds,
        organisationId,
    }: ToggleMultipleEmployeesRoleRequest): Promise<Role[]> {
        const { assignEmployees, revokeEmployees } = config.routeConfig;

        return this.http.POST(action === AssignActions.Assign ? assignEmployees : revokeEmployees, {
            benefitIds,
            userIds,
            organisationId,
        });
    }

    public toggleMultipleInvitePendingExecutiveUsersRole({
        action,
        benefitIds,
        userInvitationIds,
        organisationId,
    }: ToggleMultipleInvitePendingExecutiveUsersRoleRequest): Promise<Role[]> {
        const { assignInvitePendingExecutiveUsers, revokeInvitePendingExecutiveUsers } =
            config.routeConfig;

        return this.http.POST(
            action === AssignActions.Assign
                ? assignInvitePendingExecutiveUsers
                : revokeInvitePendingExecutiveUsers,
            {
                benefitIds,
                userInvitationIds,
                organisationId,
            },
        );
    }

    public getBenefitNotes(data: GetBenefitNoteRequest): Promise<BenefitNoteData[]> {
        return this.http.GET<BenefitNoteData[]>(
            `${config.routeConfig.contract}${data.contract}/benefits/${data.benefit}/notes/`,
        );
    }

    public createBenefitNote(data: CreateBenefitNoteRequest): Promise<void> {
        const obj = {};
        Object.entries(data)
            .filter(([, value]) => value !== undefined)
            .forEach(([key, value]) => (obj[key] = value));
        return this.http.formPOST(
            `${config.routeConfig.contract}${data.contract}/benefits/${data.benefit}/notes/`,
            obj,
        );
    }

    public deleteBenefitNote(data: DeleteBenefitNoteRequest): Promise<void> {
        return this.http.DELETE(
            `${config.routeConfig.contract}${data.contract}/benefits/${data.benefit}/notes/${data.id}/`,
        );
    }

    public editBenefitNote(data: EditBenefitNoteRequest): Promise<void> {
        return this.http.PATCH(
            `${config.routeConfig.contract}${data.contract}/benefits/${data.benefit}/notes/${data.id}/`,
            {
                ...data,
            },
        );
    }

    public deleteBenefitNoteFile({
        contract,
        benefit,
        id,
    }: DeleteBenefitNoteFileRequest): Promise<void> {
        return this.http.DELETE(
            `${config.routeConfig.contract}${contract}/benefits/${benefit}/note-files/${id}/`,
        );
    }

    public addBenefitNoteFile(data: AddBenefitNoteFileRequest): Promise<void> {
        const obj = {};
        Object.entries(data)
            .filter(([, value]) => value !== undefined)
            .forEach(([key, value]) => (obj[key] = value));
        return this.http.formPOST(
            `${config.routeConfig.contract}${data.contract}/benefits/${data.benefit}/note-files/`,
            obj,
        );
    }
}
