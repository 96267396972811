import posthog from 'posthog-js';

import { SENTRY_ENVIRONMENT } from 'config/env';
import { Action } from 'common/actions/appAction';
import {
    EvidenceApproval,
    CreateEvidenceRequest,
    CreateEvidenceResponse,
    UpdateEvidenceRequest,
    EvidenceData,
    Evidence,
    GetMyEvidenceRequest,
    FileData,
    MyEvidenceResponse,
    EvidenceDecline,
    DeleteEvidenceFileRequest,
    UploadBenefit,
    ContractManagerDetail,
} from '../models/uploadModels';

export const APPROVE_EVIDENCE_REQUESTED = 'APPROVE_EVIDENCE_REQUESTED';
export type ApproveEvidenceRequestAction = Action<
    typeof APPROVE_EVIDENCE_REQUESTED,
    EvidenceApproval
>;
export const approveEvidenceRequest = (
    evidence: EvidenceApproval,
): ApproveEvidenceRequestAction => ({
    type: APPROVE_EVIDENCE_REQUESTED,
    payload: evidence,
});

export const APPROVE_EVIDENCE_SUCCEED = 'APPROVE_EVIDENCE_SUCCEED';
export type ApproveEvidenceSuccessAction = Action<
    typeof APPROVE_EVIDENCE_SUCCEED,
    Evidence,
    string,
    posthog.CaptureResult | null
>;
export const approveEvidenceSuccess = (evidence: Evidence): ApproveEvidenceSuccessAction => ({
    type: APPROVE_EVIDENCE_SUCCEED,
    payload: evidence,
    meta: 'Evidence approved',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Evidence approved') : null,
});

export const APPROVE_EVIDENCE_FAILED = 'APPROVE_EVIDENCE_FAILED';
export type ApproveEvidenceFailureAction = Action<
    typeof APPROVE_EVIDENCE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const approveEvidenceFailure = (payload: string): ApproveEvidenceFailureAction => ({
    type: APPROVE_EVIDENCE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Evidence approval failed')
            : null,
});

export const DECLINE_EVIDENCE_REQUESTED = 'DECLINE_EVIDENCE_REQUESTED';
export type DeclineEvidenceRequestAction = Action<
    typeof DECLINE_EVIDENCE_REQUESTED,
    EvidenceDecline
>;
export const declineEvidenceRequest = (
    evidence: EvidenceDecline,
): DeclineEvidenceRequestAction => ({
    type: DECLINE_EVIDENCE_REQUESTED,
    payload: evidence,
});

export const DECLINE_EVIDENCE_SUCCEED = 'DECLINE_EVIDENCE_SUCCEED';
export type DeclineEvidenceSuccessAction = Action<
    typeof DECLINE_EVIDENCE_SUCCEED,
    Evidence,
    string,
    posthog.CaptureResult | null
>;
export const declineEvidenceSuccess = (evidence: Evidence): DeclineEvidenceSuccessAction => ({
    type: DECLINE_EVIDENCE_SUCCEED,
    payload: evidence,
    meta: 'Evidence declined',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Evidence rejected') : null,
});

export const DECLINE_EVIDENCE_FAILED = 'DECLINE_EVIDENCE_FAILED';
export type DeclineEvidenceFailureAction = Action<
    typeof DECLINE_EVIDENCE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const declineEvidenceFailure = (payload: string): DeclineEvidenceFailureAction => ({
    type: DECLINE_EVIDENCE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Evidence rejection failed')
            : null,
});

export const GET_EVIDENCES_LIST_REQUESTED = 'GET_EVIDENCES_LIST_REQUESTED';
export type GetEvidencesListRequestAction = Action<
    typeof GET_EVIDENCES_LIST_REQUESTED,
    EvidenceData[]
>;
export const getEvidencesListRequest = (
    evidenceData: EvidenceData[],
): GetEvidencesListRequestAction => ({
    type: GET_EVIDENCES_LIST_REQUESTED,
    payload: evidenceData,
});

export const GET_EVIDENCES_LIST_SUCCEED = 'GET_EVIDENCES_LIST_SUCCEED';
export type GetEvidencesListSuccessAction = Action<
    typeof GET_EVIDENCES_LIST_SUCCEED,
    { evidences: Evidence[] }
>;
export const getEvidencesListSuccess = (evidences: Evidence[]): GetEvidencesListSuccessAction => ({
    type: GET_EVIDENCES_LIST_SUCCEED,
    payload: { evidences },
});

export const GET_EVIDENCES_LIST_FAILED = 'GET_EVIDENCES_LIST_FAILED';
export type GetEvidencesListFailureAction = Action<typeof GET_EVIDENCES_LIST_FAILED, string>;
export const getEvidencesListFailure = (payload: string): GetEvidencesListFailureAction => ({
    type: GET_EVIDENCES_LIST_FAILED,
    payload,
});

export const GET_MY_EVIDENCE_REQUESTED = 'GET_MY_EVIDENCE_REQUESTED';
export type GetMyEvidenceRequestAction = Action<
    typeof GET_MY_EVIDENCE_REQUESTED,
    GetMyEvidenceRequest
>;
export const getMyEvidenceRequest = (
    payload: GetMyEvidenceRequest,
): GetMyEvidenceRequestAction => ({
    type: GET_MY_EVIDENCE_REQUESTED,
    payload,
});

export const GET_MY_EVIDENCE_SUCCEED = 'GET_MY_EVIDENCE_SUCCEED';
export type GetMyEvidenceSuccessAction = Action<typeof GET_MY_EVIDENCE_SUCCEED, MyEvidenceResponse>;
export const getMyEvidenceSuccess = (
    myEvidence: MyEvidenceResponse,
): GetMyEvidenceSuccessAction => ({
    type: GET_MY_EVIDENCE_SUCCEED,
    payload: myEvidence,
});

export const GET_MY_EVIDENCE_FAILED = 'GET_MY_EVIDENCE_FAILED';
export type GetMyEvidenceFailureAction = Action<typeof GET_MY_EVIDENCE_FAILED, string>;
export const getMyEvidenceFailure = (payload: string): GetMyEvidenceFailureAction => ({
    type: GET_MY_EVIDENCE_FAILED,
    payload,
});

export const GET_MY_EVIDENCE_FILES_REQUESTED = 'GET_MY_EVIDENCE_FILES_REQUESTED';
export type GetMyEvidenceFilesRequestAction = Action<
    typeof GET_MY_EVIDENCE_FILES_REQUESTED,
    Evidence
>;
export const getMyEvidenceFilesRequest = (evidence: Evidence): GetMyEvidenceFilesRequestAction => ({
    type: GET_MY_EVIDENCE_FILES_REQUESTED,
    payload: evidence,
});

export const GET_MY_EVIDENCE_FILES_SUCCEED = 'GET_MY_EVIDENCE_FILES_SUCCEED';
export type GetMyEvidenceFilesSuccessAction = Action<
    typeof GET_MY_EVIDENCE_FILES_SUCCEED,
    { myEvidenceFiles: FileData[] }
>;
export const getMyEvidenceFilesSuccess = (
    myEvidenceFiles: FileData[],
): GetMyEvidenceFilesSuccessAction => ({
    type: GET_MY_EVIDENCE_FILES_SUCCEED,
    payload: { myEvidenceFiles },
});

export const GET_MY_EVIDENCE_FILES_FAILED = 'GET_MY_EVIDENCE_FILES_FAILED';
export type GetMyEvidenceFilesFailureAction = Action<typeof GET_MY_EVIDENCE_FILES_FAILED, string>;
export const getMyEvidenceFilesFailure = (payload: string): GetMyEvidenceFilesFailureAction => ({
    type: GET_MY_EVIDENCE_FILES_FAILED,
    payload,
});

export const SUBMIT_FOR_APPROVAL_REQUESTED = 'SUBMIT_FOR_APPROVAL_REQUESTED';
export type SubmitFotApprovalRequestAction = Action<
    typeof SUBMIT_FOR_APPROVAL_REQUESTED,
    UpdateEvidenceRequest
>;
export const submitForApprovalRequest = (
    evidence: UpdateEvidenceRequest,
): SubmitFotApprovalRequestAction => ({
    type: SUBMIT_FOR_APPROVAL_REQUESTED,
    payload: evidence,
});

export const SUBMIT_FOR_APPROVAL_SUCCEED = 'SUBMIT_FOR_APPROVAL_SUCCEED';
export type SubmitFotApprovalSuccessAction = Action<
    typeof SUBMIT_FOR_APPROVAL_SUCCEED,
    null,
    string
>;
export const submitForApprovalSuccess = (): SubmitFotApprovalSuccessAction => ({
    type: SUBMIT_FOR_APPROVAL_SUCCEED,
    meta: 'Evidence submitted for approval',
});

export const SUBMIT_FOR_APPROVAL_FAILED = 'SUBMIT_FOR_APPROVAL_FAILED';
export type SubmitFotApprovalFailureAction = Action<typeof SUBMIT_FOR_APPROVAL_FAILED, string>;
export const submitForApprovalFailure = (payload: string): SubmitFotApprovalFailureAction => ({
    type: SUBMIT_FOR_APPROVAL_FAILED,
    payload,
});

export const CREATE_EVIDENCE_REQUESTED = 'CREATE_EVIDENCE_REQUESTED';
export type CreateEvidenceRequestAction = Action<
    typeof CREATE_EVIDENCE_REQUESTED,
    CreateEvidenceRequest
>;
export const createEvidenceRequest = (
    payload: CreateEvidenceRequest,
): CreateEvidenceRequestAction => ({
    type: CREATE_EVIDENCE_REQUESTED,
    payload,
});

export const CREATE_EVIDENCE_SUCCEED = 'CREATE_EVIDENCE_SUCCEED';
export type CreateEvidenceSuccessAction = Action<
    typeof CREATE_EVIDENCE_SUCCEED,
    CreateEvidenceResponse,
    string,
    posthog.CaptureResult | null
>;
export const createEvidenceSuccess = (
    evidenceData: CreateEvidenceResponse,
): CreateEvidenceSuccessAction => ({
    type: CREATE_EVIDENCE_SUCCEED,
    payload: evidenceData,
    meta: 'Evidence created',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Evidence created') : null,
});

export const CREATE_EVIDENCE_FAILED = 'CREATE_EVIDENCE_FAILED';
export type CreateEvidenceFailureAction = Action<
    typeof CREATE_EVIDENCE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const createEvidenceFailure = (payload: string): CreateEvidenceFailureAction => ({
    type: CREATE_EVIDENCE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Evidence creation failed')
            : null,
});

export const UPDATE_EVIDENCE_REQUESTED = 'UPDATE_EVIDENCE_REQUESTED';
export type UpdateEvidenceRequestAction = Action<
    typeof UPDATE_EVIDENCE_REQUESTED,
    UpdateEvidenceRequest
>;
export const updateEvidenceRequest = (
    payload: UpdateEvidenceRequest,
): UpdateEvidenceRequestAction => ({
    type: UPDATE_EVIDENCE_REQUESTED,
    payload,
});

export const UPDATE_EVIDENCE_SUCCEED = 'UPDATE_EVIDENCE_SUCCEED';
export type UpdateEvidenceSuccessAction = Action<
    typeof UPDATE_EVIDENCE_SUCCEED,
    CreateEvidenceResponse,
    string,
    posthog.CaptureResult | null
>;
export const updateEvidenceSuccess = (
    evidenceData: CreateEvidenceResponse,
): UpdateEvidenceSuccessAction => ({
    type: UPDATE_EVIDENCE_SUCCEED,
    payload: evidenceData,
    meta: 'Evidence updated',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Evidence updated') : null,
});

export const UPDATE_EVIDENCE_FAILED = 'UPDATE_EVIDENCE_FAILED';
export type UpdateEvidenceFailureAction = Action<
    typeof UPDATE_EVIDENCE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const updateEvidenceFailure = (payload: string): UpdateEvidenceFailureAction => ({
    type: UPDATE_EVIDENCE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Evidence update failed')
            : null,
});

export const UPLOAD_EVIDENCE_FILE_SUCCEED = 'UPLOAD_EVIDENCE_FILE_SUCCEED';
export type UploadEvidenceFileSuccessAction = Action<
    typeof UPLOAD_EVIDENCE_FILE_SUCCEED,
    FileData,
    string,
    posthog.CaptureResult | null
>;
export const uploadEvidenceFileSuccess = (file: FileData): UploadEvidenceFileSuccessAction => ({
    type: UPLOAD_EVIDENCE_FILE_SUCCEED,
    payload: file,
    meta: 'File uploaded',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Evidence file uploaded')
            : null,
});

export const UPLOAD_EVIDENCE_FILE_FAILED = 'UPLOAD_EVIDENCE_FILE_FAILED';
export type UploadEvidenceFileFailureAction = Action<
    typeof UPLOAD_EVIDENCE_FILE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const uploadEvidenceFileFailure = (payload: string): UploadEvidenceFileFailureAction => ({
    type: UPLOAD_EVIDENCE_FILE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Feedback file upload failed')
            : null,
});

export const DELETE_EVIDENCE_FILE_REQUESTED = 'DELETE_EVIDENCE_FILE_REQUESTED';
export type DeleteEvidenceFileRequestAction = Action<
    typeof DELETE_EVIDENCE_FILE_REQUESTED,
    DeleteEvidenceFileRequest
>;
export const deleteEvidenceFileRequest = (
    request: DeleteEvidenceFileRequest,
): DeleteEvidenceFileRequestAction => ({
    type: DELETE_EVIDENCE_FILE_REQUESTED,
    payload: request,
});

export const DELETE_EVIDENCE_FILE_SUCCEED = 'DELETE_EVIDENCE_FILE_SUCCEED';
export type DeleteEvidenceFileSuccessAction = Action<
    typeof DELETE_EVIDENCE_FILE_SUCCEED,
    number,
    string
>;
export const deleteEvidenceFileSuccess = (fileId: number): DeleteEvidenceFileSuccessAction => ({
    type: DELETE_EVIDENCE_FILE_SUCCEED,
    payload: fileId,
    meta: 'Evidence file deleted',
});

export const DELETE_EVIDENCE_FILE_FAILED = 'DELETE_EVIDENCE_FILE_FAILED';
export type DeleteEvidenceFileFailureAction = Action<typeof DELETE_EVIDENCE_FILE_FAILED, string>;
export const deleteEvidenceFileFailure = (payload: string): DeleteEvidenceFileFailureAction => ({
    type: DELETE_EVIDENCE_FILE_FAILED,
    payload,
});

export const GET_READY_FOR_EVIDENCE_BENEFITS_REQUESTED =
    'GET_READY_FOR_EVIDENCE_BENEFITS_REQUESTED';
export type GetReadyForEvidenceBenefitsRequestAction = Action<
    typeof GET_READY_FOR_EVIDENCE_BENEFITS_REQUESTED
>;
export const getReadyForEvidenceBenefitsRequest = (): GetReadyForEvidenceBenefitsRequestAction => ({
    type: GET_READY_FOR_EVIDENCE_BENEFITS_REQUESTED,
});

export const GET_READY_FOR_EVIDENCE_BENEFITS_SUCCEED = 'GET_READY_FOR_EVIDENCE_BENEFITS_SUCCEED';
export type GetReadyForEvidenceBenefitsSuccessAction = Action<
    typeof GET_READY_FOR_EVIDENCE_BENEFITS_SUCCEED,
    { readyForEvidenceBenefits: UploadBenefit[] }
>;
export const getReadyForEvidenceBenefitsSuccess = (
    readyForEvidenceBenefits: UploadBenefit[],
): GetReadyForEvidenceBenefitsSuccessAction => ({
    type: GET_READY_FOR_EVIDENCE_BENEFITS_SUCCEED,
    payload: { readyForEvidenceBenefits },
});

export const GET_READY_FOR_EVIDENCE_BENEFITS_FAILED = 'GET_READY_FOR_EVIDENCE_BENEFITS_FAILED';
export type GetReadyForEvidenceBenefitsFailureAction = Action<
    typeof GET_READY_FOR_EVIDENCE_BENEFITS_FAILED,
    string
>;
export const getReadyForEvidenceBenefitsFailure = (
    payload: string,
): GetReadyForEvidenceBenefitsFailureAction => ({
    type: GET_READY_FOR_EVIDENCE_BENEFITS_FAILED,
    payload,
});

export const GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_REQUESTED =
    'GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_REQUESTED';
export type GetReadyForPendingEvidenceBenefitsRequestAction = Action<
    typeof GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_REQUESTED
>;
export const getReadyForPendingEvidenceBenefitsRequest =
    (): GetReadyForPendingEvidenceBenefitsRequestAction => ({
        type: GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_REQUESTED,
    });

export const GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_SUCCEED =
    'GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_SUCCEED';
export type GetReadyForPendingEvidenceBenefitsSuccessAction = Action<
    typeof GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_SUCCEED,
    { readyForPendingEvidenceBenefits: UploadBenefit[] }
>;
export const getReadyForPendingEvidenceBenefitsSuccess = (
    readyForPendingEvidenceBenefits: UploadBenefit[],
): GetReadyForPendingEvidenceBenefitsSuccessAction => ({
    type: GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_SUCCEED,
    payload: { readyForPendingEvidenceBenefits },
});

export const GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_FAILED =
    'GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_FAILED';
export type GetReadyForPendingEvidenceBenefitsFailureAction = Action<
    typeof GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_FAILED,
    string
>;
export const getReadyForPendingEvidenceBenefitsFailure = (
    payload: string,
): GetReadyForPendingEvidenceBenefitsFailureAction => ({
    type: GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_FAILED,
    payload,
});

// TOOD: Move to contractActions
export const GET_CONTRACT_MANAGER_DETAILS_REQUESTED = 'GET_CONTRACT_MANAGER_DETAILS_REQUESTED';
export type GetContractManagerDetailsRequestAction = Action<
    typeof GET_CONTRACT_MANAGER_DETAILS_REQUESTED,
    number
>;
export const getContractManagerDetailsRequest = (
    contractId: number,
): GetContractManagerDetailsRequestAction => ({
    type: GET_CONTRACT_MANAGER_DETAILS_REQUESTED,
    payload: contractId,
});

export const GET_CONTRACT_MANAGER_DETAILS_SUCCEED = 'GET_CONTRACT_MANAGER_DETAILS_SUCCEED';
export type GetContractManagerDetailsSuccessAction = Action<
    typeof GET_CONTRACT_MANAGER_DETAILS_SUCCEED,
    { contractManagerDetails: ContractManagerDetail[] }
>;
export const getContractManagerDetailsSuccess = (
    contractManagerDetails: ContractManagerDetail[],
): GetContractManagerDetailsSuccessAction => ({
    type: GET_CONTRACT_MANAGER_DETAILS_SUCCEED,
    payload: { contractManagerDetails },
});

export const GET_CONTRACT_MANAGER_DETAILS_FAILED = 'GET_CONTRACT_MANAGER_DETAILS_FAILED';
export type GetContractManagerDetailsFailureAction = Action<
    typeof GET_CONTRACT_MANAGER_DETAILS_FAILED,
    null
>;
export const getContractManagerDetailsFailure = (): GetContractManagerDetailsFailureAction => ({
    type: GET_CONTRACT_MANAGER_DETAILS_FAILED,
});

export type EvidenceAction =
    | ApproveEvidenceRequestAction
    | ApproveEvidenceSuccessAction
    | ApproveEvidenceFailureAction
    | DeclineEvidenceRequestAction
    | DeclineEvidenceSuccessAction
    | DeclineEvidenceFailureAction
    | GetEvidencesListRequestAction
    | GetEvidencesListSuccessAction
    | GetEvidencesListFailureAction
    | GetMyEvidenceRequestAction
    | GetMyEvidenceSuccessAction
    | GetMyEvidenceFailureAction
    | GetMyEvidenceFilesRequestAction
    | GetMyEvidenceFilesSuccessAction
    | GetMyEvidenceFilesFailureAction
    | SubmitFotApprovalRequestAction
    | SubmitFotApprovalSuccessAction
    | SubmitFotApprovalFailureAction
    | CreateEvidenceRequestAction
    | CreateEvidenceSuccessAction
    | CreateEvidenceFailureAction
    | UpdateEvidenceRequestAction
    | UpdateEvidenceSuccessAction
    | UpdateEvidenceFailureAction
    | UploadEvidenceFileSuccessAction
    | UploadEvidenceFileFailureAction
    | DeleteEvidenceFileRequestAction
    | DeleteEvidenceFileSuccessAction
    | DeleteEvidenceFileFailureAction
    | GetReadyForEvidenceBenefitsRequestAction
    | GetReadyForEvidenceBenefitsSuccessAction
    | GetReadyForEvidenceBenefitsFailureAction
    | GetContractManagerDetailsRequestAction
    | GetContractManagerDetailsSuccessAction
    | GetContractManagerDetailsFailureAction
    | GetReadyForPendingEvidenceBenefitsRequestAction
    | GetReadyForPendingEvidenceBenefitsSuccessAction
    | GetReadyForPendingEvidenceBenefitsFailureAction;
