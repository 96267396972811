import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import {
    getBenefits,
    getFilteringBenefitsStatus,
} from 'features/benefit/selectors/benefitSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { RouteParams, getPageNumber } from 'common/selectors/routeSelectors';
import { isAdmin } from 'features/user/selectors/userSelectors';
import { Benefit, BenefitPathParams } from 'features/benefit/models/benefitModels';
import {
    deleteBenefitRequest,
    toggleMultipleEmployeesRoleRequest,
    toggleMultipleInvitePendingExecutiveUsersRoleRequest,
} from 'features/benefit/actions';

import { EditBenefit as EditBenefitComponent } from '../components/EditBenefit';

export interface EditBenefitStateProps {
    benefits: Benefit[];
    isFiltered: boolean;
    isUserAdmin: boolean;
    filter?: PaginationFilters;
    pageNumber?: number;
}

interface EditBenefitDispatchProps {
    deleteBenefitRequest: typeof deleteBenefitRequest;
    redirectToUrl: typeof redirectToUrl;
    autocompleteRequest: typeof autocompleteRequest;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
    toggleMultipleEmployeesRoleRequest: typeof toggleMultipleEmployeesRoleRequest;
    toggleMultipleInvitePendingExecutiveUsersRoleRequest: typeof toggleMultipleInvitePendingExecutiveUsersRoleRequest;
}

export type EditBenefitProps = EditBenefitDispatchProps & EditBenefitStateProps & RouteParams;

export class EditBenefit extends React.Component<EditBenefitProps> {
    public deleteBenefit = (benefitId: number, contractId: number) => {
        const deleteBenefitData: BenefitPathParams = {
            benefitId,
            contractId,
        };

        this.props.deleteBenefitRequest(deleteBenefitData);
    };
    public render(): JSX.Element {
        return <EditBenefitComponent deleteBenefit={this.deleteBenefit} {...this.props} />;
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams) => ({
    benefits: getBenefits(state),
    isFiltered: getFilteringBenefitsStatus(state),
    isUserAdmin: isAdmin(state),
    filter: getFilterParams(state, PaginationView.Benefits),
    pageNumber: getPageNumber(ownProps),
});

export const EditBenefitContainer = connect(mapStateToProps, {
    deleteBenefitRequest,
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
    toggleMultipleEmployeesRoleRequest,
    toggleMultipleInvitePendingExecutiveUsersRoleRequest,
})(EditBenefit);
