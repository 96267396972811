import { getEnvValue } from 'common/helpers/getEnvValue';

export const NODE_ENV = getEnvValue('NODE_ENV');
export const REACT_APP_ENV = getEnvValue('REACT_APP_ENV');
export const API_URL = getEnvValue('REACT_APP_API_URL');
export const ENV = getEnvValue('REACT_APP_ENV');
export const SENTRY_JS = getEnvValue('REACT_APP_SENTRY_JS');
export const SENTRY_ENVIRONMENT = getEnvValue('REACT_APP_SENTRY_ENVIRONMENT');
export const POSTHOG_API_KEY = getEnvValue('REACT_APP_POSTHOG_API_KEY');
export const POSTHOG_COHORT_KEY = 'phx_L0DjzikJOucVbG9roUaUk34UFlFWrgeFHF0ZD9i7Iaz';
export const POSTHOG_COHORT_API_URL = 'https://app.posthog.com/api/projects/2667/cohorts';

export const isDebug = () => NODE_ENV === 'development' || REACT_APP_ENV === 'development';
