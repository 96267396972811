import styled from 'styled-components';

import { breakpoints, colors, fontSizes } from 'theme/variables';

export const Header = styled.h2`
    margin-bottom: 3rem;
    padding-bottom: 0.5rem;
    font-size: ${fontSizes.extraLarge};
    text-align: center;
    color: ${colors.primary};
    font-size: ${fontSizes.medium};

    ${breakpoints.small} {
        font-size: ${fontSizes.extraLarge};
    }
`;
