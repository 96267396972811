import { reduce } from 'lodash';
import createHistory from 'history/createBrowserHistory';

import {
    PaginationView,
    RequestParams,
    AutocompleteField,
} from 'features/pagination/models/paginationModel';
import { API_URL } from './env';

export interface Config {
    [key: string]: string;
}

export const history = createHistory();

export const organisationUrl = `${API_URL}/organisations/`;

export const route = {
    auth: 'auth',
    users: 'users',
    contracts: 'contracts',
    benefits: 'benefits',
    benefitTemplates: 'shared-benefits',
    withRoles: 'users/with-roles',
    organisations: 'organisations',
    changeEmail: 'change-email',
    changePassword: 'change-password',
    portfolioFilters: 'portfolio-filters',
    subPrograms: 'sub-programs',
    mainProjects: 'main-projects',
    subProjects: 'sub-projects',
    subscriptions: 'subscriptions',
};

const routeConfig = {
    confirmPassword: `${route.auth}/confirm-password`,
    logInRoute: `${route.auth}/obtain-token`,
    refreshToken: `${route.auth}/refresh`,

    allContracts: route.contracts,
    allBenefits: route.benefits,
    myBenefits: `${route.benefits}/my`,
    myContracts: `${route.contracts}/my`,

    subscriptionTiers: `${route.subscriptions}/subscription-items`,

    newExecutiveInvitations: 'new-executive-invitations',
    executiveInvitations: 'executive-invitations',
    invitations: 'invitations',
    organisations: route.organisations,

    users: route.users,
    me: `${route.users}/me`,

    requestPasswordReset: `${route.users}/request-password-reset`,
    verifyResetPasswordToken: `${route.users}/validate-password-token`,
    resetPasswordChange: `${route.users}/reset-password`,
    availableProfiles: `${route.users}/available-profiles`,
    benefitRoleExecutives: `${route.users}/executives`,
    benefitRoleInvitedExecutives: `${route.users}/invited-executives`,

    exportContracts: `${route.contracts}/export-data`,
    exportBenefits: `${route.benefits}/export-data`,

    importContracts: `${route.contracts}/import-data`,
    importBenefits: `${route.benefits}/import-data`,
    importBenefitTemplates: `${route.benefits}/import-shared-data`,

    benefitsCsv: `${route.benefits}/csv`,
    contractsCsv: `${route.contracts}/csv`,

    checkEvidences: `${route.benefits}/check-evidences`,
    checkScores: `${route.benefits}/check-feedback`,

    approvedCheckEvidences: `${route.benefits}/check-approved-evidences`,
    approvedCheckScores: `${route.benefits}/check-approved-feedback`,

    scorableBenefits: `${route.benefits}/scorable`,
    readyForEvidenceBenefits: `${route.benefits}/ready-for-evidences`,

    readyForPendingEvidenceBenefits: `${route.benefits}/ready-for-pending-evidences`,
    checkPendingEvidences: `${route.benefits}/check-pending-evidences`,

    assessors: `${route.withRoles}/assessors`,
    contractManagers: `${route.withRoles}/managers`,

    suppliers: `${route.organisations}/suppliers`,
    contractSuppliers: `${route.organisations}/contract-suppliers`,
    deliveryPartners: `${route.organisations}/delivery-partners`,

    executiveSupplierUsers: `${route.withRoles}/suppliers`,
    executiveContractSupplierUsers: `${route.withRoles}/contract-suppliers`,
    executiveDeliveryPartnerUsers: `${route.withRoles}/delivery-partners`,

    approvedUploadEvidences: `${route.benefits}/my-approved-evidences`,
    approvedUploadScores: `${route.benefits}/my-approved-scores`,

    tenants: `${route.organisations}/tenants`,

    assignEmployees: `${route.benefits}/assign-employees`,
    revokeEmployees: `${route.benefits}/revoke-employees`,

    assignEmployeesToContracts: `${route.contracts}/assign-employees`,
    revokeEmployeesFromContracts: `${route.contracts}/revoke-employees`,

    assignInvitePendingExecutiveUsers: `${route.benefits}/assign-pending-executive-users`,
    revokeInvitePendingExecutiveUsers: `${route.benefits}/revoke-pending-executive-users`,
};

const organisationRouteConfig = {
    myOrganisation: '', // this will be transformed to /api/organisation/id/ by generateRoutes

    contract: route.contracts,
    benefits: route.benefits,
    benefitTemplates: route.benefitTemplates,

    contractCategories: 'contract-categories',
    benefitCategories: 'benefit-categories',
    departments: 'departments',
    locations: 'locations',
    portfolioFilters: 'portfolio-filters',
    contractPriorities: 'contract-priorities',
    benefitPriorities: 'benefit-priorities',
    priorities: 'priorities',
    dashboard: 'dashboard',

    invite: 'invite',
    employees: 'profiles',

    executives: 'executives',
    potentialExecutives: 'potential-executives',
    employeesPendingInvitations: 'employee-invitations',
    employeesExpiredInvitations: 'expired-employee-invitations',
    existingOrganisationPendingInvitations: 'executive-invitations',
    pendingRoleInvitations: 'role-invitations',
    newOrganisationPendingInvitations: 'new-executive-invitations',

    xeroCustomer: 'xero-contact',
    organisationSubscriptions: 'subscriptions',
};

const generateRoutes = (routes: Config, urlPrefix: string | undefined): Config =>
    reduce(routes, (result, value, key) => ({ ...result, [key]: `${urlPrefix}/${value}/` }), {});

export const config = {
    routeConfig: generateRoutes(routeConfig, API_URL),
};

export function setAfterLoginRoutes(organisationId: number): void {
    Object.assign(
        config.routeConfig,
        generateRoutes(organisationRouteConfig, `${organisationUrl}${organisationId}`),
    );
}

export const dateFormat = 'YYYY-MM-DD';

export const XLSMimeType = 'application/vnd.ms-excel';
export const XLSXMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const generatePaginationEndpoint = (view: PaginationView, requestParams?: RequestParams) => {
    const { routeConfig: routes } = config;

    switch (view) {
        case PaginationView.UploadEvidence:
            return routes.readyForEvidenceBenefits;

        case PaginationView.UploadPendingEvidence:
            return routes.readyForPendingEvidenceBenefits;

        case PaginationView.ApprovedUploadEvidence:
            return routes.approvedUploadEvidences;

        case PaginationView.UploadScore:
            return routes.scorableBenefits;

        case PaginationView.ApprovedUploadScores:
            return routes.approvedUploadScores;

        case PaginationView.CheckEvidence:
            return routes.checkEvidences;

        case PaginationView.CheckPendingEvidence:
            return routes.checkPendingEvidences;

        case PaginationView.CheckScores:
            return routes.checkScores;

        case PaginationView.ApprovedCheckEvidence:
            return routes.approvedCheckEvidences;

        case PaginationView.ApprovedCheckScores:
            return routes.approvedCheckScores;

        case PaginationView.DownloadContracts:
            return routes.allContracts;

        case PaginationView.DownloadBenefits:
        case PaginationView.SupplierEditContract:
            return routes.allBenefits;

        case PaginationView.BenefitTemplates:
            return routes.benefitTemplates;

        case PaginationView.Contracts:
            return routes.contract;

        case PaginationView.MyContracts:
            return routes.myContracts;

        case PaginationView.Benefits:
            return routes.benefits;

        case PaginationView.MyBenefits:
            return routes.myBenefits;

        case PaginationView.ContractBenefits:
            return `${routes.contract}${requestParams && requestParams.contractId}/benefits/`;

        case PaginationView.AddOrganisation:
            return routes.potentialExecutives;

        case PaginationView.EditOrganisations:
            return routes.executives;

        case PaginationView.EditEmployees:
        case PaginationView.AssignEmployees:
            return routes.employees;

        case PaginationView.Departments:
            return routes.departments;

        case PaginationView.Locations:
            return routes.locations;

        case PaginationView.ContractCategories:
            return routes.contractCategories;

        case PaginationView.ContractPriorities:
            return routes.contractPriorities;

        case PaginationView.BenefitCategories:
            return routes.benefitCategories;

        case PaginationView.BenefitPriorities:
            return routes.benefitPriorities;

        case PaginationView.OrganisationEmployees:
            return `${routes.organisations}${
                requestParams && requestParams.organisationId
            }/profiles/`;

        case PaginationView.EmployeePendingInvitations:
            return routes.employeesPendingInvitations;

        case PaginationView.EmployeeExpiredInvitations:
            return routes.employeesExpiredInvitations;

        case PaginationView.ExistingOrganisationPendingInvitations:
            return routes.existingOrganisationPendingInvitations;

        case PaginationView.NewOrganisationPendingInvitations:
            return routes.newOrganisationPendingInvitations;

        case PaginationView.BenefitRolePendingInvitations:
            return `${routes.contract}${
                requestParams && requestParams.contractId
            }/executive-invitations/`;

        case PaginationView.BenefitRoleExpiredInvitations:
            return `${routes.contract}${
                requestParams && requestParams.contractId
            }/expired-executive-invitations/`;

        case PaginationView.ContractRAGDashboard:
            return `${routes.dashboard}contract-rag/`;

        case PaginationView.BenefitTemplateRAGDashboard:
            return `${routes.dashboard}shared-outcomes/`;

        case PaginationView.ContractPrioritiesDashboard:
            return `${routes.dashboard}contract-priorities/`;

        case PaginationView.BenefitPrioritiesDashboard:
            return `${routes.dashboard}benefit-priorities/`;

        default:
            return false;
    }
};

export const generateAutocompleteEndpoint = (
    autocompleteField: AutocompleteField,
    requestParams?: RequestParams,
) => {
    const { routeConfig: routes } = config;

    switch (autocompleteField) {
        case AutocompleteField.Department:
            return routes.departments;

        case AutocompleteField.Location: {
            if (requestParams) {
                return `${routes.organisations}${
                    requestParams && requestParams.organisationId
                }/locations`;
            } else {
                return routes.locations;
            }
        }

        case AutocompleteField.ContractCategory:
            return routes.contractCategories;

        case AutocompleteField.ContractPriorities:
            return routes.contractPriorities;

        case AutocompleteField.ContractPortfolioFilter:
            return routes.portfolioFilters;

        case AutocompleteField.BenefitCategory:
            return routes.benefitCategories;

        case AutocompleteField.BenefitPriorities:
            return routes.benefitPriorities;

        case AutocompleteField.ContractManager:
            return routes.contractManagers;

        case AutocompleteField.Assessor:
            return routes.assessors;

        case AutocompleteField.SupplierOrganisation:
            return routes.suppliers;

        case AutocompleteField.ContractSupplierOrganisation:
            return routes.contractSuppliers;

        case AutocompleteField.DeliveryPartnerOrganisation:
            return routes.deliveryPartners;

        case AutocompleteField.TenantOrganisation:
            return routes.tenants;

        case AutocompleteField.ExecutiveOrganisation:
            return routes.executives;

        case AutocompleteField.Employee:
            return routes.employees;

        case AutocompleteField.ExecutiveSupplierUser:
            return routes.executiveSupplierUsers;

        case AutocompleteField.ExecutiveContractSupplierUser:
            return routes.executiveContractSupplierUsers;

        case AutocompleteField.ExecutiveDeliveryPartnerUser:
            return routes.executiveDeliveryPartnerUsers;

        case AutocompleteField.BenefitTemplate:
            return routes.benefitTemplates;

        case AutocompleteField.Contract:
            return routes.contract;

        case AutocompleteField.ContractExecutive:
            return routes.allContracts;

        case AutocompleteField.MyContract:
            return routes.myContracts;

        case AutocompleteField.Benefit:
            return routes.benefits;

        case AutocompleteField.MyBenefit:
            return routes.myBenefits;

        case AutocompleteField.DownloadBenefits:
            return routes.allBenefits;

        case AutocompleteField.BenefitRoleExecutives:
        case AutocompleteField.MassAssignBenefitRoleExecutives:
            return routes.benefitRoleExecutives;

        case AutocompleteField.BenefitRoleInvitedExecutives:
        case AutocompleteField.MassAssignBenefitRoleInvitedExecutives:
            return routes.benefitRoleInvitedExecutives;

        case AutocompleteField.ReadyForEvidenceBenefits:
            return routes.readyForEvidenceBenefits;

        case AutocompleteField.ReadyForPendingEvidenceBenefits:
            return routes.readyForPendingEvidenceBenefits;

        case AutocompleteField.ApprovedUploadEvidences:
            return routes.approvedUploadEvidences;

        case AutocompleteField.ScorableBenefits:
            return routes.scorableBenefits;

        case AutocompleteField.ApprovedUploadScores:
            return routes.approvedUploadScores;

        case AutocompleteField.CheckEvidence:
            return routes.checkEvidences;

        case AutocompleteField.CheckPendingEvidence:
            return routes.checkPendingEvidences;

        case AutocompleteField.CheckScores:
            return routes.checkScores;

        case AutocompleteField.ApprovedCheckEvidence:
            return routes.approvedCheckEvidences;

        case AutocompleteField.ApprovedCheckScores:
            return routes.approvedCheckScores;

        case AutocompleteField.DownloadContracts:
        case AutocompleteField.ExecutiveAdminSearchContractsAssignBenefit:
            return routes.allContracts;

        case AutocompleteField.ContractBenefits:
            return `${routes.contract}${requestParams && requestParams.contractId}/benefits/`;

        default:
            return false;
    }
};

export const notArchivedFilterParam = { archived: false }; // 3 mean not archived user
export const notRootNodeFilterParam = { rootNodes: 3 };
export const rootNodeFilterParam = '?root_nodes=2';
