import * as React from 'react';

import { PaddedSection, UploadAndCheckTableFiltersBar } from 'common/components';
import { generateColumns, addReferenceSuffixToBenfitOutcome } from 'common/helpers/utils';
import { FilterData } from 'common/helpers/url';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { ApprovedUploadProps as ApprovedUploadContainerProps } from '../containers/ApprovedUploadContainer';
import { uploadScoreTableColumns } from './UploadScore';
import { getScorableBenefitsSuccess } from '../actions/scoreActions';

type ApprovedUploadScoresProps = Antd3FormProps & ApprovedUploadContainerProps;

class ApprovedUploadScoresForm extends React.Component<ApprovedUploadScoresProps> {
    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.ApprovedUploadScores,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getScorableBenefitsSuccess,
        });

    private saveBenefitFilters = (benefitReferenceNumber: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.ApprovedUploadScores,
            values: {
                referenceNumber: benefitReferenceNumber,
            },
        });

    private saveContractFilters = (contractId: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.ApprovedUploadScores,
            values: {
                contract: contractId,
            },
        });

    private saveBuyerOrganisationFilter = (tenant: string | undefined) => {
        this.props.saveFilters({
            paginatedView: PaginationView.ApprovedUploadScores,
            values: { tenant },
        });
    };

    private searchByBenefits = (benefitReferenceNumber: string) =>
        this.saveBenefitFilters(benefitReferenceNumber) && this.refreshTable();

    private searchByContracts = (contractId: number) =>
        this.saveContractFilters(contractId) && this.refreshTable();

    private searchByBuyerOrganisation = (buyer: string) => {
        this.saveBuyerOrganisationFilter(buyer);
        this.refreshTable();
    };

    private onRemoveBenefitAutocomplete = () =>
        this.saveBenefitFilters(undefined) && this.refreshTable();

    private onRemoveContractAutocomplete = () =>
        this.saveContractFilters(undefined) && this.refreshTable();

    private onRemoveBuyerOrganisationAutocomplete = () => {
        this.saveBuyerOrganisationFilter(undefined);
        this.refreshTable();
    };

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.ApprovedUploadScores) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            quickFilterBenefitReferenceNumber: '',
            quickFilterContractReferenceNumber: '',
        });

    public render(): JSX.Element {
        const { form, filter, approvedScores, isTenant } = this.props;

        return (
            <PaddedSection header="Submit Approved Feedback">
                <UploadAndCheckTableFiltersBar
                    form={form}
                    searchByBenefits={this.searchByBenefits}
                    onRemoveBenefitAutocomplete={this.onRemoveBenefitAutocomplete}
                    searchByContracts={this.searchByContracts}
                    onRemoveContractAutocomplete={this.onRemoveContractAutocomplete}
                    searchByBuyerOrganisation={this.searchByBuyerOrganisation}
                    onRemoveBuyerOrganisationAutocomplete={
                        this.onRemoveBuyerOrganisationAutocomplete
                    }
                    filter={filter}
                    clearFilters={this.clearFilters}
                    benefitFilterAutocompleteField={AutocompleteField.ApprovedUploadScores}
                    isTenant={isTenant}
                />
                <PaginatedTable
                    view={PaginationView.ApprovedUploadScores}
                    data={addReferenceSuffixToBenfitOutcome(approvedScores)}
                    columns={generateColumns(uploadScoreTableColumns)}
                    emptyText="No benefits have been assigned to you"
                    paginationSuccessCallback={getScorableBenefitsSuccess}
                />
            </PaddedSection>
        );
    }
}

export const ApprovedUploadScores = Antd3Form.create({})(ApprovedUploadScoresForm);
