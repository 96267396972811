import styled from 'styled-components';
import { Button } from 'antd';

export const ResetButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    > button {
        height: 40px;
    }
`;

interface Props {
    onReset: () => void;
}

export const ResetButton = ({ onReset }: Props) => {
    const resetForm = () => onReset();

    return (
        <ResetButtonWrapper>
            <Button type="default" onClick={resetForm}>
                Reset form
            </Button>
        </ResetButtonWrapper>
    );
};
