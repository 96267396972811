import { BenefitRole } from 'features/benefit/models/benefitModels';

export enum InvitePaths {
    Employee = '/invite/employee',
    Organisation = '/invite/organisation',
    ExpiredInvitations = '/invite/expired-invitations',
}

export enum SupplierInvitePaths {
    Invite = '/invite/users',
    ExpiredInvitations = '/invite/expired-user-invitations',
}

export interface InviteByEmail {
    firstName?: string;
    lastName?: string;
    email: string;
    phoneNumber?: string;
    department?: number;
}

export interface InviteByEmailWithRole extends InviteByEmail {
    role: BenefitRole;
    benefit?: number;
    organisationId: number;
    id?: number;
}

export interface EditBenefitInviteByEmailWithRole extends InviteByEmailWithRole {
    contract: number;
}

export interface ChangeTileProp {
    changeTile(form: string): void;
}

export interface EmployeeInvitation {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    telephone: string;
    isAdministrator: boolean;
    active: boolean;
}

export interface OrganisationInvitation {
    id: number;
    executiveName: string;
    executiveId?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
}

export interface ResendRoleInvitation {
    organisationId: number;
    invitationId: number;
    contractId: number;
    benefitId: number;
}

export interface DeleteRoleInvitation {
    invitationId: string;
    contractId: number;
    benefitId: number;
}

export interface InvitedExecutive {
    id: number;
    email: string;
    organisation: number;
    organisationName: string;
}

export interface InvitedExecutives {
    results: InvitedExecutive[];
}
