import { updateOrAddToList } from 'common/helpers/updateOrAddListHelper';
import { orderBy } from 'lodash';

import * as actions from '../actions/contractActions';
import {
    Filters,
    Contract,
    ContractRoleData,
    ContractPriorities,
    ContractWithRAG,
    ContractPriorityDashboardItem,
    ContractNoteData,
} from '../models/contractModels';
import { FileData } from 'features/upload/models/uploadModels';

export interface ContractState {
    categories: Filters[];
    portfolioFilters: Filters[];
    contracts: Contract[];
    contractsWithRag: ContractWithRAG[];
    priorityDashboard: ContractPriorityDashboardItem[];
    contractRoles: ContractRoleData[];
    departments: Filters[];
    locations: Filters[];
    contractPrioritiesFilters: Filters[];
    benefitPrioritiesFilters: Filters[];
    myContracts: Contract[];
    isFetching: boolean;
    isFiltered: boolean;
    isFetchingContracts: boolean;
    isFetchingMyContracts: boolean;
    selectedOrganisationId: number;
    priorities: ContractPriorities[];
    isFetchingPriorities: boolean;
    contractFilterDateRange: string[];
    contractPrioritiesFilterDateRange: string[];
    contractNotes: ContractNoteData[];
    formVisible: boolean;
    contractFiles: FileData[];
}

const defaultContractState = {
    categories: [],
    portfolioFilters: [],
    contracts: [],
    contractsWithRag: [],
    priorityDashboard: [],
    contractRoles: [],
    departments: [],
    locations: [],
    contractPrioritiesFilters: [],
    benefitPrioritiesFilters: [],
    myContracts: [],
    isFetching: false,
    isFiltered: false,
    isFetchingContracts: false,
    isFetchingMyContracts: false,
    selectedOrganisationId: 0,
    priorities: [],
    isFetchingPriorities: false,
    contractFilterDateRange: [],
    contractPrioritiesFilterDateRange: [],
    contractNotes: [],
    formVisible: false,
    contractFiles: [],
};

export const contractReducer = (
    state: ContractState = defaultContractState,
    action: actions.ContractActions,
) => {
    switch (action.type) {
        case actions.GET_CATEGORIES_SUCCEED:
        case actions.GET_PORTFOLIO_FILTERS_SUCCEED:
        case actions.GET_CONTRACT_DETAILS_SUCCEED:
        case actions.GET_DEPARTMENTS_SUCCEED:
        case actions.GET_LOCATIONS_SUCCEED:
        case actions.GET_CONTRACT_ROLES_SUCCEED:
        case actions.GET_CONTRACT_PRIORITIES_FILTERS_SUCCEED:
        case actions.GET_BENEFIT_PRIORITIES_FILTERS_SUCCEED:
        case actions.GET_CONTRACT_PRIORITY_DASHBOARD_SUCCEED:
        case actions.GET_CONTRACT_NOTES_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
                isFiltered: false,
            };
        case actions.CREATE_CONTRACT_REQUESTED:
        case actions.FILTER_CONTRACTS_REQUESTED:
        case actions.GET_CATEGORIES_REQUESTED:
        case actions.GET_PORTFOLIO_FILTERS_REQUESTED:
        case actions.GET_CONTRACT_DETAILS_REQUESTED:
        case actions.GET_DEPARTMENTS_REQUESTED:
        case actions.GET_LOCATIONS_REQUESTED:
        case actions.GET_CONTRACT_ROLES_REQUESTED:
        case actions.GET_CONTRACT_PRIORITIES_FILTERS_REQUESTED:
        case actions.GET_BENEFIT_PRIORITIES_FILTERS_REQUESTED:
        case actions.GET_CONTRACT_NOTES_REQUESTED:
            return {
                ...state,
                isFetching: true,
            };
        case actions.GET_ALL_CONTRACTS_REQUESTED: {
            return {
                ...state,
                isFetchingContracts: true,
            };
        }
        case actions.GET_CONTRACTS_SUCCEED:
        case actions.GET_RAG_CONTRACTS_SUCCEED:
        case actions.GET_ALL_CONTRACTS_SUCCEED: {
            return {
                ...state,
                ...action.payload,
                isFetchingContracts: false,
            };
        }
        case actions.GET_ALL_CONTRACTS_FAILED: {
            return {
                ...state,
                isFetchingContracts: false,
            };
        }
        case actions.GET_MY_CONTRACTS_REQUESTED: {
            return {
                ...state,
                isFetchingMyContracts: true,
            };
        }
        case actions.GET_MY_CONTRACTS_SUCCEED: {
            return {
                ...state,
                ...action.payload,
                isFetchingMyContracts: false,
            };
        }
        case actions.GET_MY_CONTRACTS_FAILED: {
            return {
                ...state,
                isFetchingMyContracts: false,
            };
        }
        case actions.CREATE_CONTRACT_SUCCEED:
        case actions.CREATE_CONTRACT_FAILED:
        case actions.CREATE_FILTER_SUCCEED:
        case actions.CREATE_FILTER_FAILED:
        case actions.FILTER_CONTRACTS_FAILED:
        case actions.GET_CONTRACT_DETAILS_FAILED:
        case actions.GET_CONTRACT_ROLES_FAILED:
        case actions.GET_CONTRACT_NOTES_FAILED:
            return {
                ...state,
                isFetching: false,
            };

        case actions.FILTER_CONTRACTS_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
                isFiltered: true,
            };
        case actions.ADD_CONTRACT_ROLE_SUCCEED:
            return {
                ...state,
                contractRoles: [...state.contractRoles, action.payload],
            };
        case actions.DELETE_CONTRACT_ROLE_SUCCEED:
            return {
                ...state,
                contractRoles: state.contractRoles.filter(({ id }) => id !== action.payload),
            };
        case actions.ARCHIVE_CONTRACT_CATEGORY_SUCCEED:
            return {
                ...state,
                categories: state.categories.filter(({ id }) => id !== action.payload),
            };
        case actions.ARCHIVE_LOCATION_SUCCEED:
            return {
                ...state,
                locations: state.locations.filter(({ id }) => id !== action.payload),
            };
        case actions.ARCHIVE_CONTRACT_PRIORITY_SUCCEED:
            return {
                ...state,
                contractPriorities: state.contractPrioritiesFilters.filter(
                    ({ id }) => id !== action.payload,
                ),
            };
        case actions.ARCHIVE_BENEFIT_PRIORITY_SUCCEED:
            return {
                ...state,
                benefitPriorities: state.benefitPrioritiesFilters.filter(
                    ({ id }) => id !== action.payload,
                ),
            };
        case actions.ARCHIVE_DEPARTMENT_SUCCEED:
            return {
                ...state,
                departments: state.departments.filter(({ id }) => id !== action.payload),
            };
        case actions.GET_CONTRACT_REQUESTED:
            return {
                ...state,
                isFetchingContracts: true,
            };
        case actions.GET_CONTRACT_SUCCEED:
            return {
                ...state,
                contracts: updateOrAddToList(state.contracts, action.payload),
                isFetchingContracts: false,
            };
        case actions.GET_CONTRACT_FAILED:
            return {
                ...state,
                isFetchingContracts: false,
            };

        case actions.GET_CONTRACT_FILES_SUCCEED:
            return {
                ...state,
                ...action.payload,
                fetchingStatus: 'success',
                isUploading: false,
            };

        case actions.DELETE_CONTRACT_FILE_SUCCEED:
            return {
                ...state,
                contractFiles: state.contractFiles.filter((file) => file.id !== action.payload),
            };

        case actions.GET_CONTRACT_PRIORITIES_REQUESTED:
            return {
                ...state,
                isFetchingPriorities: true,
            };
        case actions.GET_CONTRACT_PRIORITIES_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetchingPriorities: false,
            };

        case actions.EDIT_CONTRACT_PRIORITY_SUCCEED:
            const filterPriorities = state.priorities.filter(({ id }) => id !== action.payload!.id);
            const properties = orderBy([...filterPriorities, action.payload], ['id'], ['asc']);

            return {
                ...state,
                priorities: properties,
            };
        case actions.SELECT_CONTRACT_ORGANISATION:
            return {
                ...state,
                selectedOrganisationId: action.payload,
            };
        case actions.DELETE_CONTRACT_PRIORITY_SUCCEED:
            return {
                ...state,
                priorities: state.priorities.filter(({ id }) => id !== action.payload),
            };
        case actions.ADD_CONTRACT_PRIORITY_SUCCEED:
            const newPriority = action.payload && action.payload.priorities;

            return {
                ...state,
                priorities: [...state.priorities, newPriority],
            };
        case actions.SET_DATE_RANGE_CONTRACT_DASHBOARD:
            return {
                ...state,
                contractFilterDateRange: action.payload,
            };
        case actions.SET_DATE_RANGE_CONTRACT_PRIORITIES_DASHBOARD:
            return {
                ...state,
                contractPrioritiesFilterDateRange: action.payload,
            };
        case actions.OPEN_FORM:
            return {
                ...state,
                formVisible: true,
            };
        case actions.CLOSE_FORM:
            return {
                ...state,
                formVisible: false,
            };
        default:
            return state;
    }
};
