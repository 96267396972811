import { switchMap, pluck, map } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { AppEpic } from 'common/epics/appEpic';

import * as actions from '../actions/inviteActions';

import { InviteService } from '../services/inviteService';
import { ResendRoleInvitation, DeleteRoleInvitation } from '../models/inviteModel';

export const pendingInvitationEpicFactory = (inviteService: InviteService): AppEpic => {
    const getEmployeesPendingInvitationsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_EMPLOYEES_PENDING_INVITATIONS_REQUESTED),
            pluck('payload'),
            switchMap(() =>
                inviteService
                    .getEmployeesPendingInvitations()
                    .then(actions.getEmployeesPendingInvitationsSuccess)
                    .catch(actions.getEmployeesPendingInvitationsFailure),
            ),
        );

    const getEmployeesExpiredInvitationsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_EMPLOYEES_EXPIRED_INVITATIONS_REQUESTED),
            pluck('payload'),
            switchMap(() =>
                inviteService
                    .getEmployeesExpiredInvitations()
                    .then(actions.getEmployeesExpiredInvitationsSuccess)
                    .catch(actions.getEmployeesExpiredInvitationsFailure),
            ),
        );

    const getRolesPendingInvitationsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_ROLES_PENDING_INVITATIONS_REQUESTED),
            pluck('payload'),
            switchMap((contractId) =>
                inviteService
                    .getRolesPendingInvitations(contractId)
                    .then(actions.getRolesPendingInvitationsSuccess)
                    .catch(actions.getRolesPendingInvitationsFailure),
            ),
        );

    const deletePendingRoleInviteEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_PENDING_ROLE_INVITE_REQUESTED),
            pluck('payload'),
            switchMap((payload: DeleteRoleInvitation) =>
                inviteService
                    .deletePendingRoleInvitation(payload)
                    .then(() => actions.getRolesPendingInvitationsRequest(payload.contractId))
                    .catch(actions.deletePendingRoleInviteFailure),
            ),
        );

    const deleteEmployeesPendingInvitationsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_EMPLOYEE_PENDING_INVITATION_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                inviteService
                    .deleteEmployeesPendingInvitations(id)
                    .then(actions.deleteEmployeesPendingInvitationsSuccees)
                    .catch(actions.deleteEmployeesPendingInvitationsFailure),
            ),
        );

    const refreshEmployeeInvitationsList: AppEpic = (action$) =>
        action$.pipe(
            ofType(
                actions.INVITE_EMPLOYEE_SUCCEED,
                actions.DELETE_EMPLOYEE_PENDING_INVITATION_SUCCEED,
                actions.RESEND_EMPLOYEE_EMAIL_INVITATION_SUCCEED,
            ),
            map(actions.getEmployeesPendingInvitationsRequest),
        );

    const getExistinOrgPendingInvitationsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_EXISTING_ORG_PENDING_INVITATIONS_REQUESTED),
            pluck('payload'),
            switchMap(() =>
                inviteService
                    .getExistingOrgPendingInvitations()
                    .then(actions.getExistingOrgPendingInvitationsSuccess)
                    .catch(actions.getExistingOrgPendingInvitationsFailure),
            ),
        );

    const deleteExistingOrgPendingInvitationsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_EXISTING_ORG_PENDING_INVITATION_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                inviteService
                    .deleteExistingOrgPendingInvitations(id)
                    .then(actions.deleteExistingOrgPendingInvitationsSuccees)
                    .catch(actions.deleteExistingOrgPendingInvitationsFailure),
            ),
        );

    const refreshExistingOrgInvitationsList: AppEpic = (action$) =>
        action$.pipe(
            ofType(
                actions.INVITE_POTENTIAL_EXECUTIVES_SUCCEED,
                actions.DELETE_EXISTING_ORG_PENDING_INVITATION_SUCCEED,
            ),
            map(actions.getExistingOrgPendingInvitationsRequest),
        );

    const resendRolesEmailInvitation: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.RESEND_ROLES_EMAIL_INVITATION_REQUESTED),
            pluck('payload'),
            switchMap((payload: ResendRoleInvitation) =>
                inviteService
                    .resendRolesEmailInvitation(payload)
                    .then(() => actions.resendRolesEmailInvitationSuccess(payload.contractId))
                    .catch(actions.resendRolesEmailInvitationFailure),
            ),
        );

    const resendRolesEmailInvitationSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.RESEND_ROLES_EMAIL_INVITATION_SUCCEED),
            pluck('payload'),
            map((payload) => actions.getRolesPendingInvitationsRequest(payload)),
        );

    const resendExpiredRolesEmailInvitationSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.RESEND_ROLES_EMAIL_INVITATION_SUCCEED),
            pluck('payload'),
            map((payload) => actions.getRolesExpiredInvitationsRequest(payload)),
        );

    const resendEmployeeEmailInvitation: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.RESEND_EMPLOYEE_EMAIL_INVITATION_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                inviteService
                    .resendEmployeeEmailInvitation(id)
                    .then(actions.resendEmployeeEmailInvitationSuccess)
                    .catch(actions.resendEmployeeEmailInvitationFailure),
            ),
        );

    const resendExpiredEmployeeInvitationEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.RESEND_EMPLOYEE_EXPIRED_INVITATION_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                inviteService
                    .resendExpiredEmployeeInvitation(id)
                    .then(actions.resendEmployeeExpiredInvitationSuccess)
                    .catch(actions.resendExpiredEmailInvitationFailure),
            ),
        );

    const deleteExpiredEmployeeInvitation: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_EMPLOYEE_EXPIRED_INVITATION_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                inviteService
                    .deleteExpiredEmployeeInvitation(id)
                    .then(actions.deleteEmployeesExpiredInvitationSuccees)
                    .catch(actions.deleteEmployeesExpiredInvitationFailure),
            ),
        );

    const refreshExpiredEmployeeInvitationsList: AppEpic = (action$) =>
        action$.pipe(
            ofType(
                actions.RESEND_EMPLOYEE_EXPIRED_INVITATION_SUCCEED,
                actions.DELETE_EMPLOYEE_EXPIRED_INVITATION_SUCCEED,
            ),
            map(actions.getEmployeesExpiredInvitationsRequest),
        );

    const getRolesExpiredInvitationsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_ROLES_EXPIRED_INVITATIONS_REQUESTED),
            pluck('payload'),
            switchMap((contractId) =>
                inviteService
                    .getRolesExpiredInvitations(contractId)
                    .then(actions.getRolesExpiredInvitationsSuccess)
                    .catch(actions.getRolesExpiredInvitationsFailure),
            ),
        );

    return combineEpics(
        getEmployeesPendingInvitationsEpic,
        deleteEmployeesPendingInvitationsEpic,
        refreshEmployeeInvitationsList,
        getExistinOrgPendingInvitationsEpic,
        deleteExistingOrgPendingInvitationsEpic,
        refreshExistingOrgInvitationsList,
        getRolesPendingInvitationsEpic,
        deletePendingRoleInviteEpic,
        resendRolesEmailInvitation,
        resendEmployeeEmailInvitation,
        resendRolesEmailInvitationSuccessEpic,
        getEmployeesExpiredInvitationsEpic,
        resendExpiredEmployeeInvitationEpic,
        refreshExpiredEmployeeInvitationsList,
        deleteExpiredEmployeeInvitation,
        getRolesExpiredInvitationsEpic,
        resendExpiredRolesEmailInvitationSuccessEpic,
    );
};
