import * as React from 'react';
import { Radio, Button } from 'antd';
import { Link } from 'react-router-dom';
import { RadioChangeEvent } from 'antd/lib/radio';
import { isUndefined } from 'lodash';

import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { generateColumns } from 'common/helpers/utils';
import { SubmitButton } from 'common/components';
import { Benefit, AssignActions, EditBenefitPaths } from 'features/benefit/models/benefitModels';
import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { getMyBenefitsSuccess } from 'features/benefit/actions/benefitActions';
import { FilterData } from 'common/helpers/url';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { showNotification, notificationText } from 'common/helpers/notifications';
import { notArchivedFilterParam } from 'config/config';
import { Organisation } from 'features/organisation/models/organisationModels';
import { openModal } from 'common/helpers/modal';
import {
    Antd3Form,
    Antd3Icon,
    Antd3FormProps,
    StyledAntd3Form,
} from 'common/components/deprecated/antd3';

import { EditPaths } from '../models/editModel';
import { EditMyBenefitsProps as EditMyBenefitsContainerProps } from '../containers/EditMyBenefitsContainer';
import { EditBenefitsTableFiltersBar } from './EditBenefitsTableFiltersBar';

interface ContainerProps {
    deleteBenefit(benefitId: number, contractId: number): void;
}

interface AdminEditMyBenefitsState {
    assign: AssignActions;
    selectedBenefitIds: string[];
    selectedOrganisation?: Organisation;
}

const openDeleteBenefitModal = (
    e: React.FormEvent,
    id: number,
    contract: number,
    onRemove: (id: number, contract: number) => void,
) => {
    e.preventDefault();

    return openModal({
        callback: () => onRemove(id, contract),
        title: 'If you delete this benefit you will also delete any evidence associated with it. Are you sure you want to continue?',
    });
};

type EditMyBenefitProps = EditMyBenefitsContainerProps & ContainerProps & Antd3FormProps;

class EditMyBenefitForm extends React.Component<EditMyBenefitProps, AdminEditMyBenefitsState> {
    public state: AdminEditMyBenefitsState = {
        assign: AssignActions.Assign,
        selectedBenefitIds: [],
        selectedOrganisation: undefined,
    };

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields(
            (
                error,
                { employeeIds, existingExecutiveIds, pendingExecutiveIds, selectedOrganisation },
            ) => {
                const employeeFieldSelected = !isUndefined(employeeIds) && employeeIds.length > 0;
                const existingExecutiveFieldSelected =
                    !isUndefined(existingExecutiveIds) && existingExecutiveIds.length > 0;
                const pendingExecutiveFieldSelected =
                    !isUndefined(pendingExecutiveIds) && pendingExecutiveIds.length > 0;

                const moreThanAFieldSelected = employeeFieldSelected
                    ? existingExecutiveFieldSelected || pendingExecutiveFieldSelected
                    : existingExecutiveFieldSelected && pendingExecutiveFieldSelected;

                if (error) {
                    return showNotification({ text: notificationText.Error });
                }

                if (moreThanAFieldSelected) {
                    return showNotification({
                        text: 'Please separate assigning contributor, existing executive user and invite pending executive user actions',
                    });
                }

                let userIds;
                if (employeeFieldSelected) {
                    userIds = employeeIds;
                } else if (existingExecutiveFieldSelected) {
                    userIds = existingExecutiveIds;
                } else if (pendingExecutiveFieldSelected) {
                    userIds = pendingExecutiveIds;
                }

                if (pendingExecutiveFieldSelected) {
                    return this.props.toggleMultipleInvitePendingExecutiveUsersRoleRequest({
                        benefitIds: this.state.selectedBenefitIds.map((id) => parseInt(id, 10)),
                        userInvitationIds: userIds.map((id: string) => parseInt(id, 10)),
                        action: this.state.assign,
                        organisationId: selectedOrganisation,
                    });
                } else {
                    return this.props.toggleMultipleEmployeesRoleRequest({
                        benefitIds: this.state.selectedBenefitIds.map((id) => parseInt(id, 10)),
                        userIds: userIds.map((id: string) => parseInt(id, 10)),
                        action: this.state.assign,
                        organisationId: existingExecutiveFieldSelected
                            ? selectedOrganisation
                            : undefined,
                    });
                }
            },
        );
    };

    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.MyBenefits,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getMyBenefitsSuccess,
        });

    private selectOrganisation = (selectedOrganisation: Organisation) => {
        this.setState({ selectedOrganisation });
    };

    private saveBenefitFilters = (benefitReferenceNumber: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.MyBenefits,
            values: {
                referenceNumber: benefitReferenceNumber,
            },
        });

    private saveContractFilters = (contractId: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.MyBenefits,
            values: {
                contract: contractId,
            },
        });

    private searchByBenefits = (benefitReferenceNumber: string) =>
        this.saveBenefitFilters(benefitReferenceNumber) && this.refreshTable();

    private searchByContracts = (contractId: number) =>
        this.saveContractFilters(contractId) && this.refreshTable();

    private onRemoveBenefitAutocomplete = () =>
        this.saveBenefitFilters(undefined) && this.refreshTable();

    private onRemoveContractAutocomplete = () =>
        this.saveContractFilters(undefined) && this.refreshTable();

    private onSelectChange = (selectedBenefitIds: string[]) =>
        this.setState({ selectedBenefitIds });

    private handleAssignChange = (event: RadioChangeEvent) =>
        this.setState({ assign: event.target.value });

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.MyBenefits) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            quickFilterBenefitReferenceNumber: '',
            quickFilterContractReferenceNumber: '',
        });

    public render(): JSX.Element {
        const { myBenefits, isUserAdmin, deleteBenefit, filter, form } = this.props;
        const isBenefitUnselected = !this.state.selectedBenefitIds.length;
        const employeeIds = form.getFieldValue('employeeIds');
        const isEmployeeUnselected = !(employeeIds && employeeIds.length);
        const individualExistingExecutiveIds = form.getFieldValue('existingExecutiveIds');
        const individualPendingExecutiveIds = form.getFieldValue('pendingExecutiveIds');
        const isExistingExecutiveUnselected = !(
            individualExistingExecutiveIds && individualExistingExecutiveIds.length
        );
        const isPendingExecutiveUnselected = !(
            individualPendingExecutiveIds && individualPendingExecutiveIds.length
        );

        const editBenefitColumns = [
            {
                title: 'Benefit',
                key: 'outcome',
                render: (outcome: string, { contract, referenceNumber, id }: Benefit) => (
                    <Link
                        to={`${EditPaths.Contract}/${contract}/benefits/${id}${EditBenefitPaths.Details}`}
                    >{`${outcome} | ${referenceNumber}`}</Link>
                ),
            },
            {
                title: 'Benefit Label',
                key: 'label',
                render: (label: string, { organisation, contract, id }: Benefit) => (
                    <Link
                        to={`${EditPaths.Contract}/${contract}/benefits/${id}${EditBenefitPaths.Details}`}
                    >
                        {label}
                    </Link>
                ),
            },
            {
                title: 'Project',
                key: 'contractTitle',
            },
            {
                title: 'Ref. No.',
                key: 'contractReferenceNumber',
            },
            {
                title: '',
                key: 'id',
                render: (_: string, { contract, id }: Benefit) => (
                    <>
                        <Link
                            to={`${EditPaths.Contract}/${contract}/benefits/${id}${EditBenefitPaths.BenefitForm}`}
                        >
                            <Button icon={<Antd3Icon type="edit" />} size="small">
                                Edit
                            </Button>
                        </Link>
                        <Button
                            onClick={(e: React.FormEvent) =>
                                openDeleteBenefitModal(e, id, contract, deleteBenefit)
                            }
                            type="danger"
                            icon={<Antd3Icon type="delete" />}
                            size="small"
                        >
                            Remove
                        </Button>
                    </>
                ),
            },
        ];

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Edit My Benefits" bigHeader>
                <EditBenefitsTableFiltersBar
                    searchByBenefits={this.searchByBenefits}
                    searchByContracts={this.searchByContracts}
                    onRemoveBenefitAutocomplete={this.onRemoveBenefitAutocomplete}
                    onRemoveContractAutocomplete={this.onRemoveContractAutocomplete}
                    clearFilters={this.clearFilters}
                    form={this.props.form}
                    filter={filter}
                />
                <Antd3Form.Item>
                    <PaginatedTable
                        view={PaginationView.MyBenefits}
                        paginationSuccessCallback={getMyBenefitsSuccess}
                        columns={generateColumns(editBenefitColumns)}
                        data={myBenefits}
                        emptyText="No benefits found"
                        pageNumber={this.props.pageNumber}
                        pathUrl={EditPaths.MyBenefits}
                        rowSelection={
                            isUserAdmin
                                ? {
                                      selectedRowKeys: this.state.selectedBenefitIds,
                                      onChange: this.onSelectChange,
                                  }
                                : undefined
                        }
                        withPaginationHistory
                    />
                </Antd3Form.Item>
                {isUserAdmin && (
                    <>
                        <AutocompleteSelect
                            label="Select employees to assign as Contributors"
                            id="employeeIds"
                            form={form}
                            autocompleteField={AutocompleteField.Employee}
                            notFoundContent="No employees found"
                            multiple
                            requiredMessage="Please choose at least one employee"
                            searchParams={notArchivedFilterParam}
                        />
                        <AutocompleteSelect
                            form={form}
                            autocompleteField={AutocompleteField.ExecutiveOrganisation}
                            id="selectedOrganisation"
                            placeholder="Choose Organisation"
                            notFoundContent="No organisations found"
                            getAutocompleteData={this.selectOrganisation}
                        />
                        {this.state.selectedOrganisation && (
                            <AutocompleteSelect
                                form={form}
                                autocompleteField={
                                    AutocompleteField.MassAssignBenefitRoleExecutives
                                }
                                id="existingExecutiveIds"
                                placeholder="Select existing users"
                                notFoundContent="No users found"
                                searchParams={{
                                    organisation: this.state.selectedOrganisation.id,
                                }}
                                multiple
                            />
                        )}
                        {this.state.selectedOrganisation && (
                            <AutocompleteSelect
                                form={form}
                                autocompleteField={
                                    AutocompleteField.MassAssignBenefitRoleInvitedExecutives
                                }
                                id="pendingExecutiveIds"
                                placeholder="Select invite pending users"
                                notFoundContent="No users found"
                                searchParams={{
                                    organisation: this.state.selectedOrganisation.id,
                                }}
                                multiple
                            />
                        )}
                        <Antd3Form.Item>
                            <Radio.Group
                                name="assign"
                                defaultValue={AssignActions.Assign}
                                onChange={this.handleAssignChange}
                            >
                                <Radio value={AssignActions.Assign}>Assign</Radio>
                                <Radio value={AssignActions.Revoke}>Unassign</Radio>
                            </Radio.Group>
                        </Antd3Form.Item>
                        <SubmitButton
                            value={
                                this.state.assign === AssignActions.Assign ? 'Assign' : 'Unassign'
                            }
                            disabled={
                                isBenefitUnselected ||
                                (isExistingExecutiveUnselected &&
                                    isEmployeeUnselected &&
                                    isPendingExecutiveUnselected)
                            }
                        />
                    </>
                )}
            </StyledAntd3Form>
        );
    }
}

export const EditMyBenefits = Antd3Form.create({})(EditMyBenefitForm);
