import * as React from 'react';
import styled from 'styled-components';
import { Checkbox, List as AntdList, Modal, Radio, Button } from 'antd';
import { Link } from 'react-router-dom';
import { RadioChangeEvent } from 'antd/lib/radio';

import { Filters } from 'features/contract/models/contractModels';
import { colors, fontWeights } from 'theme/variables';

import { Antd3Icon } from './deprecated/antd3';

interface DataSource {
    id: number;
    name: string;
}

interface ListState {
    radioValue: string;
}

enum ListAction {
    Archive = 'archive',
    Checkbox = 'checkbox',
    Edit = 'edit',
    Open = 'open',
    Radio = 'radio',
    Remove = 'remove',
}

interface ListProps {
    dataSource: DataSource[] | Filters[] | JSX.Element[];
    emptyText?: string;
    header?: string | JSX.Element;
    link?: string;
    linkSuffix?: string;
    listAction?: ListAction[];
    loading?: boolean;
    onArchiveMessage?: string;
    onRemoveMessage?: string;
    onChange?(item: string): void;
    onEdit?(id: number): void;
    onCheck?(item: number): void;
    onArchive?(id: number): void;
    onRemove?(data: number): void;
}

const ListWrapper = styled.div`
    width: 100%;
    background-color: ${colors.primaryWhite};

    .ant-list-header {
        background-color: ${colors.primary};
        color: ${colors.primaryWhite};
        font-weight: ${fontWeights.bold};
    }
`;
export class List extends React.Component<ListProps> {
    public state: ListState = {
        radioValue: '',
    };

    private onRadioChange = (e: RadioChangeEvent): void => {
        const radioValue = e.target.value;
        const { onChange } = this.props;

        this.setState({ radioValue });
        return onChange && onChange(radioValue);
    };

    private openArchiveOrderModal(id: number): void {
        const { onArchive, onArchiveMessage } = this.props;

        const modal = Modal.confirm({
            title: onArchiveMessage,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                onArchive ? onArchive(id) : modal.destroy();
            },
        });
    }

    private openRemoveExecutiveModal(id: number): void {
        const { onRemove, onRemoveMessage } = this.props;

        const modal = Modal.confirm({
            title: onRemoveMessage,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                onRemove ? onRemove(id) : modal.destroy();
            },
        });
    }

    public render(): JSX.Element {
        const { emptyText, linkSuffix, onCheck, loading } = this.props;
        const suffix = linkSuffix ? `/${linkSuffix}` : '';

        return (
            <ListWrapper>
                <AntdList
                    locale={{ emptyText: emptyText || 'No data' }}
                    header={this.props.header}
                    bordered
                    dataSource={this.props.dataSource}
                    loading={loading}
                    renderItem={({ id, name }: Filters) => (
                        <AntdList.Item
                            actions={[
                                this.props.listAction &&
                                    this.props.listAction.map((listAction) => {
                                        switch (listAction) {
                                            case ListAction.Archive:
                                                return (
                                                    <Button
                                                        key={listAction}
                                                        icon={<Antd3Icon type="folder" />}
                                                        onClick={() =>
                                                            id && this.openArchiveOrderModal(id)
                                                        }
                                                        size="small"
                                                        type="danger"
                                                    >
                                                        Archive
                                                    </Button>
                                                );
                                            case ListAction.Checkbox:
                                                return (
                                                    <Checkbox
                                                        key={listAction}
                                                        onChange={() => onCheck && onCheck(id)}
                                                    />
                                                );
                                            case ListAction.Edit:
                                                return (
                                                    <Button
                                                        key={listAction}
                                                        icon={<Antd3Icon type="edit" />}
                                                        onClick={() =>
                                                            this.props.onEdit &&
                                                            this.props.onEdit(id)
                                                        }
                                                        size="small"
                                                    >
                                                        Edit
                                                    </Button>
                                                );
                                            case ListAction.Open:
                                                return (
                                                    <Link
                                                        key={listAction}
                                                        to={`${location.pathname}/${
                                                            id ? id.toString() : name
                                                        }`}
                                                    >
                                                        <Button
                                                            size="small"
                                                            icon={<Antd3Icon type="form" />}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </Link>
                                                );
                                            case ListAction.Radio:
                                                return (
                                                    <Radio.Group
                                                        key={listAction}
                                                        onChange={this.onRadioChange}
                                                        value={this.state.radioValue}
                                                    >
                                                        <Radio value={id} />
                                                    </Radio.Group>
                                                );
                                            case ListAction.Remove:
                                                return (
                                                    <Button
                                                        key={listAction}
                                                        icon={<Antd3Icon type="delete" />}
                                                        onClick={() =>
                                                            id && this.openRemoveExecutiveModal(id)
                                                        }
                                                        size="small"
                                                        type="danger"
                                                    >
                                                        Remove
                                                    </Button>
                                                );
                                            default:
                                                return null;
                                        }
                                    }),
                            ]}
                        >
                            {this.props.link ? (
                                <Link to={`${this.props.link}/${id}${suffix}`}>{name}</Link>
                            ) : (
                                name
                            )}
                        </AntdList.Item>
                    )}
                />
            </ListWrapper>
        );
    }
}
