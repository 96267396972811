import { notification } from 'antd';
import { capitalize } from 'lodash';

export enum Notification {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
}

export enum NotificationPlacement {
    TopRight = 'topRight',
    TopLeft = 'topLeft',
    BottomRight = 'bottomRight',
    BottomLeft = 'bottomLeft',
}

export interface NotificationOptions {
    type?: Notification;
    message?: string;
    text: string;
    duration?: number;
    placement?: NotificationPlacement;
    button?: JSX.Element;
}

export const notificationText = {
    Success: 'Success',
    Info: 'Please ensure the data you have entered is correct',
    Warning: 'Something went wrong',
    Error: 'Please update the data you have entered',
};

export const showNotification = ({
    message,
    text,
    duration,
    placement,
    button,
    type = Notification.Error,
}: NotificationOptions) => {
    notification[type]({
        message: message || capitalize(type),
        description: text,
        duration: duration || 0,
        placement: placement || NotificationPlacement.TopRight,
        btn: button,
    });
};
