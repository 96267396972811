import { inject, injectable } from 'inversify';

import { defaultAutocompleteSize } from 'config/variables';
import { HttpService } from 'common/services/HttpService';
import { SERVICE } from 'config/identifiers';
import { config } from 'config/config';
import { FilterData } from 'common/helpers/url';
import { RoleInvitation } from 'features/contract/models/contractModels';
import { InvitePendingIndividualExecutivesRequest } from 'features/benefit/models/benefitModels';

import {
    InviteByEmail,
    EmployeeInvitation,
    OrganisationInvitation,
    InviteByEmailWithRole,
    ResendRoleInvitation,
    DeleteRoleInvitation,
    InvitedExecutive,
} from '../models/inviteModel';

@injectable()
export class InviteService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    public inviteEmployee(employeeData: InviteByEmail): Promise<void> {
        return this.http.POST(config.routeConfig.invite, employeeData);
    }

    public inviteEmployeeToExecutive(employeeData: InviteByEmailWithRole): Promise<void> {
        const { organisationId, ...employee } = employeeData;
        return this.http.POST(
            `${config.routeConfig.organisations}${organisationId}/invite/`,
            employee,
        );
    }

    public inviteNewPotentialExecutive(executive: InviteByEmail): Promise<void> {
        return this.http.POST(`${config.routeConfig.potentialExecutives}invite-new/`, executive);
    }

    public invitePotentialExecutives(ids: number[]): Promise<void> {
        return this.http.POST(`${config.routeConfig.potentialExecutives}invite/`, { ids });
    }

    public getEmployeesPendingInvitations(): Promise<EmployeeInvitation[]> {
        return this.http.GET(config.routeConfig.employeesPendingInvitations);
    }

    public deleteEmployeesPendingInvitations(id: number): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.employeesPendingInvitations}${id}/`);
    }

    public getEmployeesExpiredInvitations(): Promise<EmployeeInvitation[]> {
        return this.http.GET(config.routeConfig.employeesExpiredInvitations);
    }

    public deleteEmployeesExpiredInvitations(id: number): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.employeesExpiredInvitations}${id}/`);
    }

    public deletePendingRoleInvitation({
        invitationId,
        contractId,
        benefitId,
    }: DeleteRoleInvitation): Promise<void> {
        return this.http.DELETE(
            `${config.routeConfig.contract}${contractId}/benefits/${benefitId}/role-invitations/${invitationId}/`,
        );
    }

    public getExistingOrgPendingInvitations(): Promise<OrganisationInvitation[]> {
        return this.http.GET(`${config.routeConfig.existingOrganisationPendingInvitations}`);
    }

    public deleteExistingOrgPendingInvitations(id: number): Promise<void> {
        return this.http.DELETE(
            `${config.routeConfig.existingOrganisationPendingInvitations}${id}/`,
        );
    }

    public getNewOrgPendingInvitations(): Promise<OrganisationInvitation[]> {
        // unused
        return this.http.GET(config.routeConfig.newOrganisationPendingInvitations);
    }

    public deleteNewOrgPendingInvitations(id: number): Promise<void> {
        // unused
        return this.http.DELETE(`${config.routeConfig.newOrganisationPendingInvitations}${id}/`);
    }

    public getRolesPendingInvitations(contractId: number): Promise<RoleInvitation[]> {
        return this.http.GET(`${config.routeConfig.contract}${contractId}/executive-invitations/`);
    }

    public getRolesExpiredInvitations(contractId: number): Promise<RoleInvitation[]> {
        return this.http.GET(
            `${config.routeConfig.contract}${contractId}/expired-executive-invitations/`,
        );
    }

    public resendRolesEmailInvitation({
        organisationId,
        invitationId,
        contractId,
        benefitId,
    }: ResendRoleInvitation): Promise<void> {
        return this.http.POST(
            `${config.routeConfig.organisations}${organisationId}/contracts/${contractId}/benefits/${benefitId}/role-invitations/${invitationId}/resend/`,
        );
    }

    public resendEmployeeEmailInvitation(invitationId: number): Promise<void> {
        return this.http.POST(
            `${config.routeConfig.employeesPendingInvitations}${invitationId}/resend/`,
        );
    }

    public assignInvitePendingEmployee({
        userInvitation,
        role,
        benefit,
        contract,
    }: InvitePendingIndividualExecutivesRequest): Promise<void> {
        return this.http.POST(
            `${config.routeConfig.contract}${contract}/benefits/${benefit}/roles/assign-invited/`,
            {
                userInvitation,
                benefit,
                role,
            },
        );
    }

    public getInvitedExecutives(organisationId: number): Promise<InvitedExecutive[]> {
        const filters = { organisation: organisationId };
        const pagination = { limit: defaultAutocompleteSize, offset: 0 };
        return this.http.GET(
            `${config.routeConfig.benefitRoleInvitedExecutives}`,
            pagination,
            filters as FilterData,
        );
    }

    public resendExpiredEmployeeInvitation(invitationId: number): Promise<void> {
        return this.http.POST(
            `${config.routeConfig.employeesExpiredInvitations}${invitationId}/resend/`,
        );
    }

    public deleteExpiredEmployeeInvitation(id: number): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.employeesExpiredInvitations}${id}/`);
    }
}
