import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/lib/form/style/index.less';
import { FormComponentProps, FormProps } from '@ant-design/compatible/lib/form';
import {
    GetFieldDecoratorOptions,
    WrappedFormUtils as AntdWrappedFormUtils,
} from '@ant-design/compatible/lib/form/Form';

export const Antd3Form = Form;

export type Antd3FormProps = FormComponentProps & FormProps;

export type WrappedFormUtils = AntdWrappedFormUtils;

export interface Antd3FormModel {
    form: WrappedFormUtils;
}

export type Antd3FormElementProps = {
    form?: WrappedFormUtils;
    id: string;
    getFieldDecoratorOptions?: GetFieldDecoratorOptions;
    required?: boolean;
};
