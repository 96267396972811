import * as React from 'react';
import { Button } from 'antd';

import { showNotification, notificationText } from 'common/helpers/notifications';
import { FormFieldIds } from 'common/helpers/utils';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { RangePicker, ResetButton } from 'common/components/deprecated/form';

import { DashboardFilterContainerProps } from '../containers/DashboardFilterContainer';
import { DashboardPaths } from '../models/dashboardModel';

type DashboardFilterProps = DashboardFilterContainerProps & Antd3FormProps;

class DashboardFilterComponent extends React.Component<DashboardFilterProps> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }

            this.props.saveFilters({
                paginatedView: this.props.filterId,
                values,
                path: this.props.backUrl,
            });

            if (this.props.backUrl == DashboardPaths.Contracts) {
                this.props.setDateRangeContractDashboard([
                    this.props.form.getFieldValue(FormFieldIds.ContractDateRangeAfter),
                    this.props.form.getFieldValue(FormFieldIds.ContractDateRangeBefore),
                ]);
            }

            if (this.props.backUrl == DashboardPaths.ContractPriorities) {
                this.props.setDateRangeContractPrioritiesDashboard([
                    this.props.form.getFieldValue(FormFieldIds.ContractDateRangeAfter),
                    this.props.form.getFieldValue(FormFieldIds.ContractDateRangeBefore),
                ]);
            }

            if (this.props.backUrl == DashboardPaths.BenefitTemplates) {
                this.props.setDateRangeBenefitTemplateDashboard([
                    this.props.form.getFieldValue(FormFieldIds.ContractDateRangeAfter),
                    this.props.form.getFieldValue(FormFieldIds.ContractDateRangeBefore),
                ]);
            }

            if (this.props.backUrl == DashboardPaths.BenefitPriorities) {
                this.props.setDateRangeBenefitPriorityDashboard([
                    this.props.form.getFieldValue(FormFieldIds.ContractDateRangeAfter),
                    this.props.form.getFieldValue(FormFieldIds.ContractDateRangeBefore),
                ]);
            }
        });
    };

    public render(): JSX.Element {
        const { form, formInitial, clearFilters } = this.props;
        const { values } = formInitial || { values: {} };

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Edit Filter">
                <ResetButton
                    form={form}
                    clearFilters={clearFilters}
                    paginationView={this.props.filterId}
                />
                <RangePicker
                    form={form}
                    label="Project Date Range"
                    inputs={[
                        {
                            id: FormFieldIds.ContractDateRangeAfter,
                            placeholder: 'From',
                            initialValue: values.contractDateRangeAfter,
                        },
                        {
                            id: FormFieldIds.ContractDateRangeBefore,
                            placeholder: 'To',
                            initialValue: values.contractDateRangeBefore,
                        },
                    ]}
                />
                <Button type="primary" htmlType="submit">
                    Filter
                </Button>
            </StyledAntd3Form>
        );
    }
}

export const DashboardFilter = Antd3Form.create({})(DashboardFilterComponent);
