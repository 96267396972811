import { switchMap, pluck, map, debounceTime, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

import { AppEpic } from 'common/epics/appEpic';
import { redirectToUrl } from 'common/actions/navigationAction';
import { AssignActions } from 'features/benefit/models/benefitModels';

import * as actions from '../actions/contractActions';
import {
    ArchiveFilterRequest,
    Contract,
    CreateFilterRequest,
    EditFilterRequest,
    FilterContract,
    GetContractRequest,
    AddContractPriorityRequest,
    EditContractPriorityRequest,
    ToggleMultipleEmployeesContractRoleRequest,
    CreateContractNoteRequest,
    DeleteContractNoteRequest,
    EditContractNoteRequest,
    DeleteContractNoteFileRequest,
    AddContractNoteFileRequest,
    DeleteContractFileRequest,
} from '../models/contractModels';
import { ContractService } from '../services/contractService';
import { EditPaths } from 'features/edit/models/editModel';

export const contractEpicFactory = (contractService: ContractService): AppEpic => {
    const archiveContractCategoryEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_CONTRACT_CATEGORY_REQUESTED),
            pluck('payload'),
            switchMap((filterData: ArchiveFilterRequest) =>
                contractService
                    .archiveFilter(filterData)
                    .then(actions.archiveContractCategorySuccess)
                    .catch(actions.archiveContractCategoryFailure),
            ),
        );

    const archiveContractCategorySuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_CONTRACT_CATEGORY_SUCCEED),
            map(actions.getCategoriesRequest),
        );

    const archiveLocationEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_LOCATION_REQUESTED),
            pluck('payload'),
            switchMap((filterData: ArchiveFilterRequest) =>
                contractService
                    .archiveFilter(filterData)
                    .then(actions.archiveLocationSuccess)
                    .catch(actions.archiveLocationFailure),
            ),
        );

    const archiveLocationSuccessEpic: AppEpic = (action$) =>
        action$.pipe(ofType(actions.ARCHIVE_LOCATION_SUCCEED), map(actions.getLocationsRequest));

    const archiveContractPriorityEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_CONTRACT_PRIORITY_REQUESTED),
            pluck('payload'),
            switchMap((filterData: ArchiveFilterRequest) =>
                contractService
                    .archiveFilter(filterData)
                    .then(actions.archiveContractPrioritySuccess)
                    .catch(actions.archiveContractPriorityFailure),
            ),
        );

    /* const archiveContractPrioritySuccessEpic: AppEpic = action$ =>
        action$.pipe(
            ofType(actions.ARCHIVE_CONTRACT_PRIORITY_SUCCEED),
            pluck('payload'),
            map((id: number) => actions.getContractPrioritiesRequest(id)),
        );*/

    const archiveBenefitPriorityEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_BENEFIT_PRIORITY_REQUESTED),
            pluck('payload'),
            switchMap((filterData: ArchiveFilterRequest) =>
                contractService
                    .archiveFilter(filterData)
                    .then(actions.archiveBenefitPrioritySuccess)
                    .catch(actions.archiveBenefitPriorityFailure),
            ),
        );

    /* const archiveBenefitPrioritySuccessEpic: AppEpic = action$ =>
        action$.pipe(
            ofType(actions.ARCHIVE_BENEFIT_PRIORITY_SUCCEED),
            map(actions.getBenefitPrioritiesFiltersRequest),
        );*/

    const archiveDepartmentEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_DEPARTMENT_REQUESTED),
            pluck('payload'),
            switchMap((filterData: ArchiveFilterRequest) =>
                contractService
                    .archiveFilter(filterData)
                    .then(actions.archiveDepartmentSuccess)
                    .catch(actions.archiveDepartmentFailure),
            ),
        );

    const archiveDepartmentSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_DEPARTMENT_SUCCEED),
            map(actions.getDepartmentsRequest),
        );

    const createContractEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_CONTRACT_REQUESTED),
            pluck('payload'),
            mergeMap((contract: Contract) =>
                contractService
                    .createContract(contract)
                    .then((contract) =>
                        redirectToUrl(`${EditPaths.Contract}/${contract.id}/project-form`),
                    )
                    .catch(actions.createContractFailure),
            ),
        );

    const createFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((data: CreateFilterRequest) =>
                contractService
                    .createFilter(data)
                    .then(actions.createFilterSuccess)
                    .catch(actions.createFilterFailure),
            ),
        );

    const deleteContractEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_REQUESTED),
            pluck('payload'),
            switchMap((contractId: number) =>
                contractService
                    .deleteContract(contractId)
                    .then(actions.deleteContractSuccess)
                    .catch(actions.deleteContractFailure),
            ),
        );

    const editContractEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_CONTRACT_REQUESTED),
            pluck('payload'),
            switchMap((contract: Contract) =>
                contractService
                    .editContract(contract)
                    .then(actions.editContractSuccess)
                    .catch(actions.editContractFailure),
            ),
        );

    const editFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((filterData: EditFilterRequest) =>
                contractService
                    .editFilter(filterData)
                    .then(actions.editFilterSuccess)
                    .catch(actions.editFilterFailure),
            ),
        );

    const filterContractsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.FILTER_CONTRACTS_REQUESTED),
            pluck('payload'),
            switchMap((contract: FilterContract) =>
                contractService
                    .filterContracts(contract)
                    .then(actions.filterContractsSuccess)
                    .catch(actions.filterContractsFailure),
            ),
        );

    const filterContractsSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.FILTER_CONTRACTS_SUCCEED),
            pluck('payload'),
            map(({ path }) => redirectToUrl(path)),
        );

    const getCategoriesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_CATEGORIES_REQUESTED),
            switchMap(() =>
                contractService
                    .getCategories()
                    .then(actions.getCategoriesSuccess)
                    .catch(actions.getCategoriesFailure),
            ),
        );

    const getPortfolioFiltersEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_PORTFOLIO_FILTERS_REQUESTED),
            switchMap(() =>
                contractService
                    .getPortfolioFilters()
                    .then(actions.getPortfolioFiltersSuccess)
                    .catch(actions.getPortfolioFiltersFailure),
            ),
        );

    const getAllContractsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_ALL_CONTRACTS_REQUESTED),
            switchMap(() =>
                contractService
                    .getAllContracts()
                    .then(actions.getAllContractsSuccess)
                    .catch(actions.getAllContractsFailure),
            ),
        );

    const getMyContractsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_MY_CONTRACTS_REQUESTED),
            switchMap(() =>
                contractService
                    .getMyContracts()
                    .then(actions.getMyContractsSuccess)
                    .catch(actions.getMyContractsFailure),
            ),
        );

    const getContractDetailsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_CONTRACT_DETAILS_REQUESTED),
            switchMap(() =>
                contractService
                    .getContractDetails()
                    .then(actions.getContractDetailsSuccess)
                    .catch(actions.getContractDetailsFailure),
            ),
        );

    const getContractPrioritiesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_CONTRACT_PRIORITIES_REQUESTED),
            pluck('payload'),
            switchMap((contractId: number) =>
                contractService
                    .getContractPriorities(contractId)
                    .then(actions.getContractPrioritiesSuccess)
                    .catch(actions.getContractPrioritiesFailure),
            ),
        );

    const addContractPriorityEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ADD_CONTRACT_PRIORITY_REQUESTED),
            pluck('payload'),
            switchMap((data: AddContractPriorityRequest) =>
                contractService
                    .addContractPriority(data)
                    .then(actions.addContractPrioritySuccess)
                    .catch(actions.addContractPriorityFailure),
            ),
        );

    const editContractPriorityEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_CONTRACT_PRIORITY_REQUESTED),
            debounceTime(1000),
            pluck('payload'),
            switchMap((data: EditContractPriorityRequest) =>
                contractService
                    .editContractPriority(data)
                    .then(actions.editContractPrioritySuccess)
                    .catch(actions.editContractPriorityFail),
            ),
        );

    const deleteContractPriorityEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_PRIORITY_REQUESTED),
            pluck('payload'),
            switchMap((data: EditContractPriorityRequest) =>
                contractService
                    .deleteContractPriority(data)
                    .then(actions.deleteContractPrioritySuccess)
                    .catch(actions.deleteContractPriorityFailure),
            ),
        );

    const getContractEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_CONTRACT_REQUESTED),
            pluck('payload'),
            switchMap((request: GetContractRequest) =>
                contractService
                    .getContract(request)
                    .then(actions.getContractSuccess)
                    .catch(actions.getContractFailure),
            ),
        );

    const getContractRolesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_CONTRACT_ROLES_REQUESTED),
            pluck('payload'),
            switchMap((contractId: number) =>
                contractService
                    .getContractRoles(contractId)
                    .then(actions.getContractRolesSuccess)
                    .catch(actions.getContractRolesFailure),
            ),
        );

    const getDepartmentsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_DEPARTMENTS_REQUESTED),
            switchMap(() =>
                contractService
                    .getDepartments()
                    .then(actions.getDepartmentsSuccess)
                    .catch(actions.getDepartmentsFailure),
            ),
        );

    const getLocationsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_LOCATIONS_REQUESTED),
            switchMap(() =>
                contractService
                    .getLocations()
                    .then(actions.getLocationsSuccess)
                    .catch(actions.getLocationsFailure),
            ),
        );

    const addContractRoleEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ADD_CONTRACT_ROLE_REQUESTED),
            pluck('payload'),
            switchMap(({ role, contractId, roleId }) =>
                contractService
                    .addContractRole({ role, contractId, roleId })
                    .then(actions.addContractRoleSuccess)
                    .catch(actions.addContractRoleFail),
            ),
        );

    const deleteContractRoleEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_ROLE_REQUESTED),
            pluck('payload'),
            switchMap(({ contractId, roleId }) =>
                contractService
                    .deleteContractRole({ contractId, roleId })
                    .then(actions.deleteContractRoleSuccess)
                    .catch(actions.deleteContractRoleFail),
            ),
        );

    const deleteContractCategoryFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_CATEGORY_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((filterData: EditFilterRequest) =>
                contractService
                    .deleteFilter(filterData)
                    .then(actions.deleteContractCategoryFilterSuccess)
                    .catch(actions.deleteContractCategoryFilterFailure),
            ),
        );

    const deleteContractCategoryFilterSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_CATEGORY_FILTER_SUCCEED),
            map(actions.getCategoriesRequest),
        );

    const deleteContractPriorityFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_PRIORITY_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((filterData: EditFilterRequest) =>
                contractService
                    .deleteFilter(filterData)
                    .then(actions.deleteContractPriorityFilterSuccess)
                    .catch(actions.deleteContractPriorityFilterFailure),
            ),
        );

    /* const deleteContractPriorityFilterSuccessEpic: AppEpic = action$ =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_PRIORITY_FILTER_SUCCEED),
            map(actions.getContractPrioritiesFiltersRequest),
        ); */

    const deleteBenefitPriorityFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_PRIORITY_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((filterData: EditFilterRequest) =>
                contractService
                    .deleteFilter(filterData)
                    .then(actions.deleteBenefitPriorityFilterSuccess)
                    .catch(actions.deleteBenefitPriorityFilterFailure),
            ),
        );

    /* const deleteBenefitPriorityFilterSuccessEpic: AppEpic = action$ =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_PRIORITY_FILTER_SUCCEED),
            map(actions.getBenefitPrioritiesFiltersRequest),
        ); */

    const deleteDepartmentFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_DEPARTMENT_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((filterData: EditFilterRequest) =>
                contractService
                    .deleteFilter(filterData)
                    .then(actions.deleteDepartmentFilterSuccess)
                    .catch(actions.deleteDepartmentFilterFailure),
            ),
        );

    const deleteDepartmentFilterSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_DEPARTMENT_FILTER_SUCCEED),
            map(actions.getDepartmentsRequest),
        );

    const deleteLocationFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_LOCATION_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((filterData: EditFilterRequest) =>
                contractService
                    .deleteFilter(filterData)
                    .then(actions.deleteLocationFilterSuccess)
                    .catch(actions.deleteLocationFilterFailure),
            ),
        );

    const deleteLocationFilterSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_LOCATION_FILTER_SUCCEED),
            map(actions.getLocationsRequest),
        );

    const toggleMultipleEmployeesContractRoleEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.TOGGLE_MULTIPLE_EMPLOYEES_CONTRACT_ROLE_REQUESTED),
            pluck('payload'),
            switchMap((request: ToggleMultipleEmployeesContractRoleRequest) =>
                contractService
                    .toggleMultipleEmployeesContractRole(request)
                    .then(() => {
                        const actionMessage =
                            request.action === AssignActions.Assign
                                ? `Contract roles have been assigned`
                                : `Contract roles have been revoked`;

                        return actions.toggleMultipleEmployeesContractRoleSuccess(actionMessage);
                    })
                    .catch(actions.toggleMultipleEmployeesContractRoleFailure),
            ),
        );

    const getContractNotesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_CONTRACT_NOTES_REQUESTED),
            pluck('payload'),
            switchMap((contractId: number) =>
                contractService
                    .getContractNotes(contractId)
                    .then(actions.getContractNotesSuccess)
                    .catch(actions.getContractNotesFailure),
            ),
        );

    const createContractNoteEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_CONTRACT_NOTE_REQUESTED),
            pluck('payload'),
            switchMap((contractNote: CreateContractNoteRequest) =>
                contractService
                    .createContractNote(contractNote)
                    .then(() => actions.createContractNoteSuccess(contractNote.contract))
                    .catch(actions.createContractNoteFailure),
            ),
        );

    const createContractNoteSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_CONTRACT_NOTE_SUCCEED),
            pluck('payload'),
            map((payload: number) => actions.getContractNotesRequest(payload)),
        );

    const deleteContractNoteEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_NOTE_REQUESTED),
            pluck('payload'),
            switchMap((data: DeleteContractNoteRequest) =>
                contractService
                    .deleteContractNote(data)
                    .then(() => actions.deleteContractNoteSuccess(data.contract))
                    .catch(actions.deleteContractNoteFailure),
            ),
        );

    const deleteContractNoteSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_NOTE_SUCCEED),
            pluck('payload'),
            map((payload: number) => actions.getContractNotesRequest(payload)),
        );

    const editContractNoteEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_CONTRACT_NOTE_REQUESTED),
            pluck('payload'),
            switchMap((data: EditContractNoteRequest) =>
                contractService
                    .editContractNote(data)
                    .then(() => actions.editContractNoteSuccess(data.contract))
                    .catch(actions.editContractNoteFailure),
            ),
        );

    const editContractNoteSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_CONTRACT_NOTE_SUCCEED),
            pluck('payload'),
            map((payload: number) => actions.getContractNotesRequest(payload)),
        );

    const deleteContractNoteFileEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_NOTE_FILE_REQUESTED),
            pluck('payload'),
            switchMap((data: DeleteContractNoteFileRequest) =>
                contractService
                    .deleteContractNoteFile(data)
                    .then(() => actions.deleteContractNoteFileSuccess(data.contract))
                    .catch(actions.deleteContractNoteFileFailure),
            ),
        );

    const deleteContractFileEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_FILE_REQUESTED),
            pluck('payload'),
            switchMap((data: DeleteContractFileRequest) =>
                contractService
                    .deleteContractFile(data)
                    .then(() => actions.deleteContractFileSuccess(data.id))
                    .catch(actions.deleteContractFileFailure),
            ),
        );

    const deleteContractFileSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_FILE_SUCCEED),
            pluck('payload'),
            map((payload: GetContractRequest) => actions.getContractRequest(payload)),
        );

    const deleteContractNoteFileSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_CONTRACT_NOTE_FILE_SUCCEED),
            pluck('payload'),
            map((payload: number) => actions.getContractNotesRequest(payload)),
        );

    const addContractNoteFileEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ADD_CONTRACT_NOTE_FILE_REQUESTED),
            pluck('payload'),
            switchMap((data: AddContractNoteFileRequest) =>
                contractService
                    .addContractNoteFile(data)
                    .then(() => actions.addContractNoteFileSuccess(data.contract))
                    .catch(actions.addContractNoteFileFailure),
            ),
        );

    const addContractNoteFileSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ADD_CONTRACT_NOTE_FILE_SUCCEED),
            pluck('payload'),
            map((payload: number) => actions.getContractNotesRequest(payload)),
        );

    return combineEpics(
        archiveContractCategoryEpic,
        archiveDepartmentEpic,
        archiveLocationEpic,
        archiveContractPriorityEpic,
        archiveBenefitPriorityEpic,
        archiveContractCategorySuccessEpic,
        archiveDepartmentSuccessEpic,
        archiveLocationSuccessEpic,
        createContractEpic,
        createFilterEpic,
        deleteContractEpic,
        editContractEpic,
        editFilterEpic,
        filterContractsEpic,
        filterContractsSuccessEpic,
        getCategoriesEpic,
        getPortfolioFiltersEpic,
        getContractDetailsEpic,
        getAllContractsEpic,
        getMyContractsEpic,
        getContractEpic,
        getContractRolesEpic,
        getDepartmentsEpic,
        getLocationsEpic,
        addContractRoleEpic,
        deleteContractRoleEpic,
        getContractPrioritiesEpic,
        addContractPriorityEpic,
        editContractPriorityEpic,
        deleteContractPriorityEpic,
        deleteContractCategoryFilterEpic,
        deleteContractCategoryFilterSuccessEpic,
        deleteContractPriorityFilterEpic,
        deleteBenefitPriorityFilterEpic,
        deleteDepartmentFilterEpic,
        deleteDepartmentFilterSuccessEpic,
        deleteLocationFilterEpic,
        deleteLocationFilterSuccessEpic,
        toggleMultipleEmployeesContractRoleEpic,
        getContractNotesEpic,
        createContractNoteEpic,
        createContractNoteSuccessEpic,
        deleteContractNoteEpic,
        deleteContractNoteSuccessEpic,
        editContractNoteEpic,
        editContractNoteSuccessEpic,
        deleteContractNoteFileEpic,
        deleteContractFileEpic,
        deleteContractNoteFileSuccessEpic,
        addContractNoteFileEpic,
        addContractNoteFileSuccessEpic,
        deleteContractFileSuccessEpic,
    );
};
