import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { getUrlHashCode } from 'common/helpers/url';
import { FullPageSpinner } from 'common/components';

import {
    sendResetPasswordRequest,
    verifyPasswordResetTokenRequest,
    resetPasswordChangeRequest,
} from '../actions/userActions';
import {
    getFetchingUserStatus,
    getPasswordTokenStatus,
    getVerifyingTokenStatus,
} from '../selectors/userSelectors';

import { ResetPasswordRequestForm } from '../components/ResetPasswordRequestForm';
import { ResetPasswordChange } from '../components/ResetPasswordChange';

interface MatchParams {
    token: string;
}

interface ResetPasswordStateProps {
    isProcessing: boolean;
    isVerifyingToken: boolean;
    tokenVerified: boolean;
}

interface ResetPasswordDispatchProps {
    sendResetPasswordRequest: typeof sendResetPasswordRequest;
    verifyPasswordResetTokenRequest: typeof verifyPasswordResetTokenRequest;
    resetPasswordChangeRequest: typeof resetPasswordChangeRequest;
}

export type ResetPasswordContainerProps = ResetPasswordStateProps &
    RouteComponentProps<MatchParams> &
    ResetPasswordDispatchProps &
    ResetPasswordContainerState;

interface ResetPasswordContainerState {
    token: string;
}

class ResetPassword extends React.Component<
    ResetPasswordContainerProps,
    ResetPasswordContainerState
> {
    public state: ResetPasswordContainerState = {
        token: '',
    };

    public componentDidMount(): void {
        const token = getUrlHashCode();
        if (!token) {
            return;
        }

        this.props.verifyPasswordResetTokenRequest(token);
        this.setState({ token });
    }

    public render(): JSX.Element {
        const { tokenVerified, isVerifyingToken } = this.props;

        if (isVerifyingToken) {
            return <FullPageSpinner />;
        }

        if (tokenVerified) {
            return <ResetPasswordChange {...this.props} token={this.state.token} />;
        }

        return <ResetPasswordRequestForm {...this.props} />;
    }
}

const mapStateToProps = createSelector(
    getFetchingUserStatus,
    getVerifyingTokenStatus,
    getPasswordTokenStatus,
    (isProcessing, isVerifyingToken, tokenVerified): ResetPasswordStateProps => ({
        isProcessing,
        isVerifyingToken,
        tokenVerified,
    }),
);

export const ResetPasswordContainer = connect(mapStateToProps, {
    sendResetPasswordRequest,
    verifyPasswordResetTokenRequest,
    resetPasswordChangeRequest,
})(ResetPassword);
