import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { importContractsRequest } from 'features/import/actions/importActions';
import { redirectToUrl } from 'common/actions/navigationAction';

import { ImportContracts as ImportContractsComponent } from '../components/ImportContracts';
import { getProcessingImportStatus, getImportErrors } from '../selectors/importSelectors';
import { isAdmin } from 'features/user/selectors/userSelectors';

export interface ImportContractsDispatchProps {
    importContractsRequest: typeof importContractsRequest;
    redirectToUrl: typeof redirectToUrl;
}

export interface ImportContractsContainerStateProps {
    isProcessing: boolean;
    errors: string[];
    isUserAdmin: boolean;
}

export type ImportContractsContainerProps = ImportContractsDispatchProps &
    ImportContractsContainerStateProps;

const ImportContracts: React.FunctionComponent<ImportContractsContainerProps> = (props) => (
    <ImportContractsComponent {...props} />
);

const mapStateToProps = createSelector(
    getProcessingImportStatus,
    getImportErrors,
    isAdmin,
    (isProcessing, errors, isUserAdmin): ImportContractsContainerStateProps => ({
        isProcessing,
        errors,
        isUserAdmin,
    }),
);

export const ImportContractsContainer = connect(mapStateToProps, {
    importContractsRequest,
    redirectToUrl,
})(ImportContracts);
