import { Component } from 'react';

import { FilterType } from 'features/contract/models/contractModels';
import { PaginationView } from 'features/pagination/models/paginationModel';
import {
    getLocationsSuccess,
    getDepartmentsSuccess,
    getCategoriesSuccess,
    getContractPrioritiesFiltersSuccess,
    getBenefitPrioritiesFiltersSuccess,
} from 'features/contract/actions/contractActions';
import { getBenefitCategoriesSuccess } from 'features/benefit/actions';
import { filterTooltips } from 'common/tooltips/filterTooltips';

import { EditFilterProps } from '../containers/EditFilterContainer';
import { EditPaths } from '../models/editModel';
import { FilterTab } from './FilterTab';
import { EditFiltersNavigation } from './EditFiltersNavigation';

export class EditFilters extends Component<EditFilterProps> {
    public render(): JSX.Element {
        const {
            archiveContractCategoryRequest,
            archiveBenefitCategoryRequest,
            archiveContractPriorityRequest,
            archiveBenefitPriorityRequest,
            archiveLocationRequest,
            archiveDepartmentRequest,
            benefitCategories,
            contractCategories,
            departments,
            editFilterRequest,
            deleteContractCategoryFilterRequest,
            deleteContractPriorityFilterRequest,
            deleteBenefitCategoryFilterRequest,
            deleteBenefitPriorityFilterRequest,
            deleteDepartmentFilterRequest,
            deleteLocationFilterRequest,
            locations,
            path,
            contractPrioritiesFilters,
            benefitPrioritiesFilters,
            pageNumber,
        } = this.props;

        return (
            <>
                <EditFiltersNavigation />
                {path.includes(EditPaths.FiltersContractCategories) && (
                    <FilterTab
                        view={PaginationView.ContractCategories}
                        data={contractCategories}
                        paginationSuccessCallback={getCategoriesSuccess}
                        header="Project Categories"
                        filterType={FilterType.CONTRACT_CATEGORIES}
                        onArchive={archiveContractCategoryRequest}
                        onArchiveMessage="Project Category"
                        onEdit={editFilterRequest}
                        pathUrl={path}
                        pageNumber={pageNumber}
                        onDelete={deleteContractCategoryFilterRequest}
                        tooltip={filterTooltips.contractCategories}
                    />
                )}
                {path.includes(EditPaths.FiltersLocations) && (
                    <FilterTab
                        view={PaginationView.Locations}
                        data={locations}
                        paginationSuccessCallback={getLocationsSuccess}
                        header="Locations"
                        filterType={FilterType.LOCATIONS}
                        onArchive={archiveLocationRequest}
                        onArchiveMessage="Location"
                        onEdit={editFilterRequest}
                        pathUrl={path}
                        pageNumber={pageNumber}
                        onDelete={deleteLocationFilterRequest}
                        tooltip={filterTooltips.locations}
                    />
                )}
                {path.includes(EditPaths.FiltersDepartments) && (
                    <FilterTab
                        view={PaginationView.Departments}
                        data={departments}
                        paginationSuccessCallback={getDepartmentsSuccess}
                        header="Departments"
                        filterType={FilterType.DEPARTMENTS}
                        onArchive={archiveDepartmentRequest}
                        onArchiveMessage="Department"
                        onEdit={editFilterRequest}
                        pathUrl={path}
                        pageNumber={pageNumber}
                        onDelete={deleteDepartmentFilterRequest}
                        tooltip={filterTooltips.departments}
                    />
                )}
                {path.includes(EditPaths.FiltersBenefitCategories) && (
                    <FilterTab
                        view={PaginationView.BenefitCategories}
                        data={benefitCategories}
                        paginationSuccessCallback={getBenefitCategoriesSuccess}
                        header="Benefit Categories"
                        filterType={FilterType.BENEFIT_CATEGORIES}
                        onArchive={archiveBenefitCategoryRequest}
                        onArchiveMessage="Benefit Category"
                        onEdit={editFilterRequest}
                        pathUrl={path}
                        pageNumber={pageNumber}
                        onDelete={deleteBenefitCategoryFilterRequest}
                        tooltip={filterTooltips.benefitCategories}
                    />
                )}
                {path.includes(EditPaths.FiltersContractPriorities) && (
                    <FilterTab
                        view={PaginationView.ContractPriorities}
                        data={contractPrioritiesFilters}
                        paginationSuccessCallback={getContractPrioritiesFiltersSuccess}
                        header="Project Priorities"
                        filterType={FilterType.CONTRACT_PRIORITIES}
                        onArchive={archiveContractPriorityRequest}
                        onArchiveMessage="Project Priority"
                        onEdit={editFilterRequest}
                        pathUrl={path}
                        pageNumber={pageNumber}
                        onDelete={deleteContractPriorityFilterRequest}
                        tooltip={filterTooltips.contractPriorities}
                    />
                )}
                {path.includes(EditPaths.FiltersBenefitPriorities) && (
                    <FilterTab
                        view={PaginationView.BenefitPriorities}
                        data={benefitPrioritiesFilters}
                        paginationSuccessCallback={getBenefitPrioritiesFiltersSuccess}
                        header="Benefit Priorities"
                        filterType={FilterType.BENEFIT_PRIORITIES}
                        onArchive={archiveBenefitPriorityRequest}
                        onArchiveMessage="Benefit Priority"
                        onEdit={editFilterRequest}
                        pathUrl={path}
                        pageNumber={pageNumber}
                        onDelete={deleteBenefitPriorityFilterRequest}
                        tooltip={filterTooltips.benefitPriorities}
                    />
                )}
            </>
        );
    }
}
