import styled from 'styled-components';
import { Card } from 'antd';

export const WrappingCard = styled(Card)`
    padding: 0;
    margin: 1.5rem;
    width: 100%;

    > .ant-card-head {
        padding: 0;
    }

    > .ant-card-body {
        padding: 24px 0;
    }

    .ant-card-head-title {
        white-space: normal;
    }
`;
