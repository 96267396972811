import * as React from 'react';

import { PaddedSection } from 'common/components';
import { Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input, PhoneInput, ResetButton } from 'common/components/deprecated/form';

import { SubmitButtons, SubmitButtonsProps } from './SupplierUserForm';

type Props = Antd3FormProps & SubmitButtonsProps;

export const SupplierInviteForm: React.FunctionComponent<Props> = (props) => {
    const { form } = props;

    return (
        <PaddedSection>
            <ResetButton form={form} />
            <Input form={form} id="firstName" label="First name" />
            <Input form={form} id="lastName" label="Last Name" />
            <Input form={form} id="email" label="E-mail Address" type="email" />
            <PhoneInput form={form} id="telephone" placeholder="Telephone Number" />
            <SubmitButtons {...props} />
        </PaddedSection>
    );
};
