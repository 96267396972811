import { DatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { Antd3Form, Antd3FormModel } from '../antd3';

const Picker = styled(DatePicker)`
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    margin-top: 8px;
`;

const Item = styled(Antd3Form.Item)`
    && {
        width: 100%;

        :not(:last-child) {
            margin-right: 10px;
        }
    }
`;

interface Input {
    id: string;
    placeholder: string;
    initialValue?: string;
}

interface Props extends Antd3FormModel {
    inputs: Input[];
    label: string;
}

export const RangePicker = ({ inputs, form, label }: Props): JSX.Element => (
    <>
        <h4>{label}:</h4>
        <Wrapper>
            {inputs.map(({ id, placeholder, initialValue }) => (
                <Item key={id}>
                    {form.getFieldDecorator(id, {
                        initialValue: initialValue && moment(initialValue),
                    })(<Picker size="large" placeholder={placeholder} />)}
                </Item>
            ))}
        </Wrapper>
    </>
);
