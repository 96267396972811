import { combineEpics, ofType } from 'redux-observable';
import {
    switchMap,
    pluck,
    map,
    exhaustMap,
    mergeMap,
    catchError,
    tap,
    filter,
    ignoreElements,
} from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

import { AppEpic } from 'common/epics/appEpic';
import { setAfterLoginRoutes } from 'config/config';
import { LOG_IN_SUCCEED } from 'features/auth/actions/authActions';
import { clearAllFilters } from 'features/pagination/actions/paginationActions';
import { getProfileIdFromQuery } from 'common/helpers/utils';
import { StorageService } from 'common/services/storageService';
import { redirectToUrl } from 'common/actions/navigationAction';

import * as actions from '../actions/userActions';

import {
    User,
    CreateAccountCredentials,
    ProfileRequest,
    UpdateUserData,
    UserOrganisation,
    ChangePasswordRequest,
    ResetPasswordRequest,
    UserRole,
    ChangeEmailRequest,
    ChangeEmailResponse,
    ArchiveEmployee,
    UserPaths,
    Subscription,
    InvoiceData,
    SubscriptionPurchaseData,
    Invoice,
} from '../models/userModel';
import { UserService } from '../services/userService';
import { getUserId } from '../selectors/userSelectors';

export const userEpicFactory = (
    userService: UserService,
    storageService: StorageService,
): AppEpic => {
    const archiveEmployeeEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_EMPLOYEE_REQUESTED),
            pluck('payload'),
            switchMap(({ id, archived }: ArchiveEmployee) =>
                userService
                    .archiveEmployee(id, archived)
                    .then(() =>
                        actions.archiveEmployeeSuccess(
                            `Employee has been ${archived ? 'archived.' : 'restored.'}`,
                        ),
                    )
                    .catch(actions.archiveEmployeeFailure),
            ),
        );

    const archiveEmployeeSuccessEpic: AppEpic = (action$) =>
        action$.pipe(ofType(actions.ARCHIVE_EMPLOYEE_SUCCEED), map(actions.getEmployeesRequest));

    const confirmNewPasswordEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CONFIRM_NEW_PASSWORD_REQUESTED),
            pluck('payload'),
            switchMap((token: string) =>
                userService
                    .confirmNewPassword(token)
                    .then(() => actions.confirmNewPasswordSuccess('Password changed'))
                    .catch(actions.confirmNewPasswordFailure),
            ),
        );

    const createAccountEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_ACCOUNT_REQUESTED),
            pluck('payload'),
            switchMap((createAccount: CreateAccountCredentials) =>
                userService
                    .createAccount(createAccount)
                    .then(() =>
                        actions.createAccountSuccess(
                            'Congratulations! Your account has been created',
                        ),
                    )
                    .catch(actions.createAccountFailure),
            ),
        );

    const editEmployeeEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_EMPLOYEE_REQUESTED),
            pluck('payload'),
            switchMap((employee: ProfileRequest) =>
                userService
                    .editEmployee(employee)
                    .then(actions.editEmployeeSuccess)
                    .catch(actions.editEmployeeFailure),
            ),
        );

    const getEmployeesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_EMPLOYEES_REQUESTED),
            switchMap(() =>
                userService
                    .getEmployees()
                    .then(actions.getEmployeesSuccess)
                    .catch(actions.getEmployeesFailure),
            ),
        );

    const sendUsersOrganisationsRequest: AppEpic = (action$) =>
        action$.pipe(ofType(actions.GET_USER_SUCCEED), map(actions.updateUserOrganisationsRequest));

    const getSubscriptionTiers: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_SUBSCRIPTION_TIERS_REQUESTED),
            switchMap(() =>
                userService
                    .getSubscriptionTiers()
                    .then((subscriptions: Subscription[]) =>
                        actions.GetSubscriptionTiersSuccess(subscriptions),
                    )
                    .catch(actions.GetSubscriptionTiersFailure),
            ),
        );

    const getSubscriptions: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.UPDATE_USER_ACTIVE_ORGANISATION),
            switchMap(() =>
                userService
                    .getSubscriptions()
                    .then((subscriptions: Subscription[]) =>
                        actions.GetSubscriptionsSuccess(subscriptions),
                    )
                    .catch(actions.GetSubscriptionsFailure),
            ),
        );

    const getSubscriptionInvoices: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_SUBSCRIPTION_INVOICES_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                userService
                    .getSubscriptionInvoices(id)
                    .then((data: Invoice[]) => actions.getSubscriptionInvoicesSuccess(data))
                    .catch(actions.getSubscriptionInvoicesFailure),
            ),
        );

    const getInvoiceData: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_INVOICE_DATA_REQUESTED),
            pluck('payload'),
            switchMap(() =>
                userService
                    .getInvoiceData()
                    .then((data: InvoiceData) => actions.getInvoiceDataSuccess(data))
                    .catch(actions.getInvoiceDataFailure),
            ),
        );

    const saveInvoiceData: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.SAVE_INVOICE_DATA_REQUESTED),
            pluck('payload'),
            switchMap((data: InvoiceData) =>
                userService
                    .saveInvoiceData(data)
                    .then((data: InvoiceData) => actions.saveInvoiceDataSuccess(data))
                    .catch((error) => actions.saveInvoiceDataFailure(error)),
            ),
        );

    const updateInvoiceData: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.SAVE_INVOICE_DATA_REQUESTED),
            pluck('payload'),
            switchMap((data: InvoiceData) =>
                userService
                    .updateInvoiceData(data)
                    .then((data: InvoiceData) => actions.saveInvoiceDataSuccess(data))
                    .catch((error) => actions.saveInvoiceDataFailure(error)),
            ),
        );

    const purchaseSubscription: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.PURCHASE_SUBSCRIPTION_REQUESTED),
            pluck('payload'),
            switchMap((data: SubscriptionPurchaseData) =>
                userService
                    .requestSubscriptionPurchase(data)
                    .then((data: SubscriptionPurchaseData) =>
                        actions.purchaseSubscriptionSuccess(data),
                    )
                    .catch((error) => actions.purchaseSubscriptionFailure(error)),
            ),
        );

    const getUsersOrganisations: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.UPDATE_USER_ORGANISATIONS_REQUESTED),
            switchMap(() =>
                userService
                    .getUserOrganisations()
                    .then((organisations: UserOrganisation[]) =>
                        actions.updateUserOrganisationsSuccess(organisations),
                    )
                    .catch(actions.updateUserOrganisationsFailure),
            ),
        );

    const setUserActiveOrganisationAfterLogin: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.UPDATE_USER_ORGANISATIONS_SUCCEED),
            pluck('payload'),
            switchMap((organisations: UserOrganisation[]) => {
                const profileIdFromQuery = getProfileIdFromQuery();

                const organisationToSet = organisations.find(
                    (organisation) => organisation.idProfile === profileIdFromQuery,
                );

                if (organisationToSet) {
                    return of(actions.updateUserActiveOrganisation(organisationToSet));
                }

                return storageService
                    .read('userActiveOrganisation')
                    .then((savedOrganisation) => {
                        if (savedOrganisation) {
                            return actions.updateUserActiveOrganisation(savedOrganisation);
                        }

                        return organisations.length > 1
                            ? actions.toggleUserOrganisationSelectModal(true)
                            : actions.updateUserActiveOrganisation(organisations[0]);
                    })
                    .catch(() =>
                        actions.updateUserOrganisationsFailure(
                            "Couldn't restore previously set profile",
                        ),
                    );
            }),
        );

    const saveUserActiveOrganisation: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.UPDATE_USER_ACTIVE_ORGANISATION),
            pluck('payload'),
            tap((payload: UserOrganisation) =>
                storageService.write('userActiveOrganisation', payload).then(() => {
                    setAfterLoginRoutes(payload.organisation.id);
                }),
            ),
            filter(() => getProfileIdFromQuery() === false),
            ignoreElements(),
        );

    const clearFiltersAfterOrganizationChange: AppEpic = (action$) =>
        action$.pipe(ofType(actions.UPDATE_USER_ACTIVE_ORGANISATION), map(clearAllFilters));

    const getUserEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_USER_REQUESTED, LOG_IN_SUCCEED, actions.UPDATE_USER_DATA_SUCCEED),
            switchMap(() => userService.getUser()),
            mergeMap(({ email, firstName, lastName, userId, username, organisation, ...rest }) => {
                setAfterLoginRoutes(organisation);

                return [
                    actions.getUserSuccess({
                        organisation,
                        ...rest,
                        user: {
                            id: userId,
                            email,
                            firstName,
                            lastName,
                            username,
                        },
                    }),
                ];
            }),
            catchError((err) => of(actions.getUserFailure(err))),
        );

    const updateUserDataEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.UPDATE_USER_DATA_REQUESTED),
            pluck('payload'),
            switchMap((data: UpdateUserData) =>
                userService
                    .updateUserData(data)
                    .then((user: User) =>
                        actions.updateUserDataSuccess(user, 'User details updated'),
                    )
                    .catch(actions.updateUserDataFailure),
            ),
        );

    const changeUserPassword: AppEpic = (action$, { getState }) =>
        action$.pipe(
            ofType(actions.CHANGE_USER_PASSWORD_REQUESTED),
            pluck('payload'),
            switchMap((payload: ChangePasswordRequest) =>
                userService
                    .changeUserPassword(getUserId(getState()), payload)
                    .then(() => actions.changeUserPasswordSuccess('Your password has been changed'))
                    .catch(actions.changeUserPasswordFailure),
            ),
        );

    const sendResetPasswordMailEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.SEND_RESET_PASSWORD_REQUESTED),
            pluck('payload'),
            switchMap((email: string) =>
                userService
                    .sendResetPassword(email)
                    .then(() =>
                        actions.sendResetPasswordSuccess('A password reset link has been sent'),
                    )
                    .catch(actions.sendResetPasswordFailure),
            ),
        );

    const verifyResetPasswordTokenEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.VERIFY_PASSWORD_RESET_TOKEN_REQUESTED),
            pluck('payload'),
            switchMap((token: string) =>
                userService
                    .verifyResetPasswordToken(token)
                    .then(actions.verifyPasswordResetTokenSuccess)
                    .catch(actions.verifyPasswordResetTokenFailure),
            ),
        );

    const resetPasswordChangeEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.RESET_PASSWORD_CHANGE_REQUESTED),
            pluck('payload'),
            exhaustMap((resetPassword: ResetPasswordRequest) =>
                userService
                    .resetPasswordChange(resetPassword)
                    .then(() =>
                        actions.resetPasswordChangeSuccess(
                            'Your password has been successfully changed',
                        ),
                    )
                    .catch(actions.resetPasswordChangeFailure),
            ),
        );

    const setRoleBasedOnUserDataEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_USER_SUCCEED),
            pluck('payload'),
            map(({ isAdministrator }) =>
                actions.changeUserRole(isAdministrator ? UserRole.Admin : UserRole.Partner),
            ),
        );

    const redirectAfterEmailConsentsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_USER_SUCCEED),
            filter(() => location.pathname === UserPaths.EditConsents),
            map(() => redirectToUrl('/')),
        );

    const updateUserEmailEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.UPDATE_USER_EMAIL_REQUESTED),
            pluck('payload'),
            switchMap((data: ChangeEmailRequest) =>
                userService
                    .updateUserEmail(data)
                    .then(({ email }: ChangeEmailResponse) =>
                        actions.updateUserEmailSuccess(email, 'User email updated'),
                    )
                    .catch(actions.updateUserEmailFailure),
            ),
        );

    const deleteEmployeeEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_EMPLOYEE_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                userService
                    .deleteEmployee(id)
                    .then(() => actions.deleteEmployeeSuccess('Employee has been deleted.'))
                    .catch(actions.deleteEmployeeFailure),
            ),
        );

    const deleteEmployeeSuccessEpic: AppEpic = (action$) =>
        action$.pipe(ofType(actions.DELETE_EMPLOYEE_SUCCEED), map(actions.getEmployeesRequest));

    return combineEpics(
        archiveEmployeeEpic,
        archiveEmployeeSuccessEpic,
        confirmNewPasswordEpic,
        createAccountEpic,
        editEmployeeEpic,
        getEmployeesEpic,
        getUserEpic,
        updateUserDataEpic,
        changeUserPassword,
        sendResetPasswordMailEpic,
        verifyResetPasswordTokenEpic,
        resetPasswordChangeEpic,
        setRoleBasedOnUserDataEpic,
        updateUserEmailEpic,
        getUsersOrganisations,
        sendUsersOrganisationsRequest,
        saveUserActiveOrganisation,
        setUserActiveOrganisationAfterLogin,
        clearFiltersAfterOrganizationChange,
        deleteEmployeeEpic,
        deleteEmployeeSuccessEpic,
        redirectAfterEmailConsentsEpic,
        getSubscriptionTiers,
        getInvoiceData,
        saveInvoiceData,
        updateInvoiceData,
        purchaseSubscription,
        getSubscriptions,
        getSubscriptionInvoices,
    );
};
