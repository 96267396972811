import { InvoiceData, Subscription } from 'features/user/models/userModel';
import * as actions from '../actions/organisationActions';
import { Organisation } from '../models/organisationModels';

export interface OrganisationState {
    executives: Organisation[];
    potentialExecutives: Organisation[];
    organisations: Organisation[];
    invitedOrganisationExistsStatus?: string;
    isFetching: boolean;
    invoiceData?: InvoiceData;
}

const defaultOrganisationState = {
    executives: [],
    potentialExecutives: [],
    organisations: [],
    isFetching: false,
    invitedOrganisationExistsStatus: '',
};

export const organisationReducer = (
    state: OrganisationState = defaultOrganisationState,
    action: actions.OrganisationAction,
) => {
    switch (action.type) {
        case actions.GET_EXECUTIVES_REQUESTED:
        case actions.GET_ALL_EXECUTIVES_REQUESTED:
        case actions.GET_POTENTIAL_EXECUTIVES_REQUESTED:
        case actions.GET_ORGANISATION_REQUESTED:
            return {
                ...state,
                isFetching: true,
            };
        case actions.GET_EXECUTIVES_SUCCEED:
        case actions.GET_ALL_EXECUTIVES_SUCCEED:
        case actions.GET_POTENTIAL_EXECUTIVES_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
            };
        case actions.GET_ORGANISATION_SUCCEED:
            return {
                ...state,
                organisations: [
                    action.payload,
                    ...state.organisations.filter(({ id }) => id !== action.payload!.id),
                ],
                isFetching: false,
            };
        case actions.GET_EXECUTIVES_FAILED:
        case actions.GET_ALL_EXECUTIVES_FAILED:
        case actions.GET_POTENTIAL_EXECUTIVES_FAILED:
        case actions.GET_ORGANISATION_FAILED:
            return {
                ...state,
                isFetching: false,
            };
        case actions.CHECK_ORGANISATION_EXISTS_SUCCEED:
            return {
                ...state,
                invitedOrganisationExistsStatus: action.payload,
            };
        default:
            return state;
    }
};
