import { PortfolioFiltersEnum, PortfolioFilter } from '../models/portfolioFilterModel';

export const getPortfolioFiltersChangeOnGet = (
    depth: PortfolioFiltersEnum,
    portfolioFilters: PortfolioFilter[],
) => {
    switch (depth) {
        case PortfolioFiltersEnum.SubProgram: {
            return {
                subPrograms: portfolioFilters,
                mainProjects: [],
                subProjects: [],
            };
        }
        case PortfolioFiltersEnum.MainProject: {
            return {
                mainProjects: portfolioFilters,
                subProjects: [],
            };
        }
        default:
            return {
                subProjects: portfolioFilters,
            };
    }
};
