import { Menu } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import posthog from 'posthog-js';
import { orderBy } from 'lodash';
import { LinkOutlined, LogoutOutlined } from '@ant-design/icons';

import { getMenuRoutes } from 'common/helpers/getMenuRoutes';
import { isAdmin, isTenant } from 'features/user/selectors/userSelectors';
import { SENTRY_ENVIRONMENT } from 'config/env';
import { logOutRequest } from 'features/auth/actions/authActions';

const LinkLabel = styled.span`
    margin-left: 16px;
`;

type Props = {
    onLinkClick?: () => void;
};

export const MenuContent = ({ onLinkClick }: Props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const isUserAdmin = useSelector(isAdmin);
    const isUserTenant = useSelector(isTenant);

    const handleLogout = () => {
        if (SENTRY_ENVIRONMENT === 'frontend-production') {
            posthog.reset(true);
        }

        dispatch(logOutRequest());
    };

    const menuRoutes = getMenuRoutes(isUserTenant, isUserAdmin);
    const routePrefix = location.pathname.split('/')[1];

    return (
        <Menu mode="inline" theme="dark" selectedKeys={[routePrefix]}>
            {orderBy(menuRoutes, (route) => route.order).map((route) => (
                <Menu.Item key={route.key}>
                    <Link to={route.path} onClick={onLinkClick}>
                        {route.label}
                    </Link>
                </Menu.Item>
            ))}
            <Menu.Item key="tech-support">
                <a href="https://support.cenefits.com/" target="_blank" rel="noopener noreferrer">
                    <LinkOutlined />
                    <LinkLabel>Tech Support</LinkLabel>
                </a>
            </Menu.Item>
            <Menu.Item key="logout" onClick={handleLogout}>
                <LogoutOutlined />
                <LinkLabel>Logout</LinkLabel>
            </Menu.Item>
        </Menu>
    );
};
