import styled from 'styled-components';
import { InputNumber, InputNumberProps as AntdInputNumberProps } from 'antd';

import { Antd3FormElementProps } from './Antd3Form';

export const StyledInputNumber = styled(InputNumber)`
    && {
        width: 100%;
    }
`;

export const Antd3InputNumber = ({
    form,
    id,
    required = false,
    getFieldDecoratorOptions,
    ...inputProps
}: AntdInputNumberProps & Antd3FormElementProps) => (
    <>
        {form?.getFieldDecorator ? (
            form.getFieldDecorator(
                id,
                getFieldDecoratorOptions,
            )(<StyledInputNumber {...inputProps} />)
        ) : (
            <StyledInputNumber {...inputProps} />
        )}
    </>
);
