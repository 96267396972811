import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, RouteProps } from 'react-router';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { isTenant } from 'features/user/selectors/userSelectors';

import { ApprovedUploadScores as ApprovedUploadScoresComponent } from '../components/ApprovedUploadScores';
import { ApprovedUploadEvidences as ApprovedUploadEvidencesComponent } from '../components/ApprovedUploadEvidences';
import { getScorableBenefits, getReadyForEvidenceBenefits } from '../selectors/uploadSelectors';
import { UploadPaths, UploadBenefit } from '../models/uploadModels';

export interface ApprovedUploadStateProps {
    approvedScores: UploadBenefit[];
    approvedEvidences: UploadBenefit[];
    path: string;
    filter?: PaginationFilters;
    isTenant: boolean;
}

interface ApprovedUploadDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    redirectToUrl: typeof redirectToUrl;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
}

export type ApprovedUploadProps = ApprovedUploadStateProps &
    ApprovedUploadDispatchProps &
    RouteComponentProps<{}>;

class ApprovedUpload extends React.Component<ApprovedUploadProps> {
    public render(): JSX.Element {
        return this.props.path === UploadPaths.ApprovedScore ? (
            <ApprovedUploadScoresComponent {...this.props} />
        ) : (
            <ApprovedUploadEvidencesComponent {...this.props} />
        );
    }
}

const mapStateToProps = (
    state: AppState,
    { match: { path } }: RouteComponentProps<{}>,
): ApprovedUploadStateProps & RouteProps => ({
    approvedScores: getScorableBenefits(state),
    approvedEvidences: getReadyForEvidenceBenefits(state),
    filter: getFilterParams(
        state,
        path === UploadPaths.ApprovedScore
            ? PaginationView.ApprovedUploadScores
            : PaginationView.ApprovedUploadEvidence,
    ),
    path,
    isTenant: isTenant(state),
});

export const ApprovedUploadContainer = connect(mapStateToProps, {
    redirectToUrl,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
})(ApprovedUpload);
