import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { BenefitTemplate } from 'features/benefit/models/benefitModels';
import {
    archiveBenefitTemplateRequest,
    deleteBenefitTemplateRequest,
} from 'features/benefit/actions';
import { getBenefitTemplates } from 'features/benefit/selectors/benefitSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import { openModal } from 'common/helpers/modal';

import { EditBenefitTemplates as EditBenefitTemplatesComponent } from '../components/EditBenefitTemplates';
import { isAdmin, isTenant } from 'features/user/selectors/userSelectors';
import { StatusExplanationModal } from 'features/check/components/StatusExplanation';

export interface EditBenefitTemplateStateProps {
    benefitTemplates: BenefitTemplate[];
    isUserAdmin?: boolean;
}

interface EditBenefitTemplateContainerDispatchProps {
    archiveBenefitTemplateRequest: typeof archiveBenefitTemplateRequest;
    deleteBenefitTemplateRequest: typeof deleteBenefitTemplateRequest;
    redirectToUrl: typeof redirectToUrl;
}

export type EditBenefitTemplateProps = EditBenefitTemplateStateProps &
    EditBenefitTemplateContainerDispatchProps;

class EditBenefitTemplate extends React.Component<EditBenefitTemplateProps> {
    private archiveBenefitTemplate = (
        e: React.FormEvent,
        id: number,
        name: string,
        archived: boolean,
    ) => {
        e.preventDefault();

        openModal({
            title: `${name}: Are you sure you want to ${
                archived ? 'archive' : 'restore'
            } this benefit template?`,
            callback: () => this.props.archiveBenefitTemplateRequest(id, archived),
        });
    };

    private deleteBenefitTemplate = (e: React.FormEvent, id: number, name: string) => {
        e.preventDefault();

        openModal({
            title: `${name}: Are you sure you want to permanently delete this benefit template?`,
            callback: () => this.props.deleteBenefitTemplateRequest(id),
        });
    };

    public render(): JSX.Element {
        return (
            <EditBenefitTemplatesComponent
                {...this.props}
                archiveBenefitTemplate={this.archiveBenefitTemplate}
                deleteBenefitTemplate={this.deleteBenefitTemplate}
            />
        );
    }
}

const mapStateToProps = createSelector(
    getBenefitTemplates,
    isAdmin,
    (benefitTemplates, isUserAdmin): EditBenefitTemplateStateProps => ({
        benefitTemplates,
        isUserAdmin,
    }),
);

export const EditBenefitTemplateContainer = connect(mapStateToProps, {
    archiveBenefitTemplateRequest,
    redirectToUrl,
    deleteBenefitTemplateRequest,
})(EditBenefitTemplate);
