import { Modal } from 'antd';

import { WrappedFormUtils } from 'common/components/deprecated/antd3';
import { Input } from 'common/components/deprecated/form';

interface PostcodeFormModalComponentProps {
    form: WrappedFormUtils;
    name: string;
    isVisible: boolean;
    handleSubmit: () => void;
    toggleModal: () => void;
    title: string;
}

export const PortfolioFilterFormModal = ({
    name,
    form,
    handleSubmit,
    toggleModal,
    isVisible,
    title,
}: PostcodeFormModalComponentProps) => (
    <Modal title={title} onOk={handleSubmit} onCancel={toggleModal} visible={isVisible}>
        <Input id="name" form={form} label="Name" initialValue={name} />
    </Modal>
);
