import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AutocompleteSelect, PaddedSection } from 'common/components';
import { FormFieldIds } from 'common/helpers/utils';
import { templatedBenefitTooltips } from 'common/tooltips/templatedBenefitTooltips';
import { migrateBenefitsRequest } from 'features/benefit/actions';
import { getBenefitTemplates } from 'features/benefit/selectors/benefitSelectors';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import styled from 'styled-components';

export const MigrateBenefitsBetweenTemplates = () => {
    const dispatch = useDispatch();
    const [selectedSourceTemplateId, setSelectedSourceTemplateId] = useState<number | undefined>();
    const benefitTemplates = useSelector(getBenefitTemplates);
    const { benefitId } = useParams<{ benefitId: string }>();

    const StyledSelection = styled.div`
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    `;

    const handleBenefitsMigration = () => {
        const benefitTemplate = benefitTemplates.find(
            (template) => template.id.toString() === benefitId,
        );

        dispatch(
            migrateBenefitsRequest({
                sourceTemplateId: selectedSourceTemplateId,
                targetTemplateId: benefitTemplate ? benefitTemplate.id : undefined,
            }),
        );
    };

    const handleOnSelectTemplateId = (id: number) => setSelectedSourceTemplateId(id);

    const openMigrationModal = () => {
        const modal = Modal.confirm({
            title: `Are you sure you want to migrate all benefits from the selected Template?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                handleBenefitsMigration();
                modal.destroy();
            },
        });
    };

    return (
        <StyledSelection>
            <AutocompleteSelect
                id={FormFieldIds.SourceTemplate}
                tooltip={templatedBenefitTooltips.BenefitsMigration}
                label={'Migrate benefits from another Benefit Template'}
                autocompleteField={AutocompleteField.BenefitTemplate}
                optionLabelFormatter={({ outcome }) => outcome}
                onSelect={handleOnSelectTemplateId}
            />
            <Button onClick={openMigrationModal} block>
                Migrate Benefits
            </Button>
        </StyledSelection>
    );
};
