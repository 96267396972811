import React from 'react';
import { List } from 'antd';

interface ErrorListProps {
    errors: string[];
}

const renderItem = (error: string) => <List.Item key={error}>{error}</List.Item>;

export const ErrorList: React.FunctionComponent<ErrorListProps> = ({ errors }) => (
    <List size="large" dataSource={errors} renderItem={renderItem} />
);
