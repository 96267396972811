import styled from 'styled-components';
import { Button } from 'antd';

import { PaginationView } from 'features/pagination/models/paginationModel';
import { clearFilters as clearFilterAction } from 'features/pagination/actions/paginationActions';

import { WrappedFormUtils } from '../antd3';

export const ResetButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    button {
        height: 40px;
    }
`;

interface Props {
    form: WrappedFormUtils;
    clearFilters?: typeof clearFilterAction;
    paginationView?: PaginationView;
}

export const ResetButton = ({ form, paginationView, clearFilters }: Props) => {
    const resetForm = () => {
        if (clearFilters !== undefined && paginationView !== undefined) {
            clearFilters(paginationView);
        }
        form.resetFields();
    };

    return (
        <ResetButtonWrapper>
            <Button type="default" onClick={resetForm}>
                Reset form
            </Button>
        </ResetButtonWrapper>
    );
};
