import posthog from 'posthog-js';

import { Action } from 'common/actions/appAction';

import { SENTRY_ENVIRONMENT } from 'config/env';
import {
    BenefitIds,
    Score,
    ScoreRequest,
    ScoreApproval,
    CreateScoreResponse,
    FileData,
    ScoreDecline,
    DeleteScoreFileRequest,
    UploadBenefit,
    ScoreData,
} from '../models/uploadModels';

export const APPROVE_SCORE_REQUESTED = 'APPROVE_SCORE_REQUESTED';
export type ApproveScoreRequestAction = Action<typeof APPROVE_SCORE_REQUESTED, ScoreApproval>;
export const approveScoreRequest = (score: ScoreApproval): ApproveScoreRequestAction => ({
    type: APPROVE_SCORE_REQUESTED,
    payload: score,
});

export const APPROVE_SCORE_SUCCEED = 'APPROVE_SCORE_SUCCEED';
export type ApproveScoreSuccessAction = Action<
    typeof APPROVE_SCORE_SUCCEED,
    Score,
    string,
    posthog.CaptureResult | null
>;
export const approveScoreSuccess = (score: Score): ApproveScoreSuccessAction => ({
    type: APPROVE_SCORE_SUCCEED,
    payload: score,
    meta: 'Feedback approved',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Feedback approved') : null,
});

export const APPROVE_SCORE_FAILED = 'APPROVE_SCORE_FAILED';
export type ApproveScoreFailureAction = Action<
    typeof APPROVE_SCORE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const approveScoreFailure = (payload: string): ApproveScoreFailureAction => ({
    type: APPROVE_SCORE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Feedback approval failed')
            : null,
});

export const DECLINE_SCORE_REQUESTED = 'DECLINE_SCORE_REQUESTED';
export type DeclineScoreRequestAction = Action<typeof DECLINE_SCORE_REQUESTED, ScoreDecline>;
export const declineScoreRequest = (score: ScoreDecline): DeclineScoreRequestAction => ({
    type: DECLINE_SCORE_REQUESTED,
    payload: score,
});

export const DECLINE_SCORE_SUCCEED = 'DECLINE_SCORE_SUCCEED';
export type DeclineScoreSuccessAction = Action<typeof DECLINE_SCORE_SUCCEED, Score, string>;
export const declineScoreSuccess = (score: Score): DeclineScoreSuccessAction => ({
    type: DECLINE_SCORE_SUCCEED,
    payload: score,
    meta: 'Feedback declined',
});

export const DECLINE_SCORE_FAILED = 'DECLINE_SCORE_FAILED';
export type DeclineScoreFailureAction = Action<typeof DECLINE_SCORE_FAILED, string>;
export const declineScoreFailure = (payload: string): DeclineScoreFailureAction => ({
    type: DECLINE_SCORE_FAILED,
    payload,
});

export const GET_SCORES_REQUESTED = 'GET_SCORES_REQUESTED';
export type GetScoresRequestAction = Action<typeof GET_SCORES_REQUESTED, BenefitIds>;
export const getScoresRequest = (payload: BenefitIds): GetScoresRequestAction => ({
    type: GET_SCORES_REQUESTED,
    payload,
});

export const GET_SCORES_SUCCEED = 'GET_SCORES_SUCCEED';
export type GetScoresSuccessAction = Action<typeof GET_SCORES_SUCCEED, { scores: Score[] }>;
export const getScoresSuccess = (scores: Score[]): GetScoresSuccessAction => ({
    type: GET_SCORES_SUCCEED,
    payload: { scores },
});

export const GET_SCORES_FAILED = 'GET_SCORES_FAILED';
export type GetScoresFailureAction = Action<typeof GET_SCORES_FAILED, string>;
export const getScoresFailure = (payload: string): GetScoresFailureAction => ({
    type: GET_SCORES_FAILED,
    payload,
});

export const GET_MY_SCORE_REQUESTED = 'GET_MY_SCORE_REQUESTED';
export type GetMyScoreRequestAction = Action<typeof GET_MY_SCORE_REQUESTED, ScoreData>;
export const getMyScoreRequest = (contractData: ScoreData): GetMyScoreRequestAction => ({
    type: GET_MY_SCORE_REQUESTED,
    payload: contractData,
});

export const GET_MY_SCORE_SUCCEED = 'GET_MY_SCORE_SUCCEED';
export type GetMyScoreSuccessAction = Action<typeof GET_MY_SCORE_SUCCEED, { myScore: Score }>;
export const getMyScoreSuccess = (myScore: Score): GetMyScoreSuccessAction => ({
    type: GET_MY_SCORE_SUCCEED,
    payload: { myScore },
});

export const GET_MY_SCORE_FAILED = 'GET_MY_SCORE_FAILED';
export type GetMyScoreFailureAction = Action<typeof GET_MY_SCORE_FAILED, string>;
export const getMyScoreFailure = (payload: string): GetMyScoreFailureAction => ({
    type: GET_MY_SCORE_FAILED,
    payload,
});

export const SUBMIT_SCORE_FOR_APPROVAL_REQUESTED = 'SUBMIT_SCORE_FOR_APPROVAL_REQUESTED';
export type SubmitScoreForApprovalRequestAction = Action<
    typeof SUBMIT_SCORE_FOR_APPROVAL_REQUESTED,
    ScoreRequest
>;
export const submitScoreForApprovalRequest = (
    score: ScoreRequest,
): SubmitScoreForApprovalRequestAction => ({
    type: SUBMIT_SCORE_FOR_APPROVAL_REQUESTED,
    payload: score,
});

export const SUBMIT_SCORE_FOR_APPROVAL_SUCCEED = 'SUBMIT_SCORE_FOR_APPROVAL_SUCCEED';
export type SubmitScoreForApprovalSuccessAction = Action<
    typeof SUBMIT_SCORE_FOR_APPROVAL_SUCCEED,
    null,
    string
>;
export const submitScoreForApprovalSuccess = (): SubmitScoreForApprovalSuccessAction => ({
    type: SUBMIT_SCORE_FOR_APPROVAL_SUCCEED,
    meta: 'Feedback submitted for approval',
});

export const SUBMIT_SCORE_FOR_APPROVAL_FAILED = 'SUBMIT_SCORE_FOR_APPROVAL_FAILED';
export type SubmitScoreForApprovalFailureAction = Action<
    typeof SUBMIT_SCORE_FOR_APPROVAL_FAILED,
    string
>;
export const submitScoreForApprovalFailure = (
    payload: string,
): SubmitScoreForApprovalFailureAction => ({
    type: SUBMIT_SCORE_FOR_APPROVAL_FAILED,
    payload,
});

export const CREATE_SCORE_REQUESTED = 'CREATE_SCORE_REQUESTED';
export type CreateScoreRequestAction = Action<typeof CREATE_SCORE_REQUESTED, ScoreRequest>;
export const createScoreRequest = (score: ScoreRequest): CreateScoreRequestAction => ({
    type: CREATE_SCORE_REQUESTED,
    payload: score,
});

export const CREATE_SCORE_SUCCEED = 'CREATE_SCORE_SUCCEED';
export type CreateScoreSuccessAction = Action<
    typeof CREATE_SCORE_SUCCEED,
    CreateScoreResponse,
    string,
    posthog.CaptureResult | null
>;
export const createScoreSuccess = (scoreData: CreateScoreResponse): CreateScoreSuccessAction => ({
    type: CREATE_SCORE_SUCCEED,
    payload: scoreData,
    meta: 'Score created',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Feedback created') : null,
});

export const CREATE_SCORE_FAILED = 'CREATE_SCORE_FAILED';
export type CreateScoreFailureAction = Action<
    typeof CREATE_SCORE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const createScoreFailure = (payload: string): CreateScoreFailureAction => ({
    type: CREATE_SCORE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Feedback creation failed')
            : null,
});

export const UPDATE_SCORE_REQUESTED = 'UPDATE_SCORE_REQUESTED';
export type UpdateScoreRequestAction = Action<typeof UPDATE_SCORE_REQUESTED, ScoreRequest>;
export const updateScoreRequest = (score: ScoreRequest): UpdateScoreRequestAction => ({
    type: UPDATE_SCORE_REQUESTED,
    payload: score,
});

export const UPDATE_SCORE_SUCCEED = 'UPDATE_SCORE_SUCCEED';
export type UpdateScoreSuccessAction = Action<
    typeof UPDATE_SCORE_SUCCEED,
    CreateScoreResponse,
    string,
    posthog.CaptureResult | null
>;
export const updateScoreSuccess = (scoreData: CreateScoreResponse): UpdateScoreSuccessAction => ({
    type: UPDATE_SCORE_SUCCEED,
    payload: scoreData,
    meta: 'Feedback updated',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production' ? posthog.capture('Feedback updated') : null,
});

export const UPDATE_SCORE_FAILED = 'UPDATE_SCORE_FAILED';
export type UpdateScoreFailureAction = Action<
    typeof UPDATE_SCORE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const updateScoreFailure = (payload: string): UpdateScoreFailureAction => ({
    type: UPDATE_SCORE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Feedback update failed')
            : null,
});

export const UPLOAD_SCORE_FILE_SUCCEED = 'UPLOAD_SCORE_FILE_SUCCEED';
export type UploadScoreFileSuccessAction = Action<
    typeof UPLOAD_SCORE_FILE_SUCCEED,
    FileData,
    string,
    posthog.CaptureResult | null
>;
export const uploadScoreFileSuccess = (file: FileData): UploadScoreFileSuccessAction => ({
    type: UPLOAD_SCORE_FILE_SUCCEED,
    payload: file,
    meta: 'File uploaded',
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Feedback file uploaded')
            : null,
});

export const UPLOAD_SCORE_FILE_FAILED = 'UPLOAD_SCORE_FILE_FAILED';
export type UploadScoreFileFailureAction = Action<
    typeof UPLOAD_SCORE_FILE_FAILED,
    string,
    null,
    posthog.CaptureResult | null
>;
export const uploadScoreFileFailure = (payload: string): UploadScoreFileFailureAction => ({
    type: UPLOAD_SCORE_FILE_FAILED,
    payload,
    capture:
        SENTRY_ENVIRONMENT === 'frontend-production'
            ? posthog.capture('Feedback file upload failed')
            : null,
});

export const DELETE_SCORE_FILE_REQUESTED = 'DELETE_SCORE_FILE_REQUESTED';
export type DeleteScoreFileRequestAction = Action<
    typeof DELETE_SCORE_FILE_REQUESTED,
    DeleteScoreFileRequest
>;
export const deleteScoreFileRequest = (
    request: DeleteScoreFileRequest,
): DeleteScoreFileRequestAction => ({
    type: DELETE_SCORE_FILE_REQUESTED,
    payload: request,
});

export const DELETE_SCORE_FILE_SUCCEED = 'DELETE_SCORE_FILE_SUCCEED';
export type DeleteScoreFileSuccessAction = Action<typeof DELETE_SCORE_FILE_SUCCEED, number, string>;
export const deleteScoreFileSuccess = (fileId: number): DeleteScoreFileSuccessAction => ({
    type: DELETE_SCORE_FILE_SUCCEED,
    payload: fileId,
    meta: 'Feedback file deleted',
});

export const DELETE_SCORE_FILE_FAILED = 'DELETE_SCORE_FILE_FAILED';
export type DeleteScoreFileFailureAction = Action<typeof DELETE_SCORE_FILE_FAILED, string>;
export const deleteScoreFileFailure = (payload: string): DeleteScoreFileFailureAction => ({
    type: DELETE_SCORE_FILE_FAILED,
    payload,
});

export const GET_SCORABLE_BENEFITS_REQUESTED = 'GET_SCORABLE_BENEFITS_REQUESTED';
export type GetScorableBenefitsRequestAction = Action<typeof GET_SCORABLE_BENEFITS_REQUESTED>;
export const getScorableBenefitsRequest = (): GetScorableBenefitsRequestAction => ({
    type: GET_SCORABLE_BENEFITS_REQUESTED,
});

export const GET_SCORABLE_BENEFITS_SUCCEED = 'GET_SCORABLE_BENEFITS_SUCCEED';
export type GetScorableBenefitsSuccessAction = Action<
    typeof GET_SCORABLE_BENEFITS_SUCCEED,
    { scorableBenefits: UploadBenefit[] }
>;
export const getScorableBenefitsSuccess = (
    scorableBenefits: UploadBenefit[],
): GetScorableBenefitsSuccessAction => ({
    type: GET_SCORABLE_BENEFITS_SUCCEED,
    payload: { scorableBenefits },
});

export const GET_SCORABLE_BENEFITS_FAILED = 'GET_SCORABLE_BENEFITS_FAILED';
export type GetScorableBenefitsFailureAction = Action<typeof GET_SCORABLE_BENEFITS_FAILED, string>;
export const getScorableBenefitsFailure = (payload: string): GetScorableBenefitsFailureAction => ({
    type: GET_SCORABLE_BENEFITS_FAILED,
    payload,
});

export type ScoreAction =
    | ApproveScoreRequestAction
    | ApproveScoreSuccessAction
    | ApproveScoreFailureAction
    | DeclineScoreRequestAction
    | DeclineScoreSuccessAction
    | DeclineScoreFailureAction
    | GetScoresRequestAction
    | GetScoresSuccessAction
    | GetScoresFailureAction
    | GetMyScoreRequestAction
    | GetMyScoreSuccessAction
    | GetMyScoreFailureAction
    | SubmitScoreForApprovalRequestAction
    | SubmitScoreForApprovalSuccessAction
    | SubmitScoreForApprovalFailureAction
    | CreateScoreRequestAction
    | CreateScoreSuccessAction
    | CreateScoreFailureAction
    | UpdateScoreRequestAction
    | UpdateScoreSuccessAction
    | UpdateScoreFailureAction
    | UploadScoreFileRequestAction
    | UploadScoreFileSuccessAction
    | UploadScoreFileFailureAction
    | UploadScoreFilesSuccessAction
    | DeleteScoreFileRequestAction
    | DeleteScoreFileSuccessAction
    | DeleteScoreFileFailureAction
    | GetScorableBenefitsRequestAction
    | GetScorableBenefitsSuccessAction
    | GetScorableBenefitsFailureAction;
