import { UploadFile } from 'antd/lib/upload/interface';

export const generateUploadedFileWithName = (name?: string) =>
    name
        ? {
              uid: '',
              name,
          }
        : undefined;

export const generateFilesListFromSingleFile = (file?: UploadFile) => (file ? [file] : undefined);

export const getRequestUploadFile = (file?: UploadFile) => {
    if (!file) {
        return null;
    }

    return file.size ? file : undefined;
};
