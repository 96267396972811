import * as React from 'react';

import { SubmitButton, NewPasswordInput } from 'common/components';
import { getPercentagePasswordStrength, PasswordStrength } from 'common/helpers/passwordStrength';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input } from 'common/components/deprecated/form';

import { ResetPasswordContainerProps } from '../containers/ResetPasswordContainer';

type ResetPasswordChangeProps = ResetPasswordContainerProps & Antd3FormProps;

export type ResetPasswordChangeState = PasswordStrength;

class ResetPasswordChangeForm extends React.Component<
    ResetPasswordChangeProps,
    ResetPasswordChangeState
> {
    public state: ResetPasswordChangeState = {
        passwordStrength: 0,
        status: 'exception',
    };

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        this.props.form.validateFields(
            (error, { password }) =>
                !error &&
                this.props.resetPasswordChangeRequest({ password, token: this.props.token }),
        );
    };

    private handleOnChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { passwordStrength, status } = getPercentagePasswordStrength(event.target.value);

        this.setState({ passwordStrength, status });
    };

    private checkPasswordConfirmation = (
        _rule: any,
        value: string,
        callback: (msg?: string) => void,
    ) =>
        this.props.form.getFieldValue('password') === value
            ? callback()
            : callback('The password confirmation does not match');

    public render(): JSX.Element {
        const { form, isProcessing } = this.props;

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Choose New Password">
                <NewPasswordInput
                    form={form}
                    id="password"
                    placeholder="Password"
                    onChange={this.handleOnChangePassword}
                    percent={this.state.passwordStrength}
                    status={this.state.status}
                    required
                    disabled={isProcessing}
                />
                <Input
                    form={form}
                    id="passwordConfirmation"
                    placeholder="Repeat Password"
                    requiredMessage="Please repeat your password"
                    required
                    isPassword
                    disabled={isProcessing}
                    validator={this.checkPasswordConfirmation}
                />
                <SubmitButton loading={isProcessing} value="Save Password" />
            </StyledAntd3Form>
        );
    }
}

export const ResetPasswordChange = Antd3Form.create({})(ResetPasswordChangeForm);
