import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { Contract } from 'features/contract/models/contractModels';
import {
    getMyContracts,
    getFilteringContractsStatus,
} from 'features/contract/selectors/contractSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import { resetFilteredContractId } from 'features/benefit/actions';
import { isAdmin } from 'features/user/selectors/userSelectors';
import {
    saveFilters,
    autocompleteRequest,
    clearFilters,
    paginationRequest,
} from 'features/pagination/actions/paginationActions';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { getFilterParams } from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { RouteParams, getPageNumber } from 'common/selectors/routeSelectors';
import { toggleMultipleEmployeesContractRoleRequest } from 'features/contract/actions/contractActions';

import { EditMyContracts as EditMyContractsComponent } from '../components/EditMyContracts';

export interface EditMyContractsStateProps {
    myContracts: Contract[];
    isFiltered: boolean;
    isUserAdmin: boolean;
    filter?: PaginationFilters;
    pageNumber?: number;
}

interface EditMyContractsDispatchProps {
    redirectToUrl: typeof redirectToUrl;
    resetFilteredContractId: typeof resetFilteredContractId;
    autocompleteRequest: typeof autocompleteRequest;
    clearFilters: typeof clearFilters;
    saveFilters: typeof saveFilters;
    paginationRequest: typeof paginationRequest;
    toggleMultipleEmployeesContractRoleRequest: typeof toggleMultipleEmployeesContractRoleRequest;
}

export type EditMyContractsProps = EditMyContractsDispatchProps &
    EditMyContractsStateProps &
    RouteParams;

export class EditMyContracts extends React.Component<EditMyContractsProps> {
    public componentDidMount(): void {
        this.props.resetFilteredContractId();
    }

    public render(): JSX.Element {
        return <EditMyContractsComponent {...this.props} />;
    }
}

const mapStateToProps = (state: AppState, ownProps: RouteParams): EditMyContractsStateProps => ({
    myContracts: getMyContracts(state),
    isFiltered: getFilteringContractsStatus(state),
    isUserAdmin: isAdmin(state),
    filter: getFilterParams(state, PaginationView.MyContracts),
    pageNumber: getPageNumber(ownProps),
});

export const EditMyContractsContainer = connect(mapStateToProps, {
    redirectToUrl,
    resetFilteredContractId,
    autocompleteRequest,
    clearFilters,
    saveFilters,
    paginationRequest,
    toggleMultipleEmployeesContractRoleRequest,
})(EditMyContracts);
