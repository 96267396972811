import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
    getProfile,
    isAdmin,
    getOrganisationCurrentSubscription,
} from '../selectors/userSelectors';
import { SubscriptionDetails } from '../components/SubscriptionDetails';
import { UserState } from '../reducers/userReducer';
import { InvoiceData, Subscription } from '../models/userModel';

interface StateProps {
    profile: UserState;
    isUserAdmin: boolean;
    userSubscription: Subscription;
    userInvoices?: InvoiceData[];
}

export type Props = StateProps;

const SubscriptionElement = (props: Props): JSX.Element => <SubscriptionDetails {...props} />;

const mapStateToProps = createSelector(
    getProfile,
    isAdmin,
    getOrganisationCurrentSubscription,
    (profile, isUserAdmin, userSubscription): StateProps => ({
        profile,
        isUserAdmin,
        userSubscription,
    }),
);

export const SubscriptionContainer = connect(mapStateToProps)(SubscriptionElement);
