import * as React from 'react';

import { SubmitButton, NewPasswordInput } from 'common/components';
import { getPercentagePasswordStrength, PasswordStrength } from 'common/helpers/passwordStrength';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input } from 'common/components/deprecated/form';

import { Props } from '../containers/ChangePasswordContainer';

type ChangeUserPasswordProps = Props & Antd3FormProps;

export type ChangeUserPasswordState = PasswordStrength;

class ChangeUserPasswordForm extends React.Component<
    ChangeUserPasswordProps,
    ChangeUserPasswordState
> {
    public state: ChangeUserPasswordState = {
        passwordStrength: 0,
        status: 'exception',
    };

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, values) => {
            if (error) {
                return;
            }
            this.props.changeUserPasswordRequest(values);
        });
    };

    private handleOnChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { passwordStrength, status } = getPercentagePasswordStrength(event.target.value);

        return this.setState({ passwordStrength, status });
    };

    private checkPasswordConfirmation = (
        _rule: any,
        value: string,
        callback: (msg?: string) => void,
    ) =>
        this.props.form.getFieldValue('newPassword') === value
            ? callback()
            : callback('The password confirmation does not match');

    public render(): JSX.Element {
        const { form } = this.props;

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Change Password">
                <Input form={form} id="password" placeholder="Old Password" required isPassword />
                <NewPasswordInput
                    form={form}
                    id="newPassword"
                    placeholder="New Password"
                    onChange={this.handleOnChangePassword}
                    percent={this.state.passwordStrength}
                    status={this.state.status}
                    required
                />
                <Input
                    form={form}
                    id="passwordConfirmation"
                    placeholder="Repeat New Password"
                    requiredMessage="Please repeat your New Password"
                    required
                    isPassword
                    validator={this.checkPasswordConfirmation}
                />
                <SubmitButton value="Change Password" />
            </StyledAntd3Form>
        );
    }
}

export const ChangeUserPassword = Antd3Form.create({})(ChangeUserPasswordForm);
