import * as React from 'react';
import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';

import { FormFieldIds } from 'common/helpers/utils';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { PaginationRequestAction } from 'features/pagination/actions/paginationActions';
import { Antd3FormProps, WrappedFormUtils } from 'common/components/deprecated/antd3';
import { CreatePaths } from 'features/create/models/createModel';
import { TableFiltersBar } from 'common/components/TableFiltersBar';

import { EditPaths } from '../models/editModel';

type FormProps = {
    searchByContractTitle(contractTitle: string): PaginationRequestAction;
    onRemoveContractTitleAutocomplete(): PaginationRequestAction;
    clearFilters(): void;
    form: WrappedFormUtils;
    filter?: PaginationFilters;
};

type Props = FormProps & Antd3FormProps;

export const EditContractsTableFiltersBar: React.FunctionComponent<Props> = ({
    searchByContractTitle,
    onRemoveContractTitleAutocomplete,
    clearFilters,
    form,
    filter,
}) => {
    const { pathname } = useLocation();
    const isMyView = pathname.includes(EditPaths.MyContracts);

    return (
        <TableFiltersBar
            leftButtons={[
                <Link to={isMyView ? EditPaths.Contracts : EditPaths.MyContracts}>
                    <Button type="primary">{isMyView ? 'All Projects' : 'My Projects'}</Button>
                </Link>,
                <Link to={CreatePaths.Contract}>
                    <Button type="primary" icon={<PlusOutlined />}>
                        New Project
                    </Button>
                </Link>,
            ]}
            filters={[
                <AutocompleteSelect
                    id={FormFieldIds.Title}
                    placeholder="Search by Projects"
                    form={form}
                    autocompleteField={
                        isMyView ? AutocompleteField.MyContract : AutocompleteField.Contract
                    }
                    onSelect={searchByContractTitle}
                    onRemove={onRemoveContractTitleAutocomplete}
                    notFoundContent="No projects found"
                    requiredMessage="Project is required"
                    showLabel={false}
                    defaultValue={filter && filter.values.title}
                />,
            ]}
            filtersButtons={[
                <Link to={EditPaths.ContractFilter}>
                    <Button type="primary" icon={<FilterOutlined />}>
                        All Filters
                    </Button>
                </Link>,
                <Button onClick={clearFilters}>Clear Filters</Button>,
            ]}
        />
    );
};
