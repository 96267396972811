import { switchMap, pluck, mergeMap, map } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { AppEpic } from 'common/epics/appEpic';
import { AppAction } from 'common/actions/appAction';
import { InvitePendingIndividualExecutivesRequest } from 'features/benefit/models/benefitModels';
import { CREATE_BENEFIT_SUCCEED, SELECT_BENEFIT_TEMPLATE_SUCCEED } from 'features/benefit/actions';

import * as actions from '../actions/inviteActions';
import {
    InviteByEmail,
    InviteByEmailWithRole,
    EditBenefitInviteByEmailWithRole,
} from '../models/inviteModel';
import { InviteService } from '../services/inviteService';

export const inviteEpicFactory = (inviteService: InviteService): AppEpic => {
    const inviteEmployeeEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.INVITE_EMPLOYEE_REQUESTED),
            pluck('payload'),
            switchMap((employee: InviteByEmail) =>
                inviteService
                    .inviteEmployee(employee)
                    .then(actions.inviteEmployeeSuccess)
                    .catch(actions.inviteEmployeeFailure),
            ),
        );

    const inviteEmployeeWithRoleEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.INVITE_EMPLOYEE_WITH_ROLE_REQUESTED),
            pluck('payload'),
            switchMap((employee: InviteByEmailWithRole) =>
                inviteService
                    .inviteEmployeeToExecutive(employee)
                    .then(actions.inviteEmployeeWithRoleSuccess)
                    .catch((error) => {
                        const message = Object.keys(error.message).map((key) =>
                            error.message[key].pop(),
                        );

                        error.message = `${message.join(
                            '\n',
                        )}\nPlease remove the user from "Pending Roles" and select them again using the “Add Invited…” dropdown`;

                        return actions.inviteEmployeeWithRoleFailure(error);
                    }),
            ),
        );

    const inviteMultipleEmployeesWithRolesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(CREATE_BENEFIT_SUCCEED, SELECT_BENEFIT_TEMPLATE_SUCCEED),
            pluck('payload'),
            mergeMap(
                ({
                    newUsers,
                    assignInvitePendingUsers,
                }: {
                    newUsers: InviteByEmailWithRole[];
                    assignInvitePendingUsers: InvitePendingIndividualExecutivesRequest[];
                }) =>
                    ([] as AppAction[]).concat(
                        newUsers.map((user) => actions.inviteEmployeeWithRoleRequest(user)),
                        assignInvitePendingUsers.map((user) =>
                            actions.assignInvitedIndividualExecutiveRequest(user),
                        ),
                    ),
            ),
        );

    const inviteNewPotentialExecutiveEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.INVITE_NEW_POTENTIAL_EXECUTIVE_REQUESTED),
            pluck('payload'),
            switchMap((data: InviteByEmail) =>
                inviteService
                    .inviteNewPotentialExecutive(data)
                    .then(actions.inviteNewPotentialExecutiveSuccess)
                    .catch(actions.inviteNewPotentialExecutiveFailure),
            ),
        );

    const invitePotentialExecutivesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.INVITE_POTENTIAL_EXECUTIVES_REQUESTED),
            pluck('payload'),
            switchMap((data: number[]) =>
                inviteService
                    .invitePotentialExecutives(data)
                    .then(actions.invitePotentialExecutivesSuccess)
                    .catch((error) => {
                        const message = Object.keys(error.message).map((key) =>
                            error.message[key].pop(),
                        );

                        error.message = message.join('\n');

                        return actions.invitePotentialExecutivesFailure(error);
                    }),
            ),
        );

    const assignInvitedIndividualExecutiveEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED),
            pluck('payload'),
            switchMap((assignRoleInvitation: InvitePendingIndividualExecutivesRequest) =>
                inviteService
                    .assignInvitePendingEmployee(assignRoleInvitation)
                    .then(() =>
                        actions.assignInvitedIndividualExecutiveSuccess(assignRoleInvitation),
                    )
                    .catch(actions.assignInvitedIndividualExecutiveFailure),
            ),
        );

    const assignInvitedIndividualExecutiveSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ASSIGN_INVITED_INDIVIDUAL_EXECUTIVE_SUCCEED),
            pluck('payload'),
            map(({ contract }) => actions.getRolesPendingInvitationsRequest(contract)),
        );

    const getInvitedIndividualExecutiveSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_INVITED_INDIVIDUAL_EXECUTIVE_REQUESTED),
            pluck('payload'),
            switchMap((organisation: number) =>
                inviteService
                    .getInvitedExecutives(organisation)
                    .then(actions.getInvitedIndividualExecutiveSuccess)
                    .catch(actions.getInvitedIndividualExecutiveFailure),
            ),
        );

    const editBenefitInviteEmployeeWithRoleEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_REQUESTED),
            pluck('payload'),
            switchMap((employee: EditBenefitInviteByEmailWithRole) =>
                inviteService
                    .inviteEmployeeToExecutive(employee)
                    .then(() => actions.editBenefitInviteEmployeeWithRoleSuccess(employee.contract))
                    .catch((error) => {
                        const message = Object.keys(error.message).map((key) =>
                            error.message[key].pop(),
                        );

                        error.message = message.join('\n');

                        return actions.editInviteEmployeeWithRoleFailure(error);
                    }),
            ),
        );

    const editBenefitInviteEmployeeWithRoleSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_BENEFIT_INVITE_EMPLOYEE_WITH_ROLE_SUCCEED),
            pluck('payload'),
            map((contract) => actions.getRolesPendingInvitationsRequest(contract)),
        );

    return combineEpics(
        inviteEmployeeEpic,
        inviteNewPotentialExecutiveEpic,
        invitePotentialExecutivesEpic,
        inviteEmployeeWithRoleEpic,
        inviteMultipleEmployeesWithRolesEpic,
        assignInvitedIndividualExecutiveEpic,
        assignInvitedIndividualExecutiveSuccessEpic,
        getInvitedIndividualExecutiveSuccessEpic,
        editBenefitInviteEmployeeWithRoleEpic,
        editBenefitInviteEmployeeWithRoleSuccessEpic,
    );
};
