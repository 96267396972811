import * as React from 'react';

import { PaddedSection, UploadAndCheckTableFiltersBar } from 'common/components';
import { generateColumns } from 'common/helpers/utils';
import { FilterData } from 'common/helpers/url';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { AutocompleteField, PaginationView } from 'features/pagination/models/paginationModel';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { ApprovedProps as ApprovedContainerProps } from '../containers/ApprovedContainer';
import { getCheckScoresBenefitsSuccess } from '../actions/checkActions';
import { checkScoresColumns } from './CheckScores';

type ApprovedScoresProps = Antd3FormProps & ApprovedContainerProps;

class ApprovedScoresForm extends React.Component<ApprovedScoresProps> {
    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.ApprovedCheckScores,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getCheckScoresBenefitsSuccess,
        });

    private saveBenefitFilters = (benefitReferenceNumber: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.ApprovedCheckScores,
            values: {
                referenceNumber: benefitReferenceNumber,
            },
        });

    private saveContractFilters = (contractId: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.ApprovedCheckScores,
            values: {
                contract: contractId,
            },
        });

    private saveExecutiveOrganisationFilter = (executive: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.ApprovedCheckScores,
            values: { executive },
        });

    private searchByBenefits = (benefitReferenceNumber: string) =>
        this.saveBenefitFilters(benefitReferenceNumber) && this.refreshTable();

    private searchByContracts = (contractId: number) =>
        this.saveContractFilters(contractId) && this.refreshTable();

    private searchByExecutiveOrganisation = (executive: string) => {
        this.saveExecutiveOrganisationFilter(executive);
        this.refreshTable();
    };

    private onRemoveBenefitAutocomplete = () =>
        this.saveBenefitFilters(undefined) && this.refreshTable();

    private onRemoveContractAutocomplete = () =>
        this.saveContractFilters(undefined) && this.refreshTable();

    private onRemoveExecutiveOrganisationAutocomplete = () => {
        this.saveExecutiveOrganisationFilter(undefined);
        this.refreshTable();
    };

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.ApprovedCheckScores) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            quickFilterBenefitReferenceNumber: '',
            quickFilterContractReferenceNumber: '',
        });

    public render(): JSX.Element {
        const { form, filter, approvedScores, isTenant } = this.props;

        return (
            <PaddedSection header="Check Approved Feedback">
                <UploadAndCheckTableFiltersBar
                    form={form}
                    searchByBenefits={this.searchByBenefits}
                    onRemoveBenefitAutocomplete={this.onRemoveBenefitAutocomplete}
                    searchByContracts={this.searchByContracts}
                    onRemoveContractAutocomplete={this.onRemoveContractAutocomplete}
                    searchByExecutiveOrganisation={this.searchByExecutiveOrganisation}
                    onRemoveExecutiveOrganisationAutocomplete={
                        this.onRemoveExecutiveOrganisationAutocomplete
                    }
                    filter={filter}
                    clearFilters={this.clearFilters}
                    benefitFilterAutocompleteField={AutocompleteField.ApprovedCheckScores}
                    isTenant={isTenant}
                />
                <PaginatedTable
                    data={approvedScores}
                    view={PaginationView.ApprovedCheckScores}
                    paginationSuccessCallback={getCheckScoresBenefitsSuccess}
                    columns={generateColumns(checkScoresColumns)}
                    emptyText="No feedback has been approved"
                />
            </PaddedSection>
        );
    }
}

export const ApprovedScores = Antd3Form.create({})(ApprovedScoresForm);
