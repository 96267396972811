import * as React from 'react';
import styled from 'styled-components';
import { TablePaginationConfig } from 'antd';

import { EditPaths } from 'features/edit/models/editModel';
import { history } from 'config/config';
import { openModal } from 'common/helpers/modal';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { PaddedSection } from 'common/components';

import { PortfolioFiltersContainerProps } from '../containers/PortfolioFilterTableContainer';
import { PortfolioFiltersEnum, PortfolioFilterRowData } from '../models/portfolioFilterModel';
import { PortfolioFiltersTable } from './PortfolioFiltersTable';
import { PortfolioFilterFormModal } from './PortfolioFilterFormModal';
import { getPorftolioFiltersIdsChange } from '../helpers/getPortfolioFiltersStateChange';

const PortfolioFilterTablesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

interface PortfolioFilterState {
    subProgramId: number;
    mainProjectId: number;
    subProjectId: number;
    isModalVisible: boolean;
    modalMode: ModalMode;
    portfolioFilterData: PortfolioFilterRowData;
    depth: PortfolioFiltersEnum;
}

export enum ModalMode {
    Edit = 'edit',
    Create = 'create',
    Archive = 'archive',
}

type PortfolioFiltersProps = Antd3FormProps & PortfolioFiltersContainerProps;

class PortfolioFiltersComponent extends React.Component<
    PortfolioFiltersProps,
    PortfolioFilterState
> {
    public state: PortfolioFilterState = {
        subProgramId: -1,
        mainProjectId: -1,
        subProjectId: -1,
        isModalVisible: false,
        modalMode: ModalMode.Create,
        portfolioFilterData: {
            id: -1,
            name: '',
            archived: false,
        },
        depth: PortfolioFiltersEnum.SubProgram,
    };

    public fetchSubProgramOnSelect = (id: number) => {
        this.setState({ subProgramId: id });
        const { mainProjectId, subProjectId } = this.state;

        this.props.getPortfolioFiltersRequest({
            depth: PortfolioFiltersEnum.MainProject,
            portfolioFilterIds: {
                subProgramId: id,
                mainProjectId,
                subProjectId,
            },
            portfolioFilterRootId: this.props.portfolioFilterRootId,
        });
    };

    public fetchMainProjectsOnSelect = (id: number) => {
        this.setState({ mainProjectId: id });
        const { subProgramId, subProjectId } = this.state;

        this.props.getPortfolioFiltersRequest({
            depth: PortfolioFiltersEnum.SubProject,
            portfolioFilterIds: { subProgramId, mainProjectId: id, subProjectId },
            portfolioFilterRootId: this.props.portfolioFilterRootId,
        });
    };

    public openEditModal = (
        portfolioFilterData: PortfolioFilterRowData,
        depth: PortfolioFiltersEnum,
    ) => {
        this.props.form.resetFields();
        this.setState(
            {
                modalMode: ModalMode.Edit,
                depth,
                portfolioFilterData,
            },
            () => this.toggleModal(),
        );
    };

    public openCreateModal = (depth: PortfolioFiltersEnum) => {
        this.props.form.resetFields();
        this.setState(
            {
                modalMode: ModalMode.Create,
                depth,
                portfolioFilterData: {
                    id: -1,
                    name: '',
                    archived: false,
                },
            },
            () => this.toggleModal(),
        );
    };

    public archivePortfolioFilter = (
        portfolioFilterData: PortfolioFilterRowData,
        depth: PortfolioFiltersEnum,
    ) => {
        const { name, archived } = portfolioFilterData;
        openModal({
            title: `${name}: Are you sure you want to ${
                archived ? 'restore' : 'archive'
            } this Portfolio Filter?`,
            callback: () =>
                this.setState(
                    {
                        modalMode: ModalMode.Archive,
                        depth,
                        portfolioFilterData: {
                            ...portfolioFilterData,
                            archived: !portfolioFilterData.archived,
                        },
                    },
                    () => this.sendRequest(),
                ),
        });
    };

    public deletePortfolioFilter = (
        portfolioFilterData: PortfolioFilterRowData,
        depth: PortfolioFiltersEnum,
    ) => {
        const { subProgramId, mainProjectId, subProjectId } = this.state;
        const { id, name } = portfolioFilterData;
        const portfolioFilterIds = getPorftolioFiltersIdsChange(depth, id, {
            subProgramId,
            mainProjectId,
            subProjectId,
        });
        const data = {
            portfolioFilter: portfolioFilterData,
            params: {
                depth,
                portfolioFilterIds,
                portfolioFilterRootId: this.props.portfolioFilterRootId,
            },
        };
        openModal({
            title: `${name}: Are you sure you want to permanently delete this Portfolio Filter?`,
            callback: () => this.props.deletePortfolioFilterRequest(data),
        });
    };

    public toggleModal = () =>
        this.setState(({ isModalVisible }) => ({
            isModalVisible: !isModalVisible,
        }));

    public sendRequest = () => {
        const {
            subProgramId,
            mainProjectId,
            subProjectId,
            depth,
            portfolioFilterData: { id },
        } = this.state;

        const portfolioFilterIds = getPorftolioFiltersIdsChange(depth, id, {
            subProgramId,
            mainProjectId,
            subProjectId,
        });

        this.setState({ ...portfolioFilterIds });

        const data = {
            portfolioFilter: this.state.portfolioFilterData,
            params: {
                depth,
                portfolioFilterIds,
                portfolioFilterRootId: this.props.portfolioFilterRootId,
            },
        };

        if (this.state.modalMode === ModalMode.Create) {
            return this.props.createPortfolioFilterRequest(data);
        }
        return this.props.editPortfolioFilterRequest(data);
    };

    public handleSubmit = () => {
        this.props.form.validateFields((error, { ...values }) => {
            if (error) {
                return;
            }
            this.toggleModal();
            this.setState(
                ({ portfolioFilterData }) => ({
                    portfolioFilterData: {
                        ...portfolioFilterData,
                        name: values.name,
                    },
                }),
                () => this.sendRequest(),
            );
        });
    };

    public handlePageChange = ({ current }: TablePaginationConfig) => {
        history.push({
            pathname: `${EditPaths.FiltersPortfolio}/${current}`,
        });
    };

    public render(): JSX.Element {
        const isSubProgramSelected = this.state.subProgramId > 0;
        const isMainProjectSelected = this.state.mainProjectId > 0;
        const modalTitle = `${
            this.state.modalMode === ModalMode.Create ? 'Create' : 'Edit'
        } portfolio filter`;

        const commonTablesProps = {
            openEditModal: this.openEditModal,
            openCreateModal: this.openCreateModal,
            archivePortfolioFilter: this.archivePortfolioFilter,
            deletePortfolioFilter: this.deletePortfolioFilter,
        };

        return (
            <PaddedSection header="Portfolio Filters" bigHeader>
                <PortfolioFilterTablesWrapper>
                    <PortfolioFiltersTable
                        title="Programme"
                        portfolioFilters={this.props.subPrograms}
                        onSelect={this.fetchSubProgramOnSelect}
                        depth={PortfolioFiltersEnum.SubProgram}
                        selectedId={this.state.subProgramId}
                        pageNumber={this.props.pageNumber}
                        handlePageChange={this.handlePageChange}
                        withPaginationHistory
                        canCreate
                        {...commonTablesProps}
                    />
                    <PortfolioFiltersTable
                        title="Workstream"
                        portfolioFilters={this.props.mainProjects}
                        onSelect={this.fetchMainProjectsOnSelect}
                        depth={PortfolioFiltersEnum.MainProject}
                        selectedId={this.state.mainProjectId}
                        canCreate={isSubProgramSelected}
                        {...commonTablesProps}
                    />
                    <PortfolioFiltersTable
                        title="Initiative"
                        portfolioFilters={this.props.subProjects}
                        depth={PortfolioFiltersEnum.SubProject}
                        selectedId={-1}
                        canCreate={isMainProjectSelected}
                        {...commonTablesProps}
                    />
                </PortfolioFilterTablesWrapper>

                <PortfolioFilterFormModal
                    title={modalTitle}
                    name={this.state.portfolioFilterData.name}
                    form={this.props.form}
                    toggleModal={this.toggleModal}
                    isVisible={this.state.isModalVisible}
                    handleSubmit={this.handleSubmit}
                />
            </PaddedSection>
        );
    }
}

export const PortfolioFilters = Antd3Form.create({})(PortfolioFiltersComponent);
