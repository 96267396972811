import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import { saveFilters } from 'features/pagination/actions/paginationActions';
import { AutocompleteField, PaginationView } from '../models/paginationModel';

import { AutocompleteSelect } from './AutocompleteSelect';
import { FormFieldIds } from 'common/helpers/utils';

type Props = {
    paginatedView: PaginationView;
    path?: string;
    filterName?: string;
    form: WrappedFormUtils;
};

export const PaginationDepartmentValueFilter = ({
    paginatedView,
    path,
    filterName = 'userDepartment',
    form,
}: Props) => {
    const dispatch = useDispatch();

    const handleDepartmentFilterChange = debounce((e) => {
        dispatch(
            saveFilters({
                paginatedView,
                values: { [filterName]: e },
                path,
            }),
        );
    }, 300);

    const handleDepartmentFilterDelete = () => {
        dispatch(saveFilters({ paginatedView, values: { [filterName]: undefined } }));
    };

    return (
        <AutocompleteSelect
            autocompleteField={AutocompleteField.Department}
            form={form}
            id={FormFieldIds.Department}
            onSelect={handleDepartmentFilterChange}
            onRemove={handleDepartmentFilterDelete}
            placeholder={'Filter by Department...'}
            showLabel={false}
        />
    );
};
