import { inject, injectable } from 'inversify';

import { HttpService } from 'common/services/HttpService';
import { SERVICE } from 'config/identifiers';
import { config } from 'config/config';
import { dateRequestFormat, snakeUpperCase, publishStatus } from 'common/helpers/utils';
import { Role } from 'features/user/models/userModel';
import { AssignActions } from 'features/benefit/models/benefitModels';
import { filteringRequestPath } from 'common/helpers/url';

import {
    ArchiveFilterRequest,
    Contract,
    ContractRequest,
    ContractRequestData,
    CreateFilterRequest,
    EditFilterRequest,
    Filters,
    FilterContract,
    ContractRoleData,
    ContractRole,
    DeleteContractRoleRequest,
    AddContractRoleRequest,
    ArchiveFilterResponse,
    GetContractRequest,
    ContractPriorities,
    AddContractPriorityRequest,
    EditContractPriorityRequest,
    ToggleMultipleEmployeesContractRoleRequest,
    ContractNoteData,
    CreateContractNoteRequest,
    DeleteContractNoteRequest,
    EditContractNoteRequest,
    DeleteContractNoteFileRequest,
    AddContractNoteFileRequest,
    DeleteContractFileRequest,
} from '../models/contractModels';

@injectable()
export class ContractService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    public async archiveFilter(filterData: ArchiveFilterRequest): Promise<ArchiveFilterResponse> {
        return this.http.PATCH<ArchiveFilterResponse>(
            `${config.routeConfig[filterData.filterType]}${filterData.filterId}/`,
            {
                archived: filterData.archived,
            },
        );
    }

    public createContract(contract: Contract): Promise<void> {
        return this.http.POST(config.routeConfig.contract, {
            ...contract,
            budget: contract.budget && snakeUpperCase(contract.budget),
            routeToMarket: contract.routeToMarket && snakeUpperCase(contract.routeToMarket),
            contractType: contract.contractType && snakeUpperCase(contract.contractType),
            startDate: contract.startDate && dateRequestFormat(contract.startDate),
            endDate: contract.endDate && dateRequestFormat(contract.endDate),
            status: snakeUpperCase(contract.status),
        });
    }

    public createFilter({ filterType, name }: CreateFilterRequest): Promise<void> {
        return this.http.POST(config.routeConfig[filterType], { name });
    }

    public deleteContract(contractId: number): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.contract}${contractId}/`);
    }

    public editContract(contract: Contract): Promise<Contract> {
        return this.http.PATCH<Contract>(`${config.routeConfig.contract}${contract.id}/`, {
            ...contract,
            budget: contract.budget && snakeUpperCase(contract.budget),
            routeToMarket: contract.routeToMarket && snakeUpperCase(contract.routeToMarket),
            contractType: contract.contractType && snakeUpperCase(contract.contractType),
            endDate: contract.endDate && dateRequestFormat(contract.endDate),
            status: contract.status && snakeUpperCase(contract.status),
            startDate: contract.startDate && dateRequestFormat(contract.startDate),
        });
    }

    public editFilter({ filterId, filterType, name }: EditFilterRequest): Promise<Filters> {
        return this.http.PATCH<Filters>(`${config.routeConfig[filterType]}${filterId}/`, { name });
    }

    public deleteFilter({ filterId, filterType }: EditFilterRequest): Promise<Filters> {
        return this.http.DELETE<Filters>(`${config.routeConfig[filterType]}${filterId}/`);
    }

    public async filterContracts({
        path,
        status,
        contractType,
        minStartDate,
        maxStartDate,
        minEndDate,
        maxEndDate,
        published,
        title,
        referenceNumber,
        supplierName,
        routeToMarket,
        ...filters
    }: FilterContract): Promise<ContractRequest> {
        const contractFilters = {
            ...filters,
            ...publishStatus(published),
            referenceNumber: referenceNumber && encodeURIComponent(referenceNumber),
            title: title && encodeURIComponent(title),
            supplierName: supplierName && encodeURIComponent(supplierName),
            minStartDate: minStartDate && dateRequestFormat(minStartDate),
            maxStartDate: maxStartDate && dateRequestFormat(maxStartDate),
            minEndDate: minEndDate && dateRequestFormat(minEndDate),
            maxEndDate: maxEndDate && dateRequestFormat(maxEndDate),
            status: status && snakeUpperCase(status),
            contractType: contractType && snakeUpperCase(contractType),
            routeToMarket: routeToMarket && snakeUpperCase(routeToMarket),
        };

        const requestPath = filteringRequestPath(contractFilters);

        const [contracts, myContracts] = await Promise.all([
            this.http.GET<Contract[]>(`${config.routeConfig.allContracts}?${requestPath}`),
            this.http.GET<Contract[]>(`${config.routeConfig.myContracts}?${requestPath}`),
        ]);

        return { contracts, myContracts, path };
    }

    public getCategories(): Promise<Filters[]> {
        return this.http.GET<Filters[]>(config.routeConfig.contractCategories);
    }

    public getAllContracts(): Promise<Contract[]> {
        return this.http.GET<Contract[]>(config.routeConfig.allContracts);
    }

    public async getContractDetails(): Promise<ContractRequestData> {
        const [contracts, categories, departments, locations, portfolioFilters, priorities] =
            await Promise.all([
                this.http.GET<Contract[]>(config.routeConfig.contract),
                this.http.GET<Filters[]>(config.routeConfig.contractCategories),
                this.http.GET<Filters[]>(config.routeConfig.departments),
                this.http.GET<Filters[]>(config.routeConfig.locations),
                this.http.GET<Filters[]>(config.routeConfig.portfolioFilters),
                this.http.GET<Filters[]>(config.routeConfig.priorities),
            ]);

        return {
            contracts,
            categories,
            departments,
            locations,
            portfolioFilters,
            priorities,
        };
    }

    public getContract({ contractId }: GetContractRequest): Promise<Contract> {
        const path = `${config.routeConfig.allContracts}${contractId}/`;

        return this.http.GET<Contract>(path);
    }

    public getContractRoles(contractId: number): Promise<ContractRoleData[]> {
        return this.http.GET<ContractRoleData[]>(
            `${config.routeConfig.contract}${contractId}/roles/`,
        );
    }

    public getContractPriorities(contractId: number): Promise<ContractPriorities[]> {
        return this.http.GET<ContractPriorities[]>(
            `${config.routeConfig.contract}${contractId}/priorities/`,
        );
    }

    public async addContractPriority(
        data: AddContractPriorityRequest,
    ): Promise<ContractPriorities[]> {
        return this.http.POST<ContractPriorities[]>(
            `${config.routeConfig.contract}${data.contractId}/priorities/`,
            data.priority,
        );
    }

    public async editContractPriority(
        data: EditContractPriorityRequest,
    ): Promise<ContractPriorities> {
        return this.http.PATCH<ContractPriorities>(
            `${config.routeConfig.contract}${data.contractId}/priorities/${data.priorityId}/`,
            { value: data.value },
        );
    }

    public async deleteContractPriority(data: EditContractPriorityRequest): Promise<number> {
        await this.http.DELETE(
            `${config.routeConfig.contract}${data.contractId}/priorities/${data.priorityId}/`,
        );

        return data.priorityId;
    }

    public getMyContracts(): Promise<Contract[]> {
        return this.http.GET<Contract[]>(config.routeConfig.myContracts);
    }

    public getDepartments(): Promise<Filters[]> {
        return this.http.GET<Filters[]>(config.routeConfig.departments);
    }

    public getLocations(): Promise<Filters[]> {
        return this.http.GET<Filters[]>(config.routeConfig.locations);
    }

    public getPortfolioFilters(): Promise<Filters[]> {
        return this.http.GET<Filters[]>(config.routeConfig.portfolioFilters);
    }

    public addContractRole({
        role,
        contractId,
        roleId,
    }: AddContractRoleRequest): Promise<ContractRoleData> {
        const contract =
            role === ContractRole.SUPPLIER
                ? {
                      organisation: roleId,
                  }
                : {
                      user: roleId,
                  };

        return this.http.POST<ContractRoleData>(
            `${config.routeConfig.contract}${contractId}/roles/`,
            {
                ...contract,
                role: snakeUpperCase(role),
            },
        );
    }

    public async deleteContractRole({
        contractId,
        roleId,
    }: DeleteContractRoleRequest): Promise<number | string> {
        await this.http.DELETE(`${config.routeConfig.contract}${contractId}/roles/${roleId}/`);

        return roleId;
    }

    public toggleMultipleEmployeesContractRole({
        action,
        contractIds,
        userIds,
    }: ToggleMultipleEmployeesContractRoleRequest): Promise<Role[]> {
        const { assignEmployeesToContracts, revokeEmployeesFromContracts } = config.routeConfig;

        return this.http.POST(
            action === AssignActions.Assign
                ? assignEmployeesToContracts
                : revokeEmployeesFromContracts,
            {
                contractIds,
                userIds,
            },
        );
    }

    public getContractNotes(contractId: number): Promise<ContractNoteData[]> {
        return this.http.GET<ContractNoteData[]>(
            `${config.routeConfig.contract}${contractId}/notes/`,
        );
    }

    public createContractNote(data: CreateContractNoteRequest): Promise<void> {
        const obj = {};
        Object.entries(data)
            .filter(([, value]) => value !== undefined)
            .forEach(([key, value]) => (obj[key] = value));
        return this.http.formPOST(`${config.routeConfig.contract}${data.contract}/notes/`, obj);
    }

    public deleteContractNote(data: DeleteContractNoteRequest): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.contract}${data.contract}/notes/${data.id}/`);
    }

    public editContractNote(data: EditContractNoteRequest): Promise<void> {
        return this.http.PATCH(`${config.routeConfig.contract}${data.contract}/notes/${data.id}/`, {
            ...data,
        });
    }

    public deleteContractNoteFile({ contract, id }: DeleteContractNoteFileRequest): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.contract}${contract}/note-files/${id}/`);
    }

    public deleteContractFile({ contract, id }: DeleteContractFileRequest): Promise<void> {
        return this.http.DELETE(`${config.routeConfig.contract}${contract}/files/${id}/`);
    }

    public addContractNoteFile(data: AddContractNoteFileRequest): Promise<void> {
        const obj = {};
        Object.entries(data)
            .filter(([, value]) => value !== undefined)
            .forEach(([key, value]) => (obj[key] = value));
        return this.http.formPOST(
            `${config.routeConfig.contract}${data.contract}/note-files/`,
            obj,
        );
    }
}
