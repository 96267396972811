export interface DashboardElement {
    name: string;
    path: string;
}

export enum DashboardPaths {
    Contracts = '/dashboard/projects',
    ContractsFilter = '/dashboard/projects/filter',
    BenefitTemplates = '/dashboard/benefits',
    BenefitTemplatesFilter = '/dashboard/benefits/filter',
    ContractPriorities = '/dashboard/project-priorities',
    ContractPrioritiesFilter = '/dashboard/project-priorities/filter',
    BenefitPriorities = '/dashboard/benefit-priorities',
    BenefitPrioritiesFilter = '/dashboard/benefit-priorities/filter',
}
