import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors, fontSizes } from 'theme/variables';

const StyledBreadcrumb = styled(AntdBreadcrumb)`
    font-size: ${fontSizes.small};
    padding: 10px 32px;
    width: 100%;
    border-bottom: 1px solid ${colors.antdBorder};

    > ol {
        height: 26px;
        flex-wrap: nowrap;

        > li {
            &:not(:nth-of-type(1)) {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .ant-breadcrumb-link {
                white-space: nowrap;
            }
        }
    }
`;

type BreadcrumbLink = {
    title: string;
    to?: string;
    icon?: JSX.Element;
};

type Props = {
    goBackLink?: BreadcrumbLink;
    links: BreadcrumbLink[];
};

export const Breadcrumb = ({ goBackLink, links }: Props) => (
    <StyledBreadcrumb>
        {goBackLink && (
            <AntdBreadcrumb.Item key={goBackLink.title}>
                {goBackLink.icon}
                {goBackLink.title}
            </AntdBreadcrumb.Item>
        )}
        {links.map(({ title, to, icon }) => (
            <AntdBreadcrumb.Item key={title}>
                {to ? (
                    <Link to={to}>
                        {icon}
                        {title}
                    </Link>
                ) : (
                    <>
                        {icon}
                        {title}
                    </>
                )}
            </AntdBreadcrumb.Item>
        ))}
    </StyledBreadcrumb>
);
