import * as React from 'react';

import { ImportForm } from 'features/import/components/ImportForm';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { ImportBenefitTemplatesContainerProps } from '../containers/ImportTemplatedBenefitsContainer';
import { ErrorList } from './ErrorList';

type ImportBenefitTemplatesProps = ImportBenefitTemplatesContainerProps & Antd3FormProps;

class ImportBenefitTemplatesForm extends React.Component<ImportBenefitTemplatesProps> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { fileList: { file } }) => {
            if (!error) {
                return file && this.props.importBenefitTemplatesRequest(file);
            }
            return;
        });
    };

    public render(): JSX.Element {
        const { form, isProcessing, errors } = this.props;
        const importError = errors.length > 0;

        return (
            <>
                <ImportForm
                    header="Import Templated Benefits"
                    form={form}
                    href="import-templated-benefits"
                    onSubmit={this.handleSubmit}
                    helpUrl="https://support.cenefits.com/articles/admins-contract-managers/how-to-import-templated-benefits"
                    processing={this.props.isProcessing}
                    importError={importError}
                />
                {!isProcessing && importError && <ErrorList errors={errors} />}
            </>
        );
    }
}

export const ImportBenefitTemplates = Antd3Form.create({})(ImportBenefitTemplatesForm);
