import { route, config } from 'config/config';

import { PortfolioFiltersEnum, PortfolioFilterIds } from '../models/portfolioFilterModel';

export const getPortfolioFiltersOtherUrl = (
    rootPortfolioFilterId: number,
    portfolioFilterIds: PortfolioFilterIds,
    depth: PortfolioFiltersEnum,
) => {
    const subProgramUrl = `${config.routeConfig.portfolioFilters}${rootPortfolioFilterId}/${route.subPrograms}/`;
    const mainProjectUrl = `${portfolioFilterIds.subProgramId}/${route.mainProjects}/`;
    const subProjectUrl = `${portfolioFilterIds.mainProjectId}/${route.subProjects}/ `;

    switch (depth) {
        case PortfolioFiltersEnum.SubProgram: {
            return subProgramUrl;
        }
        case PortfolioFiltersEnum.MainProject: {
            return `${subProgramUrl}${mainProjectUrl}`;
        }
        default:
            return `${subProgramUrl}${mainProjectUrl}${subProjectUrl}`;
    }
};
