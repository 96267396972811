import { ButtonType } from 'antd/lib/button';
import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';

interface OpenModalProps extends ModalFuncProps {
    content?: string;
    title?: string;
    okText?: string;
    okType?: ButtonType;
    cancelText?: string;
    centered?: boolean;
    callback?: (...args: any[]) => any;
    modalType?: string;
}

export const openModal = ({
    content = '',
    callback,
    title = 'Are you sure?',
    okText = 'Yes',
    okType = 'danger',
    cancelText = 'No',
    modalType = 'confirm',
}: OpenModalProps) => {
    if (modalType === 'info') {
        Modal.info({
            content,
            title,
        });
    } else {
        const modal = Modal.confirm({
            content,
            title,
            okType,
            okText,
            cancelText,
            onOk: (...args) => {
                callback ? callback(...args) : modal.destroy();
            },
        });
    }
};

export const deleteOrganisationRoleText =
    'Removing the organisation will also remove associated users from the';

export const deleteUserRoleText = 'Are you sure you wish to remove this user from the';

export const deletePendingUserRoleText =
    'Are you sure you wish to cancel the invite sent to this user for the';

export const changeSupplierText =
    "Changing the supplier will also remove it's associated users from the benefit";
