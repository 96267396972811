import { Spin } from 'antd';
import styled from 'styled-components';

import { breakpoints, iconSizes, sizes } from 'theme/variables';

import { Antd3Icon } from './deprecated/antd3';

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(70vh - ${sizes.pageHeaderSmall});

    ${breakpoints.small} {
        min-height: calc(70vh - ${sizes.pageHeader});
    }

    ${breakpoints.medium} {
        min-height: 70vh;
    }
`;

const StyledIcon = styled(Antd3Icon)`
    && {
        height: ${iconSizes.extraLarge};
        width: ${iconSizes.extraLarge};
        font-size: ${iconSizes.extraLarge};
    }
`;

export const FullPageSpinner = () => (
    <SpinnerWrapper>
        <Spin tip="Loading..." indicator={<StyledIcon type="loading" spin />} />
    </SpinnerWrapper>
);
