const MAX_PHONE_NUMBER_LENGTH = 15;

const OPTIONAL_PLUS = '\\+?';
const AT_LEAST_ONE_DIGIT = '(?=.*\\d)';
const DIGITS_OR_WHITESPACES = '[ \\d]+';
const WHITESPACES = ' *';

const VALID_PHONE_NUMBER = new RegExp(
    '^' + WHITESPACES + OPTIONAL_PLUS + AT_LEAST_ONE_DIGIT + DIGITS_OR_WHITESPACES + '$',
);

export const isPhoneNumberValid = (phoneNumber: string): boolean =>
    phoneNumberContainsOnlyNumbersAndWhiteSpace(phoneNumber) &&
    phoneNumber.length <= MAX_PHONE_NUMBER_LENGTH;

export const phoneNumberContainsOnlyNumbersAndWhiteSpace = (phoneNumber: string): boolean =>
    VALID_PHONE_NUMBER.test(phoneNumber);
