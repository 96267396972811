import * as React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';

import { PaginationResources, RequestParams } from 'features/pagination/models/paginationModel';
import { EditContractPaths, RoleInvitation } from 'features/contract/models/contractModels';
import { EditPaths } from 'features/edit/models/editModel';

import {
    GetEmployeesPendingInvitationsSuccessAction,
    GetEmployeesExpiredInvitationsSuccessAction,
    GetRolesPendingInvitationsSuccessAction,
    GetRolesExpiredInvitationsSuccessAction,
    GetExistingOrgPendingInvitationsSuccessAction,
    GetNewOrgPendingInvitationsSuccessAction,
} from '../actions/inviteActions';
import {
    EmployeeInvitation,
    ResendRoleInvitation,
    DeleteRoleInvitation,
    InvitePaths,
    SupplierInvitePaths,
} from '../models/inviteModel';
import { InvitationTab } from './InvitationTab';

const { TabPane } = Tabs;

const TableWrapper = styled.div`
    width: 100%;
    padding: 1.5rem;
`;

export interface PendingInvitationsListProps {
    pendingInvitations: EmployeeInvitation[] | RoleInvitation[];
    expiredInvitations: EmployeeInvitation[] | RoleInvitation[];
    pendingPaginationView: number;
    expiredPaginationView: number;
    resendPendingInvitation: (id: number | ResendRoleInvitation) => void;
    resendExpiredInvitation: (id: number | ResendRoleInvitation) => void;
    deletePendingInvitation: (id: string | DeleteRoleInvitation) => void;
    deleteExpiredInvitation: (id: string | DeleteRoleInvitation) => void;
    pendingInvitationsPaginationSuccessCallback: (
        data: PaginationResources[],
    ) =>
        | GetEmployeesPendingInvitationsSuccessAction
        | GetExistingOrgPendingInvitationsSuccessAction
        | GetNewOrgPendingInvitationsSuccessAction
        | GetRolesPendingInvitationsSuccessAction;
    expiredInvitationsPaginationSuccessCallback: (
        data: PaginationResources[],
    ) => GetEmployeesExpiredInvitationsSuccessAction | GetRolesExpiredInvitationsSuccessAction;
    redirectToUrl(path: string): void;
    contractId?: number;
    pageNumber?: number;
    path: string;
    requestParams?: RequestParams;
    isEmployee?: boolean;
    isExistingOrganisation?: boolean; // unused
    isRole?: boolean;
    isTenant?: boolean;
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PendingInvitationsList: React.FC<Partial<PendingInvitationsListProps>> = ({
    pendingInvitations,
    expiredInvitations,
    pendingPaginationView,
    expiredPaginationView,
    resendPendingInvitation,
    resendExpiredInvitation,
    deletePendingInvitation,
    deleteExpiredInvitation,
    pendingInvitationsPaginationSuccessCallback,
    expiredInvitationsPaginationSuccessCallback,
    redirectToUrl,
    contractId,
    pageNumber,
    path,
    requestParams,
    isEmployee,
    isExistingOrganisation,
    isTenant,
    isRole,
}): JSX.Element => {
    const contractInvitationsPath = `${EditPaths.Contract}/${contractId}${EditContractPaths.Invitations}`;
    const contractExpiredInvitationsPath = `${EditPaths.Contract}/${contractId}${EditContractPaths.ExpiredInvitations}`;

    const isPendingPath =
        path?.includes(InvitePaths.Employee) ||
        path?.includes(SupplierInvitePaths.Invite) ||
        path?.includes(contractInvitationsPath);

    const isExpiredPath =
        path?.includes(InvitePaths.ExpiredInvitations) ||
        path?.includes(SupplierInvitePaths.ExpiredInvitations) ||
        path?.includes(contractExpiredInvitationsPath);

    const pendingTabKey = contractId
        ? contractInvitationsPath
        : isTenant
        ? InvitePaths.Employee
        : SupplierInvitePaths.Invite;

    const expiredTabKey = contractId
        ? contractExpiredInvitationsPath
        : isTenant
        ? InvitePaths.ExpiredInvitations
        : SupplierInvitePaths.ExpiredInvitations;

    return (
        <TableWrapper>
            <Tabs onChange={redirectToUrl} activeKey={path} type="card" animated={false}>
                <TabPane key={pendingTabKey} tab="Pending Invitations" />
                <TabPane key={expiredTabKey} tab="Expired Invitations" />
            </Tabs>
            <Wrapper>
                {isPendingPath && (
                    <InvitationTab
                        view={pendingPaginationView}
                        data={pendingInvitations}
                        resendInvitation={resendPendingInvitation}
                        deleteInvitation={deletePendingInvitation}
                        paginationSuccessCallback={pendingInvitationsPaginationSuccessCallback}
                        pageNumber={pageNumber}
                        pathUrl={contractId ? contractInvitationsPath : path}
                        requestParams={requestParams}
                        isEmployee={isEmployee}
                        isExistingOrganisation={isExistingOrganisation}
                        isRole={isRole}
                    />
                )}
                {isExpiredPath && (
                    <InvitationTab
                        view={expiredPaginationView}
                        data={expiredInvitations}
                        resendInvitation={resendExpiredInvitation}
                        deleteInvitation={deleteExpiredInvitation}
                        paginationSuccessCallback={expiredInvitationsPaginationSuccessCallback}
                        pageNumber={pageNumber}
                        pathUrl={contractId ? contractExpiredInvitationsPath : path}
                        requestParams={requestParams}
                        isEmployee={isEmployee}
                        isExistingOrganisation={isExistingOrganisation}
                        isRole={isRole}
                    />
                )}
            </Wrapper>
        </TableWrapper>
    );
};
