import * as React from 'react';
import { Button, Upload } from 'antd';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';
import styled from 'styled-components';

import { sizes } from 'theme/variables';
import { NoteFileData } from 'features/upload/models/uploadModels';

import { UploadedFilesList } from './UploadedFilesList';
import { Antd3Form, Antd3FormProps, Antd3Icon } from '../deprecated/antd3';

interface EditUploadContractNoteFileProps extends Antd3FormProps {
    id: string;
    description: string;
    required?: boolean;
    disabled?: boolean;
    accept?: string;
    uploadedFiles: NoteFileData[];
    onChange?(): void;
    addFile: (file: IUploadFile) => void;
    removeFile: (id: number) => void;
}

const StyledUpload = styled(Upload)`
    button.ant-btn,
    .ant-upload {
        width: 100%;
        max-width: ${sizes.formWidth};
    }
`;

export class EditUploadContractNoteFile extends React.Component<EditUploadContractNoteFileProps> {
    public render(): JSX.Element {
        const { disabled } = this.props;

        const props = {
            onChange: () => this.props.onChange && this.props.onChange(),
            beforeUpload: (file: IUploadFile) => {
                this.props.addFile(file);
                return false;
            },
            fileList: [],
            disabled,
            accept: this.props.accept,
            multiple: false,
        };

        return (
            <Antd3Form.Item>
                <StyledUpload {...props}>
                    <Button size="large" disabled={disabled} loading={disabled}>
                        <Antd3Icon type="upload" />
                        {this.props.description}
                    </Button>
                </StyledUpload>
                <UploadedFilesList
                    items={
                        this.props.uploadedFiles &&
                        this.props.uploadedFiles.map((item) => ({
                            name: item.fileName,
                            id: item.id,
                        }))
                    }
                    deleteItem={(id: number) => this.props.removeFile(id)}
                    noData={'No File Uploaded'}
                />
            </Antd3Form.Item>
        );
    }
}
