import { useState } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';

import {
    activateAccountRequest,
    activateExecutiveAdminAccountRequest,
} from 'features/auth/actions/authActions';
import { NewPasswordInput, SubmitButton } from 'common/components';
import { getPercentagePasswordStrength } from 'common/helpers/passwordStrength';
import { Input } from 'common/components/deprecated/form';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { showNotification } from 'common/helpers/notifications';

import { UserAccountAgreements } from '../models/userModel';
import { ConsentsCheckboxes } from './ConsentsCheckboxes';

const ButtonRow = styled(Space)`
    width: 100%;
    justify-content: flex-end;
`;

type Props = Antd3FormProps & {
    activateRequest: typeof activateAccountRequest | typeof activateExecutiveAdminAccountRequest;
    userExists?: boolean;
    isAuthenticated?: boolean;
};

type Status = 'success' | 'active' | 'exception' | undefined;

const CreateAccountForm = (props: Props) => {
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [status, setStatus] = useState<Status>('exception');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.form.validateFields((error, values) => {
            if (error) {
                const passwordError = error.password?.errors[0]?.message;

                if (passwordError) {
                    showNotification({ text: passwordError });
                }

                return;
            }

            props.activateRequest({
                password: values.password,
                [UserAccountAgreements.FunctionalEmails]:
                    values[UserAccountAgreements.FunctionalEmails],
                [UserAccountAgreements.NonFunctionalEmails]:
                    values[UserAccountAgreements.NonFunctionalEmails],
                [UserAccountAgreements.TermsOfService]:
                    values[UserAccountAgreements.TermsOfService],
                userExists: props.userExists,
                isAuthenticated: props.isAuthenticated,
            });
        });
    };

    const handleOnChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { passwordStrength, status } = getPercentagePasswordStrength(event.target.value);

        setPasswordStrength(passwordStrength);
        setStatus(status);
    };

    return (
        <StyledAntd3Form
            header={props.userExists ? 'Join Organisation' : 'Create Account'}
            bigHeader
            onSubmit={handleSubmit}
        >
            {!props.userExists && (
                <>
                    <NewPasswordInput
                        form={props.form}
                        id="password"
                        placeholder="Password"
                        onChange={handleOnChangePassword}
                        percent={passwordStrength}
                        status={status}
                        requiredMessage="Please enter a strong password"
                        required
                    />
                    <Input
                        form={props.form}
                        id="passwordConfirmation"
                        requiredMessage="Please repeat your Password"
                        placeholder="Repeat Password"
                        isPassword
                    />
                </>
            )}
            <ConsentsCheckboxes form={props.form} />
            <ButtonRow>
                <SubmitButton
                    type="primary"
                    disabled={
                        !props.form.getFieldValue(UserAccountAgreements.FunctionalEmails) ||
                        !props.form.getFieldValue(UserAccountAgreements.TermsOfService)
                    }
                    value={props.userExists ? 'Join Organisation' : 'Create Account'}
                />
            </ButtonRow>
        </StyledAntd3Form>
    );
};

export const CreateAccount = Antd3Form.create({})(CreateAccountForm);
