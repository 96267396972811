import { LeftOutlined, LinkOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { CheckPaths } from 'features/check/models/checkModel';
import { EditPaths } from 'features/edit/models/editModel';
import { Breadcrumb, ContentMenu, RouteProps } from 'common/components';
import { AppState } from 'common/appState';
import {
    getPaginationState,
    getPaginationView,
} from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';

import { EditBenefitPaths } from '../models/benefitModels';
import { getBenefitByRouteId } from '../selectors/benefitSelectors';

export const EditBenefitNavigation = (routeProps: RouteProps) => {
    const pagination = useSelector(getPaginationState);
    const view = useSelector(getPaginationView);

    const { id: contractId, benefitId } = routeProps.match.params;

    const benefit = benefitId
        ? useSelector((state: AppState) => getBenefitByRouteId(state, { benefitId }))
        : undefined;

    if (!contractId || !benefit) {
        return null;
    }

    const benefitMenuLinks = [
        {
            key: `${EditPaths.Contract}/${contractId}/benefits/${benefitId}${EditBenefitPaths.Details}`,
            label: 'Benefit Details',
        },
        {
            key: `${EditPaths.Contract}/${contractId}/benefits/${benefitId}${EditBenefitPaths.BenefitForm}`,
            label: 'Edit Benefit',
        },
        {
            key: `${CheckPaths.Evidence}${EditPaths.Contract}/${contractId}/benefit/${benefitId}`,
            label: 'Check Evidence',
            Icon: <LinkOutlined />,
        },
        {
            key: `${CheckPaths.Scores}${EditPaths.Contract}/${contractId}/benefit/${benefitId}`,
            label: 'Check Feedback',
            Icon: <LinkOutlined />,
        },
    ];

    const benefitMenuLinksWithNotes = [
        {
            key: `${EditPaths.Contract}/${contractId}/benefits/${benefitId}${EditBenefitPaths.Details}`,
            label: 'Benefit Details',
        },
        {
            key: `${EditPaths.Contract}/${contractId}/benefits/${benefitId}${EditBenefitPaths.BenefitForm}`,
            label: 'Edit Benefit',
        },
        {
            key: `${EditPaths.Contract}/${contractId}/benefits/${benefitId}${EditBenefitPaths.Notes}`,
            label: 'Private Notes',
        },
        {
            key: `${CheckPaths.Evidence}${EditPaths.Contract}/${contractId}/benefit/${benefitId}`,
            label: 'Check Evidence',
            Icon: <LinkOutlined />,
        },
        {
            key: `${CheckPaths.Scores}${EditPaths.Contract}/${contractId}/benefit/${benefitId}`,
            label: 'Check Feedback',
            Icon: <LinkOutlined />,
        },
    ];

    return (
        <>
            <Breadcrumb
                links={[
                    {
                        title:
                            view === PaginationView.MyBenefits
                                ? 'Edit My Benefits'
                                : 'Edit Benefits',
                        to: `${
                            view === PaginationView.MyBenefits
                                ? EditPaths.MyBenefits
                                : EditPaths.Benefits
                        }/${pagination.current}`,
                        icon: <LeftOutlined />,
                    },
                    {
                        title: benefit.outcome,
                    },
                ]}
            />
            <ContentMenu
                adminMenuLinks={benefitMenuLinksWithNotes}
                contractManagerMenuLinks={benefitMenuLinksWithNotes}
                menuLinks={benefitMenuLinks}
                small
            />
        </>
    );
};
