import { Input } from 'antd';

import { Antd3Form, Antd3InputProps } from '../antd3';
import { InputLabelWithTooltip } from '../../form/components/InputLabelWithTooltip';

interface Props extends Antd3InputProps {
    rows?: number;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    initialValue?: string;
    autosize?: boolean;
}

export const TextArea = ({
    form,
    id,
    requiredMessage,
    placeholder,
    label,
    onChange,
    onInputChange,
    initialValue,
    rows = 4,
    required = true,
    disabled = false,
    autosize = false,
    tooltip,
}: Props) => {
    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (onInputChange) {
            onInputChange(id, 'value', event.target.value);
        }
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <Antd3Form.Item
            label={
                tooltip ? (
                    <InputLabelWithTooltip label={label || placeholder} tooltip={tooltip} />
                ) : (
                    label || placeholder
                )
            }
        >
            {form.getFieldDecorator(id, {
                initialValue,
                rules: [
                    { required, message: requiredMessage || `${label || placeholder} is required` },
                ],
            })(
                <Input.TextArea
                    autoSize={autosize}
                    onChange={handleOnChange}
                    rows={rows}
                    placeholder={placeholder || label}
                    disabled={disabled}
                />,
            )}
        </Antd3Form.Item>
    );
};
