import { createEpicMiddleware } from 'redux-observable';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import { appInit } from 'common/actions/appInit';
import { authReducer } from 'features/auth/reducers/authReducer';
import { benefitReducer } from 'features/benefit/reducers/benefitReducer';
import { contractReducer } from 'features/contract/reducers/contractReducer';
import { downloadReducer } from 'features/download/reducers/downloadReducer';
import { importReducer } from 'features/import/reducers/importReducer';
import { inviteReducer } from 'features/invite/reducers/inviteReducer';
import { organisationReducer } from 'features/organisation/reducers/organisationReducer';
import { userReducer } from 'features/user/reducers/userReducer';
import { uploadReducer } from 'features/upload/reducers/uploadReducer';
import { checkReducer } from 'features/check/reducers/checkReducer';
import { paginationReducer } from 'features/pagination/reducers/paginationReducer';
import { portfolioFilterReducer } from 'features/portfolioFilter/reducers/portfolioFilterReducer';

import { SERVICE } from './identifiers';
import { container } from './appContainer';

const rootReducer = combineReducers({
    auth: authReducer,
    benefit: benefitReducer,
    contract: contractReducer,
    download: downloadReducer,
    import: importReducer,
    invite: inviteReducer,
    organisation: organisationReducer,
    upload: uploadReducer,
    user: userReducer,
    check: checkReducer,
    pagination: paginationReducer,
    portfolioFilter: portfolioFilterReducer,
});

const epicMiddleware = createEpicMiddleware(container.get(SERVICE.rootEpicFactory));

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

store.dispatch(appInit());
