import * as React from 'react';
import { List, Input, Modal, Button, Row } from 'antd';
import styled from 'styled-components';

import {
    ArchiveFilterRequest,
    FilterType,
    EditFilterRequest,
} from 'features/contract/models/contractModels';
import { ButtonWrapper } from 'common/components/styled';
import { Antd3Icon } from 'common/components/deprecated/antd3';

interface FilterInputProps {
    id: number;
    name: string;
    filterType: FilterType;
    onArchiveMessage: string;
    onArchive(filterData: ArchiveFilterRequest): void;
    onEdit(filterData: EditFilterRequest): void;
    archived: boolean;
    onDelete(filterData: EditFilterRequest): void;
}

interface FilterInputState {
    value: string;
    archived: boolean;
}

const StyledRow = styled(Row)`
    width: 100%;

    && {
        display: flex;
    }
`;

const StyledInput = styled(Input)`
    flex: 1;
`;

export class FilterInput extends React.Component<FilterInputProps> {
    public state: FilterInputState = {
        value: this.props.name,
        archived: this.props.archived,
    };

    private handleInput = (event: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({ value: event.target.value });

    private openArchiveOrderModal = () => {
        const actionMsg = this.props.archived ? 'unarchive' : 'archive';
        const modal = Modal.confirm({
            title: `${this.state.value}: are you sure you want to ${actionMsg} this ${this.props.onArchiveMessage}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                this.onArchive();
                modal.destroy();
            },
        });
    };

    private onArchive = () =>
        this.props.onArchive({
            name: this.state.value,
            filterId: this.props.id,
            filterType: this.props.filterType,
            archived: !this.props.archived,
        });

    private onEdit = (event: React.MouseEvent) => {
        event.preventDefault();
        this.props.onEdit({
            name: this.state.value,
            filterId: this.props.id,
            filterType: this.props.filterType,
        });
    };

    private openDeleteOrderModal = () => {
        const modal = Modal.confirm({
            title: `${this.state.value}: are you sure you want to delete this filter?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                this.onDelete();
                modal.destroy();
            },
        });
    };

    private onDelete = () => {
        this.props.onDelete({
            name: this.state.value,
            filterId: this.props.id,
            filterType: this.props.filterType,
        });
    };

    public render(): JSX.Element {
        const { archived } = this.props;
        const { value } = this.state;

        const filterName = archived ? `${value} (Archived)` : value;
        const archiveBtnLabel = this.props.archived ? 'Unarchive' : 'Archive';

        return (
            <List.Item>
                <StyledRow>
                    <StyledInput
                        value={filterName}
                        onChange={this.handleInput}
                        disabled={archived}
                    />
                    <ButtonWrapper>
                        <Button
                            icon={<Antd3Icon type="form" />}
                            type="primary"
                            onClick={this.onEdit}
                        >
                            Save
                        </Button>
                        <Button
                            type={archived ? 'default' : 'danger'}
                            icon={<Antd3Icon type={archived ? 'undo' : 'folder'} />}
                            onClick={this.openArchiveOrderModal}
                        >
                            {archiveBtnLabel}
                        </Button>
                        <Button
                            onClick={this.openDeleteOrderModal}
                            type="danger"
                            icon={<Antd3Icon type="delete" />}
                        >
                            Remove
                        </Button>
                    </ButtonWrapper>
                </StyledRow>
            </List.Item>
        );
    }
}
