import { Button, Modal, Space } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { redirectToUrl } from 'common/actions/navigationAction';

import { UserAccountAgreements } from '../models/userModel';
import { updateUserDataRequest } from '../actions/userActions';
import { ConsentsCheckboxes } from './ConsentsCheckboxes';
import { AppState } from 'common/appState';
import {
    getUserFunctionalEmailsConsent,
    getUserNonFunctionalEmailsConsent,
    getUserTermsOfServiceEmailsConsent,
} from '../selectors/userSelectors';

const ButtonRow = styled(Space)`
    width: 100%;
    justify-content: flex-end;
`;

const EditConsentsForm = (props: Antd3FormProps) => {
    useEffect(
        () =>
            props.form.setFieldsValue({
                [UserAccountAgreements.FunctionalEmails]: functionalEmailConsent
                    ? functionalEmailConsent
                    : false,
                [UserAccountAgreements.NonFunctionalEmails]: nonFunctionalEmailsConsent
                    ? nonFunctionalEmailsConsent
                    : false,
                [UserAccountAgreements.TermsOfService]: termsOfServiceEmailsConsent
                    ? termsOfServiceEmailsConsent
                    : false,
            }),
        [],
    );

    const functionalEmailConsent = useSelector((state: AppState) =>
        getUserFunctionalEmailsConsent(state),
    );
    const nonFunctionalEmailsConsent = useSelector((state: AppState) =>
        getUserNonFunctionalEmailsConsent(state),
    );
    const termsOfServiceEmailsConsent = useSelector((state: AppState) =>
        getUserTermsOfServiceEmailsConsent(state),
    );

    const dispatch = useDispatch();
    const submitForm = () => {
        props.form.validateFields((error, values) => {
            if (error) {
                return;
            }

            if (values[UserAccountAgreements.FunctionalEmails]) {
                dispatch(updateUserDataRequest(values));
                dispatch(redirectToUrl('/'));
            } else {
                Modal.warning({
                    title: 'Functional emails are necessary to use the application.',
                    content: (
                        <>
                            The only way we can action this request is for us to archive or delete
                            your user account - please get in touch with us if you'd like us to
                            proceed: <a href="mailto:hello@cenefits.com">hello@cenefits.com</a>
                        </>
                    ),
                });
            }
        });
    };

    return (
        <StyledAntd3Form header="Update Emails Consents" bigHeader>
            <ConsentsCheckboxes form={props.form} />
            <ButtonRow>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={submitForm}
                    disabled={!props.form.getFieldValue(UserAccountAgreements.TermsOfService)}
                >
                    Update Consents
                </Button>
            </ButtonRow>
        </StyledAntd3Form>
    );
};

export const EditConsents = Antd3Form.create({})(EditConsentsForm);
