import * as React from 'react';
import { Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { TableFiltersBar } from 'common/components/TableFiltersBar';

type Props = {
    filterPageUrl: string;
    filterId: number;
};

export const DashboardTableFiltersBar: React.FunctionComponent<Props> = ({
    filterPageUrl,
    filterId,
}) => (
    <TableFiltersBar
        leftButtons={[
            <Link to={`${filterPageUrl}/${filterId}`}>
                <Button type="primary" icon={<FilterOutlined />}>
                    Filters
                </Button>
            </Link>,
        ]}
    />
);
