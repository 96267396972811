import { route, config } from 'config/config';

import { PortfolioFiltersEnum, PortfolioFilterIds } from '../models/portfolioFilterModel';

export const getPortfolioFiltersEditUrl = (
    rootPortfolioFilterId: number,
    portfolioFilterIds: PortfolioFilterIds,
    depth: PortfolioFiltersEnum,
) => {
    const portfolioFilterUrl = `${config.routeConfig.portfolioFilters}${rootPortfolioFilterId}/`;
    const subProgramUrl = `${route.subPrograms}/${portfolioFilterIds.subProgramId}/`;
    const mainProjectUrl = `${route.mainProjects}/${portfolioFilterIds.mainProjectId}/`;
    const subProjectUrl = `${route.subProjects}/${portfolioFilterIds.subProjectId}/`;

    switch (depth) {
        case PortfolioFiltersEnum.SubProgram: {
            return `${portfolioFilterUrl}${subProgramUrl}`;
        }
        case PortfolioFiltersEnum.MainProject: {
            return `${portfolioFilterUrl}${subProgramUrl}${mainProjectUrl}`;
        }
        case PortfolioFiltersEnum.SubProject: {
            return `${portfolioFilterUrl}${subProgramUrl}${mainProjectUrl}${subProjectUrl}`;
        }
        default:
            return portfolioFilterUrl;
    }
};
