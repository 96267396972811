import * as React from 'react';
import { startCase, camelCase, capitalize, omit } from 'lodash';

import {
    Select,
    TextArea,
    DatePicker,
    Input,
    PublishRadioButtons,
    ResetButton,
    UploadMultiFiles,
} from 'common/components/deprecated/form';
import { RolesList, SubmitButton, FullPageSpinner } from 'common/components';
import { openModal, deleteOrganisationRoleText } from 'common/helpers/modal';
import { notificationText, showNotification } from 'common/helpers/notifications';
import {
    getUsername,
    FormFieldIds,
    formatPriorityToState,
    formatPriorityRequest,
    formatRouteToMethod,
    contractPath,
} from 'common/helpers/utils';
import { contractTooltips } from 'common/tooltips/contractTooltips';
import { EditContractContainerProps } from 'features/edit/containers/EditContractFormContainer';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { DeleteRoleOptions } from 'features/benefit/components/EditBenefit';
import {
    contractType,
    status,
    ContractRole,
    budgetType,
    routeToMarket,
    AddContractPriorityRequest,
    EditContractPriorityRequest,
} from 'features/contract/models/contractModels';
import { notRootNodeFilterParam, notArchivedFilterParam } from 'config/config';
import { PrioritiesForm } from 'features/create/components/PrioritiesForm';
import { BenefitPriority } from 'features/benefit/models/benefitModels';
import { StyledAntd3Form, Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { Divider, Upload } from 'antd';
import styled from 'styled-components';
import { sizes } from 'theme/variables';
import {
    addContractFileFailure,
    addContractFileSuccess,
    deleteContractFileRequest,
} from '../actions/contractActions';
import { FileList } from 'features/check/components/FileList';
import { FileData } from 'features/upload/models/uploadModels';

const StyledUpload = styled(Upload.Dragger)`
    .ant-upload-drag-container {
        width: 100%;
        max-width: ${sizes.formWidth};
    }
    && .anticon-cross {
        display: none;
    }
`;

const StyledFiles = styled.div`
    width: 100%;
    padding: 1.5rem;
`;

interface Props {
    updatePriorities: () => void;
    addPriority: (data: AddContractPriorityRequest) => void;
    editPriority: (data: EditContractPriorityRequest) => void;
    deletePriority: (data: EditContractPriorityRequest) => void;
}

interface EditContractState {
    priorities: BenefitPriority[];
    contractFiles: FileData[];
}

type EditContractProps = Props & EditContractContainerProps & Antd3FormProps;

class EditContractFormComponent extends React.Component<EditContractProps, EditContractState> {
    constructor(props: EditContractProps) {
        super(props);
        this.state = {
            priorities: this.props.priorities,
        };
    }

    public componentDidUpdate(prevProps: EditContractContainerProps): void {
        if (prevProps.priorities !== this.props.priorities) {
            this.setState({
                priorities: this.props.priorities,
            });
        }
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { managers, suppliers, ...values }) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }
            const preparedValues = omit(values, 'priorities');

            return this.props.editContractRequest({
                ...preparedValues,
                contractType: preparedValues.contractType || null,
                budget: preparedValues.budget || null,
                portfolioFilter: preparedValues.portfolioFilter || null,
                category: preparedValues.category || null,
                department: preparedValues.department || null,
                location: preparedValues.location || null,
                frameworkContract: preparedValues.frameworkContract || null,
                id: this.props.contractId,
                contractFiles: this.props.contractFiles,
            });
        });
    };

    private deleteRole = (roleId: number | string, { organisation }: DeleteRoleOptions = {}) =>
        openModal({
            content: organisation ? `${deleteOrganisationRoleText} contract` : undefined,
            callback: () =>
                this.props.deleteContractRoleRequest({
                    contractId: this.props.contractId,
                    roleId,
                }),
        });

    private addRole = (roleId: number, role: ContractRole): void => {
        this.props.addContractRoleRequest({ role, roleId, contractId: this.props.contractId });
    };

    private selectNewPriority = (priorityData: BenefitPriorities) => {
        if (!this.state.priorities.find((a) => a.priority === priorityData.id)) {
            const formattedPriority = formatPriorityToState(priorityData);

            this.setState((prevState) => ({
                ...prevState,
                priorities: [...prevState.priorities, formattedPriority],
            }));
        }

        const data = {
            contractId: this.props.contractId,
            priority: formatPriorityRequest(priorityData),
        };

        this.props.addPriority(data);
    };

    private deleteContractFile = (fileId: number) => {
        this.props.deleteContractFileRequest({ contract: this.props.contractId, id: fileId });
    };

    private deletePriority = (priorityId: number) => {
        this.setState((prevState) => ({
            ...prevState,
            priorities: prevState.priorities.filter((item) => item.id !== Number(priorityId)),
        }));

        const data = {
            contractId: this.props.contractId,
            priorityId,
        };

        this.props.deletePriority(data);
    };

    private changePriorityValue = (priorityId: number, value: number) => {
        this.setState((prevState) => ({
            ...prevState,
            priorities: prevState.priorities.map((priority) =>
                priority.id === priorityId ? { ...priority, value } : priority,
            ),
        }));
        const data = {
            contractId: this.props.contractId,
            priorityId,
            value,
        };
        this.props.editPriority(data);
    };

    public render(): JSX.Element {
        const { contract, contractId, form, contractManagers, contractSuppliers } = this.props;
        const formattedContractManagers =
            contractManagers &&
            contractManagers.map(({ user, id, archived }) => ({
                name: getUsername({
                    firstName: user?.firstName || '',
                    lastName: user?.lastName || '',
                    archived,
                }),
                id,
            }));

        if (this.props.isFetching) {
            return <FullPageSpinner />;
        }

        if (!contract) {
            return <h2>No project with id {this.props.contractId} found</h2>;
        }

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Edit Project">
                <ResetButton form={form} />
                <Input
                    form={form}
                    id={FormFieldIds.Title}
                    label="Project Title"
                    initialValue={contract.title}
                    tooltip={contractTooltips.ContractTitle}
                />
                <Input
                    form={form}
                    id={FormFieldIds.ReferenceNumber}
                    label="Project Reference Number"
                    initialValue={contract.referenceNumber}
                    tooltip={contractTooltips.ContractReferenceNumber}
                />
                <TextArea
                    form={form}
                    id={FormFieldIds.Description}
                    label="Project Description"
                    tooltip={contractTooltips.ContractDescription}
                    initialValue={contract.description}
                    required={false}
                />
                <Select
                    form={form}
                    id={FormFieldIds.ContractType}
                    label="Project Type"
                    tooltip={contractTooltips.ContractType}
                    initialValue={capitalize(contract.contractType)}
                    options={contractType}
                    required={false}
                    allowClear
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ContractCategory}
                    id={FormFieldIds.Category}
                    placeholder="Project Category"
                    notFoundContent="No categories found"
                    tooltip={contractTooltips.ContractCategory}
                    initialValue={contract.category}
                    searchParams={notArchivedFilterParam}
                    required={false}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ContractPortfolioFilter}
                    id={FormFieldIds.PortfolioFilter}
                    placeholder="Portfolio"
                    notFoundContent="Portfolio Filters not found"
                    tooltip={contractTooltips.Portfolio}
                    initialValue={contract.portfolioFilter}
                    searchParams={{ ...notRootNodeFilterParam, ...notArchivedFilterParam }}
                    required={false}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Department}
                    id={FormFieldIds.Department}
                    placeholder="Department"
                    notFoundContent="No departments found"
                    tooltip={contractTooltips.Department}
                    initialValue={contract.department}
                    searchParams={notArchivedFilterParam}
                    required={false}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Location}
                    id={FormFieldIds.Location}
                    placeholder="Project Location"
                    notFoundContent="No locations found"
                    tooltip={contractTooltips.ContractLocation}
                    initialValue={contract.location}
                    searchParams={notArchivedFilterParam}
                    required={false}
                />
                <Select
                    form={form}
                    id={FormFieldIds.Budget}
                    placeholder="Budget Source"
                    tooltip={contractTooltips.BudgetSource}
                    options={budgetType}
                    initialValue={startCase(camelCase(contract.budget))}
                    required={false}
                    allowClear
                />
                <Select
                    form={form}
                    id={FormFieldIds.RouteToMarket}
                    placeholder="Route to Market"
                    tooltip={contractTooltips.RouteToMarket}
                    options={routeToMarket}
                    initialValue={formatRouteToMethod(contract)}
                    required={false}
                    allowClear
                />
                {form.getFieldValue('routeToMarket') && (
                    <AutocompleteSelect
                        form={form}
                        autocompleteField={AutocompleteField.Contract}
                        id="frameworkContract"
                        placeholder="Framework Project"
                        notFoundContent="No project found"
                        tooltip={contractTooltips.FrameworkContract}
                        searchParams={notArchivedFilterParam}
                        initialValue={contract.frameworkContract}
                        required={false}
                    />
                )}
                <Input
                    form={form}
                    id={FormFieldIds.Value}
                    label="Project Value"
                    initialValue={contract.value}
                    type="number"
                    tooltip={contractTooltips.ContractValue}
                    required={false}
                />
                <Input
                    form={form}
                    id={FormFieldIds.TargetBenefitValue}
                    label="Target Benefit Value"
                    type="number"
                    initialValue={contract.targetBenefitValue}
                    tooltip={contractTooltips.TargetBenefitValue}
                    required={false}
                />
                <Input
                    form={form}
                    id={FormFieldIds.TargetBenefitPoints}
                    label="Target Benefit Points"
                    initialValue={contract.targetBenefitPoints}
                    type="number"
                    tooltip={contractTooltips.TargetBenefitPoints}
                    required={false}
                />
                <Input
                    form={form}
                    id={FormFieldIds.TargetBenefitEmissionSavings}
                    label="Target Benefit Emission Savings"
                    initialValue={contract.targetBenefitEmissionSavings}
                    type="number"
                    tooltip={contractTooltips.TargetBenefitEmissionSavings}
                    required={false}
                />
                <Input
                    form={form}
                    id={FormFieldIds.TargetBenefitDeliveryNumber}
                    label="Target Benefit Delivery Number"
                    initialValue={contract.targetBenefitDeliveryNumber}
                    type="number"
                    tooltip={contractTooltips.TargetBenefitDeliveryNumber}
                    required={false}
                />
                <PrioritiesForm
                    form={form}
                    selectedPriorities={this.state.priorities}
                    selectNewPriority={this.selectNewPriority}
                    deletePriority={this.deletePriority}
                    changePriorityValue={this.changePriorityValue}
                    autocompleteField={AutocompleteField.ContractPriorities}
                    id="priorities"
                    placeholder="Project Priorities"
                    notFoundContent="No project priorities found"
                    tooltip={contractTooltips.ContractPriorities}
                />
                <DatePicker
                    form={form}
                    id={FormFieldIds.StartDate}
                    label="Project Start Date"
                    tooltip={contractTooltips.StartDate}
                    initialValue={contract.startDate}
                    required={false}
                />
                <DatePicker
                    form={form}
                    id={FormFieldIds.EndDate}
                    label="Project Completion Date"
                    tooltip={contractTooltips.EndDate}
                    initialValue={contract.endDate}
                    required={false}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Employee}
                    id={FormFieldIds.Managers}
                    placeholder="Project Managers"
                    notFoundContent="No project managers found"
                    tooltip={contractTooltips.ContractManagers}
                    onSelect={(id) => this.addRole(id as number, ContractRole.CONTRACT_MANAGER)}
                    searchParams={notArchivedFilterParam}
                    required={false}
                />
                <RolesList
                    items={formattedContractManagers}
                    deleteItem={this.deleteRole}
                    noData="No managers added"
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ExecutiveOrganisation}
                    id={FormFieldIds.Suppliers}
                    placeholder="Suppliers"
                    notFoundContent="No organisations found"
                    tooltip={contractTooltips.Suppliers}
                    onSelect={(id) => this.addRole(id as number, ContractRole.SUPPLIER)}
                    required={false}
                />
                <RolesList
                    items={contractSuppliers}
                    deleteItem={(id) => this.deleteRole(id, { organisation: true })}
                    noData="No suppliers added"
                />
                <Select
                    form={form}
                    id={FormFieldIds.Status}
                    label="Project Status"
                    tooltip={contractTooltips.ContractStatus}
                    initialValue={startCase(camelCase(contract.status))}
                    options={status}
                />
                <PublishRadioButtons
                    form={form}
                    defaultValue={contract && contract.published}
                    tooltip={contractTooltips.Published}
                />
                <Divider />
                <label>Project Files</label>
                <StyledFiles>
                    <UploadMultiFiles
                        uploadUrl={`${contractPath({
                            contractId,
                            organisationId: contract.organisation,
                        })}files/`}
                        // customRequest={(file: FileData) => this.addFileToContract(file)}
                        onRemove={(file) => this.deleteContractFile(file)}
                        successAction={addContractFileSuccess}
                        failureAction={addContractFileFailure}
                    />
                    <FileList files={this.props.contractFiles} onDelete={this.deleteContractFile} />
                </StyledFiles>
                <SubmitButton value="Edit Project" />
            </StyledAntd3Form>
        );
    }
}

export const EditContractForm = Antd3Form.create({})(EditContractFormComponent);
