import { Button, Collapse } from 'antd';
import styled from 'styled-components';
import { breakpoints, colors } from 'theme/variables';

export const FiltersBarWrapper = styled.div`
    margin-bottom: 8px;

    > div {
        display: flex;
        flex-direction: row;

        ${breakpoints.large} {
            flex-direction: column;
        }
        ${breakpoints.desktop} {
            flex-direction: row;
        }
    }
    button {
        height: 40px;
    }
`;

export const LeftButtons = styled.div`
    display: flex;
    justify-content: flex-start;

    > * {
        margin-right: 8px;
    }
`;

export const Filters = styled.div<{ desktoponly: number }>(
    ({ desktoponly }) => `
    display: ${desktoponly ? 'none' : 'flex'};
    flex: 1;
    justify-content: flex-end;
    width: 100%;
    flex-direction: column;

    ${breakpoints.large} {
        flex-direction: row;
        margin-top: 8px;
        display: flex;

        > *:not(:first-child) {
            margin-left: 8px;
        }
    }
    ${breakpoints.desktop} {
        margin: 0;
    }
`,
);

export const FilterWrapper = styled.div`
    height: 40px;
    flex: 1;
    overflow: hidden;

    && {
        .ant-legacy-form-item {
            margin: 0;
        }
        .ant-select {
            width: 100%;
        }
        .ant-select-selector {
            height: 40px;
        }
        .ant-input {
            height: 38px;
        }
        .ant-input-search .ant-input {
            height: 40px;
        }
        .ant-input-suffix {
            margin-right: 10px;
        }
        && .ant-select-selection-item {
            padding-top: 4px;
            line-height: 30px;
        }
        .ant-select-selection-placeholder {
            white-space: normal;
        }
    }
`;

export const FiltersButtons = styled.div`
    display: flex;
    justify-content: flex-end;

    > *:not(:first-child) {
        margin-left: 8px;
    }
`;

export const RightButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1;

    ${breakpoints.large} {
        flex: 0;
    }

    > * {
        margin-left: 8px;
    }
`;

export const MobileFiltersButton = styled(Button)`
    display: flex-item;

    ${breakpoints.large} {
        display: none;
    }

    > span:not(.anticon) {
        display: none;

        ${breakpoints.small} {
            display: inline-block;
        }
    }
`;

export const MobileFilters = styled(Collapse)`
    border: none;
    background-color: ${colors.primaryWhite};

    && {
        .ant-collapse-item {
            width: 100%;
            border: none;
        }
        .ant-collapse-content {
            border: none;
        }
        .ant-collapse-content-box {
            padding: 8px 0 0;
        }
        .ant-collapse-header {
            display: none;
        }
        .ant-form-item,
        .ant-legacy-form-item {
            margin-bottom: 8px;
        }
    }
`;
