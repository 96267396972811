import { InputNumber as AntdInputNumber, InputNumberProps, Form } from 'antd';
import { FormItemProps } from '@ant-design/compatible/lib/form';
import styled from 'styled-components';

import { InputLabelWithTooltip } from './components/InputLabelWithTooltip';
import { FormInputProps } from './models';
import { NamePath } from 'antd/lib/form/interface';

const StyledFormItem = styled(Form.Item)<{ nobottommargin: number }>(
    ({ nobottommargin }) => `
        margin-bottom: ${nobottommargin ? 0 : '1rem'};
`,
);

const InputWrapper = styled(AntdInputNumber)`
    width: 100%;
`;

type Props = FormItemProps &
    Omit<InputNumberProps, 'name'> &
    FormInputProps & {
        validateDecimals?: boolean;
        name: NamePath;
        noBottomMargin?: boolean;
    };

export const InputNumber = ({
    name,
    placeholder,
    label,
    required = true,
    requiredMessage,
    disabled = false,
    hidden,
    autoComplete,
    tooltip,
    help,
    validateStatus,
    validateDecimals,
    noBottomMargin,
}: Props) => (
    <StyledFormItem
        name={name}
        label={
            tooltip ? (
                <InputLabelWithTooltip label={label || placeholder} tooltip={tooltip} />
            ) : (
                label || placeholder
            )
        }
        help={help}
        validateStatus={validateStatus}
        rules={[{ required, message: requiredMessage || `${label || placeholder} is required` }]}
        hidden={hidden}
        nobottommargin={noBottomMargin ? 1 : 0}
    >
        <InputWrapper
            size="large"
            placeholder={placeholder || label}
            disabled={disabled}
            hidden={hidden}
            autoComplete={autoComplete}
            precision={validateDecimals ? 2 : undefined}
        />
    </StyledFormItem>
);
