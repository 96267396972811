import { Collapse } from 'antd';
import styled from 'styled-components';
import { UploadFile } from 'antd/lib/upload/interface';

import { benefitTemplateTooltips } from 'common/tooltips/benefitTemplateTooltips';
import { Upload } from 'common/components';

const CollapseWrapper = styled(Collapse)`
    margin-bottom: 24px;
`;

export type TemplateFiles = {
    evidenceAssessorTemplate?: UploadFile;
    evidenceSupplierTemplate?: UploadFile;
    evidencePartnerTemplate?: UploadFile;
    scoreAssessorTemplate?: UploadFile;
    scoreSupplierTemplate?: UploadFile;
};

type Props = {
    files: TemplateFiles;
    onUpdateTemplateFile: (id: string, file: UploadFile) => void;
};

export const TemplateFilesFields = (props: Props) => {
    const handleUpdate = (name: string, files: UploadFile[]) =>
        props.onUpdateTemplateFile(name, files[0]);

    return (
        <CollapseWrapper>
            <Collapse.Panel header="Template Files" key="0">
                <Upload
                    name="evidenceAssessorTemplate"
                    label="Contributor Evidence File"
                    tooltip={benefitTemplateTooltips.ProjectManagerEvidenceTemplate}
                    file={props.files.evidenceAssessorTemplate}
                    onUpload={handleUpdate}
                />
                <Upload
                    name="evidenceSupplierTemplate"
                    label="Supplier Evidence File"
                    tooltip={benefitTemplateTooltips.SupplierEvidenceTemplate}
                    file={props.files.evidenceSupplierTemplate}
                    onUpload={handleUpdate}
                />
                <Upload
                    name="evidencePartnerTemplate"
                    label="Delivery Partner Evidence File"
                    tooltip={benefitTemplateTooltips.DeliveryPartnerEvidenceTemplate}
                    file={props.files.evidencePartnerTemplate}
                    onUpload={handleUpdate}
                />
                <Upload
                    name="scoreAssessorTemplate"
                    label="Contributor Feedback File"
                    tooltip={benefitTemplateTooltips.ProjectManagerScoreTemplate}
                    file={props.files.scoreAssessorTemplate}
                    onUpload={handleUpdate}
                />
                <Upload
                    name="scoreSupplierTemplate"
                    label="Supplier Feedback File"
                    tooltip={benefitTemplateTooltips.SupplierScoreTemplate}
                    file={props.files.scoreSupplierTemplate}
                    onUpload={handleUpdate}
                />
            </Collapse.Panel>
        </CollapseWrapper>
    );
};
