import * as React from 'react';
import { Button, Alert } from 'antd';
import styled from 'styled-components';

import { SubmitButton } from 'common/components';
import { XLSMimeType } from 'config/config';
import {
    Antd3Icon,
    StyledAntd3Form,
    Antd3Form,
    Antd3FormProps,
} from 'common/components/deprecated/antd3';
import { UploadFile } from 'common/components/deprecated/form';

type ImportFormProps = {
    header: string;
    href: string;
    helpUrl: string;
    onSubmit(event: React.FormEvent<HTMLFormElement>): void;
    processing: boolean;
    importError?: boolean;
} & Antd3FormProps;

const Wrapper = styled(Antd3Form.Item)`
    .ant-btn {
        width: 100%;
        &:last-child {
            margin-top: 10px;
        }
    }
    margin-bottom: 5px;
`;

export const ImportForm: React.FunctionComponent<ImportFormProps> = ({
    header,
    form,
    href,
    onSubmit,
    helpUrl,
    processing,
    importError,
}) => (
    <StyledAntd3Form onSubmit={onSubmit} header={header}>
        <Wrapper>
            <Button
                icon={<Antd3Icon type="download" />}
                href={`https://storage.googleapis.com/cenefits-static-assets/templates/${href}.xls`}
                target="blank"
                rel="noopener noreferrer"
            >
                Download XLS Template
            </Button>
            <Button
                icon={<Antd3Icon type="download" />}
                href={`https://storage.googleapis.com/cenefits-static-assets/templates/${href}.ods`}
                target="blank"
                rel="noopener noreferrer"
            >
                Download ODS Template
            </Button>
        </Wrapper>
        <Wrapper>
            <a href={helpUrl} target="blank" rel="noopener noreferrer">
                Click here to read about how you should populate the template file.
            </a>
        </Wrapper>
        <UploadFile
            description="Browse"
            form={form}
            required
            disabled={processing}
            accept={XLSMimeType}
        />
        <Wrapper>
            <SubmitButton value="Import" disabled={processing} loading={processing} />
        </Wrapper>
        {importError && (
            <Wrapper>
                <Alert
                    message="There were some errors when importing"
                    description="Please correct them and then try again"
                    type="error"
                    showIcon
                />
            </Wrapper>
        )}
    </StyledAntd3Form>
);
