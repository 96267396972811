import * as React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import {
    EmployeeInvitation,
    ResendRoleInvitation,
    DeleteRoleInvitation,
} from 'features/invite/models/inviteModel';
import { RoleInvitation } from 'features/contract/models/contractModels';

const ButtonWrapper = styled.div`
    display: flex;
    #delete-button {
        margin-left: 10px;
    }
`;

export const pickColumnsForInvitationList = (
    resendInvitation: (id: number | ResendRoleInvitation) => void,
    deleteInvitation: (id: string | DeleteRoleInvitation) => void,
    forEmployee?: boolean,
    forExistingOrganisation?: boolean,
    forRole?: boolean,
) => {
    const deleteButtonColumn = {
        title: 'Delete',
        key: 'id',
        render: (id: string) => <Button onClick={() => deleteInvitation(id)}>Delete</Button>,
    };

    const columnsForEmployee = [
        {
            title: 'Administrator',
            key: 'isAdministrator',
            render: (value: string) => <span>{value ? 'Yes' : 'No'}</span>,
        },
        { title: 'Phone', key: 'telephone' },
    ];

    const nameColumn = {
        title: 'Name',
        key: 'lastName',
        render: (_value: string, item: EmployeeInvitation) => (
            <span>
                {item.firstName} {item.lastName}
            </span>
        ),
    };

    const organisationColumn = {
        title: 'Organisation',
        key: 'organisation',
        render: (_value: string, item: RoleInvitation) => {
            const role = item.awaitingRoles[0];
            return <span key={role.executive}>{role.executive}</span>;
        },
    };

    const emailColumn = { title: 'Email', key: 'email' };

    const executiveNameColumn = {
        title: 'Executive Name',
        key: 'executiveName',
    };

    const resendEmailRoleInvitationColumn = {
        title: 'Invitation',
        key: 'id',
        render: (_id: string, item: RoleInvitation) => {
            const role = item.awaitingRoles[0];
            return (
                <Button
                    onClick={() =>
                        resendInvitation({
                            invitationId: role.id,
                            organisationId: item.organisation,
                            benefitId: role.benefit,
                            contractId: role.contract,
                        })
                    }
                >
                    Resend
                </Button>
            );
        },
    };

    const actionColumnForEmployee = {
        title: 'Invitation',
        key: 'id',
        render: (id: number) => (
            <ButtonWrapper>
                <Button key={id} onClick={() => resendInvitation(id)}>
                    Resend
                </Button>
                <Button id="delete-button" onClick={() => deleteInvitation(String(id))}>
                    Delete
                </Button>
            </ButtonWrapper>
        ),
    };

    if (forEmployee) {
        return [nameColumn, emailColumn, ...columnsForEmployee, actionColumnForEmployee];
    }

    if (forExistingOrganisation) {
        return [executiveNameColumn, emailColumn, nameColumn, deleteButtonColumn];
    }

    if (forRole) {
        return [nameColumn, emailColumn, organisationColumn, resendEmailRoleInvitationColumn];
    }

    return [executiveNameColumn, deleteButtonColumn];
};
