import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import { ClearFilters, SaveFilters } from 'features/pagination/actions/paginationActions';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { PaginationView } from 'features/pagination/models/paginationModel';

const TableRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ItemLabel = styled.span`
    flex: 2;
    text-overflow: ellipsis;
`;

const RowActions = styled.div`
    display: flex;
    flex-wrap: wrap;
    button {
        flex: 1;
        margin: 5px;
    }
`;

interface FilterHandlers {
    clearFilters: (paginatedView: PaginationView) => ClearFilters;
    saveFilters: (filters: PaginationFilters) => SaveFilters;
}

interface TableRowProps {
    label: string;
    edit: PaginationFilters;
    download: PaginationFilters;
    handlers: FilterHandlers;
}

interface ActionBtnProps {
    label: string;
    data: PaginationFilters;
    handlers: FilterHandlers;
}

export const ActionableTableRow: React.FC<TableRowProps> = ({
    label,
    edit,
    download,
    handlers,
}) => (
    <TableRow>
        <ItemLabel>{label}</ItemLabel>
        <RowActions>
            <ActionButton label={'Edit'} data={edit} handlers={handlers} />
            <ActionButton label={'Download'} data={download} handlers={handlers} />
        </RowActions>
    </TableRow>
);

const ActionButton: React.FC<ActionBtnProps> = ({
    label,
    data,
    handlers: { clearFilters, saveFilters },
}) => (
    <Button
        size="small"
        onClick={() => {
            clearFilters(data.paginatedView);
            saveFilters(data);
        }}
    >
        {label}
    </Button>
);
