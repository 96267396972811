import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { FullPageSpinner } from 'common/components';
import { redirectToUrl } from 'common/actions/navigationAction';
import { routeParams, getRouteId } from 'common/selectors/routeSelectors';
import { Profile } from 'features/user/models/userModel';
import { getExecutivesRequest } from 'features/organisation/actions/organisationActions';
import { getEmployeesRequest } from 'features/user/actions/userActions';
import { isAdmin } from 'features/user/selectors/userSelectors';

import {
    Contract,
    ContractDetailsRouteParams,
    OrganisationRole,
    ContractPriorities,
} from '../models/contractModels';
import { ContractDetails as ContractDetailsComponent } from '../components/ContractDetails';
import {
    deleteContractRequest,
    getContractRequest,
    getContractRolesRequest,
    getContractPrioritiesRequest,
    getContractFilesRequest,
} from '../actions/contractActions';
import {
    getContractByRouteId,
    getFetchingContractsStatus,
    getContractManagers,
    getContractSuppliers,
    getContractPriorities,
    isContractManager,
} from '../selectors/contractSelectors';

interface ContractDetailsStateProps {
    contractId: number;
    contract?: Contract;
    contractManagers: Profile[];
    contractSuppliers: OrganisationRole[];
    isFetching: boolean;
    priorities: ContractPriorities[];
    isContractManager: boolean;
    isAdmin: boolean;
}

interface ContractDetailsDispatchProps {
    deleteContractRequest: typeof deleteContractRequest;
    getContractRequest: typeof getContractRequest;
    redirectToUrl: typeof redirectToUrl;
    getEmployeesRequest: typeof getEmployeesRequest;
    getExecutivesRequest: typeof getExecutivesRequest;
    getContractRolesRequest: typeof getContractRolesRequest;
    getContractPrioritiesRequest: typeof getContractPrioritiesRequest;
    getContractFilesRequest: typeof getContractFilesRequest;
}

export type ContractDetailsProps = ContractDetailsStateProps &
    ContractDetailsDispatchProps &
    ContractDetailsRouteParams;

class ContractDetails extends React.Component<ContractDetailsProps> {
    public componentDidMount(): void {
        this.props.getContractRequest({ contractId: this.props.contractId });
        this.props.getContractRolesRequest(this.props.contractId);
        this.props.getEmployeesRequest();
        this.props.getExecutivesRequest();
        this.props.getContractPrioritiesRequest(this.props.contractId);
        this.props.getContractFilesRequest({
            contractId: this.props.contractId,
            organisationId: this.props.contract?.organisation,
        });
    }

    public render(): JSX.Element {
        const { isFetching } = this.props;

        return isFetching ? <FullPageSpinner /> : <ContractDetailsComponent {...this.props} />;
    }
}

const mapStateToProps = (
    state: AppState,
    ownProps: ContractDetailsRouteParams,
): ContractDetailsStateProps => ({
    contractId: getRouteId(ownProps),
    contract: getContractByRouteId(state, routeParams(ownProps)),
    isFetching: getFetchingContractsStatus(state),
    contractManagers: getContractManagers(state),
    contractSuppliers: getContractSuppliers(state),
    priorities: getContractPriorities(state),
    isContractManager: isContractManager(state),
    isAdmin: isAdmin(state),
});

export const ContractDetailsContainer = connect(mapStateToProps, {
    deleteContractRequest,
    getContractRequest,
    redirectToUrl,
    getExecutivesRequest,
    getEmployeesRequest,
    getContractRolesRequest,
    getContractPrioritiesRequest,
    getContractFilesRequest,
})(ContractDetails);
