import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';

import { redirectToUrl } from 'common/actions/navigationAction';
import {
    getContractsWithRag,
    getDateRangeContractDashboard,
} from 'features/contract/selectors/contractSelectors';
import { ContractWithRAG } from 'features/contract/models/contractModels';
import { isLoadingRole, isTenant, isAdmin } from 'features/user/selectors/userSelectors';
import { saveFilters, clearFilters } from 'features/pagination/actions/paginationActions';
import { PaginationView } from 'features/pagination/models/paginationModel';

import { ContractDashboardComponent } from '../components/ContractDashboard';
import { DashboardPaths } from '../models/dashboardModel';

interface ContractDashboardContainerStateProps {
    loading: boolean;
    isUserTenant: boolean;
    isUserAdmin: boolean;
    contracts: ContractWithRAG[];
    dateRange: string[];
}

interface ContractDashboardContainerDispatchProps {
    redirectToUrl: typeof redirectToUrl;
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
    getDateRangeContractDashboard: typeof getDateRangeContractDashboard;
}
export type ContractDashboardContainerProps = ContractDashboardContainerStateProps &
    ContractDashboardContainerDispatchProps;

export class ContractDashboard extends React.Component<ContractDashboardContainerProps> {
    constructor(props: ContractDashboardContainerProps) {
        super(props);
        const today = moment().format();
        const threeMonthsAgo = moment().subtract(3, 'months').format();
        this.props.saveFilters({
            paginatedView: PaginationView.ContractRAGDashboard,
            values: {
                contractDateRangeAfter:
                    this.props.dateRange.length > 0
                        ? this.props.dateRange[0] == null
                            ? undefined
                            : this.props.dateRange[0]
                        : threeMonthsAgo,
                contractDateRangeBefore:
                    this.props.dateRange.length > 0
                        ? this.props.dateRange[1] == null
                            ? undefined
                            : this.props.dateRange[1]
                        : today,
            },
            path: DashboardPaths.Contracts,
        });
    }

    public render(): JSX.Element {
        return <ContractDashboardComponent {...this.props} redirect={this.props.redirectToUrl} />;
    }
}

const mapStateToProps = createSelector(
    isLoadingRole,
    isAdmin,
    isTenant,
    getContractsWithRag,
    getDateRangeContractDashboard,
    (
        loading,
        isUserAdmin,
        isUserTenant,
        contracts,
        dateRange,
    ): ContractDashboardContainerStateProps => ({
        loading,
        isUserAdmin,
        isUserTenant,
        contracts,
        dateRange,
    }),
);

export const ContractDashboardContainer = connect(mapStateToProps, {
    redirectToUrl,
    saveFilters,
    clearFilters,
})(ContractDashboard);
