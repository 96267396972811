import { CustomRouteProps } from 'common/models/navigationModel';

import { InviteEmployeeContainer } from '../containers/InviteEmployeeContainer';
import { InviteOrganisationContainer } from '../containers/InviteOrganisationContainer';
import { SupplierInviteUsersContainer } from '../containers/SupplierInviteUsersContainer';
import { InvitePaths, SupplierInvitePaths } from '../models/inviteModel';

enum InvitePageTitles {
    Organisation = 'Organisation',
    Employee = 'Employee',
}

const menuLinks = [
    { key: InvitePaths.Organisation, label: InvitePageTitles.Organisation },
    {
        key: InvitePaths.Employee,
        label: InvitePageTitles.Employee,
        additionalActiveKeys: [InvitePaths.ExpiredInvitations],
    },
];

const baseInviteRoutes: CustomRouteProps[] = [
    {
        path: InvitePaths.Organisation,
        component: InviteOrganisationContainer,
    },
    {
        path: `${InvitePaths.Employee}/:pageNumber?`,
        component: InviteEmployeeContainer,
    },
    {
        path: `${InvitePaths.ExpiredInvitations}/:pageNumber?`,
        component: InviteEmployeeContainer,
    },
].map((route) => ({
    ...route,
    menuLinks,
}));

const supplierInviteRoutes = [
    {
        path: `${SupplierInvitePaths.Invite}/:pageNumber?`,
        component: SupplierInviteUsersContainer,
    },
    {
        path: `${SupplierInvitePaths.ExpiredInvitations}/:pageNumber?`,
        component: SupplierInviteUsersContainer,
    },
];

export const inviteRoutes = [...baseInviteRoutes, ...supplierInviteRoutes];
