import * as React from 'react';
import { Redirect } from 'react-router';

import { generateColumns } from 'common/helpers/utils';
import { UploadPaths } from 'features/upload/models/uploadModels';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getRagBenefitTemplateSuccess } from 'features/benefit/actions';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { SaveFilters, ClearFilters } from 'features/pagination/actions/paginationActions';
import { EditPaths } from 'features/edit/models/editModel';
import { DownloadPaths } from 'features/download/models/downloadModel';
import { PaddedSection } from 'common/components';

import { BenefitTemplateDashboardContainerProps } from '../containers/BenefitTemplateDashboardContainer';
import { DashboardPaths } from '../models/dashboardModel';
import { DashboardTableFiltersBar } from './DashboardTableFiltersBar';
import { ActionableTableRow } from './ActionableTableRow';

interface BenefitTemplateDashboardDispatchProps {
    redirect(path: string): void;
}

type Props = BenefitTemplateDashboardContainerProps & BenefitTemplateDashboardDispatchProps;

const benefitTemplateDashboardColumns = (
    clearFilters: (paginatedView: PaginationView) => ClearFilters,
    saveFilters: (filters: PaginationFilters) => SaveFilters,
) => [
    {
        title: 'Benefit Outcome',
        key: 'outcome',
        render: (outcome: string) => (
            <ActionableTableRow
                label={outcome}
                edit={{
                    paginatedView: PaginationView.Benefits,
                    values: { outcome },
                    path: EditPaths.Benefits,
                }}
                download={{
                    paginatedView: PaginationView.DownloadBenefits,
                    values: { outcome },
                    path: DownloadPaths.Benefits,
                }}
                handlers={{ clearFilters, saveFilters }}
            />
        ),
    },
    {
        title: 'Category',
        key: 'categoryName',
    },
    {
        title: 'Benefits Delivered',
        key: 'deliveredBenefitCount',
    },
    {
        title: 'Points',
        key: 'points',
    },
    {
        title: 'Value',
        key: 'value',
    },
    {
        title: 'Emissions Savings',
        key: 'emissionSavings',
    },
];

export class BenefitTemplateDashboardComponent extends React.Component<Props> {
    public render(): JSX.Element {
        const { loading, isUserTenant } = this.props;

        if (!loading && !isUserTenant) {
            return <Redirect to={UploadPaths.PendingEvidence} />;
        }

        return (
            <PaddedSection header="Benefit Template Outcomes">
                <DashboardTableFiltersBar
                    filterPageUrl={DashboardPaths.BenefitTemplatesFilter}
                    filterId={PaginationView.BenefitTemplateRAGDashboard}
                />
                <PaginatedTable
                    data={this.props.benefitTemplates}
                    columns={generateColumns(
                        benefitTemplateDashboardColumns(
                            this.props.clearFilters,
                            this.props.saveFilters,
                        ),
                    )}
                    emptyText="No benefits found"
                    view={PaginationView.BenefitTemplateRAGDashboard}
                    paginationSuccessCallback={getRagBenefitTemplateSuccess}
                />
            </PaddedSection>
        );
    }
}
