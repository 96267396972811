import { EditPaths } from 'features/edit/models/editModel';
import { ContentMenu } from 'common/components';

interface Props {
    benefitId: number;
    contractId: number;
}

const menuLinks = [
    {
        key: EditPaths.FiltersContractCategories,
        label: 'Project Category',
    },
    {
        key: EditPaths.FiltersContractPriorities,
        label: 'Project Priorities',
    },
    {
        key: EditPaths.FiltersBenefitCategories,
        label: 'Benefit Category',
    },
    {
        key: EditPaths.FiltersBenefitPriorities,
        label: 'Benefit Priorities',
    },
    {
        key: EditPaths.FiltersDepartments,
        label: 'Department',
    },
    {
        key: EditPaths.FiltersLocations,
        label: 'Location',
    },
];

export const EditFiltersNavigation = () => <ContentMenu menuLinks={menuLinks} small />;
