import * as actions from '../actions/authActions';
import { GET_USER_SUCCEED } from 'features/user/actions/userActions';
import { AppAction } from 'common/actions/appAction';

export interface AuthState {
    isAuthenticated: boolean;
    isTokenChecked: boolean;
    isUserVerified: boolean;
    newAccountToken: string;
    isProcessing: boolean;
    userExists?: boolean;
}

const defaultLogInState = {
    isAuthenticated: false,
    isTokenChecked: false,
    isUserVerified: false,
    isProcessing: false,
    newAccountToken: '',
};

export const authReducer = (state: AuthState = defaultLogInState, action: AppAction) => {
    switch (action.type) {
        case actions.LOG_IN_REQUESTED:
            return {
                ...state,
                isProcessing: true,
            };
        case GET_USER_SUCCEED:
            return {
                ...state,
                isAuthenticated: true,
                isTokenChecked: true,
                isProcessing: false,
                userExists: undefined,
            };
        case actions.LOG_IN_FAILED:
            return {
                ...state,
                isAuthenticated: false,
                isProcessing: false,
            };
        case actions.LOG_OUT_REQUESTED:
            return {
                ...state,
                isAuthenticated: false,
                isTokenChecked: true,
            };
        case actions.REFRESH_TOKEN_SUCCEED:
        case actions.REFRESH_TOKEN_FAILED:
            return {
                ...state,
                isTokenChecked: true,
                isProcessing: false,
            };
        case actions.VERIFY_NEW_EMPLOYEE_SUCCEED:
            const { userExists } = action.payload!;

            return {
                ...state,
                userExists,
                isUserVerified: true,
            };

        case actions.VERIFY_NEW_EXECUTIVE_SUCCEED:
            return {
                ...state,
                isUserVerified: true,
            };

        case actions.VERIFY_EXISTING_EXECUTIVE_SUCCEED:
            return {
                ...state,
                isUserVerified: true,
            };

        default:
            return state;
    }
};
