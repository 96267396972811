import { map, tap, pluck, ignoreElements } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { AppEpic } from 'common/epics/appEpic';
import { history } from 'config/config';
import { redirectToUrl, REDIRECT_TO_URL } from 'common/actions/navigationAction';
import * as authActions from 'features/auth/actions/authActions';
import * as userActions from 'features/user/actions/userActions';
import * as paginationActions from 'features/pagination/actions/paginationActions';

export const navigationEpicFactory = (): AppEpic => {
    const redirectEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(REDIRECT_TO_URL),
            pluck('payload'),
            tap((path) =>
                history.push({
                    pathname: path,
                    state: {
                        from: location.pathname,
                    },
                }),
            ),
            ignoreElements(),
        );

    const redirectToLogInPageEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(
                authActions.VERIFY_NEW_EMPLOYEE_FAILED,
                authActions.VERIFY_NEW_EXECUTIVE_FAILED,
                authActions.ACTIVATE_ACCOUNT_SUCCEED,
                authActions.ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_SUCCEED,
                userActions.CREATE_ACCOUNT_SUCCEED,
                userActions.SEND_RESET_PASSWORD_SUCCEED,
                userActions.VERIFY_PASSWORD_RESET_TOKEN_FAILED,
                userActions.RESET_PASSWORD_CHANGE_SUCCEED,
            ),
            map(() => redirectToUrl('/')),
        );

    const redirectAfterFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(paginationActions.SAVE_FILTERS),
            pluck('payload'),
            map(({ path }) => redirectToUrl(path)),
        );

    return combineEpics(redirectEpic, redirectToLogInPageEpic, redirectAfterFilterEpic);
};
