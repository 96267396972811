import { inject, injectable } from 'inversify';

import { HttpService } from 'common/services/HttpService';
import { SERVICE } from 'config/identifiers';
import { config } from 'config/config';

@injectable()
export class ImportService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    public importContracts(file: File): Promise<void> {
        return this.http.formPOST(config.routeConfig.importContracts, { file }, true);
    }

    public importBenefits(file: File): Promise<void> {
        return this.http.formPOST(config.routeConfig.importBenefits, { file }, true);
    }

    public importBenefitTemplates(file: File): Promise<void> {
        return this.http.formPOST(config.routeConfig.importBenefitTemplates, { file }, true);
    }
}
