export const benefitTemplateTooltips = {
    Outcome: `The title or purpose of the Benefit.`,
    Description: `A more detailed description of what is expected and how it is to be delivered.`,
    BenefitCategory: `A filter which you can define. Some organisations refer to this as a "theme".`,
    NumberOfPointsHint: `A value you can add to provide a hint to a Project Manager about the number of points to be associated with related
                        Templated Benefits.`,
    BenefitValueHint: `A value you can add to provide a hint to a Project Manager about the monetary value to be associated with related
                        Templated Benefits.`,
    EmissionsSavingsHint: `A value you can add to provide a hint to a Project Manager about the greenhouse gas emissions savings to be
                            associated with related Templated Benefits.`,
    OutcomeInventory: `The number of Benefits of this type that can be created. You can use this to impose limits on Templated Benefits, to
                        ensure that you deliver a good mix of Benefits in your Contracts. If you don't want to use this feature, you can just
                        enter a very high figure in this box.`,
    OutcomePriority: `A filter which allows you to specify the priority with which you want Benefit Templates to be considered by your colleagues
                    when selecting them for a Contract.`,
    ProjectManagerEvidenceTemplate: `A document which you can upload, that will be visible to a Contributor on the Submit Evidence page for
                                    related Templated Benefits they are assigned to. You can use this to provide a formal structure to the evidence
                                    you want them to give.`,
    ProjectManagerEvidenceText: `Free text that will be visible to a Contributor in the Evidence box on the Submit Evidence page for related
                                Templated Benefits they are assigned to. You can use this to provide a formal structure to the evidence you want
                                them to give.`,
    SupplierEvidenceTemplate: `A document which you can upload, that will be visible to an Supplier on the Submit Evidence page for related Templated
                                Benefits they are assigned to. You can use this to provide a formal structure to the evidence you want them to give.`,
    SupplierEvidenceText: `Free text that will be visible to a Supplier in the Evidence box on the Submit Evidence page for related Templated
                            Benefits they are assigned to. You can use this to provide a formal structure to the evidence you want them to give.`,
    DeliveryPartnerEvidenceTemplate: `A document which you can upload, that will be visible to a Delivery Partner on the Submit Evidence page for 
                                        related Templated Benefits they are assigned to. You can use this to provide a formal structure to the
                                        evidence you want them to give.`,
    DeliveryPartnerEvidenceText: `Free text that will be visible to a Delivery Partner in the Evidence box on the Submit Evidence page for related
                                    Templated Benefits they are assigned to. You can use this to provide a formal structure to the evidence you want
                                    them to give.`,
    ProjectManagerScoreTemplate: `A document which you can upload, that will be visible to a Contributor on the Upload Feedback page for related
                                    Templated Benefits they are assigned to. You can use this to provide a formal structure to the feedback 
                                    you want them to give.`,
    ProjectManagerScoreText: `Free text that will be visible to a Contributor in the Feedback Explanation box on the Upload Feedback page for related
                                Templated Benefits they are assigned to. You can use this to provide a formal structure to the feedback you want 
                                them to give.`,
    SupplierScoreTemplate: `A document which you can upload, that will be visible to a Supplier on the Upload Feedback page for related Templated 
                            Benefits they are assigned to. You can use this to provide a formal structure to the feedback you want them to give.`,
    SupplierScoreText: `Free text that will be visible to a Supplier in the Feedback Explanation box on the Upload Feedback page for related Templated
                        Benefits they are assigned to. You can use this to provide a formal structure to the feedback you want them to give.`,
};
