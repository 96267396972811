import { useForm } from 'antd/lib/form/Form';
import { Form, Input, Select } from 'common/components';
import { capitalize } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceDataRequest, saveInvoiceDataRequest } from '../actions/userActions';
import { AddressType, InvoiceData } from '../models/userModel';
import { getInvoiceData } from '../selectors/userSelectors';
import { config, route } from 'config/config';

export const InvoiceForm = () => {
    const [form] = useForm<InvoiceData>();
    const dispatch = useDispatch();
    const invoiceData = useSelector(getInvoiceData);

    const handleOnFinish = (values: InvoiceData) => {
        dispatch(saveInvoiceDataRequest(values));
    };

    useEffect(() => {
        dispatch(getInvoiceDataRequest());
    }, []);

    useEffect(() => form.resetFields(), [invoiceData]);
    return (
        <Form
            form={form}
            onFinish={handleOnFinish}
            header="Invoice Data"
            submitButtonText="Save Invoice Data"
            initialValues={invoiceData}
        >
            <Input name="firstName" label="First Name" key="firstName" />
            <Input name="lastName" label="Last Name" />
            <Input name="emailAddress" label="E-mail Address" />
            <Select
                name="addressType"
                label="Address Type"
                options={Object.values(AddressType).map((type) => ({
                    label: capitalize(type),
                    value: type,
                }))}
            />
            <Input name="city" label="City" />
            <Input name="region" label="Region" />
            <Input name="postalCode" label="Postal Code" />
            <Input name="country" label="Country" required={false} />
            <Input name="addressLine1" label="Address line 1" />
            <Input name="addressLine2" label="Address line 2" required={false} />
            <Input name="addressLine3" label="Address line 3" required={false} />
            <Input name="addressLine4" label="Address line 4" required={false} />
        </Form>
    );
};
