import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { importBenefitTemplatesRequest } from 'features/import/actions/importActions';
import { redirectToUrl } from 'common/actions/navigationAction';

import { ImportBenefitTemplates as ImportBenefitTemplatesComponent } from '../components/ImportTemplatedBenefits';
import { getProcessingImportStatus, getImportErrors } from '../selectors/importSelectors';
import { isAdmin } from 'features/user/selectors/userSelectors';

export interface ImportBenefitTemplatesContainerDispatchProps {
    importBenefitTemplatesRequest: typeof importBenefitTemplatesRequest;
    redirectToUrl: typeof redirectToUrl;
}

export interface ImportBenefitTemplatesContainerStateProps {
    isProcessing: boolean;
    errors: string[];
    isUserAdmin: boolean;
}

export type ImportBenefitTemplatesContainerProps = ImportBenefitTemplatesContainerDispatchProps &
    ImportBenefitTemplatesContainerStateProps;

const ImportBenefitTemplates: React.FunctionComponent<ImportBenefitTemplatesContainerProps> = (
    props,
) => <ImportBenefitTemplatesComponent {...props} />;

const mapStateToProps = createSelector(
    getProcessingImportStatus,
    getImportErrors,
    isAdmin,
    (isProcessing, errors, isUserAdmin): ImportBenefitTemplatesContainerStateProps => ({
        isProcessing,
        errors,
        isUserAdmin,
    }),
);

export const ImportBenefitTemplatesContainer = connect(mapStateToProps, {
    importBenefitTemplatesRequest,
    redirectToUrl,
})(ImportBenefitTemplates);
