import { Form, Tag } from 'antd';
import styled from 'styled-components';

import { Checkbox } from 'common/components';
import { actionPropsByAction } from 'features/upload/helpers';
import { BenefitChangeSuggestionsAcceptanceFormDeliveryDate } from 'features/upload/models/uploadModels';
import * as S from 'features/upload/components/BenefitChangeSuggestionsFormItem/shared/styles';

const ApproveCheckboxWrapper = styled.div`
    position: absolute;
    left: -1.8rem;
    top: 1.2rem;
`;

type Props = {
    name: number;
};

export const DeliveryDateSuggestionAcceptanceCard = ({ name }: Props) => {
    const deliveryDate = Form.useWatch(['deliveryDates', name]) as
        | BenefitChangeSuggestionsAcceptanceFormDeliveryDate
        | undefined;

    if (!deliveryDate) {
        return null;
    }

    const isTitleUpdated = deliveryDate.prevTitle && deliveryDate.prevTitle !== deliveryDate.title;
    const isDateUpdated = deliveryDate.prevDate && deliveryDate.prevDate !== deliveryDate.date;
    const isUpdated = deliveryDate.action === 'update' && (isTitleUpdated || isDateUpdated);

    const actionProps = deliveryDate.action && actionPropsByAction[deliveryDate.action];
    const displayChange = actionProps && (deliveryDate.action !== 'update' || isUpdated);

    return (
        <S.SuggestionWrapper color={displayChange ? actionProps.color : undefined}>
            {displayChange && (
                <S.ChangeInfo>
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {isUpdated && (
                            <span>
                                {isTitleUpdated
                                    ? ` | Previous title: "${deliveryDate.prevTitle}"`
                                    : null}
                                {isDateUpdated
                                    ? ` | Previous date: ${deliveryDate.prevDate}`
                                    : null}
                            </span>
                        )}
                    </Tag>
                </S.ChangeInfo>
            )}
            {deliveryDate.isFromSuggestion && displayChange && (
                <ApproveCheckboxWrapper>
                    <Checkbox name={[name, 'isApproved']} />
                </ApproveCheckboxWrapper>
            )}
            <S.InputsWrapper direction="horizontal" align="start">
                <S.SuggestionValue>{deliveryDate.title}</S.SuggestionValue>
                <S.SuggestionValue>{deliveryDate.date}</S.SuggestionValue>
            </S.InputsWrapper>
        </S.SuggestionWrapper>
    );
};
