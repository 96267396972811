import * as React from 'react';
import { Redirect } from 'react-router';

import { UploadPaths } from 'features/upload/models/uploadModels';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { generateColumns } from 'common/helpers/utils';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getBenefitPriorityDashboardSuccess } from 'features/benefit/actions/benefitActions';
import { DownloadPaths } from 'features/download/models/downloadModel';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { SaveFilters, ClearFilters } from 'features/pagination/actions/paginationActions';
import { BenefitPriorityDashboardItem } from 'features/benefit/models/benefitModels';
import { EditPaths } from 'features/edit/models/editModel';
import { PaddedSection } from 'common/components';

import { DashboardTableFiltersBar } from './DashboardTableFiltersBar';
import { BenefitPriorityDashboardContainerProps } from '../containers/BenefitPriorityDashboardContainer';
import { DashboardPaths } from '../models/dashboardModel';
import { ActionableTableRow } from './ActionableTableRow';

interface BenefitPriorityDashboardDispatchProps {
    redirect(path: string): void;
}

type Props = BenefitPriorityDashboardContainerProps & BenefitPriorityDashboardDispatchProps;

const benefitPriorityDashboardColumns = (
    clearFilters: (paginatedView: PaginationView) => ClearFilters,
    saveFilters: (filters: PaginationFilters) => SaveFilters,
) => [
    {
        title: 'Name',
        key: 'name',
        render: (name: string, { id }: BenefitPriorityDashboardItem) => (
            <ActionableTableRow
                label={name}
                edit={{
                    paginatedView: PaginationView.Benefits,
                    values: { priorities: [id.toString()] },
                    path: EditPaths.Benefits,
                }}
                download={{
                    paginatedView: PaginationView.DownloadBenefits,
                    values: { priorities: [id.toString()] },
                    path: DownloadPaths.Benefits,
                }}
                handlers={{ clearFilters, saveFilters }}
            />
        ),
    },
    {
        title: 'Delivered Priority Value',
        key: 'deliveredPriorityValue',
    },
];

export class BenefitPriorityDashboardComponent extends React.Component<Props> {
    public render(): JSX.Element {
        const { loading, isUserTenant } = this.props;

        if (!loading && !isUserTenant) {
            return <Redirect to={UploadPaths.PendingEvidence} />;
        }

        return (
            <PaddedSection header="Benefit Priorities">
                <DashboardTableFiltersBar
                    filterPageUrl={DashboardPaths.BenefitPrioritiesFilter}
                    filterId={PaginationView.BenefitPrioritiesDashboard}
                />
                <PaginatedTable
                    data={this.props.priorities}
                    columns={generateColumns(
                        benefitPriorityDashboardColumns(
                            this.props.clearFilters,
                            this.props.saveFilters,
                        ),
                    )}
                    emptyText="No benefit priorities found"
                    view={PaginationView.BenefitPrioritiesDashboard}
                    paginationSuccessCallback={getBenefitPriorityDashboardSuccess}
                />
            </PaddedSection>
        );
    }
}
