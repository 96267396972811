import * as _ from 'lodash';
import { injectable } from 'inversify';

import { renderErrorMessage } from 'common/services/errorMessage';
import { PaginationQueryParams } from 'features/pagination/models/paginationModel';
import { store } from 'config/configureStore';
import { UserOrganisation } from 'features/user/models/userModel';

import { generateFilterQueryParams, FilterData } from '../helpers/url';

export type requestField = string | File | Blob;

type Error = Record<string, string[]>;

export type RequestHeaders = Record<string, string>;

export type RequestBody = Record<string, requestField>;

type MakeRequestParams = {
    method: string;
    path: string;
    body?: object;
    csv?: boolean;
    requestHeaders?: RequestHeaders;
    newToken?: string;
    allow404?: boolean;
};

type RequestFormParams = {
    method: string;
    path: string;
    body: RequestBody;
    csv?: boolean;
};

type sendRequestParams = {
    path: string;
    options: RequestInit;
    csv?: boolean;
    allow404?: boolean;
};

@injectable()
export class HttpService {
    public GET<R>(
        path: string,
        pagination?: PaginationQueryParams,
        filters?: FilterData,
        allow404?: boolean,
        requestHeaders?: RequestHeaders,
    ): Promise<R> {
        const paginatedPath = pagination
            ? `${path}?limit=${pagination.limit}&offset=${pagination.offset}`
            : path;

        const filterQuery = filters ? generateFilterQueryParams(filters) : '';

        return this.makeRequest({
            method: 'GET',
            path: `${paginatedPath}${filterQuery}`,
            allow404,
            requestHeaders,
        });
    }

    public POST<R = void>(path: string, body?: object, csv?: boolean): Promise<R> {
        return this.makeRequest({ method: 'POST', path, body, csv });
    }

    public PATCH<R = void>(
        path: string,
        body: object,
        newToken?: string,
        csv?: boolean,
        requestHeaders?: RequestHeaders,
    ): Promise<R> {
        return this.makeRequest({ method: 'PATCH', path, body, csv, requestHeaders, newToken });
    }

    public DELETE<R = void>(path: string): Promise<R> {
        return this.makeRequest({ method: 'DELETE', path });
    }

    public formPOST<R = void>(path: string, body: RequestBody, csv?: boolean): Promise<R> {
        return this.requestForm({ method: 'POST', path, body, csv });
    }

    public formPATCH<R = void>(path: string, body: RequestBody): Promise<R> {
        return this.requestForm({ method: 'PATCH', path, body });
    }

    private async makeRequest<R>({
        method,
        path,
        body,
        csv,
        requestHeaders,
        newToken,
        allow404,
    }: MakeRequestParams): Promise<R> {
        const bodyJSON = method === 'GET' ? body : JSON.stringify(body);
        const token = localStorage.getItem('token');

        const organisation = store.getState().user.activeOrganisation as UserOrganisation;
        const organisationId = store.getState().user.activeOrganisation && organisation!.idProfile;

        const headers = requestHeaders || {
            'Content-Type': 'application/json',
        };

        const headersWithProfile = organisationId!
            ? { 'Profile-Id': organisationId, ...headers }
            : headers;

        const tokenHeaders = { ...headersWithProfile, Authorization: `Token ${token || newToken}` };

        const options = {
            body: body && bodyJSON,
            headers: !!token || !!newToken ? tokenHeaders : headers,
            method,
        } as RequestInit;

        return this.sendRequest<R>({ path, options, csv, allow404 });
    }

    private async requestForm<R>({ method, path, body, csv }: RequestFormParams): Promise<R> {
        const token = localStorage.getItem('token');
        const formData = new FormData();

        Object.keys(body).forEach((fieldName) => formData.append(fieldName, body[fieldName]));

        const organisation = store.getState().user.activeOrganisation as UserOrganisation;
        const organisationId = store.getState().user.activeOrganisation && organisation!.idProfile;

        const profileHeader = { 'Profile-Id': organisationId };

        const tokenHeaders = {
            ...(organisationId && profileHeader),
            Authorization: `Token ${token}`,
        };

        const options = {
            body: formData,
            headers: token ? tokenHeaders : null,
            method,
        } as RequestInit;

        return this.sendRequest<R>({ path, options, csv });
    }

    private async sendRequest<R>({ path, options, csv, allow404 }: sendRequestParams): Promise<R> {
        const response = await fetch(path, options);

        if (allow404 && response.status === 404) {
            return Promise.reject(response.statusText);
        }

        if (response.status === 403) {
            window.location.href = '/';
        }

        const errorStatuses = [404, 500];

        if (errorStatuses.includes(response.status)) {
            return renderErrorMessage({ status: response.status, path });
        }

        if (!response.ok) {
            const error: Error = await response.json();

            const values = Object.values(error);
            const errorValues = csv ? values : _.flatten(values);
            const message = csv ? errorValues : { message: errorValues[0] };

            return Promise.reject(message);
        }

        return response.status === 204 || options.headers['content-type'] === 'application/pdf'
            ? response
            : csv
            ? response.blob()
            : response.json();
    }
}
