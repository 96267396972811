import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';

import { notificationText, showNotification } from 'common/helpers/notifications';
import { Form } from 'common/components';
import {
    clearBenefitTemplate,
    createBenefitTemplateRequest,
    editBenefitTemplateFilesRequest,
} from 'features/benefit/actions';
import {
    BenefitQuestionnaires,
    benefitQuestionnairesTypesNames,
    BenefitQuestionnaireType,
} from 'features/benefit/models/questionnaireModels';
import { BenefitTemplateFormValues } from 'features/benefit/models/benefitModels';
import { QuestionnaireTemplate } from 'features/benefit/components/QuestionnaireTemplate/QuestionnaireTemplate';
import { getRequestUploadFile } from 'common/components/form/helpers';
import { getBenefitTemplate } from 'features/benefit/selectors/benefitSelectors';

import { TemplateFiles, TemplateFilesFields } from './components/TemplateFilesFields';
import { BaseFields } from './components/BaseFields';
import { getUserActiveOrganisation } from 'features/user/selectors/userSelectors';
import { Organisation } from 'features/organisation/models/organisationModels';

const generateInitialFormValues = (
    organisation?: Organisation,
): BenefitQuestionnaires | undefined =>
    organisation
        ? {
              [BenefitQuestionnaireType.EVIDENCE_ASSESSOR]:
                  organisation[BenefitQuestionnaireType.EVIDENCE_ASSESSOR],
              [BenefitQuestionnaireType.SCORE_ASSESSOR]:
                  organisation[BenefitQuestionnaireType.SCORE_ASSESSOR],
              [BenefitQuestionnaireType.EVIDENCE_SUPPLIER]:
                  organisation[BenefitQuestionnaireType.EVIDENCE_SUPPLIER],
              [BenefitQuestionnaireType.SCORE_SUPPLIER]:
                  organisation[BenefitQuestionnaireType.SCORE_SUPPLIER],
              [BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER]:
                  organisation[BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER],
          }
        : undefined;

export const CreateBenefitTemplateForm = () => {
    const [form] = useForm<BenefitTemplateFormValues>();
    const dispatch = useDispatch();

    const [templateFiles, setTemplateFiles] = useState<TemplateFiles>({});

    const benefitTemplate = useSelector(getBenefitTemplate);
    const organisation = useSelector(getUserActiveOrganisation);

    useEffect(() => {
        dispatch(clearBenefitTemplate());
    }, []);

    useEffect(() => {
        if (!benefitTemplate) {
            return;
        }

        dispatch(
            editBenefitTemplateFilesRequest({
                id: benefitTemplate.id,
                evidenceAssessorTemplate: getRequestUploadFile(
                    templateFiles.evidenceAssessorTemplate,
                ),
                evidenceSupplierTemplate: getRequestUploadFile(
                    templateFiles.evidenceSupplierTemplate,
                ),
                evidencePartnerTemplate: getRequestUploadFile(
                    templateFiles.evidencePartnerTemplate,
                ),
                scoreAssessorTemplate: getRequestUploadFile(templateFiles.scoreAssessorTemplate),
                scoreSupplierTemplate: getRequestUploadFile(templateFiles.scoreSupplierTemplate),
            }),
        );
    }, [benefitTemplate]);

    const handleOnFinish = (values: BenefitTemplateFormValues) => {
        const filteredValues = values;
        const missingQuestionnaires: string[] = [];

        Object.values(BenefitQuestionnaireType).forEach((questionnaireType) => {
            if (!filteredValues[questionnaireType]?.length) {
                missingQuestionnaires.push(benefitQuestionnairesTypesNames[questionnaireType]);
            }
        });

        if (missingQuestionnaires.length) {
            showNotification({
                text: `You can’t save a Benefit Template when one or more of its questionnaires is missing. Please add a questionnaire for: ${missingQuestionnaires}`,
            });
            return;
        }

        dispatch(
            createBenefitTemplateRequest({
                ...filteredValues,
                pointsHint: filteredValues.pointsHint
                    ? filteredValues.pointsHint.toString()
                    : undefined,
                valueHint: filteredValues.valueHint
                    ? filteredValues.valueHint.toString()
                    : undefined,
                emissionSavingsHint: filteredValues.emissionSavingsHint
                    ? filteredValues.emissionSavingsHint.toString()
                    : undefined,
            }),
        );
    };

    const handleOnFinishFailed = () => showNotification({ text: notificationText.Error });

    const updateTemplateFile = (name: string, file: UploadFile) =>
        setTemplateFiles({
            ...templateFiles,
            [name]: file,
        });

    return (
        <Form
            form={form}
            onFinish={handleOnFinish}
            onFinishFailed={handleOnFinishFailed}
            header="Create Benefit Template"
            showResetButton
            submitButtonText="Create Benefit Template"
            initialValues={generateInitialFormValues(organisation)}
        >
            <BaseFields />
            {Object.values(BenefitQuestionnaireType).map((questionnaireType) => (
                <QuestionnaireTemplate
                    key={questionnaireType}
                    type={questionnaireType}
                    isClearable
                />
            ))}
            <TemplateFilesFields files={templateFiles} onUpdateTemplateFile={updateTemplateFile} />
        </Form>
    );
};
