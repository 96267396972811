import { Radio } from 'antd';

import { InputLabelWithTooltip } from '../../form/components/InputLabelWithTooltip';
import { Antd3Form, Antd3FormModel } from '../antd3';

interface PublishRadioButtonsInterface {
    defaultValue?: boolean;
    required?: boolean;
    tooltip?: string;
}

type Props = PublishRadioButtonsInterface & Antd3FormModel;

export const PublishRadioButtons = ({ defaultValue, form, required = false, tooltip }: Props) => (
    <Antd3Form.Item label={<InputLabelWithTooltip label="Published" tooltip={tooltip} />}>
        {form.getFieldDecorator('published', {
            rules: [{ required, message: 'Please choose unpublished or published' }],
            initialValue: defaultValue,
        })(
            <Radio.Group name="publish">
                <Radio value={false}>Unpublished</Radio>
                <Radio value={true}>Published</Radio>
            </Radio.Group>,
        )}
    </Antd3Form.Item>
);
