import * as React from 'react';
import { Table, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { UserOrganisation } from '../models/userModel';

interface OrganisationSelectListProps {
    organisationsList: UserOrganisation[];
    isFetching: boolean;
    setActiveOrganisation(organisation: UserOrganisation): void;
}

export const OrganisationSelectList: React.FunctionComponent<OrganisationSelectListProps> = ({
    organisationsList,
    setActiveOrganisation,
    isFetching,
}) => {
    const columns: Array<ColumnProps<UserOrganisation>> = [
        {
            key: 'Profile',
            title: 'Profile',
            dataIndex: ['organisation', 'name'],
        },
        {
            title: 'Action',
            key: 'Action',
            render: (record) => (
                <span>
                    <Button type="primary" onClick={() => setActiveOrganisation(record)}>
                        Select
                    </Button>
                </span>
            ),
        },
    ];

    return (
        <Table<UserOrganisation>
            columns={columns}
            dataSource={organisationsList}
            loading={isFetching}
            rowKey={({ idProfile }) => idProfile.toString()}
        />
    );
};
