import { Antd3Form, Antd3Icon, StyledAntd3Form } from 'common/components/deprecated/antd3';
import { useEffect } from 'react';
import { Button, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { generateColumns } from 'common/helpers/utils';
import { Props } from '../containers/SubscriptionContainer';
import { getSubscriptionInvoicesRequest } from '../actions/userActions';
import { getSubscriptionInvoices, getUserActiveOrganisation } from '../selectors/userSelectors';
import { PaddedSection } from 'common/components';
import { Invoice } from '../models/userModel';
import { NavLink } from 'react-router-dom';

export const InvoiceList = ({ isUserAdmin, userSubscription }: Props) => {
    const activeOrganisation = useSelector(getUserActiveOrganisation);
    const { id } = userSubscription;
    const invoiceDownloadPath = `/api/organisations/${activeOrganisation?.id}/subscriptions/`;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSubscriptionInvoicesRequest(id));
    }, [userSubscription]);

    const myInvoices = useSelector(getSubscriptionInvoices);

    const invoiceColumns = [
        {
            title: 'Issued at',
            key: 'issuedAt',
        },
        {
            title: 'Xero Id',
            key: 'xeroInvoiceId',
        },
        {
            title: 'Due at',
            key: 'dueAt',
        },
        {
            title: 'Total amount',
            key: 'total',
        },
        {
            title: '',
            key: 'id',
            render: (_: string, { id }: Invoice) => (
                <NavLink
                    to={`${invoiceDownloadPath}${userSubscription?.id}/invoices/${id}/get-pdf`}
                    target="_blank"
                >
                    <Button size="small" icon={<Antd3Icon type="download" />}>
                        Download
                    </Button>
                </NavLink>
            ),
        },
    ];

    return (
        <PaddedSection>
            <StyledAntd3Form header="My Invoices" bigHeader>
                <Antd3Form.Item>
                    <Table columns={generateColumns(invoiceColumns)} dataSource={myInvoices} />
                </Antd3Form.Item>
            </StyledAntd3Form>
        </PaddedSection>
    );
};
