import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';
import { RouteParams, getRouteId } from 'common/selectors/routeSelectors';
import {
    getContractNotesRequest,
    createContractNoteRequest,
    deleteContractNoteRequest,
    openForm,
    closeForm,
    editContractNoteRequest,
} from 'features/contract/actions/contractActions';
import {
    getContractNotes,
    getContractNoteFormVisibility,
} from 'features/contract/selectors/contractSelectors';
import { getUserOrganisationId } from 'features/user/selectors/userSelectors';

import { ContractNotes as ContractNotesComponent } from '../components/ContractNotes';
import { ContractNoteData } from '../models/contractModels';

export interface ContractNotesContainerStateProps {
    contractNotes: ContractNoteData[];
    contractId: number;
    organisationId: number;
    formVisible: boolean;
}

interface ContractNotesDispatchProps {
    redirectToUrl: typeof redirectToUrl;
    getContractNotesRequest: typeof getContractNotesRequest;
    createContractNoteRequest: typeof createContractNoteRequest;
    deleteContractNoteRequest: typeof deleteContractNoteRequest;
    openForm: typeof openForm;
    closeForm: typeof closeForm;
    editContractNoteRequest: typeof editContractNoteRequest;
}

export type ContractNotesProps = ContractNotesContainerStateProps &
    ContractNotesDispatchProps &
    RouteParams;

class ContractNotes extends React.Component<ContractNotesProps> {
    public componentDidMount(): void {
        this.props.getContractNotesRequest(this.props.contractId);
    }

    public render(): JSX.Element {
        return <ContractNotesComponent {...this.props} />;
    }
}

const mapStateToProps = (
    state: AppState,
    ownProps: RouteParams,
): ContractNotesContainerStateProps => ({
    contractNotes: getContractNotes(state),
    contractId: getRouteId(ownProps),
    organisationId: getUserOrganisationId(state),
    formVisible: getContractNoteFormVisibility(state),
});

export const ContractNotesContainer = connect(mapStateToProps, {
    redirectToUrl,
    getContractNotesRequest,
    createContractNoteRequest,
    deleteContractNoteRequest,
    openForm,
    closeForm,
    editContractNoteRequest,
})(ContractNotes);
