import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    List,
    Modal,
    notification,
    Radio,
    Row,
    Space,
} from 'antd';
import styled from 'styled-components';

import { PaddedSection } from 'common/components';
import { colors, fontSizes } from 'theme/variables';

import { PaymentType, Subscription, SubscriptionPurchaseData } from '../models/userModel';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { getSubscriptionsTiersRequest, PurchaseSubscriptionRequest } from '../actions/userActions';
import { UserState } from '../reducers/userReducer';
import { dateFormat } from 'config/config';

const SubscriptionCard = styled(Card)<{ isCurrent: number }>(
    ({ isCurrent }) => `
    border-color: ${isCurrent ? colors.primary : colors.antdBorder};
    border-width: 2px;
    height:100%;
`,
);

const TierButton = styled(Button)`
    display: block;
    float: right;
    margin: 15px 0 5px 0;
`;

type Props = {
    profile: UserState;
    userSubscription?: Subscription;
};

export const SubscriptionCards = ({ profile, userSubscription }: Props) => {
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [selectedPackageTier, setSelectedPackageTier] = useState<number | undefined>();
    const [modalForm] = useForm<SubscriptionPurchaseData>();
    const dispatch = useDispatch();
    const { subscriptionTiers } = profile;

    useEffect(() => {
        dispatch(getSubscriptionsTiersRequest());
    }, []);

    const handleButtonClick = (tier: number) => () => {
        if (!userSubscription) {
            setSelectedPackageTier(tier);
            setIsPaymentModalOpen(true);
        } else {
            showNotification();
        }
    };

    const handleSubscribe = () => {
        dispatch(
            PurchaseSubscriptionRequest(parseSubscriptionPurchaseData(modalForm.getFieldsValue())),
        );
        handleModalClose();
    };

    const parseSubscriptionPurchaseData = (data?: SubscriptionPurchaseData) => ({
        ...data,
        starting_on: data?.starting_on.format(dateFormat),
        item: selectedPackageTier ? selectedPackageTier : 1,
        type: data?.type.toUpperCase,
    });

    const handleModalClose = () => {
        setSelectedPackageTier(undefined);
        setIsPaymentModalOpen(false);
    };

    const showNotification = () => {
        const args = {
            message: 'Notification',
            description:
                'In order to upgrade your Cenefits subscription, please contact Wildcat Apps',
            duration: 5,
        };
        notification.info(args);
    };

    const renderTierButton = (tier: number) => {
        if (!userSubscription) {
            return <TierButton onClick={handleButtonClick(tier)}>Subscribe</TierButton>;
        }

        return (
            tier > userSubscription.tierIndex + 1 && (
                <TierButton onClick={handleButtonClick(tier)}>Upgrade</TierButton>
            )
        );
    };

    return (
        <>
            <PaddedSection header="Buy/Upgrade Subscription">
                <Row gutter={16}>
                    {subscriptionTiers.map(
                        ({ name, title, maxAvailableNumberOfUsers, price, description, pk }) => (
                            <Col key={title} span={6}>
                                <SubscriptionCard
                                    size="small"
                                    title={name}
                                    isCurrent={userSubscription?.itemTitle === title ? 1 : 0}
                                >
                                    <List>
                                        <List.Item>
                                            Max users: {maxAvailableNumberOfUsers}
                                            <List.Item>Description: {description}</List.Item>
                                            <List.Item>Price: {price}</List.Item>
                                        </List.Item>
                                    </List>
                                    {renderTierButton(pk)}
                                </SubscriptionCard>
                            </Col>
                        ),
                    )}
                </Row>
            </PaddedSection>
            <Modal
                visible={isPaymentModalOpen}
                onCancel={handleModalClose}
                onOk={handleSubscribe}
                okText={userSubscription ? 'Upgrade' : 'Subscribe'}
            >
                Select type of payment:
                <Form form={modalForm}>
                    <Form.Item name="type">
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value={PaymentType.Monthly}>{PaymentType.Monthly}</Radio>
                                <Radio value={PaymentType.Yearly}>{PaymentType.Yearly}</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    Enter number of payment periods
                    <Form.Item name="payment_periods">
                        <Input type="number"></Input>
                    </Form.Item>
                    Select Subscription Start Date
                    <Form.Item name="starting_on">
                        <DatePicker></DatePicker>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
