import styled from 'styled-components';
import { Layout, Button, Divider } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';
import { useDispatch, useSelector } from 'react-redux';

import { breakpoints, colors, fontSizes } from 'theme/variables';
import {
    getActiveOrganisation,
    getOrganisations,
    getUserRole,
} from 'features/user/selectors/userSelectors';
import { toggleUserOrganisationSelectModal } from 'features/user/actions/userActions';

import { MenuContent } from './MenuContent';
import { Logo } from '.';

const Sider = styled(Layout.Sider)`
    display: none;

    ${breakpoints.medium} {
        display: block;
    }
`;

const StyledSiderHeader = styled.div`
    margin: 10px;
`;

const StyledButton = styled((props: NativeButtonProps) => <Button {...props} />)`
    display: block;
    margin: 4px auto 0 auto;
`;

const StyledText = styled.span`
    display: block;
    text-align: center;
    margin-top: 10px;
    color: ${colors.primaryGray};
    font-size: ${fontSizes.small};
    overflow: auto;
    text-overflow: ellipsis;
`;

type Props = {
    hideMenu: boolean;
};

export const SideMenu = ({ hideMenu }: Props) => {
    const dispatch = useDispatch();

    const userRole = useSelector(getUserRole);
    const organisations = useSelector(getOrganisations);
    const activeOrganisation = useSelector(getActiveOrganisation);

    return (
        <Sider>
            <Logo />
            {!hideMenu && userRole && (
                <>
                    <StyledSiderHeader>
                        {organisations.length > 1 && (
                            <StyledButton
                                type="default"
                                onClick={() => dispatch(toggleUserOrganisationSelectModal(true))}
                            >
                                Select Organisation
                            </StyledButton>
                        )}
                        {activeOrganisation && (
                            <>
                                <Divider />
                                <StyledText>{activeOrganisation.organisation.name}</StyledText>
                            </>
                        )}
                        <Divider />
                    </StyledSiderHeader>
                    <MenuContent />
                </>
            )}
        </Sider>
    );
};
