import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import styled from 'styled-components';

import { breakpoints, sizes } from 'theme/variables';

import { Antd3Form } from '../deprecated/antd3';

const SubmitButtonContainer = styled(Antd3Form.Item)`
    && {
        margin-bottom: 0;

        button {
            width: 100%;
        }

        ${breakpoints.small} {
            margin: 0 auto;
            max-width: ${sizes.formWidth};
        }
    }
`;

interface SubmitButtonProps {
    disabled?: boolean;
    isFetching?: boolean;
    value: string;
}

type Props = SubmitButtonProps & ButtonProps;

export const SubmitButton = ({ isFetching, value, disabled = false, ...props }: Props) => (
    <SubmitButtonContainer>
        <Button
            disabled={disabled}
            type="primary"
            htmlType="submit"
            loading={isFetching}
            {...props}
        >
            {value}
        </Button>
    </SubmitButtonContainer>
);
