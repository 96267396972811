export const SERVICE = {
    Auth: Symbol('Auth'),
    Benefit: Symbol('Benefit'),
    Contract: Symbol('Contract'),
    Download: Symbol('Download'),
    Import: Symbol('Import'),
    Http: Symbol('Http'),
    Invite: Symbol('Invite'),
    Organisation: Symbol('Organisation'),
    Upload: Symbol('Upload'),
    User: Symbol('User'),
    Pagination: Symbol('Pagination'),
    PortfolioFilter: Symbol('PortfolioFilter'),
    Storage: Symbol('Storage'),

    benefitEpicFactory: Symbol('benefitEpicFactory'),
    contractEpicFactory: Symbol('contractEpicFactory'),
    downloadEpicFactory: Symbol('downloadEpicFactory'),
    importEpicFactory: Symbol('importEpicFactory'),
    logInEpicFactory: Symbol('logInEpicFactory'),
    rootEpicFactory: Symbol('rootEpicFactory'),
    inviteEpicFactory: Symbol('inviteEpicFactory'),
    pendingInvitationsEpicFactory: Symbol('pendingInvitationsEpicFactory'),
    organisationEpicFactory: Symbol('organisationEpicFactory'),
    uploadEpicFactory: Symbol('uploadEpicFactory'),
    tokenEpicFactory: Symbol('tokenEpicFactory'),
    userEpicFactory: Symbol('userEpicFactory'),
    paginationEpicFactory: Symbol('paginationEpicFactory'),
    portfolioFilterEpicFactory: Symbol('portfolioFilterEpicFactory'),
};
