import { Button, Space } from 'antd';
import styled from 'styled-components';

import { hexToRGBA } from 'common/helpers/utils';
import { colors } from 'theme/variables';

export const SuggestionWrapper = styled.div<{ color?: string }>(
    ({ color }) => `
    position: relative;
    border: 1px solid ${color || colors.borderGray};
    background-color: ${color ? hexToRGBA(color, 0.1) : 'transparent'};
    border-radius: 4px;
    padding: 1.5rem;
    margin-bottom: 1rem;
`,
);

export const ChangeInfo = styled.div`
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
`;

export const SuggestionValue = styled.h3<{ topMargin?: string }>(
    ({ topMargin }) => `
    margin: ${topMargin || 0} 0 0;
`,
);

export const InputsWrapper = styled(Space)`
    width: 100%;

    > .ant-space-item:first-child {
        flex: 1;
    }
`;

export const RemoveButton = styled(Button)`
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
`;
