import { Collapse, Form as AntdForm, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { orderBy, update } from 'lodash';
import { useEffect } from 'react';

import { AutocompleteSelect, Form, InputNumber } from 'common/components';
import { CollapseWrapper } from 'common/components/styled';
import {
    BenefitChangeSuggestions,
    BenefitChangeSuggestionsAcceptanceFormDeliveryDate,
    BenefitChangeSuggestionsAcceptanceFormBenefitPriority,
} from 'features/upload/models/uploadModels';
import {
    Benefit,
    DeliveryDate,
    FetchedBenefitPriority,
} from 'features/benefit/models/benefitModels';
import { editBenefitRequest } from 'features/benefit/actions';

import { DeliveryDateSuggestionAcceptanceCard } from './components/DeliveryDateSuggestionAcceptanceCard';
import { BenefitPrioritySuggestionAcceptanceCard } from './components/BenefitPrioritySuggestionAcceptanceCard';
import { BenefitLocationSuggestionAcceptanceCard } from './components/BenefitLocationSuggestionAcceptanceCard';
import { BenefitValueSuggestionAcceptanceCard } from './components/BenefitValueSuggestionAcceptanceCard';

type Props = {
    contractId: number;
    benefitId: number;
    sharedBenefitForUrl?: number;
    benefitDeliveryDates: DeliveryDate[];
    benefitPriorities: FetchedBenefitPriority[];
    benefitLocation: number;
    benefitValue: number;
    benefitChangeSuggestions: BenefitChangeSuggestions;
};

type BenefitChangeSuggestionsAcceptanceDeliveryDatesFormValues = {
    deliveryDates?: BenefitChangeSuggestionsAcceptanceFormDeliveryDate[];
};

type BenefitChangeSuggestionsAcceptanceBenefitPrioritiesFormValues = {
    benefitPriorities?: BenefitChangeSuggestionsAcceptanceFormBenefitPriority[];
};

const expandedSuggestionPanelIds: string[] = [];

const generateInitialDeliveryDatesFormValues = (
    benefitDeliveryDates: DeliveryDate[],
    benefitChangeSuggestions: BenefitChangeSuggestions,
): BenefitChangeSuggestionsAcceptanceDeliveryDatesFormValues => {
    benefitChangeSuggestions?.deliveryDates && expandedSuggestionPanelIds.push('deliveryDates');

    let deliveryDates: BenefitChangeSuggestionsAcceptanceFormDeliveryDate[] =
        benefitDeliveryDates.map((deliveryDate) => ({
            id: deliveryDate.id,
            isFinal: deliveryDate.isFinal,
            isStart: deliveryDate.isStart,
            prevTitle: deliveryDate.title,
            title: deliveryDate.title,
            prevDate: deliveryDate.date,
            date: deliveryDate.date,
        }));

    benefitChangeSuggestions.deliveryDates?.forEach((suggestion) => {
        switch (suggestion.action) {
            case 'create':
                !deliveryDates.find(({ date }) => suggestion.date == date) &&
                    deliveryDates.push({
                        action: 'create',
                        isFinal: false,
                        isStart: false,
                        title: suggestion.title,
                        date: suggestion.date,
                        isFromSuggestion: true,
                    });
                break;
            case 'delete':
                deliveryDates = deliveryDates.map((deliveryDate) =>
                    deliveryDate.id === suggestion.id
                        ? {
                              ...deliveryDate,
                              action: 'delete',
                              isFromSuggestion: true,
                          }
                        : deliveryDate,
                );
                break;
            case 'update':
                deliveryDates = deliveryDates.map((deliveryDate) =>
                    deliveryDate.id === suggestion.id
                        ? {
                              ...deliveryDate,
                              action: 'update',
                              title: suggestion.title,
                              date: suggestion.date,
                              isFromSuggestion: true,
                          }
                        : deliveryDate,
                );
                break;
            default:
        }
    });

    return {
        deliveryDates: orderBy(deliveryDates, (deliveryDate) => deliveryDate.date),
    };
};

const generateInitialBenefitLocationFormValues = (
    benefitChangeSuggestions: BenefitChangeSuggestions,
) => ({
    benefitLocation: benefitChangeSuggestions.benefitLocation,
    benefitValue: benefitChangeSuggestions.benefitValue,
});

const generateInitialBenefitPrioritiesFormValues = (
    benefitPriorities: FetchedBenefitPriority[],
    benefitChangeSuggestions: BenefitChangeSuggestions,
): BenefitChangeSuggestionsAcceptanceBenefitPrioritiesFormValues => {
    let priorities: BenefitChangeSuggestionsAcceptanceFormBenefitPriority[] = benefitPriorities.map(
        (benefitPriority) => ({
            id: benefitPriority.id,
            priority: benefitPriority.priority,
            name: benefitPriority.priorityName,
            prevValue: benefitPriority.value,
            value: benefitPriority.value,
        }),
    );

    benefitChangeSuggestions?.benefitPriorities && expandedSuggestionPanelIds.push('priorities');

    benefitChangeSuggestions?.benefitPriorities?.forEach((suggestion) => {
        if (priorities.find((priority) => priority.priority === suggestion.priority)) {
            priorities = priorities.map((priority) =>
                priority.id === suggestion.id
                    ? {
                          ...priority,
                          value: suggestion.value,
                          action: 'update',
                      }
                    : priority,
            );
        } else {
            priorities.push({ ...suggestion, action: 'create' });
        }
    });

    return {
        benefitPriorities: priorities,
    };
};

export const BenefitChangeSuggestionsAcceptanceForms = ({
    contractId,
    benefitId,
    sharedBenefitForUrl,
    benefitDeliveryDates,
    benefitPriorities,
    benefitChangeSuggestions,
    benefitLocation,
    benefitValue,
}: Props) => {
    const [deliveryDatesForm] = useForm();
    const [benefitPrioritiesForm] = useForm();
    const [benefitLocationForm] = useForm();
    const [benefitValueForm] = useForm();
    const dispatch = useDispatch();

    const displayLocationPanel =
        benefitChangeSuggestions.benefitLocation !== benefitLocation &&
        benefitChangeSuggestions.benefitLocation;

    displayLocationPanel && expandedSuggestionPanelIds.push('benefitLocation');

    const displayValuePanel =
        Math.round(benefitChangeSuggestions.benefitValue) !== Math.round(benefitValue) &&
        benefitChangeSuggestions.benefitValue;

    displayValuePanel && expandedSuggestionPanelIds.push('benefitValue');

    const displayDeliveryDatesPanel = benefitChangeSuggestions.deliveryDates?.find((suggestion) =>
        benefitDeliveryDates.every((deliveryDate) => suggestion.date !== deliveryDate.date),
    );

    displayDeliveryDatesPanel && expandedSuggestionPanelIds.push('benefitDeliveryDates');

    const displayPrioritiesPanel = benefitChangeSuggestions.benefitPriorities?.find((suggestion) =>
        benefitPriorities.every((priority) => suggestion.priority !== priority.priority),
    );

    displayPrioritiesPanel && expandedSuggestionPanelIds.push('benefitPriorities');

    useEffect(() => {
        benefitPrioritiesForm.resetFields();
    }, [benefitPriorities]);

    useEffect(() => {
        deliveryDatesForm.resetFields();
    }, [benefitDeliveryDates]);

    const handleOnFinish = ({
        deliveryDates,
    }: BenefitChangeSuggestionsAcceptanceDeliveryDatesFormValues) => {
        if (!deliveryDates) {
            return;
        }

        const updatedDeliveryDates: Partial<DeliveryDate>[] = [];

        deliveryDates
            .filter((deliveryDate) => !deliveryDate.isStart && !deliveryDate.isFinal)
            .forEach((deliveryDate) => {
                if (
                    !deliveryDate.isFromSuggestion ||
                    (deliveryDate.action === 'create' && deliveryDate.isApproved) ||
                    (deliveryDate.action === 'delete' && !deliveryDate.isApproved) ||
                    (deliveryDate.action === 'update' && !deliveryDate.isApproved)
                ) {
                    updatedDeliveryDates.push({
                        id: deliveryDate.id,
                        title: deliveryDate.title,
                        date: deliveryDate.date,
                    });
                } else if (deliveryDate.action === 'update') {
                    updatedDeliveryDates.push({
                        id: deliveryDate.id,
                        title: deliveryDate.title || deliveryDate.prevTitle,
                        date: deliveryDate.date || deliveryDate.prevDate,
                    });
                }
            });
        dispatch(
            editBenefitRequest({
                contract: contractId,
                sharedBenefitForUrl,
                benefitId,
                startDate: deliveryDates.find((deliveryDate) => deliveryDate.isStart)?.date,
                finalDeliveryDate: deliveryDates.find((deliveryDate) => deliveryDate.isFinal)?.date,
                deliveryDates: updatedDeliveryDates,
            }),
        );
    };

    const handleLocationUpdate = () => {
        if (!benefitChangeSuggestions.benefitLocation) {
            return;
        }

        dispatch(
            editBenefitRequest({
                contract: contractId,
                sharedBenefitForUrl,
                benefitId,
                location: benefitChangeSuggestions.benefitLocation,
            }),
        );
    };

    const handleValueUpdate = () => {
        if (!benefitChangeSuggestions.benefitValue) {
            return;
        }

        dispatch(
            editBenefitRequest({
                contract: contractId,
                sharedBenefitForUrl,
                benefitId,
                value: benefitChangeSuggestions.benefitValue,
            }),
        );
    };

    return (
        <CollapseWrapper>
            <Collapse defaultActiveKey={expandedSuggestionPanelIds}>
                {displayDeliveryDatesPanel && (
                    <Collapse.Panel
                        header="Update Benefit Delivery Dates"
                        key="benefitDeliveryDates"
                    >
                        <Form
                            form={deliveryDatesForm}
                            onFinish={handleOnFinish}
                            submitButtonText="Update Benefit Dates"
                            initialValues={generateInitialDeliveryDatesFormValues(
                                benefitDeliveryDates,
                                benefitChangeSuggestions,
                            )}
                        >
                            <AntdForm.List name="deliveryDates">
                                {(fields) =>
                                    fields.map(({ name, key }) => (
                                        <DeliveryDateSuggestionAcceptanceCard
                                            key={key}
                                            name={name}
                                        />
                                    ))
                                }
                            </AntdForm.List>
                        </Form>
                    </Collapse.Panel>
                )}
                {displayLocationPanel && (
                    <Collapse.Panel header="Update Benefit Location" key="benefitLocation">
                        <Form
                            onFinish={handleLocationUpdate}
                            submitButtonText="Approve Benefit Location"
                            form={benefitLocationForm}
                            initialValues={generateInitialBenefitLocationFormValues(
                                benefitChangeSuggestions,
                            )}
                        >
                            <BenefitLocationSuggestionAcceptanceCard
                                name="benefitLocation"
                                benefitLocation={benefitLocation}
                            />
                        </Form>
                    </Collapse.Panel>
                )}

                {displayValuePanel && (
                    <Collapse.Panel header="Update Benefit Value" key="benefitValue">
                        <Form
                            onFinish={handleValueUpdate}
                            submitButtonText="Approve Benefit Value"
                            form={benefitValueForm}
                            initialValues={generateInitialBenefitLocationFormValues(
                                benefitChangeSuggestions,
                            )}
                        >
                            <BenefitValueSuggestionAcceptanceCard
                                name={'benefitValue'}
                                benefitValue={benefitValue}
                            />
                        </Form>
                    </Collapse.Panel>
                )}

                {displayPrioritiesPanel && (
                    <Collapse.Panel header="Update Benefit Priorities" key="benefitPriorities">
                        <Form
                            form={benefitPrioritiesForm}
                            hideSubmitButton
                            initialValues={generateInitialBenefitPrioritiesFormValues(
                                benefitPriorities,
                                benefitChangeSuggestions,
                            )}
                        >
                            <AntdForm.List name="benefitPriorities">
                                {(fields) =>
                                    fields.map(({ name, key }) => (
                                        <BenefitPrioritySuggestionAcceptanceCard
                                            key={key}
                                            name={name}
                                            contractId={contractId}
                                            benefitId={benefitId}
                                        />
                                    ))
                                }
                            </AntdForm.List>
                        </Form>
                    </Collapse.Panel>
                )}
            </Collapse>
        </CollapseWrapper>
    );
};
