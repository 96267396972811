import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { debounce } from 'lodash';
import styled from 'styled-components';

import { breakpoints, sizes } from 'theme/variables';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { paginationRequest, saveFilters } from 'features/pagination/actions/paginationActions';
import {
    checkOrganisationExists,
    getAllExecutivesSuccess,
} from 'features/organisation/actions/organisationActions';
import {
    getInvitedOrganisationExists,
    getExecutives,
} from 'features/organisation/selectors/organisationSelectors';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { Input, TextArea, ResetButton } from 'common/components/deprecated/form';

export const SubmitButtonWrapper = styled(Antd3Form.Item)`
    && {
        margin-bottom: 0;

        button {
            width: 100%;
        }

        ${breakpoints.small} {
            margin: 0 auto;
            max-width: ${sizes.formWidth};
        }
    }
`;

const InviteNewOrganisationForm = ({ form }: Antd3FormProps) => {
    const dispatch = useDispatch();
    const [inputReferenceNumber, setInputReferenceNumber] = useState('');

    const invitedOrganisationExists = useSelector(getInvitedOrganisationExists);
    const executives = useSelector(getExecutives);

    useEffect(() => {
        dispatch(
            saveFilters({
                paginatedView: PaginationView.EditOrganisations,
                values: {},
            }),
        );
        dispatch(
            paginationRequest({
                view: PaginationView.EditOrganisations,
                pagination: {
                    current: 1,
                },
                paginationSuccessCallback: getAllExecutivesSuccess,
            }),
        );
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        form.validateFields((error) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }
        });
    };

    const searchOrganisations = (value: string) => dispatch(checkOrganisationExists(value));

    const debouncedSearch: ((value: string) => void) & Cancelable = debounce((value) => {
        searchOrganisations(value);
        setInputReferenceNumber(value);
    }, 300);

    const orgNameInput = form.getFieldValue('organisationName');
    const orgRegistrationNumberInput = form.getFieldValue('organisationRegistrationNumber');
    const optionalNoteInput = form.getFieldValue('optionalNote');

    const isOrgRegistrationNumberEmpty = !orgRegistrationNumberInput?.length;

    const isError = !isOrgRegistrationNumberEmpty && Boolean(invitedOrganisationExists);

    const mailtoContent = ` mailto:hello@cenefits.com?subject=New%20Organisation%3A%20${orgRegistrationNumberInput}
                                &body=Name%3A%0D%0A%0D%0A${orgNameInput}
                                %0D%0A%0D%0ANumber%3A%0D%0A%0D%0A${orgRegistrationNumberInput}
                                %0D%0A%0D%0ANote%3A%0D%0A%0D%0A${optionalNoteInput} `;

    return (
        <StyledAntd3Form onSubmit={handleSubmit} header="Request Organisation">
            <p>
                If you would like a new Supplier or Delivery Partner to be added to Cenefits, please
                fill out the fields below, and then click to send an email to our support site with
                the details.
            </p>
            <p>
                Please be as precise as possible, using the official organisation name and
                registration number from the organisation’s country’s company or charity regulator.
                In the UK that’s Companies House, Charity Commission or Office of the Scottish
                Charity Regulator.
            </p>
            <ResetButton form={form} />
            <Input form={form} id="organisationName" label="Organisation Name" />
            <Input
                form={form}
                id="organisationRegistrationNumber"
                label="Organisation Registration Number"
                onChange={({ target: { value } }) => debouncedSearch(value)}
                hasFeedback
                validateStatus={
                    isError ? 'error' : isOrgRegistrationNumberEmpty ? undefined : 'success'
                }
                help={invitedOrganisationExists}
            />
            <TextArea form={form} id="optionalNote" label="Optional Note" required={false} />
            <SubmitButtonWrapper>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!orgNameInput?.length || isOrgRegistrationNumberEmpty || isError}
                >
                    <a href={mailtoContent}>Submit For Approval</a>
                </Button>
            </SubmitButtonWrapper>
        </StyledAntd3Form>
    );
};

export const InviteNewOrganisation = Antd3Form.create({})(InviteNewOrganisationForm);
