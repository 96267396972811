import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { FullPageSpinner } from 'common/components';
import { getAllEmployees } from 'features/user/selectors/userSelectors';
import { Profile } from 'features/user/models/userModel';
import { getRouteId } from 'common/selectors/routeSelectors';

import {
    getFetchingOrganisationsStatus,
    getOrganisationById,
} from '../selectors/organisationSelectors';
import { EditOrganisation as EditOrganisationComponent } from '../components/OrganisationDetails';
import { Organisation, OrganisationDetailsRouteParams } from '../models/organisationModels';
import {
    getAllExecutivesRequest,
    getPotentialExecutivesRequest,
    getOrganisationRequest,
} from '../actions/organisationActions';

export interface OrganisationDetailsStateProps {
    organisationId: number;
    organisation?: Organisation;
    isFetching: boolean;
    employees: Profile[];
}

export interface OrganisationDetailsDispatchProps {
    getAllExecutivesRequest: typeof getAllExecutivesRequest;
    getPotentialExecutivesRequest: typeof getPotentialExecutivesRequest;
    getOrganisationRequest: typeof getOrganisationRequest;
}

export type OrganisationDetailsContainerProps = OrganisationDetailsStateProps &
    OrganisationDetailsRouteParams &
    OrganisationDetailsDispatchProps &
    OrganisationDetailsRouteParams;

class OrganisationDetails extends React.Component<OrganisationDetailsContainerProps> {
    public componentDidMount(): void {
        this.props.getOrganisationRequest(this.props.organisationId);
    }

    public render(): JSX.Element {
        return this.props.isFetching ? (
            <FullPageSpinner />
        ) : (
            <EditOrganisationComponent {...this.props} />
        );
    }
}

const mapStateToProps = (
    state: AppState,
    ownProps: OrganisationDetailsRouteParams,
): OrganisationDetailsStateProps => ({
    organisationId: getRouteId(ownProps),
    organisation: getOrganisationById(state, getRouteId(ownProps)),
    isFetching: getFetchingOrganisationsStatus(state),
    employees: getAllEmployees(state),
});

export const OrganisationDetailsContainer = connect(mapStateToProps, {
    getAllExecutivesRequest,
    getPotentialExecutivesRequest,
    getOrganisationRequest,
})(OrganisationDetails);
