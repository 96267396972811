import { AppAction } from 'common/actions/appAction';

import * as evidenceActions from '../actions/evidenceActions';
import * as scoreActions from '../actions/scoreActions';
import * as uploadActions from '../actions/uploadActions';
import { Evidence, Score, UploadBenefit, ContractManagerDetail } from '../models/uploadModels';

export interface UploadState {
    evidences: Evidence[];
    myEvidence?: Evidence;
    scores: Score[];
    myScore?: Score;
    fetchingStatus: 'idle' | 'inProgress' | 'success' | 'failed';
    isUploading: boolean;
    scorableBenefits: UploadBenefit[];
    readyForEvidenceBenefits: UploadBenefit[];
    readyForPendingEvidenceBenefits: UploadBenefit[];
    isFetchingScorable: boolean;
    isFetchingReadyForEvidence: boolean;
    isChangingApprovalStatus: boolean;
    contractManagerDetails: ContractManagerDetail[];
    isFetchingContractManagerDetails: boolean;
}

const defaultUploadState: UploadState = {
    evidences: [],
    myEvidence: undefined,
    scores: [],
    myScore: undefined,
    fetchingStatus: 'idle',
    isUploading: false,
    scorableBenefits: [],
    readyForEvidenceBenefits: [],
    readyForPendingEvidenceBenefits: [],
    isFetchingScorable: false,
    isFetchingReadyForEvidence: false,
    isChangingApprovalStatus: false,
    contractManagerDetails: [],
    isFetchingContractManagerDetails: false,
};

const actions = { ...evidenceActions, ...scoreActions, ...uploadActions };

export const uploadReducer = (state: UploadState = defaultUploadState, action: AppAction) => {
    switch (action.type) {
        case actions.GET_EVIDENCES_LIST_REQUESTED:
        case actions.GET_MY_EVIDENCE_REQUESTED:
            return {
                ...state,
                fetchingStatus: 'inProgress',
            };
        case actions.GET_SCORES_REQUESTED:
        case actions.GET_MY_EVIDENCE_FILES_REQUESTED:
        case actions.DELETE_SCORE_FILE_REQUESTED:
        case actions.DELETE_EVIDENCE_FILE_REQUESTED:
            return {
                ...state,
                isUploading: true,
            };
        case actions.GET_EVIDENCES_LIST_SUCCEED:
            return {
                ...state,
                ...action.payload,
                fetchingStatus: 'success',
            };
        case actions.GET_MY_EVIDENCE_SUCCEED:
        case actions.CREATE_EVIDENCE_SUCCEED:
            return {
                ...state,
                myEvidence: action.payload,
                fetchingStatus: 'success',
            };
        case actions.GET_SCORES_SUCCEED:
        case actions.GET_MY_EVIDENCE_FILES_SUCCEED:
        case actions.GET_MY_SCORE_SUCCEED:
            return {
                ...state,
                ...action.payload,
                fetchingStatus: 'success',
                isUploading: false,
            };
        case actions.UPLOAD_EVIDENCE_FILE_SUCCEED:
            return {
                ...state,
                myEvidence: {
                    ...state.myEvidence,
                    files: state.myEvidence?.files
                        ? [action.payload, ...state.myEvidence.files]
                        : [action.payload],
                },
                isUploading: false,
            };
        case actions.UPLOAD_SCORE_FILE_SUCCEED:
            return {
                ...state,
                myScore: {
                    ...state.myScore,
                    files: state.myScore?.files
                        ? [action.payload, ...state.myScore.files]
                        : [action.payload],
                },
                isUploading: false,
            };
        case actions.GET_EVIDENCES_LIST_FAILED:
        case actions.GET_MY_EVIDENCE_FAILED:
            return {
                ...state,
                myEvidenceFiles: [],
                myEvidence: undefined,
                fetchingStatus: 'failed',
            };
        case actions.GET_MY_SCORE_FAILED:
            return {
                ...state,
                myScore: undefined,
                fetchingStatus: 'failed',
            };
        case actions.CREATE_SCORE_SUCCEED:
            return {
                ...state,
                myScore: action.payload,
                fetchingStatus: 'success',
            };
        case actions.UPLOAD_EVIDENCE_FILE_FAILED:
        case actions.CREATE_EVIDENCE_FAILED:
        case actions.CREATE_SCORE_FAILED:
        case actions.GET_MY_EVIDENCE_FILES_FAILED:
        case actions.UPLOAD_SCORE_FILE_FAILED:
        case actions.DELETE_SCORE_FILE_FAILED:
        case actions.DELETE_EVIDENCE_FILE_FAILED:
            return {
                ...state,
                isUploading: false,
            };
        case actions.DELETE_SCORE_FILE_SUCCEED:
            return {
                ...state,
                myScore: {
                    ...state.myScore,
                    files: state.myScore?.files?.filter(({ id }) => id !== action.payload),
                },
                isUploading: false,
            };
        case actions.DELETE_EVIDENCE_FILE_SUCCEED:
            return {
                ...state,
                myEvidenceFiles: state.myEvidence?.files?.filter(({ id }) => id !== action.payload),
                isUploading: false,
            };
        case actions.CLEAR_UPLOAD_DATA:
            return {
                ...state,
                myScore: undefined,
                myEvidence: undefined,
                myEvidenceFiles: [],
            };
        case actions.GET_SCORABLE_BENEFITS_REQUESTED:
            return {
                ...state,
                isFetchingScorable: true,
            };
        case actions.GET_READY_FOR_EVIDENCE_BENEFITS_REQUESTED:
        case actions.GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_REQUESTED:
            return {
                ...state,
                isFetchingReadyForEvidence: true,
            };
        case actions.GET_SCORABLE_BENEFITS_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetchingScorable: false,
            };
        case actions.GET_READY_FOR_EVIDENCE_BENEFITS_SUCCEED:
        case actions.GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetchingReadyForEvidence: false,
            };
        case actions.GET_SCORABLE_BENEFITS_FAILED:
            return {
                ...state,
                isFetchingScorable: false,
            };
        case actions.GET_READY_FOR_EVIDENCE_BENEFITS_FAILED:
        case actions.GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_FAILED:
            return {
                ...state,
                isFetchingReadyForEvidence: false,
            };
        case actions.APPROVE_EVIDENCE_REQUESTED:
        case actions.DECLINE_EVIDENCE_REQUESTED:
        case actions.APPROVE_SCORE_REQUESTED:
        case actions.DECLINE_SCORE_REQUESTED:
            return {
                ...state,
                isChangingApprovalStatus: true,
            };
        case actions.APPROVE_EVIDENCE_SUCCEED:
        case actions.DECLINE_EVIDENCE_SUCCEED: {
            return {
                ...state,
                evidences: state.evidences.map((evidence) =>
                    evidence.id === action.payload!.id ? action.payload : evidence,
                ),
                isChangingApprovalStatus: false,
            };
        }
        case actions.APPROVE_SCORE_SUCCEED:
        case actions.DECLINE_SCORE_SUCCEED: {
            return {
                ...state,
                scores: state.scores.map((score) =>
                    score.id === action.payload!.id ? action.payload : score,
                ),
                isChangingApprovalStatus: false,
            };
        }
        case actions.APPROVE_EVIDENCE_FAILED:
        case actions.DECLINE_EVIDENCE_FAILED:
        case actions.APPROVE_SCORE_FAILED:
        case actions.DECLINE_SCORE_FAILED:
            return {
                ...state,
                isChangingApprovalStatus: false,
            };
        case actions.GET_CONTRACT_MANAGER_DETAILS_REQUESTED:
            return {
                ...state,
                isFetchingContractManagerDetails: true,
            };
        case actions.GET_CONTRACT_MANAGER_DETAILS_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetchingContractManagerDetails: false,
            };
        case actions.GET_CONTRACT_MANAGER_DETAILS_FAILED:
            return {
                ...state,
                isFetchingContractManagerDetails: false,
            };
        default:
            return state;
    }
};
