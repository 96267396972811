import * as React from 'react';
import { connect } from 'react-redux';
import { Divider } from 'antd';

import { InnerCard } from 'common/components/styled';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteData, AutocompleteField } from 'features/pagination/models/paginationModel';
import { Organisation } from 'features/organisation/models/organisationModels';
import { selectBenefitContract } from 'features/benefit/actions';
import {
    autocompleteRequest,
    clearInitialValueData,
    getInitialValueRequest,
} from 'features/pagination/actions/paginationActions';
import { selectContractOrganisation } from 'features/contract/actions/contractActions';
import { RolesList } from 'common/components';
import { InviteUserModal } from 'features/invite/components/InviteUserModal';
import { DeleteOrganisationButton } from 'features/create/components/DeletePartnerButton';
import { BenefitOrganisationRole, BenefitRole } from 'features/benefit/models/benefitModels';
import { EditBenefitInviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { getRolesPendingInvitations } from 'features/invite/selectors/inviteSelectors';
import { RoleInvitation } from 'features/contract/models/contractModels';
import { Antd3FormProps } from 'common/components/deprecated/antd3';

interface AutocompleteDispatchProps {
    autocompleteRequest: typeof autocompleteRequest;
    getInitialValueRequest: typeof getInitialValueRequest;
    clearInitialValueData: typeof clearInitialValueData;
    selectContractOrganisation: typeof selectContractOrganisation;
    selectBenefitContract: typeof selectBenefitContract;
}

interface AutocompleteSelectionProps {
    benefitId: number;
    contractId: number;
    selectedSupplier: BenefitOrganisationRole[];
    selectSupplierOrganisation: (selectedSupplier: Organisation) => void;
    deleteSupplierOrganisation: (supplierId: number, orgId: number) => void;
    selectedSupplierUsers: BenefitOrganisationRole[];
    selectSupplierUser: (selectedUser: AutocompleteData) => void;
    deleteSupplierUser: (userId: number) => void;
    pendingSupplierUsers: RoleInvitation[];
    selectInvitePendingSupplierUser: (selectedSupplier: AutocompleteData) => void;
    deletePendingSupplierUser: (roleId: string) => void;
    inviteNewEmployee: (employee: EditBenefitInviteByEmailWithRole) => void;
    tooltip?: string;
}

class EditSupplierFields extends React.Component<
    AutocompleteSelectionProps & AutocompleteDispatchProps & Antd3FormProps
> {
    public render(): JSX.Element {
        const {
            form,
            selectedSupplier,
            selectedSupplierUsers,
            deleteSupplierUser,
            deleteSupplierOrganisation,
            deletePendingSupplierUser,
            pendingSupplierUsers,
            benefitId,
            contractId,
        } = this.props;

        const suppliers = selectedSupplier.map((supplier) => (
            <div key={supplier.id}>
                {supplier.name}
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.BenefitRoleExecutives}
                    id="individual_suppliers"
                    placeholder="Add Existing Supplier Users"
                    notFoundContent="No users found"
                    getAutocompleteData={this.props.selectSupplierUser}
                    disabled={selectedSupplier == undefined}
                    searchParams={{ organisation: supplier.organisation }}
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.BenefitRoleInvitedExecutives}
                    id="invited_individual_suppliers"
                    placeholder="Add Invited Supplier Users"
                    notFoundContent="No users with pending invites"
                    getAutocompleteData={this.props.selectInvitePendingSupplierUser}
                    disabled={selectedSupplier == undefined}
                    searchParams={{
                        organisation: supplier.organisation,
                    }}
                />
                <InviteUserModal
                    form={form}
                    onInvite={this.props.inviteNewEmployee}
                    organisationName={supplier.name}
                    organisationId={supplier.organisation!}
                    role={BenefitRole.SUPPLIER}
                    contractId={contractId}
                />
                <Divider orientation="left">Active Roles</Divider>
                <RolesList
                    items={selectedSupplierUsers.map(({ id, email }) => ({
                        id,
                        name: email ? email : '',
                    }))}
                    deleteItem={deleteSupplierUser}
                    noData="No Supplier users added"
                />
                {pendingSupplierUsers.length > 0 && (
                    <>
                        <Divider orientation="left">Pending Roles</Divider>
                        <RolesList
                            items={pendingSupplierUsers.map(({ awaitingRoles, email }) => ({
                                id: awaitingRoles.filter((item) => item.benefit == benefitId)[0].id,
                                name: email ? email : '',
                            }))}
                            deleteItem={deletePendingSupplierUser}
                            noData="There are no pending supplier user invites."
                        />
                    </>
                )}

                <DeleteOrganisationButton
                    onClick={() => deleteSupplierOrganisation(supplier.id, supplier.organisation!)}
                />
            </div>
        ));

        return (
            <InnerCard>
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.ExecutiveOrganisation}
                    id="suppliers"
                    placeholder="Choose Supplier Organisation"
                    notFoundContent="No Supplier organisations found"
                    getAutocompleteData={this.props.selectSupplierOrganisation}
                    tooltip={this.props.tooltip}
                />
                {suppliers}
            </InnerCard>
        );
    }
}

export const EditSupplierForm = connect(null, {
    autocompleteRequest,
    getInitialValueRequest,
    clearInitialValueData,
    selectContractOrganisation,
    selectBenefitContract,
    getRolesPendingInvitations,
})(EditSupplierFields);
