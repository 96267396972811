import * as React from 'react';
import { connect } from 'react-redux';

import { createFilterRequest } from 'features/contract/actions/contractActions';
import { redirectToUrl } from 'common/actions/navigationAction';

import { CreateFilter as CreateFilterComponent } from '../components/CreateFilter';

interface CreateFilterContainerDispatchProps {
    createFilterRequest: typeof createFilterRequest;
    redirectToUrl: typeof redirectToUrl;
}

export type Props = CreateFilterContainerDispatchProps;

const CreateFilter: React.FunctionComponent<Props> = (props) => (
    <CreateFilterComponent {...props} />
);

export const CreateFilterContainer = connect(null, { createFilterRequest, redirectToUrl })(
    CreateFilter,
);
