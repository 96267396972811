import * as React from 'react';
import { Radio, Col, Button } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { NativeButtonProps } from 'antd/lib/button/button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { notArchivedFilterParam } from 'config/config';
import { SubmitButton } from 'common/components';
import {
    generateColumns,
    addReferenceSuffixToBenfitOutcome,
    FormFieldIds,
} from 'common/helpers/utils';
import { FilterData } from 'common/helpers/url';
import { showNotification, notificationText } from 'common/helpers/notifications';
import { Profile } from 'features/user/models/userModel';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField, PaginationView } from 'features/pagination/models/paginationModel';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { downloadBenefitsColumns } from 'features/download/components/DownloadBenefits';
import { getBenefitsSuccess } from 'features/benefit/actions';
import { AssignActions } from 'features/benefit/models/benefitModels';
import { Antd3Form, Antd3FormProps, StyledAntd3Form } from 'common/components/deprecated/antd3';

import { SupplierEditContractsContainerProps } from '../containers/SupplierEditContractsContainer';
import { SupplierAssignPage } from './SupplierAssignPage';
import { SupplierAssignPageTitles, SupplierAssignPaths } from '../models/editModel';

const FiltersWrapper = styled(Col)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const StyledButton = styled((props: NativeButtonProps) => <Button {...props} />)`
    && {
        height: 40px;
    }
`;

const SearchWrapper = styled.div`
    width: 100%;
    height: 35px;
    margin: 0 10px;
`;

interface SupplierEditContractsState {
    editingUserProfile?: Profile;
    showInviteButton: boolean;
    showSaveButton: boolean;
    assign: AssignActions;
    selectedBenefitIds: string[];
}

type SupplierEditContractsProps = Antd3FormProps & SupplierEditContractsContainerProps;

class SupplierEditContractsForm extends React.Component<
    SupplierEditContractsProps,
    SupplierEditContractsState
> {
    public state: SupplierEditContractsState = {
        showInviteButton: true,
        showSaveButton: false,
        assign: AssignActions.Assign,
        selectedBenefitIds: [],
    };

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { userIds }) => {
            if (error) {
                return showNotification({ text: notificationText.Error });
            }
            return this.props.toggleMultipleEmployeesRoleRequest({
                benefitIds: this.state.selectedBenefitIds.map((id) => parseInt(id, 10)),
                userIds: userIds.map((id: string) => parseInt(id, 10)),
                action: this.state.assign,
            });
        });
    };

    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.SupplierEditContract,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getBenefitsSuccess,
        });

    private saveFilters = (contractId: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.SupplierEditContract,
            values: {
                contract: contractId,
            },
        });

    private searchBenefits = (contractId: number) =>
        this.saveFilters(contractId) && this.refreshTable();

    private onRemoveContractAutocomplete = () => this.saveFilters(undefined) && this.refreshTable();

    private onSelectChange = (selectedBenefitIds: string[]) =>
        this.setState({ selectedBenefitIds });

    private handleAssignChange = (event: RadioChangeEvent) =>
        this.setState({ assign: event.target.value });

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.SupplierEditContract) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({ contractId: '' });

    public render(): JSX.Element {
        const { form, filter } = this.props;
        const isBenefitUnselected = !this.state.selectedBenefitIds.length;
        const userIds = form.getFieldValue('userIds');
        const isEmployeeUnselected = !(userIds && userIds.length);

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Assign Employees" bigHeader>
                <SupplierAssignPage tile={SupplierAssignPageTitles.Assign} />
                <FiltersWrapper>
                    <Link
                        to={`${SupplierAssignPaths.Contracts}/filter/${PaginationView.SupplierEditContract}`}
                    >
                        <StyledButton type="primary">Filter</StyledButton>
                    </Link>
                    <SearchWrapper>
                        <AutocompleteSelect
                            id={FormFieldIds.ContractId}
                            placeholder="Search contracts"
                            form={form}
                            autocompleteField={
                                AutocompleteField.ExecutiveAdminSearchContractsAssignBenefit
                            }
                            onSelect={this.searchBenefits}
                            onRemove={this.onRemoveContractAutocomplete}
                            notFoundContent="No contracts found"
                            requiredMessage="Contract is required"
                            showLabel={false}
                            defaultValue={filter && filter.values.contract}
                        />
                    </SearchWrapper>
                    <StyledButton onClick={this.clearFilters}>Clear Filter</StyledButton>
                </FiltersWrapper>

                <PaginatedTable
                    data={addReferenceSuffixToBenfitOutcome(this.props.benefits)}
                    columns={generateColumns(downloadBenefitsColumns(false))}
                    emptyText="No benefits found"
                    view={PaginationView.SupplierEditContract}
                    paginationSuccessCallback={getBenefitsSuccess}
                    rowSelection={{
                        selectedRowKeys: this.state.selectedBenefitIds,
                        onChange: this.onSelectChange,
                    }}
                    pageNumber={this.props.pageNumber}
                    pathUrl={SupplierAssignPaths.Contracts}
                    withPaginationHistory
                />
                <AutocompleteSelect
                    label="Select employees"
                    id="userIds"
                    form={form}
                    autocompleteField={AutocompleteField.Employee}
                    notFoundContent="No employees found"
                    multiple
                    required
                    requiredMessage="Please choose at least one employee"
                    searchParams={notArchivedFilterParam}
                />
                <Antd3Form.Item>
                    <Radio.Group
                        name="assign"
                        defaultValue={AssignActions.Assign}
                        onChange={this.handleAssignChange}
                    >
                        <Radio value={AssignActions.Assign}>Assign</Radio>
                        <Radio value={AssignActions.Revoke}>Unassign</Radio>
                    </Radio.Group>
                </Antd3Form.Item>
                <SubmitButton
                    value={this.state.assign === AssignActions.Assign ? 'Assign' : 'Unassign'}
                    disabled={isBenefitUnselected || isEmployeeUnselected}
                />
            </StyledAntd3Form>
        );
    }
}

export const SupplierEditContracts = Antd3Form.create({})(SupplierEditContractsForm);
