export const filterTooltips = {
    benefitCategories: `Benefit Categories are used to filter Benefits.`,
    benefitPriorities: `Benefit Priorities are used to filter Benefits. You can use them to record additional values like the number of 
                        "Priority Groups" people who are the subject or object of your Benefits can be classified under. More than one of
                        these can be applied to each Benefit, and each of them can also have a quantity.`,
    contractCategories: `Project Categories are used to filter Project and Benefits.`,
    contractPriorities: `Project Priorities are used to filter Projects. You can use them to record values like the number of "SMEs" or 
                        "Living Wage Accredited" suppliers assigned to your Projects. More than one of these can be applied to each Project, 
                        and each of them can also have a quantity.`,
    departments: `Departments are used to filter Projects, Benefits and Employees.`,
    locations: `Locations are used to filter Projects and Benefits. We suggest you use the first part of the post codes in your area.`,
};
