import { isArray, sortBy } from 'lodash';
import styled from 'styled-components';

import { IdValueObject } from 'common/models/defaultsModel';
import {
    QuestionnaireAnswer,
    QuestionnaireQuestion,
} from 'features/benefit/models/questionnaireModels';
import { borders } from 'theme/variables';

const Answer = styled.div`
    & + & {
        border-top: ${borders.border};
        margin-top: 1.5rem;
    }
`;

const AnswerBody = styled.p`
    font-weight: bold;
`;

type Props = {
    questions?: QuestionnaireQuestion[];
    answers?: QuestionnaireAnswer[];
};

export const QuestionnaireAnswers = ({ questions, answers }: Props) => {
    if (!questions || !answers) {
        return null;
    }

    const getQuestionAnswer = (questionId: string): string => {
        const answer = answers.find((answer) => answer.questionId === questionId);

        if (!answer || !answer.value) {
            return '—';
        }

        if (isArray(answer.value)) {
            return (answer.value as IdValueObject[]).map((choice) => choice.value).join(', ');
        }

        return typeof answer.value === 'object'
            ? (answer.value as IdValueObject).value || '—'
            : answer.value;
    };

    return (
        <>
            {sortBy(questions, (question) => question.index).map((question) => (
                <Answer key={question.id}>
                    <h3>{`${question.index + 1}: ${question.label}`}</h3>
                    <AnswerBody>{question.body}</AnswerBody>
                    <p>{getQuestionAnswer(question.id)}</p>
                </Answer>
            ))}
        </>
    );
};
