import React from 'react';
import styled from 'styled-components';

import { PaginationResources } from '../models/paginationModel';

interface EmployeeExpandableRowProps {
    rowData: PaginationResources;
}

const EmployeeExpandableRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const EmployeeDetailsColumn = [
    {
        title: 'Email',
        key: 'email',
    },
    {
        title: 'Administrator',
        key: 'isAdministrator',
    },
    { title: 'Phone', key: 'telephone' },
];

const ExecutiveDetailsColumn = [
    {
        title: 'Email',
        key: 'email',
    },
];

export const EmployeeExpandableRow: React.FunctionComponent<EmployeeExpandableRowProps> = ({
    rowData,
}) =>
    rowData.hasOwnProperty('isAdministrator') ? (
        <div>
            {EmployeeDetailsColumn.map((data) => (
                <EmployeeExpandableRowWrapper>
                    <p>{data.title}</p>
                    <p>{String(rowData[data.key])}</p>
                </EmployeeExpandableRowWrapper>
            ))}
        </div>
    ) : (
        <div>
            {ExecutiveDetailsColumn.map((data) => (
                <EmployeeExpandableRowWrapper>
                    <p>{data.title}</p>
                    <p>{rowData[data.key]}</p>
                </EmployeeExpandableRowWrapper>
            ))}
        </div>
    );
