import * as actions from '../actions/importActions';
import { AppAction } from 'common/actions/appAction';

export interface ImportState {
    isProcessing: boolean;
    errors: string[];
}

const defaultImportState = {
    isProcessing: false,
    errors: [],
};

export const importReducer = (state: ImportState = defaultImportState, action: AppAction) => {
    switch (action.type) {
        case actions.IMPORT_CONTRACTS_REQUESTED:
        case actions.IMPORT_BENEFITS_REQUESTED:
        case actions.IMPORT_BENEFIT_TEMPLATES_REQUESTED:
            return {
                ...state,
                isProcessing: true,
            };
        case actions.IMPORT_CONTRACTS_SUCCEED:
        case actions.IMPORT_BENEFITS_SUCCEED:
        case actions.IMPORT_BENEFIT_TEMPLATES_SUCCEED:
            return {
                ...state,
                errors: [],
                isProcessing: false,
            };
        case actions.IMPORT_CONTRACTS_FAILED:
        case actions.IMPORT_BENEFITS_FAILED:
        case actions.IMPORT_BENEFIT_TEMPLATES_FAILED:
            return {
                ...state,
                errors: action.payload,
                isProcessing: false,
            };
        default:
            return state;
    }
};
