import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { CreateContractForm } from 'features/contract/components/CreateContractForm';
import { createContractRequest } from 'features/contract/actions/contractActions';
import { isAdmin } from 'features/user/selectors/userSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';

interface CreateContractContainerStateProps {
    isUserAdmin: boolean;
}

export interface CreateContractContainerDispatchProps {
    createContractRequest: typeof createContractRequest;
    redirectToUrl: typeof redirectToUrl;
}

export type CreateContractContainerProps = CreateContractContainerDispatchProps &
    CreateContractContainerStateProps;

class CreateContract extends React.Component<CreateContractContainerProps> {
    public render(): JSX.Element {
        return <CreateContractForm {...this.props} />;
    }
}

const mapStateToProps = createSelector(
    isAdmin,
    (isUserAdmin): CreateContractContainerStateProps => ({
        isUserAdmin,
    }),
);

export const CreateContractContainer = connect(mapStateToProps, {
    redirectToUrl,
    createContractRequest,
})(CreateContract);
