import * as React from 'react';

import { SmallTabs } from 'common/components/styled';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { AddOrganisation } from './AddOrganisationForm';
import { InviteNewOrganisation } from './InviteNewOrganisation';
import { InviteOrganisationProps as InviteOrganisationContainerProps } from '../containers/InviteOrganisationContainer';

type InviteOrganisationProps = Antd3FormProps & InviteOrganisationContainerProps;

const InviteOrganisationForm: React.FC<InviteOrganisationProps> = (props) => {
    const {
        clearFilters,
        clearPagination,
        getPotentialExecutivesSuccess,
        paginationRequest,
        form,
    } = props;

    function resetAll(key: string) {
        clearFilters(PaginationView.AddOrganisation);
        clearPagination({
            paginationSuccessCallback: getPotentialExecutivesSuccess,
        });
        form.resetFields();
        if (key == 'add-organisation') {
            paginationRequest({
                view: PaginationView.AddOrganisation,
                pagination: {
                    current: 1,
                },
                paginationSuccessCallback: getPotentialExecutivesSuccess,
            });
        }
    }

    return (
        <SmallTabs onTabClick={resetAll}>
            <SmallTabs.TabPane key="add-organisation" tab="Add Organisation">
                <AddOrganisation {...props} />
            </SmallTabs.TabPane>
            <SmallTabs.TabPane key="requeset-organisation" tab="Request Organisation">
                <InviteNewOrganisation />
            </SmallTabs.TabPane>
        </SmallTabs>
    );
};

export const InviteOrganisation = Antd3Form.create({})(InviteOrganisationForm);
