import { Benefit, BenefitOrganisationRole } from 'features/benefit/models/benefitModels';
import { Contract } from 'features/contract/models/contractModels';
import { BenefitDetails as BaseBenefitDetails } from 'common/components/BenefitDetails';
import { Profile } from 'features/user/models/userModel';

import { BenefitAssignedUsers } from './BenefitAssignedUsers';
import { EditBenefitStatus } from './EditBenefitStatus';
import { EditBenefitPriorities } from './EditBenefitPriorities';

type Props = {
    benefit: Benefit;
    contract: Contract;
    benefitAssessors: Profile[];
    benefitSuppliers: BenefitOrganisationRole[];
    benefitPartners: BenefitOrganisationRole[];
    isEditable?: boolean;
};

export const BenefitDetails = ({
    benefit,
    contract,
    benefitAssessors,
    benefitSuppliers,
    benefitPartners,
    isEditable,
}: Props) => (
    <>
        <BaseBenefitDetails benefit={benefit} contract={contract} showFinalDeliveryDate={true} />
        <BenefitAssignedUsers
            benefitAssessors={benefitAssessors}
            benefitSuppliers={benefitSuppliers}
            benefitPartners={benefitPartners}
        />
        {isEditable && (
            <>
                <EditBenefitStatus benefit={benefit} contract={contract} />
                <EditBenefitPriorities benefit={benefit} contract={contract} />
            </>
        )}
    </>
);
