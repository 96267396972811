import { capitalize } from 'lodash';

import { Priority } from 'features/benefit/models/benefitModels';
import { benefitTemplateTooltips } from 'common/tooltips/benefitTemplateTooltips';
import { AutocompleteField } from 'features/pagination/models/paginationModel';
import { notArchivedFilterParam } from 'config/config';
import { Input, InputNumber, TextArea, Select, AutocompleteSelect } from 'common/components';

type Props = {
    areFieldsDisabled?: boolean;
};

export const BaseFields = ({ areFieldsDisabled }: Props) => (
    <>
        <Input
            name="outcome"
            label="Outcome"
            disabled={areFieldsDisabled}
            tooltip={benefitTemplateTooltips.Outcome}
        />
        <TextArea
            name="description"
            label="Description"
            required={false}
            disabled={areFieldsDisabled}
            tooltip={benefitTemplateTooltips.Description}
        />
        <AutocompleteSelect
            name="category"
            autocompleteField={AutocompleteField.BenefitCategory}
            label="Benefit Category"
            disabled={areFieldsDisabled}
            searchParams={notArchivedFilterParam}
            required
            tooltip={benefitTemplateTooltips.BenefitCategory}
        />
        <InputNumber
            name="pointsHint"
            label="Benefit Points Hint"
            required={false}
            disabled={areFieldsDisabled}
            tooltip={benefitTemplateTooltips.NumberOfPointsHint}
            validateDecimals
        />
        <InputNumber
            name="valueHint"
            label="Benefit Value Hint"
            required={false}
            disabled={areFieldsDisabled}
            tooltip={benefitTemplateTooltips.BenefitValueHint}
            validateDecimals
        />
        <InputNumber
            name="emissionSavingsHint"
            label="Emissions Savings Hint"
            required={false}
            disabled={areFieldsDisabled}
            tooltip={benefitTemplateTooltips.EmissionsSavingsHint}
            validateDecimals
        />
        <InputNumber
            name="outcomeInventory"
            label="Outcome Inventory"
            disabled={areFieldsDisabled}
            tooltip={benefitTemplateTooltips.OutcomeInventory}
        />
        <Select
            name="priority"
            label="Outcome Priority"
            options={Object.values(Priority).map((priority) => ({
                label: capitalize(priority),
                value: priority,
            }))}
            required={false}
            disabled={areFieldsDisabled}
            allowClear
            tooltip={benefitTemplateTooltips.OutcomePriority}
        />
    </>
);
