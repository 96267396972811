import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { AppState } from 'common/appState';
import {
    archiveBenefitCategoryRequest,
    deleteBenefitCategoryFilterRequest,
} from 'features/benefit/actions';
import { BenefitCategory } from 'features/benefit/models/benefitModels';
import { Filters } from 'features/contract/models/contractModels';
import { getBenefitCategories } from 'features/benefit/selectors/benefitSelectors';
import {
    getCategories,
    getDepartments,
    getLocations,
    getContractPrioritiesFilters,
    getBenefitPrioritiesFilters,
} from 'features/contract/selectors/contractSelectors';
import {
    archiveContractCategoryRequest,
    editFilterRequest,
    archiveLocationRequest,
    archiveDepartmentRequest,
    archiveContractPriorityRequest,
    archiveBenefitPriorityRequest,
    deleteContractCategoryFilterRequest,
    deleteContractPriorityFilterRequest,
    deleteBenefitPriorityFilterRequest,
    deleteDepartmentFilterRequest,
    deleteLocationFilterRequest,
} from 'features/contract/actions/contractActions';
import { redirectToUrl } from 'common/actions/navigationAction';
import { getPageNumber } from 'common/selectors/routeSelectors';

import { EditFilters as EditFilterComponent } from '../components/EditFilters';

interface EditFilterStateProps {
    benefitCategories: BenefitCategory[];
    contractCategories: Filters[];
    departments: Filters[];
    locations: Filters[];
    contractPrioritiesFilters: Filters[];
    benefitPrioritiesFilters: Filters[];
    path: string;
    pageNumber?: number;
}

interface EditFilterContainerDispatchProps {
    archiveContractCategoryRequest: typeof archiveContractCategoryRequest;
    archiveBenefitCategoryRequest: typeof archiveBenefitCategoryRequest;
    archiveLocationRequest: typeof archiveLocationRequest;
    archiveDepartmentRequest: typeof archiveDepartmentRequest;
    archiveBenefitPriorityRequest: typeof archiveBenefitPriorityRequest;
    archiveContractPriorityRequest: typeof archiveContractPriorityRequest;
    editFilterRequest: typeof editFilterRequest;
    deleteContractCategoryFilterRequest: typeof deleteContractCategoryFilterRequest;
    deleteContractPriorityFilterRequest: typeof deleteContractPriorityFilterRequest;
    deleteBenefitCategoryFilterRequest: typeof deleteBenefitCategoryFilterRequest;
    deleteBenefitPriorityFilterRequest: typeof deleteBenefitPriorityFilterRequest;
    deleteDepartmentFilterRequest: typeof deleteDepartmentFilterRequest;
    deleteLocationFilterRequest: typeof deleteLocationFilterRequest;
    redirectToUrl: typeof redirectToUrl;
}

export type EditFilterProps = EditFilterStateProps &
    EditFilterContainerDispatchProps &
    RouteComponentProps<{}>;

class EditFilter extends React.Component<EditFilterProps> {
    public render(): JSX.Element {
        return <EditFilterComponent {...this.props} />;
    }
}
const mapStateToProps = (
    state: AppState,
    routeParams: RouteComponentProps,
): EditFilterStateProps => ({
    benefitCategories: getBenefitCategories(state),
    contractCategories: getCategories(state),
    departments: getDepartments(state),
    locations: getLocations(state),
    contractPrioritiesFilters: getContractPrioritiesFilters(state),
    benefitPrioritiesFilters: getBenefitPrioritiesFilters(state),
    path: routeParams.match.path.slice(0, routeParams.match.path.lastIndexOf('/')),
    pageNumber: getPageNumber(routeParams),
});

export const EditFilterContainer = connect(mapStateToProps, {
    archiveContractCategoryRequest,
    archiveBenefitCategoryRequest,
    archiveContractPriorityRequest,
    archiveBenefitPriorityRequest,
    archiveLocationRequest,
    archiveDepartmentRequest,
    editFilterRequest,
    deleteContractCategoryFilterRequest,
    deleteContractPriorityFilterRequest,
    deleteBenefitCategoryFilterRequest,
    deleteBenefitPriorityFilterRequest,
    deleteDepartmentFilterRequest,
    deleteLocationFilterRequest,
    redirectToUrl,
})(EditFilter);
