export const templatedBenefitTooltips = {
    BenefitTemplateOutcome: `The Outcome of the Benefit Template the Templated Benefit is to use.`,
    ContractTitle: `The Title of the Project which the Benefit is to be associated with. It is also possible to type the Project Reference Number 
                    in here.`,
    BenefitLabel: `The Label that will differentiate this Benefit from other benefits based on the same Template`,
    BenefitModel: `A filter which allows you to specify the model you are using for this Benefit.`,
    BenefitLocation: `A filter which you can define.`,
    NumberOfPoints: `The number of points you want to associate with the Benefit. The greyed out value in the box represents the Benefit Points
                    hint added to the Benefit Template.`,
    BenefitValue: `The monetary value you want to associate with the Benefit. The greyed out value in the box represents the Benefit Value Hint added
                    to the Benefit Template.`,
    EmissionsSavings: `The greenhouse gas emissions savings you want to associate with the Benefit. The greyed out value in the box represents the
                    Emissions Savings Hint added to the Benefit Template.`,
    Quantity: `The number of Outcomes to be delivered. This reduces the total number available to be selected in the Outcome Inventory associated with 
              the Benefit Template, and when none are left, that Template cannot be selected until the Outcome Inventory is increased. We suggest you only create 
              multiple Benefits in scenarios where each item of a quantity matters and needs to be evidenced separately.`,
    BenefitPriorities: `A filter which you can define. You can use them to record additional values like the number of "Priority Groups" people who
                        are the subject or object of your Benefits can be classified under. More than one of these can be applied to each Benefit,
                        and each of them can also have a quantity.`,
    ProjectManagers: `The people in your organisation who you want to assess Benefits from your perspective, by submitting evidence and feedback by 
                        the appropriate dates. We suggest you only use this role if the person you've identified is able to witness or independently 
                        verify the delivery of the Benefit or if the Supplier fails to submit evidence directly but provides it to you outwith Cenefits.`,
    Supplier: `The organisation you want to associate with a Project or Benefit as a Supplier. Typically these are organisations who are responsible
                for delivering a Project that a Benefit is associated with, and ensuring the success of the Benefit itself. The users you select
                from or invite to that organisation will be required to submit evidence by the appropriate dates and to submit feedback once the
                Benefit is marked as Completed.`,
    DeliveryPartners: `An organisation you want to associate with a Project or Benefit as a Delivery Partner. Typically these are either 
                        subcontractors or community / parish councils, charities and social enterprises - organisations with a stake in ensuring 
                        the Benefit is a success. The users you select from or invite to that organisation will be required to submit evidence by
                        the appropriate dates.`,
    StartDate: `The date that the Benefit is due to start. We suggest you don't schedule too many Benefits to start at the same time in order to avoid 
               overfacing your submitters.`,
    InterimDeliveryDate: `Any interim delivery dates that are required to ensure the quantity and quality of the Outcomes being delivered. We suggest 
                         you only use these for Benefits being delivered over a longer period, e.g. a year or more.`,
    FinalDeliveryDate: `The date by which the Benefit is due to be delivered. We suggest you don't schedule too many Benefits to end at the same time 
                       in order to avoid overfacing your submitters.`,
    BenefitStatus: `A filter which allows you to select the current status of the Benefit. The Contributors should keep this up to date as the
                    Benefit progresses to make sure that reports remain accurate. If you mark a Benefit as Delivered or Partially Delivered, this will trigger an email
                    to Contributors and Suppliers asking them to submit a feedback. If you mark a Benefit as Delayed, Partially Delivered, 
                    Not Delivered or Substituted, you will be asked to type an explanation.`,
    BenefitStatusExplanation: `A free text field that is shown if you mark a Benefit is Delayed, Partially Delivered, Not Delivered or Substituted.
                                If you are substituting a Benefit, we suggest you create the new Benefit first, and then type its Reference Number when
                                filling out the Benefit Status Explanation for the old Benefit.`,
    BenefitsMigration: `This will transfer all benefits from the selected Benefit Template for which the questionnaires haven't been answered.`,
};
