import { LinkOutlined } from '@ant-design/icons';

import { CustomRouteProps } from 'common/models/navigationModel';
import { EditPageTitles, EditPaths } from 'features/edit/models/editModel';

import { CreateBenefitContainer } from '../containers/CreateBenefitContainer';
import { CreateBenefitTemplateContainer } from '../containers/CreateBenefitTemplateContainer';
import { CreateContractContainer } from '../containers/CreateContractContainer';
import { CreateFilterContainer } from '../containers/CreateFilterContainer';
import { CreatePaths } from '../models/createModel';

const adminMenuLinks = [
    { key: CreatePaths.Contract, label: 'Project' },
    { key: CreatePaths.Benefit, label: 'Benefit' },
    { key: CreatePaths.BenefitTemplate, label: 'Benefit Template' },
    { key: CreatePaths.Filter, label: 'Filter' },
    {
        key: EditPaths.FiltersPortfolio,
        label: EditPageTitles.PortfolioFilters,
        Icon: <LinkOutlined />,
    },
];

const menuLinks = [{ key: CreatePaths.Benefit, label: 'Benefit' }];

export const createRoutes: CustomRouteProps[] = [
    {
        path: CreatePaths.Contract,
        component: CreateContractContainer,
    },
    {
        path: `${CreatePaths.Benefit}/:contractId?`,
        component: CreateBenefitContainer,
    },
    {
        path: CreatePaths.BenefitTemplate,
        component: CreateBenefitTemplateContainer,
    },
    {
        path: CreatePaths.Filter,
        component: CreateFilterContainer,
    },
].map((route) => ({
    ...route,
    adminMenuLinks,
    menuLinks,
}));
