import { inject, injectable } from 'inversify';

import { HttpService } from 'common/services/HttpService';
import { SaveBlobParams, saveBlob, DownloadFileFormats } from 'common/helpers/utils';
import { SERVICE } from 'config/identifiers';
import { config } from 'config/config';
import { DownloadRequest, InvoiceDownloadRequest } from '../models/downloadModel';
import { generateFilterQueryParams } from 'common/helpers/url';

@injectable()
export class DownloadService {
    constructor(@inject(SERVICE.Http) private readonly http: HttpService) {}

    public exportBenefits({
        ids,
        fileFormat,
        filters,
        downloadAll,
        exportType,
        benefitType,
    }: DownloadRequest): Promise<Blob> {
        const filterQuery = filters ? generateFilterQueryParams(filters, false) : '';

        const path = `${config.routeConfig.exportBenefits}?${filterQuery}`;

        return this.http.POST(
            path,
            {
                ids,
                file_format:
                    fileFormat === DownloadFileFormats.XLSX ? 'XLS' : DownloadFileFormats.ODS,
                download_all: downloadAll,
                export_type: exportType,
                benefit_type: benefitType ? benefitType : undefined,
            },
            true,
        );
    }

    public exportInvoicePdf({ subscriptionId, invoiceId }: InvoiceDownloadRequest) {
        const path = `${config.routeConfig.organisationSubscriptions}${subscriptionId}/invoices/${invoiceId}/get-pdf/`;
        return this.http.GET<Response>(path, undefined, undefined, undefined, {
            'content-type': 'application/pdf',
        });
    }

    public exportContracts({
        ids,
        fileFormat,
        filters,
        downloadAll,
    }: DownloadRequest): Promise<Blob> {
        const filterQuery = filters ? generateFilterQueryParams(filters, false) : '';

        const path = `${config.routeConfig.exportContracts}?${filterQuery}`;

        return this.http.POST(
            path,
            {
                ids,
                file_format:
                    fileFormat === DownloadFileFormats.XLSX ? 'XLS' : DownloadFileFormats.ODS,
                download_all: downloadAll,
            },
            true,
        );
    }

    public saveBlob({ fileName, blob }: SaveBlobParams): Promise<void> {
        return saveBlob({ fileName, blob });
    }
}
