import { switchMap, pluck, map } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { AppEpic } from 'common/epics/appEpic';

import {
    CreateEvidenceRequest,
    UpdateEvidenceRequest,
    CreateEvidenceResponse,
    ScoreRequest,
    EvidenceData,
    EvidenceApproval,
    ScoreApproval,
    GetMyEvidenceRequest,
    ScoresRequest,
    Score,
    ScoreDecline,
    EvidenceDecline,
    DeleteScoreFileRequest,
    DeleteEvidenceFileRequest,
    ContractPath,
} from '../models/uploadModels';
import { UploadService } from '../services/uploadService';
import * as evidenceActions from '../actions/evidenceActions';
import * as scoreActions from '../actions/scoreActions';
import {
    GET_CONTRACT_FILES_REQUESTED,
    GET_CONTRACT_PRIORITIES_SUCCEED,
    GET_CONTRACT_SUCCEED,
    getContractFilesFailure,
    getContractFilesRequest,
    getContractFilesSuccess,
} from 'features/contract/actions/contractActions';
import { Contract } from 'features/contract/models/contractModels';

const actions = { ...evidenceActions, ...scoreActions };

export const uploadEpicFactory = (uploadService: UploadService): AppEpic => {
    const approveEvidenceEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.APPROVE_EVIDENCE_REQUESTED),
            pluck('payload'),
            switchMap((evidenceRequest: EvidenceApproval) =>
                uploadService
                    .approveEvidence(evidenceRequest)
                    .then(actions.approveEvidenceSuccess)
                    .catch(actions.approveEvidenceFailure),
            ),
        );

    const approveScoreEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.APPROVE_SCORE_REQUESTED),
            pluck('payload'),
            switchMap((scoreRequest: ScoreApproval) =>
                uploadService
                    .approveScore(scoreRequest)
                    .then(actions.approveScoreSuccess)
                    .catch(actions.approveScoreFailure),
            ),
        );

    const declineEvidenceEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DECLINE_EVIDENCE_REQUESTED),
            pluck('payload'),
            switchMap((evidenceRequest: EvidenceDecline) =>
                uploadService
                    .declineEvidence(evidenceRequest)
                    .then(actions.declineEvidenceSuccess)
                    .catch(actions.declineEvidenceFailure),
            ),
        );

    const declineScoreEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DECLINE_SCORE_REQUESTED),
            pluck('payload'),
            switchMap((scoreRequest: ScoreDecline) =>
                uploadService
                    .declineScore(scoreRequest)
                    .then(actions.declineScoreSuccess)
                    .catch(actions.declineScoreFailure),
            ),
        );

    const getEvidencesListEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_EVIDENCES_LIST_REQUESTED),
            pluck('payload'),
            switchMap((evidencesRequest: EvidenceData[]) =>
                uploadService
                    .getEvidencesList(evidencesRequest)
                    .then(actions.getEvidencesListSuccess)
                    .catch(actions.getEvidencesListFailure),
            ),
        );

    const getMyEvidenceEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_MY_EVIDENCE_REQUESTED),
            pluck('payload'),
            switchMap((myEvidenceRequest: GetMyEvidenceRequest) =>
                uploadService
                    .getMyEvidences(myEvidenceRequest)
                    .then((evidence) =>
                        actions.getMyEvidenceSuccess({
                            ...evidence,
                            ...myEvidenceRequest,
                        }),
                    )
                    .catch(actions.getMyEvidenceFailure),
            ),
        );

    const getContractFilesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(GET_CONTRACT_FILES_REQUESTED),
            pluck('payload'),
            switchMap((contract: ContractPath) =>
                uploadService
                    .getContractFiles(contract)
                    .then(getContractFilesSuccess)
                    .catch(getContractFilesFailure),
            ),
        );

    const requestMyEvidenceFilesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_MY_EVIDENCE_SUCCEED),
            pluck('payload'),
            map(actions.getMyEvidenceFilesRequest),
        );

    const getMyEvidenceFilesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_MY_EVIDENCE_FILES_REQUESTED),
            pluck('payload'),
            switchMap((evidence: EvidenceData) =>
                uploadService
                    .getMyEvidenceFiles(evidence)
                    .then(actions.getMyEvidenceFilesSuccess)
                    .catch(actions.getMyEvidenceFilesFailure),
            ),
        );

    const getScoresEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_SCORES_REQUESTED),
            pluck('payload'),
            switchMap((contractData: ScoresRequest) =>
                uploadService
                    .getScores(contractData)
                    .then(actions.getScoresSuccess)
                    .catch(actions.getScoresFailure),
            ),
        );

    const getMyScoreEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_MY_SCORE_REQUESTED),
            pluck('payload'),
            switchMap((contractData: ScoresRequest) =>
                uploadService
                    .getMyScore(contractData)
                    .then(actions.getMyScoreSuccess)
                    .catch(actions.getMyScoreFailure),
            ),
        );

    const submitForApprovalEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.SUBMIT_FOR_APPROVAL_REQUESTED),
            pluck('payload'),
            switchMap((evidenceRequest: UpdateEvidenceRequest) =>
                uploadService
                    .submitForApproval(evidenceRequest)
                    .then(actions.submitForApprovalSuccess)
                    .catch(actions.submitForApprovalFailure),
            ),
        );

    const submitScoreForApprovalEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.SUBMIT_SCORE_FOR_APPROVAL_REQUESTED),
            pluck('payload'),
            switchMap((scoreRequest: ScoreRequest) =>
                uploadService
                    .submitScoreForApproval(scoreRequest)
                    .then(actions.submitScoreForApprovalSuccess)
                    .catch(actions.submitScoreForApprovalFailure),
            ),
        );

    const createEvidenceEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_EVIDENCE_REQUESTED),
            pluck('payload'),
            switchMap((evidenceRequest: CreateEvidenceRequest) =>
                uploadService
                    .createEvidence(evidenceRequest)
                    .then((data: CreateEvidenceResponse) =>
                        actions.createEvidenceSuccess({
                            ...evidenceRequest,
                            ...data,
                            evidenceId: data.id,
                        }),
                    )
                    .catch(actions.createEvidenceFailure),
            ),
        );

    const updateEvidenceEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.UPDATE_EVIDENCE_REQUESTED),
            pluck('payload'),
            switchMap((evidenceRequest: UpdateEvidenceRequest) =>
                uploadService
                    .updateEvidence(evidenceRequest)
                    .then((data: CreateEvidenceResponse) =>
                        actions.updateEvidenceSuccess({
                            ...data,
                            ...evidenceRequest,
                            evidenceId: data.id,
                        }),
                    )
                    .catch(actions.updateEvidenceFailure),
            ),
        );

    const createScoreEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_SCORE_REQUESTED),
            pluck('payload'),
            switchMap((scoreRequest: ScoreRequest) =>
                uploadService
                    .createScore(scoreRequest)
                    .then((data: Score) =>
                        actions.createScoreSuccess({
                            ...scoreRequest,
                            ...data,
                            scoreId: data.id,
                            file: scoreRequest.file,
                        }),
                    )
                    .catch(actions.createScoreFailure),
            ),
        );

    const updateScoreEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.UPDATE_SCORE_REQUESTED),
            pluck('payload'),
            switchMap((scoreRequest: ScoreRequest) =>
                uploadService
                    .updateScore(scoreRequest)
                    .then((data: Score) =>
                        actions.updateScoreSuccess({
                            ...scoreRequest,
                            ...data,
                            scoreId: data.id,
                            file: scoreRequest.file,
                        }),
                    )
                    .catch(actions.updateScoreFailure),
            ),
        );

    const deleteScoreFileEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_SCORE_FILE_REQUESTED),
            pluck('payload'),
            switchMap((request: DeleteScoreFileRequest) =>
                uploadService
                    .deleteScoreFile(request)
                    .then(() => actions.deleteScoreFileSuccess(request.fileId))
                    .catch(actions.uploadScoreFileFailure),
            ),
        );

    const deleteEvidenceFileEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_EVIDENCE_FILE_REQUESTED),
            pluck('payload'),
            switchMap((request: DeleteEvidenceFileRequest) =>
                uploadService
                    .deleteEvidenceFile(request)
                    .then(() => actions.deleteEvidenceFileSuccess(request.fileId))
                    .catch(actions.deleteEvidenceFileFailure),
            ),
        );

    const getScorableBenefitsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_SCORABLE_BENEFITS_REQUESTED),
            pluck('payload'),
            switchMap(() =>
                uploadService
                    .getScorableBenefits()
                    .then(actions.getScorableBenefitsSuccess)
                    .catch(actions.getScorableBenefitsFailure),
            ),
        );

    const getReadyForEvidenceBenefitsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_READY_FOR_EVIDENCE_BENEFITS_REQUESTED),
            pluck('payload'),
            switchMap(() =>
                uploadService
                    .getReadyForEvidenceBenefits()
                    .then(actions.getReadyForEvidenceBenefitsSuccess)
                    .catch(actions.getReadyForEvidenceBenefitsFailure),
            ),
        );

    const getReadyForPendingEvidenceBenefitsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_READY_FOR_PENDING_EVIDENCE_BENEFITS_REQUESTED),
            pluck('payload'),
            switchMap(() =>
                uploadService
                    .getReadyForPendingEvidenceBenefits()
                    .then(actions.getReadyForPendingEvidenceBenefitsSuccess)
                    .catch(actions.getReadyForPendingEvidenceBenefitsFailure),
            ),
        );

    const getContractManagerDetailsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_CONTRACT_MANAGER_DETAILS_REQUESTED),
            pluck('payload'),
            switchMap((contractId: number) =>
                uploadService
                    .getContractManagerDetails(contractId)
                    .then(actions.getContractManagerDetailsSuccess)
                    .catch(actions.getContractManagerDetailsFailure),
            ),
        );

    return combineEpics(
        approveEvidenceEpic,
        approveScoreEpic,
        declineEvidenceEpic,
        declineScoreEpic,
        getEvidencesListEpic,
        getMyEvidenceEpic,
        requestMyEvidenceFilesEpic,
        getMyEvidenceFilesEpic,
        getScoresEpic,
        getMyScoreEpic,
        submitForApprovalEpic,
        submitScoreForApprovalEpic,
        updateEvidenceEpic,
        createEvidenceEpic,
        createScoreEpic,
        updateScoreEpic,
        deleteScoreFileEpic,
        deleteEvidenceFileEpic,
        getScorableBenefitsEpic,
        getReadyForEvidenceBenefitsEpic,
        getContractManagerDetailsEpic,
        getReadyForPendingEvidenceBenefitsEpic,
        getContractFilesEpic,
    );
};
