import { BenefitsFilterContainer } from 'common/containers/BenefitsFilterContainer';
import { SupplierEditContractsContainer } from 'features/edit/containers/SupplierEditContractsContainer';
import { SupplierAssignPaths } from 'features/edit/models/editModel';

export const assignRoutes = [
    {
        path: `${SupplierAssignPaths.Contracts}/filter/:filterId`,
        component: BenefitsFilterContainer,
    },
    {
        path: `${SupplierAssignPaths.Contracts}/:pageNumber?`,
        component: SupplierEditContractsContainer,
    },
];
