import { Form } from 'antd';

import { Input } from 'common/components';
import { getQuestionnaireQuestionInput } from 'features/benefit/benefitHelpers';
import { QuestionnaireQuestion } from 'features/benefit/models/questionnaireModels';

type Props = {
    questions: QuestionnaireQuestion[];
};

export const Questionnaire = ({ questions }: Props) => (
    <Form.List name="questionnaireAnswer">
        {(fields) =>
            fields.map(({ key, name }) => {
                const question = questions.find((question) => question.index === key);

                if (!question) {
                    return null;
                }

                return (
                    <div key={key}>
                        <h3>{question.label}</h3>
                        {getQuestionnaireQuestionInput(name, question)}
                        <Input name={[name, 'index']} required={false} disabled hidden />
                        <Input name={[name, 'questionId']} required={false} disabled hidden />
                    </div>
                );
            })
        }
    </Form.List>
);
