import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { FullPageSpinner } from 'common/components';

import { confirmNewPasswordRequest } from '../actions/userActions';

interface MatchParams {
    token: string;
}

interface ConfirmNewPasswordDispatchProps {
    confirmNewPasswordRequest: typeof confirmNewPasswordRequest;
}

export type ConfirmNewPasswordProps = RouteComponentProps<MatchParams> &
    ConfirmNewPasswordDispatchProps;

class ConfirmNewPassword extends React.Component<ConfirmNewPasswordProps> {
    public componentDidMount(): void {
        const { token } = this.props.match.params;
        this.props.confirmNewPasswordRequest(token);
    }

    public render(): JSX.Element {
        return <FullPageSpinner />;
    }
}

export const ConfirmNewPasswordContainer = connect(null, { confirmNewPasswordRequest })(
    ConfirmNewPassword,
);
