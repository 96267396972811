import { ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs/observable/of';
import { switchMap, pluck, mergeMap, catchError, map } from 'rxjs/operators';
import { AppEpic } from 'common/epics/appEpic';

import * as actions from '../actions/portfolioFilterAction';
import { PortfolioFilterService } from '../services/portfolioFilterService';
import {
    GetPortfolioFiltersRequest,
    PortfolioFilterRequest,
    PortfolioFiltersEnum,
    portfolioFilterDefaultIds,
} from '../models/portfolioFilterModel';

export const portfolioFilterEpicFactory = (
    portfolioFilterService: PortfolioFilterService,
): AppEpic => {
    const getPortfolioFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_PORTFOLIO_FILTERS_LIST_REQUESTED),
            pluck('payload'),
            switchMap((portfolioFilterRequest: GetPortfolioFiltersRequest) =>
                portfolioFilterService
                    .getPortfolioFilters(portfolioFilterRequest)
                    .then((portfolioFilters) =>
                        actions.getPortfolioFiltersSuccess({
                            portfolioFilters,
                            depth: portfolioFilterRequest.depth,
                        }),
                    )
                    .catch(actions.getPortfolioFiltersFailure),
            ),
        );

    const createPortfolioFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_PORTFOLIO_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((portfolioFilterRequest: PortfolioFilterRequest) =>
                portfolioFilterService
                    .createPortfolioFilter(portfolioFilterRequest)
                    .then((portfolioFilter) =>
                        actions.createPortfolioFilterSuccess({
                            portfolioFilter,
                            depth: portfolioFilterRequest.params.depth,
                        }),
                    )
                    .catch(actions.createPortfolioFilterFailure),
            ),
        );

    const editPortfolioFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_PORTFOLIO_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((portfolioFilterRequest: PortfolioFilterRequest) =>
                portfolioFilterService
                    .editPortfolioFilter(portfolioFilterRequest)
                    .then((portfolioFilter) =>
                        actions.editPortfolioFilterSuccess({
                            portfolioFilter,
                            depth: portfolioFilterRequest.params.depth,
                        }),
                    )
                    .catch(actions.editPortfolioFilterFailure),
            ),
        );

    const getPortfolioFilterRootEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_PORTFOLIO_FILTERS_ROOT_REQUESTED),
            pluck('payload'),
            switchMap(() => portfolioFilterService.getPortfolioFilterRoot()),
            mergeMap((portfolioFilter) => [
                actions.getPortfolioFiltersRootSuccess(portfolioFilter),
                actions.getPortfolioFiltersRequest({
                    depth: PortfolioFiltersEnum.SubProgram,
                    portfolioFilterIds: portfolioFilterDefaultIds,
                    portfolioFilterRootId: portfolioFilter.id,
                }),
            ]),
            catchError((err) => of(actions.getPortfolioFiltersFailure(err))),
        );

    const deletePortfolioFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_PORTFOLIO_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((portfolioFilterRequest: PortfolioFilterRequest) =>
                portfolioFilterService
                    .deletePortfolioFilter(portfolioFilterRequest)
                    .then(() =>
                        actions.deletePortfolioFilterSuccess(
                            portfolioFilterRequest.params,
                            'Portfolio filter has been deleted.',
                        ),
                    )
                    .catch(actions.deletePortfolioFilterFailure),
            ),
        );

    const deletePortfolioFilterSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_PORTFOLIO_FILTER_SUCCEED),
            pluck('payload'),
            map(actions.getPortfolioFiltersRequest),
        );

    return combineEpics(
        getPortfolioFilterEpic,
        createPortfolioFilterEpic,
        editPortfolioFilterEpic,
        getPortfolioFilterRootEpic,
        deletePortfolioFilterEpic,
        deletePortfolioFilterSuccessEpic,
    );
};
