import * as React from 'react';
import { startCase, camelCase, omit } from 'lodash';

import { SubmitButton, FullPageSpinner, RolesList } from 'common/components';
import {
    DatePicker,
    Input,
    Select,
    TextArea,
    DynamicFieldSet,
    ResetButton,
} from 'common/components/deprecated/form';
import {
    openModal,
    deleteOrganisationRoleText,
    deleteUserRoleText,
    deletePendingUserRoleText,
} from 'common/helpers/modal';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { dateFormat, notArchivedFilterParam } from 'config/config';
import {
    BenefitRole,
    BenefitPriority,
    EditBenefitPriorityRequest,
    AddBenefitPriorityRequest,
    BenefitRoleName,
    InvitePendingIndividualExecutivesRequest,
    BenefitTemplate,
} from 'features/benefit/models/benefitModels';
import { EditBenefitContainerProps } from 'features/benefit/containers/EditBenefitContainer';
import { DateObject } from 'features/create/components/CreateBenefitForm';
import { PrioritiesForm } from 'features/create/components/PrioritiesForm';
import {
    getUsername,
    getCategoryName,
    formatPriorityRequest,
    formatPriorityToState,
    checkIntegerConfirmation,
    checkDecimalPlaceConfirmation,
} from 'common/helpers/utils';
import { uniqueBenefitTooltips } from 'common/tooltips/uniqueBenefitTooltips';
import { templatedBenefitTooltips } from 'common/tooltips/templatedBenefitTooltips';
import { AutocompleteSelect } from 'features/pagination/components/AutocompleteSelect';
import { AutocompleteField, AutocompleteData } from 'features/pagination/models/paginationModel';
import { autocompleteRequest } from 'features/pagination/actions/paginationActions';
import { benefitMethod, RoleInvitation } from 'features/contract/models/contractModels';
import { Organisation } from 'features/organisation/models/organisationModels';
import { InviteByEmailWithRole } from 'features/invite/models/inviteModel';
import { Antd3Form, StyledAntd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { EditStatusExplanation } from './EditStatusExplanation';
import { SelectBenefitTemplateParent } from './SelectBenefitTemplateParent';
import { EditSupplierForm } from './EditSupplierForm';
import { EditDeliveryPartnerForm } from './EditDeliveryPartnerForm';
import { BenefitQuestionnairesEditabilityType } from '../models/questionnaireModels';

export interface DeleteRoleOptions {
    organisation?: boolean;
}

interface Props {
    addRole: (role: BenefitRole, organisationId: number, userId?: number) => void;
    editPriority: (data: EditBenefitPriorityRequest) => void;
    addPriority: (data: AddBenefitPriorityRequest) => void;
    deletePriority: (data: EditBenefitPriorityRequest) => void;
    inviteNewEmployee: (employeeData: InviteByEmailWithRole) => void;
    deleteRole: (id: number | string, orgId?: number) => void;
    deletePendingRole: (id: string, benefitId: number, contractId?: number) => void;
    updatePriorities: () => void;
    autocompleteRequest: typeof autocompleteRequest;
    benefitCategories: AutocompleteData[];
    assignInvitedIndividualExecutive: (data: InvitePendingIndividualExecutivesRequest) => void;
}

interface State {
    isParentSelectVisible: boolean;
    priorities: BenefitPriority[];
}

type EditBenefitProps = Props & EditBenefitContainerProps & Antd3FormProps;

class EditBenefit extends React.Component<EditBenefitProps, State> {
    constructor(props: EditBenefitProps) {
        super(props);
        this.state = {
            isParentSelectVisible: false,
            priorities: this.props.priorities,
        };
    }

    public componentDidMount(): void {
        this.props.autocompleteRequest({
            autocompleteField: AutocompleteField.BenefitTemplate,
            searchTerm: '',
        });
    }

    public componentDidUpdate(prevProps: EditBenefitContainerProps): void | boolean {
        const resetCondition =
            prevProps.benefit &&
            this.props.benefit &&
            prevProps.benefit.sharedBenefit !== this.props.benefit.sharedBenefit;

        if (prevProps.priorities !== this.props.priorities) {
            this.setState({
                priorities: this.props.priorities,
            });
        }

        return resetCondition || this.props.form.resetFields(['referenceNumber']);
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.props.form.validateFields((error, { statusExplanation, ...values }) => {
            const preparedValues = omit(values, 'priorities');
            const deliveryDates: DateObject[] =
                preparedValues.deliveryDates && Object.values(preparedValues.deliveryDates);

            const dates = deliveryDates && deliveryDates.filter(({ date }) => date);

            return error
                ? showNotification({ text: notificationText.Error })
                : this.props.editBenefitRequest({
                      ...preparedValues,
                      location: preparedValues.location || null,
                      method: preparedValues.method || null,
                      points: preparedValues.points || null,
                      value: preparedValues.value || null,
                      emissionSavings: preparedValues.emissionSavings || null,
                      benefitId: this.props.benefitId,
                      contract: this.props.contract,
                      deliveryDates:
                          dates?.map(({ date, ...rest }: DateObject) => ({
                              ...rest,
                              date: date.format(dateFormat),
                          })) || [],
                      sharedBenefit: preparedValues.benefitTemplate,
                      sharedBenefitForUrl: this.props.benefit.sharedBenefit,
                      ...(statusExplanation !== this.props.benefit.statusExplanation && {
                          statusExplanation,
                      }),
                  });
        });
    };

    private selectSupplierOrganisation = (supplier: Organisation) => {
        if (this.props.benefitSuppliers.find((item) => item.organisation == supplier.id)) {
            showNotification({ text: "You've already selected this supplier." });
            return;
        }
        this.props.addRole(BenefitRole.SUPPLIER, supplier.id);
    };

    private deleteSupplierOrganisation = (roleId: number, orgId: number) => {
        openModal({
            content: `${deleteOrganisationRoleText} benefit`,
            callback: () => this.props.deleteRole(roleId, orgId),
        });
    };

    private selectSupplierUser = (supplier: AutocompleteData) => {
        if (
            this.props.benefitIndividualSuppliers.find(
                (item) =>
                    item.user == supplier.userId && item.organisation == supplier.organisation,
            )
        ) {
            showNotification({ text: "You've already selected this user." });
            return;
        }
        this.props.addRole(BenefitRole.SUPPLIER, supplier.organisation!, supplier.userId!);
    };

    private deleteSupplierUser = (roleId: number) => {
        openModal({
            content: `${deleteUserRoleText} benefit?`,
            callback: () => this.props.deleteRole(roleId),
        });
    };

    private selectInvitePendingSupplierUser = (supplier: AutocompleteData) => {
        let hasPendingInvite = false;
        this.props.rolesPendingInvitations.forEach((item) => {
            if (item.id == supplier.id && item.organisation == supplier.organisation) {
                item.awaitingRoles.forEach((role) => {
                    if (role.benefit == this.props.benefitId && role.role == 'Supplier') {
                        showNotification({ text: "You've already selected this user." });
                        hasPendingInvite = true;
                        return;
                    }
                });
            }
        });
        if (!hasPendingInvite) {
            this.props.assignInvitedIndividualExecutiveRequest({
                userInvitation: supplier.id,
                role: BenefitRole.SUPPLIER,
                benefit: this.props.benefitId,
                contract: this.props.contract,
            });
        }
    };

    private selectDeliveryPartnerOrganisation = (selectedPartner: Organisation) => {
        if (this.props.benefitPartners.find((item) => item.organisation == selectedPartner.id)) {
            showNotification({ text: "You've already selected this delivery partner." });
            return;
        }
        this.props.addRole(BenefitRole.DELIVERY_PARTNER, selectedPartner.id);
    };

    private deleteDeliveryPartner = (roleId: number, orgId: number) => {
        openModal({
            content: `${deleteOrganisationRoleText} benefit`,
            callback: () => this.props.deleteRole(roleId, orgId),
        });
    };

    private selectDeliveryPartnerUser = (selectedExecutive: AutocompleteData) => {
        if (
            this.props.benefitIndividualPartners.find(
                (item) =>
                    item.user == selectedExecutive.id &&
                    item.organisation == selectedExecutive.organisation,
            )
        ) {
            showNotification({ text: "You've already selected this user." });
            return;
        }
        this.props.addRole(
            BenefitRole.DELIVERY_PARTNER,
            selectedExecutive.organisation!,
            selectedExecutive.userId!,
        );
    };

    private deletePartnerUser = (roleId: number) => {
        openModal({
            content: `${deleteUserRoleText} benefit?`,
            callback: () => this.props.deleteRole(roleId),
        });
    };

    private selectInvitePendingPartnerUser = (selectedExecutive: AutocompleteData) => {
        let hasPendingInvite = false;
        this.props.rolesPendingInvitations.forEach((item) => {
            if (
                item.id == selectedExecutive.id &&
                item.organisation == selectedExecutive.organisation
            ) {
                item.awaitingRoles.forEach((role) => {
                    if (role.benefit == this.props.benefitId && role.role == 'Delivery partner') {
                        showNotification({ text: "You've already selected this user." });
                        hasPendingInvite = true;
                        return;
                    }
                });
            }
        });
        if (!hasPendingInvite) {
            this.props.assignInvitedIndividualExecutiveRequest({
                userInvitation: selectedExecutive.id,
                role: BenefitRole.DELIVERY_PARTNER,
                benefit: this.props.benefitId,
                contract: this.props.contract,
            });
        }
    };

    private deletePendingInvite = (roleId: string) => {
        openModal({
            content: `${deletePendingUserRoleText} benefit?`,
            callback: () =>
                this.props.deletePendingRole(roleId, this.props.contractId, this.props.benefitId),
        });
    };

    private toggleParentSelect = () => {
        this.setState(({ isParentSelectVisible }) => ({
            isParentSelectVisible: !isParentSelectVisible,
        }));
    };

    private selectNewPriority = (priorityData: BenefitPriority) => {
        if (!this.state.priorities.find((a) => a.priority === priorityData.id)) {
            const formattedPriority = formatPriorityToState(priorityData);

            this.setState((prevState) => ({
                ...prevState,
                priorities: [...prevState.priorities, formattedPriority],
            }));
        }

        const data = {
            contractId: this.props.contractId,
            benefitId: this.props.benefitId,
            priority: formatPriorityRequest(priorityData),
        };

        this.props.addPriority(data);
    };

    private deletePriority = (priorityId: number) => {
        this.setState((prevState) => ({
            ...prevState,
            priorities: prevState.priorities.filter((item) => item.id !== Number(priorityId)),
        }));

        const data = {
            contractId: this.props.contractId,
            benefitId: this.props.benefitId,
            priorityId,
        };

        this.props.deletePriority(data);
    };

    private changePriorityValue = (priorityId: number, value: number) => {
        this.setState((prevState) => ({
            ...prevState,
            priorities: prevState.priorities.map((priority) =>
                priority.id === priorityId ? { ...priority, value } : priority,
            ),
        }));
        const data = {
            contractId: this.props.contractId,
            benefitId: this.props.benefitId,
            priorityId,
            value,
        };
        this.props.editPriority(data);
    };

    private updateSelectedBenefitTemplate = (id: number) => {
        this.props.getBenefitTemplateWhenCreatingTemplatedBenefitRequest(id);
    };

    public render(): JSX.Element {
        const {
            contractId,
            contract,
            benefitId,
            benefit,
            label,
            form,
            benefitAssessors,
            benefitSuppliers,
            benefitPartners,
            benefitIndividualSuppliers,
            benefitIndividualPartners,
            rolesPendingInvitations,
            benefitCategories,
            addRole,
            inviteNewEmployee,
            selectedBenefitTemplate,
        } = this.props;

        const benefitData = benefit
            ? {
                  outcome: benefit.outcome,
                  referenceNumber: benefit.referenceNumber,
                  description: benefit.description,
                  benefitCategory: benefit.category,
                  location: benefit.location,
                  points: benefit.points,
                  value: benefit.value,
                  emissionSavings: benefit.emissionSavings,
                  quantity: benefit.quantity,
                  startDate: benefit.startDate,
                  endDate: benefit.finalDeliveryDate,
                  status: startCase(camelCase(benefit.status)),
                  deliveryDates: benefit.deliveryDates.filter(
                      ({ isStart, isFinal }) => !isStart && !isFinal,
                  ),
                  benefitTemplateId: benefit.sharedBenefit,
                  method: startCase(camelCase(benefit.method)),
                  statusExplanation: benefit.statusExplanation,
                  priorities: this.state.priorities,
                  label: benefit.label,
              }
            : {};

        const benefitTemplateChild = !!benefitData.benefitTemplateId;
        const isSharedBenefit = benefit && benefit.type === 'Shared';

        if (this.props.isFetching) {
            return <FullPageSpinner />;
        }

        if (!(benefit && contract)) {
            return (
                <h2>
                    No contract (id: {contractId}) and/or benefit (id: {benefitId}) found
                </h2>
            );
        }

        const template = this.props.benefitTemplates[0];

        const isBenefitTemplateChangeAllowed = Boolean(
            template &&
                Object.values(BenefitQuestionnairesEditabilityType).every(
                    (type) => template[type] === true,
                ),
        );

        const benefitTemplateOutcomeInitialValue = selectedBenefitTemplate[0]
            ? selectedBenefitTemplate[0].id
            : benefit.sharedBenefit;

        const placeholderNumberOfPoints =
            selectedBenefitTemplate[0] && selectedBenefitTemplate[0].pointsHint
                ? 'Benefit Points (Hint: ' + selectedBenefitTemplate[0].pointsHint + ')'
                : benefit.pointsHint && !selectedBenefitTemplate[0]
                ? 'Benefit Points (Hint: ' + benefit.pointsHint + ')'
                : 'Benefit Points';

        const placeholderHintValue =
            selectedBenefitTemplate[0] && selectedBenefitTemplate[0].valueHint
                ? 'Benefit Value (Hint: ' + selectedBenefitTemplate[0].valueHint + ')'
                : benefit.valueHint && !selectedBenefitTemplate[0]
                ? 'Benefit Value (Hint: ' + benefit.valueHint + ')'
                : 'Benefit Value';

        const placeholderEmissionSavingsValue =
            selectedBenefitTemplate[0] && selectedBenefitTemplate[0].emissionSavingsHint
                ? 'Emissions Savings (Hint: ' + selectedBenefitTemplate[0].emissionSavingsHint + ')'
                : benefit.emissionSavingsHint && !selectedBenefitTemplate[0]
                ? 'Emissions Savings (Hint: ' + benefit.emissionSavingsHint + ')'
                : 'Emissions Savings';

        const getPendingRoleInvitesByRole = (
            invites: RoleInvitation[],
            role: BenefitRoleName,
            benefitId: number,
        ) =>
            invites.filter(
                (invite) =>
                    invite.awaitingRoles.filter(
                        (record) => record.role === role && record.benefit === benefitId,
                    ).length > 0 && invite.active,
            );

        const pendingSupplierUsers = getPendingRoleInvitesByRole(
            rolesPendingInvitations,
            BenefitRoleName.Supplier,
            benefitId,
        );

        const pendingPartnerUsers = getPendingRoleInvitesByRole(
            rolesPendingInvitations,
            BenefitRoleName.DeliveryPartner,
            benefitId,
        );

        return (
            <StyledAntd3Form onSubmit={this.handleSubmit} header="Edit Benefit">
                <ResetButton form={form} />
                <Input
                    form={form}
                    id="outcome"
                    label="Outcome"
                    initialValue={benefitData.outcome}
                    disabled={isSharedBenefit || benefitTemplateChild}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.BenefitTemplateOutcome
                            : uniqueBenefitTooltips.Outcome
                    }
                />
                <Input
                    form={form}
                    id="label"
                    label="Benefit Label"
                    initialValue={benefitData.label}
                    required={false}
                />

                {benefitTemplateChild && (
                    <>
                        <SelectBenefitTemplateParent
                            isChildVisible={this.state.isParentSelectVisible}
                            showChild={this.toggleParentSelect}
                            isDisabled={!isBenefitTemplateChangeAllowed}
                        >
                            <AutocompleteSelect
                                form={form}
                                id="benefitTemplate"
                                autocompleteField={AutocompleteField.BenefitTemplate}
                                label="Benefit Template Outcome"
                                notFoundContent="No Benefit Templates found"
                                searchParams={notArchivedFilterParam}
                                initialValue={benefitTemplateOutcomeInitialValue}
                                required
                                onSelect={this.updateSelectedBenefitTemplate}
                                tooltip={templatedBenefitTooltips.BenefitTemplateOutcome}
                            />
                        </SelectBenefitTemplateParent>
                    </>
                )}
                <Input
                    form={form}
                    id="referenceNumber"
                    label="Reference Number"
                    initialValue={benefitData.referenceNumber || 'None'}
                    required={false}
                    disabled={true}
                />
                <TextArea
                    initialValue={benefitData.description}
                    form={form}
                    id="description"
                    label="Description"
                    required={false}
                    disabled={isSharedBenefit || benefitTemplateChild}
                    tooltip={uniqueBenefitTooltips.BenefitDescription}
                />
                {benefitTemplateChild ? (
                    <Input
                        form={form}
                        id="category"
                        label="Benefit Category"
                        initialValue={getCategoryName(
                            benefitData.benefitCategory!,
                            benefitCategories,
                        )}
                        required={false}
                        disabled={true}
                        tooltip={uniqueBenefitTooltips.BenefitCategory}
                    />
                ) : (
                    <AutocompleteSelect
                        autocompleteField={AutocompleteField.BenefitCategory}
                        form={form}
                        id="category"
                        label="Benefit Category"
                        initialValue={benefitData.benefitCategory}
                        disabled={benefitTemplateChild}
                        searchParams={notArchivedFilterParam}
                        required={true}
                        tooltip={uniqueBenefitTooltips.BenefitCategory}
                    />
                )}
                <Select
                    form={form}
                    id="method"
                    placeholder="Benefit Model"
                    options={benefitMethod}
                    required={false}
                    initialValue={benefitData.method}
                    allowClear
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.BenefitModel
                            : uniqueBenefitTooltips.BenefitModel
                    }
                />
                <AutocompleteSelect
                    autocompleteField={AutocompleteField.Location}
                    form={form}
                    id="location"
                    label="Benefit Location"
                    initialValue={benefitData.location}
                    searchParams={notArchivedFilterParam}
                    required={false}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.BenefitLocation
                            : uniqueBenefitTooltips.BenefitLocation
                    }
                />
                <Input
                    form={form}
                    id="points"
                    label={placeholderNumberOfPoints}
                    required={false}
                    initialValue={benefitData.points ? parseFloat(benefitData.points) : ''}
                    type="number"
                    validator={checkDecimalPlaceConfirmation}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.NumberOfPoints
                            : uniqueBenefitTooltips.NumberOfPoints
                    }
                />
                <Input
                    form={form}
                    id="value"
                    label={placeholderHintValue}
                    required={false}
                    initialValue={benefitData.value ? parseFloat(benefitData.value) : ''}
                    type="number"
                    validator={checkDecimalPlaceConfirmation}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.BenefitValue
                            : uniqueBenefitTooltips.BenefitValue
                    }
                />
                <Input
                    form={form}
                    id="emissionSavings"
                    label={placeholderEmissionSavingsValue}
                    required={false}
                    initialValue={
                        benefitData.emissionSavings ? parseFloat(benefitData.emissionSavings) : ''
                    }
                    type="number"
                    validator={checkDecimalPlaceConfirmation}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.EmissionsSavings
                            : uniqueBenefitTooltips.EmissionsSavings
                    }
                />
                <Input
                    form={form}
                    id="quantity"
                    label="Quantity"
                    initialValue={benefitData.quantity}
                    type="number"
                    validator={checkIntegerConfirmation}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.Quantity
                            : uniqueBenefitTooltips.Quantity
                    }
                />
                <PrioritiesForm
                    form={form}
                    selectedPriorities={this.state.priorities}
                    selectNewPriority={this.selectNewPriority}
                    deletePriority={this.deletePriority}
                    changePriorityValue={this.changePriorityValue}
                    autocompleteField={AutocompleteField.BenefitPriorities}
                    id="priorities"
                    placeholder="Benefit Priorities"
                    notFoundContent="No benefit priorities found"
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.BenefitPriorities
                            : uniqueBenefitTooltips.BenefitPriorities
                    }
                />
                <AutocompleteSelect
                    form={form}
                    autocompleteField={AutocompleteField.Employee}
                    id="assessors"
                    label="Contributors (Please read tooltip before use)"
                    placeholder="Add Contributor"
                    required={false}
                    onSelect={(id) =>
                        addRole(BenefitRole.ASSESSOR, this.props.organisationId, id as number)
                    }
                    searchParams={notArchivedFilterParam}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.ProjectManagers
                            : uniqueBenefitTooltips.ProjectManagers
                    }
                />
                <RolesList
                    items={
                        benefitAssessors &&
                        benefitAssessors.map(({ user, id, archived }) => ({
                            name: getUsername({
                                firstName: user?.firstName || '',
                                lastName: user?.lastName || '',
                                archived,
                            }),
                            id,
                        }))
                    }
                    deleteItem={(id) => this.props.deleteRole(id)}
                    noData="No Contributors added"
                />
                <EditSupplierForm
                    form={form}
                    benefitId={this.props.benefitId}
                    contractId={this.props.contractId}
                    selectedSupplier={benefitSuppliers}
                    selectSupplierOrganisation={this.selectSupplierOrganisation}
                    deleteSupplierOrganisation={this.deleteSupplierOrganisation}
                    selectedSupplierUsers={benefitIndividualSuppliers}
                    selectSupplierUser={this.selectSupplierUser}
                    deleteSupplierUser={this.deleteSupplierUser}
                    pendingSupplierUsers={pendingSupplierUsers}
                    selectInvitePendingSupplierUser={this.selectInvitePendingSupplierUser}
                    deletePendingSupplierUser={this.deletePendingInvite}
                    inviteNewEmployee={inviteNewEmployee}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.Supplier
                            : uniqueBenefitTooltips.Supplier
                    }
                />
                <EditDeliveryPartnerForm
                    form={form}
                    benefitId={this.props.benefitId}
                    contractId={this.props.contractId}
                    selectedPartners={benefitPartners}
                    selectPartnerOrganisation={this.selectDeliveryPartnerOrganisation}
                    deletePartner={this.deleteDeliveryPartner}
                    selectedPartnerUsers={benefitIndividualPartners}
                    selectPartnerUser={this.selectDeliveryPartnerUser}
                    deletePartnerUser={this.deletePartnerUser}
                    pendingPartnerUsers={pendingPartnerUsers}
                    selectInvitePendingPartnerUser={this.selectInvitePendingPartnerUser}
                    deletePendingPartnerUser={this.deletePendingInvite}
                    inviteNewEmployee={inviteNewEmployee}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.DeliveryPartners
                            : uniqueBenefitTooltips.DeliveryPartners
                    }
                />
                <DatePicker
                    form={form}
                    id="startDate"
                    label="Start Date"
                    initialValue={benefitData.startDate}
                    required={false}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.StartDate
                            : uniqueBenefitTooltips.StartDate
                    }
                />
                <DynamicFieldSet
                    fieldSetId="deliveryDates"
                    fieldType="deliveryDate"
                    initialFields={benefitData.deliveryDates?.map((date) => ({
                        id: date.id,
                        date: date.date,
                        title: date.title,
                    }))}
                    label="Interim Delivery Date"
                    placeholder="Interim Delivery Date"
                    form={form}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.InterimDeliveryDate
                            : uniqueBenefitTooltips.InterimDeliveryDate
                    }
                />
                <DatePicker
                    form={form}
                    id="finalDeliveryDate"
                    label="Benefit Completion Date"
                    initialValue={benefitData.endDate}
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.FinalDeliveryDate
                            : uniqueBenefitTooltips.FinalDeliveryDate
                    }
                />
                <EditStatusExplanation
                    form={form}
                    benefitStatus={benefit.status}
                    benefitStatusExplanation={benefit.statusExplanation}
                    deliveredEmissionSavings={
                        benefit.deliveredEmissionSavings
                            ? parseFloat(benefit.deliveredEmissionSavings)
                            : undefined
                    }
                    deliveredPoints={
                        benefit.deliveredPoints ? parseFloat(benefit.deliveredPoints) : undefined
                    }
                    deliveredValue={
                        benefit.deliveredValue ? parseFloat(benefit.deliveredValue) : undefined
                    }
                    tooltip={
                        benefitTemplateChild
                            ? templatedBenefitTooltips.BenefitStatus
                            : uniqueBenefitTooltips.BenefitStatus
                    }
                />
                <SubmitButton value="Update Benefit" />
            </StyledAntd3Form>
        );
    }
}

export const EditBenefitForm = Antd3Form.create({})(EditBenefit);
