import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { EditBenefitTemplateForm } from 'features/edit/components/EditBenefitTemplateForm';

import { getBenefitTemplateRequest } from '../actions';

export const EditBenefitTemplateFormContainer = () => {
    const dispatch = useDispatch();
    const { benefitId } = useParams<{ benefitId: string }>();

    useEffect(() => {
        dispatch(getBenefitTemplateRequest(Number(benefitId)));
    }, [benefitId]);

    return <EditBenefitTemplateForm />;
};
