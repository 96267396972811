import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { createBenefitRequest, selectBenefitTemplateRequest } from 'features/benefit/actions';
import {
    getCreatingBenefitStatus,
    getSelectedBenefitTemplateId,
} from 'features/benefit/selectors/benefitSelectors';

import {
    getBenefitTemplateWhenCreatingTemplatedBenefitRequest,
    clearSelectedBenefitTemplate,
} from 'features/benefit/actions/benefitTemplateActions';
import { getUserOrganisationId, isAdmin } from 'features/user/selectors/userSelectors';
import { redirectToUrl } from 'common/actions/navigationAction';
import { BenefitTemplate } from 'features/benefit/models/benefitModels';

import { CreateBenefit as CreateBenefitComponent } from '../components/CreateBenefit';
import { EditBenefitRouteParams } from '../models/createModel';

export interface CreateBenefitContainerStateProps {
    isCreating: boolean;
    contractId?: string;
    isUserAdmin: boolean;
    organisation: number;
    selectedBenefitTemplate: BenefitTemplate[];
}

export interface CreateBenefitContainerDispatchProps {
    createBenefitRequest: typeof createBenefitRequest;
    selectBenefitTemplateRequest: typeof selectBenefitTemplateRequest;
    redirectToUrl: typeof redirectToUrl;
    getBenefitTemplateWhenCreatingTemplatedBenefitRequest: typeof getBenefitTemplateWhenCreatingTemplatedBenefitRequest;
    clearSelectedBenefitTemplate: typeof clearSelectedBenefitTemplate;
}

export type CreateBenefitContainerProps = CreateBenefitContainerStateProps &
    CreateBenefitContainerDispatchProps &
    EditBenefitRouteParams;

class CreateBenefit extends React.Component<CreateBenefitContainerProps> {
    public componentWillUnmount(): void {
        this.props.clearSelectedBenefitTemplate();
    }
    public render(): JSX.Element {
        return <CreateBenefitComponent {...this.props} />;
    }
}

const mapStateToProps = (
    state: AppState,
    ownProps: EditBenefitRouteParams,
): CreateBenefitContainerStateProps => ({
    isCreating: getCreatingBenefitStatus(state),
    contractId: ownProps.match.params.contractId,
    isUserAdmin: isAdmin(state),
    organisation: getUserOrganisationId(state),
    selectedBenefitTemplate: getSelectedBenefitTemplateId(state),
});

export const CreateBenefitContainer = connect(mapStateToProps, {
    createBenefitRequest,
    selectBenefitTemplateRequest,
    redirectToUrl,
    getBenefitTemplateWhenCreatingTemplatedBenefitRequest,
    clearSelectedBenefitTemplate,
})(CreateBenefit);
