import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Organisation } from 'features/organisation/models/organisationModels';
import { removeExecutiveRequest } from 'features/organisation/actions/organisationActions';
import { getExecutives } from 'features/organisation/selectors/organisationSelectors';
import {
    paginationRequest,
    saveFilters,
    clearFilters,
} from 'features/pagination/actions/paginationActions';
import { redirectToUrl } from 'common/actions/navigationAction';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { RouteParams, getPageNumber } from 'common/selectors/routeSelectors';
import { AppState } from 'common/appState';

import { EditOrganisations as EditOrganisationComponent } from '../components/EditOrganisations';

export interface EditOrganisationStateProps {
    executives: Organisation[];
    pageNumber?: number;
}

interface EditOrganisationContainerDispatchProps {
    removeExecutiveRequest: typeof removeExecutiveRequest;
    redirectToUrl: typeof redirectToUrl;
    paginationRequest: typeof paginationRequest;
    saveFilters: typeof saveFilters;
    clearFilters: typeof clearFilters;
}

export type EditOrganisationProps = EditOrganisationStateProps &
    EditOrganisationContainerDispatchProps;

class EditOrganisation extends React.Component<EditOrganisationProps> {
    public componentDidMount = () => this.props.clearFilters(PaginationView.EditOrganisations);

    public render(): JSX.Element {
        return <EditOrganisationComponent {...this.props} />;
    }
}

const mapStateToProps = createStructuredSelector<AppState, RouteParams, EditOrganisationStateProps>(
    {
        executives: getExecutives,
        pageNumber: (_, ownProps) => getPageNumber(ownProps),
    },
);

export const EditOrganisationContainer = connect(mapStateToProps, {
    redirectToUrl,
    removeExecutiveRequest,
    paginationRequest,
    saveFilters,
    clearFilters,
})(EditOrganisation);
