import * as React from 'react';
import { Button, Upload, Tooltip } from 'antd';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';
import styled from 'styled-components';

import { sizes } from 'theme/variables';

import { Antd3Form, Antd3Icon, Antd3FormProps } from '../deprecated/antd3';

interface UploadEvidenceScoreTemplateProps extends Antd3FormProps {
    id: string;
    description: string;
    required?: boolean;
    disabled?: boolean;
    accept?: string;
    onChange?(): void;
    addFile: (id: string, file: IUploadFile) => void;
    removeFile: (id: string) => void;
    tooltip?: string;
}

interface UploadFileState {
    fileList: IUploadFile[];
}

const StyledUpload = styled(Upload)`
    button.ant-btn,
    .ant-upload {
        width: 100%;
        max-width: ${sizes.formWidth};
    }
`;

export class UploadEvidenceScoreTemplate extends React.Component<
    UploadEvidenceScoreTemplateProps,
    UploadFileState
> {
    public state: UploadFileState = {
        fileList: [],
    };

    public render(): JSX.Element {
        const uploadLimit = 1;
        const props = {
            onChange: () => {
                this.setState(({ fileList }) => ({ fileList: fileList.slice(-uploadLimit) }));
                return this.props.onChange && this.props.onChange();
            },
            beforeUpload: (file: IUploadFile) => {
                this.props.addFile(this.props.id, file);
                this.setState(({ fileList }) => ({ fileList: [...fileList, file] }));
                return false;
            },
            onRemove: (fileToRemove: IUploadFile) => {
                this.props.removeFile(this.props.id);
                this.setState(({ fileList }) => ({
                    fileList: fileList.filter((file) => file !== fileToRemove),
                }));
            },
            fileList: this.state.fileList,
            disabled: this.props.disabled,
            accept: this.props.accept,
            multiple: false,
        };

        const { disabled, tooltip } = this.props;

        return (
            <Antd3Form.Item>
                <StyledUpload {...props}>
                    <Button size="large" disabled={disabled} loading={disabled}>
                        <Antd3Icon type="upload" />
                        {this.props.description}
                    </Button>
                </StyledUpload>
                <Tooltip title={tooltip}>
                    <Antd3Icon type="info-circle-o" />
                </Tooltip>
            </Antd3Form.Item>
        );
    }
}
