import React from 'react';
import { Badge, Space } from 'antd';
import styled from 'styled-components';

export interface GdprStatusProps {
    agreedToGdpr?: boolean;
}

const GdprStatusWrapper = styled(Space)`
    width: 100%;
    justify-content: space-between;

    p {
        margin: 0;
    }
`;

export const GdprStatus: React.FunctionComponent<GdprStatusProps> = ({ agreedToGdpr }) => (
    <GdprStatusWrapper align="center">
        <Badge status={agreedToGdpr ? 'success' : 'error'} />
        {agreedToGdpr ? (
            <p>
                This submission can contain personally identifiable information about a data
                subject, because the GDPR confirmation box was checked.
            </p>
        ) : (
            <p>
                This submission cannot contain personally identifiable information about a data
                subject, because the GDPR confirmation box was not checked.
            </p>
        )}
    </GdprStatusWrapper>
);
