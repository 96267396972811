import { Form, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Moment } from 'moment';

import { Input, DatePicker } from 'common/components';
import { actionPropsByAction } from 'features/upload/helpers';
import { BenefitChangeSuggestionsFormItemDeliveryDate } from 'features/upload/models/uploadModels';
import { dateFormat } from 'config/config';

import * as S from '../shared/styles';

type Props = {
    name: number;
    evidenceDateId?: number;
    onRemove: (name: number) => () => void;
};

export const DeliveryDateSuggestionCard = ({ name, evidenceDateId, onRemove }: Props) => {
    const deliveryDates: BenefitChangeSuggestionsFormItemDeliveryDate[] | undefined = Form.useWatch(
        ['benefitChangeSuggestions', 'deliveryDates'],
    );
    const deliveryDate = deliveryDates ? deliveryDates[name] : undefined;

    if (!deliveryDates || !deliveryDate) {
        return null;
    }

    const getDisabledDates = (date: Moment) => {
        const interimDates = deliveryDates
            .filter((deliveryDate) => !deliveryDate.isStart && !deliveryDate.isFinal)
            .map((deliveryDate) => deliveryDate.date);

        const benefitStartDate = deliveryDates.find((deliveryDate) => deliveryDate.isStart)?.date;
        const benefitFinalDate = deliveryDates.find((deliveryDate) => deliveryDate.isFinal)?.date;

        return Boolean(
            date.isSame(benefitStartDate) ||
                date.isSame(benefitFinalDate) ||
                interimDates.find((interimDate) => interimDate?.isSame(date)),
        );
    };

    const isInterim = !deliveryDate.isFinal && !deliveryDate.isStart;

    const isTitleUpdated = deliveryDate.title !== deliveryDate.prevTitle;
    const isDateUpdated = !deliveryDate.date?.isSame(deliveryDate.prevDate);
    const isUpdated = deliveryDate.action === 'update' && (isTitleUpdated || isDateUpdated);

    const actionProps = deliveryDate.action && actionPropsByAction[deliveryDate.action];
    const displayChange = actionProps && (deliveryDate.action !== 'update' || isUpdated);

    return (
        <S.SuggestionWrapper color={displayChange ? actionProps.color : undefined}>
            {displayChange && (
                <S.ChangeInfo>
                    <Tag color={actionProps.color}>
                        {actionProps.label}
                        {isUpdated && (
                            <span>
                                {isTitleUpdated
                                    ? ` | Previous title: "${deliveryDate.prevTitle}"`
                                    : null}
                                {isDateUpdated
                                    ? ` | Previous date: ${deliveryDate.prevDate?.format(
                                          dateFormat,
                                      )}`
                                    : null}
                            </span>
                        )}
                    </Tag>
                </S.ChangeInfo>
            )}
            <S.InputsWrapper direction="horizontal" align="start">
                <Input
                    label="Title"
                    name={[name, 'title']}
                    disabled={!isInterim || deliveryDate.action === 'delete'}
                    noBottomMargin
                />
                <DatePicker
                    label="Date"
                    name={[name, 'date']}
                    disabled={deliveryDate.action === 'delete'}
                    noBottomMargin
                    disabledDate={getDisabledDates} // temporary fix as start and end date should have different ranges
                />
            </S.InputsWrapper>
            <Input name={[name, 'id']} disabled hidden required={false} />
            {isInterim && evidenceDateId !== deliveryDate.id && deliveryDate.needsUserAction && (
                <S.RemoveButton onClick={onRemove(name)} shape="circle" icon={<CloseOutlined />} />
            )}
        </S.SuggestionWrapper>
    );
};
