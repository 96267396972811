import styled from 'styled-components';
import { DatePicker } from 'antd';
import moment from 'moment';

import { FormDeliveryDate } from 'features/benefit/models/benefitModels';

import { Antd3Input, Antd3Form, Antd3InputProps } from '../antd3';
import { DynamicFieldSetItemProps } from './DynamicFieldSet';

const InterimDateWrapper = styled.div`
    width: 100%;
`;

const StyledDatePicker = styled(DatePicker)`
    width: 100%;
`;

type Props = FormDeliveryDate & Antd3InputProps & DynamicFieldSetItemProps;

export const InterimDate = ({
    form,
    id,
    fullId,
    isTemporaryId,
    date,
    title,
    onInputChange,
}: Props) => {
    const handleInputUpdate = (fieldName: string, value: any) => {
        if (onInputChange) {
            onInputChange(id, fieldName, value);
        }
    };

    return (
        <InterimDateWrapper>
            <Antd3Form.Item required={false}>
                <Antd3Input
                    form={form}
                    id={`${fullId}[title]`}
                    getFieldDecoratorOptions={{
                        initialValue: title,
                    }}
                    size="large"
                    placeholder="Title"
                    onChange={(event) => handleInputUpdate('title', event.target.value)}
                />
            </Antd3Form.Item>
            <Antd3Form.Item required={false}>
                {form.getFieldDecorator(`${fullId}[date]`, {
                    initialValue: date && moment(date),
                })(
                    <StyledDatePicker
                        size="large"
                        placeholder="Date"
                        onChange={(value) => handleInputUpdate('date', value)}
                    />,
                )}
            </Antd3Form.Item>
            {!isTemporaryId && (
                <Antd3Form.Item required={false}>
                    <Antd3Input
                        form={form}
                        id={`${fullId}[id]`}
                        getFieldDecoratorOptions={{
                            initialValue: id,
                        }}
                        disabled
                        hidden
                    />
                </Antd3Form.Item>
            )}
        </InterimDateWrapper>
    );
};
