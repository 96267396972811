import { config } from 'config/config';

interface ErrorMessage {
    message: string;
}

interface ErrorStatus {
    status: number;
    path?: string;
}

export const showErrorMessage = ({ message }: ErrorMessage) => Promise.reject(new Error(message));

export const renderErrorMessage = ({ status, path }: ErrorStatus) =>
    Promise.reject(new Error(getErrorMessage(status, path)));

const getErrorMessage = (status: number, path?: string) => {
    if (status === 500) {
        if (path === config.routeConfig.logInRoute) {
            return `You have entered your Cenefits account password incorrectly more than ten times in a row. We've locked your account in order to keep your information and our platform secure`;
        }
        return 'Internal server error';
    }

    if (status === 403) {
        if (path === config.routeConfig.logInRoute) {
            return `Too many failed login attempts, please contact support to access your account`;
        }
        return 'You do not have permission to perform this action';
    }

    if (status === 404) {
        return 'Not found';
    }

    if (status === 401) {
        return 'Unauthorised';
    }

    return 'Connection error';
};
