import * as React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

import { breakpointsValues } from 'theme/variables';

import * as S from './styles';

interface Props {
    leftButtons?: JSX.Element[];
    filters?: JSX.Element[];
    filtersButtons?: JSX.Element[];
    rightButtons?: JSX.Element[];
}

export const TableFiltersBar: React.FunctionComponent<Props> = ({
    leftButtons,
    filters,
    filtersButtons,
    rightButtons,
}) => {
    const [areFiltersVisible, setAreFiltersVisible] = React.useState(false);

    const toggleFilters = () => setAreFiltersVisible(!areFiltersVisible);

    const toggleFiltersByResize = (event: Event) => {
        if ((event.target as Window).innerWidth >= breakpointsValues.large) {
            setAreFiltersVisible(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('resize', toggleFiltersByResize);
    }, []);

    const renderFiltersContent = (desktopOnly?: boolean) =>
        filters ? (
            <S.Filters desktoponly={desktopOnly ? 1 : 0}>
                {filters.map((Filter, i) => (
                    <S.FilterWrapper key={i}>{Filter}</S.FilterWrapper>
                ))}
                {filtersButtons && (
                    <S.FiltersButtons>
                        {filtersButtons.map((Button, i) => (
                            <React.Fragment key={i}>{Button}</React.Fragment>
                        ))}
                    </S.FiltersButtons>
                )}
            </S.Filters>
        ) : null;

    return (
        <S.FiltersBarWrapper>
            <div>
                <S.LeftButtons>
                    {leftButtons?.map((Button, i) => (
                        <React.Fragment key={i}>{Button}</React.Fragment>
                    ))}
                </S.LeftButtons>
                {renderFiltersContent(true)}
                <S.RightButtons>
                    {rightButtons?.map((Button, i) => (
                        <React.Fragment key={i}>{Button}</React.Fragment>
                    ))}
                    {filters && (
                        <S.MobileFiltersButton
                            type={areFiltersVisible ? 'primary' : 'default'}
                            icon={<MenuOutlined />}
                            onClick={toggleFilters}
                        >
                            <span>Filters</span>
                        </S.MobileFiltersButton>
                    )}
                </S.RightButtons>
            </div>
            {filters && (
                <S.MobileFilters activeKey={areFiltersVisible ? 0 : undefined}>
                    <Collapse.Panel header="This is panel header 1" key="0">
                        {renderFiltersContent()}
                    </Collapse.Panel>
                </S.MobileFilters>
            )}
        </S.FiltersBarWrapper>
    );
};
