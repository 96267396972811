import React from 'react';
import { List, Button, ConfigProvider } from 'antd';
import styled from 'styled-components';

import { FileData } from 'features/upload/models/uploadModels';
import { openModal } from 'common/helpers/modal';
import { Antd3Icon } from 'common/components/deprecated/antd3';

interface FileListProps {
    files?: FileData[];
    loading?: boolean;
    onDelete?: ((id: number) => void) | false;
    editDescription?: boolean;
    onEdit?: ((id: number, value: string) => void) | false;
    emptyText?: string;
    renderEmpty?: React.ReactNode;
}

const StyledListWrapper = styled.div`
    .ant-list-item {
        cursor: default;
    }
`;

export const FileList: React.FunctionComponent<FileListProps> = ({
    files,
    loading,
    onDelete,
    emptyText,
    renderEmpty,
}) => {
    const onClick = (event: React.FormEvent, id: number) => {
        event.preventDefault();
        return (
            onDelete &&
            openModal({
                callback: () => onDelete(id),
                content: "Are you sure? Once a file has been deleted, it can't be recovered.",
            })
        );
    };

    return (
        <StyledListWrapper>
            <ConfigProvider renderEmpty={() => renderEmpty}>
                <List
                    loading={loading}
                    split={true}
                    dataSource={files}
                    locale={{ emptyText: emptyText || '' }}
                    renderItem={({ id, file, name, fileName }: FileData) => (
                        <List.Item
                            key={id}
                            actions={
                                onDelete
                                    ? [
                                          <Button
                                              type="danger"
                                              ghost
                                              size="small"
                                              onClick={(e: React.FormEvent) => onClick(e, id)}
                                          >
                                              <Antd3Icon type="delete" />
                                              Remove
                                          </Button>,
                                      ]
                                    : []
                            }
                        >
                            <List.Item.Meta
                                title={name}
                                description={
                                    <a href={file} target="_blank" rel="noopener noreferrer">
                                        <Antd3Icon type="file" /> {fileName || 'Download'}
                                    </a>
                                }
                            />
                        </List.Item>
                    )}
                />
            </ConfigProvider>
        </StyledListWrapper>
    );
};
