import { switchMap, pluck } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { AppEpic } from 'common/epics/appEpic';

import * as actions from '../actions/importActions';
import { ImportService } from '../services/importService';

export const importEpicFactory = (importService: ImportService): AppEpic => {
    const importContractsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.IMPORT_CONTRACTS_REQUESTED),
            pluck('payload'),
            switchMap((file: File) =>
                importService
                    .importContracts(file)
                    .then(actions.importContractsSuccess)
                    .catch(actions.importContractsFailure),
            ),
        );

    const importBenefitsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.IMPORT_BENEFITS_REQUESTED),
            pluck('payload'),
            switchMap((file: File) =>
                importService
                    .importBenefits(file)
                    .then(actions.importBenefitsSuccess)
                    .catch(actions.importBenefitsFailure),
            ),
        );

    const importBenefitTemplatesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.IMPORT_BENEFIT_TEMPLATES_REQUESTED),
            pluck('payload'),
            switchMap((file: File) =>
                importService
                    .importBenefitTemplates(file)
                    .then(actions.importBenefitTemplatesSuccess)
                    .catch(actions.importBenefitTemplatesFailure),
            ),
        );

    return combineEpics(importContractsEpic, importBenefitsEpic, importBenefitTemplatesEpic);
};
