import * as React from 'react';
import { Redirect } from 'react-router';

import { UploadPaths } from 'features/upload/models/uploadModels';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { generateColumns } from 'common/helpers/utils';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getContractPriorityDashboardSuccess } from 'features/contract/actions/contractActions';
import { DownloadPaths } from 'features/download/models/downloadModel';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { SaveFilters, ClearFilters } from 'features/pagination/actions/paginationActions';
import { ContractPriorityDashboardItem } from 'features/contract/models/contractModels';
import { EditPaths } from 'features/edit/models/editModel';
import { PaddedSection } from 'common/components';

import { DashboardTableFiltersBar } from './DashboardTableFiltersBar';
import { ContractPriorityDashboardContainerProps } from '../containers/ContractPriorityDashboardContainer';
import { DashboardPaths } from '../models/dashboardModel';
import { ActionableTableRow } from './ActionableTableRow';

interface ContractPriorityDashboardDispatchProps {
    redirect(path: string): void;
}

type Props = ContractPriorityDashboardContainerProps & ContractPriorityDashboardDispatchProps;

const contractPriorityDashboardColumns = (
    clearFilters: (paginatedView: PaginationView) => ClearFilters,
    saveFilters: (filters: PaginationFilters) => SaveFilters,
) => [
    {
        title: 'Name',
        key: 'name',
        render: (name: string, { id }: ContractPriorityDashboardItem) => (
            <ActionableTableRow
                label={name}
                edit={{
                    paginatedView: PaginationView.Contracts,
                    values: { priorities: [id.toString()] },
                    path: EditPaths.Contracts,
                }}
                download={{
                    paginatedView: PaginationView.DownloadContracts,
                    values: { priorities: [id.toString()] },
                    path: DownloadPaths.Contracts,
                }}
                handlers={{ clearFilters, saveFilters }}
            />
        ),
    },
    {
        title: 'Delivered Priority Value',
        key: 'deliveredPriorityValue',
    },
];

export class ContractPriorityDashboardComponent extends React.Component<Props> {
    public render(): JSX.Element {
        const { loading, isUserTenant } = this.props;

        if (!loading && !isUserTenant) {
            return <Redirect to={UploadPaths.PendingEvidence} />;
        }

        return (
            <PaddedSection header="Project Priorities">
                <DashboardTableFiltersBar
                    filterPageUrl={DashboardPaths.ContractPrioritiesFilter}
                    filterId={PaginationView.ContractPrioritiesDashboard}
                />
                <PaginatedTable
                    data={this.props.priorities}
                    columns={generateColumns(
                        contractPriorityDashboardColumns(
                            this.props.clearFilters,
                            this.props.saveFilters,
                        ),
                    )}
                    emptyText="No contract priorities found"
                    view={PaginationView.ContractPrioritiesDashboard}
                    paginationSuccessCallback={getContractPriorityDashboardSuccess}
                />
            </PaddedSection>
        );
    }
}
