import { useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';

import { EditPaths } from 'features/edit/models/editModel';
import { InvitePaths } from 'features/invite/models/inviteModel';
import { ContentMenu, RouteProps, Breadcrumb } from 'common/components';
import { AppState } from 'common/appState';
import {
    getPaginationState,
    getPaginationView,
} from 'features/pagination/selectors/paginationSelectors';
import { PaginationView } from 'features/pagination/models/paginationModel';

import { EditContractPaths } from '../models/contractModels';
import { getContractByRouteId } from '../selectors/contractSelectors';

export const EditContractNavigation = (routeProps: RouteProps) => {
    const pagination = useSelector(getPaginationState);
    const view = useSelector(getPaginationView);

    const contractId = routeProps.match.params.id;

    const contract = contractId
        ? useSelector((state: AppState) => getContractByRouteId(state, { id: contractId }))
        : undefined;

    if (!contract) {
        return null;
    }

    const menuLinks = [
        {
            key: `${EditPaths.Contract}/${contractId}${EditContractPaths.ContractDetails}`,
            label: 'Project Details',
        },
        {
            key: `${EditPaths.Contract}/${contractId}${EditContractPaths.ContractForm}`,
            label: 'Edit Project',
        },
        {
            key: `${EditPaths.Contract}/${contractId}${EditContractPaths.Benefits}`,
            label: 'Benefits',
        },
        {
            key: `${EditPaths.Contract}/${contractId}${EditContractPaths.Invitations}`,
            label: 'Invitations',
            additionalActiveKeys: [InvitePaths.ExpiredInvitations],
        },
    ];

    const menuLinksWithNotes = [
        ...menuLinks,
        {
            key: `${EditPaths.Contract}/${contractId}${EditContractPaths.Notes}`,
            label: 'Private Notes',
        },
    ];

    return (
        <>
            <Breadcrumb
                links={[
                    {
                        title:
                            view === PaginationView.MyContracts
                                ? 'Edit My Projects'
                                : 'Edit Projects',
                        to: `${
                            view === PaginationView.MyContracts
                                ? EditPaths.MyContracts
                                : EditPaths.Contracts
                        }/${pagination.current}`,
                        icon: <LeftOutlined />,
                    },
                    {
                        title: contract.title,
                    },
                ]}
            />
            <ContentMenu
                adminMenuLinks={menuLinksWithNotes}
                contractManagerMenuLinks={menuLinksWithNotes}
                menuLinks={menuLinks}
                small
            />
        </>
    );
};
