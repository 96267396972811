import { switchMap } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { AppEpic } from 'common/epics/appEpic';
import { APP_INIT } from 'common/actions/appInit';
import { AuthService } from '../services/authService';
import * as actions from '../actions/authActions';

export const tokenEpicFactory = (authService: AuthService): AppEpic => {
    const verifyTokenEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(APP_INIT, actions.REFRESH_TOKEN_REQUESTED),
            switchMap(() =>
                authService.refreshToken().then(actions.logInSuccess).catch(actions.logOutRequest),
            ),
        );

    return combineEpics(verifyTokenEpic);
};
