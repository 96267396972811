import * as React from 'react';
import { Button, Upload } from 'antd';
import { UploadFile as IUploadFile } from 'antd/lib/upload/interface';
import styled from 'styled-components';

import { sizes } from 'theme/variables';

import { Antd3Form, Antd3FormProps, Antd3Icon } from '../antd3';

interface UploadFileProps extends Antd3FormProps {
    description: string;
    required?: boolean;
    onChange?(): void;
    disabled?: boolean;
    accept?: string;
}

interface UploadFileState {
    fileList: IUploadFile[];
}

const StyledUpload = styled(Upload)`
    button.ant-btn,
    .ant-upload {
        width: 100%;
        max-width: ${sizes.formWidth};
    }
`;

export class UploadFile extends React.Component<UploadFileProps, UploadFileState> {
    public state: UploadFileState = {
        fileList: [],
    };

    public render(): JSX.Element {
        const uploadLimit = 1;
        const props = {
            onChange: () => {
                this.setState(({ fileList }) => ({ fileList: fileList.slice(-uploadLimit) }));
                return this.props.onChange && this.props.onChange();
            },
            beforeUpload: (file: IUploadFile) => {
                this.setState(({ fileList }) => ({ fileList: [...fileList, file] }));
                return false;
            },
            onRemove: (fileToRemove: IUploadFile) => {
                this.setState(({ fileList }) => ({
                    fileList: fileList.filter((file) => file !== fileToRemove),
                }));
            },
            fileList: this.state.fileList,
            disabled: this.props.disabled,
            accept: this.props.accept,
        };

        const { disabled, required, description, form } = this.props;

        return (
            <Antd3Form.Item>
                {form.getFieldDecorator('fileList', {
                    rules: [{ required, message: 'Please upload a file' }],
                })(
                    <StyledUpload {...props}>
                        <Button size="large" disabled={disabled} loading={disabled}>
                            <Antd3Icon type="upload" />
                            {description}
                        </Button>
                    </StyledUpload>,
                )}
            </Antd3Form.Item>
        );
    }
}
