import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'common/appState';
import { redirectToUrl } from 'common/actions/navigationAction';
import { RouteParams, getRouteId, getBenefitId } from 'common/selectors/routeSelectors';
import {
    getContractRequest,
    getContractRolesRequest,
} from 'features/contract/actions/contractActions';
import { isContractManager } from 'features/contract/selectors/contractSelectors';
import {
    getBenefitNotesRequest,
    createBenefitNoteRequest,
    deleteBenefitNoteRequest,
    openForm,
    closeForm,
    editBenefitNoteRequest,
} from 'features/benefit/actions/benefitActions';
import {
    getBenefitNotes,
    getBenefitNoteFormVisibility,
} from 'features/benefit/selectors/benefitSelectors';
import { getUserOrganisationId, isAdmin } from 'features/user/selectors/userSelectors';

import { BenefitNotes as BenefitNotesComponent } from '../components/BenefitNotes';
import { BenefitNoteData } from '../models/benefitModels';

export interface BenefitNotesContainerStateProps {
    benefitNotes: BenefitNoteData[];
    contractId: number;
    organisationId: number;
    formVisible: boolean;
    benefitId: number;
    isContractManager: boolean;
    isAdmin: boolean;
}

interface BenefitNotesDispatchProps {
    redirectToUrl: typeof redirectToUrl;
    getBenefitNotesRequest: typeof getBenefitNotesRequest;
    createBenefitNoteRequest: typeof createBenefitNoteRequest;
    deleteBenefitNoteRequest: typeof deleteBenefitNoteRequest;
    openForm: typeof openForm;
    closeForm: typeof closeForm;
    editBenefitNoteRequest: typeof editBenefitNoteRequest;
    getContractRolesRequest: typeof getContractRolesRequest;
    getContractRequest: typeof getContractRequest;
}

export type BenefitNotesProps = BenefitNotesContainerStateProps &
    BenefitNotesDispatchProps &
    RouteParams;

class BenefitNotes extends React.Component<BenefitNotesProps> {
    public componentDidMount(): void {
        const { contractId, benefitId } = this.props;

        this.props.getContractRequest({ contractId });
        this.props.getContractRolesRequest(contractId);

        this.props.getBenefitNotesRequest({
            contract: contractId,
            benefit: benefitId,
        });
    }

    public render(): JSX.Element {
        return <BenefitNotesComponent {...this.props} />;
    }
}

const mapStateToProps = (
    state: AppState,
    ownProps: RouteParams,
): BenefitNotesContainerStateProps => ({
    benefitNotes: getBenefitNotes(state),
    contractId: getRouteId(ownProps),
    benefitId: getBenefitId(ownProps),
    organisationId: getUserOrganisationId(state),
    formVisible: getBenefitNoteFormVisibility(state),
    isContractManager: isContractManager(state),
    isAdmin: isAdmin(state),
});

export const BenefitNotesContainer = connect(mapStateToProps, {
    redirectToUrl,
    getBenefitNotesRequest,
    createBenefitNoteRequest,
    deleteBenefitNoteRequest,
    openForm,
    closeForm,
    editBenefitNoteRequest,
    getContractRequest,
    getContractRolesRequest,
})(BenefitNotes);
