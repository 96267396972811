import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';

import { Form } from 'common/components';
import { WarningMessage } from 'common/components/';
import { getUserActiveOrganisation } from 'features/user/selectors/userSelectors';
import {
    BenefitQuestionnaires,
    BenefitQuestionnairesEditabilityType,
    BenefitQuestionnaireType,
} from 'features/benefit/models/questionnaireModels';
import { notificationText, showNotification } from 'common/helpers/notifications';
import { QuestionnaireTemplate } from 'features/benefit/components/QuestionnaireTemplate/QuestionnaireTemplate';

import { editOrganisationRequest } from '../actions/organisationActions';
import { Organisation } from '../models/organisationModels';

const generateInitialFormValues = (organisation: Organisation): BenefitQuestionnaires => ({
    [BenefitQuestionnaireType.EVIDENCE_ASSESSOR]:
        organisation[BenefitQuestionnaireType.EVIDENCE_ASSESSOR],
    [BenefitQuestionnaireType.SCORE_ASSESSOR]:
        organisation[BenefitQuestionnaireType.SCORE_ASSESSOR],
    [BenefitQuestionnaireType.EVIDENCE_SUPPLIER]:
        organisation[BenefitQuestionnaireType.EVIDENCE_SUPPLIER],
    [BenefitQuestionnaireType.SCORE_SUPPLIER]:
        organisation[BenefitQuestionnaireType.SCORE_SUPPLIER],
    [BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER]:
        organisation[BenefitQuestionnaireType.EVIDENCE_DELIVERY_PARTNER],
});

const checkIsAnyQuestionnaireAnswered = (organisation?: Organisation) =>
    Boolean(
        organisation &&
            Object.values(BenefitQuestionnairesEditabilityType).find(
                (type) => organisation[type] === false,
            ),
    );
export const EditOrganisationQuestionnairesForm = () => {
    const [form] = useForm<BenefitQuestionnaires>();
    const dispatch = useDispatch();

    const organisation = useSelector(getUserActiveOrganisation);

    const handleOnFinish = (values: BenefitQuestionnaires) => {
        dispatch(editOrganisationRequest({ id: organisation!.id, ...values }));
    };

    const handleOnFinishFailed = () => showNotification({ text: notificationText.Error });

    if (!organisation) {
        return null;
    }

    return (
        <>
            {checkIsAnyQuestionnaireAnswered(organisation) && (
                <WarningMessage
                    message="Some of the questions have already been answered in at least one Benefit.
Please note that in order to keep questions and answers consistent changing any of them now will affect the Download Evidence and Feedback files (a new tab will be added in each spreadsheet)."
                />
            )}
            <Form
                form={form}
                onFinish={handleOnFinish}
                onFinishFailed={handleOnFinishFailed}
                initialValues={generateInitialFormValues(organisation)}
                header="Edit Default Benefit Questions"
                showResetButton
                submitButtonText="Update"
            >
                {Object.values(BenefitQuestionnaireType).map((questionnaireType) => (
                    <QuestionnaireTemplate type={questionnaireType} />
                ))}
            </Form>
        </>
    );
};
