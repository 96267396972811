import { FilterData } from 'common/helpers/url';

export enum DownloadPaths {
    Contracts = '/download/projects',
    ContractsFilter = '/download/projects/filter',
    Benefits = '/download/benefits',
    BenefitsFilter = '/download/benefits/filter',
}

export interface DownloadRequest {
    ids?: number[];
    fileFormat: string;
    filters?: FilterData;
    downloadAll: boolean;
    exportType?: string;
    benefitType?: string;
}

export interface DownloadAll {
    downloadAll?: boolean;
}

export interface InvoiceDownloadRequest {
    subscriptionId?: number;
    invoiceId?: number;
}
